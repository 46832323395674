// src/types/DataGridTypes.ts

export interface Column<T> {
  key: keyof T;
  header: string;
  filterType?: EEditorType;
  editable?: boolean;
  options?: { label: string; value: any }[]; // For dropdown filters and editors
  warning?: (value: any) => string | null; // Function to determine warnings
  width?: number;
  minWidth?: number;
  renderEditCell?: React.FC<any>;
  validationFunction?: (row: T, value: any) => string;
}

export interface RowData {
  id: number | string;
  [key: string]: any;
}

export interface DataGridProps<T extends RowData> {
  columns: Column<T>[];
  data: T[];
  expandable?: boolean;
  onRowExpand?: (row: T) => React.ReactNode;
  selectable?: boolean;
  onSelectionChange?: (selected: T) => void;
  editable?: boolean;
  onEdit?: (updatedRow: T) => void;
  editableRowIds?: Set<number | string>; // Set of editable row IDs
  isWithFilter?: boolean; // Whether to show the filter bar
  dir?: "rtl" | "ltr";
  detailsKeysToHide?: string[];
  selectedRowIds?: Set<string | number>;
  setLoadMore?: (state: boolean) => void;
  minWidth?: number;
}
export enum EEditorType {
  text,
  dropdown,
}
