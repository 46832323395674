// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.competency {
  --background: var(--ion-color-primary);
  display: flex;
  justify-content: center;
}

.itemRow {
  width: 100%;
  margin-top: 20px;
  background-color: var(--ion-color-dark);
  justify-content: center;
}

.itemHeaderDiv {
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  margin-inline-start: 10px;
  margin-inline-end: 10px;
  margin-top: 10px;
}

.itemHeaderLabel {
  font-family: "Light" !important;
  font-size: 24px !important;
  color: rgba(255, 255, 255, 0.6);
}

.dropdownRow {
  width: 100%;
  align-items: center;
}

.selectForceRow {
  padding: 10px;
  padding-bottom: 0;
}

.treeIconCol {
  display: flex;
  justify-content: center;
}

.finalGradeRow {
  min-height: 353px;
}

.traineeTreeButtonCol {
  display: flex;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Mobile/Competency/Competency.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,uCAAuC;EACvC,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,aAAa;EACb,8BAA8B;EAC9B,yBAAyB;EACzB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,+BAA+B;EAC/B,0BAA0B;EAC1B,+BAA+B;AACjC;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".competency {\n  --background: var(--ion-color-primary);\n  display: flex;\n  justify-content: center;\n}\n\n.itemRow {\n  width: 100%;\n  margin-top: 20px;\n  background-color: var(--ion-color-dark);\n  justify-content: center;\n}\n\n.itemHeaderDiv {\n  width: 100%;\n  flex-direction: row;\n  display: flex;\n  justify-content: space-between;\n  margin-inline-start: 10px;\n  margin-inline-end: 10px;\n  margin-top: 10px;\n}\n\n.itemHeaderLabel {\n  font-family: \"Light\" !important;\n  font-size: 24px !important;\n  color: rgba(255, 255, 255, 0.6);\n}\n\n.dropdownRow {\n  width: 100%;\n  align-items: center;\n}\n\n.selectForceRow {\n  padding: 10px;\n  padding-bottom: 0;\n}\n\n.treeIconCol {\n  display: flex;\n  justify-content: center;\n}\n\n.finalGradeRow {\n  min-height: 353px;\n}\n\n.traineeTreeButtonCol {\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
