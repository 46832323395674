// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stationButtonComp {
  width: 40vw;
  height: 10vh;
  --color: var(--ion-color-fontWhite);
  --background: var(--ion-color-light);
  --border-radius: 4px;
  --ripple-color: var(--ion-color-light);
  --color-focused: var(--ion-color-light);
  --color-activated: var(--ion-color-fontWhite);
  --background-activated: var(--ion-color-medium);
  font-size: large;
  font-family: "Regular" !important;
  font-weight: normal;
  --box-shadow: var(--box-shadow);

  text-transform: none;
  white-space: normal;
}
.stationButtonLabel {
  line-height: 21px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Mobile/SiteManagement/StationButtonComp.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,mCAAmC;EACnC,oCAAoC;EACpC,oBAAoB;EACpB,sCAAsC;EACtC,uCAAuC;EACvC,6CAA6C;EAC7C,+CAA+C;EAC/C,gBAAgB;EAChB,iCAAiC;EACjC,mBAAmB;EACnB,+BAA+B;;EAE/B,oBAAoB;EACpB,mBAAmB;AACrB;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".stationButtonComp {\n  width: 40vw;\n  height: 10vh;\n  --color: var(--ion-color-fontWhite);\n  --background: var(--ion-color-light);\n  --border-radius: 4px;\n  --ripple-color: var(--ion-color-light);\n  --color-focused: var(--ion-color-light);\n  --color-activated: var(--ion-color-fontWhite);\n  --background-activated: var(--ion-color-medium);\n  font-size: large;\n  font-family: \"Regular\" !important;\n  font-weight: normal;\n  --box-shadow: var(--box-shadow);\n\n  text-transform: none;\n  white-space: normal;\n}\n.stationButtonLabel {\n  line-height: 21px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
