import "./SimpleSelect.css";
import Select from "react-select";
import { useTranslation } from "react-i18next";

interface IOption<T> {
  label: string;
  value: T;
}

interface IProps<T> {
  onSelect: (selectedOption: IOption<T> | null) => void;
  options: IOption<T>[];
  value: IOption<T> | null;
  onBlur?: () => void;
  placeholder?: string;
}

const SimpleSelect = <T,>(props: IProps<T>): JSX.Element => {
  const { onSelect, options, value, onBlur } = props;
  const { t } = useTranslation();
  const matchInObject = ({ value }: any, inputValue: string) => {
    const searchValue = inputValue?.toLowerCase();
    return (
      value?.toString()?.toLowerCase()?.includes(searchValue) ||
      Object.values(value).some((val) => {
        return val
          ?.toString()
          .toLowerCase()
          .includes(searchValue || "");
      })
    );
  };

  return (
    <Select
      options={options}
      value={value}
      onChange={onSelect}
      isSearchable
      filterOption={matchInObject}
      classNamePrefix={"shula"}
      onBlur={onBlur}
      placeholder={t(props.placeholder) || ""}
      menuPortalTarget={document.body} // Render dropdown in the body to avoid scroll constraints
      maxMenuHeight={200}
      menuPosition="absolute"
      menuPlacement="auto"
      menuShouldScrollIntoView={false} // Prevent scroll jumping
      noOptionsMessage={() => t("noOptions")}
    />
  );
};

export default SimpleSelect;
