import React, { FC, useEffect, useState } from "react";
import { IonPage, IonContent, IonGrid, IonRow } from "@ionic/react";
import "./DataPostingMobileTemplate.css";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ELanguage from "../../../Enums/ELanguage";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import usePlans from "../../CustomHooks/usePlans";
import PlansDropdown from "../../Shared/PlansDropdown/PlansDropdown";
import MobileHeader from "../MobileHeader/MobileHeader";
import MobileTitleRow from "../MobileHeader/MobileTitleRow";
import PMButton from "../../themeComponents/PMButton";

interface IProps {
  children: React.ReactNode;
  stationName: string;
  traineeName: string;
  isSendDisabled?: boolean;
}
const DataPostingMobileTemplate: FC<IProps> = (props: IProps) => {
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const {
    onPlanSelectedHandler,
    selectedPlan,
    plansOptions,
    plansValuesForSelectedPlan,
  } = usePlans();

  const [isSendClicked, setButtonClicked] = useState(false);
  const [isButtonSendDisabled, setIsSendDisable] = useState<boolean>(false);

  const handleButtonClick = () => {
    setButtonClicked(true);
  };

  useEffect(() => {
    if (isSendClicked) setButtonClicked(false);
  }, [isSendClicked]);

  const childrenWithProps = React.Children.map(props.children, (child: any) => {
    return React.cloneElement(child, {
      isSendClicked,
      planId: selectedPlan?.id,
      setButtonClicked: setButtonClicked,
      plansValuesForSelectedPlan,
      setIsSendDisable,
    });
  });

  const backHandler = () => {
    history.goBack();
  };
  return (
    <IonPage>
      <div className="contentContainerMobileDataPosting">
        <MobileHeader
          iconEndSrc={EIconsSrc.MENU_ICON}
          iconStartSrc={
            i18n.language === ELanguage.he
              ? EIconsSrc.BACK_ICON_HE
              : EIconsSrc.BACK_ICON_EN
          }
          title={t(props.stationName)}
          iconStartHandler={backHandler}
        />
        <IonGrid className="mobileDataPostingGrid">
          <MobileTitleRow title={props.traineeName} />
          <IonRow className="mobilePlansDropdown">
            <PlansDropdown
              onPlanSelectedHandler={onPlanSelectedHandler}
              plansOptions={plansOptions}
              selectedPlan={selectedPlan}
            />
          </IonRow>

          {childrenWithProps}
          <IonRow className={"mobileButton buttonContainerMT"}>
            <PMButton
              onClickHandler={handleButtonClick}
              isDisabled={props.isSendDisabled || isButtonSendDisabled}
              color="orange"
              size="small"
              label={{
                fontColor: "light",
                fontSize: "medium",
                fontFamily: "Regular",
              }}
            >
              {t("send")}
            </PMButton>
          </IonRow>
        </IonGrid>
      </div>
    </IonPage>
  );
};

export default DataPostingMobileTemplate;
