// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warningRow {
  width: max-content;
  height: max-content;
  background-color: var(--ion-color-light);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3px;
  padding-inline-start: 12px;
  padding-inline-end: 12px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  align-self: center;
  justify-self: center;
  padding: 5px;
}
.warningIcon {
  margin-inline-end: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/SPIKEGorilla/WarningRow.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,wCAAwC;EACxC,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,eAAe;EACf,0BAA0B;EAC1B,wBAAwB;EACxB,+CAA+C;EAC/C,kBAAkB;EAClB,kBAAkB;EAClB,oBAAoB;EACpB,YAAY;AACd;AACA;EACE,sBAAsB;AACxB","sourcesContent":[".warningRow {\n  width: max-content;\n  height: max-content;\n  background-color: var(--ion-color-light);\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-top: 3px;\n  padding-inline-start: 12px;\n  padding-inline-end: 12px;\n  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);\n  border-radius: 4px;\n  align-self: center;\n  justify-self: center;\n  padding: 5px;\n}\n.warningIcon {\n  margin-inline-end: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
