// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.results-buttons-wrapper {
  width: 100%;
  height: 10%;

  display: flex;
  justify-content: space-evenly;
  align-items: self-end;
}
`, "",{"version":3,"sources":["webpack://./src/components/Mobile/ManualStation/ShootingRange/ResultsScreen/ResultsButtons/ResultsButtons.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,WAAW;;EAEX,aAAa;EACb,6BAA6B;EAC7B,qBAAqB;AACvB","sourcesContent":[".results-buttons-wrapper {\n  width: 100%;\n  height: 10%;\n\n  display: flex;\n  justify-content: space-evenly;\n  align-items: self-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
