import IPlan from "../../../Interfaces/IPlan";
import { IForceAllData } from "../../../Interfaces/IForceAllData";
import { IResult } from "../../../Interfaces/dataCalculator";
import { TFunction } from "i18next";
import generatePDF from "./generatePDFHelper";
import { getForceFullName } from "../../../services/helpers";
const getRemark = (
  grade: number | undefined,
  plan: IPlan | undefined,
  t: any
) => {
  if (!grade) return t("notGoodGrade");
  if (grade >= 90) return t("excellent");
  else {
    if (grade >= 80) return t("veryGoog");
    else {
      if (plan && grade >= Number(plan.lethality)) return t("goodGrade");
      else return t("notGoodGrade");
    }
  }
};

const insertToPdf = (
  header: { name: string; grade: number },
  data: IResult[],
  t: TFunction,
  isTooltips?: boolean
) => {
  let forceArray: string[] = [];
  let fixedHeader =
    header.grade !== null
      ? `${t(header.name)}: ${Math.round(header.grade)}`
      : `${t(header.name)}:-`;
  forceArray.push(fixedHeader);
  data.forEach((resultsArray: IResult) => {
    let isNameNumber = false;
    if (!resultsArray.name || !isNaN(Number(resultsArray.name))) {
      isNameNumber = true;
    }
    if (!(isTooltips && resultsArray.value === undefined)) {
      let row =
        resultsArray.grade !== null && resultsArray.grade !== undefined
          ? !isNameNumber
            ? `${t(resultsArray.name)}: ${Math.round(resultsArray.grade)}`
            : `${Math.round(resultsArray.grade)}: ${t(resultsArray.name)}`
          : resultsArray.value !== undefined && resultsArray.value !== null
          ? !isNameNumber
            ? `${t(resultsArray.name)}: ${resultsArray.value}`
            : `${resultsArray.value}: ${t(resultsArray.name)}`
          : !isNameNumber
          ? `${t(resultsArray.name)}: ${
              isTooltips ? t("noValue") : t("notAttended")
            }`
          : `${isTooltips ? t("noValue") : t("notAttended")}: ${t(
              resultsArray.name
            )}`;
      forceArray.push(row);
    }
  });

  return forceArray;
};
const exportToPdf = async (
  exportData: IForceAllData[],
  plan?: IPlan,
  t?: any,
  isLightMode?: boolean
) => {
  let currentDate: Date = new Date();
  let fileName = `PerformanceReport_${String(currentDate.getDate()).padStart(
    2,
    "0"
  )}${String(currentDate.getMonth() + 1).padStart(2, "0")}${
    currentDate.getFullYear() % 100
  }${currentDate.getHours()}${currentDate.getMinutes()}${currentDate.getSeconds()}${
    exportData?.length === 1 ? "_" + exportData[0].forceDetails.name : ""
  }${
    exportData?.length === 1 && exportData[0].forceDetails.soldier_id !== null
      ? "_" + exportData[0].forceDetails.soldier_id
      : ""
  }`;
  let pdfData: {
    type:
      | "indicators"
      | "stations"
      | "competency"
      | "leaders"
      | "trends"
      | "forceDetails"
      | "hashtags"
      | "title";
    data: any;
    image?: string;
    title?: string;
  }[] = [];
  if (exportData && exportData.length > 0) {
    exportData.forEach((forceData: IForceAllData) => {
      if (exportData.length > 0) {
        //name title
        let name = `${t(
          forceData?.forceDetails.is_soldier ? "traineeReport" : "generalReport"
        )}: ${forceData?.forceDetails.name}`;

        pdfData.push({
          type: "title",
          data: name,
        });
        //plan
        let planDetails: any = {};
        let forceDetails: any = {};
        if (plan && t && plan.startDate && plan.endDate) {
          let planName = `${t("trainingPlans")}: ${plan?.name}`;
          let planDates = `${formatDate(plan.startDate)} - ${formatDate(
            plan.endDate
          )}`;
          planDetails = { plan: planName, dates: planDates };
        }

        //soldier id
        if (forceData?.forceDetails.soldier_id)
          forceDetails["soldierId"] = `${t("soldierId")}: ${
            forceData?.forceDetails.soldier_id
          }`;

        //affiliation
        if (forceData?.forceGradesData.affiliation !== "") {
          forceDetails[
            "affiliation"
          ] = `${forceData?.forceGradesData.affiliation.replaceAll("->", "/")}`;
        }

        //insert badge to array and final grade

        let remark = getRemark(
          forceData.forceGradesData.forceResults.grade,
          plan,
          t
        );
        planDetails["remark"] = remark;

        planDetails["grade"] = `${t("finalGrade")}: ${Math.round(
          forceData.forceGradesData.forceResults.grade
        )}`;
        if (forceData.forceGradesData.pic3)
          planDetails["image"] = forceData.forceGradesData.pic3;

        pdfData.push({ type: "competency", data: planDetails });
        //ranks

        if (forceData.forceDetails.nodes === null) {
          if (
            forceData.forceGradesData.forceResults.rankings &&
            forceData.forceGradesData.forceResults.rankings.length
          ) {
            forceDetails["ranks"] = {
              title: t("rank"),
              data: forceData.forceGradesData.forceResults.rankings.map(
                (rank) => [
                  getForceFullName(rank.rank.name, rank.rank.forceType, t),
                  rank.value,
                ]
              ),
            };
          }
        }
        pdfData.push({ type: "forceDetails", data: forceDetails });

        let forceArray;

        //indicators

        let forceIndicators: string[][] = [];
        if (forceData.forceGradesData.forceResults.indicatorResults.length) {
          forceData.forceGradesData.forceResults.indicatorResults.forEach(
            (indicator) => {
              forceArray = insertToPdf(
                indicator,
                indicator.trainingTypeResults,
                t
              );
              forceIndicators.push(forceArray);
            }
          );
        }
        let forceIndicatorsData: any = {};
        if (forceIndicators.length) {
          forceIndicatorsData = {
            type: "indicators",
            data: forceIndicators,
            title: t("indicatorsAndTrainingTypesDetails"),
          };
          if (
            forceData.forceGradesData.pic1 &&
            forceData.forceGradesData.pic1 !== ""
          ) {
            forceIndicatorsData["image"] = forceData.forceGradesData.pic1;
          }
          pdfData.push(forceIndicatorsData);
        }

        let forceTrainingTypes: string[][] = [];

        let trainingTypes =
          forceData.forceGradesData.forceResults.indicatorResults
            .map((indicator) => indicator.trainingTypeResults)
            .flat(1);
        trainingTypes.forEach((trainingType) => {
          if (
            trainingType.grade !== null &&
            trainingType.tooltipResults?.tooltipElementsResults?.length
          ) {
            forceArray = insertToPdf(
              trainingType,
              trainingType.tooltipResults?.tooltipElementsResults || [],
              t,

              true
            );
            forceTrainingTypes.push(forceArray);
          }
        });
        let forceTrainingTypesData: any = {};

        if (forceTrainingTypes.length) {
          forceTrainingTypesData = {
            type: "stations",
            data: forceTrainingTypes,
          };
          if (
            forceData.forceGradesData.pic2 &&
            forceData.forceGradesData.pic2 !== ""
          ) {
            forceTrainingTypesData["image"] = forceData.forceGradesData.pic2;
          }
          pdfData.push(forceTrainingTypesData);
        }
        let forceHashtags: string[][] = [];
        trainingTypes.forEach((trainingType) => {
          //hashtags
          if (trainingType.hashtagsResults.length) {
            forceArray = insertToPdf(
              trainingType,
              trainingType.hashtagsResults,

              t
            );
            forceHashtags.push(forceArray);
          }
        });
        if (forceHashtags.length) {
          pdfData.push({
            type: "hashtags",
            data: forceHashtags,
          });
        }
      }
    });

    return generatePDF(pdfData, fileName, isLightMode);
  }
  return false;
};
export { exportToPdf };
function formatDate(dateString: Date) {
  let date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();

  // Customize the format as needed
  return `${day}/${month}/${year}`;
}
