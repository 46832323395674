import { IonGrid, IonRow, IonCol } from "@ionic/react";
import React, { useEffect, useState } from "react";
import ISimpleTrainee from "../../../Interfaces/ISimpleTrainee";
import SearchComponents from "../../Shared/MenualStation/SearchSoldierComp/SearchComponents";
import DesktopManualInputTemplate from "./DesktopManualInputTemplate";
import IDataPosting from "../../../Interfaces/IDataPosting";

import "./SPIKE.css";
import SPIKEComponent from "../../Shared/MenualStation/SpikeOEM/SPIKEComponent";
const SPIKE: React.FC<IDataPosting> = (props: IDataPosting): JSX.Element => {
  const [soldierDetails, setSoldierDetails] = useState<ISimpleTrainee[]>([]);
  const SPIKE_EOM_TRAINING_TYPE_ID = 17;
  const [isSendDataClicked, setSendDataClicked] = useState<boolean>(false);

  const [isSendEnabled, setIsComplete] = useState<boolean>(false);

  useEffect(() => {
    setSendDataClicked(false);
  }, [isSendDataClicked]);
  return (
    <DesktopManualInputTemplate
      selectedPlan={props.selectedPlan}
      plansOptions={props.plansOptions}
      onPlanSelectedHandler={props.onPlanSelectedHandler}
      pageName={"SPIKE"}
      onClickThirdButton={() => setSendDataClicked(true)}
      isSendDisabled={!isSendEnabled}
    >
      <IonGrid className="searchGrid">
        <IonRow className="searchRow">
          <IonCol size="2" className="searchCol">
            <SearchComponents
              soldierDetails={soldierDetails}
              setSoldierDetails={setSoldierDetails}
              isDesktop={true}
            />
          </IonCol>
          <IonCol size="0.2">
            <div></div>
          </IonCol>
          <IonCol size="9">
            <SPIKEComponent
              selectedTrainee={soldierDetails[0]}
              trainingTypeId={SPIKE_EOM_TRAINING_TYPE_ID}
              isSendClicked={isSendDataClicked}
              isComplete={isSendEnabled}
              setIsComplete={setIsComplete}
              planId={props.selectedPlan?.id}
              afterPostHandler={props.resetCompetencyForces}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </DesktopManualInputTemplate>
  );
};

export default SPIKE;
