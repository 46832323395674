import { FC } from "react";
import { IonCol, IonItem, IonItemGroup, IonRow } from "@ionic/react";
import "./VerticalSteps.css";
import PMLabel from "../../themeComponents/PMLabel";
import PMIcon from "../../themeComponents/PMIcon";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import { useTranslation } from "react-i18next";
import { ESingleGunnerSteps } from "../../../Interfaces/Gorilla/IHeatTemplate";
import { EObserverSteps } from "../../../Interfaces/Gorilla/IObserver";
import { ENCOSteps } from "../../CustomHooks/NCOHook";

interface IStep {
  id: ESingleGunnerSteps | EObserverSteps | ENCOSteps;
  title: string;
  isCompleted: boolean;
  isMustCompleted?: boolean;
  description?: string;
  isPartlyCompleted?: boolean;
}
interface IProps {
  steps: IStep[];
  activeStep: number;
  isToDisplaySteps?: boolean;
  onClickHandler?: (id: any) => void;
}
const VerticalSteps: FC<IProps> = (props: IProps) => {
  const { activeStep, steps, isToDisplaySteps, onClickHandler } = props;
  const { t } = useTranslation();

  return (
    <IonItemGroup className="verticalStepsCol">
      {steps.map((step, index) => (
        <IonItem
          lines="none"
          className={`verticalStepItem ${
            !(
              step.isCompleted ||
              (steps[(index - 1) as ESingleGunnerSteps] &&
                steps[(index - 1) as ESingleGunnerSteps].isCompleted)
            ) && isToDisplaySteps
              ? "disabled"
              : ""
          }`}
          key={index}
          button={onClickHandler !== undefined}
          onClick={() => {
            if (onClickHandler) onClickHandler(step.id);
          }}
        >
          <IonRow className="verticalStepRow">
            <IonCol className="verticalStepItemWrap">
              <span
                className={`verticalStepCol ${
                  step.isCompleted
                    ? "complectedStep"
                    : (isToDisplaySteps && activeStep === step.id) ||
                      step.isPartlyCompleted
                    ? "activeStep"
                    : step.isCompleted
                    ? "complectedStep"
                    : ""
                }`}
              >
                {step.isCompleted ? (
                  <PMIcon size="xSmall" iconSrc={EIconsSrc.OK}></PMIcon>
                ) : step.isPartlyCompleted ? (
                  <PMIcon size="xSmall" iconSrc={EIconsSrc.OK}></PMIcon>
                ) : isToDisplaySteps ? (
                  step.id + 1
                ) : null}
              </span>
            </IonCol>

            <IonCol className="VSLabel">
              <PMLabel
                fontColor={
                  activeStep === step.id
                    ? "orange"
                    : !(
                        step.isCompleted ||
                        (steps[(index - 1) as ESingleGunnerSteps] &&
                          steps[(index - 1) as ESingleGunnerSteps].isCompleted)
                      ) && isToDisplaySteps
                    ? "disabled"
                    : "light"
                }
                fontFamily={"Regular"}
                fontSize={activeStep === step.id ? "xLarge" : "large"}
              >
                {t(step.title)}
              </PMLabel>
            </IonCol>
          </IonRow>
        </IonItem>
      ))}
    </IonItemGroup>
  );
};

export default VerticalSteps;
