enum ESPIKEIcons {
  COMBINED = "./assets/icon/spike_icons/combined.svg ",
  BUILT = "./assets/icon/spike_icons/built.svg ",
  CAR = "./assets/icon/spike_icons/car.svg",
  CONTROL = "./assets/icon/spike_icons/control.svg",
  DAY = "./assets/icon/spike_icons/day.svg",
  DRIVING = "./assets/icon/spike_icons/driving.svg",
  EXIST = "./assets/icon/spike_icons/exist.svg",
  HIDDEN = "./assets/icon/spike_icons/hidden.svg ",
  LOCKED = "./assets/icon/spike_icons/locked.svg",
  LONG = "./assets/icon/spike_icons/long.svg ",
  MAN = "./assets/icon/spike_icons/man.svg ",
  MEDIUM = "./assets/icon/spike_icons/medium.svg",
  MOVING = "./assets/icon/spike_icons/moving.svg",
  NIGHT = "./assets/icon/spike_icons/night.svg ",
  NOT_EXIST = "./assets/icon/spike_icons/not_exist.svg",
  OPEN = "./assets/icon/spike_icons/open.svg ",
  SHORT = "./assets/icon/spike_icons/short.svg",
  STATIC = "./assets/icon/spike_icons/static.svg",
  COMPLEX = "./assets/icon/spike_icons/tangled.svg",
  WINDOW = "./assets/icon/spike_icons/window.svg",
  INNER_MAN = "./assets/icon/spike_inner_icons/inner_man.svg",
  INNER_BUILT = "./assets/icon/spike_inner_icons/inner_built.svg",
  INNER_CAR = "./assets/icon/spike_inner_icons/inner_car.svg",
  INNER_LONG = "./assets/icon/spike_inner_icons/inner_long.svg",
  INNER_MEDIUM = "./assets/icon/spike_inner_icons/inner_medium.svg",
  INNER_OPEN = "./assets/icon/spike_inner_icons/inner_open.svg",
  INNER_SHORT = "./assets/icon/spike_inner_icons/inner_short.svg",
  INNER_COMPLEX = "./assets/icon/spike_inner_icons/inner_tangled.svg",
  INNER_WINDOW = "./assets/icon/spike_inner_icons/inner_window.svg",
}

export default ESPIKEIcons;
