import React, { useEffect, useState } from "react";
import IPlan from "../../../../../Interfaces/IPlan";
import "./Rankings.css";
import Axios from "../../../../../Axios";
import { baseUrlPMBackend } from "../../../../../Configurations/consts";
import IForceFromOrbat from "../../../../../Interfaces/IForceFromOrbat";
import { useTranslation } from "react-i18next";
import Spinner from "../../../../Shared/Spinner/Spinner";
import PMLabel from "../../../../themeComponents/PMLabel";
import { IRankResult } from "../../../../../Interfaces/dataCalculator";
import { getForceFullName } from "../../../../../services/helpers";

interface IRankings {
  plan: IPlan;
  checkedForces: IForceFromOrbat[];
}

interface IForceRanking {
  forceId: number;
  forceRanks: IRankResult[];
}

const Rankings = (props: IRankings) => {
  const [forcesRankings, setForcesRankings] = useState<IForceRanking[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  useEffect(() => {
    let mounted: boolean = true;
    setIsLoading(true);
    setForcesRankings([]);

    if (
      !props.plan.id ||
      !props.checkedForces.filter((f) => f.isSoldier).length
    )
      return;

    Axios.get(`${baseUrlPMBackend}performanceGrades/getRankingResults`, {
      params: {
        forcesIds: props.checkedForces
          .filter((f) => f.isSoldier)
          .map((f) => f.id),
        planId: props.plan.id,
      },
    }).then((res) => {
      if (mounted) {
        let rankingsRes: IForceRanking[] = res.data;
        setForcesRankings(rankingsRes);
        setIsLoading(false);
      }
    });
    return () => {
      mounted = false;
    };
  }, [props.plan, props.checkedForces]);

  return (
    <div className="rankingsDiv">
      {isLoading ? (
        <Spinner className="competency-spinner" />
      ) : (
        <div className="RankingWrap">
          {forcesRankings.map((force) => (
            <div key={force.forceId} className="">
              <div className="legendContainerRanking">
                <canvas
                  className="legendStyleRanking"
                  style={{
                    backgroundColor: props.checkedForces.find(
                      (f) => f.id === force.forceId
                    )?.color,
                  }}
                />
                <PMLabel
                  cssClass="legendTextRanking"
                  fontColor="light"
                  fontSize="large"
                >
                  {
                    props.checkedForces.find((f) => f.id === force.forceId)
                      ?.name
                  }
                </PMLabel>
              </div>
              <div className="rankingsList">
                {force.forceRanks.map((ranking) => (
                  <div className="rankingDiv" key={ranking.id}>
                    <PMLabel fontColor="xLight" fontSize="large">
                      {getForceFullName(
                        ranking.rank.name,
                        ranking.rank.forceType,
                        t
                      )}
                    </PMLabel>
                    <PMLabel fontColor="light" fontSize="medium">
                      {ranking.value}
                    </PMLabel>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Rankings;
