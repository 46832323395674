import { Dispatch, FC, SetStateAction, useState } from "react";
import { IonCol, IonItemGroup, IonRow } from "@ionic/react";
import "./HeatsMenuDesktop.css";
import HeatMenuRow from "./HeatMenuRow";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import { useTranslation } from "react-i18next";
import { IHeatTemplate } from "../../../Interfaces/Gorilla/IHeatTemplate";
import { initialHeatTemplate } from "../../CustomHooks/GorillaConsts";
import PMIcon from "../../themeComponents/PMIcon";
import WarningModal from "./WarningModal";
interface IProps {
  heatsArray: IHeatTemplate[];
  activeHeatId: number;
  setActiveHeat: (heatIndex: number) => void;
  resetHeat: (heatIndex: number) => void;
  setActiveHeatId: Dispatch<SetStateAction<number>>;
  isDisableAddHeats?: boolean;
  deleteHeat: (heatId: number) => void;
  addHeat: () => void;
}
const HeatsMenuDesktop: FC<IProps> = (props: IProps) => {
  const {
    heatsArray,
    activeHeatId,
    addHeat,
    deleteHeat,
    setActiveHeat,
    resetHeat,
    isDisableAddHeats,
  } = props;
  const [selectedHeatId, setSelectedHeatId] = useState<number | undefined>();
  const [isResetHeatAlertOpen, setIsResetHeatAlertOpen] =
    useState<boolean>(false);
  const [isDeleteHeatAlertOpen, setIsDeleteHeatAlertOpen] =
    useState<boolean>(false);

  const { t } = useTranslation();

  return (
    <>
      <IonCol className={"heatMenuContentDesktop"}>
        <IonRow className="listWrapDesktop">
          <IonRow className="descriptionHeatRowDesktop">
            <IonCol className="paddingFree">
              <PMIcon
                onClick={addHeat}
                iconSrc={EIconsSrc.ADD_OUTLINE}
                size="xLarge"
                color="light"
                disabled={isDisableAddHeats}
              ></PMIcon>
            </IonCol>
            <IonCol className="heatsIconColDesktop">
              <IonRow className="heatsIconsRowDesktop">
                <PMIcon
                  onClick={() => setActiveHeat(selectedHeatId!)}
                  iconSrc={EIconsSrc.PLAY}
                  size="xLarge"
                  color="light"
                  disabled={
                    heatsArray.find(
                      (heat) => heat.heatId === selectedHeatId
                    ) === undefined
                  }
                ></PMIcon>

                <PMIcon
                  onClick={() => {
                    setIsResetHeatAlertOpen(true);
                  }}
                  iconSrc={EIconsSrc.RESTART}
                  size="xLarge"
                  color="light"
                  disabled={
                    heatsArray.find(
                      (heat) => heat.heatId === selectedHeatId
                    ) === undefined
                  }
                ></PMIcon>
                <PMIcon
                  onClick={() => {
                    setIsDeleteHeatAlertOpen(true);
                  }}
                  iconSrc={EIconsSrc.TRASH_ICON}
                  size="xLarge"
                  color="light"
                  disabled={
                    heatsArray.length <= 1 ||
                    heatsArray.find(
                      (heat) => heat.heatId === selectedHeatId
                    ) === undefined
                  }
                ></PMIcon>
              </IonRow>
            </IonCol>
          </IonRow>

          <IonRow className="heatsListWrapDesktop">
            <IonItemGroup className="heatsListDesktop">
              {heatsArray.map((heat, index) => (
                <div key={index}>
                  {index > 0 && <IonRow className="dividerHeatsRow" />}
                  <HeatMenuRow
                    heatName={`${t("heat")} ${heat.heatId}`}
                    isCompleted={heat.isCompleted}
                    isActive={heat.heatId === activeHeatId}
                    onClick={() => {
                      setSelectedHeatId((prev) =>
                        prev === heat.heatId ? undefined : heat.heatId
                      );
                    }}
                    isSelected={heat.heatId === selectedHeatId}
                    onDoubleClickHandler={() => {
                      setActiveHeat(heat.heatId);
                    }}
                  ></HeatMenuRow>
                </div>
              ))}
            </IonItemGroup>
            <IonRow className="heatMenuTitleRowDesktop"></IonRow>
          </IonRow>
        </IonRow>
      </IonCol>
      <WarningModal
        isOpen={isResetHeatAlertOpen}
        acceptText={t("resetHeatConfirmation")}
        declineText="cancel"
        onAccept={() => {
          resetHeat(selectedHeatId!);
          setIsResetHeatAlertOpen(false);
        }}
        onDecline={() => {
          setIsResetHeatAlertOpen(false);
        }}
        title={t("resetHeat")}
        warningText={`${t("resetHeatWarning")} ${selectedHeatId}`}
        warningQuestion={t("resetHeatQuestion")}
        isAcceptDefault
      ></WarningModal>
      <WarningModal
        isOpen={isDeleteHeatAlertOpen}
        acceptText={t("deleteHeatConfirmation")}
        declineText="cancel"
        onAccept={() => {
          deleteHeat(selectedHeatId!);
          setIsDeleteHeatAlertOpen(false);
        }}
        onDecline={() => {
          setIsDeleteHeatAlertOpen(false);
        }}
        title={t("heatDeleting")}
        warningQuestion={`${t("deleteHeatQuestion")} ${selectedHeatId}?`}
        isAcceptDefault
      ></WarningModal>
    </>
  );
};

export default HeatsMenuDesktop;
