import React from "react";
import { IonCheckbox } from "@ionic/react";
import "./Checkbox.css";

interface IProps {
  name?: string;
  onChange?: (arg: any) => void;
  onClicked?: (arg: any) => void;
  value?: string;
  isChecked: boolean;
  hidden?: boolean;
  color?: "xLight" | "light" | "dark" | "xDark" | "orange" | "red";
  isDisabled?: boolean;
  isLocked?: boolean;
}
const Checkbox = (props: IProps) => {
  return (
    <IonCheckbox
      className={`selectorCheckbox ${props.isLocked ? "lockedCheckbox" : ""} ${
        props.color
      }C`}
      name={props.name}
      value={props.value}
      checked={props.isChecked}
      hidden={props.hidden}
      onIonChange={props.onChange}
      onClick={props.onClicked}
      disabled={props.isDisabled}
    />
  );
};

export default Checkbox;
