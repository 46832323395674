import React, { FC } from "react";
import "./PerformanceDisplay.css";
import { IonPage, IonRow } from "@ionic/react";
import { MAX_SELECTED } from "../../../Configurations/consts";
import IForceTreeNode from "../../../Interfaces/IForceTreeNode";
import { useTranslation } from "react-i18next";
import IFinalGradeDisplay from "../../../Interfaces/IFinalGradeDisplay";
import FinalGradeDisplay from "../../../components/Shared/FinalGradeDisplay/FinalGradeDisplay";
import IPlan from "../../../Interfaces/IPlan";

import PMLoading from "../../../components/Shared/Alert/PMLoading";
import PMButton from "../../../components/themeComponents/PMButton";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import PMTitle from "../../../components/themeComponents/PMTitle";

import OrbatTreeDrawer from "../../../components/Shared/Orbat/OrbatTree/OrbatTreeDrawer";
import PMLabel from "../../../components/themeComponents/PMLabel";
import PopOverButton from "../../../components/themeComponents/PopOverButton";
import EExportMethod from "../../../Interfaces/EExportMethod";
import PlansDropdown from "../../../components/Shared/PlansDropdown/PlansDropdown";
import classNames from "classnames";

interface IProps {
  title: string;
  trendsDropdown?: React.ReactNode;
  chart: React.ReactNode;
  finalGrades?: IFinalGradeDisplay[];
  loading: boolean;
  orbatForces: IForceTreeNode[];
  setChecked: (forces: IForceTreeNode[]) => void;
  isDrawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClickExportButton?: (exportMethod: EExportMethod) => void;
  exportButtonText?: string;
  onClickRefreshButton?: () => void;
  refreshButtonText?: string;
  loadingText: string;
  limit?: number;
  selectedPlan: IPlan | undefined;
  onPlanSelectedHandler?: (planOption: IPlan) => void;
  plansOptions?: IPlan[];
  isPlanLoading?: boolean;
  displayPlatoonAndAbove?: boolean | undefined;
  onClickTopButtonHandler?: () => void;
  topButtonText?: string;
  selectedForceId?: number;
  isNotExport?: boolean;
  iButtonsDisabled?: boolean;
  detailsComponent?: React.ReactNode;
}

const PerformanceTemplate: FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation();

  const {
    trendsDropdown,
    orbatForces,
    setChecked,
    finalGrades,
    isDrawerOpen,
    setDrawerOpen,
    loading,
    onClickExportButton,
    exportButtonText,
    onClickRefreshButton,
    refreshButtonText,
    loadingText,
    limit,
    onPlanSelectedHandler,
    selectedPlan,
    plansOptions,
    displayPlatoonAndAbove,
    selectedForceId,
    iButtonsDisabled,
    detailsComponent,
  } = props;

  return (
    <IonPage>
      <PMLoading isOpen={loading} message={loadingText} spinner={"bubbles"} />
      <div className="shadowBorder">
        <div
          className={classNames(`treeAndChart`, {
            fullWidthTreeAndChart: finalGrades || detailsComponent,
          })}
        >
          <OrbatTreeDrawer
            onCheckForce={setChecked}
            orbatForces={orbatForces}
            limit={limit ? limit : MAX_SELECTED}
            isDrawerOpen={isDrawerOpen}
            setDrawerOpen={setDrawerOpen}
            displayPlatoonAndAbove={displayPlatoonAndAbove}
            selectedForceId={selectedForceId}
          ></OrbatTreeDrawer>

          <div className="chartsAndControls">
            <div className="header-scope">
              <PMLabel
                fontColor="light"
                fontFamily="Regular"
                fontSize="xxLarge"
                textAlign="start"
              >
                {props.title}
              </PMLabel>
              <IonRow className="desktopDropdown">
                {props.onClickTopButtonHandler ? (
                  <PMButton
                    size="medium"
                    color="orange"
                    fill="outline"
                    onClickHandler={props.onClickTopButtonHandler}
                    label={{
                      fontSize: "medium",
                      fontColor: "xLight",
                      fontFamily: "Regular",
                    }}
                    cssClass="topButton"
                  >
                    {t(props.topButtonText)}
                  </PMButton>
                ) : null}
                {trendsDropdown ? (
                  trendsDropdown
                ) : (
                  <PlansDropdown
                    onPlanSelectedHandler={onPlanSelectedHandler}
                    plansOptions={plansOptions}
                    selectedPlan={selectedPlan}
                  ></PlansDropdown>
                )}
              </IonRow>
            </div>
            <div className="graph-col">{props.chart}</div>
            <div className="buttonsCompetency">
              <div className="TrendsToggle">
                {exportButtonText && onClickExportButton ? (
                  <PopOverButton
                    size="medium"
                    color="orange"
                    fill="solid"
                    isDisabled={iButtonsDisabled}
                    popOverItems={[
                      {
                        title: "pdfDarkMode",
                        onClick: () =>
                          onClickExportButton(EExportMethod.pdfDarkMode),
                        icon: EIconsSrc.EXPORT_PDF_DARK_MODE,
                      },
                      {
                        title: "pdfLightMode",
                        onClick: () =>
                          onClickExportButton(EExportMethod.pdfLightMode),
                        icon: EIconsSrc.EXPORT_PDF_LIGHT_MODE,
                      },
                      {
                        title: "excel",
                        onClick: () => onClickExportButton(EExportMethod.excel),
                        icon: EIconsSrc.XLS_FILE_EXPORT,
                      },
                    ]}
                    icon={{
                      iconSrc: EIconsSrc.EXPORT_ICON,
                      color: "dark",
                      size: "large",
                    }}
                    label={{
                      fontSize: "large",
                      fontColor: "dark",
                      fontFamily: "Regular",
                    }}
                  >
                    {exportButtonText}
                  </PopOverButton>
                ) : null}
              </div>
              <div className="TrendsToggle">
                {refreshButtonText && onClickRefreshButton ? (
                  <PMButton
                    size="medium"
                    color="orange"
                    fill="solid"
                    isDisabled={iButtonsDisabled}
                    icon={{
                      iconSrc: EIconsSrc.REFRESH_ICON,
                      size: "large",
                      color: "dark",
                    }}
                    onClickHandler={onClickRefreshButton}
                    label={{
                      fontSize: "medium",
                      fontColor: "xLight",
                      fontFamily: "Regular",
                    }}
                  >
                    {refreshButtonText}
                  </PMButton>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div
          className={classNames("leftPanel", {
            open: finalGrades || detailsComponent,
            close: !finalGrades && !detailsComponent,
            "badges-col": finalGrades,
          })}
        >
          {finalGrades ? (
            <>
              {selectedPlan && (
                <div className="title-container">
                  <PMTitle
                    fontColor="light"
                    fontFamily="Light"
                    fontSize="large"
                  >
                    {t("lethality")}
                  </PMTitle>
                </div>
              )}
              <div className="scrollContainerD">
                <div className="gradeContainerD">
                  {finalGrades[0] &&
                    finalGrades.map(
                      (grade: IFinalGradeDisplay, index: number) => (
                        <div className="badge-gradeQ" key={index}>
                          <FinalGradeDisplay
                            key={index}
                            soldierFinalGrade={grade.soldierFinalGrade}
                            threshold={grade.threshold}
                          />
                        </div>
                      )
                    )}
                </div>
              </div>
            </>
          ) : detailsComponent ? (
            detailsComponent
          ) : null}
        </div>
      </div>
    </IonPage>
  );
};

export default PerformanceTemplate;
