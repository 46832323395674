import React, { ReactNode, useContext, useState } from "react";
import { EAppMode } from "../../Enums/EAppMode";
import { UserContext } from "../UserContext/userContext";

interface ITrainingTypesContext {
  appMode: EAppMode;
  setAppMode: React.Dispatch<React.SetStateAction<EAppMode>>;
}

export const AppModeContext = React.createContext<ITrainingTypesContext>({
  setAppMode: () => {},
  appMode: EAppMode.WARTAC,
});

const AppModeContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { user } = useContext(UserContext);
  const [appMode, setAppMode] = useState<EAppMode>(
    EAppMode[user.appMode as EAppMode] || EAppMode.WARTAC
  );

  return (
    <AppModeContext.Provider
      value={{
        setAppMode,
        appMode,
      }}
    >
      {children}
    </AppModeContext.Provider>
  );
};

export default AppModeContextProvider;
