import {
  IonAccordion,
  IonAccordionGroup,
  IonCol,
  IonItem,
  IonRow,
} from "@ionic/react";
import "./PMAccordion.css";
import { caretDown } from "ionicons/icons";
import PMLabel from "../../themeComponents/PMLabel";
import { useEffect, useRef } from "react";
import Divider from "../SPIKEGorilla/Divider";

interface IProps {
  label?: string;
  icon?: any;
  children: any;
  isInnerItem?: boolean;
  placeholder: string;
  disabled?: boolean;
  isDisableAutoClose?: boolean;
}
const PMAccordion = (props: IProps) => {
  const accordionRef = useRef<null | HTMLIonAccordionGroupElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      accordionRef.current &&
      !accordionRef.current.contains(event.target as Node)
    ) {
      accordionRef.current!.value = undefined;
    }
  };

  useEffect(() => {
    if (!props.isDisableAutoClose)
      document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props.isDisableAutoClose]);

  return (
    <IonRow
      className={`accordionMainWrap ${props.isInnerItem ? "inner" : ""} ${
        props.disabled ? "disabled" : ""
      }`}
    >
      <IonAccordionGroup className="pmAccordionGroup" ref={accordionRef}>
        <IonAccordion
          className="pmAccordion"
          value={props.placeholder}
          toggleIcon={props.icon ? props.icon : caretDown}
        >
          <IonItem
            className={`pmAccordionItem ${props.isInnerItem ? "inner" : ""} ${
              props.label ? "withLabel" : ""
            }`}
            button
            slot="header"
            lines="full"
          >
            <div className="PMAccordionItem">
              {!props.isInnerItem ? (
                <PMLabel
                  fontSize={props.label ? "small" : "large"}
                  fontColor={props.label ? "orange" : "light"}
                  fontFamily="Regular"
                  cssClass={`floatingLabelPMAccordion ${
                    props.label ? "floating" : ""
                  }`}
                >
                  {props.placeholder}
                </PMLabel>
              ) : null}

              {props.label ? (
                <PMLabel
                  fontSize={"large"}
                  fontColor={"light"}
                  fontFamily="Light"
                  cssClass="placeholderPMAccordion"
                >
                  {props.label}
                </PMLabel>
              ) : null}
            </div>
          </IonItem>
          <div className="pmAccordionContent scrollS" slot="content">
            {props.children}
          </div>
        </IonAccordion>
      </IonAccordionGroup>
    </IonRow>
  );
};

export default PMAccordion;
