import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  PointElement,
  LineElement,
  Filler,
  ArcElement,
  TooltipItem,
  ChartDataset,
} from "chart.js";
import { FC, useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { AppContext } from "../../../../context/AppContext/AppContext";
import { UserContext } from "../../../../context/UserContext/userContext";
import ELanguage from "../../../../Enums/ELanguage";
import { ITooltipElementsResult } from "../../../../Interfaces/dataCalculator";
import { IChartBarData } from "../../../../Interfaces/IChartData";
import IChartGrade from "../../../../Interfaces/IChartGrade";
import IDatasetsChart from "../../../../Interfaces/IDatasetsChart";
import IForceFromOrbat from "../../../../Interfaces/IForceFromOrbat";
import ILabel from "../../../../Interfaces/ILabel";
import IPlan from "../../../../Interfaces/IPlan";
import IStationChartData from "../../../../Interfaces/IStationChartData";
import IStationsChart from "../../../../Interfaces/IStationsChart";
import IThreshold from "../../../../Interfaces/IThreshold";
import NotFoundPlan from "../../../../pages/Desktop/NotFound/NotFoundPlan";
import { sortByLabelId, translateString } from "../../../../services/helpers";
import Spinner from "../../Spinner/Spinner";
import "./BarChart.css";
import DatalabelsPlugin from "chartjs-plugin-datalabels";
import { EGraphType } from "../../../../Enums/EGraphType";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
  ArcElement,
  DatalabelsPlugin
);
const thresholdId = 0;
interface IProps {
  chartData?: IChartBarData | undefined;
  thresholds?: IThreshold[] | undefined;
  labels?: ILabel[] | undefined;
  barChartData?: any[] | undefined;
  selectedForces?: (IForceFromOrbat | undefined)[] | undefined;
  newForces?: (IForceFromOrbat | undefined)[] | undefined;
  forcesToRemove?: IForceFromOrbat[] | undefined;
  barsType: string;
  id?: string;
  className?: string;
  isModal?: boolean;
  color?: string;
  isDesktop?: boolean;
  setDataForMobileLabels?: (data: IDatasetsChart[]) => void;
  plan: IPlan | undefined;
  onBarClick?: (
    plan: IPlan | undefined,
    forceId: number,
    trainingTypeId: number,
    forceName: string,
    trainingTypeName: string
  ) => void;
  trends?: boolean;
  isDisableZoom?: boolean;
  lineOrScatterTypeToggle?: EGraphType;
}
const PLANS = "plans";
const TRAINING_TYPES = "trainingTypes";
const notAttendedFontColor = "#FF3D3D";
const attendedFontColor = "#ffffff";

const BarChart: FC<IProps> = (props: IProps): JSX.Element => {
  const history = useHistory();

  const {
    thresholds,
    labels,
    barChartData,
    selectedForces,
    forcesToRemove,
    barsType,
    isModal,
    color,
    setDataForMobileLabels,
    isDesktop,
    plan,
    onBarClick,
    lineOrScatterTypeToggle,
  } = props;

  const { t, i18n } = useTranslation();

  const [data, setData] = useState<any>({
    labels: [],
    datasets: [],
  });
  const [grades, setGrades] = useState<IChartGrade[]>();
  const [loading, setLoading] = useState<boolean>(true);
  let allGrades: IChartGrade[] = [];
  const { isAsDesktop } = useContext(AppContext);

  const isMobile: boolean = !isAsDesktop;
  useEffect(() => {
    let timer = setTimeout(() => setLoading(false), 500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (isMobile && setDataForMobileLabels) {
      setDataForMobileLabels([...data.datasets]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const initializeThresholdAndLabels = (prev: any) => {
    let newData: IChartBarData = { ...prev };
    let sortedLabels = labels?.map((l) => ({ ...l, label: t(l.label) }));
    let sortedThresholds = sortByLabelId(thresholds, labels);
    sortedThresholds = sortedThresholds.filter((element: any) =>
      labels?.find((label) => Number(label.id) === Number(element.id))
    );
    newData["labels"] = sortedLabels?.map(
      (labelItem: ILabel) => labelItem.label
    );
    newData.datasets.splice(0);
    newData.datasets = [
      {
        order: thresholdId,
        label: t("thresholdForOperationalIndicators"),
        borderWidth: 3.5,
        backgroundColor: "rgb(0,176,117)",
        hoverBackgroundColor: "rgb(0,176,117)",
        borderColor: "rgb(0,176,117)",
        hoverBorderColor: "rgb(0,176,117)",
        hoverBorderWidth: 4,
        type: lineOrScatterTypeToggle == EGraphType.line ? "line" : "scatter",
        pointStyle:
          lineOrScatterTypeToggle == EGraphType.line ? "circle" : "line", //circle
        data: sortedThresholds?.map(
          (thresholdItem: IThreshold) => thresholdItem.threshold
        ),
      },
      ...newData.datasets,
    ];

    return { ...newData };
  };
  const getGrades = (barChartData: any[] | undefined) => {
    barChartData?.map((element: IStationsChart) => {
      let sortedData = sortByLabelId(element.gradesArray, labels);
      sortedData = sortedData?.filter((element: IStationsChart) =>
        labels?.find((label) => Number(label.id) === Number(element.id))
      );

      allGrades.push({
        id: element.id,
        grades: sortedData?.length
          ? sortedData?.map((station: IStationChartData) => station.grade)
          : labels?.map(() => 0),
        colors: sortedData?.length
          ? sortedData?.map((station: IStationChartData) =>
              station.isAttended || Number(station.grade) !== 0
                ? attendedFontColor
                : notAttendedFontColor
            )
          : labels?.map(() =>
              element.isAttended && !sortedData?.length
                ? attendedFontColor
                : notAttendedFontColor
            ),
      });

      return element.gradesArray?.map(
        (station: IStationChartData) => station.grade
      );
    });
    return allGrades;
  };
  useEffect(() => {
    if (thresholds && labels) {
      setData((prev: any) => initializeThresholdAndLabels(prev));
    }
  }, [thresholds, labels, lineOrScatterTypeToggle]);

  useEffect(() => {
    if (selectedForces) {
      setGrades(() => getGrades(barChartData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barChartData, selectedForces, labels, lineOrScatterTypeToggle]);

  useEffect(() => {
    if (grades?.length) {
      setData((prev: IChartBarData) => {
        let datasets: ChartDataset<"bar" | "scatter" | "line">[] = [
          prev.datasets[0],
        ];
        grades.forEach((gardeObject) => {
          let force = selectedForces?.find(
            (force) => Number(force?.id) === Number(gardeObject.id)
          );
          if (force) {
            let forceData = grades
              .find(
                (currentGrade) => Number(currentGrade.id) === Number(force!.id)
              )
              ?.grades?.map((grade) => Math.round(grade));

            datasets.push({
              type: lineOrScatterTypeToggle,
              order: force!.id,
              label: force!.name,
              backgroundColor: isModal === true && color ? color : force!.color,
              hoverBackgroundColor:
                isModal === true && color ? color : force!.color,
              borderColor: isModal === true && color ? color : force!.color,
              hoverBorderColor:
                isModal === true && color ? color : force!.color,

              data: forceData || [],
            });
          }
        });

        return { ...prev, datasets: [...datasets] };
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grades, selectedForces]);

  useEffect(() => {
    if (forcesToRemove?.length) {
      setData((prev: IChartBarData) => {
        let datasets = prev.datasets.filter(
          (forceElement) =>
            Number(forceElement.order) !==
            Number(
              forcesToRemove.find(
                (forceToRemove: IForceFromOrbat) =>
                  Number(forceToRemove.id) === Number(forceElement.order)
              )?.id
            )
        );
        return {
          ...prev,
          datasets: [...datasets],
        };
      });
    }
  }, [forcesToRemove]);

  const clickHandler = (event: any, elements: any) => {
    let planId;
    let index: number = elements[0]?.index;
    if (index !== undefined && labels !== undefined) {
      planId = labels[index].id;
    }

    if (barsType === PLANS && isDesktop && planId) {
      history.push({
        pathname: "/performance/stations",
        state: {
          data: planId,
        },
      });
    } else if (barsType === TRAINING_TYPES && isDesktop && planId) {
      const dataset = data.datasets[elements[0].datasetIndex];
      const label = labels ? labels[index] : undefined;
      if (onBarClick && dataset.id !== 0 && label)
        onBarClick(
          plan,
          dataset.order,
          Number(label?.id!),
          dataset.label,
          label?.label!
        );
    }
  };

  const barThickness = isMobile ? 12 : 24;
  const options: ChartOptions<"bar"> = {
    datasets: {
      bar: {
        borderRadius: 4,
        maxBarThickness: barThickness,
        categoryPercentage: 0.8,
        barPercentage: 0.7,
      },
    },
    layout: {
      padding: {
        top: 0,
        bottom: 0,
        left: 10,
        right: 10,
      },
    },
    events: ["click", "mousemove"],
    onClick: clickHandler,
    responsive: true,

    scales: {
      y: {
        min: 0,
        max: 100,
        border: {
          display: false,
        },
        grid: {
          color: "rgba(205,205,205,0.3)",
        },
        ticks: {
          minRotation: 0,
          color: "rgba(255, 255, 255, 0.89)",
          autoSkip: true,
          stepSize: 10,
        },
      },

      x: {
        offset: lineOrScatterTypeToggle === "bar" || !lineOrScatterTypeToggle, // Make sure the line starts at the first bar

        grid: {
          display: false,
          drawOnChartArea: false,
        },
        border: {
          display: false,
        },
        ticks: {
          minRotation: 0,
          font: { family: "SemiBold", size: isMobile ? 10 : 18 },
          color: "#DDDDDD",
          stepSize: 10,
          padding: 20,
          major: {
            enabled: true,
          },
        },
      },
    },
    plugins: {
      tooltip: {
        backgroundColor: "#36383E",
        titleColor: "rgba(255,255,255,0.89)",
        bodyColor: "rgba(255,255,255,0.89)",
        footerColor: "rgba(255,255,255,0.89)",
        footerFont: { size: 12, family: "Light" },
        footerAlign: t("startTooltipAlign"),
        titleAlign: t("startTooltipAlign"),
        titleFont: { size: isMobile ? 12 : 18, family: "Light" },
        displayColors: false,
        mode: "nearest",
        bodyAlign: t("startTooltipAlign"),
        callbacks: {
          footer(tooltipItems: TooltipItem<"bar">[]) {
            let forceId = tooltipItems[0].dataset.order;
            let currentTrainingTypeTooltip: string = "";
            let forceData = barChartData?.find(
              (forceData: IStationsChart) =>
                Number(forceData.id) === Number(forceId)
            );
            let currTrainingType = labels?.find(
              (label) => label.label === tooltipItems[0].label
            );
            let trainingType = forceData?.gradesArray?.find(
              (trainingType: any) =>
                +trainingType.id === Number(currTrainingType?.id)
            );
            if (trainingType?.tooltipResults?.standardDeviation !== undefined)
              currentTrainingTypeTooltip = `${t("avgGrade")}: ${
                tooltipItems[0].formattedValue
              }\n${t("standardDeviation")}: ${
                trainingType?.tooltipResults?.standardDeviation
              }`;

            return tooltipItems[0].datasetIndex === 0
              ? ""
              : barsType === TRAINING_TYPES
              ? currentTrainingTypeTooltip
              : "";
          },

          label(tooltipItem: TooltipItem<"bar" | "scatter">) {
            if (tooltipItem.dataset.type === "scatter")
              return `${t(tooltipItem.dataset.label)}: ${
                tooltipItem.dataset.data[tooltipItem.dataIndex]
              }`;
            let forceId = tooltipItem.dataset.order;

            let forceData = barChartData?.find(
              (forceData: IStationsChart) =>
                Number(forceData.id) === Number(forceId)
            );

            let currTrainingType = labels?.find(
              (label) => label.label === tooltipItem.label
            );
            let trainingTypeTooltips: ITooltipElementsResult[] | undefined =
              forceData?.gradesArray?.find(
                (trainingType: any) =>
                  +trainingType.id === Number(currTrainingType?.id)
              )?.tooltipResults?.tooltipElementsResults;

            let currentTrainingTypeTooltip: string[] = [];
            let isTab = false;
            if (trainingTypeTooltips) {
              trainingTypeTooltips?.forEach((tooltip) => {
                if (tooltip.value !== undefined)
                  if (tooltip.value !== undefined) {
                    if (tooltip.value !== null) {
                      let value =
                        tooltip.value === null
                          ? t("noValue")
                          : translateString(tooltip.value, t);

                      currentTrainingTypeTooltip.push(
                        `${isTab ? "   " : ""}${t(tooltip.name)} : ${value}`
                      );
                    }
                  } else {
                    isTab = true;
                    if (tooltip.name === "") {
                      currentTrainingTypeTooltip.push(t(tooltip.name));
                      isTab = false;
                    } else
                      currentTrainingTypeTooltip.push(t(tooltip.name) + ":");
                  }
              });
            }
            return tooltipItem.datasetIndex === 0
              ? tooltipItem.label +
                  ": " +
                  translateString(tooltipItem.formattedValue, t)
              : barsType === TRAINING_TYPES
              ? currentTrainingTypeTooltip
              : isMobile
              ? ""
              : t("presentDetails");
          },
        },
      },
      legend: {
        display: !isMobile,
        rtl: i18n.language === ELanguage.he,
        position: "bottom",
        reverse: true,
        fullSize: false,
        labels: {
          boxWidth: 25,
          color: "rgba(255, 255, 255, 0.89)",
          padding: 10,
          filter: (item) => {
            return item.datasetIndex !== 0;
          },
          pointStyle: "circle",
          usePointStyle: true,
          boxHeight: 10 + 3.5,
          font: {
            size: isModal === true ? 14 : 20,
            family: "Regular",
          },
        },
        title: {
          display: false,
        },
      },
      datalabels: {
        anchor: "end",
        align: "top",
        color: (context) => {
          let forceId = context.dataset.order;
          let forceData = barChartData?.find(
            (forceData) => Number(forceData.id) === Number(forceId)
          );

          let sortedData = sortByLabelId(forceData?.gradesArray, labels);
          sortedData = sortedData?.filter((element: IStationsChart) =>
            labels?.find((label) => Number(label.id) === Number(element.id))
          );

          let isAttended =
            sortedData &&
            context?.dataIndex !== undefined &&
            sortedData[context?.dataIndex]?.isAttended;
          return !isAttended ? "red" : "#DDDDDD";
        },
        textAlign: "center",
        font: {
          size: isMobile ? 10 : 14,
          family: "Regular",
        },

        offset(context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index] !== 100 ? 0 : -20;
          return value;
        },
        padding: 0,
        clamp: true,
        display: (context) => {
          return context.dataset.order !== thresholdId;
        },
      },
    },
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: lineOrScatterTypeToggle == "line" ? 3 : 17,
        hoverRadius: lineOrScatterTypeToggle == "line" ? 4 : 18,
      },
    },
  };

  return (
    <div className={`bar-component ${props.className}`} id={props.id}>
      {loading ? (
        <Spinner />
      ) : labels?.length !== 0 ? (
        <div className="barChartWrap scrollM" style={{ height: "100%" }}>
          <div
            style={
              isDesktop
                ? {
                    minWidth: isDesktop
                      ? `${
                          Number(data?.labels?.length) *
                          Number(data?.datasets.length) *
                          (barThickness + 10)
                        }px`
                      : "100%",
                    height: "100%",
                  }
                : { width: "100%", height: "100%" }
            }
          >
            <Bar data={data} height={160} options={options} />
          </div>
        </div>
      ) : plan ? (
        <NotFoundPlan text={t("notFoundPlanText")} />
      ) : (
        <NotFoundPlan text={t("noActivePlanSelected")} />
      )}
    </div>
  );
};

export default BarChart;
