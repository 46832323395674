import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import IPlan from "../../Interfaces/IPlan";
import { AppState } from "../../redux/store/plansStore";
import { IPlansReducer } from "../../redux/reducers/plans";
import { useSelector } from "react-redux";
import { usePlanValuesForSelectedPlan } from "./HooksWithRQ/usePlanValuesForSelectedPlan";

const usePlans = (
  relevantPlanIds?: number[],
  isMustHaveSelectedPlan: boolean = true,
  isToDisplayAllPlans: boolean = false
) => {
  const [selectedPlan, setSelectedPlan] = useState<IPlan | undefined>();

  const { t } = useTranslation();
  const plansState = useSelector<AppState, IPlansReducer>(
    (state) => state.plans
  );

  const { plansValuesForSelectedPlan } = usePlanValuesForSelectedPlan(
    selectedPlan?.id,
    selectedPlan?.allPlans
  );
  const [plansOptions, setPlansOptions] = useState<IPlan[]>([]);
  const [globalPlans, setGlobalPlans] = useState<IPlan[]>([]);
  const [dropdownPlans, setDropdownPlans] = useState<IPlan[]>([]);
  const [plansToDisplay, setPlansToDisplay] = useState<IPlan[]>([]);
  const [activePlan, setActivePlan] = useState<IPlan | null>(null);

  const onPlanSelectedHandler = (planOption: IPlan | undefined) => {
    if (!planOption) setSelectedPlan(undefined);
    else
      setSelectedPlan(() => {
        let selectedPlan = dropdownPlans.find(
          (plan: IPlan) => +plan.id === +planOption.id
        );

        if (!selectedPlan && isMustHaveSelectedPlan) {
          selectedPlan = dropdownPlans[0];
        }

        return selectedPlan;
      });
  };

  useEffect(() => {
    setPlansOptions(dropdownPlans);

    if (isMustHaveSelectedPlan) {
      const activePlan = dropdownPlans.find((plan: IPlan) => plan.isActive);
      setSelectedPlan(activePlan || dropdownPlans[0]);
    }
  }, [dropdownPlans, t, isMustHaveSelectedPlan]);

  useEffect(() => {
    setGlobalPlans(plansToDisplay.filter((plan: IPlan) => plan.isGlobal));

    setDropdownPlans(
      plansToDisplay.filter(
        (plan: IPlan) => !plan.allPlans || isToDisplayAllPlans
      )
    );
    const plan = plansToDisplay.find((plan: IPlan) => plan.isActive);

    if (plan) {
      setActivePlan(plan);
    }
    // eslint-disable-next-line
  }, [plansToDisplay, isToDisplayAllPlans]);

  useEffect(() => {
    let relevantPlans = dropdownPlans;
    if (relevantPlanIds && relevantPlanIds.length) {
      relevantPlans = relevantPlans.filter((plan: IPlan) =>
        relevantPlanIds.includes(plan.id)
      );

      if (isMustHaveSelectedPlan) {
        setSelectedPlan((prev) =>
          prev
            ? relevantPlanIds.includes(prev.id)
              ? prev
              : relevantPlans[0]
            : relevantPlans[0]
        );
      }

      setPlansOptions(relevantPlans);
    }
  }, [
    selectedPlan,
    dropdownPlans,
    relevantPlanIds,
    isMustHaveSelectedPlan,
    isToDisplayAllPlans,
    t,
  ]);

  useEffect(() => {
    if (plansState.plans) {
      setPlansToDisplay(
        plansState.plans.sort((a, b) => {
          if (Number(b.isGlobal) !== Number(a.isGlobal)) {
            return Number(b.isGlobal) - Number(a.isGlobal);
          }
          return a.id - b.id;
        })
      );
    }
    // eslint-disable-next-line
  }, [plansState.plans]);

  return {
    activePlan,
    plansOptions,
    selectedPlan,
    onPlanSelectedHandler,
    globalPlans,
    plans: plansToDisplay,
    plansValuesForSelectedPlan,
  };
};

export default usePlans;
