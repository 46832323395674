// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.exportImportTranslationContainer {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  justify-content: space-around;
  align-items: center;
}

.exportImportWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/ImportTranslationsFile/ImportTranslationsFile.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAO;EACP,6BAA6B;EAC7B,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,WAAW;AACb","sourcesContent":[".exportImportTranslationContainer {\n  display: flex;\n  flex-direction: row;\n  flex: 1;\n  justify-content: space-around;\n  align-items: center;\n}\n\n.exportImportWrap {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
