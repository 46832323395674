// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bar-component {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 20px;
}
.barChartWrap {
  display: block;
  position: static;
  width: 100%;
  overflow-x: auto;
  height: 100%;
  direction: ltr;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/Charts/Bar/BarChart.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,aAAa;AACf;AACA;EACE,cAAc;EACd,gBAAgB;EAChB,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,cAAc;AAChB","sourcesContent":[".bar-component {\n  height: 100%;\n  width: 100%;\n  position: relative;\n  padding: 20px;\n}\n.barChartWrap {\n  display: block;\n  position: static;\n  width: 100%;\n  overflow-x: auto;\n  height: 100%;\n  direction: ltr;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
