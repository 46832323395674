import { IonCol, IonLabel, IonRow } from "@ionic/react";
import React, { Dispatch, useContext, useEffect, useState } from "react";
import { IElement } from "../../../Interfaces/ITrainingPlan";
import SmallInput from "./SmallInput";
import "./Element.css";
import { useDispatch, useSelector } from "react-redux";
import { IPlansReducer } from "../../../redux/reducers/plans";
import { AppState } from "../../../redux/store/plansStore";
import { useTranslation } from "react-i18next";
import Checkbox from "../../Shared/Checkbox/Checkbox";
import { units, validateInput } from "../DataPosting/inputValidations";
import { IElementsPlanValue } from "../../../Interfaces/IPlanValues";
import { EDIT_ELEMENT } from "../../../redux/actions/trainingPlanActions";
import useCheckSumErrors from "../../CustomHooks/useCheckSumErrors";
import { userRoles } from "../../../services/routeRoles";
import { UserContext } from "../../../context/UserContext/userContext";
import { ITrainingPlanProps } from "../../../Interfaces/TrainingPlanContext/ITrainingPlan";
import { commanderDashboardMaximumElementsForIndicator } from "../../../Configurations/consts";
import PMTooltip from "../PMTooltip/PMTooltip";
interface IElementProps extends ITrainingPlanProps {
  element: IElement;
}

const Element: React.FC<IElementProps> = (props: IElementProps) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const updateElement = (element: IElementsPlanValue) => {
    dispatch({ type: EDIT_ELEMENT, element: element });
  };
  const { user } = useContext(UserContext);
  const [isDashboardElementsAvailable, setIsDashboardElementAvailable] =
    useState<boolean>();
  const [element, setElement] = useState<IElement>();
  const { t } = useTranslation();
  const { errors, setErrors } = useCheckSumErrors(
    props.setIsErrorExist,
    element
  );
  const editMode = useSelector<AppState, IPlansReducer>(
    (state) => state.plans
  ).editMode;
  const plan = useSelector<AppState, IPlansReducer>(
    (state) => state.plans
  ).selectedPlan;

  useEffect(() => {
    setElement(props.element);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.element]);

  const onChangeElementDetailsHandler: Function = async (
    field: string,
    value: string,
    units: string
  ) => {
    setErrors((prev: any) => ({
      ...prev,
      [field]: validateInput(value, units),
    }));
    setElement((prev) =>
      prev
        ? {
            ...prev,
            [field]: value,
          }
        : { ...element!, [field]: value }
    );
  };
  useEffect(() => {
    //update the redux state when the element changes(not just on blur)
    element && updateElement(element);
  }, [element]);

  useEffect(() => {
    //if the indicator's elements for dashboard array includes the elements name or the elements array < maximum this element is valid
    if (element)
      setIsDashboardElementAvailable(
        props.elementsForDashboard.includes(element!.name) ||
          props.elementsForDashboard.length <
            commanderDashboardMaximumElementsForIndicator
      );
  }, [
    props.elementsForDashboard,
    commanderDashboardMaximumElementsForIndicator,
    element,
  ]);

  return element ? (
    <IonCol size="12">
      <IonRow className="elementsHeadersRow">
        <IonCol size="3.05" className="elNameCol">
          <PMTooltip id={`${element.id}`} />

          <div
            data-tooltip-id={`${element.id}`}
            data-tooltip-content={t(element.description)}
          >
            <IonLabel className="elementName">
              {t(element?.name?.trim())}
            </IonLabel>
          </div>
        </IonCol>
        <IonCol size="3.1">
          <SmallInput
            inputValue={element?.weight}
            inputType="number"
            length="smallLong"
            onChange={(field: string, value: string) =>
              onChangeElementDetailsHandler(field, value, units.Weight)
            }
            inputName="weight"
            isPercentageInput
            isDisabled={
              !editMode ||
              props.element.viewOnly ||
              props.element.isOnlyForDashboard
            }
            cssClass={`tpSmallInput ${
              errors.weight?.length ? "tpInputError" : null
            }`}
          ></SmallInput>
        </IonCol>
        <IonCol size="1.3">
          <SmallInput
            cssClass={`tpSmallInput ${
              errors.lowerThreshold?.length ? "tpInputError" : null
            }`}
            inputValue={element?.lowerThreshold}
            inputType="number"
            length="smallShort"
            onChange={(field: string, value: string) =>
              onChangeElementDetailsHandler(field, value, element.units)
            }
            inputName="lowerThreshold"
            units={props.element.units}
            isDisabled={!editMode}
          ></SmallInput>
        </IonCol>
        <IonCol size="1.3">
          <SmallInput
            cssClass={`tpSmallInput ${
              errors.upperThreshold?.length ? "tpInputError" : null
            }`}
            inputValue={element?.upperThreshold}
            inputType="number"
            length="smallShort"
            onChange={(field: string, value: string) =>
              onChangeElementDetailsHandler(field, value, element.units)
            }
            inputName="upperThreshold"
            units={props.element.units}
            isDisabled={!editMode}
          ></SmallInput>
        </IonCol>
        <IonCol size="1.3">
          <SmallInput
            cssClass={`tpSmallInput ${
              errors.requiredThreshold?.length ? "tpInputError" : null
            }`}
            inputValue={element?.requiredThreshold}
            inputType="number"
            length="smallShort"
            onChange={(field: string, value: string) =>
              onChangeElementDetailsHandler(field, value, element.units)
            }
            inputName="requiredThreshold"
            units={props.element.units}
            isDisabled={!editMode}
          ></SmallInput>
        </IonCol>
        <IonCol size="1" className="checkboxHeader checkboxRow">
          <div className="isForDashboardCheckbox">
            <Checkbox
              color={
                //for the prevues training plan color the checkbox in red to emphasize the error
                !isDashboardElementsAvailable && element.isForDashboard
                  ? "red"
                  : undefined
              }
              isDisabled={
                !plan?.isEditable ||
                (!editMode && user.role !== userRoles.Admin) ||
                //if all elements for dashboard are taken disable the element
                (!isDashboardElementsAvailable && !element.isForDashboard)
              }
              isChecked={element?.isForDashboard}
              onChange={(e) => {
                if (e && e.detail)
                  onChangeElementDetailsHandler(
                    "isForDashboard",
                    e.detail.checked,
                    "null"
                  );
              }}
            ></Checkbox>
          </div>
        </IonCol>
      </IonRow>
      <div className="elementBorder"></div>
    </IonCol>
  ) : null;
};

export default Element;
