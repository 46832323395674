import { Dispatch, FC, SetStateAction, useState } from "react";
import "./ObserverComponent.css";
import { IonRow } from "@ionic/react";
import NavigationRow from "../NavigationRow";
import SegmentsMenu from "../../../../pages/Mobile/DataHistory/SegmentsMenu";
import { ITabOptions } from "../../../../Interfaces/IDataHistory";
import SettingStation from "./SettingStation";
import TargetAcquisition from "./TargetAcquisition";
import ConductingQuality from "./ConductingQuality";
import WorkRoutine from "./WorkRoutine";
import OpenAndCover from "./OpenAndCover";
import {
  IOpenAndCover,
  IWorkRoutine,
  ISettingStation,
  IConductingQuality,
  ITargetAcquisition,
} from "../../../../Interfaces/Gorilla/IObserver";
import MainNotesTab from "./MainNotesTab";
import PMLabel from "../../../themeComponents/PMLabel";
import { INote } from "../../../../Interfaces/Gorilla/GorillaTrainings";

interface IProps {
  endTrainingHandler: () => void;
  openAndCover: IOpenAndCover;
  setWorkRoutine: Dispatch<SetStateAction<IWorkRoutine>>;
  workRoutine: IWorkRoutine;
  settingStation: ISettingStation;
  conductingQuality: IConductingQuality;
  targetAcquisition: ITargetAcquisition;
  setTargetAcquisition: Dispatch<SetStateAction<ITargetAcquisition>>;
  setOpenAndCover: Dispatch<SetStateAction<IOpenAndCover>>;
  setSettingStation: Dispatch<SetStateAction<ISettingStation>>;
  setConductingQuality: Dispatch<SetStateAction<IConductingQuality>>;
  mainNotes: INote[];
  addNote: (note: string) => void;
  deleteNote: (index: number) => void;
  editNote: (index: number, note: string) => void;
  traineeName: string | undefined;
  isTrainingEnd: boolean;
}
interface ISegment extends ITabOptions {
  component: () => JSX.Element;
}
const ObserverComponent: FC<IProps> = (props: IProps): JSX.Element => {
  const {
    endTrainingHandler,
    openAndCover,
    setWorkRoutine,
    workRoutine,
    settingStation,
    conductingQuality,
    targetAcquisition,
    setTargetAcquisition,
    setOpenAndCover,
    setSettingStation,
    setConductingQuality,
    isTrainingEnd,
    mainNotes,
    addNote,
    deleteNote,
    editNote,
  } = props;

  const segmentsOptions: ISegment[] = [
    {
      tabId: 1,
      title: "SettingStation",
      url: "observer/SettingStation",
      component: () => (
        <SettingStation
          settingStation={settingStation}
          setSettingStation={setSettingStation}
        ></SettingStation>
      ),
    },
    {
      tabId: 2,
      title: "targetAcquisition",
      url: "observer/TargetAcquisition",
      component: () => (
        <TargetAcquisition
          setTargetAcquisition={setTargetAcquisition}
          targetAcquisition={targetAcquisition}
        ></TargetAcquisition>
      ),
    },
    {
      tabId: 3,
      title: "ConductingQuality",
      url: "observer/ConductingQuality",
      component: () => (
        <ConductingQuality
          conductingQuality={conductingQuality}
          setConductingQuality={setConductingQuality}
        ></ConductingQuality>
      ),
    },
    {
      tabId: 4,
      title: "workRoutine",
      url: "observer/workRoutine",
      component: () => (
        <WorkRoutine
          setWorkRoutine={setWorkRoutine}
          workRoutine={workRoutine}
        ></WorkRoutine>
      ),
    },
    {
      tabId: 5,
      title: "openAndCover",
      url: "observer/openAndCover",
      component: () => (
        <OpenAndCover
          openAndCover={openAndCover}
          setOpenAndCover={setOpenAndCover}
        ></OpenAndCover>
      ),
    },
    {
      tabId: 6,
      title: "mainNotes",
      url: "observer/mainNotes",
      component: () => (
        <MainNotesTab
          endTrainingHandler={endTrainingHandler}
          mainNotes={mainNotes}
          deleteNote={deleteNote}
          editNote={editNote}
          addNote={addNote}
          isTrainingEnd={isTrainingEnd}
        ></MainNotesTab>
      ),
    },
  ];
  const [selectedSegment, setSelectedSegment] = useState<ISegment | undefined>(
    segmentsOptions[0]
  );

  return (
    <>
      <div className="headerGorilla">
        <PMLabel
          fontColor="light"
          fontFamily="Bold"
          fontSize="xLarge"
          cssClass="traineeNameSG"
        >
          {props.traineeName}
        </PMLabel>
        <IonRow className="segmentMenuRow">
          <SegmentsMenu
            segmentsOptions={segmentsOptions}
            selectedSegment={selectedSegment}
            setSelectedSegment={setSelectedSegment}
          />
        </IonRow>
      </div>
      <div className="contentObserver" key={selectedSegment?.tabId}>
        {segmentsOptions
          .find((segment) => segment.tabId === selectedSegment?.tabId || 0)
          ?.component()}
      </div>
      <div className="footer">
        {selectedSegment?.tabId !==
        segmentsOptions[segmentsOptions.length - 1].tabId ? (
          <NavigationRow
            isPrevHidden={selectedSegment?.tabId === 1}
            isNextHidden={selectedSegment?.tabId === segmentsOptions.length}
            onNextHandler={() => {
              setSelectedSegment((prev) => {
                return prev
                  ? segmentsOptions.find(
                      (segment) => segment.tabId === prev?.tabId + 1
                    )
                  : segmentsOptions[0];
              });
            }}
            onPrevHandler={() => {
              setSelectedSegment((prev) => {
                return prev
                  ? segmentsOptions.find(
                      (segment) => segment.tabId === prev?.tabId - 1
                    )
                  : segmentsOptions[0];
              });
            }}
          ></NavigationRow>
        ) : null}
      </div>
    </>
  );
};

export default ObserverComponent;
