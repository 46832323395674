// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.red {
  background: var(--ion-color-danger);
}

.green {
  background: var(--ion-color-success);
}

.dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-inline-end: 10px;
}

.onlineBadge {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 22vw;
  justify-content: flex-start;
}

.lastUpdateLabel {
  margin-inline-start: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/OnlineBadge/OnlineBadge.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;AACrC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,qBAAqB;EACrB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,WAAW;EACX,2BAA2B;AAC7B;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".red {\n  background: var(--ion-color-danger);\n}\n\n.green {\n  background: var(--ion-color-success);\n}\n\n.dot {\n  height: 12px;\n  width: 12px;\n  border-radius: 50%;\n  display: inline-block;\n  margin-inline-end: 10px;\n}\n\n.onlineBadge {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  width: 22vw;\n  justify-content: flex-start;\n}\n\n.lastUpdateLabel {\n  margin-inline-start: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
