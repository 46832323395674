import React from "react";
import { FC } from "react";
import "./InputWithError.css";
interface IProps {
  text: string;
  error: string;
}
const errorClassName = "cellInputValue";
const InputWithError: FC<IProps> = (props: IProps) => {
  return (
    <div className={"cellInput"}>
      <span className="cellInputValue">
        <p>{props.text}</p>
      </span>
      <span className="cellInputError">
        {/* //!mast be cellInputError!!! */}
        <p>{props.error}</p>
      </span>
    </div>
  );
};
export { InputWithError, errorClassName };
