import React, { ReactNode, useState } from "react";
import IWeapon from "../../Interfaces/IWeapon";

interface IWeaponsContext {
  weapons: IWeapon[];
  setWeapons: React.Dispatch<React.SetStateAction<IWeapon[]>>;
}

export const WeaponsContext = React.createContext<IWeaponsContext>({
  weapons: [],
  setWeapons: () => {},
});

const WeaponsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [weapons, setWeapons] = useState<IWeapon[]>([]);

  return (
    <WeaponsContext.Provider
      value={{
        setWeapons,
        weapons,
      }}
    >
      {children}
    </WeaponsContext.Provider>
  );
};

export default WeaponsProvider;
