import React, { useEffect, useState } from "react";
import "./Alert.css";

import { IonAlert } from "@ionic/react";
import { useTranslation } from "react-i18next";

interface IProps {
  header: string;
  subHeader?: string;
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  actionOnSave: () => void;
  actionOnCancel?: () => void;
  actionText?: string;
}

const Alert: React.FC<IProps> = (props: IProps) => {
  const {
    actionOnSave,
    header,
    isOpen,
    setIsOpen,
    actionOnCancel,
    actionText,
    subHeader,
  } = props;
  const { t } = useTranslation();
  const [buttons, setButtons] = useState<any>([]);
  const buttons1 = [
    {
      text: actionText ? t(actionText) : t("OK"),
      cssClass: "save-batton",
      handler: () => {
        actionOnSave();
      },
    },
  ];
  const buttons2 = [
    {
      text: t("cancel"),
      role: "cancel",
      cssClass: "cancel-button",
      handler: () => {
        setIsOpen(false);
        if (actionOnCancel !== undefined) actionOnCancel();
      },
    },
    {
      text: actionText ? t(actionText) : t("OK"),
      cssClass: "save-batton",
      handler: () => {
        actionOnSave();
      },
    },
  ];

  useEffect(() => {
    if (actionOnCancel !== undefined) setButtons(buttons2);
    else setButtons(buttons1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionOnCancel]);

  return (
    <IonAlert
      isOpen={isOpen}
      header={header}
      onDidDismiss={() => setIsOpen(false)}
      subHeader={subHeader}
      cssClass="alert"
      buttons={buttons}
    />
  );
};

export default Alert;
