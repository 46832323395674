// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.traineeItemSqL {
  --background: var(--ion-color-light);
  --border-color: var(--ion-color-disabled);
  --color: var(--ion-color-fontXLight);
  margin-right: 0px;
  margin-left: 0px;
  --inner-padding-end: 3px;
  --inner-padding-start: 3px;
  --padding-start: 3px;
  --padding-end: 0;
}
.greenItem {
  color: #3acb77 !important;
}
.labelSL {
  font-family: "Regular" !important;
}
.selectedLI {
  --background: var(--ion-color-light-tint) !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/SiteManagement/ListItem.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,yCAAyC;EACzC,oCAAoC;EACpC,iBAAiB;EACjB,gBAAgB;EAChB,wBAAwB;EACxB,0BAA0B;EAC1B,oBAAoB;EACpB,gBAAgB;AAClB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,iCAAiC;AACnC;AACA;EACE,oDAAoD;AACtD","sourcesContent":[".traineeItemSqL {\n  --background: var(--ion-color-light);\n  --border-color: var(--ion-color-disabled);\n  --color: var(--ion-color-fontXLight);\n  margin-right: 0px;\n  margin-left: 0px;\n  --inner-padding-end: 3px;\n  --inner-padding-start: 3px;\n  --padding-start: 3px;\n  --padding-end: 0;\n}\n.greenItem {\n  color: #3acb77 !important;\n}\n.labelSL {\n  font-family: \"Regular\" !important;\n}\n.selectedLI {\n  --background: var(--ion-color-light-tint) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
