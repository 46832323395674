// css
import { useEffect } from "react";
import { IonCol, IonRow } from "@ionic/react";
import "./ObserverDesktopResults.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import usePlans from "../../../components/CustomHooks/usePlans";
import PerformanceTemplate from "../PerformanceDisplay/performanceTemplate";
import TrainingTypeResults from "./TrainingTypeResults";
import useOneSelectedForce from "./useOneSelectedForce";
import useGetObserverGrades from "../../../components/CustomHooks/useGetObserverGrades";
import IndicatorFinalGrade from "./IndicatorFinalGrade";
import { useLocation } from "react-router";
import EmptyState from "../../../components/Shared/EmptyState/EmptyState";

const ObserverDesktopResults: React.FC = (): JSX.Element => {
  const { t } = useTranslation();

  const { state } = useLocation<
    | {
        forceId: string;
        planId: string;
      }
    | undefined
  >();

  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<boolean>();

  const { onPlanSelectedHandler, plansOptions, selectedPlan } = usePlans(
    undefined,
    undefined,
    true
  );
  const { selectedForce, onCheckForce, forces } = useOneSelectedForce();

  const { observerForceResults, loading, notes } = useGetObserverGrades(
    selectedPlan,
    forces,
    refresh,
    setRefresh
  );

  useEffect(() => {
    if (state?.planId && state?.forceId) {
      let newSelectedPlan = plansOptions.find(
        (plan) => Number(plan.id) === Number(state?.planId)
      );
      if (newSelectedPlan) onPlanSelectedHandler(newSelectedPlan);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);
  const chart =
    selectedForce[0] && !loading ? (
      <IonRow className="singleGunnerContent scrollL">
        <IndicatorFinalGrade
          grade={observerForceResults?.grade}
          threshold={observerForceResults?.threshold}
          notes={notes}
        />

        <IonCol className="observerCol">
          <IonRow>
            {observerForceResults?.trainingTypeResults
              .sort((a, b) =>
                t(a.name).toLowerCase().localeCompare(t(b.name).toLowerCase())
              )
              .map((trainingType, index) => (
                <TrainingTypeResults
                  key={index}
                  isWithWeight
                  grade={trainingType.grade}
                  threshold={trainingType.threshold}
                  title={trainingType.name}
                  weight={trainingType.weight}
                  resultsArray={trainingType.elementResults
                    .filter((element) => element.grade !== null)
                    .map((element) => ({
                      grade: Math.round(element.grade),
                      successRate: element.successRate,
                      name: element.name,
                      weight: element.weight,
                    }))}
                />
              ))}
          </IonRow>
        </IonCol>
      </IonRow>
    ) : (
      <EmptyState
        text={selectedForce[0] ? "loadingData" : "noSelectedForces"}
      />
    );
  return (
    <div className="gorillaPage">
      <PerformanceTemplate
        selectedForceId={state ? Number(state?.forceId) : undefined}
        key={"dashboard"}
        loadingText={t("loadingData")}
        limit={1}
        chart={chart}
        isDrawerOpen={isDrawerOpen}
        loading={loading}
        orbatForces={selectedForce}
        refreshButtonText={t("refresh")}
        onClickExportButton={() => {
          setRefresh(true);
        }}
        plansOptions={plansOptions}
        selectedPlan={selectedPlan}
        onPlanSelectedHandler={onPlanSelectedHandler}
        setChecked={onCheckForce}
        setDrawerOpen={setDrawerOpen}
        title={`${selectedForce.length ? selectedForce[0]?.name : ""}`}
      ></PerformanceTemplate>
    </div>
  );
};
export default ObserverDesktopResults;
