// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pm-tabs ion-tab-button {
  --color-selected: var(--ion-color-orange);
}
.pm-tabs .tab-selected {
  border-bottom-style: solid;
  border-bottom-color: var(--ion-color-orange);
  border-bottom-width: thin;
}
#main {
  background-color: var(--ion-color-primary);
}
.personalZoneTabs {
  min-height: 60px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Desktop/PersonalZone/PersonalZone.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;AAC3C;AACA;EACE,0BAA0B;EAC1B,4CAA4C;EAC5C,yBAAyB;AAC3B;AACA;EACE,0CAA0C;AAC5C;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".pm-tabs ion-tab-button {\n  --color-selected: var(--ion-color-orange);\n}\n.pm-tabs .tab-selected {\n  border-bottom-style: solid;\n  border-bottom-color: var(--ion-color-orange);\n  border-bottom-width: thin;\n}\n#main {\n  background-color: var(--ion-color-primary);\n}\n.personalZoneTabs {\n  min-height: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
