import React, { Dispatch, SetStateAction } from "react";

import "./SingleGunnerPanel.css";
import PMAccordion from "../../../../Shared/PMAccordion/PMAccordion";
import HeatsMenuDesktop from "../../../../Shared/SPIKEGorilla/HeatsMenuDesktop";
import VerticalSteps from "../../../../Shared/SPIKEGorilla/VerticalSteps";
import { useTranslation } from "react-i18next";
import {
  ESingleGunnerSteps,
  IHeatTemplate,
  ISingleGunnerStep,
} from "../../../../../Interfaces/Gorilla/IHeatTemplate";
import { IonCol, IonRow } from "@ionic/react";
import PMButton from "../../../../themeComponents/PMButton";
import Divider from "../../../../Shared/SPIKEGorilla/Divider";

interface IProps {
  activeHeatId: number;
  currentStepType: ESingleGunnerSteps;
  heatsArray: IHeatTemplate[];
  setActiveHeat: (heatIndex: number) => void;
  setCurrentStepType: React.Dispatch<React.SetStateAction<ESingleGunnerSteps>>;
  singleGunnerSteps: Record<ESingleGunnerSteps, ISingleGunnerStep>;
  resetHeat: (heatIndex: number) => void;
  setActiveHeatId: React.Dispatch<React.SetStateAction<number>>;
  isDisableAddHeats: boolean;
  addHeat: () => void;
  deleteHeat: (heatId: number) => void;
}
const SingleGunnerPanel: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    activeHeatId,
    currentStepType,
    heatsArray,
    setActiveHeat,
    setCurrentStepType,
    singleGunnerSteps,
    addHeat,
    deleteHeat,
    resetHeat,
    setActiveHeatId,
    isDisableAddHeats,
  } = props;

  return (
    <IonCol className="singleGunnerPanel">
      <IonRow className="panelContentWrap scrollS">
        <IonCol className="paddingFree">
          <IonRow>
            <PMAccordion
              isDisableAutoClose
              placeholder={`${t("heat")} ${activeHeatId} (${t("outOf")} ${
                heatsArray.length
              })`}
              children={
                <>
                  <HeatsMenuDesktop
                    heatsArray={heatsArray}
                    activeHeatId={activeHeatId}
                    setActiveHeat={setActiveHeat}
                    resetHeat={(heatIndex) => {
                      if (heatIndex === activeHeatId)
                        setCurrentStepType(ESingleGunnerSteps.SystemEncryption);
                      resetHeat(heatIndex);
                    }}
                    setActiveHeatId={setActiveHeatId}
                    isDisableAddHeats={isDisableAddHeats}
                    addHeat={addHeat}
                    deleteHeat={deleteHeat}
                  ></HeatsMenuDesktop>
                  <IonRow>
                    <IonCol>
                      <Divider isFull />
                    </IonCol>
                  </IonRow>
                </>
              }
            ></PMAccordion>
          </IonRow>

          <IonRow>
            <VerticalSteps
              isToDisplaySteps
              activeStep={currentStepType}
              steps={Object.values(singleGunnerSteps)}
              onClickHandler={(id: any) =>
                (singleGunnerSteps[id as ESingleGunnerSteps].isCompleted ||
                  (singleGunnerSteps[(id - 1) as ESingleGunnerSteps] &&
                    singleGunnerSteps[(id - 1) as ESingleGunnerSteps]
                      .isCompleted)) &&
                setCurrentStepType(id)
              }
            />
          </IonRow>
        </IonCol>
      </IonRow>
    </IonCol>
  );
};

export default SingleGunnerPanel;
