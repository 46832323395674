import { IonPage } from "@ionic/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./Login.css";
import PMButton from "../../../components/themeComponents/PMButton";
import PMInput from "../../../components/Desktop/TrainingPlan/PMInput";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import useLogin from "../../../components/CustomHooks/useLogin";
import MicrosoftLoginButton from "../../../components/Shared/MicrosoftLoginButton/MicrosoftLoginButton";
import { environment } from "../../../Configurations/consts";
import EEnvironment from "../../../Enums/EEnvironment";
import PMLabel from "../../../components/themeComponents/PMLabel";

const Login = () => {
  const [userLockoutTimeout, setUseLockoutTimeout] = useState<any>();

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  // Handles icon click

  const { t } = useTranslation();
  const {
    localSiteLogin,
    loginWithMicrosoft,
    userLockoutTime,
    setUseLockoutTime,
  } = useLogin();

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      localSiteLogin(username, password);
    }
  };
  useEffect(() => {
    let intervalId: any;

    if (userLockoutTime) {
      if (userLockoutTimeout) clearTimeout(userLockoutTimeout);

      intervalId = setInterval(() => {
        setUseLockoutTime((prevTime) => {
          const newTime = Number(prevTime) - 1;
          if (newTime <= 0) {
            clearInterval(intervalId);
          }
          return newTime;
        });
      }, 1000);
    }

    return () => {
      if (userLockoutTimeout) clearTimeout(userLockoutTimeout);
      if (intervalId) clearInterval(intervalId);
    };
  }, [userLockoutTime, userLockoutTimeout]);

  return (
    <IonPage>
      <form className="loginDiv">
        <div className="inputsDiv">
          <PMInput
            inputType={"text"}
            placeholder={t("userName")}
            inputName="userName"
            width="long"
            inputValue={username}
            onChangeHandler={(inputName: string, inputValue: string) => {
              setUsername(inputValue);
            }}
            fontSize="large"
            onKeyPress={handleKeyPress}
          />
          <PMInput
            inputType={"password"}
            placeholder={t("password")}
            inputName="password"
            width="long"
            inputValue={password}
            onChangeHandler={(inputName: string, inputValue: string) => {
              setPassword(inputValue);
            }}
            fontSize="large"
            onKeyPress={handleKeyPress}
          />
        </div>
        <div className="buttonDiv">
          <PMButton
            id="loginButton"
            color="orange"
            isDisabled={!!userLockoutTime}
            label={{ fontColor: "light", fontFamily: "Light" }}
            onClickHandler={() => localSiteLogin(username, password)}
            size="large"
          >
            {t("login")}
          </PMButton>
          {userLockoutTime ? (
            <PMLabel
              fontSize="medium"
              fontColor="light"
              fontFamily="Regular"
            >{`${t("userLockout")}. ${t("remainingTime")}: ${Math.floor(
              userLockoutTime / 60
            )
              .toString()
              .padStart(2, "0")}:${Math.floor(userLockoutTime % 60)
              .toString()
              .padStart(2, "0")}`}</PMLabel>
          ) : (
            ""
          )}
          {environment === EEnvironment.staging ? (
            <MicrosoftLoginButton
              login={() => {
                setPassword("");
                setUsername("");
                loginWithMicrosoft();
              }}
            ></MicrosoftLoginButton>
          ) : null}
        </div>
      </form>
    </IonPage>
  );
};

export default Login;
