// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.magnetGrid {
  height: 100%;
  width: 100vw;
  padding: 24px;
}

.magnetDataRow {
  display: flex;
  height: 430px;
  flex-direction: column;
}

.magnetInputRow {
  height: 60px !important;
  width: 100%;
}

.sendRow {
  display: flex;
  justify-content: center;
  height: fit-content;
}

/* Regular option */
.action-sheet-button.ion-activatable.ion-focusable.action-sheet-.select-interface-option.md.sc-ion-action-sheet-md {
  color: var(--ion-color-fontLight);
}

/* Selected option */
.action-sheet-button.ion-activatable.ion-focusable.action-sheet-selected.select-interface-option.md.sc-ion-action-sheet-md {
  color: var(--ion-color-fontWhite);
}

/* Cancel option */
.action-sheet-button.ion-activatable.ion-focusable.action-sheet-cancel.sc-ion-action-sheet-md {
  color: var(--ion-color-fontLight);
}

.input-cover {
  position: static;
}

.cnt {
  height: 500px !important;
}
/* --keyboard-offset: 0px !important;
  padding-bottom: 0 !important;
  min-height: 100% !important;
  margin: 0 !important;
  padding: 0 !important; */
`, "",{"version":3,"sources":["webpack://./src/components/Mobile/ManualStation/MagnetDataPosting/MagnetFOFComponent.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA,mBAAmB;AACnB;EACE,iCAAiC;AACnC;;AAEA,oBAAoB;AACpB;EACE,iCAAiC;AACnC;;AAEA,kBAAkB;AAClB;EACE,iCAAiC;AACnC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,wBAAwB;AAC1B;AACA;;;;0BAI0B","sourcesContent":[".magnetGrid {\n  height: 100%;\n  width: 100vw;\n  padding: 24px;\n}\n\n.magnetDataRow {\n  display: flex;\n  height: 430px;\n  flex-direction: column;\n}\n\n.magnetInputRow {\n  height: 60px !important;\n  width: 100%;\n}\n\n.sendRow {\n  display: flex;\n  justify-content: center;\n  height: fit-content;\n}\n\n/* Regular option */\n.action-sheet-button.ion-activatable.ion-focusable.action-sheet-.select-interface-option.md.sc-ion-action-sheet-md {\n  color: var(--ion-color-fontLight);\n}\n\n/* Selected option */\n.action-sheet-button.ion-activatable.ion-focusable.action-sheet-selected.select-interface-option.md.sc-ion-action-sheet-md {\n  color: var(--ion-color-fontWhite);\n}\n\n/* Cancel option */\n.action-sheet-button.ion-activatable.ion-focusable.action-sheet-cancel.sc-ion-action-sheet-md {\n  color: var(--ion-color-fontLight);\n}\n\n.input-cover {\n  position: static;\n}\n\n.cnt {\n  height: 500px !important;\n}\n/* --keyboard-offset: 0px !important;\n  padding-bottom: 0 !important;\n  min-height: 100% !important;\n  margin: 0 !important;\n  padding: 0 !important; */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
