import React, { useContext, useEffect, useState } from "react";
import { IonCol, IonRow } from "@ionic/react";
import { useSelector } from "react-redux";
import IIndicatorType from "../../../Interfaces/IIndicatorType";
import { useTranslation } from "react-i18next";
import { ITrainingPlanReducer } from "../../../redux/reducers/trainingPlan";
import { AppState } from "../../../redux/store/plansStore";
import Indicator from "./Indicator";
import "./Indicators.css";
import { IIndicator } from "../../../Interfaces/ITrainingPlan";
import { IErrorState } from "../../CustomHooks/useCheckSumErrors";
import { IndicatorsContext } from "../../../context/IndicatorsContext/IndicatorsContext";

type IProps = {
  indicators: IIndicator[];
  setIsErrorExist: React.Dispatch<React.SetStateAction<IErrorState>>;
};

const Indicators: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();
  const [typeOptions, setTypeOptions] = useState<IIndicatorType[]>([]);

  // Selects reducers
  const indicatorsState = useSelector<AppState, ITrainingPlanReducer>(
    (state) => state.indicators
  );

  const { indicators } = useContext(IndicatorsContext);

  useEffect(() => {
    let options: IIndicatorType[] = indicators.filter(
      (type) =>
        !indicatorsState.indicators?.some(
          (indicator) => +type.id === +indicator.indicatorId
        )
    );

    setTypeOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indicatorsState.indicators, indicators]);

  return !indicatorsState.indicators ? (
    <h1 className="loading">{t("loadingData")}</h1>
  ) : (
    <IonCol size="12" className="indicatorsGrid scrollM">
      {props.indicators
        ?.sort((a, b) => a.indicatorId - b.indicatorId)
        ?.map((indicator, index) => (
          <IonRow key={index} className="indicatorRow">
            <Indicator
              key={indicator.indicatorId}
              indicator={indicator}
              typeOptions={typeOptions}
              setIsErrorExist={props.setIsErrorExist}
            ></Indicator>
          </IonRow>
        ))}
    </IonCol>
  );
};

export default Indicators;
