import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import Axios from "../../../Axios";
import { baseUrlPMBackend } from "../../../Configurations/consts";
import { IndicatorsContext } from "../../../context/IndicatorsContext/IndicatorsContext";
import customToast from "../../Shared/Toast/CustomToast";
import IIndicatorType from "../../../Interfaces/IIndicatorType";
import { AppModeContext } from "../../../context/AppModeContext/AppModeContext";
/**
 *
 * @returns array with of indicators
 */
const asyncFetchIndicators = async () => {
  const res = await Axios.get(`${baseUrlPMBackend}indicators/getIndicators`);
  return res.data;
};

export const useIndicators = () => {
  const { appMode } = useContext(AppModeContext);

  const { t } = useTranslation();
  const { setIndicators } = useContext(IndicatorsContext);

  let query = useQuery<IIndicatorType[]>({
    queryKey: ["indicators", appMode],
    queryFn: asyncFetchIndicators,
    enabled: appMode !== undefined,
    staleTime: Infinity,
  });
  const { data, isLoading, isError, refetch } = query;

  useEffect(() => {
    //when the app mode change fetch the app indicators
    refetch();
  }, [appMode]);

  useEffect(() => {
    if (!isLoading && !isError && data && data?.length) {
      //det the context with the indicators data
      setIndicators(
        data.map((indicator) => ({
          ...indicator,
          id: Number(indicator.id),
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (isError) {
      customToast.error(t("errorWhileLoadingIndicators"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);
};
