import React, { FC } from "react";
import { IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import PMLabel from "../../../../../themeComponents/PMLabel";
import "./AchievementRequired.css";

interface IProps {
  resultType: string;
}

const AchievementRequired: FC<IProps> = (props: IProps): JSX.Element => {
  const { resultType } = props;

  const { t } = useTranslation();
  return (
    <div className="achievement-required">
      <div className="achievement-required-text">
        <IonText className="achievement-required-header">
          {t("achievementRequired")}
        </IonText>
        <PMLabel
          textAlign="center"
          fontFamily="Light"
          fontSize="medium"
          cssClass="result-type"
        >
          {t(resultType)}
        </PMLabel>
      </div>
      <div className="vertical-line"></div>
    </div>
  );
};

export default AchievementRequired;
