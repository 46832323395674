import React, { ReactNode, useState } from "react";
import IElementType from "../../Interfaces/IElementType";
interface IElementContext {
  elements: IElementType[];
  setElements: React.Dispatch<React.SetStateAction<IElementType[]>>;
}

export const ElementsContext = React.createContext<IElementContext>({
  elements: [],
  setElements: () => {},
});

const ElementsContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [elements, setElements] = useState<IElementType[]>([]);

  return (
    <ElementsContext.Provider
      value={{
        elements,
        setElements,
      }}
    >
      {children}
    </ElementsContext.Provider>
  );
};

export default ElementsContextProvider;
