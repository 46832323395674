import { IonRow, IonCol } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EEventType from "../../../../Interfaces/EEventType";
import customToast from "../../Toast/CustomToast";

import "./SPIKEComponent.css";
import { sendDrillToBBackend } from "../../../Desktop/DataPosting/dataPostingHelpers";
import PMInput from "../../../Desktop/TrainingPlan/PMInput";
import ISimpleTrainee from "../../../../Interfaces/ISimpleTrainee";
import SPIKEInput from "./SPIKEInput";
import InputTable from "../../../Desktop/InputTable/InputTable";
import { IHit } from "../../../../Interfaces/ITableData";
interface IProps {
  selectedTrainee: ISimpleTrainee | undefined;
  trainingTypeId: number;
  afterPostHandler?: () => void;
  isSendClicked?: boolean;
  isComplete: boolean;
  setIsComplete: (state: boolean) => void;
  planId: number | undefined;
}

const SPIKEComponent: React.FC<IProps> = (props: IProps): JSX.Element => {
  const {
    selectedTrainee,
    trainingTypeId,
    afterPostHandler,
    isSendClicked,
    setIsComplete,
  } = props;
  const { t } = useTranslation();
  const [trainee, setTrainee] = useState<ISimpleTrainee>();
  const [spikeInputsData, setSpikeInputsData] = useState<{
    hits: number | null;
    doubleHits: number | null;
    outOfSector: number | null;
    missilesFired: number | null;
    correctWarheadSelection: number | null;
    correctTrajectorySelection: number | null;
    hitsTable: {
      staticTarget: IHit | null;
      movingTarget: IHit | null;
      NLOSTarget: IHit | null;
      nightTarget: IHit | null;
      manualTarget: IHit | null;
    };
  }>({
    hits: null,
    doubleHits: null,
    outOfSector: null,
    missilesFired: null,
    correctWarheadSelection: null,
    correctTrajectorySelection: null,
    hitsTable: {
      staticTarget: null,
      movingTarget: null,
      NLOSTarget: null,
      nightTarget: null,
      manualTarget: null,
    },
  });
  const startDateTime: Date = new Date();

  const isValidData = () => {
    if (
      Number(spikeInputsData.hits) > Number(spikeInputsData.missilesFired) ||
      Number(spikeInputsData.doubleHits) > Number(spikeInputsData.hits) ||
      Number(spikeInputsData.outOfSector) >
        Number(spikeInputsData.missilesFired) - Number(spikeInputsData.hits) ||
      Number(spikeInputsData.correctTrajectorySelection) >
        Number(spikeInputsData.missilesFired) ||
      Number(spikeInputsData.correctWarheadSelection) >
        Number(spikeInputsData.missilesFired) ||
      Object.values(spikeInputsData.hitsTable).find(
        (value) => Number(value?.hits) > Number(value?.shots)
      )
    )
      return false;
    return true;
  };
  /**
   * post the data to b-backend
   */
  const postData = () => {
    if (!trainee) {
      customToast.error(t("noSelectedSoldier"));
      return;
    }
    if (!isValidData()) {
      customToast.error(t("dataInvalid"));
      return;
    }
    const timeStamp: Date = new Date();
    const SPIKEDrill = {
      MessageType: EEventType.SPIKE_OEM_MESSAGE_TYPE,
      TimeStamp: timeStamp,
      TrainingTypeID: trainingTypeId,
      SoldierID: trainee.id,
      DrillStartTime: startDateTime,
      DrillEndTime: timeStamp,
      MissilesFired: spikeInputsData.missilesFired,
      OutOfSector: spikeInputsData.outOfSector,
      DoubleHits: spikeInputsData.doubleHits,
      RedHits: spikeInputsData.hits,
      CorrectWarheadSelection: spikeInputsData.correctWarheadSelection,
      CorrectTrajectorySelection: spikeInputsData.correctTrajectorySelection,
      StaticTarget: `${spikeInputsData.hitsTable.staticTarget?.hits}/${spikeInputsData.hitsTable.staticTarget?.shots}`,
      MovingTarget: `${spikeInputsData.hitsTable.movingTarget?.hits}/${spikeInputsData.hitsTable.movingTarget?.shots}`,
      NLOSTarget: `${spikeInputsData.hitsTable.NLOSTarget?.hits}/${spikeInputsData.hitsTable.NLOSTarget?.shots}`,
      NightTarget: `${spikeInputsData.hitsTable.nightTarget?.hits}/${spikeInputsData.hitsTable.nightTarget?.shots}`,
      ManualTarget: `${spikeInputsData.hitsTable.manualTarget?.hits}/${spikeInputsData.hitsTable.manualTarget?.shots}`,
      PlanId: props.planId,
    };
    const displayToast = true;

    sendDrillToBBackend(
      SPIKEDrill,
      afterPostHandler,
      () => {},
      t,
      displayToast
    );
  };

  useEffect(() => {
    if (isSendClicked) postData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSendClicked]);

  useEffect(() => {
    if (trainee?.id !== selectedTrainee?.id && selectedTrainee)
      setTrainee(selectedTrainee);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTrainee]);

  useEffect(() => {
    if (
      spikeInputsData.hits !== null &&
      spikeInputsData.doubleHits !== null &&
      spikeInputsData.outOfSector !== null &&
      spikeInputsData.correctTrajectorySelection !== null &&
      spikeInputsData.correctWarheadSelection !== null &&
      spikeInputsData.missilesFired !== null &&
      trainee?.id !== undefined &&
      isValidData()
    ) {
      setIsComplete(true);
    } else {
      setIsComplete(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spikeInputsData, trainee]);

  useEffect(() => {
    setSpikeInputsData((prev) => {
      let sumHits: any = 0;
      let sumMissilesFired: any = 0;

      let isWithValue = Object.values(spikeInputsData.hitsTable).find(
        (val) => val?.hits !== null || val?.shots !== null
      );

      Object.values(spikeInputsData.hitsTable).forEach((hit) => {
        sumHits += hit?.hits;
        sumMissilesFired += hit?.shots;
      });

      return {
        ...prev,
        hits: isWithValue ? sumHits : null,
        missilesFired: isWithValue ? sumMissilesFired : null,
      };
    });
  }, [spikeInputsData.hitsTable]);
  const onChangeHandler = (
    field: string,
    value: string | IHit,
    isForHits?: boolean
  ) => {
    if (isForHits) {
      setSpikeInputsData((prev) => ({
        ...prev,
        hitsTable: {
          ...prev.hitsTable,
          [field]: value ? value : null,
        },
      }));
    } else {
      setSpikeInputsData((prev) => ({
        ...prev,
        [field]: value ? Number(value) : null,
      }));
    }
  };

  return (
    <React.Fragment>
      <IonRow className="spikeComponentWrap">
        <IonCol size="12" className="spikeComponentWrapCol">
          <IonRow className="rowSpikeComponent">
            <div className="hitsSPIKECol1">
              <PMInput
                cssClass="traineeInput"
                fontSize="large"
                inputType={"text"}
                inputName="traineeName"
                width="long"
                inputValue={trainee?.name}
                onChangeHandler={(inputName, inputValue: string) => {
                  setTrainee((prev) =>
                    prev
                      ? {
                          ...prev,
                          name: inputValue,
                          uniqId: prev.uniqId,
                        }
                      : {
                          name: inputValue,
                          id: undefined,
                          uniqId: -1,
                        }
                  );
                }}
                placeholder={t("traineeName")}
              />
            </div>
            <PMInput
              fontSize="large"
              inputType={"number"}
              inputName="ID"
              width="long"
              inputValue={trainee?.id}
              onChangeHandler={(inputName, inputValue: string) => {
                setTrainee((prev) =>
                  prev
                    ? {
                        ...prev,
                        id: inputValue ? Number(inputValue) : undefined,
                        uniqId: prev.uniqId,
                      }
                    : {
                        name: "",
                        id: inputValue ? Number(inputValue) : undefined,
                        uniqId: -1,
                      }
                );
              }}
              placeholder={t("ID")}
            />
          </IonRow>
          <IonRow className="rowSpikeComponent">
            <div className="hitsSPIKECol">
              <SPIKEInput
                inputName="hits"
                isDisabled
                value={spikeInputsData?.hits}
                isWrongValue={
                  Number(spikeInputsData.hits) >
                  Number(spikeInputsData.missilesFired)
                }
                constValue={spikeInputsData.missilesFired}
                constTitle="missilesFired"
              ></SPIKEInput>
              <InputTable
                onChangeHandler={onChangeHandler}
                tableData={spikeInputsData.hitsTable}
                elementTitle="targetTypeMode"
                valueTitle="successRate"
                maximumValue={spikeInputsData.hits}
                maximumRate={spikeInputsData.missilesFired}
                editable
              ></InputTable>
            </div>
            <IonCol className="inputsColSPIKE">
              <IonRow className="inputsRowSPIKE">
                <SPIKEInput
                  inputName="doubleHits"
                  onChangeHandler={onChangeHandler}
                  value={spikeInputsData?.doubleHits}
                  isWrongValue={
                    Number(spikeInputsData.doubleHits) >
                    Number(spikeInputsData.hits)
                  }
                  constValue={spikeInputsData.hits}
                  constTitle="hits"
                ></SPIKEInput>
                <SPIKEInput
                  inputName="outOfSector"
                  onChangeHandler={onChangeHandler}
                  value={spikeInputsData?.outOfSector}
                  isWrongValue={
                    Number(spikeInputsData.outOfSector) >
                    Number(spikeInputsData.missilesFired) -
                      Number(spikeInputsData.hits)
                  }
                  constValue={
                    spikeInputsData.missilesFired && spikeInputsData.hits
                      ? (spikeInputsData.missilesFired || 0) -
                        (spikeInputsData.hits || 0)
                      : undefined
                  }
                  constTitle={`${t("missilesFired")} - ${t("hits")}`}
                ></SPIKEInput>
                <SPIKEInput
                  inputName="correctWarheadSelection"
                  onChangeHandler={onChangeHandler}
                  value={spikeInputsData?.correctWarheadSelection}
                  isWrongValue={
                    Number(spikeInputsData.correctWarheadSelection) >
                    Number(spikeInputsData.missilesFired)
                  }
                  constTitle="missilesFired"
                  constValue={spikeInputsData.missilesFired}
                ></SPIKEInput>
                <SPIKEInput
                  inputName="correctTrajectorySelection"
                  onChangeHandler={onChangeHandler}
                  value={spikeInputsData?.correctTrajectorySelection}
                  isWrongValue={
                    Number(spikeInputsData.correctTrajectorySelection) >
                    Number(spikeInputsData.missilesFired)
                  }
                  constValue={spikeInputsData.missilesFired}
                  constTitle="missilesFired"
                ></SPIKEInput>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
    </React.Fragment>
  );
};

export default SPIKEComponent;
