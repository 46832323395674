// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orbatAccordionGroup {
  width: 300px;
  max-width: 100%;
  z-index: 8;
}
.orbatAccordionContent {
  min-height: 300px;
  z-index: 8;
  height: fit-content;
  max-height: 50vh;
  min-height: 40vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
  background-color: var(--ion-color-primary);
}
.searchDropDownWrap {
  width: 100%;
  align-self: center;
  margin-bottom: 10px;
  background-color: var(--ion-color-primary);
}
.orbatAccordionItem {
  --min-height: 55px;
  --ripple-color: #47484d;
}
.ion-accordion-toggle-icon {
  font-size: 14px;
  color: var(--ion-color-secondary);
}
.treeCollaps {
  min-height: 150px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/Orbat/Input/OrbatInput.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,UAAU;AACZ;AACA;EACE,iBAAiB;EACjB,UAAU;EACV,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,aAAa;EACb,0CAA0C;AAC5C;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,mBAAmB;EACnB,0CAA0C;AAC5C;AACA;EACE,kBAAkB;EAClB,uBAAuB;AACzB;AACA;EACE,eAAe;EACf,iCAAiC;AACnC;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".orbatAccordionGroup {\n  width: 300px;\n  max-width: 100%;\n  z-index: 8;\n}\n.orbatAccordionContent {\n  min-height: 300px;\n  z-index: 8;\n  height: fit-content;\n  max-height: 50vh;\n  min-height: 40vh;\n  overflow-y: auto;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  padding: 10px;\n  background-color: var(--ion-color-primary);\n}\n.searchDropDownWrap {\n  width: 100%;\n  align-self: center;\n  margin-bottom: 10px;\n  background-color: var(--ion-color-primary);\n}\n.orbatAccordionItem {\n  --min-height: 55px;\n  --ripple-color: #47484d;\n}\n.ion-accordion-toggle-icon {\n  font-size: 14px;\n  color: var(--ion-color-secondary);\n}\n.treeCollaps {\n  min-height: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
