import React from "react";
import "./PMTooltip.css";
import { Tooltip } from "react-tooltip";
interface IProps {
  id: string;
  children?: any;
}
const PMTooltip: React.FC<IProps> = (props: IProps): JSX.Element => {
  return (
    <Tooltip
      id={`${props.id}`}
      className="custom-tooltip"
      classNameArrow="tooltip-custom-arrow "
    >
      {props.children}
    </Tooltip>
  );
};

export default PMTooltip;
