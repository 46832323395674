import React from "react";
import IShootingHeat from "../../../../../../Interfaces/IShootingHeat";
import MobileTitleRow from "../../../../MobileHeader/MobileTitleRow";
import AchievementRequired from "./AchievementRequired";
import "./ResultsScreenHeader.css";

type Props = {
  currentHeat: IShootingHeat | undefined;
};

const ResultsScreenHeader = (props: Props) => {
  const { currentHeat } = props;
  return (
    <div className="results-header">
      <div className="selected-heat">
        {currentHeat && <MobileTitleRow title={currentHeat.name} />}
      </div>
      <div className="archievement-required-details">
        {currentHeat ? (
          <AchievementRequired resultType={currentHeat.type} />
        ) : null}
      </div>
    </div>
  );
};

export default ResultsScreenHeader;
