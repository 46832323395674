// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigationToggleRow {
  width: 100%;
  min-height: 68px;
  display: flex;
  align-items: center;
  background-color: var(--ion-color-light);
}
.frameTrainingContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.frameTrainingComponentWrap {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow-y: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/SPIKEGorilla/FrameTraining/FrameTraining.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,wCAAwC;AAC1C;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,YAAY;AACd;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAO;EACP,gBAAgB;AAClB","sourcesContent":[".navigationToggleRow {\n  width: 100%;\n  min-height: 68px;\n  display: flex;\n  align-items: center;\n  background-color: var(--ion-color-light);\n}\n.frameTrainingContainer {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n}\n.frameTrainingComponentWrap {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
