import React, { ReactNode, useState } from "react";
import IForceTreeNode from "../../Interfaces/IForceTreeNode";
import { createContext } from "react";
import IPlan from "../../Interfaces/IPlan";

interface Context {
  orbatForces: IForceTreeNode[];
  setOrbatForces: React.Dispatch<React.SetStateAction<IForceTreeNode[]>>;
  dashboardOrbatForces: IForceTreeNode[];
  setDashboardOrbatForces: React.Dispatch<
    React.SetStateAction<IForceTreeNode[]>
  >;
  resetCompetencyForces: () => void;
  competencySelectedPlan: IPlan | undefined;
  setCompetencySelectedPlan: React.Dispatch<
    React.SetStateAction<IPlan | undefined>
  >;
  dashboardSelectedPlan: IPlan | undefined;
  setDashboardSelectedPlan: React.Dispatch<
    React.SetStateAction<IPlan | undefined>
  >;
  competencyPath: string | undefined;
  setCompetencyPath: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const ForcesContextDesktop = createContext<Context>({
  orbatForces: [],
  setOrbatForces: () => {},
  dashboardOrbatForces: [],
  setDashboardOrbatForces: () => {},
  resetCompetencyForces: () => {},
  competencySelectedPlan: undefined,
  setCompetencySelectedPlan: () => {},
  dashboardSelectedPlan: undefined,
  setDashboardSelectedPlan: () => {},
  competencyPath: undefined,
  setCompetencyPath: () => {},
});

const ForcesContextProviderDesktop: React.FC<{
  children: ReactNode;
}> = (props: { children: ReactNode }) => {
  const [orbatForces, setOrbatForces] = useState<IForceTreeNode[]>([]);
  const [dashboardOrbatForces, setDashboardOrbatForces] = useState<
    IForceTreeNode[]
  >([]);
  const [competencySelectedPlan, setCompetencySelectedPlan] = useState<IPlan>();
  const [competencyPath, setCompetencyPath] = useState<string>();
  const [dashboardSelectedPlan, setDashboardSelectedPlan] = useState<IPlan>();
  const resetCompetencyForces = () => {
    setOrbatForces([]);
    setDashboardOrbatForces([]);
    setCompetencySelectedPlan(undefined);
    setDashboardSelectedPlan(undefined);
    setCompetencyPath(undefined);
  };

  return (
    <ForcesContextDesktop.Provider
      value={{
        orbatForces,
        setOrbatForces,
        dashboardOrbatForces,
        setDashboardOrbatForces,
        resetCompetencyForces,
        competencySelectedPlan,
        setCompetencySelectedPlan,
        dashboardSelectedPlan,
        setDashboardSelectedPlan,
        competencyPath,
        setCompetencyPath,
      }}
    >
      {props.children}
    </ForcesContextDesktop.Provider>
  );
};

export default ForcesContextProviderDesktop;
