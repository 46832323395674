// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.liveShootingContent {
  --background: transparent;
}

.shooting-range-grid {
  height: 90%;
  width: 100%;
  overflow-y: hidden;
  --ion-grid-padding: var(--pm_padding_l);
}

.shooting-range-table {
  height: 80%;
}

.shooting-range-wrapper {
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/DataPosting/ShootingRange/LiveShooting.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,WAAW;EACX,kBAAkB;EAClB,uCAAuC;AACzC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;AACd","sourcesContent":[".liveShootingContent {\n  --background: transparent;\n}\n\n.shooting-range-grid {\n  height: 90%;\n  width: 100%;\n  overflow-y: hidden;\n  --ion-grid-padding: var(--pm_padding_l);\n}\n\n.shooting-range-table {\n  height: 80%;\n}\n\n.shooting-range-wrapper {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
