import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import Axios from "../../../Axios";
import { baseUrlPMBackend } from "../../../Configurations/consts";
import customToast from "../../Shared/Toast/CustomToast";
import ForcesListContext from "../../../context/ForcesContext/forcesListContext";
import { getForceFullName } from "../../../services/helpers";
import { UserContext } from "../../../context/UserContext/userContext";
import { userRoles } from "../../../services/routeRoles";
import { useWebsocketUpdateForcesList } from "../../../services/forcesUpdateSocket";

/**
 *
 * @returns array with of trainees
 */
const asyncFetchTrainees = async (forceId: number | undefined) => {
  const res = await Axios.get(
    `${baseUrlPMBackend}forces/getSoldiersIdsAndNames`,
    {
      params: { forceId: forceId },
    }
  );
  return res.data;
};

export const useGetTrainees = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [updateForcesMassage] = useWebsocketUpdateForcesList();

  const { soldiersNamesAndIds, setSoldiersNamesAndIds } =
    useContext(ForcesListContext);

  const { data, isLoading, isError, refetch } = useQuery<
    {
      name: string;
      forceType: string;
      uniqId: number;
    }[]
  >({
    queryKey: ["soldiersNamesAndIds"],
    queryFn: () => asyncFetchTrainees(user.forceToDisplayInOrbat?.id),
    enabled:
      !soldiersNamesAndIds.length &&
      (user.role === userRoles.Admin ||
        user.forceToDisplayInOrbat?.id !== undefined),
  });

  useEffect(() => {
    // Refetch all soldiers when force was updated
    if (updateForcesMassage) refetch();
  }, [updateForcesMassage]);

  useEffect(() => {
    if (!isLoading && !isError && data && data?.length) {
      // Transform the new data
      const transformedData = data.map(
        (trainee: {
          name: string;
          forceType: string;
          uniqId: number;
          id?: number;
        }) => ({
          ...trainee,
          name: getForceFullName(trainee.name, trainee.forceType, t),
        })
      );

      // Compare existing state with the new data update the trainees array only if the array was changed
      const hasChanges =
        soldiersNamesAndIds.length !== transformedData.length ||
        !soldiersNamesAndIds.every((existingTrainee) =>
          transformedData.some(
            (newTrainee) =>
              existingTrainee.name === newTrainee.name &&
              existingTrainee.id === newTrainee.id &&
              existingTrainee.uniqId === newTrainee.uniqId
          )
        ) ||
        !transformedData.every((newTrainee) =>
          soldiersNamesAndIds.some(
            (existingTrainee) =>
              existingTrainee.name === newTrainee.name &&
              existingTrainee.id === newTrainee.id &&
              existingTrainee.uniqId === newTrainee.uniqId
          )
        );

      // Update state only if there are changes
      if (hasChanges) {
        if (soldiersNamesAndIds.length)
          customToast.info(t("forcesTreeUpdated"));
        setSoldiersNamesAndIds(transformedData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (isError) {
      customToast.error(t("errorWhileLoadingTraineesData"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return { soldiersNamesAndIds };
};
