// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.openButtonWrap {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  max-width: 44px;
}
.closeButtonWrap {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  max-width: 44px;
}
.openButton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.closeButton {
  height: 100%;
  background-color: var(--ion-color-enable);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.teecollapseRow {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}
.orbarTreeDrawer {
  display: flex;
  flex-direction: row;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/Orbat/OrbatTree/OrbatTreeDrawer.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;AACxB;AACA;EACE,YAAY;EACZ,yCAAyC;EACzC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,WAAW;AACb;AACA;EACE,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".openButtonWrap {\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: max-content;\n  max-width: 44px;\n}\n.closeButtonWrap {\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: max-content;\n  max-width: 44px;\n}\n.openButton {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n.closeButton {\n  height: 100%;\n  background-color: var(--ion-color-enable);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n\n.teecollapseRow {\n  display: flex;\n  flex-direction: row;\n  height: 100%;\n  width: 100%;\n}\n.orbarTreeDrawer {\n  display: flex;\n  flex-direction: row;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
