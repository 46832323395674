// css
import { IonCol, IonRow, IonText } from "@ionic/react";
import "./ElementResultRow.css";
import { useTranslation } from "react-i18next";
import PMLabel from "../../../components/themeComponents/PMLabel";
import { IElementResultRow } from "../../../Interfaces/Gorilla/IGorillaDesktopResults";

interface IProps {
  result: IElementResultRow;
  isWithSuccessRate?: boolean;
  isWithWeight?: boolean;
  isHeader?: boolean;
}
const ElementResultRow: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { result } = props;
  const { t } = useTranslation();
  return (
    <IonRow className="elementResultsRow">
      <IonCol className={`elementResultCol name`}>
        <IonText className="elementResultsName">{t(result.name)}</IonText>
      </IonCol>
      {props.isWithWeight ? (
        <IonCol size="2" className={`elementResultCol`}>
          {" "}
          <PMLabel
            textAlign="center"
            fontColor="light"
            fontSize="medium"
            fontFamily="Light"
          >
            {isNaN(Number(result.weight))
              ? `${t(result.weight)}`
              : `${Math.round(Number(result.weight))}%`}
          </PMLabel>
        </IonCol>
      ) : null}
      {props.isWithSuccessRate ? (
        <IonCol size="2" className={`elementResultCol`}>
          {" "}
          <PMLabel
            textAlign="center"
            fontColor="light"
            fontSize="medium"
            fontFamily="Light"
          >
            {t(result.successRate)}
          </PMLabel>
        </IonCol>
      ) : null}
      <IonCol size="2" className={`elementResultCol`}>
        {" "}
        <PMLabel
          textAlign="center"
          fontColor={result.grade === null ? "notAttended" : "light"}
          fontSize="medium"
          fontFamily="Light"
        >
          {isNaN(Number(result.grade))
            ? t(result.grade)
            : Math.round(Number(result.grade))}
        </PMLabel>
      </IonCol>
    </IonRow>
  );
};
export default ElementResultRow;
