import React from "react";
import DropdownEditor from "./DropdownEditor";
import DrillsDataTable from "./DrillsDataTable";
import { useTranslation } from "react-i18next";
import customToast from "../../Shared/Toast/CustomToast";
import "./Magnet.css";
import DesktopManualInputTemplate from "./DesktopManualInputTemplate";

import {
  convertLaneToMrkANdIESTLane,
  handleSave,
  IbasicRow,
  resetRowsData,
  sendDrillToBBackend,
} from "./dataPostingHelpers";
import UseDataPosting from "../../CustomHooks/UseDataPosting";
import {
  ComparisonFormatter,
  PositiveNumberFormatter,
  PositiveNumberNtoMFormatter,
} from "./InputFormatters";
import SoldiersListEditor from "./SoldiersListEditor";
import IDataPosting from "../../../Interfaces/IDataPosting";
import {
  comparisonFormatter,
  positiveNumber,
  positiveNumberNtoM,
} from "./inputValidations";
import { Column, EEditorType } from "../PMDataGrid/DataGridTypes";
import DataGrid from "../PMDataGrid/DataGrid";

const messageType: number = 6;
const rowsNumber: number = 14;
const trainingTypeId: number = 10;

// Mrk row (ui) interface
export interface IMagnetRow extends IbasicRow {
  laneId: number;
  weaponType: string;
  numberOfTargets: number | "";
  numberOfShots: number | "";
  numberOfHits: number | "";
  targetsDestroyed: number | "";
  hashtags: string | "";
  soldierId: number | "";
  blueDestroyed: number | "";
  blueHits: number | "";
  damagePercentage: number | "";
}

// Mrk message interface
interface magnetMessage {
  MessageType: number;
  TimeStamp: Date;
  LaneID: number;
  TrainingTypeID: number;
  SoldierID: number | "";
  DrillStartTime: Date;
  WeaponType: string;
  NumOfTargets: number | "";
  NumOfShots: number | "";
  RedHits: number | "";
  BlueDestroyed: number | "";
  BlueHits: number | "";
  Damage: number | "";
  TargetsDestroyed: number | "";
  Hashtags: string[];
  PlanId: number | undefined;
}

const Magnet: React.FC<IDataPosting> = (props: IDataPosting): JSX.Element => {
  const { t } = useTranslation();
  const XDefaultRow: IMagnetRow = {
    id: 0,
    laneId: 0,
    weaponType: " ",
    numberOfShots: "",
    numberOfHits: "",
    numberOfTargets: "",
    targetsDestroyed: "",
    hashtags: "",
    soldierId: "",
    soldierName: "",
    blueDestroyed: "",
    blueHits: "",
    damagePercentage: "",
  };

  const {
    isSuccess,
    setIsSuccess,
    rows,
    setRows,
    refreshRowsWrap,
    resetRowsDataWrap,
    weaponsTypesList,
  } = UseDataPosting(XDefaultRow, rowsNumber, trainingTypeId);

  // Columns for each input type
  const columns: Column<IMagnetRow>[] = [
    {
      key: "laneId",
      header: t("lane"),
      width: 80,
    },
    {
      key: "soldierName",
      header: t("soldierName"),
      editable: true,
      renderEditCell: SoldiersListEditor,
      filterType: EEditorType.text,
      width: 200,
    },
    {
      key: "soldierId",
      header: t("militaryId"),
      editable: true,
      filterType: EEditorType.text,
      validationFunction: (row: IMagnetRow, value: any) =>
        positiveNumber(value),
    },
    {
      key: "weaponType",
      header: t("weaponType"),
      editable: true,
      filterType: EEditorType.dropdown,
      options: weaponsTypesList.map((weapon) => ({
        label: t(weapon),
        value: weapon,
      })),
    },
    {
      key: "numberOfTargets",
      header: t("numOfTargets"),
      editable: true,
      filterType: EEditorType.text,
      validationFunction: (row: IMagnetRow, value: any) =>
        positiveNumberNtoM(value, 10, 0, t),
    },
    {
      key: "numberOfShots",
      header: t("numberOfShots"),
      editable: true,
      filterType: EEditorType.text,
      validationFunction: (row: IMagnetRow, value: any) =>
        positiveNumber(value),
    },

    {
      key: "numberOfHits",
      header: t("numberOfHits"),
      editable: true,
      filterType: EEditorType.text,
      validationFunction: (row: IMagnetRow, value: any) => {
        return comparisonFormatter(
          "numberOfHits",
          "numberOfShots",
          row,
          value,
          t
        );
      },
    },
    {
      key: "targetsDestroyed",
      header: t("numOfDestroyed"),
      editable: true,
      filterType: EEditorType.text,
      validationFunction: (row: IMagnetRow, value: any) =>
        positiveNumber(value),
    },
    {
      key: "blueHits",
      header: t("BlueHits"),
      editable: true,
      filterType: EEditorType.text,
      validationFunction: (row: IMagnetRow, value: any) =>
        positiveNumber(value),
    },
    {
      key: "blueDestroyed",
      header: t("BlueDestroyed"),
      editable: true,
      filterType: EEditorType.text,
      validationFunction: (row: IMagnetRow, value: any) =>
        positiveNumber(value),
    },
    {
      key: "damagePercentage",
      header: t("damagePercentage"),
      editable: true,
      filterType: EEditorType.text,
      validationFunction: (row: IMagnetRow, value: any) =>
        positiveNumberNtoM(value, 100, 0, t),
    },
    {
      key: "hashtags",
      header: t("hashtagsExcName"),
      editable: true,
      filterType: EEditorType.text,
    },
  ];

  // Manages data sending
  const sendData = async (row: IMagnetRow) => {
    // To avoid sending empty rows, check if all elements exist
    if (row.soldierId !== "") {
      if (row.targetsDestroyed === "" || row.numberOfTargets === "") {
        customToast.error(t("fillMagnetError"));
      } else {
        // Sets the loading
        setIsSuccess(-1);
        let dbLaneId: number | undefined;
        let rowToSend: magnetMessage;
        let weaponType = row.weaponType === " " ? "None" : row.weaponType;
        dbLaneId = await convertLaneToMrkANdIESTLane(row.laneId);
        // If a lane matches, it creates the row to send
        if (dbLaneId) {
          rowToSend = {
            SoldierID: row.soldierId,
            MessageType: messageType,
            TimeStamp: new Date(),
            TrainingTypeID: trainingTypeId,
            LaneID: dbLaneId,
            WeaponType: weaponType,
            NumOfShots: row.numberOfShots,
            RedHits: row.numberOfHits,
            NumOfTargets: row.numberOfTargets,
            TargetsDestroyed: row.targetsDestroyed,
            Hashtags: row.hashtags.split(" "),
            DrillStartTime: new Date(),
            PlanId: props.selectedPlan?.id,
            BlueDestroyed: row.blueDestroyed,
            BlueHits: row.blueHits,
            Damage: row.damagePercentage,
          };
          // Then sends it
          sendDrillToBBackend(
            rowToSend,
            () => {
              props.resetCompetencyForces();
            },
            () => setIsSuccess(500)
          );
        }
      }
    }
  };

  return (
    <DesktopManualInputTemplate
      selectedPlan={props.selectedPlan}
      plansOptions={props.plansOptions}
      onPlanSelectedHandler={props.onPlanSelectedHandler}
      pageName={"MAGNET_Urban"}
      onClickThirdButton={() => {
        handleSave(rows, sendData, t);
      }}
      onClickSecondButton={() => {
        refreshRowsWrap(XDefaultRow);
      }}
      onClickFirstButton={() => {
        resetRowsDataWrap(XDefaultRow);
      }}
    >
      <DataGrid
        columns={columns}
        data={rows}
        editable
        onEdit={(updatedRow) => {
          setRows((prev) =>
            prev.map((row) => (row.id === updatedRow.id ? updatedRow : row))
          );
        }}
        minWidth={150}
      ></DataGrid>
    </DesktopManualInputTemplate>
  );
};

export default Magnet;
