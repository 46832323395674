// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trainingPlanRow {
  width: 100%;
  min-height: 48px;
  background-color: var(--ion-color-dark);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline-start: var(--pm_spike_gorilla);
  padding-inline-end: var(--pm_spike_gorilla);
  filter: drop-shadow(rgba(0, 0, 0, 0.46) 0 1px 3px);
}
.IonMenuButtonDisabled {
  pointer-events: none;
}

.space {
  width: 28px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/SPIKEGorilla/TrainingPlanRow.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,uCAAuC;EACvC,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,6CAA6C;EAC7C,2CAA2C;EAC3C,kDAAkD;AACpD;AACA;EACE,oBAAoB;AACtB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".trainingPlanRow {\n  width: 100%;\n  min-height: 48px;\n  background-color: var(--ion-color-dark);\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding-inline-start: var(--pm_spike_gorilla);\n  padding-inline-end: var(--pm_spike_gorilla);\n  filter: drop-shadow(rgba(0, 0, 0, 0.46) 0 1px 3px);\n}\n.IonMenuButtonDisabled {\n  pointer-events: none;\n}\n\n.space {\n  width: 28px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
