// customToast.js
import { toast } from "react-toastify";
import "./CustomToast.css";

const customToast = {
  success(msg: string, options = {}) {
    return toast.success(msg, {
      ...options,
      icon: false,
      className: "toast-success-container toast-success-container-after",
    });
  },
  error(msg: string, options = {}) {
    return toast.error(msg, {
      ...options,
      icon: false,
      className: "toast-error-container toast-error-container-after",
    });
  },
  info(msg: string, options = {}) {
    return toast.info(msg, {
      ...options,
      icon: false,
      className: "toast-info-container toast-info-container-after",
    });
  },
};

export default customToast;
