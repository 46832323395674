// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toast-success-container,
.Toastify__toast--success,
.toast-success-container-after {
  background: var(--ion-color-success) !important;
  color: var(--ion-color-fontWhite);
  font-weight: 400;
  font-size: 16px;
}

.Toastify__toast--success .Toastify__progress-bar {
  background-color: var(--ion-color-success-tint);
  color: var(--ion-color-success-tint);
}

.toast-error-container,
.toast-error-container-after {
  background: var(--ion-color-danger) !important;
  color: var(--ion-color-fontWhite);
  font-weight: 400;
  font-size: 16px;
}

.toast-error-container .Toastify__progress-bar {
  background-color: var(--ion-color-danger-tint);
  color: var(--ion-color-danger-tint);
}

.toast-info-container,
.toast-info-container-after {
  background: var(--ion-color-dark) !important;
  color: var(--ion-color-fontWhite);
  font-weight: 400;
  font-size: 16px;
}
.toast-info-container .Toastify__progress-bar {
  background-color: var(--ion-color-fontWhite);
  color: var(--ion-color-fontWhite);
}

.Toastify__close-button {
  color: var(--ion-color-fontWhite);
  opacity: 0.7;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/Toast/CustomToast.css"],"names":[],"mappings":"AAAA;;;EAGE,+CAA+C;EAC/C,iCAAiC;EACjC,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,+CAA+C;EAC/C,oCAAoC;AACtC;;AAEA;;EAEE,8CAA8C;EAC9C,iCAAiC;EACjC,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,8CAA8C;EAC9C,mCAAmC;AACrC;;AAEA;;EAEE,4CAA4C;EAC5C,iCAAiC;EACjC,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,4CAA4C;EAC5C,iCAAiC;AACnC;;AAEA;EACE,iCAAiC;EACjC,YAAY;AACd","sourcesContent":[".toast-success-container,\n.Toastify__toast--success,\n.toast-success-container-after {\n  background: var(--ion-color-success) !important;\n  color: var(--ion-color-fontWhite);\n  font-weight: 400;\n  font-size: 16px;\n}\n\n.Toastify__toast--success .Toastify__progress-bar {\n  background-color: var(--ion-color-success-tint);\n  color: var(--ion-color-success-tint);\n}\n\n.toast-error-container,\n.toast-error-container-after {\n  background: var(--ion-color-danger) !important;\n  color: var(--ion-color-fontWhite);\n  font-weight: 400;\n  font-size: 16px;\n}\n\n.toast-error-container .Toastify__progress-bar {\n  background-color: var(--ion-color-danger-tint);\n  color: var(--ion-color-danger-tint);\n}\n\n.toast-info-container,\n.toast-info-container-after {\n  background: var(--ion-color-dark) !important;\n  color: var(--ion-color-fontWhite);\n  font-weight: 400;\n  font-size: 16px;\n}\n.toast-info-container .Toastify__progress-bar {\n  background-color: var(--ion-color-fontWhite);\n  color: var(--ion-color-fontWhite);\n}\n\n.Toastify__close-button {\n  color: var(--ion-color-fontWhite);\n  opacity: 0.7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
