import { FC, SetStateAction } from "react";
import "./SingleGunner.css";
import { ISystemEncryption } from "../../../../Interfaces/Gorilla/ISystemEncryption";
import TimeAccuracyElement from "../TimeAccuracyElement";
import { IonCol } from "@ionic/react";
import { ELuncherTypes } from "../../../../Enums/EHasifaTkifa";

type IProps = {
  systemEncryption: ISystemEncryption;
  setSystemEncryption: (value: SetStateAction<ISystemEncryption>) => void;
  isMobile?: boolean;
  selectedLuncherType: ELuncherTypes;
};

const SystemEncryption: FC<IProps> = (props: IProps): JSX.Element => {
  const { systemEncryption, setSystemEncryption, selectedLuncherType } = props;

  return (
    <>
      <IonCol className={props.isMobile ? "mobileFlow" : "SYCol SYLeftCol"}>
        <TimeAccuracyElement
          time={systemEncryption[selectedLuncherType]?.time}
          accuracy={systemEncryption[selectedLuncherType]?.accuracy}
          targetSeconds={systemEncryption[selectedLuncherType]?.goalSeconds}
          onTimeChanged={(seconds) => {
            setSystemEncryption((prev) => ({
              ...prev,
              [selectedLuncherType]: {
                ...prev[selectedLuncherType],
                time: seconds,
              },
            }));
          }}
          onAccuracyChanged={(accuracy) => {
            setSystemEncryption((prev) => ({
              ...prev,
              [selectedLuncherType]: {
                ...prev[selectedLuncherType],
                accuracy:
                  prev[selectedLuncherType].accuracy === null ? null : accuracy,
              },
            }));
          }}
        ></TimeAccuracyElement>
      </IonCol>
    </>
  );
};

export default SystemEncryption;
