// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.traingle-icon-up {
  font-size: x-small;
  color: #ffffff99;
  margin-top: 5px;
}

.traingle-icon-up.disabled {
  color: var(--ion-color-disabled);
}

.traingle-icon-down {
  font-size: x-small;
  transform: rotate(180deg);
}

.traingle-icon-down.disabled {
  color: var(--ion-color-disabled);
}

.labelTitle {
  font-size: 12px !important;
  top: 5px;
  position: absolute;
  width: 100%;
  text-align: start;
  /* from here we can change the title placement */
  padding-inline-start: 5px;
  font-family: "Light" !important;
  color: var(--ion-color-fontWhite) !important;
}
.labelTitle {
  left: 2px;
}
.labelTitle:lang(he) {
  right: 2px;
}

.labelTitle.disabled {
  color: var(--ion-color-fontWhite) !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/SearchDropdown/SearchDropdown.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,0BAA0B;EAC1B,QAAQ;EACR,kBAAkB;EAClB,WAAW;EACX,iBAAiB;EACjB,gDAAgD;EAChD,yBAAyB;EACzB,+BAA+B;EAC/B,4CAA4C;AAC9C;AACA;EACE,SAAS;AACX;AACA;EACE,UAAU;AACZ;;AAEA;EACE,4CAA4C;AAC9C","sourcesContent":[".traingle-icon-up {\n  font-size: x-small;\n  color: #ffffff99;\n  margin-top: 5px;\n}\n\n.traingle-icon-up.disabled {\n  color: var(--ion-color-disabled);\n}\n\n.traingle-icon-down {\n  font-size: x-small;\n  transform: rotate(180deg);\n}\n\n.traingle-icon-down.disabled {\n  color: var(--ion-color-disabled);\n}\n\n.labelTitle {\n  font-size: 12px !important;\n  top: 5px;\n  position: absolute;\n  width: 100%;\n  text-align: start;\n  /* from here we can change the title placement */\n  padding-inline-start: 5px;\n  font-family: \"Light\" !important;\n  color: var(--ion-color-fontWhite) !important;\n}\n.labelTitle {\n  left: 2px;\n}\n.labelTitle:lang(he) {\n  right: 2px;\n}\n\n.labelTitle.disabled {\n  color: var(--ion-color-fontWhite) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
