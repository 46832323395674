// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.achievement-required {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  margin-inline-end: 5vw;
  margin-top: 1.3vh;
  justify-content: flex-end;
}

.achievement-required-text {
  flex-direction: column;
  margin-inline-end: 3vw;
  display: flex;
}

.achievement-required-header {
  color: var(--ion-color-fontXLight);
  font-size: 14px;
  margin-top: 0.5vh;
  text-align: center;
}

.result-type {
  justify-content: center;
  font-size: large;
  color: var(--ion-color-fontXLight);
}

.vertical-line {
  margin-block-start: 1vh;
  border-left: 2px solid var(--ion-color-vertical-line);
  height: 36px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Mobile/ManualStation/ShootingRange/ResultsScreen/ResultsScreenHeader/AchievementRequired.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAO;EACP,mBAAmB;EACnB,sBAAsB;EACtB,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;EACtB,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,kCAAkC;EAClC,eAAe;EACf,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,kCAAkC;AACpC;;AAEA;EACE,uBAAuB;EACvB,qDAAqD;EACrD,YAAY;AACd","sourcesContent":[".achievement-required {\n  display: flex;\n  flex: 1;\n  flex-direction: row;\n  margin-inline-end: 5vw;\n  margin-top: 1.3vh;\n  justify-content: flex-end;\n}\n\n.achievement-required-text {\n  flex-direction: column;\n  margin-inline-end: 3vw;\n  display: flex;\n}\n\n.achievement-required-header {\n  color: var(--ion-color-fontXLight);\n  font-size: 14px;\n  margin-top: 0.5vh;\n  text-align: center;\n}\n\n.result-type {\n  justify-content: center;\n  font-size: large;\n  color: var(--ion-color-fontXLight);\n}\n\n.vertical-line {\n  margin-block-start: 1vh;\n  border-left: 2px solid var(--ion-color-vertical-line);\n  height: 36px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
