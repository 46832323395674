import { FC } from "react";
import { IonModal, IonRow } from "@ionic/react";
import "./WarningModal.css";
import PMLabel from "../../themeComponents/PMLabel";
import SaveCancelButtons from "../Buttons/SaveCancelButtons";
import { useTranslation } from "react-i18next";

interface IProps {
  isOpen: boolean;
  onAccept: () => void;
  onDecline: () => void;
  title: string;
  warningText?: string;
  warningQuestion: string;
  acceptText: string;
  declineText?: string;
  isWarning?: boolean;
  isAcceptDefault?: boolean;
}

const WarningModal: FC<IProps> = (props: IProps) => {
  const {
    isOpen,
    acceptText,
    onAccept,
    onDecline,
    title,
    warningText,
    warningQuestion,
    declineText,
    isAcceptDefault,
  } = props;
  const { t } = useTranslation();
  return (
    <IonModal isOpen={isOpen} className="warningModal" onDidDismiss={onDecline}>
      <div className="warningModalContent">
        <IonRow className="warningTextRow">
          <PMLabel
            textAlign="center"
            fontFamily="SemiBold"
            fontSize="xLarge"
            fontColor="light"
          >
            {t(title)}
          </PMLabel>
        </IonRow>
        <IonRow className="warningTextRow">
          {warningText ? (
            <IonRow className="warningText">
              <PMLabel
                fontFamily="Light"
                fontSize="large"
                fontColor="light"
                textAlign="center"
              >
                {t(warningText)}
              </PMLabel>
            </IonRow>
          ) : null}
          <IonRow className="">
            <PMLabel
              fontFamily="Regular"
              fontSize="xLarge"
              fontColor="light"
              textAlign="center"
            >
              {t(warningQuestion)}
            </PMLabel>
          </IonRow>
        </IonRow>
        <IonRow className="acceptDeclineButtonsRow">
          <SaveCancelButtons
            onCancelClickHandler={isAcceptDefault ? onAccept : onDecline}
            onSaveClickHandler={isAcceptDefault ? onDecline : onAccept}
            saveText={isAcceptDefault ? declineText : acceptText}
            declineText={isAcceptDefault ? acceptText : declineText}
            isWarnings={props.isWarning}
            isButtonCancelOutLine
          ></SaveCancelButtons>
        </IonRow>
      </div>
    </IonModal>
  );
};

export default WarningModal;
