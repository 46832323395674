import React, { FC } from "react";
import { IonCol, IonRow } from "@ionic/react";
import PMLabel from "../../../themeComponents/PMLabel";
import { useTranslation } from "react-i18next";
import "./TrackingLoginsList.css";
import { ILoginSiteItem } from "../../../../Interfaces/ILoginSiteItem";

type IProps = { sitesList: ILoginSiteItem[] | undefined };

const TrackingLoginsList: FC<IProps> = (props: IProps) => {
  const { sitesList } = props;
  const { t } = useTranslation();

  return (
    <div className="sites-list scrollL">
      {sitesList?.map((item: ILoginSiteItem) => (
        <IonRow className="site-row" key={item.siteName}>
          <IonCol className="sites-list-column">
            <PMLabel
              cssClass="tracking-label"
              fontColor="xLight"
              fontFamily="Light"
            >
              {t(item.siteName.replace(" ", ""))}
            </PMLabel>
          </IonCol>
          <IonCol className="sites-list-column">
            <PMLabel
              cssClass="tracking-label entries"
              fontColor="xLight"
              fontFamily="Light"
            >
              {item.count}
            </PMLabel>
          </IonCol>
        </IonRow>
      ))}
    </div>
  );
};

export default TrackingLoginsList;
