import IForceTreeNode from "../Interfaces/IForceTreeNode";

export const removeTempForce = (tree: IForceTreeNode) => {
  let tempNodes: IForceTreeNode[] | undefined = tree?.nodes?.filter(
    (node: IForceTreeNode) => node.id > 0
  );

  tempNodes && Object.assign(tree, { ...tree, nodes: tempNodes });

  tree?.nodes &&
    tree.nodes.forEach((node: IForceTreeNode) => {
      removeTempForce(node);
    });
};
export const isTwoLevelsAtLeast = (force: IForceTreeNode) => {
  return isAtLeastNLevels(force, 2);
};

export const isTreeLevelsAtLeast = (force: IForceTreeNode) => {
  return isAtLeastNLevels(force, 3);
};
export const isOnlyOneLevel = (force: IForceTreeNode) => {
  return (
    !force.nodes?.length || !force.nodes.find((child) => child.nodes?.length)
  );
};
export const isAtLeastNLevels = (force: IForceTreeNode, n: number = 2) => {
  if (!force || !force.nodes) {
    // Base case: force or force.nodes is null
    return false;
  }

  if (n < 1) {
    // Base case: reached the desired level
    return true;
  }

  for (let i = 0; i < force.nodes.length; i++) {
    const childNode = force.nodes[i];
    if (childNode.nodes && childNode.nodes.length > 0) {
      // Recursive case: check each child node
      if (isAtLeastNLevels(childNode, n - 1)) {
        return true;
      }
    }
  }

  return false;
};
