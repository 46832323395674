import { IonItem, IonList, IonRow } from "@ionic/react";
import "./PMDropdown.css";
import PMLabel from "../../themeComponents/PMLabel";
import PMCollapse from "../PMCollapse/PMCollapse";
import { useTranslation } from "react-i18next";
import Checkbox from "../Checkbox/Checkbox";
import { useState } from "react";
import IOption from "../../../Interfaces/IOption";

interface IProps {
  placeholder: string;
  options: IOption[];
  onOptionChanges: (option: IOption) => void;
  selectedOption: IOption | undefined;
  disabled?: boolean;
  isMulti?: boolean;
  isSmall?: boolean;
}
const PMDropdown = (props: IProps) => {
  const { t } = useTranslation();
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  return (
    <IonRow
      className={`PMDropdown ${props.disabled ? "disabled" : ""} ${
        props.isSmall ? "small" : ""
      }`}
    >
      <PMCollapse
        placeholder={props.placeholder}
        label={props.selectedOption ? props.selectedOption.value : undefined}
        disabled={props.disabled}
        isOpen={isCollapseOpen}
        setIsOpen={setIsCollapseOpen}
      >
        <IonList className="pmDropdownList">
          {props.options.map((option, index) => (
            <IonItem
              key={index}
              onClick={() => {
                setIsCollapseOpen(false);
                props.onOptionChanges(option);
              }}
              lines="none"
              className={`pmDropdownItem ${
                props.selectedOption?.key === option.key ? "selected" : ""
              }`}
            >
              {props.isMulti ? (
                <Checkbox
                  isChecked={!!option.isSelected}
                  onChange={(e) => {
                    if (e.detail.checked !== option.isSelected) {
                      props.onOptionChanges({
                        ...option,
                        isSelected: e.detail.checked,
                      });
                    }
                  }}
                ></Checkbox>
              ) : null}{" "}
              <PMLabel fontColor="light" fontFamily="Regular">
                {t(option.value)}
              </PMLabel>
            </IonItem>
          ))}
        </IonList>
      </PMCollapse>
    </IonRow>
  );
};

export default PMDropdown;
