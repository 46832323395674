// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentContainerMobileDataPosting {
  display: flex;
  position: relative;
  --ion-background-color: var(--ion-color-primary);
  flex-direction: column;
  flex: 1 1;
}

.mobileDataPostingGrid {
  display: flex;
  flex: 1 1;
  width: 100%;
  padding: 0;
  padding-top: 0;
  padding-bottom: 0;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
}

.stationNameItemM {
  --border-color: var(--ion-color-dividerAndroidTitle);
  --background: var(--ion-color-primary);
  font-size: large;
  font-family: "Bold";
  padding: 0;
  --min-height: 0px;
}

.itemM {
  width: 100%;
  --background: var(--ion-color-primary);
  --padding-start: 0px;
  --padding-end: 0px;
  --inner-padding-end: 0px;
}

.traineeNameLabelM {
  width: 100%;
  --color: var(--ion-color-fontXLight);
  font-size: 16pt;
  font-family: "Regular";
}

.titleM {
  margin-top: 0;
  --color: var(--ion-color-fontXLight);
}
`, "",{"version":3,"sources":["webpack://./src/components/Mobile/DataPosting/DataPostingMobileTemplate.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,gDAAgD;EAChD,sBAAsB;EACtB,SAAO;AACT;;AAEA;EACE,aAAa;EACb,SAAO;EACP,WAAW;EACX,UAAU;EACV,cAAc;EACd,iBAAiB;EACjB,sBAAsB;EACtB,8BAA8B;EAC9B,aAAa;AACf;;AAEA;EACE,oDAAoD;EACpD,sCAAsC;EACtC,gBAAgB;EAChB,mBAAmB;EACnB,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,sCAAsC;EACtC,oBAAoB;EACpB,kBAAkB;EAClB,wBAAwB;AAC1B;;AAEA;EACE,WAAW;EACX,oCAAoC;EACpC,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,oCAAoC;AACtC","sourcesContent":[".contentContainerMobileDataPosting {\n  display: flex;\n  position: relative;\n  --ion-background-color: var(--ion-color-primary);\n  flex-direction: column;\n  flex: 1;\n}\n\n.mobileDataPostingGrid {\n  display: flex;\n  flex: 1;\n  width: 100%;\n  padding: 0;\n  padding-top: 0;\n  padding-bottom: 0;\n  flex-direction: column;\n  justify-content: space-between;\n  padding: 24px;\n}\n\n.stationNameItemM {\n  --border-color: var(--ion-color-dividerAndroidTitle);\n  --background: var(--ion-color-primary);\n  font-size: large;\n  font-family: \"Bold\";\n  padding: 0;\n  --min-height: 0px;\n}\n\n.itemM {\n  width: 100%;\n  --background: var(--ion-color-primary);\n  --padding-start: 0px;\n  --padding-end: 0px;\n  --inner-padding-end: 0px;\n}\n\n.traineeNameLabelM {\n  width: 100%;\n  --color: var(--ion-color-fontXLight);\n  font-size: 16pt;\n  font-family: \"Regular\";\n}\n\n.titleM {\n  margin-top: 0;\n  --color: var(--ion-color-fontXLight);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
