// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainText {
  padding-top: 40vh;
  display: flex;
  justify-content: center;
}

.img {
  height: fit-content;
  width: fit-content;
  overflow: hidden;
  align-self: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Desktop/Unauthorized/Unauthorized.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".mainText {\n  padding-top: 40vh;\n  display: flex;\n  justify-content: center;\n}\n\n.img {\n  height: fit-content;\n  width: fit-content;\n  overflow: hidden;\n  align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
