import { FC } from "react";
import "./CombatFitnessStation.css";
import { useHistory } from "react-router-dom";

import CombatFitnessComponent from "./CombatFitnessComponent";
import DataPostingMobileTemplate from "../../../Mobile/DataPosting/DataPostingMobileTemplate";
import useMobileDataPostingParams from "../../../CustomHooks/useMobileDataPostingParams";

const CombatFitnessStation: FC = () => {
  const { trainees, trainingTypeId, stationName } =
    useMobileDataPostingParams();

  const history = useHistory();

  const backHandler = () => {
    history.goBack();
  };

  return (
    <DataPostingMobileTemplate
      stationName={stationName}
      traineeName={trainees[0]?.name || ""}
    >
      <CombatFitnessComponent
        traineeId={Number(trainees[0]?.id)}
        trainingTypeId={Number(trainingTypeId)}
        afterPostHandler={backHandler}
      ></CombatFitnessComponent>
    </DataPostingMobileTemplate>
  );
};

export default CombatFitnessStation;
