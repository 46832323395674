import { Dispatch, FC, SetStateAction } from "react";
import "./Observer.css";
import { useTranslation } from "react-i18next";
import PMLabel from "../../../themeComponents/PMLabel";
import Divider from "../Divider";
import GeneralGrade from "../GeneralGrade";
import { IOpenAndCover } from "../../../../Interfaces/Gorilla/IObserver";
import { IonGrid } from "@ionic/react";

interface IProps {
  setOpenAndCover: Dispatch<SetStateAction<IOpenAndCover>>;
  openAndCover: IOpenAndCover;
  isDesktop?: boolean;
}

const OpenAndCover: FC<IProps> = (props: IProps): JSX.Element => {
  const { openAndCover, setOpenAndCover } = props;
  const { t } = useTranslation();

  return (
    <IonGrid className="openAndCoverWrap">
      {Object.keys(openAndCover).map((key, index) => {
        let typedKey = key as keyof IOpenAndCover;
        return (
          <div key={index} className="TACol">
            {index === 0 && (
              <div className="titleRowMO">
                <PMLabel
                  fontSize="large"
                  fontFamily="Regular"
                  fontColor="light"
                >
                  {t("openAreaAndObservationCover")}
                </PMLabel>
              </div>
            )}
            {index > 0 && !props.isDesktop ? <Divider /> : null}
            <GeneralGrade
              elementName={key}
              grade={openAndCover[typedKey]}
              setGrade={(grade) => {
                setOpenAndCover((prev) => ({
                  ...prev,
                  [typedKey]: grade,
                }));
              }}
              isDesktop={props.isDesktop}
            ></GeneralGrade>
          </div>
        );
      })}
    </IonGrid>
  );
};

export default OpenAndCover;
