import { Dispatch, useEffect, useState } from "react";
import EEventType from "../Interfaces/EEventType";
import ICheckInOutData from "../Interfaces/ICheckInData";
import ITraineeDetails from "../Interfaces/ITraineeDetails";
import IForceTreeNode from "./../Interfaces/IForceTreeNode";
import { getIO } from "./socketIo";
import { environment } from "../Configurations/consts";
import { useDispatch } from "react-redux";
import { getOrbatTree } from "../redux/actions/orbatTreeActions";
import { useTranslation } from "react-i18next";

interface IMessageCheckIn {
  stationId: number;
  soldierId: number;
  soldierName: string;
  eventType: EEventType;
  laneId: string;
  uniqId: number;
  forceType: string;
}
interface IMessageRegister {
  laneId: number;
  soldierRFID: number;
  soldierName: string;
}

const useWebsocketCheckIn = () => {
  const dispatch = useDispatch<Dispatch<any>>();
  const { t } = useTranslation();
  const [receivedMessage, setReceivedMessage] = useState<ICheckInOutData>();

  const onCheckInReceived = (message: any) => {
    let checkInMessage: IMessageCheckIn = message;
    const soldierDetailed: ITraineeDetails = {
      traineeId: checkInMessage.soldierId,
      traineeName: checkInMessage.soldierName,
      laneId: checkInMessage.laneId,
      newInColumn: true,
      stationId: checkInMessage.stationId,
      uniqId: Number(checkInMessage.uniqId),
      forceType: checkInMessage.forceType,
    };
    const checkInData: ICheckInOutData = {
      stationId: checkInMessage.stationId,
      traineeDetails: soldierDetailed,
      eventType: message.eventType,
    };
    if (+checkInData.eventType === EEventType.SITE_CHECKOUT) {
      dispatch(getOrbatTree(t));
    }
    setReceivedMessage(checkInData);
  };
  useEffect(() => {
    if (environment && environment.toString() !== "production") {
      const io = getIO();
      io?.on("check-in-out-message", onCheckInReceived);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [receivedMessage];
};

const useWebsocketRegister = () => {
  useEffect(() => {
    if (environment && environment.toString() !== "production") {
      const io = getIO();
      io?.on("register-message", onRegisterReceived);
    }
  }, []);
  const [registerMessage, setRegisterMessage] = useState<IMessageRegister>();

  const onRegisterReceived = (message: any) => {
    let checkInMessage: IMessageRegister = message;

    setRegisterMessage(checkInMessage);
  };

  return [registerMessage];
};

const useWebsocketTagging = () => {
  useEffect(() => {
    if (environment && environment.toString() !== "production") {
      const io = getIO();
      io?.on("tagging-message", onTaggingReceived);
    }
  }, []);
  const [taggingMessage, setTaggingMessage] = useState<IForceTreeNode>();

  const onTaggingReceived = (message: IForceTreeNode[]) => {
    setTaggingMessage(message[0]);
  };
  return taggingMessage;
};

export { useWebsocketRegister, useWebsocketCheckIn, useWebsocketTagging };
