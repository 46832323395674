import { IonPage, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import "./DesktopManualInputTemplate.css";
import PMButton from "../../themeComponents/PMButton";
import { useTranslation } from "react-i18next";
import PMLabel from "../../themeComponents/PMLabel";
import IPlan from "../../../Interfaces/IPlan";
import PlansDropdown from "../../Shared/PlansDropdown/PlansDropdown";

interface IProps {
  onClickFirstButton?: () => void;
  onClickSecondButton?: () => void;
  onClickThirdButton: () => void;
  isSendDisabled?: boolean;
  children: React.ReactNode;
  headerChildren?: React.ReactNode;
  pageName: string;
  plansOptions: IPlan[];
  selectedPlan: IPlan | undefined;
  onPlanSelectedHandler: (planOption: IPlan) => void;
  firstButtonText?: string;
  secondButtonText?: string;
  thirdButtonText?: string;
  isDisabledFirstButton?: boolean;
  isDisabledSecondButton?: boolean;
  isDisabledThirdButton?: boolean;
  isToHideButtons?: boolean;
}
const DesktopManualInputTemplate: React.FC<IProps> = (
  props: IProps
): JSX.Element => {
  const {
    onClickFirstButton,
    onClickSecondButton,
    onClickThirdButton,
    children,
    isSendDisabled,
    plansOptions,
    onPlanSelectedHandler,
    pageName,
    selectedPlan,
    headerChildren,
  } = props;
  const { t } = useTranslation();

  return (
    <IonPage className="dataPostingTemplate">
      <IonGrid className="compMD gridMD dataPostingPage scrollL">
        <IonRow className="dataPostingHeader">
          <PMLabel
            fontColor="light"
            fontFamily="Regular"
            fontSize="xxLarge"
            cssClass="dataPostingHeaderLabel"
          >
            {t(pageName)}
          </PMLabel>
          <div className="rowDMTHeader">
            {headerChildren ? headerChildren : null}
            <PlansDropdown
              onPlanSelectedHandler={onPlanSelectedHandler}
              plansOptions={plansOptions}
              selectedPlan={selectedPlan}
            ></PlansDropdown>
          </div>
        </IonRow>
        <IonRow className="compMD mainViewMD scrollL">{children}</IonRow>
        {props.isToHideButtons ? null : (
          <IonRow className="compMD buttonsRowMD">
            <PMButton
              label={{
                fontColor: "light",
                fontSize: "medium",
                fontFamily: "Light",
              }}
              color="orange"
              fill="outline"
              size="fit"
              isHidden={onClickFirstButton === undefined}
              onClickHandler={onClickFirstButton}
              isDisabled={props.isDisabledFirstButton}
            >
              {props.firstButtonText ? props.firstButtonText : t("reset")}
            </PMButton>
            <PMButton
              label={{
                fontColor: "light",
                fontSize: "medium",
                fontFamily: "Light",
              }}
              color="orange"
              fill="outline"
              size="fit"
              cssClass="refreshButton"
              isHidden={onClickSecondButton === undefined}
              onClickHandler={onClickSecondButton}
              isDisabled={props.isDisabledSecondButton}
            >
              {props.secondButtonText ? props.secondButtonText : t("refresh")}
            </PMButton>
            <PMButton
              label={{
                fontColor: "light",
                fontSize: "medium",
                fontFamily: "Light",
              }}
              color="orange"
              fill="solid"
              size="fit"
              onClickHandler={onClickThirdButton}
              isDisabled={isSendDisabled || props.isDisabledThirdButton}
            >
              {props.thirdButtonText ? props.thirdButtonText : t("send")}
            </PMButton>
          </IonRow>
        )}
      </IonGrid>
    </IonPage>
  );
};

export default DesktopManualInputTemplate;
