import IForceTreeNode from "./../../Interfaces/IForceTreeNode";
import * as OrbatTreeActions from "../actions/orbatTreeActions";

export interface IOrbatTreeReducer {
  orbatTree: IForceTreeNode;
  loading: boolean | false;
  updateOccured: boolean | false;
  updateResStatus: string;
  updateErrorMsg: any;
  addOccured: boolean | false;
  addResStatus: string;
  addErrorMsg: any;
  deleteOccured: boolean | false;
}

const orbatTree = (
  state: IOrbatTreeReducer = {} as IOrbatTreeReducer,
  action: OrbatTreeActions.Actions
) => {
  switch (action.type) {
    case OrbatTreeActions.OrbatTreeActionTypes.GET_ORBAT_TREE:
      return { ...state, orbatTree: action.forces };
    case OrbatTreeActions.OrbatTreeActionTypes.TREE_LOADING:
      return { ...state, loading: true };
    case OrbatTreeActions.OrbatTreeActionTypes.TREE_END_LOADING:
      return { ...state, loading: false };
    case OrbatTreeActions.OrbatTreeActionTypes.ADD_FORCE:
      return {
        ...state,
        orbatTree:
          "data" in action.res
            ? OrbatTreeActions.changeNodeInTree(
                state.orbatTree,
                action.res.data,
                "add"
              )
            : state.orbatTree,

        addResStatus: action.res.status,
        addErrorMsg: "msg" in action.res && action.res.msg,
      };
    case OrbatTreeActions.OrbatTreeActionTypes.ADD_OCCURED:
      return { ...state, addOccured: true };
    case OrbatTreeActions.OrbatTreeActionTypes.UPDATE_FORCE:
      return {
        ...state,
        orbatTree:
          "data" in action.res
            ? OrbatTreeActions.changeNodeInTree(
                state.orbatTree,
                action.res.data,
                "update"
              )
            : state.orbatTree,
        updateResStatus: action.res.status,
        updateErrorMsg: "msg" in action.res && action.res.msg,
      };
    case OrbatTreeActions.OrbatTreeActionTypes.UPDATE_OCCURED:
      return { ...state, updateOccured: true };
    case OrbatTreeActions.OrbatTreeActionTypes.RESET_FLAGS:
      return {
        ...state,
        updateResStatus: "",
        updateErrorMsg: "",
        addErrorMsg: "",
        addResStatus: "",
        localForceAdded: false,
        updateOccured: false,
        addOccured: false,
        deleteOccured: false,
      };
    case OrbatTreeActions.OrbatTreeActionTypes.DELETE_FORCE:
      return {
        ...state,
        orbatTree: OrbatTreeActions.changeNodeInTree(
          state.orbatTree,
          action.force,
          "delete"
        ),
      };
    case OrbatTreeActions.OrbatTreeActionTypes.DELETE_OCCURED:
      return {
        ...state,
        deleteOccured: true,
      };
    case OrbatTreeActions.OrbatTreeActionTypes.REMOVE_UNSAVE_FORCE:
      return {
        ...state,
        orbatTree: OrbatTreeActions.changeNodeInTree(
          state.orbatTree,
          undefined,
          "remove-unsave"
        ),
      };
    default:
      return state;
  }
};

export default orbatTree;
