// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.numbersList {
  --background: var(--ion-color-dark);
  --border-color: var(--ion-color-dark);
  margin-right: 0px;
  margin-left: 0px;
  --color: var(--ion-color-fontLight);
}
.label {
  font-family: "Regular" !important;
  min-width: max-content;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/SiteManagement/NumbersList.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,qCAAqC;EACrC,iBAAiB;EACjB,gBAAgB;EAChB,mCAAmC;AACrC;AACA;EACE,iCAAiC;EACjC,sBAAsB;AACxB","sourcesContent":[".numbersList {\n  --background: var(--ion-color-dark);\n  --border-color: var(--ion-color-dark);\n  margin-right: 0px;\n  margin-left: 0px;\n  --color: var(--ion-color-fontLight);\n}\n.label {\n  font-family: \"Regular\" !important;\n  min-width: max-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
