// Dependencies
import { IonIcon, IonInput, IonItem } from "@ionic/react";
import React, { useState } from "react";

// CSS
import "./PMInput.css";
import PMLabel from "../../themeComponents/PMLabel";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";

interface IInputProps {
  iconInInput?: boolean | false;
  inputType: "text" | "number" | "password" | "email";
  inputName: string;
  placeholder?: string | "";
  inputValue?: string | number | null;
  isDisabled?: false | boolean;
  width?: "short" | "long" | "mid";
  height?: "short" | "long" | "mid" | "settingsHeight";
  onChangeHandler?: ((field: string, value: string) => void) | undefined;
  onBlurHandler?: () => void;
  invalid?: false | boolean;
  icon?: string;
  onIconClick?: () => void;
  cssClass?: string;
  isReadonly?: boolean;
  errorCss?: string;
  inputCss?: string;
  fontSize?: "medium" | "large";
  onClick?: () => void;
  constValue?: string | number | undefined;
  onKeyPress?: (event: any) => void;
  autocomplete?: "username" | "email" | "new-password" | undefined;
}

const PMInput: React.FC<IInputProps> = (props: IInputProps) => {
  const [icon, setIcon] = useState<string>(eyeOutline);

  const [passwordInputType, setPasswordInputType] = useState<
    "password" | "text"
  >("password");
  const onIconClickHandler = () => {
    setIcon((prev) => (prev === eyeOutline ? eyeOffOutline : eyeOutline));
    setPasswordInputType((prev) => (prev === "password" ? "text" : "password"));
  };
  return (
    <IonItem
      onKeyDown={props.onKeyPress}
      onClick={props.onClick}
      disabled={props.isDisabled}
      lines="none"
      className={`PMInput ${props.isDisabled ? "disabled" : ""}  ${
        props.errorCss
      } ${props.width}WPMI`}
    >
      <PMLabel
        position="floating"
        cssClass={`${props.cssClass} tpLabelPMI`}
        fontFamily="Light"
        fontSize={"medium"}
      >
        {props.isReadonly ? "" : props.placeholder}
      </PMLabel>
      {props.icon || props.inputType === "password" ? (
        <div className="InputWrapWithIconPMI">
          <IonInput
            readonly={props.isReadonly}
            // name={props.inputName}
            value={props.inputValue}
            type={
              props.inputType === "password" ? passwordInputType : undefined
            }
            min="0"
            onIonChange={(e) => {
              props.onChangeHandler
                ? props.onChangeHandler(props.inputName, e.detail.value!)
                : console.log();
            }}
            onBlur={props.onBlurHandler}
            className={`inputTextPMI `}
            placeholder={props.placeholder}
            autocomplete={props.autocomplete ? props.autocomplete : "off"}
          ></IonInput>

          {props.iconInInput || props.inputType === "password" ? (
            <div
              className="eyeIconDiv"
              onClick={() =>
                props.onIconClick
                  ? props.onIconClick()
                  : props.inputType === "password"
                  ? onIconClickHandler()
                  : null
              }
            >
              <IonIcon
                className={`${
                  props.inputType === "password" ? "eyeIcon" : "calendarIcon"
                }`}
                icon={props.inputType === "password" ? icon : props.icon}
              />
            </div>
          ) : null}
        </div>
      ) : (
        <div className="InputWrapWithConstPMI">
          {props.constValue !== undefined && props.inputValue !== null ? (
            <div className={`constValue`}>{props.constValue}</div>
          ) : null}
          <IonInput
            readonly={props.isReadonly}
            name={props.inputName}
            value={props.inputValue}
            type={props.inputType}
            min="0"
            onIonChange={(e) => {
              if (props.onChangeHandler)
                props.onChangeHandler(props.inputName, e.detail.value!);
            }}
            onBlur={props.onBlurHandler}
            className={`inputTextPMI ${props.inputCss} ${
              props.constValue !== undefined && props.inputValue !== null
                ? "inputWithConst"
                : ""
            }`}
            placeholder={props.placeholder}
            autocomplete={props.autocomplete ? props.autocomplete : "off"}
          ></IonInput>
        </div>
      )}
    </IonItem>
  );
};

export default PMInput;
