import { FC, useEffect, useState } from "react";
import { IonCol, IonModal, IonRow } from "@ionic/react";
import "./CheckboxesList.css";
import SaveCancelButtons from "../Buttons/SaveCancelButtons";
import PMLabel from "../../themeComponents/PMLabel";
import Checkbox from "../Checkbox/Checkbox";
import SearchBar from "../SearchBar/SearchBar";

interface IProps {
  isOpen: boolean;
  list: {
    name: string;
    isChecked: boolean;
    isExecuted?: boolean;
    id: number;
  }[];
  onSave: (ElementsTemp: Set<string>) => void;
  onCancel: () => void;
  limit?: number;
  title: string;
}

const CheckboxesList: FC<IProps> = (props: IProps) => {
  const { isOpen, list, onCancel, onSave, title } = props;
  const [selectedElementsTemp, setElementsTemp] = useState<Set<string>>(
    new Set()
  );
  const [filtersElements, setFilteredElements] = useState<
    { name: string; isChecked: boolean; isExecuted?: boolean; id: number }[]
  >([]);
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    setFilteredElements(props.list);
    setElementsTemp(
      new Set(list.filter((ha) => ha.isChecked).map((s) => s.name))
    );
  }, [list]);

  useEffect(() => {
    if (!isOpen) {
      setSearchText("");
    }
  }, [isOpen]);

  useEffect(() => {
    setFilteredElements(
      list.filter(
        (element) =>
          element.name.toLowerCase().includes(searchText.toLowerCase()) ||
          selectedElementsTemp.has(element.name)
      )
    );
  }, [searchText, list]);

  return (
    <IonModal
      showBackdrop
      isOpen={isOpen}
      className="checkboxListModal"
      onDidDismiss={onCancel}
    >
      <div className="checkboxListModalContent">
        <IonRow className="checkboxListTitleRow">
          <PMLabel fontFamily="Regular" fontSize="xxLarge" fontColor="light">
            {title}
          </PMLabel>
        </IonRow>
        <IonRow className="checkboxesListSearchBar">
          <SearchBar
            value={searchText}
            onChange={(searchText) => {
              setSearchText(searchText);
            }}
            color="dark"
          ></SearchBar>
        </IonRow>
        <IonRow className="checkboxListList">
          <IonCol className="checkboxListListCol scrollS">
            {filtersElements.map((element) => (
              <IonRow className="faultRow" key={element.id}>
                <IonCol size="1.4">
                  <Checkbox
                    isDisabled={
                      !selectedElementsTemp.has(element.name) &&
                      props.limit !== undefined &&
                      props.limit <= selectedElementsTemp.size
                    }
                    isChecked={selectedElementsTemp.has(element.name)}
                    onChange={(event) => {
                      setElementsTemp((prev) => {
                        let prevElements = new Set(prev);
                        if (event.detail.checked) {
                          prevElements.add(element.name);
                        } else prevElements.delete(element.name);
                        return prevElements;
                      });
                    }}
                    color="orange"
                  ></Checkbox>
                </IonCol>
                <IonCol>
                  <PMLabel
                    fontFamily="Light"
                    fontSize="medium"
                    fontColor="light"
                  >
                    {element.name}
                  </PMLabel>
                </IonCol>
              </IonRow>
            ))}
          </IonCol>
        </IonRow>
        <IonRow className="saveCancelRowCheckboxListFModal">
          <SaveCancelButtons
            onCancelClickHandler={onCancel}
            onSaveClickHandler={() => onSave(selectedElementsTemp)}
            saveText="select"
          ></SaveCancelButtons>
        </IonRow>
      </div>
    </IonModal>
  );
};

export default CheckboxesList;
