import { FC } from "react";
import { IonRow } from "@ionic/react";
import "./TrainingPlanRow.css";
import { useTranslation } from "react-i18next";
import PMIcon from "../../themeComponents/PMIcon";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import PMLabel from "../../themeComponents/PMLabel";
interface IProps {
  heatName: string;
  withoutMenu?: boolean;
  isMenuDisabled?: boolean;
  withoutInfo?: boolean;
  setIsModalOpen?: (state: boolean) => void;
}
const TrainingPlanRow: FC<IProps> = (props: IProps) => {
  const { heatName, withoutMenu, isMenuDisabled, withoutInfo } = props;
  const { t } = useTranslation();

  return (
    <IonRow className="trainingPlanRow">
      {!withoutMenu ? (
        <PMIcon
          size="xLarge"
          iconSrc={EIconsSrc.OPEN_MENU}
          disabled={isMenuDisabled}
          onClick={() => {
            //open menu
            props.setIsModalOpen && props.setIsModalOpen(true);
          }}
        ></PMIcon>
      ) : (
        <div className="space"></div>
      )}
      <PMLabel fontFamily="Regular" fontSize="large" fontColor="light">
        {t(heatName)}
      </PMLabel>
      {!withoutInfo ? (
        <PMIcon size="xLarge" iconSrc={EIconsSrc.INFO}></PMIcon>
      ) : (
        <div className="space"></div>
      )}{" "}
    </IonRow>
  );
};

export default TrainingPlanRow;
