import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import "./DateDrawerRange.css";
import { IDateRange } from "../../../Interfaces/IDatePicker";
import EDateDrawer from "../../../Enums/EDateDrawer";
import DateDrawer from "./DateDrawer";

type Props = {
  idToControl: string;
  rangeDate: IDateRange | undefined;
  setRangeDate: React.Dispatch<React.SetStateAction<IDateRange | undefined>>;
  drawerState: EDateDrawer.selectEnd | EDateDrawer.selectStart;
  setDrawerState: React.Dispatch<
    React.SetStateAction<EDateDrawer.selectStart | EDateDrawer.selectEnd>
  >;
  isNotRange?: boolean;
};

const DateDrawerRange = (props: Props) => {
  const { t } = useTranslation();

  const [tempDate, setTempDate] = useState<IDateRange>({
    startDate: undefined,
    endDate: undefined,
  });
  useEffect(() => {
    if (tempDate?.startDate) {
      props.setDrawerState(EDateDrawer.selectEnd);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempDate?.startDate]);

  useEffect(() => {
    if (tempDate?.startDate && tempDate?.endDate) {
      props.setRangeDate(tempDate);
      props.setDrawerState(EDateDrawer.selectStart);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempDate]);

  return (
    <DateDrawer
      date={
        props.drawerState === EDateDrawer.selectEnd
          ? props.rangeDate?.startDate
          : props.rangeDate?.endDate
      }
      idToControl={props.idToControl}
      selectText={
        props.drawerState === EDateDrawer.selectStart
          ? t("selectStartDate")
          : props.drawerState === EDateDrawer.selectEnd
          ? t("selectEndDate")
          : t("selectDate")
      }
      setDate={(date: Date) => {
        if (props.drawerState === EDateDrawer.selectStart)
          setTempDate((prev) => {
            return { ...prev, startDate: date };
          });
        else if (props.drawerState === EDateDrawer.selectEnd)
          setTempDate((prev) => {
            return { ...prev, endDate: date };
          });
      }}
      isStartDate={props.drawerState === EDateDrawer.selectStart}
    ></DateDrawer>
  );
};

export default DateDrawerRange;
