import React, { useContext, useEffect, useMemo, useState } from "react";
import { IonCol, IonContent, IonGrid, IonRow } from "@ionic/react";
import "./exportModal.css";
import IForceTreeNode from "../../../Interfaces/IForceTreeNode";
import { useTranslation } from "react-i18next";
import OrbatTree from "../../Shared/Orbat/OrbatTree/OrbatTree";
import RadarGraph from "../../Shared/Charts/Radar/RadarGraph";
import { exportToPdfAndCsv as exportToCsv } from "./exportToCsv";
import PMLoading from "../../Shared/Alert/PMLoading";
import BarChart from "../../Shared/Charts/Bar/BarChart";
import { toast } from "react-toastify";
import { IForceAllData } from "../../../Interfaces/IForceAllData";
import FinalGradeDisplay from "../../Shared/FinalGradeDisplay/FinalGradeDisplay";
import { getForceFullName } from "../../../services/helpers";
import ExportModalCollapse from "./ExportModalCollapse";
import {
  EChartId,
  IChartsSelection,
  IIndicatorsSelection,
  ISelection,
  ITooltipsOptions,
} from "../../../Interfaces/IExportReport";
import useForcesChecked from "../../CustomHooks/useForcesChecked";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import { IForce } from "../../../Interfaces/results/force.interface";
import { UserContext } from "../../../context/UserContext/userContext";
import {
  IIndicatorResult,
  ITrainingPlanResult,
  ITrainingTypeResult,
} from "../../../Interfaces/dataCalculator";
import { useOriginalTooltipsTitles } from "../../CustomHooks/HooksWithRQ/useOriginalTooltipsTitles";
import usePlans from "../../CustomHooks/usePlans";
import useInitGraphsData from "../../CustomHooks/useInitGraphsData";
import { useReportGeneratorGrades } from "../../CustomHooks/HooksWithRQ/useReportGeneratorGrades";
import PopOverButton from "../../themeComponents/PopOverButton";
import EExportMethod from "../../../Interfaces/EExportMethod";
import { exportToPdf } from "./exportToPdf";
import { toPng } from "html-to-image";
import { TrainingTypesContext } from "../../../context/TrainingTypesContext/TrainingTypesContext";
import useGetSubForces from "../../CustomHooks/useGetSubForces";
interface IProps {}
const ModalExcel: React.FC<IProps> = (): JSX.Element => {
  const { t } = useTranslation();
  const { trainingTypes } = useContext(TrainingTypesContext);

  const { user } = useContext(UserContext);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [indicatorsSelection, setIndicatorsSelection] = useState<
    IIndicatorsSelection[]
  >([] as IIndicatorsSelection[]);
  const [tooltipsTitlesSelection, setTooltipsTitles] = useState<
    ITooltipsOptions[]
  >([] as ITooltipsOptions[]);

  const originalTooltipsTitlesSelectionQuery = useOriginalTooltipsTitles();
  const originalTooltipsTitlesSelection = useMemo(
    () => originalTooltipsTitlesSelectionQuery?.data ?? [],
    [originalTooltipsTitlesSelectionQuery?.data]
  );

  const [hashtagsSelections, setHashtags] = useState<ITooltipsOptions[]>(
    [] as ITooltipsOptions[]
  );
  // const [originalHashtags, setOriginalHashtags] = useState<ITooltipsOptions[]>(
  //   [] as ITooltipsOptions[]
  // );
  const [chartsType, setChartsData] = useState<IChartsSelection[]>([
    {
      isSelected: false,
      name: t("stations"),
      chartId: EChartId.PERFORMANCE_DETAILS,
    },
    { isSelected: false, name: t("indicators"), chartId: EChartId.RADAR },
    {
      isSelected: false,
      name: t("combatCapacity"),
      chartId: EChartId.LETHALITY,
    },
  ]);

  const color = "#2334B5";
  const [graphsData, setGraphsData] = useState<IForceAllData[]>(
    [] as IForceAllData[]
  );
  const [orbatForces, setOrbatForces] = useState<{
    forces: IForceTreeNode[];
    isSubordinates?: boolean;
  }>({ forces: [], isSubordinates: false });
  const { subForces } = useGetSubForces(
    orbatForces.forces,
    orbatForces.isSubordinates
  );
  const { checkedForces, relevantPlansIds } = useForcesChecked(subForces);

  const {
    plansOptions,
    selectedPlan,
    onPlanSelectedHandler,
    plansValuesForSelectedPlan,
  } = usePlans(relevantPlansIds, true, true);
  const {
    labelsCompetency,
    labelsDetails,
    thresholdsCompetency,
    thresholdsDetails,
  } = useInitGraphsData(plansValuesForSelectedPlan);
  useEffect(() => {
    setIndicatorsSelection([]);
    setTooltipsTitles((prev) =>
      prev.map((tt) => ({ ...tt, isSelected: false }))
    );
  }, [selectedPlan]);

  const { dataResult } = useReportGeneratorGrades(
    selectedPlan?.id,
    user?.forceToDisplayInOrbat?.id
  );
  useEffect(() => {
    if (checkedForces.length === 0) {
      setGraphsData([]);
      setLoading(false);
      setDownloadLoading(false);
      return;
    }
  }, [checkedForces]);

  const onChoose = (forces: IForceTreeNode[], isSubordinates?: boolean) => {
    setOrbatForces({ forces, isSubordinates });
  };

  const exportToCsvOrPDF = async (
    imagesRadar: string[],
    imagesBar: string[],
    imagesBadge: string[],
    exportMethod: EExportMethod
  ) => {
    let forcesData: IForceAllData[] = graphsData!.map(
      (force: IForceAllData, index) => {
        if (index !== undefined && index >= 0) {
          return {
            ...force,
            forceGradesData: {
              ...force.forceGradesData,
              forceResults: filterForceResults(
                force.forceGradesData.forceResults
              ),
              pic1: imagesRadar[index],
              pic2: imagesBar[index],
              pic3: imagesBadge[index],
            },
          };
        }
        return force;
      }
    );
    let isFinished = true;
    if (exportMethod === EExportMethod.excel)
      isFinished = await exportToCsv(forcesData, selectedPlan, t, true);
    if (
      exportMethod === EExportMethod.pdfDarkMode ||
      exportMethod === EExportMethod.pdfLightMode
    )
      isFinished = await exportToPdf(
        forcesData,
        selectedPlan,
        t,
        exportMethod === EExportMethod.pdfLightMode
      );
    isFinished
      ? toast.success(t("DownloadFileSuccessfully"))
      : toast.error(t("DownloadFileUnsuccessfully"));
    setDownloadLoading(false);
  };
  const filterForceResults = (
    forceResult: ITrainingPlanResult
  ): ITrainingPlanResult => {
    return {
      ...forceResult,
      indicatorResults: forceResult.indicatorResults
        .map((indicator) => {
          return {
            ...indicator,
            trainingTypeResults: indicator.trainingTypeResults
              .map((trainingType) => {
                return {
                  ...trainingType,
                  hashtagsResults: trainingType.hashtagsResults.filter(
                    (hashtag) =>
                      hashtagsSelections
                        .find(
                          (h) =>
                            Number(h.trainingTypeId) === Number(trainingType.id)
                        )
                        ?.selectionsOptions.find(
                          (h) => h.option.label === hashtag.name
                        )?.isSelected
                  ),
                  tooltipResults: {
                    ...trainingType.tooltipResults,
                    tooltipElementsResults:
                      trainingType.tooltipResults?.tooltipElementsResults.filter(
                        (tooltip) =>
                          tooltipsTitlesSelection
                            .find(
                              (t) =>
                                Number(t.trainingTypeId) ===
                                Number(trainingType.id)
                            )
                            ?.selectionsOptions.find(
                              (t) => tooltip.name === t.option.label
                            )?.isSelected
                      ),
                  },
                };
              })
              .filter(
                (t) =>
                  tooltipsTitlesSelection.find(
                    (tt) => tt.trainingTypeId === t.id
                  )?.isSelected
              ),
          };
        })
        .filter(
          (indicator) =>
            indicatorsSelection.find(
              (i) => Number(indicator.id) === Number(i.id)
            )?.isSelected
        ),
    };
  };

  const getImageAndExport = async (exportMethod: EExportMethod) => {
    if (checkedForces?.length === 0) return;
    setDownloadLoading(true);

    setTimeout(async () => {
      let imagesCompRadar: HTMLCollectionOf<Element>;
      let imagesArrayRadar = [];
      let imagesCompBar: HTMLCollectionOf<Element>;
      let imagesArrayBar = [];
      let imagesCompBadge: HTMLCollectionOf<Element>;
      let imagesArrayBadge = [];
      if (
        chartsType.find((chart) => chart.chartId === EChartId.RADAR)?.isSelected
      ) {
        imagesCompRadar = document.getElementsByClassName("csvRadarImage");
        imagesArrayRadar = Array.prototype.slice.call(imagesCompRadar);
      }
      if (
        chartsType.find(
          (chart) => chart.chartId === EChartId.PERFORMANCE_DETAILS
        )?.isSelected
      ) {
        imagesCompBar = document.getElementsByClassName("csvBarImage");
        imagesArrayBar = Array.prototype.slice.call(imagesCompBar);
      }
      if (
        chartsType.find((chart) => chart.chartId === EChartId.LETHALITY)
          ?.isSelected
      ) {
        imagesCompBadge = document.getElementsByClassName("csvBadgeImage");
        imagesArrayBadge = Array.prototype.slice.call(imagesCompBadge);
      }
      let allImagesRadar: string[] = [];
      let allImagesBar: string[] = [];
      let allImagesBadge: string[] = [];

      await Promise.all([
        await Promise.all(
          imagesArrayRadar?.map(async (element: HTMLElement) => {
            allImagesRadar.push(await toPng(element));
          })
        ),
        await Promise.all(
          imagesArrayBar?.map(async (element: HTMLElement) => {
            allImagesBar.push(await toPng(element));
          })
        ),
        await Promise.all(
          imagesArrayBadge?.map(async (element: HTMLElement) => {
            allImagesBadge.push(await toPng(element));
          })
        ),
      ]);
      await exportToCsvOrPDF(
        allImagesRadar,
        allImagesBar,
        allImagesBadge,
        exportMethod
      );
    }, 2000);
  };

  useEffect(() => {
    if (!dataResult && checkedForces?.length) {
      setLoading(true);
      return;
    } else setLoading(false);
    setGraphsData(() => {
      let forcesData: IForce[] = [];
      if (dataResult) findAllForcesResults(forcesData, dataResult);
      let forcesGradesData: IForceAllData[] = forcesData
        .map((force) => {
          let forceDetails = subForces?.find(
            (forceFromOrbat) => Number(force.id) === Number(forceFromOrbat.id)
          );
          if (forceDetails)
            return {
              forceDetails: forceDetails!,
              forceGradesData: {
                forceResults: force.results,
                affiliation: force.hierarchicalName
                  .map((affiliation) => {
                    return getForceFullName(
                      affiliation.name,
                      affiliation.forceType,
                      t
                    );
                  })
                  .join(" / "),
                isAttended: force.results.grade ? true : false,
                isSoldier: force.isSoldier,
              },
            };
        })
        .filter(
          (forceResults): forceResults is IForceAllData =>
            forceResults !== undefined
        );
      return forcesGradesData;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedForces, subForces, dataResult]);
  const findAllForcesResults = (forcesData: IForce[], dataResult: IForce) => {
    if (
      checkedForces.find((force) => Number(force.id) === Number(dataResult.id))
    ) {
      forcesData.push(dataResult);
    }
    if (dataResult.children?.length)
      dataResult.children.forEach((child) => {
        findAllForcesResults(forcesData, child);
      });
  };
  //get hashtags option for export currently not in use
  useEffect(() => {
    // let mounted: boolean = true;
    // const getHashtags = async (selectedPlan: IPlan) => {
    //   if (!selectedPlan.startDate || !selectedPlan.endDate) return;
    //   Axios.get(`${baseUrlPMBackend}performanceDetails/getHashtags`, {
    //     params: { selectedPlan: selectedPlan },
    //   }).then((res: any) => {
    //     let selectionsOptions: ITooltipsOptions[] = [];
    //     Object.keys(res.data).map((trainingType) => {
    //       let selectionOptions: ISelectionOption[] = [];
    //       res.data[trainingType].map((hashtag: string) => {
    //         if (hashtag !== "")
    //           selectionOptions.push({
    //             isSelected: false,
    //             option: {
    //               id: `${trainingType}_${hashtag}`,
    //               label: hashtag,
    //             },
    //           });
    //       });
    //       selectionsOptions.push({
    //         selectionsOptions: selectionOptions,
    //         trainingTypeName: trainingType,
    //         isSelected: false,
    //         trainingTypeId: Number(trainingType),
    //       });
    //     });
    //     mounted && setOriginalHashtags(selectionsOptions);
    //   });
    // };

    if (checkedForces?.length) setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlan]);

  useEffect(() => {
    if (
      trainingTypes &&
      trainingTypes.length &&
      originalTooltipsTitlesSelection &&
      originalTooltipsTitlesSelection.length
    ) {
      setTooltipsTitles(() =>
        originalTooltipsTitlesSelection.map((trainingTypeTooltips) => {
          let tt = trainingTypes.find(
            (trainingType) =>
              trainingType.name === trainingTypeTooltips.trainingTypeName
          );
          return {
            ...trainingTypeTooltips,
            trainingTypeId: tt ? Number(tt.id) : 0,
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainingTypes, originalTooltipsTitlesSelection]);
  // useEffect(() => {
  //   if (
  //     labelsDetails &&
  //     labelsDetails.length &&
  //     originalHashtags &&
  //     originalHashtags.length
  //   ) {
  //     setHashtags(() =>
  //       originalHashtags.map((trainingTypeTooltips) => {
  //         let tt = labelsDetails.find(
  //           (trainingType) =>
  //             Number(trainingType.id) ===
  //             Number(trainingTypeTooltips.trainingTypeId)
  //         );
  //         return {
  //           ...trainingTypeTooltips,
  //           trainingTypeName: tt
  //             ? tt.label
  //             : trainingTypeTooltips.trainingTypeName,
  //         };
  //       })
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [labelsDetails, originalHashtags]);
  useEffect(() => {
    let indicatorsSelections: IIndicatorsSelection[] = [];
    labelsCompetency?.forEach((labelRadar) => {
      let trainingTypeSelections: ISelection[] = [];
      labelsDetails
        ?.filter(
          (labelBar) =>
            Number(
              trainingTypes.find(
                (trainingType) => +trainingType.id === +labelBar.id
              )?.indicatorId
            ) === Number(labelRadar.id)
        )
        ?.forEach((bar) => {
          trainingTypeSelections.push({
            id: Number(bar.id),
            isSelected: false,
            label: bar.label,
          });
        });
      indicatorsSelections.push({
        id: Number(labelRadar.id),
        isSelected: false,
        label: labelRadar.label,
        trainingTypeSelections: trainingTypeSelections,
      });
    });
    setIndicatorsSelection(indicatorsSelections);
  }, [labelsCompetency, labelsDetails, trainingTypes]);
  return (
    <IonContent scrollY={false}>
      <PMLoading
        isOpen={isLoading}
        message={t("loadingData")}
        spinner={"bubbles"}
      />
      <PMLoading
        isOpen={downloadLoading}
        message={t("downloadFile")}
        spinner={"bubbles"}
      />

      <IonGrid className="modalGrid">
        <IonRow className=" rowAllM">
          <IonCol size="2" className="treeCol">
            <OrbatTree
              readonly
              checked={onChoose}
              checkedForces={subForces}
              canSelectAllChildren
            />
          </IonCol>
          <IonCol className="modalComp ">
            <IonRow className="headerRow">
              <IonCol className="exportModalCollapseDiv">
                <ExportModalCollapse
                  chartsType={chartsType}
                  hashtags={hashtagsSelections}
                  indicatorsSelection={indicatorsSelection}
                  isSelectionOpened={true}
                  tooltipsTitles={tooltipsTitlesSelection}
                  setChartsData={setChartsData}
                  setHashtags={setHashtags}
                  setIndicatorsSelection={setIndicatorsSelection}
                  setTooltipsTitles={setTooltipsTitles}
                  plansOptions={plansOptions}
                  onPlanSelectedHandler={onPlanSelectedHandler}
                  selectedPlan={selectedPlan}
                ></ExportModalCollapse>
              </IonCol>
              <div className="exportButtonEM">
                <PopOverButton
                  size="medium"
                  color="orange"
                  isDisabled={!checkedForces.length}
                  popOverItems={[
                    {
                      title: "pdfDarkMode",
                      onClick: () =>
                        getImageAndExport(EExportMethod.pdfDarkMode),
                      icon: EIconsSrc.EXPORT_PDF_DARK_MODE,
                    },
                    {
                      title: "pdfLightMode",
                      onClick: () =>
                        getImageAndExport(EExportMethod.pdfLightMode),
                      icon: EIconsSrc.EXPORT_PDF_LIGHT_MODE,
                    },
                    {
                      title: "excel",
                      onClick: () => getImageAndExport(EExportMethod.excel),
                      icon: EIconsSrc.XLS_FILE_EXPORT,
                    },
                  ]}
                  icon={{
                    iconSrc: EIconsSrc.EXPORT_ICON,
                    color: "xLight",
                    size: "large",
                  }}
                  label={{
                    fontSize: "large",
                    fontColor: "xLight",
                    fontFamily: "Regular",
                  }}
                >
                  {t("export")}
                </PopOverButton>
              </div>
            </IonRow>
            <IonRow className="modalCol">
              <div className="graphsRowEM">
                {graphsData?.map((data: IForceAllData) => (
                  <div className="modalCol" key={data.forceDetails.id}>
                    <div className="finalGradeModal">
                      <FinalGradeDisplay
                        key={data.forceDetails.id}
                        soldierFinalGrade={{
                          id: data.forceDetails.id,
                          name: `${data.forceDetails.name}`,
                          grade: Number(
                            data.forceGradesData.forceResults.grade
                          ),
                          legendColor: color,
                          isAttended: data.forceGradesData.isAttended === true,
                        }}
                        threshold={
                          selectedPlan ? Number(selectedPlan.lethality) : 0
                        }
                      />
                    </div>
                    <div className="graphRow rowModal">
                      <div className="graphWrap radarModal">
                        <RadarGraph
                          key={data.forceDetails.id}
                          className="csvRadarImage imageModal"
                          isModal={true}
                          color={color}
                          radarData={[
                            {
                              forceColor: "#ffffff",
                              id: Number(data.forceDetails.id),
                              indicators:
                                data.forceGradesData.forceResults
                                  .indicatorResults,
                              name: data.forceDetails.name,
                            },
                          ]}
                          selectedForces={checkedForces}
                          thresholds={thresholdsCompetency}
                          labels={labelsCompetency}
                          newForces={[
                            checkedForces?.find(
                              (force) =>
                                Number(force.id) ===
                                Number(data.forceDetails.id)
                            ),
                          ]}
                          forcesToRemove={[]}
                          plan={selectedPlan}
                        />
                      </div>
                      <div className="graphWrap barModal">
                        <BarChart
                          key={data.forceDetails.id}
                          className="csvBarImage imageModal"
                          isModal={true}
                          color={color}
                          barChartData={[
                            {
                              finalGrade:
                                data.forceGradesData.forceResults.grade,
                              gradesArray:
                                data.forceGradesData.forceResults.indicatorResults
                                  .map(
                                    (indicator: IIndicatorResult) =>
                                      indicator.trainingTypeResults
                                  )
                                  .flat(1)
                                  .map((trainingType: ITrainingTypeResult) => {
                                    return {
                                      grade: trainingType.grade,
                                      id: trainingType.id,
                                      isAttended: trainingType.participants > 0,
                                      tooltipResults:
                                        trainingType.tooltipResults,
                                      standardDeviation:
                                        trainingType?.tooltipResults
                                          ?.standardDeviation,
                                      name: trainingType.name,
                                    };
                                  }),

                              id: Number(data.forceDetails.id),
                              isAttended: true,
                              name: data.forceDetails.name,
                            },
                          ]}
                          thresholds={thresholdsDetails}
                          labels={labelsDetails}
                          selectedForces={checkedForces}
                          newForces={[
                            checkedForces?.find(
                              (force) =>
                                Number(force.id) ===
                                Number(data.forceDetails.id)
                            ),
                          ]}
                          forcesToRemove={[]}
                          barsType={"trainingTypes"}
                          plan={selectedPlan}
                          isDisableZoom
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};
export default ModalExcel;
