import exportToCsv from "./export-to-csv";
import { switchTrainingTypesArray } from "./finalReportHelpers";
import IPlan from "../../../Interfaces/IPlan";
import { IForceAllData } from "../../../Interfaces/IForceAllData";
import { IResult } from "../../../Interfaces/dataCalculator";
import { TFunction } from "i18next";
import { exportToPdf } from "./exportToPdf";
import { getForceFullName } from "../../../services/helpers";
const getRemark = (
  grade: number | undefined,
  plan: IPlan | undefined,
  t: any
) => {
  if (!grade) return t("notGoodGrade");
  if (grade >= 90) return t("excellent");
  else {
    if (grade >= 80) return t("veryGoog");
    else {
      if (plan && grade >= Number(plan.lethality)) return t("goodGrade");
      else return t("notGoodGrade");
    }
  }
};
const insertPicture = (
  picture: string | undefined,
  imagesArray: string[],
  emptyRows: number,
  csvFormatArray: string[][],
  picturesRowsIndexes: number[],
  sections: any[]
) => {
  if (picture && picture !== "") {
    sections.push({
      title: undefined,
      content: [{ type: "image", src: picture }],
    });
    imagesArray.push(picture);
    let picRow = csvFormatArray.length + 1;
    for (let index = 0; index < emptyRows; index++) {
      csvFormatArray.push([]);
    }
    picturesRowsIndexes.push(picRow);
  }
};
const insertToCsv = (
  header: { name: string; grade: number },
  data: IResult[],
  t: TFunction,
  isTooltips?: boolean
) => {
  let forceArray: string[] = [];
  let fixedHeader =
    header.grade !== null
      ? `${t(header.name)}: ${Math.round(header.grade)}`
      : `${t(header.name)}: ${t("notAttended")}`;
  forceArray.push(fixedHeader);

  data.forEach((resultsArray: IResult) => {
    let isNameNumber = false;
    if (!resultsArray.name || !isNaN(Number(resultsArray.name))) {
      isNameNumber = true;
    }
    if (!(isTooltips && resultsArray.value === undefined)) {
      let row =
        resultsArray.grade !== null && resultsArray.grade !== undefined
          ? !isNameNumber
            ? `${t(resultsArray.name)}: ${Math.round(resultsArray.grade)}`
            : `${Math.round(resultsArray.grade)}: ${t(resultsArray.name)}`
          : resultsArray.value !== undefined && resultsArray.value !== null
          ? !isNameNumber
            ? `${t(resultsArray.name)}: ${resultsArray.value}`
            : `${resultsArray.value}: ${t(resultsArray.name)}`
          : !isNameNumber
          ? `${t(resultsArray.name)}: ${
              isTooltips ? t("noValue") : t("notAttended")
            }`
          : `${isTooltips ? t("noValue") : t("notAttended")}}: ${t(
              resultsArray.name
            )}`;
      forceArray.push(row);
    }
  });

  return forceArray;
};
const exportToPdfAndCsv = (
  exportData: IForceAllData[],
  plan?: IPlan,
  t?: any,
  isFromModal?: boolean,
  title?: string, //for spike station
  pictureHeight?: number, //for spike station
  pictureWidth?: number //for spike station
) => {
  let imagesRadar: string[] = [];
  let imagesBar: string[] = [];
  let imagesBadge: string[] = [];

  let headersRowsIndexToBold: number[][] = [];
  let picturesRowsRadarIndex: number[] = [];
  let picturesRowsBarIndex: number[] = [];
  let picturesRowsBadgeIndex: number[] = [];
  let finalGradesIndex: number[] = [];
  let rowsIndexToBorder: number[][] = [];
  let headerRows: number[] = [];
  const emptyRowsForPictures = 13;
  const emptyRows = 2;
  let dataBorder: number[][] = [];
  let csvFormat: string[][] = [];
  let currentDate: Date = new Date();
  let fileName = `PerformanceReport_${String(currentDate.getDate()).padStart(
    2,
    "0"
  )}${String(currentDate.getMonth() + 1).padStart(2, "0")}${
    currentDate.getFullYear() % 100
  }${currentDate.getHours()}${currentDate.getMinutes()}${currentDate.getSeconds()}${
    exportData?.length === 1 ? "_" + exportData[0].forceDetails.name : ""
  }${
    exportData?.length === 1 && exportData[0].forceDetails.soldier_id !== null
      ? "_" + exportData[0].forceDetails.soldier_id
      : ""
  }`;
  let objectToPDF = {};
  let sections: any = [];
  if (exportData && exportData.length > 0) {
    for (let index = 0; index < 5; index++) {
      csvFormat.push([]);
    }
    if (exportData)
      exportData.forEach((forceData: IForceAllData) => {
        if (exportData.length > 0) {
          //name title
          let mane = `${t(
            forceData?.forceDetails.is_soldier
              ? "traineeReport"
              : "generalReport"
          )}: ${forceData?.forceDetails.name}`;
          csvFormat.push([mane]);
          objectToPDF = { title: mane };
          headerRows.push(csvFormat.length + 1);
          csvFormat.push([]);
          csvFormat.push([]);
          csvFormat.push([]);

          //plan
          if (plan && t && plan.startDate && plan.endDate) {
            let planDetails = `${t("trainingPlans")}: ${plan?.name} (${new Date(
              plan.startDate
            ).toLocaleDateString()} - ${new Date(
              plan.endDate
            ).toLocaleDateString()})`;
            csvFormat.push([planDetails]);
            csvFormat.push([]);
            sections.push({
              title: planDetails,
              content: [],
            });
          }

          //insert badge to array
          insertPicture(
            forceData.forceGradesData.pic3,
            imagesBadge,
            0,
            csvFormat,
            picturesRowsBadgeIndex,
            sections
          );

          //soldier id
          csvFormat.push([
            `${
              forceData?.forceDetails.soldier_id !== null &&
              Number(forceData?.forceDetails.soldier_id) !== 0
                ? `${t("soldierId")}: ${forceData?.forceDetails.soldier_id}`
                : ""
            }`,
          ]);
          sections.push({
            title: `${t("soldierId")}: ${forceData?.forceDetails.soldier_id}`,
            content: [],
          });
          csvFormat.push([]);
          //affiliation
          if (forceData?.forceGradesData.affiliation !== "") {
            csvFormat.push([
              `${forceData?.forceGradesData.affiliation.replaceAll("->", "/")}`,
            ]);
            csvFormat.push([]);
            sections.push({
              title: `${forceData?.forceGradesData.affiliation.replaceAll(
                "->",
                "/"
              )}`,
              content: [],
            });
          }

          //final grade
          let remark = getRemark(
            forceData.forceGradesData.forceResults.grade,
            plan,
            t
          );
          csvFormat.push([
            forceData.forceGradesData.forceResults.grade
              ? `${t("finalGrade")}: ${Math.round(
                  forceData.forceGradesData.forceResults.grade
                )} (${remark})`
              : `${t("finalGrade")}: ${0} (${remark})`,
          ]);
          sections.push({
            title: forceData.forceGradesData.forceResults.grade
              ? `${t("finalGrade")}: ${Math.round(
                  forceData.forceGradesData.forceResults.grade
                )} (${remark})`
              : `${t("finalGrade")}: ${0} (${remark})`,
            content: [],
          });
          finalGradesIndex.push(csvFormat.length + 1);
          csvFormat.push([]);

          //ranks

          if (forceData.forceDetails.nodes === null) {
            if (
              forceData.forceGradesData.forceResults.rankings &&
              forceData.forceGradesData.forceResults.rankings.length
            ) {
              csvFormat.push([`${t("rank")}`]);
              forceData.forceGradesData.forceResults.rankings.forEach(
                (rank) => {
                  csvFormat.push([`${t(rank.rank)}: ${rank.value}`]);
                }
              );
              sections.push({
                title: forceData.forceGradesData.forceResults.grade
                  ? `${t("finalGrade")}: ${Math.round(
                      forceData.forceGradesData.forceResults.grade
                    )} (${remark})`
                  : `${t("finalGrade")}: ${0} (${remark})`,
                content: [
                  {
                    type: `${t("rank")}`,
                    data: forceData.forceGradesData.forceResults.rankings.map(
                      (rank) =>
                        getForceFullName(rank.rank.name, rank.rank.forceType, t)
                    ),
                  },
                ],
              });
            }
          }

          let forceArray;

          //indicators

          csvFormat = insertTitle(
            title ? title : t("indicatorsAndTrainingTypesDetails"), //for spike station
            forceData.forceGradesData.forceResults.indicatorResults
              .map((t) => t.trainingTypeResults)
              .flat(1).length,
            csvFormat,
            sections
          );

          let startTable = 0;
          let forceIndicators: string[][] = [];
          if (forceData.forceGradesData.forceResults.indicatorResults.length) {
            startTable = csvFormat.length + 3;
            forceData.forceGradesData.forceResults.indicatorResults.forEach(
              (indicator) => {
                forceArray = insertToCsv(
                  indicator,
                  indicator.trainingTypeResults,
                  t
                );
                forceIndicators.push(forceArray);
              }
            );
          }

          csvFormat = decorateData(
            headersRowsIndexToBold,
            csvFormat,
            startTable,
            forceIndicators,
            rowsIndexToBorder,
            sections
          );

          let forceTrainingTypes: string[][] = [];

          let trainingTypes =
            forceData.forceGradesData.forceResults.indicatorResults
              .map((indicator) => indicator.trainingTypeResults)
              .flat(1);
          csvFormat.push([]);
          if (title === undefined)
            //for spike station
            csvFormat = insertTitle(
              t("tooltips"),
              trainingTypes.map((t) => t.tooltipResults).flat(1).length,
              csvFormat,
              sections
            );

          startTable = csvFormat.length + 3;
          trainingTypes.forEach((trainingType) => {
            if (trainingType.tooltipResults.tooltipElementsResults?.length) {
              forceArray = insertToCsv(
                trainingType,
                trainingType.tooltipResults.tooltipElementsResults,
                t,

                true
              );
              forceTrainingTypes.push(forceArray);
            }
          });

          csvFormat = decorateData(
            headersRowsIndexToBold,
            csvFormat,
            startTable,
            forceTrainingTypes,
            rowsIndexToBorder,
            sections
          );

          csvFormat = insertTitle(
            t("hashtags"),
            trainingTypes.map((t) => t.hashtagsResults).flat(1).length,
            csvFormat,
            sections
          );

          let forceHashtags: string[][] = [];
          startTable = csvFormat.length + 3;
          trainingTypes.forEach((trainingType) => {
            //hashtags
            if (trainingType.hashtagsResults.length) {
              forceArray = insertToCsv(
                trainingType,
                trainingType.hashtagsResults,

                t
              );
              forceHashtags.push(forceArray);
            }
          });

          csvFormat = decorateData(
            headersRowsIndexToBold,
            csvFormat,
            startTable,
            forceHashtags,
            rowsIndexToBorder,
            sections
          );

          for (let index = 0; index < emptyRows; index++) {
            csvFormat.push([]);
          }
          insertPicture(
            forceData.forceGradesData.pic1,
            imagesRadar,
            emptyRowsForPictures,
            csvFormat,
            picturesRowsRadarIndex,
            sections
          );
          insertPicture(
            forceData.forceGradesData.pic2,
            imagesBar,
            emptyRowsForPictures,
            csvFormat,
            picturesRowsBarIndex,
            sections
          );
        }
      });
    let columns = Math.max(...csvFormat.map((row) => row.length));

    return exportToCsv(
      `${fileName}`,
      headerRows,
      csvFormat,
      columns,
      finalGradesIndex,
      headersRowsIndexToBold,
      rowsIndexToBorder,
      dataBorder,
      imagesRadar,
      picturesRowsRadarIndex,
      picturesRowsBarIndex,
      picturesRowsBadgeIndex,
      t,
      isFromModal ? isFromModal : false,
      imagesBar.length === 0 ? undefined : imagesBar,
      imagesBadge.length === 0 ? undefined : imagesBadge,
      pictureHeight, //for spike station
      pictureWidth //for spike station
    );
  }
  return false;
};

export { exportToPdfAndCsv };
const insertTitle = (
  title: string,
  dataLength: number,
  csvFormat: string[][],
  sections: any[]
) => {
  if (dataLength > 0) {
    csvFormat.push([]);
    csvFormat.push([title]);
    csvFormat.push([]);
    sections.push({
      title: title,
      content: [],
    });
  }
  return csvFormat;
};
const decorateData = (
  headersRowsIndexToBold: number[][],
  csvFormat: string[][],
  startTable: number,
  forceData: string[][],
  rowsIndexToBorder: number[][],
  sections: any[]
) => {
  let tableLength =
    switchTrainingTypesArray(forceData).length +
    csvFormat.length +
    2 -
    startTable;
  if (tableLength < 0) return csvFormat;
  headersRowsIndexToBold.push([csvFormat.length + 2, forceData.length]);

  new Array(tableLength).fill(0).forEach((_val, index) => {
    rowsIndexToBorder.push([startTable + index, forceData.length]);
  });
  forceData = switchTrainingTypesArray(forceData);
  sections.push({
    title: undefined,
    content: [
      {
        type: "table",
        data: forceData,
        state: "split",
      },
    ],
  });
  csvFormat = csvFormat.concat(forceData);

  return csvFormat;
};
