// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.indicatorsGrid {
  overflow-y: scroll;
  height: 100%;
}

.indicatorRow {
  padding-bottom: 4.5%;
  width: 100%;
}

.loading {
  display: flex;
  width: 100%;
  color: #878a91;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.planGrid .tpInput {
  display: flex;
  align-items: center;
  align-self: center;
  --height: 45px;
  height: 45px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/TrainingPlan/Indicators.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,oBAAoB;EACpB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,WAAW;EACX,cAAc;EACd,aAAa;EACb,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,cAAc;EACd,YAAY;AACd","sourcesContent":[".indicatorsGrid {\n  overflow-y: scroll;\n  height: 100%;\n}\n\n.indicatorRow {\n  padding-bottom: 4.5%;\n  width: 100%;\n}\n\n.loading {\n  display: flex;\n  width: 100%;\n  color: #878a91;\n  display: flex;\n  text-align: center;\n  justify-content: center;\n  align-items: center;\n}\n\n.planGrid .tpInput {\n  display: flex;\n  align-items: center;\n  align-self: center;\n  --height: 45px;\n  height: 45px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
