// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.passDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 24px;
}

.passLabel {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  direction: ltr;
}
.passLabel:lang(he) {
  direction: rtl;
}

.form {
  display: flex;
}

.submitButton {
  box-shadow: var(--box-shadow);
  width: 3vw;
}
.cancelButton {
  --color: var(--ion-color-fontXLight);
  box-shadow: var(--box-shadow);
  margin-inline-start: 10px;
  margin-inline-end: 10px;
  width: 3vw;
}
.saveButton {
  margin-inline-start: 10px;
  margin-inline-end: 10px;
}
.buttonsRow {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/AdminPassword/AdminPassword.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,cAAc;AAChB;AACA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,6BAA6B;EAC7B,UAAU;AACZ;AACA;EACE,oCAAoC;EACpC,6BAA6B;EAC7B,yBAAyB;EACzB,uBAAuB;EACvB,UAAU;AACZ;AACA;EACE,yBAAyB;EACzB,uBAAuB;AACzB;AACA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".passDiv {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  padding: 24px;\n}\n\n.passLabel {\n  width: 90%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 20px;\n  direction: ltr;\n}\n.passLabel:lang(he) {\n  direction: rtl;\n}\n\n.form {\n  display: flex;\n}\n\n.submitButton {\n  box-shadow: var(--box-shadow);\n  width: 3vw;\n}\n.cancelButton {\n  --color: var(--ion-color-fontXLight);\n  box-shadow: var(--box-shadow);\n  margin-inline-start: 10px;\n  margin-inline-end: 10px;\n  width: 3vw;\n}\n.saveButton {\n  margin-inline-start: 10px;\n  margin-inline-end: 10px;\n}\n.buttonsRow {\n  margin-top: 20px;\n  display: flex;\n  flex-direction: row;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
