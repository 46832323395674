import { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import IForceTreeNode from "../../../../Interfaces/IForceTreeNode";
import IShootingDay from "../../../../Interfaces/IShootingDay";
import IShootingHeat from "../../../../Interfaces/IShootingHeat";
import "./ShootingRangeInputs.css";
import OrbatInput from "../../../Shared/Orbat/Input/OrbatInput";
import PMDropdown from "../../../Shared/PMDropdown/PMDropdown";
import IOption from "../../../../Interfaces/IOption";

type Props = {
  forcePath: string | undefined;
  selectedForces: IForceTreeNode[];
  setSelectedForces: Dispatch<SetStateAction<IForceTreeNode[]>>;
  selectedShootingDay: IShootingDay | undefined;
  selectedHeat: IShootingHeat | undefined;
  onChangeShootingDay: (currentValue: number) => void;
  onChangeHeat: (currentValue: number) => void;
  shootingDayOptions: IOption[];
  heatOptions: IOption[];
};

const ShootingRangeInputs: FC<Props> = (props: Props): JSX.Element => {
  const {
    forcePath,
    selectedForces,
    setSelectedForces,
    selectedShootingDay,
    selectedHeat,
    onChangeShootingDay,
    onChangeHeat,
    shootingDayOptions,
    heatOptions,
  } = props;
  const { t } = useTranslation();

  return (
    <div className="inputs-row">
      <div className="selected-shooting-day-input">
        <OrbatInput
          wrapperCss="selected-soldier"
          inputValue={`${forcePath ? forcePath : t("selectForces")}`}
          placeholder={`${t("selectForces")}`}
          inputWidth="long"
          isForDropDown
          collapseCssClass="treeCollapse  scrollM"
          selectedForces={selectedForces}
          setSelectedForces={setSelectedForces}
          limit={1}
        />
      </div>
      <div className="selected-shooting-day-input">
        <PMDropdown
          options={shootingDayOptions ? shootingDayOptions : []}
          onOptionChanges={(item) => onChangeShootingDay(+item.key)}
          selectedOption={
            selectedShootingDay
              ? {
                  value: t(selectedShootingDay?.name),
                  key: selectedShootingDay?.id,
                }
              : undefined
          }
          placeholder={t("shootingDay")}
          disabled={false}
        />
      </div>
      <div className="selected-heat-input">
        <PMDropdown
          options={heatOptions ? heatOptions : []}
          onOptionChanges={(item) => onChangeHeat(+item.key)}
          placeholder={t("heat")}
          disabled={false}
          selectedOption={
            selectedHeat
              ? {
                  value: t(selectedHeat?.name),
                  key: selectedHeat?.id,
                }
              : undefined
          }
        />
      </div>
    </div>
  );
};

export default ShootingRangeInputs;
