// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coming-soon {
  color: var(--ion-color-fontLight);
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-block-start: 35vh;
  font-size: 2.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/PersonalZone/Notifications/Notifications.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;EACnB,wBAAwB;EACxB,iBAAiB;AACnB","sourcesContent":[".coming-soon {\n  color: var(--ion-color-fontLight);\n  text-align: center;\n  justify-content: center;\n  align-items: center;\n  margin-block-start: 35vh;\n  font-size: 2.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
