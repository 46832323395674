// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.arrows {
  height: 50px;
  color: rgb(204, 198, 198);
  flex-direction: row;
  font-size: large;
  align-self: center;
  position: relative;
  padding-bottom: 20px;
}

div.arrows ion-icon:hover {
  cursor: pointer;
}

h1 {
  color: #ffff;
  font-family: Bold;
  margin-inline-start: 20px;
}

.graph-colT {
  width: 100%;
  height: 100%;
}

.barT {
  align-self: center;
  width: 100% !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.slidesWrap {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.TrendsToggle {
  margin-inline-start: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.barT .swiper-wrapper {
  height: 100% !important;
}
.barT .swiper {
  height: 85% !important;
  width: 98% !important;
}
.trendSlide {
  width: 100% !important;
  height: 100% !important;
  background-color: var(--ion-color-dark);
}

.trendsDropdownWrap {
  width: 300px;
}

.trendsIconContainerRow {
  width: min-content;
  display: flex;
  justify-content: flex-end;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Desktop/PerformanceDisplay/PerformanceTabs/PerformanceTrends/PerformanceTrends.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,yBAAyB;EACzB,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,qBAAqB;AACvB;AACA;EACE,wBAAwB;EACxB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,uBAAuB;AACzB;AACA;EACE,sBAAsB;EACtB,qBAAqB;AACvB;AACA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,uCAAuC;AACzC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,yBAAyB;AAC3B","sourcesContent":["div.arrows {\n  height: 50px;\n  color: rgb(204, 198, 198);\n  flex-direction: row;\n  font-size: large;\n  align-self: center;\n  position: relative;\n  padding-bottom: 20px;\n}\n\ndiv.arrows ion-icon:hover {\n  cursor: pointer;\n}\n\nh1 {\n  color: #ffff;\n  font-family: Bold;\n  margin-inline-start: 20px;\n}\n\n.graph-colT {\n  width: 100%;\n  height: 100%;\n}\n\n.barT {\n  align-self: center;\n  width: 100% !important;\n  height: 100% !important;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.slidesWrap {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n}\n.TrendsToggle {\n  margin-inline-start: 8px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n}\n.barT .swiper-wrapper {\n  height: 100% !important;\n}\n.barT .swiper {\n  height: 85% !important;\n  width: 98% !important;\n}\n.trendSlide {\n  width: 100% !important;\n  height: 100% !important;\n  background-color: var(--ion-color-dark);\n}\n\n.trendsDropdownWrap {\n  width: 300px;\n}\n\n.trendsIconContainerRow {\n  width: min-content;\n  display: flex;\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
