import Excel, { BorderStyle, Column } from "exceljs";
import { saveAs } from "file-saver";
import bagiraLogoBase64 from "./bagiraLogoBase64";
const exportToCsv = async (
  filename: string,
  headerRows: number[],
  rows: string[][],
  columnsCount: number,
  finalGradesIndex: number[],
  headersRowsIndexToBold: number[][],
  rowsIndexToBorder: number[][],
  dataBorder: number[][],
  imagesRadar: string[],
  picturesRowsRadarIndex: number[],
  picturesRowsBarIndex: number[],
  picturesRowsBadgeIndex: number[],
  t: any,
  fromModal: boolean,
  imagesBar?: string[],
  imagesBadge?: string[], //for spike station
  pic1Height?: number, //for spike station
  pic1Width?: number, //for spike station
  columnToBold?: number[],
  rowsToCenter?: number[],
  boldWithoutBorder?: boolean,
  imagesTrends?: {
    name: string;
    image: string;
  }[]
) => {
  const numbersToLetters = Array.from({ length: 26 }, (_, i) =>
    String.fromCharCode(65 + i)
  ).slice(0, columnsCount);
  const borderStyle: BorderStyle = "medium";
  const fontSizeHeader = 35;
  const fontSize = 24;
  const picHeight = pic1Height ? pic1Height : 400; //for spike station
  const picWidth = pic1Width ? pic1Width : 850; //for spike station
  let workbook = new Excel.Workbook();
  let worksheet = workbook.addWorksheet("sheet", {
    pageSetup: {
      orientation: "portrait",
      fitToWidth: 1,
      fitToHeight: 0,

      margins: {
        bottom: 0,
        footer: 0,
        left: 0.3,
        top: 0,
        right: 0.3,
        header: 0,
      },
    },

    views: [{ rightToLeft: true }],
  });

  let columnsObj = [];
  for (let index = 0; index < columnsCount; index++) {
    columnsObj.push({
      header: "",
      key: String(index),
      style: { font: { size: fontSize, name: "Ariel" } },
    });
  }
  worksheet.columns = columnsObj;

  worksheet.columns.forEach((column: Partial<Column>) => {
    column.width = 60;
    column.alignment = { horizontal: "center" };
  });

  rows.forEach((e: string[], index: number) => {
    const rowIndex = index + 1;
    worksheet.addRow({
      ...e,
      amountRemaining: {
        formula: `=C${rowIndex}-D${rowIndex}`,
      },
      percentRemaining: {
        formula: `=E${rowIndex}/C${rowIndex}`,
      },
    });
  });

  let logo = workbook.addImage({
    base64: bagiraLogoBase64,
    extension: "png",
  });
  if (logo !== undefined)
    worksheet.addImage(logo, {
      tl: {
        col: columnsObj.length < 8 ? 7 : columnsObj.length,
        row: 1,
      },
      ext: { width: 100, height: 120 },
      editAs: "absolute",
      // hyperlinks: { hyperlink: "wwww.google.com", tooltip: "bagira.com" },
    });
  if (!fromModal && !imagesBar && imagesRadar.length) {
    let imageId1 = workbook.addImage({
      base64: imagesRadar[0],
      extension: "png",
    });
    if (imageId1 !== undefined)
      worksheet.addImage(imageId1, {
        tl: {
          col: 0.5,
          row: picturesRowsRadarIndex[picturesRowsRadarIndex.length - 1],
        },
        ext: { width: picWidth, height: picHeight },
      });
  } else {
    if (imagesRadar?.length === picturesRowsRadarIndex.length)
      imagesRadar.forEach((pic: string, index: number) => {
        if (pic) {
          let imageId1 = workbook.addImage({
            base64: pic,
            extension: "png",
          });
          worksheet.addImage(imageId1, {
            tl: { col: 0.5, row: picturesRowsRadarIndex[index] },
            ext: { width: picWidth, height: picHeight },
          });
        }
      });
    if (imagesBar?.length === picturesRowsBarIndex.length)
      imagesBar?.forEach((pic: string, index: number) => {
        if (pic) {
          let imageId1 = workbook.addImage({
            base64: pic,
            extension: "png",
          });
          worksheet.addImage(imageId1, {
            tl: { col: 0.5, row: picturesRowsBarIndex[index] },
            ext: { width: picWidth, height: picHeight },
          });
        }
      });
    if (imagesBadge?.length === picturesRowsBadgeIndex.length)
      imagesBadge?.forEach((pic: string, index: number) => {
        if (pic) {
          let imageId1 = workbook.addImage({
            base64: pic,
            extension: "png",
          });
          worksheet.addImage(imageId1, {
            tl: {
              col: columnsObj.length - 1 < 5 ? 5 : columnsObj.length - 1,
              row: picturesRowsBadgeIndex[index],
            },
            ext: { width: 200, height: 230 },
          });
        }
      });
  }
  if (imagesTrends) {
    imagesTrends.forEach((trendImage, index) => {
      if (trendImage.image.length) {
        let imageId1 = workbook.addImage({
          base64: trendImage.image,
          extension: "png",
        });
        let rowIndex = rows.length + 4 + index * 21;
        const cell = worksheet.getCell(`B${rowIndex - 1}`);

        cell.value = trendImage.name;
        cell.font = { ...cell.font, bold: true };

        worksheet.addImage(imageId1, {
          tl: {
            col: 1,
            row: rowIndex,
          },
          ext: { width: picWidth, height: picHeight + 5 },
        });
      }
    });
  }
  finalGradesIndex.forEach((element) => {
    worksheet.getRow(element).font = { bold: true, size: fontSize };
  });
  headersRowsIndexToBold.forEach((element) => {
    worksheet.getRow(element[0]).font = { bold: true, size: fontSize };
    if (!boldWithoutBorder) {
      numbersToLetters.forEach((column, index) => {
        if (index < element[1])
          worksheet.getCell(`${[column]}${element[0]}}`).border = {
            top: { style: borderStyle },
            bottom: { style: borderStyle },
            left: {
              style: borderStyle,
            },
            right: {
              style: borderStyle,
            },
          };
      });
    }
  });
  rowsToCenter?.forEach((element) => {
    worksheet.getRow(element).alignment = { horizontal: "center" };
  });
  columnToBold?.forEach((element) => {
    worksheet.getCell(`${["A"]}${element}}`).font = {
      bold: true,
      size: fontSize,
    };
  });
  columnToBold?.forEach((element) => {
    worksheet.getCell(`${["A"]}${element}}`).font = {
      bold: true,
      size: fontSize,
    };
  });
  dataBorder.forEach((element) => {
    for (let row = element[0]; row < element[1]; row++) {
      // worksheet.getRow(row ).alignment = { horizontal: "center" };
      numbersToLetters.forEach((column, index) => {
        if (index < element[2])
          worksheet.getCell(`${column}${row}}`).border = {
            left: { style: borderStyle },
            right: { style: borderStyle },
          };
      });
    }
  });
  rowsIndexToBorder.forEach((element) => {
    numbersToLetters.forEach((column, index) => {
      if (index < element[1])
        worksheet.getCell(`${column}${element[0]}}`).border = {
          left: { style: borderStyle },
          right: { style: borderStyle },
          bottom: { style: borderStyle },
          top: { style: borderStyle },
        };
    });
  });

  headerRows.forEach((headerRow) => {
    worksheet.mergeCells(
      `${numbersToLetters[0]}${headerRow}:${
        numbersToLetters[numbersToLetters.length - 1]
      }${headerRow}`
    );

    worksheet.getRow(headerRow).alignment = { horizontal: "center" };
    worksheet.getRow(headerRow).font = {
      bold: true,
      underline: true,
      size: fontSizeHeader,
      name: "Ariel",
    };
  });
  autoWidth(worksheet);
  const buffer = await workbook.xlsx.writeBuffer();
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const fileExtension = ".xlsx";
  const blob = new Blob([buffer], { type: fileType });
  const formData = new FormData();
  formData.append(
    "excelFile",
    new Blob([buffer], { type: fileType }),
    `${filename}${fileExtension}`
  );
  saveAs(blob, filename + fileExtension);
  return true;
};
export default exportToCsv;
/**
 * if there is need to define all columns with the same width of the max text size*/
const autoWidth = (worksheet: any, minimalWidth = 40) => {
  worksheet.columns.forEach((column: any) => {
    let maxColumnLength = 0;
    column.eachCell({ includeEmpty: true }, (cell: any) => {
      maxColumnLength = Math.max(
        maxColumnLength,
        minimalWidth,
        cell.value ? cell.value.toString().length * 2 : 0
      );
    });

    column.width = maxColumnLength + 2;
  });
};
