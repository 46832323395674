import { IonButton } from "@ionic/react";
import React from "react";
import IHashtag from "../../../Interfaces/IHashtag";
import "./HashtagLabel.css";

interface HashtagLabelProps {
  hashtag: IHashtag;
  updateHashtag: (hashtag: IHashtag) => void;
}

const HashtagLabel = (props: HashtagLabelProps) => {
  return (
    <IonButton
      shape={"round"}
      className={`labelButton ${
        props.hashtag.isActive ? "activeHashtag" : "inactiveHashtag"
      }`}
      onClick={() => props.updateHashtag(props.hashtag)}
    >
      {props.hashtag.name}
    </IonButton>
  );
};

export default HashtagLabel;
