import React, { FC } from "react";
import { IonSpinner } from "@ionic/react";
import "./Spinner.css";

type Props = {
  className?: string;
};

const Spinner: FC<Props> = (props: Props) => {
  const { className } = props;
  return (
    <div className={className ? className : "loading-spinner"}>
      <IonSpinner name="crescent" color="x-light" />
    </div>
  );
};

export default Spinner;
