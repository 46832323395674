// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentContainerHS {
  flex-wrap: nowrap;
  --overflow: hidden !important;
  height: 100%;
}

.backButtonIconHS {
  height: 24px;
  width: 24px;
}
.gridHS {
  height: 100%;
}
.backButtonHS {
  --color: var(--ion-color-fontXLight);
  --border-radius: 30px !important;
  width: 44px;
  height: 44px;
  --icon-font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.itemsContainerHS {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
  padding: 0;
}
.pageHS {
  --ion-background-color: var(--ion-color-primary);
}

.heat-selection-wrap {
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Mobile/ManualStation/ShootingRangeDataPosting/HeatSelection.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;AACA;EACE,YAAY;AACd;AACA;EACE,oCAAoC;EACpC,gCAAgC;EAChC,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,WAAW;EACX,UAAU;AACZ;AACA;EACE,gDAAgD;AAClD;;AAEA;EACE,YAAY;AACd","sourcesContent":[".contentContainerHS {\n  flex-wrap: nowrap;\n  --overflow: hidden !important;\n  height: 100%;\n}\n\n.backButtonIconHS {\n  height: 24px;\n  width: 24px;\n}\n.gridHS {\n  height: 100%;\n}\n.backButtonHS {\n  --color: var(--ion-color-fontXLight);\n  --border-radius: 30px !important;\n  width: 44px;\n  height: 44px;\n  --icon-font-size: 24px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.itemsContainerHS {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  height: 90%;\n  padding: 0;\n}\n.pageHS {\n  --ion-background-color: var(--ion-color-primary);\n}\n\n.heat-selection-wrap {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
