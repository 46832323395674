// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warningsButton {
  height: 28px !important;
  opacity: 0.7;
}

.SCButton {
  margin-inline-start: 12px;
  margin-inline-end: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/Buttons/SaveCancelButtons.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,uBAAuB;AACzB","sourcesContent":[".warningsButton {\n  height: 28px !important;\n  opacity: 0.7;\n}\n\n.SCButton {\n  margin-inline-start: 12px;\n  margin-inline-end: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
