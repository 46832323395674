import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import ELanguage from "../Enums/ELanguage";

/*  HOW TO USE IN COMPONENTS:
      1. Add a translation here in the wanted language json
         name:"שם"
      2. In the component to want to translate something, add the useTranslation hook
         import {useTranslation} from 'react-i18next'
      3. Create a const to handle the translation
         const {t} = useTranslation();
      4. Send the name of the translation
         t("name");

    Example for interpolating value in translation:
    "Name": {"1":"שלום, {{username}}"}
*/
const Languages = [ELanguage.he, ELanguage.en, ELanguage.de, ELanguage.ua];
//only login page localization on client
const resources = {
  he: {
    translation: {
      login: "התחבר",
      loginError: "תקלה בנסיון ההתחברות",
      loginSucceed: "התחברת למערכת בהצלחה",
      wrongCredentials: "שם משתמש או סיסמה שגויים",
      userLockout: "המשתמש חסום",
      remainingTime: "הזמן הנותר לשחרור",
      userName: "שם משתמש",
      password: "סיסמה",
    },
  },

  en: {
    translation: {
      login: "Login",
      loginError: "There was en error trying to log you in",
      loginSucceed: "You Logged In Successfully",
      wrongCredentials: "Wrong user or password",
      userLockout: "User is locked",
      remainingTime: "Remaining time",
      userName: "User name",
      password: "Password",
    },
  },
  de: {
    translation: {
      login: "Anmelden",
      loginError: "Es gab einen Fehler beim Versuch, Sie anzumelden",
      loginSucceed: "Sie haben sich erfolgreich angemeldet",
      wrongCredentials: "Falscher Benutzer oder Passwort",
      userLockout: "Benutzer ist gesperrt",
      remainingTime: "Verbleibende Zeit",
      userName: "Benutzername",
      password: "Passwort",
    },
  },
  ua: {
    translation: {
      login: "Увійти",
      loginError: "Сталася помилка під час спроби входу",
      loginSucceed: "Ви успішно увійшли",
      wrongCredentials: "Неправильний користувач або пароль",
      userLockout: "Користувач заблокований",
      remainingTime: "Час, що залишився",
      userName: "Ім'я користувача",
      password: "Пароль",
    },
  },
};
i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: ELanguage.en,
  debug: false,
  whitelist: Languages,
  resources,
  lng: ELanguage.en,
});

export default i18n;
