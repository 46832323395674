import { FC, useContext, useEffect, useState } from "react";
import {
  IonCol,
  IonButton,
  IonContent,
  IonGrid,
  IonLabel,
  IonPage,
  IonRow,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import MobileHeader from "../../../components/Mobile/MobileHeader/MobileHeader";
import "./Competency.css";
import FinalGrade from "../../../components/Mobile/PersonalZone/Competency/FinalGrade/FinalGrade";
import IndicatorsRadar from "../../../components/Mobile/PersonalZone/Competency/IndicatorsRadar/IndicatorsRadar";
import PersonalPerformanceDetails from "../../../components/Mobile/PersonalZone/Competency/PersonalPerformanceDetails/PersonalPerformanceDetails";
import PersonalTrends from "../../../components/Mobile/PersonalZone/Competency/PersonalTrends/PersonalTrends";
import Rankings from "../../../components/Mobile/PersonalZone/Competency/Rankings/Rankings";
import useGetPerformanceGrades, {
  FilteredIndicatorsGrades,
} from "../../../components/CustomHooks/useGetPerformanceGrades";
import IForceTreeNode from "../../../Interfaces/IForceTreeNode";
import useInitGraphsData from "../../../components/CustomHooks/useInitGraphsData";
import useGetPerformanceTrends from "../../../components/CustomHooks/UseGetPerformaneTrends";
import useForcesChecked from "../../../components/CustomHooks/useForcesChecked";
import ForcesContext from "../../../context/ForcesContext/forcesContext";
import { UserContext } from "../../../context/UserContext/userContext";
import OrbatTreeModal from "../../../components/Mobile/OrbatTreeModal/OrbatTreeModal";
import Spinner from "../../../components/Shared/Spinner/Spinner";
import NotExistLabel from "../../../components/Shared/NotExistLabel/NotExistLabel";
import TooltipData from "../../../components/Mobile/PersonalZone/Competency/TooltipData/TooltipData";
import usePlans from "../../../components/CustomHooks/usePlans";
import { ECompetencyMode } from "../../../Enums/ECompetencyPageMode";
import { getForceFullName } from "../../../services/helpers";
import PlansDropdown from "../../../components/Shared/PlansDropdown/PlansDropdown";
interface ICompProps {
  mode: ECompetencyMode;
}

const Competency: FC<ICompProps> = (props: ICompProps) => {
  const { mode } = props;
  const { t } = useTranslation();

  const {
    forces: orbatForces,
    isForcesTreeOpen,
    setIsForcesTreeOpen,
  } = useContext(ForcesContext);
  const { user } = useContext(UserContext);
  const [isGradesLoading, setIsGradesLoading] = useState<boolean>(true);
  const [isTrendsLoading, setIsTrendsLoading] = useState<boolean>(true);
  const [isTooltipOpened, setIsTooltipOpened] = useState<boolean>(false);

  const [localOrbatForces, setLocalOrbatForces] = useState<
    IForceTreeNode[] | []
  >([]);

  useEffect(() => {
    let mounted: boolean = true;
    const getForce = async () => {
      if (user.relatedForce) {
        mounted &&
          setLocalOrbatForces([
            user.relatedForce
              ? {
                  ...user.relatedForce,
                  name: getForceFullName(
                    user.relatedForce!.name,
                    user.relatedForce.force_type,
                    t
                  ),
                }
              : user.relatedForce,
          ]);
      }
    };
    if (mode === ECompetencyMode.personal) {
      if (!localOrbatForces.length) getForce();
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localOrbatForces.length, mode, user.relatedForce]);

  useEffect(() => {
    let mounted: boolean = true;
    if (mode === ECompetencyMode.competency) {
      if (mounted) {
        setLocalOrbatForces(orbatForces);
      }
    }

    return () => {
      mounted = false;
    };
  }, [orbatForces, mode]);
  const { checkedForces, forcesToRemove, newForces, relevantPlansIds } =
    useForcesChecked(localOrbatForces);
  const {
    onPlanSelectedHandler,
    plansOptions,
    selectedPlan,
    globalPlans,
    plansValuesForSelectedPlan,
  } = usePlans(relevantPlansIds, true, true);

  const {
    labelsCompetency,
    thresholdsCompetency,
    thresholdsTrends,
    trendsIndicators,
    labelsTrends,
    labelsDetails,
    thresholdsDetails,
  } = useInitGraphsData(plansValuesForSelectedPlan, globalPlans);

  const { activePlanFinalGrades, indicatorsGrades, trainingTypesGrades } =
    useGetPerformanceGrades(
      selectedPlan,
      checkedForces,
      newForces,
      forcesToRemove,
      setIsGradesLoading
    );

  const { trendsBarChartsData } = useGetPerformanceTrends(
    globalPlans,
    newForces,
    forcesToRemove,
    checkedForces,
    trendsIndicators,
    setIsTrendsLoading,
    true
  );

  return (
    <IonPage>
      <MobileHeader
        isShowForcesTree={props.mode !== ECompetencyMode.personal}
      />
      <IonContent
        className="competency"
        scrollY={selectedPlan?.id !== undefined}
      >
        {selectedPlan?.id ? (
          <IonGrid>
            <OrbatTreeModal
              limit={3}
              isOpen={isForcesTreeOpen}
              close={() => setIsForcesTreeOpen(false)}
              mode="secondary"
            />

            <IonRow className="dropdownRow">
              <IonCol size={`12`}>
                <PlansDropdown
                  onPlanSelectedHandler={onPlanSelectedHandler}
                  plansOptions={plansOptions}
                  selectedPlan={selectedPlan}
                ></PlansDropdown>
              </IonCol>
            </IonRow>
            {!localOrbatForces.length ? (
              <NotExistLabel
                className="no-selected-forces"
                text="noSelectedForces"
              />
            ) : selectedPlan ? (
              <div>
                <IonRow className="finalGradeRow itemRow">
                  <div className="itemHeaderDiv">
                    <IonLabel className="itemHeaderLabel">
                      {t("lethality")}
                    </IonLabel>
                  </div>
                  {isGradesLoading || !activePlanFinalGrades ? (
                    <Spinner className="competency-spinner" />
                  ) : (
                    activePlanFinalGrades.map((finalGrade, index) => (
                      <FinalGrade
                        key={index}
                        lethality={finalGrade.soldierFinalGrade.grade}
                        isLoading={isGradesLoading}
                        forceName={finalGrade.soldierFinalGrade.name}
                        forceColor={finalGrade.soldierFinalGrade.legendColor}
                      ></FinalGrade>
                    ))
                  )}
                </IonRow>
                <IonRow className="radarRow itemRow">
                  <div className="itemHeaderDiv">
                    <IonLabel className="itemHeaderLabel">
                      {t("indicators")}
                    </IonLabel>
                  </div>

                  <IndicatorsRadar
                    plan={selectedPlan}
                    labels={labelsCompetency}
                    thresholds={thresholdsCompetency}
                    radarData={FilteredIndicatorsGrades(
                      indicatorsGrades,
                      labelsCompetency
                    )}
                    planGrades={activePlanFinalGrades.map((grade) => ({
                      id: grade.soldierFinalGrade.id,
                      grade: grade.soldierFinalGrade.grade,
                    }))}
                    isLoading={isGradesLoading}
                    force={checkedForces}
                    threshold={activePlanFinalGrades[0]?.threshold}
                    checkedForces={checkedForces}
                    forcesToRemove={forcesToRemove}
                  ></IndicatorsRadar>
                </IonRow>
                <IonRow className="performanceDetailsRow itemRow">
                  <div className="itemHeaderDiv">
                    <IonLabel className="itemHeaderLabel">
                      {t("stations")}
                    </IonLabel>
                    {isGradesLoading ? (
                      <div></div>
                    ) : (
                      <>
                        <IonButton
                          id="open-modal"
                          size="small"
                          onClick={() => {
                            setIsTooltipOpened(true);
                          }}
                        >
                          {t("moreDetails")}
                        </IonButton>

                        <TooltipData
                          tooltipData={trainingTypesGrades}
                          isOpen={isTooltipOpened}
                          setIsOpen={setIsTooltipOpened}
                        />
                      </>
                    )}
                  </div>
                  <PersonalPerformanceDetails
                    plan={selectedPlan}
                    isLoading={isGradesLoading}
                    barChartData={trainingTypesGrades}
                    thresholds={thresholdsDetails}
                    labels={labelsDetails}
                    force={checkedForces}
                    checkedForces={checkedForces}
                    forcesToRemove={forcesToRemove}
                  ></PersonalPerformanceDetails>
                </IonRow>
                {checkedForces.filter((force) => force.isSoldier).length ? (
                  <IonRow className="rankingsRow itemRow">
                    <div className="itemHeaderDiv">
                      <IonLabel className="itemHeaderLabel">
                        {t("rank")}
                      </IonLabel>
                    </div>
                    <Rankings
                      plan={selectedPlan}
                      checkedForces={checkedForces}
                    ></Rankings>
                  </IonRow>
                ) : (
                  <></>
                )}
                <IonRow className="trendsRow itemRow">
                  <div className="itemHeaderDiv">
                    <IonLabel className="itemHeaderLabel">
                      {t("trends")}
                    </IonLabel>
                  </div>
                  <PersonalTrends
                    isLoading={isTrendsLoading}
                    trendsChartsData={trendsBarChartsData}
                    indicators={trendsIndicators}
                    force={checkedForces}
                    labels={labelsTrends} //if we want relevant plans just switch to relevantLabelsTrends
                    thresholds={thresholdsTrends}
                    plan={selectedPlan}
                    checkedForces={checkedForces}
                    forcesToRemove={forcesToRemove}
                    mode={mode}
                  ></PersonalTrends>
                </IonRow>
              </div>
            ) : (
              <NotExistLabel text="noRelevantPlans" className="no-relevant" />
            )}
          </IonGrid>
        ) : (
          <Spinner />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Competency;
