// Dependencies
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSelector, useDispatch } from "react-redux";
// CSS
import "./TrainingPlansSideBar.css";
import { add } from "ionicons/icons";

// Interfaces
import IPlan from "../../../Interfaces/IPlan";
import {
  ADD_PLAN,
  deletePlan,
  duplicatePlan,
  fetchPlans,
  savePlanAttributes,
  SELECTED_PLAN,
  setActive,
  SET_EDIT_MODE,
} from "../../../redux/actions/plansActions";
import { AppState } from "../../../redux/store/plansStore";
import Alert from "../../Shared/Alert/Alert";
import { IPlansReducer } from "../../../redux/reducers/plans";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../context/UserContext/userContext";
import PopoverMenu from "../../Shared/Popover/PopoverMenu";
import PMLabel from "../../themeComponents/PMLabel";
import PopoverItem from "../../themeComponents/PopoverItem";
import PMButton from "../../themeComponents/PMButton";
import PlansList from "./PlansList";
import PasswordModal from "../AdminPassword/AdminPasswordModal";
import { checkSuperSoferPassword } from "../../../services/passwordsHandler";
import { ForcesContextDesktop } from "../../../context/ForcesContext/forcesContextProviderDesktop";
import useImportTrainingPlan from "../../CustomHooks/useImportTrainingPlan";
import usePlans from "../../CustomHooks/usePlans";

type IProps = {
  isUnsaved: () => boolean;
  setIsShowUnsaveModal: Dispatch<SetStateAction<boolean>>;
  setPlanToChange: Dispatch<SetStateAction<IPlan | undefined>>;
  setIsShowEditMode: Dispatch<SetStateAction<boolean>>;
  setEnableGlobalPlansEditing: React.Dispatch<React.SetStateAction<boolean>>;
  enableGlobalPlansEditing: boolean;
};

// Timeout before saving, to make sure
// all data updated in redux
const GET_PLANS_TIMEOUT = 1500;

const TrainingPlansSidebar: React.FC<IProps> = (props: IProps) => {
  const {
    isUnsaved,
    setIsShowUnsaveModal,
    setPlanToChange,
    setIsShowEditMode,
    setEnableGlobalPlansEditing,
  } = props;
  const { isAdmin } = useContext(UserContext);

  const editMode = useSelector<AppState, IPlansReducer>(
    (state) => state.plans
  ).editMode;

  const selectedPlan = useSelector<AppState, IPlansReducer>(
    (state) => state.plans
  ).selectedPlan;
  const { resetCompetencyForces } = useContext(ForcesContextDesktop);
  const { plans } = usePlans();
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const [isShowActiveModal, setIsShowActiveModal] = useState<boolean>(false);
  const [isShowDuplicateModal, setIsShowDuplicateModal] =
    useState<boolean>(false);
  const [planToDelete, setPlanToDelete] = useState<number>(-1);
  const [planToActive, setPlanToActive] = useState<number>(-1);
  const [planToDuplicate, setPlanToDuplicate] = useState<number>(-1);
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const dispatch = useDispatch<Dispatch<any>>();
  const { t } = useTranslation();

  const [isShowSuperSoferModal, setIsShowSuperSoferModal] =
    useState<boolean>(false);
  const [invalid, setInvalid] = useState<boolean>(false);
  const [planIdToEdit, setPlanIdToEdit] = useState<number>();

  // Adds new plan function
  const newPlanGenerator = async (type: string) => {
    // plan default values
    let newPlan: IPlan = {
      id: -1,
      name: type,
      startDate: new Date(),
      endDate: new Date(),
      lethality: 0,
      isTest: type === t("examName"),
      isActive: false,
      error: "",
      isGlobal: false,
      isEditable: true,
      allPlans: false,
    };

    // Redux function to save plan attributes
    await dispatch(savePlanAttributes(newPlan, selectedPlan, "newPlan", t));

    // After saving/editing in db, re-fetches plans
    setTimeout(() => {
      dispatch(fetchPlans(t));
    }, GET_PLANS_TIMEOUT);
    return newPlan;
  };

  const handleEdit = (id: number) => {
    setIsShowEditMode(true);
    let planToChange: IPlan | undefined = plans.find((plan) => plan.id === id);
    if (isUnsaved() && id !== selectedPlan?.id) {
      setPlanToChange(planToChange);
      setIsShowUnsaveModal(true);
    } else {
      dispatch({
        type: SELECTED_PLAN,
        selectedPlan: planToChange,
      });
    }
    dispatch({ type: SET_EDIT_MODE, editMode: true });
  };

  const handleImport = (planId: number) => {
    handleEdit(planId);
    setImportClicked(false);
    setImportOK(true);
  };
  const onSendPasswordHandler = async (password: string) => {
    const isPasswordValid = await checkSuperSoferPassword(password, setInvalid);
    if (isPasswordValid && planIdToEdit) {
      //is password enter for deleting rows
      handleEdit(planIdToEdit);
      setEnableGlobalPlansEditing(true);
      setIsShowSuperSoferModal(false);
      if (importClicked) handleImport(planIdToEdit);
    }
  };
  /**
   * if the plan is not global or if the password has already
   * been confirmed handleEdit otherwise open password modal
   * @param planId - planId
   */
  const openPlanEdit = (planId: number) => {
    //disable super Sofer password every time enter editing
    setEnableGlobalPlansEditing(false);
    if (!plans.find((plan) => plan.id === planId)?.isGlobal) handleEdit(planId);
    else {
      setPlanIdToEdit(planId);
      setIsShowSuperSoferModal(true);
    }
  };
  const openPlanImport = (planId: number) => {
    setEnableGlobalPlansEditing(false);
    setImportClicked(true);
    setImportOK(false);
    if (!plans.find((plan) => plan.id === planId)?.isGlobal) {
      setPlanIdToEdit(planId);
      handleImport(planId);
    } else {
      setPlanIdToEdit(planId);
      setIsShowSuperSoferModal(true);
    }
    //disable super Sofer password every time enter editing
  };
  // First function to occur, changes the id to manipulate
  const handlePlanDuplicate = (id: number) => {
    setPlanToDuplicate(id);
  };
  // First function to occur, changes the id to manipulate
  const handlePlanDelete = (id: number) => {
    setPlanToDelete(id);
  };

  // First function to occur, changes the id to manipulate
  const handlePlanActive = (id: number) => {
    setPlanToActive(id);
    resetCompetencyForces();
  };

  // Listens to planToDelete and pops popover in case of change
  useEffect(() => {
    if (planToDelete !== -1) setIsShowDeleteModal(true);
  }, [planToDelete]);

  // Listens to planToActive and pops popover in case of change
  useEffect(() => {
    if (planToActive !== -1) setIsShowActiveModal(true);
  }, [planToActive]);

  // Listens to planToDuplicate and pops popover in case of change
  useEffect(() => {
    if (planToDuplicate !== -1) setIsShowDuplicateModal(true);
  }, [planToDuplicate]);

  //if new plan selected - disable the global plans edit mode
  useEffect(() => {
    setEnableGlobalPlansEditing(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlan]);
  const [importClicked, setImportClicked] = useState<boolean>(false);
  const [importOK, setImportOK] = useState<boolean>(false);

  const { getInputProps, getRootProps, ref } = useImportTrainingPlan(
    selectedPlan,
    importOK,
    setImportOK,
    planIdToEdit
  );
  return !plans ? (
    <h1 className="loadingPlans">{t("loadingPlans")}</h1>
  ) : (
    <React.Fragment>
      <div className="plansGrid">
        <div className="ion-justify-content-start">
          {isAdmin ? (
            <div className="addPlanDiv">
              <PMButton
                size="medium"
                color="orange"
                fill="outline"
                icon={{ iconSrc: add, color: "orange", size: "medium" }}
                onClickHandler={(e: any) => {
                  e.persist();
                  setShowPopover({ showPopover: true, event: e });
                }}
                label={{
                  fontSize: "medium",
                  fontColor: "light",
                  fontFamily: "Regular",
                }}
              >
                {t("add")}
              </PMButton>
            </div>
          ) : null}
          <PopoverMenu
            popoverState={popoverState}
            onDismiss={() =>
              setShowPopover({ showPopover: false, event: undefined })
            }
          >
            <PopoverItem
              fontColor="light"
              onClickHandler={() => {
                dispatch({
                  type: ADD_PLAN,
                  plan: newPlanGenerator(t("trainName")),
                });
                setShowPopover({ showPopover: false, event: undefined });
              }}
              background="Blight"
            >
              <PMLabel fontFamily="Light" fontSize="medium" textAlign="center">
                {t("addTraining")}
              </PMLabel>
            </PopoverItem>
            <PopoverItem
              fontColor="light"
              onClickHandler={() => {
                dispatch({
                  type: ADD_PLAN,
                  plan: newPlanGenerator(t("examName")),
                });
                setShowPopover({ showPopover: false, event: undefined });
              }}
              background="Blight"
            >
              <PMLabel fontFamily="Light" fontSize="medium" textAlign="center">
                {t("addExam")}
              </PMLabel>
            </PopoverItem>
          </PopoverMenu>
        </div>
        <div className="ion-justify-content-end plansRow">
          <div className="plansDiv">
            {/* Renders global plans */}
            <PlansList
              isGlobal
              plans={plans}
              isAdmin={isAdmin}
              selectedPlan={selectedPlan}
              isUnsaved={isUnsaved}
              handlePlanActive={handlePlanActive}
              handlePlanDelete={handlePlanDelete}
              handlePlanDuplicate={handlePlanDuplicate}
              handlePlanEdit={openPlanEdit}
              setIsShowUnsaveModal={setIsShowUnsaveModal}
              setPlanToChange={setPlanToChange}
              setIsShowEditMode={setIsShowEditMode}
              isEditMood={editMode}
              handlePlanImport={openPlanImport}
            ></PlansList>
            {plans.filter((plan: IPlan) => plan.isGlobal).length ? (
              <div className="tpListDivider"></div>
            ) : null}
            {/* Renders local plans */}
            <PlansList
              isGlobal={false}
              plans={plans}
              isAdmin={isAdmin}
              selectedPlan={selectedPlan}
              isUnsaved={isUnsaved}
              handlePlanActive={handlePlanActive}
              handlePlanDelete={handlePlanDelete}
              handlePlanDuplicate={handlePlanDuplicate}
              handlePlanEdit={openPlanEdit}
              setIsShowUnsaveModal={setIsShowUnsaveModal}
              setPlanToChange={setPlanToChange}
              setIsShowEditMode={setIsShowEditMode}
              isEditMood={editMode}
              handlePlanImport={openPlanImport}
            ></PlansList>
          </div>
        </div>
      </div>
      <Alert
        header={t("deletePlanAlert")}
        isOpen={isShowDeleteModal}
        setIsOpen={setIsShowDeleteModal}
        actionOnSave={() => dispatch(deletePlan(planToDelete!, t))}
        actionOnCancel={() => {
          setPlanToDelete(-1);
          setIsShowDeleteModal(false);
        }}
      />
      <Alert
        header={t("setPlanActiveAlert")}
        isOpen={isShowActiveModal}
        setIsOpen={setIsShowActiveModal}
        actionOnSave={() => {
          dispatch(setActive(planToActive, t));
          setPlanToActive(-1);
        }}
        actionOnCancel={() => {
          setPlanToActive(-1);
          setIsShowActiveModal(false);
        }}
      />
      <Alert
        header={t("duplicatePlanAlert")}
        isOpen={isShowDuplicateModal}
        setIsOpen={setIsShowDuplicateModal}
        actionOnSave={() => {
          dispatch(duplicatePlan(planToDuplicate, t));
          setPlanToDuplicate(-1);
        }}
        actionOnCancel={() => {
          setPlanToDuplicate(-1);
          setIsShowDeleteModal(false);
        }}
      />
      <PasswordModal
        onSendPasswordHandler={(password: string) =>
          onSendPasswordHandler(password)
        }
        onCancelHandler={() => setIsShowSuperSoferModal(false)}
        invalid={invalid}
        text={`${t("enterSuperSoferPassword")} ${t("inOrderToEditPlan")}`}
        isOpen={isShowSuperSoferModal}
      ></PasswordModal>
      <div {...getRootProps()} ref={ref}>
        <input {...getInputProps()} />
      </div>
    </React.Fragment>
  );
};

export default TrainingPlansSidebar;
