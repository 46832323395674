import { baseUrlPMBackendBasic, environment } from "../Configurations/consts";
import EEnvironment from "../Enums/EEnvironment";
const io = require("socket.io-client");

let socket: any;
const initIO = () => {
  if (!socket) {
    socket = io(baseUrlPMBackendBasic, {
      extraHeaders: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
  }
};
const addRoomIO = (room: string) => {
  if (!socket) initIO();
  if (socket) socket.emit("addroom", room);
};
const getIO = () => {
  return socket;
};

export { initIO, getIO, addRoomIO };
