import React, { ReactNode, useState } from "react";
import IShootingDay from "../../Interfaces/IShootingDay";
import IShootingHeat from "../../Interfaces/IShootingHeat";
interface IShootingRangeContext {
  shootingDays: IShootingDay[];
  setShootingDays: React.Dispatch<React.SetStateAction<IShootingDay[]>>;
  heats: IShootingHeat[];
  setHeats: React.Dispatch<React.SetStateAction<IShootingHeat[]>>;
}
export const ShootingRangeContext = React.createContext<IShootingRangeContext>({
  shootingDays: [],
  setShootingDays: () => {},
  heats: [],
  setHeats: () => {},
});
const ShootingRangeContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [shootingDays, setShootingDays] = useState<IShootingDay[]>([]);
  const [heats, setHeats] = useState<IShootingHeat[]>([]);

  return (
    <ShootingRangeContext.Provider
      value={{ shootingDays, heats, setHeats, setShootingDays }}
    >
      {children}
    </ShootingRangeContext.Provider>
  );
};
export default ShootingRangeContextProvider;
