import { FC } from "react";

import "./InstructorFeedback.css";
import { useHistory, useParams } from "react-router-dom";
import InstructorFeedbackComponent from "./InstructorFeedbackComponent";
import DataPostingMobileTemplate from "../../DataPosting/DataPostingMobileTemplate";

const InstructorFeedback: FC = () => {
  const history = useHistory();

  const params = useParams<{
    traineeName: string;
    traineeId: string;
    trainingTypeId: string;
    stationId: string;
    stationName: string;
  }>();

  const backHandler = () => {
    history.goBack();
  };

  const traineeId = parseInt(params.traineeId);
  const traineeName = params.traineeName;
  const stationName = params.stationName;
  const trainingTypeId = Number(params.trainingTypeId);
  return (
    <DataPostingMobileTemplate
      stationName={stationName}
      traineeName={traineeName}
    >
      <InstructorFeedbackComponent
        traineeId={traineeId}
        afterPostHandler={backHandler}
        trainingTypeId={trainingTypeId}
      ></InstructorFeedbackComponent>
    </DataPostingMobileTemplate>
  );
};

export default InstructorFeedback;
