// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentContainerTL {
  --ion-background-color: var(--ion-color-primary);
  flex-wrap: nowrap;
  --overflow: hidden !important;
  height: 100%;
}

.stationNameItem {
  border-color: var(--ion-color-dividerAndroidTitle);
  --background: var(--ion-color-primary);
  --color: var(--ion-color-fontXLight);
  font-size: large;
  font-family: "Bold";
  padding: 0;
  margin-top: 10px;
}

.backButtonIconTL {
  height: 24px;
  width: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Mobile/Stations/StationsTraineesList/StationsTraineesList.css"],"names":[],"mappings":"AAAA;EACE,gDAAgD;EAChD,iBAAiB;EACjB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,kDAAkD;EAClD,sCAAsC;EACtC,oCAAoC;EACpC,gBAAgB;EAChB,mBAAmB;EACnB,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb","sourcesContent":[".contentContainerTL {\n  --ion-background-color: var(--ion-color-primary);\n  flex-wrap: nowrap;\n  --overflow: hidden !important;\n  height: 100%;\n}\n\n.stationNameItem {\n  border-color: var(--ion-color-dividerAndroidTitle);\n  --background: var(--ion-color-primary);\n  --color: var(--ion-color-fontXLight);\n  font-size: large;\n  font-family: \"Bold\";\n  padding: 0;\n  margin-top: 10px;\n}\n\n.backButtonIconTL {\n  height: 24px;\n  width: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
