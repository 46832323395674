import { Dispatch } from "react";
import { AppActions } from "./rootActions";
import axios from "../../Axios";
import { baseUrlPMBackend } from "../../Configurations/consts";
import customToast from "../../components/Shared/Toast/CustomToast";
import {
  IElement,
  IIndicator,
  ITrainingType,
} from "../../Interfaces/ITrainingPlan";

export const EDIT_ELEMENT = "EDIT_ELEMENT";
export const EDIT_TRAINING_TYPE = "EDIT_TRAINING_TYPE";
export const ADD_INDICATOR = "ADD_INDICATOR";
export const EDIT_INDICATOR = "EDIT_INDICATOR";
export const SET_INDICATOR = "SET_INDICATOR";
export const CANCEL_CHANGES = "CANCEL_CHANGES";
export const DELETE_INDICATOR = "DELETE_INDICATOR";
export const UPDATE_INDICATOR = "UPDATE_INDICATOR";
export const SAVE_INDICATORS_REQUEST = "SAVE_INDICATORS_REQUEST";
export const SAVE_INDICATORS_SUCCESS = "SAVE_INDICATORS_SUCCESS";
export const SAVE_INDICATORS_FAILURE = "SAVE_INDICATORS_FAILURE";
export const FETCH_INDICATORS_SUCCESS = "FETCH_INDICATORS_SUCCESS";
export const SAVE_PLAN = "SAVE_PLAN";
export const RESET_INDICATORS_UPDATE_COUNTER =
  "RESET_INDICATORS_UPDATE_COUNTER";
export const INCREMENT_INDICATORS_UPDATE_COUNTER =
  "INCREMENT_INDICATORS_UPDATE_COUNTER";
export const INCREMENT_INDICATORS_RENDER_COUNTER =
  "INCREMENT_INDICATORS_RENDER_COUNTER";
export const DECREMENT_INDICATORS_RENDER_COUNTER =
  "DECREMENT_INDICATORS_RENDER_COUNTER";
export const RESET_INDICATORS_RENDER_COUNTER =
  "RESET_INDICATORS_RENDER_COUNTER";

export interface fetchIndicatorsSuccess {
  type: typeof FETCH_INDICATORS_SUCCESS;
  indicators: IIndicator[];
}

export interface EditTrainingTypeAction {
  type: typeof EDIT_TRAINING_TYPE;
  trainingType: ITrainingType;
}
export interface EditElementAction {
  type: typeof EDIT_ELEMENT;
  element: IElement;
}
export interface AddIndicatorAction {
  type: typeof ADD_INDICATOR;
  indicator: IIndicator;
}

export interface EditIndicatorAction {
  type: typeof EDIT_INDICATOR;
  indicator: IIndicator;
}
export interface SetIndicatorAction {
  type: typeof SET_INDICATOR;
  indicator: IIndicator;
  idToUpdate: number | undefined;
}
export interface CancelChangesAction {
  type: typeof CANCEL_CHANGES;
}

export interface DeleteIndicatorAction {
  type: typeof DELETE_INDICATOR;
  id: number;
}

export interface UpdateIndicator {
  type: typeof UPDATE_INDICATOR;
  indicator: IIndicator;
}

export interface SaveIndicatorsRequest {
  type: typeof SAVE_INDICATORS_REQUEST;
}

export interface SaveIndicatorsSuccess {
  type: typeof SAVE_INDICATORS_SUCCESS;
  indicators: IIndicator[];
}

export interface SaveIndicatorsFailure {
  type: typeof SAVE_INDICATORS_FAILURE;
  error: Error | "";
}

export interface IncrementUpdateCounter {
  type: typeof INCREMENT_INDICATORS_UPDATE_COUNTER;
}

export interface ResetUpdateCounter {
  type: typeof RESET_INDICATORS_UPDATE_COUNTER;
}

export interface DecrementRenderCounter {
  type: typeof DECREMENT_INDICATORS_RENDER_COUNTER;
}

export interface IncrementRenderCounter {
  type: typeof INCREMENT_INDICATORS_RENDER_COUNTER;
}

export interface ResetRenderCounter {
  type: typeof RESET_INDICATORS_RENDER_COUNTER;
}
export interface SavePlan {
  type: typeof SAVE_PLAN;
}

export const savePlanValues = (
  indicators: IIndicator[],
  initIndicators: IIndicator[],
  planId: number,
  t: any
) => {
  let indicatorsToDelete = initIndicators
    .filter((i) => {
      return !indicators.find((ii) => ii.indicatorId === i.indicatorId);
    })
    .map((i) => i.indicatorId);

  return async (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: SAVE_INDICATORS_REQUEST,
    });

    axios
      .post(`${baseUrlPMBackend}plans/savePlanValues`, {
        planValues: indicators,
        indicatorsToDelete: indicatorsToDelete,
        planId: planId,
      })
      .then(() => {
        dispatch({ type: SAVE_INDICATORS_SUCCESS, indicators: indicators });
      })
      .catch((error) => {
        dispatch({ type: SAVE_INDICATORS_FAILURE, error: error });
        customToast.error(t("errorWhileSavePlan"));
      });
  };
};

export const incrementCounter = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INCREMENT_INDICATORS_UPDATE_COUNTER });
  };
};

export const incrementRenderCounter = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INCREMENT_INDICATORS_RENDER_COUNTER });
  };
};

export const decrementRenderCounter = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: DECREMENT_INDICATORS_RENDER_COUNTER });
  };
};

// Indicator types
export type IndicatorActionTypes =
  | EditTrainingTypeAction
  | AddIndicatorAction
  | EditIndicatorAction
  | DeleteIndicatorAction
  | UpdateIndicator
  | SaveIndicatorsRequest
  | SaveIndicatorsSuccess
  | SaveIndicatorsFailure
  | IncrementUpdateCounter
  | ResetUpdateCounter
  | IncrementRenderCounter
  | DecrementRenderCounter
  | ResetRenderCounter
  | fetchIndicatorsSuccess
  | SetIndicatorAction
  | CancelChangesAction
  | EditElementAction;
