// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-trainees-list {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: transparent;
}

.small-trainees-list-wrapper {
  height: 75%;
}

.regular-trainees-list-wrapper {
  height: 68%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Mobile/ManualStation/ShootingRange/ResultsScreen/ShootingRangeTraineesList/TraineesList.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb","sourcesContent":[".mobile-trainees-list {\n  width: 100%;\n  height: 100%;\n  overflow-y: auto;\n  background: transparent;\n}\n\n.small-trainees-list-wrapper {\n  height: 75%;\n}\n\n.regular-trainees-list-wrapper {\n  height: 68%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
