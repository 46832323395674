// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SGDButtonWrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.hasifaTkifaDesktopTemplateWrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.hasifaTkifaDesktopTemplateWrap.touch {
  zoom: 1.2;
}
.SGComponentRow {
  overflow-y: auto;
  flex: 1 1;
}
.componentCompleted {
  pointer-events: none;
}
.componentCompleted::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2); /* Black color with 50% opacity */
  z-index: 9999; /* Ensure overlay is on top */
  pointer-events: none; /* Allow clicking through the overlay */
}
.SGTitleRow {
  height: 60px;
  margin-top: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/DataPosting/HASIFA_TKIFA/HasifaTkifaTrainingTemplate.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;AACA;EACE,SAAS;AACX;AACA;EACE,gBAAgB;EAChB,SAAO;AACT;AACA;EACE,oBAAoB;AACtB;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC,EAAE,iCAAiC;EACvE,aAAa,EAAE,6BAA6B;EAC5C,oBAAoB,EAAE,uCAAuC;AAC/D;AACA;EACE,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".SGDButtonWrap {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n}\n.hasifaTkifaDesktopTemplateWrap {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n.hasifaTkifaDesktopTemplateWrap.touch {\n  zoom: 1.2;\n}\n.SGComponentRow {\n  overflow-y: auto;\n  flex: 1;\n}\n.componentCompleted {\n  pointer-events: none;\n}\n.componentCompleted::before {\n  content: \"\";\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.2); /* Black color with 50% opacity */\n  z-index: 9999; /* Ensure overlay is on top */\n  pointer-events: none; /* Allow clicking through the overlay */\n}\n.SGTitleRow {\n  height: 60px;\n  margin-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
