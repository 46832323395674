import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import "./InstructorFeedbackMobileElement.css";
import { InstructorFeedbackGrades } from "../../../../Interfaces/IInstructorFeedback";
import { IonCol, IonList, IonRow } from "@ionic/react";
import InstructorFeedbackElementHook from "../../../CustomHooks/InstructorFeedbackElementHook";
import PMLabel from "../../../themeComponents/PMLabel";
import PMButton from "../../../themeComponents/PMButton";
import InstructorFeedbackGrade from "../../../Shared/MenualStation/InstructorFeedback/InstructorFeedbackGrade";
export interface IProps {
  title: string;
  elements: string[];
  dataType: string;
  setGrade: Dispatch<SetStateAction<InstructorFeedbackGrades[]>>;
  resetAll: boolean;
  setResetAll: (state: boolean) => void;
  index: number;

  isMust: boolean;
}
const InstructorFeedbackMobileElement: React.FC<IProps> = (
  props: IProps
): JSX.Element => {
  const {
    title,
    elements,
    dataType,
    setGrade,
    resetAll,
    setResetAll,

    isMust,
  } = props;
  const { t } = useTranslation();

  const { setElementGrade, finalGrade } = InstructorFeedbackElementHook(
    elements,
    dataType,
    setGrade,
    resetAll,
    setResetAll
  );

  return (
    <React.Fragment>
      <IonCol className="UFIMobileElement">
        <div className="IFtitleRow">
          <PMLabel fontSize="large" fontFamily="Light" fontColor="light">
            {t(title)}
          </PMLabel>
          {isMust ? <span className="isMustCircleMobile">{isMust}</span> : null}
        </div>
        <IonRow className="elementsRowMobile">
          <IonCol className="colIFM leftBorder">
            <IonList className="listIFM">
              {elements.map((element, index) => (
                <div className="itemIFM" key={index}>
                  <PMLabel
                    fontColor="light"
                    fontFamily="Light"
                    fontSize="medium"
                    cssClass="labelIFM"
                  >
                    {element}
                  </PMLabel>
                  <div className="IFGradeRow" key={index}>
                    <InstructorFeedbackGrade
                      isToRest={resetAll}
                      isTraineeSelected={true}
                      onGradeSelected={(grade: number | undefined) => {
                        setElementGrade(element, grade);
                      }}
                      setIsToRest={setResetAll}
                    ></InstructorFeedbackGrade>
                  </div>
                </div>
              ))}
            </IonList>
          </IonCol>
        </IonRow>
        <IonRow className="averageGradeRow">
          <PMLabel fontSize="xxLarge" fontFamily="Light" fontColor="light">
            {t("avgGrade")}
          </PMLabel>
        </IonRow>
        <IonRow className="finalGradeRowUFI">
          <PMLabel
            fontSize="large"
            fontFamily="Bold"
            fontColor="light"
            textAlign="center"
          >
            {finalGrade}
          </PMLabel>
        </IonRow>
      </IonCol>
    </React.Fragment>
  );
};

export default InstructorFeedbackMobileElement;
