import { AxiosResponse } from "axios";
import axios from "../Axios";
import { APIs } from "../Configurations/urls";
import IPlan from "../Interfaces/IPlan";

export const getPlans = async (): Promise<AxiosResponse<IPlan[]>> => {
  try {
    return await axios.get(APIs.plans.getAll());
  } catch (error) {
    throw error;
  }
};
