// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  height: max-content;
}

.footer {
  height: max-content;
}

.contentSingleGunner {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 10px;
  background-color: var(--ion-color-light);
}
.traineeNameSG {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/SPIKEGorilla/SingleGunner/SingleGunnerComponent.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,SAAO;EACP,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,oBAAoB;EACpB,wCAAwC;AAC1C;AACA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":[".header {\n  height: max-content;\n}\n\n.footer {\n  height: max-content;\n}\n\n.contentSingleGunner {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  overflow-y: auto;\n  padding-bottom: 10px;\n  background-color: var(--ion-color-light);\n}\n.traineeNameSG {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
