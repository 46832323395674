import React, { useContext } from "react";
import { IonFooter, IonToolbar } from "@ionic/react";
import OnlineBadge from "../OnlineBadge/OnlineBadge";
import ActivePlan from "../ActivePlan/ActivePlan";
import "./Footer.css";
import SyncProgressBar from "../SyncProgressBar/SyncProgressBar";
import { UserContext } from "../../../context/UserContext/userContext";
import { AppModeContext } from "../../../context/AppModeContext/AppModeContext";
import { environment } from "../../../Configurations/consts";
import EEnvironment from "../../../Enums/EEnvironment";
import { EAppMode } from "../../../Enums/EAppMode";
import { isValidAppMode } from "../../../services/helpers";

const Footer: React.FC = (): JSX.Element => {
  const { appMode } = useContext(AppModeContext);
  const { isAdmin } = useContext(UserContext);

  return (
    <div className="footer-wrapper">
      <IonFooter>
        <IonToolbar className="footer-height">
          <div className="footer-content">
            <OnlineBadge></OnlineBadge>
            {isAdmin &&
            environment === EEnvironment.localSite &&
            isValidAppMode(appMode, EAppMode.WARTAC) ? (
              <SyncProgressBar />
            ) : null}
            <ActivePlan />
          </div>
        </IonToolbar>
      </IonFooter>
    </div>
  );
};

export default Footer;
