import {
  ESingleGunnerSteps,
  IHeatTemplate,
  ISingleGunnerStep,
} from "../../Interfaces/Gorilla/IHeatTemplate";
import { IShootingExecution } from "../../Interfaces/Gorilla/IShootingExecution";
import { ITargetsPropertiesResults } from "../../Interfaces/Gorilla/ITargetProperties";
import { useContext, useEffect, useState } from "react";
import { usePlanValuesForSelectedPlan } from "./HooksWithRQ/usePlanValuesForSelectedPlan";
import { ISystemEncryption } from "../../Interfaces/Gorilla/ISystemEncryption";
import { ISettingStation } from "../../Interfaces/Gorilla/IObserver";
import { ElementsContext } from "../../context/ElementsContext/ElementsContext";
const GorillaConsts = (planId?: number, isAllPlans?: boolean) => {
  const { plansValuesForSelectedPlan } = usePlanValuesForSelectedPlan(
    planId,
    isAllPlans
  );
  const { elements } = useContext(ElementsContext);
  const getPlanValueUpperThreshold = (
    elementName: string,
    defaultTime: number
  ) => {
    let elementObj = elements.find(
      (element) =>
        element.name === elementName || element.name === `${elementName}Time`
    );
    let planValue = plansValuesForSelectedPlan?.elementsPlanValues.find(
      (elementPlanValue) => elementPlanValue.elementId === elementObj?.id
    );
    return (
      (Number(planValue?.upperThreshold) < Number(planValue?.lowerThreshold)
        ? planValue?.lowerThreshold
        : planValue?.upperThreshold) || defaultTime
    );
  };
  const getObjectWithGoalTime = (prev: any) => {
    Object.keys(prev).forEach((elementName: any) => {
      if (
        (prev[elementName as keyof ISystemEncryption] as any)?.goalSeconds !==
        undefined
      ) {
        (prev[elementName as keyof ISystemEncryption] as any)!.goalSeconds =
          getPlanValueUpperThreshold(
            elementName,
            (prev[elementName as keyof ISystemEncryption] as any)
              ?.goalSeconds || 120
          );
      }
    });
    return { ...prev };
  };

  useEffect(() => {
    if (plansValuesForSelectedPlan && elements.length) {
      setInitSettingStation((prev) => getObjectWithGoalTime(prev));
      setInitialSystemEncryptionTemplate((prev) => getObjectWithGoalTime(prev));
      setInitialMalfunctionOperationTemplate((prev) =>
        getObjectWithGoalTime(prev)
      );
      setInitialShootingExecutionTemplate((prev) =>
        getObjectWithGoalTime(prev)
      );
    }
    // eslint-disable-next-line
  }, [plansValuesForSelectedPlan, elements]);
  const [initialSystemEncryptionTemplate, setInitialSystemEncryptionTemplate] =
    useState<ISystemEncryption>({
      ControlUnit: { goalSeconds: 120, accuracy: undefined, time: undefined },
      Combined: { goalSeconds: 120, accuracy: undefined, time: undefined },
      OperatingLauncherHeatGrade: undefined,
    });

  const [
    initialMalfunctionOperationTemplate,
    setInitialMalfunctionOperationTemplate,
  ] = useState<IMalfunctionOperationOptions>({
    ShootingStop: {
      isExecuted: false,
      isSelected: false,
      goalSeconds: 60,
      time: undefined,
      accuracy: undefined,
    },
    HotMissile: {
      isExecuted: false,
      isSelected: false,
      goalSeconds: 60,
      time: undefined,
      accuracy: null,
    },
    HotBattery: {
      isExecuted: false,
      isSelected: false,
      goalSeconds: 60,
      time: undefined,
      accuracy: null,
    },
    ImmobilizerPress: {
      isExecuted: false,
      isSelected: false,
      goalSeconds: 60,
      time: undefined,
      accuracy: null,
    },
    ImproveOnly: {
      isExecuted: false,
      isSelected: false,
      goalSeconds: 60,
      time: undefined,
      accuracy: null,
    },
    ISNTShootingLowHigh: {
      isExecuted: false,
      isSelected: false,
      goalSeconds: 60,
      time: undefined,
      accuracy: null,
    },
    LockBreak: {
      isExecuted: false,
      isSelected: false,
      goalSeconds: 60,
      time: undefined,
      accuracy: null,
    },
    BrokenLauncher: {
      isExecuted: false,
      isSelected: false,
      goalSeconds: 60,
      time: undefined,
      accuracy: null,
    },
  });
  const [
    initialShootingExecutionTemplate,
    setInitialShootingExecutionTemplate,
  ] = useState<IShootingExecution>({
    Hit: undefined,
    WarheadFit: undefined,
    ExamineDamageEffect: undefined,
    OperatingTime: { goalSeconds: 218, time: undefined, accuracy: null },
  });
  const [initSettingStation, setInitSettingStation] = useState<ISettingStation>(
    {
      HIZDATRUTatField: {
        accuracy: undefined,
        goalSeconds: 240,
        time: undefined,
      },
      StationSelection: undefined,
      SettingStation: {
        accuracy: undefined,
        goalSeconds: 240,
        time: undefined,
      },
    }
  );

  return {
    initialSystemEncryptionTemplate,
    initialMalfunctionOperationTemplate,
    initialShootingExecutionTemplate,
    initSettingStation,
  };
};
export const initialTargetsPropertiesResultsTemplate: ITargetsPropertiesResults =
  {
    targetType: undefined,
    areaZone: undefined,
    LOSToTarget: undefined,
    shootingType: undefined,
    shootToTarget: undefined,
    targetState: undefined,
    time: undefined,
  };

export const initHeatsStepTemplate: Record<
  ESingleGunnerSteps,
  ISingleGunnerStep
> = {
  [ESingleGunnerSteps.SystemEncryption]: {
    id: ESingleGunnerSteps.SystemEncryption,
    title: "systemEncryption",
    description: "systemEncryption",
    isCompleted: false,
  },
  [ESingleGunnerSteps.TargetsProperties]: {
    id: ESingleGunnerSteps.TargetsProperties,
    title: "targetProperties",
    isCompleted: false,
    isMustCompleted: true,
  },
  [ESingleGunnerSteps.MalfunctionOperation]: {
    id: ESingleGunnerSteps.MalfunctionOperation,
    title: "malfunctionsOperation",
    isCompleted: false,
  },
  [ESingleGunnerSteps.ShootingExecution]: {
    id: ESingleGunnerSteps.ShootingExecution,
    title: "shootingExecution",
    isCompleted: false,
    isMustCompleted: true,
  },
  [ESingleGunnerSteps.MainNotes]: {
    id: ESingleGunnerSteps.MainNotes,
    title: "mainNotes",
    isCompleted: false,
  },
};
export const initialHeatTemplate: IHeatTemplate = {
  malfunctionsOperation: undefined,
  shootingExecution: undefined,
  systemEncryption: undefined,
  targetsPropertiesResults: undefined,
  isCompleted: false,
  heatId: 0,
  stepsState: initHeatsStepTemplate,
};
export default GorillaConsts;
