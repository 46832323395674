import { useContext } from "react";
import Axios from "../../Axios";
import { UserContext } from "../../context/UserContext/userContext";
import { useMsal } from "@azure/msal-react";
const MicrosoftLogout = () => {
  const { instance } = useMsal();
  const { logout } = useContext(UserContext);
  const logoutUser = () => {
    if (Axios.defaults.headers.common["Authorization"]?.includes("Bearer")) {
      Axios.defaults.headers.common["Authorization"] = "";
      instance
        .logoutRedirect()
        .then(() => {
          logout();
        })
        .catch((e: any) => {
          console.error(e);
          logout();
        });
      setTimeout(() => {
        logout();
      }, 500);
    } else {
      logout();
    }
  };
  return { logoutUser };
};
export default MicrosoftLogout;
