// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputTable {
  background-color: var(--ion-color-primary);
  width: 100%;
  box-shadow: var(--box-shadow);
  border-radius: 2px;
  margin-top: 2px;
}
.inputTableRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.inputTableCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-color: var(--ion-color-primary);
  border-width: 1px;
  border-style: solid;
  background-color: var(--ion-color-enable);
}
.valueColIT {
  background-color: var(--ion-color-dark);
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.wrongValue {
  color: var(--ion-color-danger);
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/InputTable/InputTable.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;EAC1C,WAAW;EACX,6BAA6B;EAC7B,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,sCAAsC;EACtC,iBAAiB;EACjB,mBAAmB;EACnB,yCAAyC;AAC3C;AACA;EACE,uCAAuC;EACvC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".inputTable {\n  background-color: var(--ion-color-primary);\n  width: 100%;\n  box-shadow: var(--box-shadow);\n  border-radius: 2px;\n  margin-top: 2px;\n}\n.inputTableRow {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n}\n.inputTableCol {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  border-color: var(--ion-color-primary);\n  border-width: 1px;\n  border-style: solid;\n  background-color: var(--ion-color-enable);\n}\n.valueColIT {\n  background-color: var(--ion-color-dark);\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n}\n\n.wrongValue {\n  color: var(--ion-color-danger);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
