import { FC } from "react";
import { useTranslation } from "react-i18next";
import PMLabel from "../../themeComponents/PMLabel";
import "./ActivePlan.css";
import usePlans from "../../CustomHooks/usePlans";

const ActivePlan: FC = () => {
  const { t } = useTranslation();

  const { activePlan } = usePlans();

  return (
    <div className="active-plan">
      <PMLabel fontSize="medium" fontColor="light" fontFamily="Light">
        {activePlan
          ? `${t("activePlan")} ${activePlan.name}`
          : t("noActivePlanSelected")}
      </PMLabel>
    </div>
  );
};

export default ActivePlan;
