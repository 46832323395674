import { useLocation, useParams } from "react-router";
import ISimpleTrainee, { ETeam } from "../../Interfaces/ISimpleTrainee";
import { useContext, useEffect } from "react";
import useNavigation from "./useNavigation";
import { TrainingTypesContext } from "../../context/TrainingTypesContext/TrainingTypesContext";
import { mobileRoutes } from "../../services/routeRoles";
interface Params {
  planId: string;
  stationId: string;
  stationName: string;
  trainingTypeId: string;
  laneId: string;
  isManual: string;
  selectedForcesAmount: string;
  isPairs: string;
  shootingDayId: string;
  heatId: string;
}
const useMobileDataPostingParams = () => {
  const { search } = useLocation();
  const {
    planId,
    stationId,
    trainingTypeId,
    stationName,
    isManual,
    laneId,
    selectedForcesAmount,
    isPairs,
    shootingDayId,
    heatId,
  } = useParams<Params>();
  const { replace } = useNavigation();
  const { trainingTypes } = useContext(TrainingTypesContext);
  const queryParams = new URLSearchParams(search);
  const trainees: ISimpleTrainee[] = [];
  const redirectToValidTab = (replace: (path: string) => void) => {
    const isValidPath = trainingTypes.some(
      (trainingType) => Number(trainingTypeId) === Number(trainingType.id)
    );
    if (!isValidPath) {
      replace(mobileRoutes.stations);
    }
  };
  useEffect(() => {
    redirectToValidTab(replace);
  }, [trainingTypeId, trainingTypes]);

  for (let i = 1; queryParams.get(`force${i}Id`); i++) {
    trainees.push({
      id: Number(queryParams.get(`force${i}Id`)) || 0,
      uniqId: Number(queryParams.get(`force${i}Id`)) || 0,
      name: queryParams.get(`force${i}Name`) || "",
      team: queryParams.get(`force${i}Team`) as ETeam,
    });
  }
  return {
    trainees,
    planId: Number(planId),
    stationId: Number(stationId),
    trainingTypeId: Number(trainingTypeId),
    stationName,
    isManual: isManual == "manual",
    laneId: Number(laneId),
    selectedForcesAmount: Number(selectedForcesAmount) || 1,
    isPairs: isPairs === "true",
    shootingDayId: Number(shootingDayId),
    heatId: Number(heatId),
  };
};
export default useMobileDataPostingParams;
