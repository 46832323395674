import React, { useEffect, useState } from "react";

import "./ObserverDesktop.css";
import { useTranslation } from "react-i18next";
import { IonCol, IonGrid, IonPage, IonRow } from "@ionic/react";
import {
  EGorillaForces,
  EGorillaTrainings,
} from "../../../../Interfaces/Gorilla/GorillaTrainings";
import PMLabel from "../../../themeComponents/PMLabel";
import ObserverHook from "../../../CustomHooks/ObserverHook";
import { EObserverSteps } from "../../../../Interfaces/Gorilla/IObserver";
import ObserverDesktopComponent from "./Observer/ObserverDesktopComponent";
import VerticalSteps from "../../../Shared/SPIKEGorilla/VerticalSteps";
import PromptHook from "../../../CustomHooks/PromptHook";
import WarningModal from "../../../Shared/SPIKEGorilla/WarningModal";
import PMButton from "../../../themeComponents/PMButton";
import customToast from "../../../Shared/Toast/CustomToast";
import HasifaTkifaEndPage, {
  EEndPageState,
} from "./HasifaTkifaEndPage/HasifaTkifaEndPage";

const ObserverDesktop: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const [isFinishedAllTrainingTabOpen, setIsFinishedAllTrainingTabOpen] =
    useState<boolean>(false);
  const [observerTrainingState, setObserverTrainingState] =
    useState<EEndPageState>(EEndPageState.incomplete);
  const [currentStepType, setCurrentStepType] = useState<EObserverSteps>(
    EObserverSteps.SettingStation
  );
  const {
    endTrainingHandlerMO,
    openAndCover,
    setWorkRoutine,
    workRoutine,
    settingStation,
    conductingQuality,
    targetAcquisition,
    setTargetAcquisition,
    setOpenAndCover,
    setSettingStation,
    setConductingQuality,
    mainNotes,
    addNote,
    deleteNote,
    editNote,
    isTrainingEnd,
    traineeName,
    observerSteps,
    endTrainingHandlerMOAfterConfirmation,
    closeConfirmationModal,
    isEndTrainingModalOpen,
  } = ObserverHook(true);
  useEffect(() => {
    if (isTrainingEnd) {
      customToast.success(
        `${t("dataSendSuccess")} ${t("for")} ${t(EGorillaTrainings.Observer)}`
      );
    }
  }, [isTrainingEnd]);

  useEffect(() => {
    let isComplete = true;

    const checkStepsCompletion = (
      steps: Record<
        EObserverSteps,
        { isCompleted: boolean; isPartlyCompleted: boolean }
      >
    ) => {
      let hasPartlyCompletedStep = false;
      for (const [stepType, step] of Object.entries(steps)) {
        if ((Number(stepType) as EObserverSteps) !== EObserverSteps.MainNotes) {
          if (!step.isCompleted) {
            isComplete = false;
          }
          if (step.isPartlyCompleted) {
            hasPartlyCompletedStep = true;
          }
        }
      }
      return hasPartlyCompletedStep;
    };

    let observerPartlyComplete = checkStepsCompletion(observerSteps);

    setObserverTrainingState(
      isComplete
        ? EEndPageState.fullCompleted
        : observerPartlyComplete
        ? EEndPageState.partlyCompleted
        : EEndPageState.incomplete
    );
  }, [observerSteps]);

  PromptHook(isTrainingEnd, "hasifaTkifaLeave");
  return (
    <IonPage>
      <WarningModal
        acceptText={`${t("yes")}, ${t("finish")}`}
        onDecline={closeConfirmationModal}
        onAccept={endTrainingHandlerMOAfterConfirmation}
        isOpen={isEndTrainingModalOpen}
        title="endingTraining"
        warningText="endTrainingConfirmation"
        warningQuestion={`${t("endTrainingConfirmationQuestion")} ${t(
          EGorillaForces.Observer
        )}?`}
      ></WarningModal>
      <IonGrid className="FTDContainerWrap">
        <IonCol
          size="3"
          className="firstCol scrollS"
          onClick={() => setIsFinishedAllTrainingTabOpen(false)}
        >
          <IonRow className="titleRowHT">
            <PMLabel fontFamily="Regular" fontSize="xxLarge" fontColor="light">
              {`${t(EGorillaTrainings.Observer)}`}
            </PMLabel>
          </IonRow>
          <IonRow className="nameRowHT">
            <PMLabel fontFamily="Regular" fontSize="xxLarge" fontColor="light">
              {`${traineeName}`}
            </PMLabel>
          </IonRow>
          <IonRow className="FRDRow observerStepRow">
            <VerticalSteps
              activeStep={currentStepType}
              steps={Object.values(observerSteps)}
              onClickHandler={(id: any) => setCurrentStepType(id)}
            />
          </IonRow>
          <IonRow className="endTrainingRow">
            <PMButton
              onClickHandler={endTrainingHandlerMO}
              color="orange"
              size="large"
              label={{ fontColor: "dark", fontSize: "medium" }}
              isDisabled={
                !Object.values(observerSteps).some(
                  (step) => step.isPartlyCompleted
                ) || isTrainingEnd
              }
            >
              {t("endTraining")}
            </PMButton>
          </IonRow>
        </IonCol>
        <IonCol className="FTDContainer">
          {isFinishedAllTrainingTabOpen ? (
            <HasifaTkifaEndPage
              state={observerTrainingState}
              onPrevButtonHandler={() => setIsFinishedAllTrainingTabOpen(false)}
            ></HasifaTkifaEndPage>
          ) : (
            <ObserverDesktopComponent
              conductingQuality={conductingQuality}
              generalCommentsMO={mainNotes}
              isTrainingEnd={isTrainingEnd}
              openAndCover={openAndCover}
              setConductingQuality={setConductingQuality}
              setOpenAndCover={setOpenAndCover}
              setSettingStation={setSettingStation}
              setTargetAcquisition={setTargetAcquisition}
              setWorkRoutine={setWorkRoutine}
              settingStation={settingStation}
              workRoutine={workRoutine}
              targetAcquisition={targetAcquisition}
              currentStepType={currentStepType}
              setCurrentStepType={setCurrentStepType}
              deleteNote={deleteNote}
              editNote={editNote}
              addNote={addNote}
              nextTraining={() => {
                setIsFinishedAllTrainingTabOpen(true);
              }}
            ></ObserverDesktopComponent>
          )}
        </IonCol>
      </IonGrid>
    </IonPage>
  );
};

export default ObserverDesktop;
