export enum userRoles {
  Admin = "Admin",
  Instructor = "Instructor",
  Viewer = "Viewer",
  Unauthorized = "Unauthorized",
}

export enum routes {
  performance = "/performance",
  siteManagement = "/site-management",
  noAccess = "/no-access",
  unauthorized = "/unauthorized",
  notFound = "/not-found",
  personal = "/personal",
  dataPosting = "/dataPosting",
  dataHistory = "/data-history",
  orbatSettings = "/orbat-settings",
  trainingPlan = "/training-plan",
  reportGenerator = "/report-generator",
  stationView = "/stationView",
  marketing = "/marketing",
}

export enum mobileRoutes {
  performance = "/performance",
  trends = "/trends",
  leaders = "/leaders",
  stations = "/stations",
  settings = "/settings",
  personal = "/personal",
  dataHistory = "/history",
  singleGunner = "/Single_Gunner",
  observer = "/Observer",
  frameTraining = "/Frame_Training",
  gorillaInitTraining = "/gorillaInitTraining",
  sitStatus = "/site_status",
  fireSupport = "/Fire_Support",
  billiard = "/Billiard",
}

export const routeRoles = {
  Admins: [String(userRoles.Admin)],
  Instructors: [String(userRoles.Instructor)],
  Viewers: [String(userRoles.Viewer)],
  Unauthorized: [String(userRoles.Unauthorized)],
};
