import axios from "../../../Axios";
import { baseUrlPMBackend } from "../../../Configurations/consts";
import { saveAs } from "file-saver";
const generatePDF = async (
  data: any,
  fileName: string,
  isLightMode?: boolean
) => {
  const formData = new FormData();
  const blobOrigin = new Blob([JSON.stringify(data)], { type: "text/plain" });

  formData.append("file", blobOrigin, fileName);
  formData.append("isLightMode", `${isLightMode}`);
  const response = await axios.post(
    `${baseUrlPMBackend}generatePDF/generatePDF`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      responseType: "blob",
    }
  );

  const blob = new Blob([response.data], { type: "application/pdf" });
  saveAs(blob, `${fileName}.pdf`);
  return true;
};
export default generatePDF;
