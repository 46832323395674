import { Dispatch, SetStateAction, useEffect, useState } from "react";
import IForceFromOrbat from "../../Interfaces/IForceFromOrbat";
import IPlan from "../../Interfaces/IPlan";
import { IIndicatorResult } from "../../Interfaces/dataCalculator";
import useGetGorillaGrades from "./useGetGorillaGrades";
import { EGorillaIndicators } from "../../Interfaces/Gorilla/GorillaTrainings";

const useGetObserverGrades = (
  selectedPlan: IPlan | undefined,
  force: IForceFromOrbat[],
  refresh: boolean | undefined,
  setRefresh: Dispatch<SetStateAction<boolean | undefined>>
) => {
  const [observerForceResults, setObserverForceResults] =
    useState<IIndicatorResult>();
  const [notes, setNotes] = useState<string[] | undefined>();
  const { forceData, loading } = useGetGorillaGrades(
    selectedPlan,
    force,
    refresh,
    setRefresh
  );
  useEffect(() => {
    if (forceData) {
      setNotes(forceData?.notes?.Observer || undefined);
      setObserverForceResults(
        forceData.indicatorResults?.find(
          (indicator) => indicator.id === EGorillaIndicators.Observer
        )
      );
    } else setObserverForceResults(undefined);
  }, [forceData]);
  return {
    observerForceResults,
    loading,
    notes,
  };
};
export default useGetObserverGrades;
