// css
import { useEffect } from "react";
import { IonRow } from "@ionic/react";
import "./SingleGunnerDesktopResults.css";
import PerformanceTemplate from "../PerformanceDisplay/performanceTemplate";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import usePlans from "../../../components/CustomHooks/usePlans";
import TrainingTypeResults from "./TrainingTypeResults";
import IndicatorFinalGrade from "./IndicatorFinalGrade";
import HitsAnalyze from "./HitsAnalyze";
import useGetSingleGunnerGrades from "../../../components/CustomHooks/useGetSingleGunnerGrades";
import useOneSelectedForce from "./useOneSelectedForce";
import { useLocation } from "react-router";
import EmptyState from "../../../components/Shared/EmptyState/EmptyState";

const SingleGunnerDesktopResults: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const { state } = useLocation<
    | {
        forceId: string;
        planId: string;
      }
    | undefined
  >();

  const [isToDisplayHitsAnalyze, setIsToDisplayHitsAnalyze] =
    useState<boolean>(false);
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<boolean>();

  const { onPlanSelectedHandler, plansOptions, selectedPlan } = usePlans(
    undefined,
    undefined,
    true
  );
  const { forces, onCheckForce, selectedForce } = useOneSelectedForce();

  const { singleGunnerForceResults, loading, hitAnalyze, notes } =
    useGetSingleGunnerGrades(selectedPlan, forces, refresh, setRefresh);

  useEffect(() => {
    if (state?.planId && state?.forceId) {
      let newSelectedPlan = plansOptions.find(
        (plan) => Number(plan.id) === Number(state?.planId)
      );
      if (newSelectedPlan) onPlanSelectedHandler(newSelectedPlan);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);
  const chart =
    selectedForce[0] && !loading ? (
      <IonRow className="singleGunnerContent scrollL">
        <IndicatorFinalGrade
          grade={singleGunnerForceResults?.grade}
          threshold={singleGunnerForceResults?.threshold}
          hitRate={
            hitAnalyze?.length
              ? `${hitAnalyze?.reduce(
                  (a: any, b: any) => a + (b.hit ? 1 : 0),
                  0
                )}/${hitAnalyze?.length}`
              : undefined
          }
          notes={notes}
        />

        {isToDisplayHitsAnalyze ? (
          <HitsAnalyze hitsAnalysisResults={hitAnalyze || []}></HitsAnalyze>
        ) : (
          <>
            {singleGunnerForceResults?.trainingTypeResults.map(
              (trainingType, index) => (
                <TrainingTypeResults
                  key={index}
                  grade={trainingType.grade}
                  threshold={trainingType.threshold}
                  title={trainingType.name}
                  weight={trainingType.weight}
                  isWithWeight
                  isWithSuccessRate
                  resultsArray={trainingType.elementResults.map((element) => ({
                    grade: element.grade,
                    successRate: element.successRate,
                    name: element.name,
                    weight: element.weight,
                  }))}
                />
              )
            )}
          </>
        )}
      </IonRow>
    ) : (
      <EmptyState
        text={selectedForce[0] ? "loadingData" : "noSelectedForces"}
      />
    );

  return (
    <div className="gorillaPage">
      <PerformanceTemplate
        selectedForceId={state ? Number(state?.forceId) : undefined}
        key={"dashboard"}
        loadingText={t("loadingData")}
        limit={1}
        chart={chart}
        isDrawerOpen={isDrawerOpen}
        loading={loading}
        orbatForces={selectedForce}
        refreshButtonText={t("refresh")}
        onClickExportButton={() => {
          setRefresh(true);
        }}
        plansOptions={plansOptions}
        selectedPlan={selectedPlan}
        onPlanSelectedHandler={onPlanSelectedHandler}
        setChecked={onCheckForce}
        setDrawerOpen={setDrawerOpen}
        title={`${selectedForce.length ? selectedForce[0]?.name : ""}`}
        onClickTopButtonHandler={() => {
          setIsToDisplayHitsAnalyze((prev) => !prev);
        }}
        topButtonText={
          isToDisplayHitsAnalyze ? "stationDetails" : "hitsAnalyze"
        }
      ></PerformanceTemplate>
    </div>
  );
};
export default SingleGunnerDesktopResults;
