import { Dispatch, FC, SetStateAction, useEffect } from "react";
import "./NavigationToggle.css";
import { useTranslation } from "react-i18next";
import ELanguage from "../../../Enums/ELanguage";
interface IProps {
  selectedToggleIndex: number;
  setSelectedToggleIndex: Dispatch<SetStateAction<number>>;
  navigationOptions: string[];
}

const NavigationToggle: FC<IProps> = (props: IProps) => {
  const { navigationOptions, selectedToggleIndex, setSelectedToggleIndex } =
    props;
  const { t, i18n } = useTranslation();
  const handleOptionChange = (index: number) => {
    setSelectedToggleIndex(index);
  };
  useEffect(() => {
    setSelectedToggleIndex(0);
    // eslint-disable-next-line
  }, [navigationOptions]);
  return (
    <div className="toggle-container">
      <div
        className="slider"
        style={{
          width: `${95 / navigationOptions.length}%`,
          left: `${
            (i18n.language === ELanguage.he
              ? navigationOptions.length - selectedToggleIndex - 1
              : selectedToggleIndex) *
            (100 / navigationOptions.length)
          }%`,
        }}
      >
        {t(navigationOptions[selectedToggleIndex])}
      </div>
      {navigationOptions.map((option, index) => (
        <button
          key={index}
          style={{ width: `${95 / navigationOptions.length}%` }}
          onClick={() => handleOptionChange(index)}
          className={`option ${
            selectedToggleIndex === index ? "selected" : ""
          }`}
        >
          {t(navigationOptions[index])}
        </button>
      ))}
    </div>
  );
};

export default NavigationToggle;
