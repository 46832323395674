import React from "react";
import { useTranslation } from "react-i18next";

import "./SPIKEInput.css";
import { IonCol, IonRow } from "@ionic/react";
import PMLabel from "../../../themeComponents/PMLabel";
interface IProps {
  value: null | number;
  constValue?: number | null;
  inputName: string;
  isWrongValue?: boolean;
  onChangeHandler?: ((field: string, value: string) => void) | undefined;
  isDisabled?: boolean;
  constTitle?: string;
}
const SPIKEInput: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="inputWrapSPIKE">
      <IonRow className="inputWrapSPIKERow">
        <IonCol size="5.7" className="inputWrapSPIKECol">
          <IonRow className="inputWrapSPIKERow ">
            <PMLabel
              fontColor="light"
              fontSize={
                props.value === null && props.isDisabled ? "medium" : "small"
              }
              fontFamily="Light"
              cssClass="inputName"
            >
              {t(props.inputName)}
            </PMLabel>
          </IonRow>
          <IonRow className={`inputWrapSPIKERow`}>
            {!props.isDisabled ? (
              <input
                className={`SPIKEInput ${
                  props.isWrongValue || isNaN(Number(props.value))
                    ? "wrongValue"
                    : ""
                }`}
                value={props.value || undefined}
                onChange={(e) => {
                  props.onChangeHandler &&
                    props.onChangeHandler(props.inputName, e.target.value);
                }}
              ></input>
            ) : (
              <PMLabel
                fontColor="light"
                fontFamily="Regular"
                cssClass={props.isWrongValue ? "wrongValue" : ""}
              >
                {props.value}
              </PMLabel>
            )}
          </IonRow>
        </IonCol>{" "}
        <IonCol size="0.6" className="inputWrapSPIKECol slashCol">
          <PMLabel fontColor="light" fontSize="xxLarge">
            /
          </PMLabel>
        </IonCol>
        <IonCol size="5.7" className="inputWrapSPIKECol">
          <IonRow className="inputWrapSPIKERow ">
            <PMLabel
              fontColor="light"
              fontSize={props.constValue !== undefined ? "small" : "medium"}
              fontFamily="Light"
            >
              {t(props.constTitle)}
            </PMLabel>
          </IonRow>
          <IonRow className="inputWrapSPIKERow ">
            <PMLabel fontColor="light" fontFamily="Regular">
              {props.constValue}
            </PMLabel>
          </IonRow>
        </IonCol>
      </IonRow>
    </div>
  );
};

export default SPIKEInput;
