import { IonPage } from "@ionic/react";
import React from "react";
import "./Unauthorized.css";
import logo from "../../../Assets/unauthorized.png";

const Unauthorized: React.FC = () => {
  return (
    <IonPage>
      <img alt="unauthorizedImage" src={logo} className="img"></img>
    </IonPage>
  );
};

export default Unauthorized;
