import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { IonCol, IonModal, IonRow } from "@ionic/react";
import "./NotesModal.css";
import PMLabel from "../../themeComponents/PMLabel";
import { useTranslation } from "react-i18next";
import PMIcon from "../../themeComponents/PMIcon";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import PMButton from "../../themeComponents/PMButton";
import AddButton from "./AddButton";
import SaveCancelButtons from "../Buttons/SaveCancelButtons";
import { pencil } from "ionicons/icons";
import { INote } from "../../../Interfaces/Gorilla/GorillaTrainings";

interface IProps {
  isOpen: boolean;
  notes: INote[];
  addNote: (note: string) => void;
  deleteNote: (index: number) => void;
  editNote: (index: number, note: string) => void;
  closeModal: () => void;
}

const NotesModal: FC<IProps> = (props: IProps) => {
  const { isOpen, addNote, closeModal, notes, deleteNote, editNote } = props;
  const [noteToEdit, setNoteToEdit] = useState<INote>();
  const maxNotes = 5;
  const [isAddNote, setIsAddNote] = useState<boolean>(
    notes.length < maxNotes && isOpen
  );
  useEffect(() => {
    setIsAddNote(notes.length < maxNotes && isOpen);
  }, [notes, isOpen]);
  const { t } = useTranslation();

  const [editingNote, setEditingNote] = useState<string>("");
  const maxLetters = 70;
  useEffect(() => {
    setEditingNote(noteToEdit?.note || "");
  }, [noteToEdit]);
  useEffect(() => {
    setEditingNote("");
  }, [isAddNote]);

  return (
    <>
      <IonModal
        isOpen={isOpen}
        className="commentsModal"
        onDidDismiss={() => {
          setNoteToEdit(undefined);
          setIsAddNote(false);
          closeModal();
        }}
      >
        <div className="commentsModalContent">
          <div className="commentsWrap">
            <IonRow className="commentsModalTitle">
              <PMLabel
                textAlign="center"
                fontFamily="Regular"
                fontSize="medium"
                fontColor="light"
              >
                {t("mainNotes")}
              </PMLabel>
            </IonRow>
            <IonRow className="commentsRow">
              <IonCol className="commentsCol">
                {notes
                  .filter((note) => note.noteId !== noteToEdit?.noteId)
                  .map((note, index) => (
                    <IonRow className="commentRow" key={index}>
                      <IonCol className="commentIndexCol" size="0.6">
                        <PMLabel
                          fontFamily="ExtraLight"
                          fontSize="medium"
                          fontColor="light"
                        >
                          {`${index + 1}.`}
                        </PMLabel>
                      </IonCol>
                      <IonCol className="commentCol" size="8.5">
                        <PMLabel
                          fontFamily="ExtraLight"
                          fontSize="medium"
                          fontColor="light"
                          cssClass="commentText"
                        >
                          {`${
                            note.heatId ? `${t("heat")} ${note.heatId} - ` : ""
                          }${note.note}`}
                        </PMLabel>
                      </IonCol>
                      <IonCol className="commentCol">
                        <PMIcon
                          iconSrc={pencil}
                          size="small"
                          color="light"
                          onClick={() => setNoteToEdit(note)}
                        ></PMIcon>
                      </IonCol>
                      <IonCol className="commentCol">
                        <PMIcon
                          iconSrc={EIconsSrc.RECYCLE_BIN}
                          size="large"
                          color="danger"
                          onClick={() => deleteNote(note.noteId)}
                        ></PMIcon>
                      </IonCol>
                    </IonRow>
                  ))}
              </IonCol>
            </IonRow>
          </div>

          {!!noteToEdit || isAddNote ? (
            <>
              <PMLabel
                textAlign="center"
                fontFamily="Regular"
                fontSize="medium"
                fontColor="light"
              >
                {noteToEdit
                  ? `${t("editComment")} - ${"test 1"}/${"test 2"}`
                  : t("addNote")}
              </PMLabel>
              <IonRow className="commentsRow">
                <textarea
                  className="commentsInput scrollS"
                  value={editingNote}
                  onChange={(event) => {
                    setEditingNote(event.target.value);
                  }}
                  maxLength={70}
                  autoFocus
                ></textarea>
              </IonRow>
              <IonRow className="commentEditorModalTitle">
                <PMLabel
                  fontFamily="ExtraLight"
                  fontSize="small"
                  fontColor="light"
                  textAlign="center"
                >
                  {`${editingNote.length}/${maxLetters}`}
                </PMLabel>
              </IonRow>
              <IonRow className="acceptDeclineButtonsRow">
                <SaveCancelButtons
                  disabled={!editingNote.length}
                  onCancelClickHandler={() => {
                    setIsAddNote(false);
                    setNoteToEdit(undefined);
                  }}
                  onSaveClickHandler={() => {
                    if (isAddNote) {
                      addNote(editingNote);
                      setIsAddNote(false);
                    } else {
                      if (noteToEdit) editNote(noteToEdit?.noteId, editingNote);
                    }
                  }}
                  saveText={t("saveNote")}
                ></SaveCancelButtons>
              </IonRow>
            </>
          ) : (
            <>
              {maxNotes > notes.length ? (
                <IonRow className="commentsModalTitle">
                  <AddButton
                    onClickHandler={() => {
                      setIsAddNote(true);
                    }}
                    buttonText={t("addNote")}
                  />
                </IonRow>
              ) : null}
              <IonRow className="sendButtonsRow">
                <PMButton
                  color={"orange"}
                  size="small"
                  onClickHandler={() => {
                    closeModal();
                  }}
                  label={{
                    fontSize: "medium",
                    fontFamily: "Light",
                    fontColor: "light",
                  }}
                >
                  {t("close")}
                </PMButton>
              </IonRow>
            </>
          )}
        </div>
      </IonModal>
    </>
  );
};

export default NotesModal;
