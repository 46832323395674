import { FC } from "react";
import { useHistory } from "react-router";

import DataPostingMobileTemplate from "../../DataPosting/DataPostingMobileTemplate";
import MagnetFOFComponent from "./MagnetFOFComponent";
import useMobileDataPostingParams from "../../../CustomHooks/useMobileDataPostingParams";
const MagnetDataPosting: FC = () => {
  const history = useHistory();
  const { trainees, trainingTypeId, stationName, laneId } =
    useMobileDataPostingParams();
  const returnToSoldiersList = () => {
    history.goBack();
  };

  return (
    <DataPostingMobileTemplate
      stationName={stationName}
      traineeName={trainees[0]?.name || ""}
    >
      <MagnetFOFComponent
        laneId={laneId}
        stationName={stationName}
        traineeId={Number(trainees[0]?.id)}
        traineeName={trainees[0]?.name || ""}
        trainingTypeId={trainingTypeId}
        afterPostHandler={returnToSoldiersList}
      />
    </DataPostingMobileTemplate>
  );
};

export default MagnetDataPosting;
