import React, { Dispatch, useState } from "react";
import { useTranslation } from "react-i18next";
import PMLabel from "../../themeComponents/PMLabel";
import "./PlansMobile.css";
import PopoverItem from "../../themeComponents/PopoverItem";
import usePlans from "../../CustomHooks/usePlans";
import { useDispatch } from "react-redux";
import { setActive } from "../../../redux/actions/plansActions";
import PMIcon from "../../themeComponents/PMIcon";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import WarningModal from "../SPIKEGorilla/WarningModal";
import IPlan from "../../../Interfaces/IPlan";

const PlansMobile: React.FC = () => {
  const { t } = useTranslation();
  const { plansOptions } = usePlans();
  const dispatch = useDispatch<Dispatch<any>>();
  const [isWarningOpen, setIsWarningOpen] = useState<boolean>(false);
  const [planToActive, setPlanToActive] = useState<IPlan>();
  return (
    <div className="plansWrapMobile">
      {plansOptions.map((plan) => (
        <PopoverItem
          key={plan.id}
          onClickHandler={(e: any) => {
            if (!plan.isActive && !planToActive) {
              setPlanToActive(plan);
              setIsWarningOpen(true);
            }
          }}
        >
          <PMLabel
            textAlign="start"
            fontColor={plan.isActive ? "orange" : "light"}
            fontFamily={plan.isActive ? "Bold" : "Regular"}
            fontSize="medium"
          >
            {`${t(plan.name)} ${plan.isActive ? `(${t("active")})` : ""}`}
          </PMLabel>
          <PMIcon
            size="medium"
            color={plan.isActive ? "orange" : "xLight"}
            iconSrc={plan.isActive ? EIconsSrc.STAR : EIconsSrc.STAR_OUTLINE}
          ></PMIcon>
        </PopoverItem>
      ))}
      <WarningModal
        acceptText={"yes"}
        onDecline={() => {
          setIsWarningOpen(false);
          setPlanToActive(undefined);
        }}
        onAccept={() => {
          if (planToActive) dispatch(setActive(planToActive?.id, t));
          setIsWarningOpen(false);
        }}
        isOpen={isWarningOpen}
        title={t(planToActive?.name)}
        warningQuestion={`${t("setPlanActiveAlert")}`}
      ></WarningModal>
    </div>
  );
};
export default PlansMobile;
