// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.smallInputField {
  --color: var(--ion-color-fontLight);
  font-size: 16px !important;
  font-family: "light" !important;
  padding-inline-start: 14px;
}

.smallInputItem {
  display: flex;
  align-items: center;
  height: 4vh;
  border-radius: 2px !important;
  --background: var(--ion-color-labelInput);
  --color: var(--ion-color-fontWhite);
  border-radius: 2px;
  min-height: 40px;
  --padding-start: 0;
  --inner-padding-end: 0;
}
.disabledSmallInputItem {
  pointer-events: none;
  --color: var(--ion-color-fontWhite);
}
.smallInputItem:hover {
  --background: var(--ion-color-labelInput-tint);
}
.smallInputItem > ion-input > .native-input {
  padding-top: 0px;
  padding-bottom: 0px;
}

.smallShort {
  max-width: 8vw !important;
}

.smallLong {
  max-width: 14vw !important;
}

.units {
  font-size: 11px;
  padding-inline-end: 14px;
}

.tpInputError {
  border: 1px solid var(--ion-color-danger-input) !important;
}
.tpInputError .tpLabelPMI {
  color: var(--ion-color-danger-input) !important;
}
.smallInputField {
  background-color: transparent;
  outline: 0 !important;
  border-width: 0;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/TrainingPlan/SmallInput.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,0BAA0B;EAC1B,+BAA+B;EAC/B,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,6BAA6B;EAC7B,yCAAyC;EACzC,mCAAmC;EACnC,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,sBAAsB;AACxB;AACA;EACE,oBAAoB;EACpB,mCAAmC;AACrC;AACA;EACE,8CAA8C;AAChD;AACA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,eAAe;EACf,wBAAwB;AAC1B;;AAEA;EACE,0DAA0D;AAC5D;AACA;EACE,+CAA+C;AACjD;AACA;EACE,6BAA6B;EAC7B,qBAAqB;EACrB,eAAe;EACf,WAAW;AACb","sourcesContent":[".smallInputField {\n  --color: var(--ion-color-fontLight);\n  font-size: 16px !important;\n  font-family: \"light\" !important;\n  padding-inline-start: 14px;\n}\n\n.smallInputItem {\n  display: flex;\n  align-items: center;\n  height: 4vh;\n  border-radius: 2px !important;\n  --background: var(--ion-color-labelInput);\n  --color: var(--ion-color-fontWhite);\n  border-radius: 2px;\n  min-height: 40px;\n  --padding-start: 0;\n  --inner-padding-end: 0;\n}\n.disabledSmallInputItem {\n  pointer-events: none;\n  --color: var(--ion-color-fontWhite);\n}\n.smallInputItem:hover {\n  --background: var(--ion-color-labelInput-tint);\n}\n.smallInputItem > ion-input > .native-input {\n  padding-top: 0px;\n  padding-bottom: 0px;\n}\n\n.smallShort {\n  max-width: 8vw !important;\n}\n\n.smallLong {\n  max-width: 14vw !important;\n}\n\n.units {\n  font-size: 11px;\n  padding-inline-end: 14px;\n}\n\n.tpInputError {\n  border: 1px solid var(--ion-color-danger-input) !important;\n}\n.tpInputError .tpLabelPMI {\n  color: var(--ion-color-danger-input) !important;\n}\n.smallInputField {\n  background-color: transparent;\n  outline: 0 !important;\n  border-width: 0;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
