// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.planDropdownItem:hover {
  --background: var(--ion-color-selected);
}
.planLabelItem {
  text-overflow: unset;
}
.planDropdownItem.selectedPlan {
  --background: var(--ion-color-selected);
}
.plansDropdownList {
  background-color: var(--ion-color-light);
}
.planDropdownItem {
  --background: var(--ion-color-light);
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/PlansDropdown/PlansDropdown.css"],"names":[],"mappings":"AAAA;EACE,uCAAuC;AACzC;AACA;EACE,oBAAoB;AACtB;AACA;EACE,uCAAuC;AACzC;AACA;EACE,wCAAwC;AAC1C;AACA;EACE,oCAAoC;AACtC","sourcesContent":[".planDropdownItem:hover {\n  --background: var(--ion-color-selected);\n}\n.planLabelItem {\n  text-overflow: unset;\n}\n.planDropdownItem.selectedPlan {\n  --background: var(--ion-color-selected);\n}\n.plansDropdownList {\n  background-color: var(--ion-color-light);\n}\n.planDropdownItem {\n  --background: var(--ion-color-light);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
