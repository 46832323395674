// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.elementNameColA {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.accuracyRow {
  min-height: 68px;
  display: flex;
  align-items: center;
  background-color: var(--ion-color-light);
  padding-inline-start: var(--pm_spike_gorilla);
  padding-inline-end: var(--pm_spike_gorilla);
}
.accuracyElementCol {
  --ion-grid-column-padding: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}
.switchButtonRowA {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.switchButtonRowA .switchButton {
  width: 134px;
  height: 38px;
}
.switchButtonRowA .switchSpan {
  background-color: var(--ion-color-dark-shade);
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/SPIKEGorilla/AccuracyElement.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,wCAAwC;EACxC,6CAA6C;EAC7C,2CAA2C;AAC7C;AACA;EACE,4BAA4B;EAC5B,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;AACA;EACE,6CAA6C;AAC/C","sourcesContent":[".elementNameColA {\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n  flex-direction: column;\n}\n\n.accuracyRow {\n  min-height: 68px;\n  display: flex;\n  align-items: center;\n  background-color: var(--ion-color-light);\n  padding-inline-start: var(--pm_spike_gorilla);\n  padding-inline-end: var(--pm_spike_gorilla);\n}\n.accuracyElementCol {\n  --ion-grid-column-padding: 0;\n  padding-top: 5px;\n  padding-bottom: 5px;\n}\n.switchButtonRowA {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n}\n\n.switchButtonRowA .switchButton {\n  width: 134px;\n  height: 38px;\n}\n.switchButtonRowA .switchSpan {\n  background-color: var(--ion-color-dark-shade);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
