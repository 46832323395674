import { FC } from "react";
import "./AddButton.css";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import PMButton from "../../themeComponents/PMButton";

interface IProps {
  onClickHandler: () => void;
  buttonText: string;
  isDisabled?: boolean;
}

const AddButton: FC<IProps> = (props: IProps) => {
  const { onClickHandler, buttonText } = props;
  return (
    <PMButton
      size="fit"
      fill="clear"
      icon={{
        iconSrc: EIconsSrc.ADD,
        color: "xLight",
        size: "large",
      }}
      onClickHandler={onClickHandler}
      label={{
        fontSize: "small",
        fontColor: "xLight",
        fontFamily: "Regular",
      }}
      isDisabled={props.isDisabled}
    >
      {buttonText}
    </PMButton>
  );
};

export default AddButton;
