// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comparePlansModal {
  height: 80vh;
  width: 80vw;
  overflow: hidden;
  padding: 15px;
}
.comparePlansGrid {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.stt {
  height: 250px;
}
.rowPDD {
  width: 100%;
}
.notEqualFont {
  color: var(--ion-color-danger) !important;
}
.equalFont {
  color: var(--ion-color-success) !important;
}
.titlePlansDiff {
  margin-bottom: 20px;
}
.titlePlansRow {
  display: flex;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/PlansDifferencesDetails/PlansDifferencesDetails.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,YAAY;EACZ,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,aAAa;AACf;AACA;EACE,WAAW;AACb;AACA;EACE,yCAAyC;AAC3C;AACA;EACE,0CAA0C;AAC5C;AACA;EACE,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".comparePlansModal {\n  height: 80vh;\n  width: 80vw;\n  overflow: hidden;\n  padding: 15px;\n}\n.comparePlansGrid {\n  height: 100%;\n  width: 100%;\n  overflow-y: auto;\n}\n.stt {\n  height: 250px;\n}\n.rowPDD {\n  width: 100%;\n}\n.notEqualFont {\n  color: var(--ion-color-danger) !important;\n}\n.equalFont {\n  color: var(--ion-color-success) !important;\n}\n.titlePlansDiff {\n  margin-bottom: 20px;\n}\n.titlePlansRow {\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
