import React from "react";
import { useTranslation } from "react-i18next";
import DesktopManualInputTemplate from "./DesktopManualInputTemplate";
import {
  convertLaneToMrkANdIESTLane,
  handleSave,
  IbasicRow,
  sendDrillToBBackend,
} from "./dataPostingHelpers";
import UseDataPosting from "../../CustomHooks/UseDataPosting";
import IDataPosting from "../../../Interfaces/IDataPosting";
import SoldiersListEditor from "./SoldiersListEditor";
import "./Mrk.css";
import { Column, EEditorType } from "../PMDataGrid/DataGridTypes";
import {
  comparisonFormatter,
  positiveNumber,
  positiveNumberNtoM,
} from "./inputValidations";
import DataGrid from "../PMDataGrid/DataGrid";

const messageType: number = 6;

const rowsNumber: number = 14;
const IESTSimulativeShootingTrainingTypeId: number = 13;
const departmentWeaponTrainingTypeId: number = 15;
const BISTSimulativeShootingTrainingTypeId = 19;
// Mrk row (ui) interface
export interface IMRKRow extends IbasicRow {
  laneId: number;
  weaponType: string;
  numberOfShots: number | "";
  numberOfHits: number | "";
  score: number | "";
  grouping: number | "";
  lastHeat: string;
  soldierName: string;
  hashtags: string | "";
}

// Mrk message interface
interface mrkMessage {
  MessageType: number;
  SoldierID: number;
  TimeStamp: Date;
  LaneID: number;
  TrainingTypeID: number;
  WeaponType: string;
  NumOfShots: number | "";
  RedHits: number | "";
  Score: number | "";
  Grouping: number | "";
  LastHeat: string;
  Hashtags: string[];
  DrillStartTime: Date;
  PlanId: number | undefined;
}
interface IProps extends IDataPosting {
  isBist?: boolean;
}
const Mrk: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation();

  const trainingTypeId = props.isBist
    ? BISTSimulativeShootingTrainingTypeId
    : IESTSimulativeShootingTrainingTypeId;
  const XDefaultRow: IMRKRow = {
    id: 0,
    laneId: 1,
    weaponType: " ",
    numberOfShots: "",
    numberOfHits: "",
    score: "",
    grouping: "",
    lastHeat: "",
    soldierName: "",
    hashtags: "",
    soldierId: "",
  };
  const {
    isSuccess,
    setIsSuccess,
    rows,
    setRows,
    refreshRowsWrap,
    resetRowsDataWrap,
    weaponsTypesList,
  } = UseDataPosting(XDefaultRow, rowsNumber, trainingTypeId, true);

  // Columns for each input type
  const columns: Column<IMRKRow>[] = [
    {
      key: "laneId",
      header: t("lane"),
      width: 80,
    },
    {
      key: "soldierName",
      header: t("soldierName"),
      editable: true,
      renderEditCell: SoldiersListEditor,
      filterType: EEditorType.text,
      width: 200,
    },
    {
      key: "soldierId",
      header: t("militaryId"),
      editable: true,
      filterType: EEditorType.text,
      validationFunction: (row: IMRKRow, value: any) => positiveNumber(value),
    },
    {
      key: "weaponType",
      header: t("weaponType"),
      editable: true,
      filterType: EEditorType.dropdown,
      options: weaponsTypesList.map((weapon) => ({
        label: t(weapon),
        value: weapon,
      })),
    },
    {
      key: "numberOfShots",
      header: t("numberOfShots"),
      editable: true,
      filterType: EEditorType.text,
      validationFunction: (row: IMRKRow, value: any) => positiveNumber(value),
    },
    {
      key: "numberOfHits",
      header: t("numberOfHits"),
      editable: true,
      filterType: EEditorType.text,
      validationFunction: (row: IMRKRow, value: any) => {
        return comparisonFormatter(
          "numberOfHits",
          "numberOfShots",
          row,
          value,
          t
        );
      },
    },
    {
      key: "score",
      header: t("score"),
      editable: true,
      filterType: EEditorType.text,
      validationFunction: (row: IMRKRow, value: any) =>
        positiveNumberNtoM(value, 100, 0, t),
    },
    {
      key: "grouping",
      header: t("grouping"),
      editable: true,
      filterType: EEditorType.text,
      validationFunction: (row: IMRKRow, value: any) =>
        positiveNumberNtoM(value, 20, 0, t),
    },
    {
      key: "lastHeat",
      header: t("lastHeat"),
      editable: true,
      filterType: EEditorType.text,
    },
    {
      key: "hashtags",
      header: t("hashtags"),
      editable: true,
      filterType: EEditorType.text,
    },
  ];

  // Manages data sending
  const sendData = async (row: IMRKRow) => {
    // To avoid sending empty rows, check if all elements exist
    if (
      (row.numberOfHits !== "" ||
        row.numberOfShots !== "" ||
        row.score !== "" ||
        row.grouping !== "") &&
      row.soldierId !== ""
    ) {
      // Sets the loading
      setIsSuccess(-1);
      let dbLaneId: number | undefined;
      let rowToSend: mrkMessage;
      let weaponType = row.weaponType === " " ? "None" : row.weaponType;

      const trainingType =
        !props.isBist && weaponsTypesList.indexOf(row.weaponType) >= 4
          ? departmentWeaponTrainingTypeId
          : trainingTypeId;
      dbLaneId = await convertLaneToMrkANdIESTLane(row.laneId);
      // If a lane matches, it creates the row to send
      if (dbLaneId) {
        rowToSend = {
          SoldierID: row.soldierId,
          MessageType: messageType,
          TimeStamp: new Date(),
          TrainingTypeID: trainingType,
          LaneID: dbLaneId,
          WeaponType: weaponType,
          NumOfShots: row.numberOfShots,
          RedHits: row.numberOfHits,
          Score: row.score,
          Grouping: row.grouping,
          LastHeat: row.lastHeat,
          Hashtags: row.hashtags.split(" "),
          DrillStartTime: new Date(),
          PlanId: props.selectedPlan?.id,
        };
        // Then sends it
        sendDrillToBBackend(
          rowToSend,
          () => {
            props.resetCompetencyForces();
          },
          () => setIsSuccess(500)
        );
      }
    }
  };

  return (
    <DesktopManualInputTemplate
      selectedPlan={props.selectedPlan}
      plansOptions={props.plansOptions}
      onPlanSelectedHandler={props.onPlanSelectedHandler}
      pageName={"IEST_Simulative_Shooting"}
      onClickThirdButton={() => {
        handleSave(rows, sendData, t);
      }}
      onClickSecondButton={() => {
        refreshRowsWrap(XDefaultRow);
      }}
      onClickFirstButton={() => {
        resetRowsDataWrap(XDefaultRow);
      }}
    >
      <DataGrid
        columns={columns}
        data={rows}
        editable
        onEdit={(updatedRow) => {
          setRows((prev) =>
            prev.map((row) => (row.id === updatedRow.id ? updatedRow : row))
          );
        }}
        minWidth={150}
      ></DataGrid>
    </DesktopManualInputTemplate>
  );
};

export default Mrk;
