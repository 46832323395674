import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import Axios from "../../../Axios";
import { baseUrlPMBackend } from "../../../Configurations/consts";
import { StationsContext } from "../../../context/StationsContext/StationsContext";
import IStationDetails from "../../../Interfaces/IStationDetails";
import customToast from "../../Shared/Toast/CustomToast";
import { AppModeContext } from "../../../context/AppModeContext/AppModeContext";

/**
 *
 * @returns array with of force types
 */
const asyncFetchStations = async () => {
  return (await Axios.get(`${baseUrlPMBackend}stations/getStations`)).data;
};

export const useStations = () => {
  const { t } = useTranslation();
  const { appMode } = useContext(AppModeContext);

  const { setStations } = useContext(StationsContext);

  let query = useQuery<IStationDetails[]>({
    queryKey: ["stations", appMode],
    queryFn: asyncFetchStations,
    enabled: appMode !== undefined,
    staleTime: Infinity,
  });
  const { data, isLoading, isError, refetch } = query;

  useEffect(() => {
    //when the app mode change fetch the app indicators
    refetch();
  }, [appMode]);

  useEffect(() => {
    if (!isLoading && !isError && data && data.length) {
      //det the context with the force types data

      setStations(
        data.map((station) => ({
          ...station,
          trainingTypeId: Number(station.trainingTypeId),
          stationId: Number(station.stationId),
          id: Number(station.id),
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (isError) {
      customToast.error(t("errorWhileLoadingStations"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);
};
