import { FC, useContext, useEffect, useRef, useState } from "react";
import {
  IonApp,
  IonContent,
  IonPage,
  getPlatforms,
  isPlatform,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { ToastContainer } from "react-toastify";
import {
  environment,
  gaTrackingCode,
  baseUrlPMBackend,
} from "./Configurations/consts";
import ReactGA from "react-ga";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

/* Pages */
import Header from "./components/Desktop/Header/Header";
import SideMenu from "./components/Desktop/SideMenu/SideMenu";
import Footer from "./components/Desktop/Footer/Footer";
import Tabs from "./components/Mobile/Tabs/Tabs";
import { UserContext } from "./context/UserContext/userContext";
import { useTranslation } from "react-i18next";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Switch } from "react-router";
import NotFound from "./pages/Desktop/NotFound/NotFound";
import { BrowserRouter } from "react-router-dom";
import useLogin from "./components/CustomHooks/useLogin";
import AuthRoute from "./components/Desktop/AuthRoute/AuthRoute";
import { routeRoles, routes } from "./services/routeRoles";
import NoAccess from "./pages/Desktop/NoAccess/NoAccess";
import Login from "./pages/Desktop/Login/Login";
import Loading from "./pages/Desktop/Loading/Loading";
import EEnvironment from "./Enums/EEnvironment";
import { setupIonicReact } from "@ionic/react"; // must be import in ionic v6
import { addUserForTracking } from "./apis/TrackingLoginsAPI";
import axiosInstance from "./Axios";
import Axios from "./Axios";
import MobileSideMenu from "./components/Mobile/MobileSideMenu/MobileSideMenu";
import { AppContext } from "./context/AppContext/AppContext";
import ELanguage from "./Enums/ELanguage";
setupIonicReact({});

const App: FC = () => {
  const { i18n, t } = useTranslation();
  document.documentElement.lang = i18n.language;
  const { isLoggedIn, toggleDarkModeHandler, darkMode, user, logout } =
    useContext(UserContext);
  const { isAsDesktop, setIsAsDesktop } = useContext(AppContext);
  const queryClient = new QueryClient();
  const [isI18NextReset, setIsI18nextReset] = useState<boolean>(false);
  const {
    isLoginLoading,
    refreshToken,
    isLocalLogin,
    setIsLocalLogin,
    setIsLoginLoading,
  } = useLogin();

  // Google Analytics (this is  using in Universal Analytics that will stop processing data in 1.7.2023, we need to change it to Google Analytics 4, the new version of GA).
  ReactGA.initialize(`${gaTrackingCode}`);
  const firstUpdate = useRef(true);
  const [timeoutID, setTimeoutID] = useState<any>(null); // Define timeoutID using useState
  useEffect(() => {
    console.log(getPlatforms());
  }, []);
  useEffect(() => {
    if (timeoutID) {
      clearTimeout(timeoutID);
    }
    if (!isLoggedIn && environment === EEnvironment.staging) {
      let timeoutID = setTimeout(() => {
        logout();
        setIsLoginLoading(false);
        setIsLocalLogin(true);
      }, 5000);
      setTimeoutID(timeoutID);
    }
    return () => {
      clearTimeout(timeoutID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);
  const setLanguage = () => {
    let currentLang = localStorage.getItem("language");
    if (currentLang === null) {
      localStorage.setItem(
        "language",
        EEnvironment.staging === environment ? ELanguage.en : i18n.language
      );
      i18n.changeLanguage(
        EEnvironment.staging === environment ? ELanguage.en : i18n.language
      );
      Axios.defaults.headers.common["language"] =
        EEnvironment.staging === environment ? ELanguage.en : i18n.language;
    } else {
      i18n.changeLanguage(currentLang);
      Axios.defaults.headers.common["language"] = currentLang;
    }
  };
  useEffect(() => {
    const setI18nextTranslations = async () => {
      let translationsResources = await Axios.get(
        `${baseUrlPMBackend}localization/gatSettings`
      );
      i18n.init({ ...translationsResources.data });
      setLanguage();

      setTimeout(() => {
        setIsI18nextReset(true);
      }, 200);
    };
    if (user.id !== -1) setI18nextTranslations();
  }, [user]);

  // Checks theme preference according to local storage
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    let theme = localStorage.getItem("theme");
    if (theme !== "dark" && darkMode) toggleDarkModeHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addUserToLoginTracking = async () => {
    try {
      await addUserForTracking(user.id);
    } catch (error) {
      console.error("Can't insert user to login tracking table");
    }
  };

  useEffect(() => {
    if (
      firstUpdate.current &&
      isLoggedIn &&
      environment === EEnvironment.production &&
      user
    ) {
      firstUpdate.current = false;
      addUserToLoginTracking();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, user]);

  //receive all axios response error, if the error in unauthorized refresh the token
  axiosInstance.interceptors.response.use(
    (response) => {
      // Successful responses (2xx) will reach this block
      return response;
    },
    async (error) => {
      // Check if the status code is 401 (unauthorized)
      if (error.response && error.response.status === 401) {
        if (
          Axios.defaults.headers.common["Authorization"]?.includes("Bearer")
        ) {
          localStorage.removeItem("access_token");
          await refreshToken();
        } else {
          if (!error.config.url.includes("logout")) {
            logout(true);
          }
        }
      }
      // Return the error to be handled by the calling code
      return Promise.reject(error);
    }
  );
  useEffect(() => {
    console.log("width", window.screen, "height", window.screen);
    console.log(
      "outer width",
      window.outerWidth,
      "outer height",
      window.outerHeight
    );
    console.log(
      "inner width",
      window.innerWidth,
      "inner height",
      window.innerHeight
    );

    if (
      (window.outerWidth > 1200 && window.outerHeight > 800) ||
      isPlatform("desktop")
    )
      setIsAsDesktop(true);
    else setIsAsDesktop(false);
  }, []);
  return isLoginLoading || (!isI18NextReset && isLoggedIn) ? (
    <Loading></Loading>
  ) : !isLoggedIn && isLocalLogin ? (
    <>
      <Login></Login>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        className="toast-container"
      />
    </>
  ) : (
    <BrowserRouter>
      <Switch>
        <AuthRoute
          path={routes.noAccess}
          Component={NoAccess}
          requiredRoles={[...routeRoles.Unauthorized]}
        ></AuthRoute>

        <AuthRoute
          path="/"
          Component={() => (
            <IonApp className="noselect">
              <IonContent dir={i18n.language === ELanguage.he ? "rtl" : "ltr"}>
                {/* Root page for desktop */}
                {isAsDesktop && (
                  <div className="desktopweb">
                    <IonReactRouter>
                      <Header
                        setDarkMode={toggleDarkModeHandler}
                        darkMode={darkMode}
                      />
                      <SideMenu />
                      <ToastContainer
                        position="bottom-right"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl
                        pauseOnFocusLoss={false}
                        draggable
                        pauseOnHover
                        className="toast-container"
                      />
                    </IonReactRouter>
                    <Footer />
                  </div>
                )}

                {/* Root page for mobile website */}
                {!isAsDesktop && (
                  <div className="mobileweb">
                    <IonReactRouter>
                      <QueryClientProvider client={queryClient}>
                        <MobileSideMenu />
                        <IonPage id="mobile-main-content" className="pageApp">
                          <Tabs />
                        </IonPage>
                      </QueryClientProvider>
                    </IonReactRouter>
                    <ToastContainer
                      position="bottom-right"
                      autoClose={3000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl
                      pauseOnFocusLoss={false}
                      draggable
                      pauseOnHover
                      className="toast-container"
                    />
                  </div>
                )}
              </IonContent>
            </IonApp>
          )}
          requiredRoles={[
            ...routeRoles.Admins,
            ...routeRoles.Instructors,
            ...routeRoles.Viewers,
          ]}
        ></AuthRoute>
        <AuthRoute
          path="/*"
          Component={NotFound}
          requiredRoles={[
            ...routeRoles.Unauthorized,
            ...routeRoles.Admins,
            ...routeRoles.Instructors,
            ...routeRoles.Viewers,
          ]}
        ></AuthRoute>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
