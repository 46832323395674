import "./PersonalTrends.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import IThreshold from "../../../../../Interfaces/IThreshold";
import ILabel from "../../../../../Interfaces/ILabel";
import IForceFromOrbat from "../../../../../Interfaces/IForceFromOrbat";
import ITrendsIndicatorData from "../../../../../Interfaces/ITrendsIndicatorData";
import IPlan from "../../../../../Interfaces/IPlan";
import IndicatorBarChart from "../../../../../components/Desktop/IndicatorBarChart/IndicatorBarChart";

import IIndicator from "../../../../../Interfaces/ITrendIndicator";
import PMLabel from "../../../../themeComponents/PMLabel";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import Spinner from "../../../../Shared/Spinner/Spinner";
import { EGraphType } from "../../../../../Enums/EGraphType";

interface ITrendsProps {
  labels: ILabel[];
  thresholds: IThreshold[];
  indicators: IIndicator[];
  isLoading: boolean;
  plan: IPlan;
  trendsChartsData: ITrendsIndicatorData[];
  force: IForceFromOrbat[];
  checkedForces: IForceFromOrbat[];
  forcesToRemove: IForceFromOrbat[];
  mode: string;
}

const PersonalTrends = (props: ITrendsProps): JSX.Element => {
  const { t } = useTranslation();
  const [currentSwiperPage, setCurrentSwiperPage] = useState<number>(0);

  return (
    <div className="personalTrendsDiv">
      {!props.isLoading ? (
        <>
          <PMLabel fontFamily="Regular" fontSize="medium" fontColor="xLight">
            {t(props.indicators[currentSwiperPage]?.name)}
          </PMLabel>
          <Swiper
            modules={[Pagination]}
            pagination={{
              clickable: true,
            }}
            loop
            allowTouchMove
            className="mobilTrendsSlides"
            onSlideChange={(event) => {
              setCurrentSwiperPage(event.realIndex);
            }}
          >
            {props.indicators?.map((indicator: IIndicator) => (
              <SwiperSlide className="mobileTrendsSlide" key={indicator.name}>
                <IndicatorBarChart
                  key={indicator.name}
                  indicatorId={indicator.id}
                  thresholds={props.thresholds}
                  labels={props.labels}
                  selectedForces={props.checkedForces}
                  newForces={props.checkedForces}
                  forcesToRemove={props.forcesToRemove}
                  barChartsData={props.trendsChartsData}
                  plan={props.plan}
                  trends
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      ) : (
        <Spinner className="competency-spinner" />
      )}
    </div>
  );
};

export default PersonalTrends;
