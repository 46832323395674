// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hashtagsDetailsHeaderRow {
  width: 100%;
  border-bottom: 1px #666666 solid;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.hashtagsDetailsRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px #666666;
  justify-content: space-between;
}

.dividerRow {
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
}
.hashtagDetailCol {
  padding: 0;
}
.header-scope .toggleRow {
  max-width: 150px;
  margin-inline-start: 18px;
}

.HashtagDetailsCol {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.selectHashtagButton {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.hashtagsBarWrap {
  height: calc(100% - 70px);
  width: 100%;
  display: flex;
  justify-content: center;
}
.stackSpinner {
  position: fixed;
  top: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Desktop/PerformanceDisplay/PerformanceTabs/Hashtags/Hashtags.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gCAAgC;EAChC,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;AACA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,gCAAgC;EAChC,8BAA8B;AAChC;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,UAAU;AACZ;AACA;EACE,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;AACA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;AACA;EACE,yBAAyB;EACzB,WAAW;EACX,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,eAAe;EACf,QAAQ;AACV","sourcesContent":[".hashtagsDetailsHeaderRow {\n  width: 100%;\n  border-bottom: 1px #666666 solid;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n.hashtagsDetailsRow {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  border-bottom: solid 1px #666666;\n  justify-content: space-between;\n}\n\n.dividerRow {\n  width: 100%;\n  margin-top: 24px;\n  margin-bottom: 24px;\n}\n.hashtagDetailCol {\n  padding: 0;\n}\n.header-scope .toggleRow {\n  max-width: 150px;\n  margin-inline-start: 18px;\n}\n\n.HashtagDetailsCol {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n.selectHashtagButton {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n.hashtagsBarWrap {\n  height: calc(100% - 70px);\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n.stackSpinner {\n  position: fixed;\n  top: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
