import { IShootingExecution } from "./IShootingExecution";
import { ISystemEncryption } from "./ISystemEncryption";
import { ITargetsPropertiesResults } from "./ITargetProperties";
export enum ESingleGunnerSteps {
  SystemEncryption,
  TargetsProperties,
  MalfunctionOperation,
  ShootingExecution,
  MainNotes,
}
export interface ISingleGunnerStep {
  id: ESingleGunnerSteps;
  title: string;
  isCompleted: boolean;
  isMustCompleted?: boolean;
  description?: string;
}
export interface IHeatTemplate {
  systemEncryption: ISystemEncryption | undefined;
  targetsPropertiesResults: ITargetsPropertiesResults | undefined;
  shootingExecution: IShootingExecution | undefined;
  malfunctionsOperation: IMalfunctionOperationOptions | undefined;
  isCompleted: boolean;
  heatId: number;
  stepsState: Record<ESingleGunnerSteps, ISingleGunnerStep>;
}
