import { Dispatch, FC, SetStateAction } from "react";
import "./Observer.css";
import Divider from "../Divider";
import GeneralGrade from "../GeneralGrade";
import TimeAccuracyElement from "../TimeAccuracyElement";
import { ISettingStation } from "../../../../Interfaces/Gorilla/IObserver";
import { IonCol, IonGrid, IonRow } from "@ionic/react";

type IProps = {
  setSettingStation: Dispatch<SetStateAction<ISettingStation>>;
  settingStation: ISettingStation;
  isDesktop?: boolean;
};

const SettingStation: FC<IProps> = (props: IProps): JSX.Element => {
  const { setSettingStation, settingStation } = props;

  return (
    <IonGrid className="observerContentWrap scrollS">
      <IonRow className="SSROw ">
        <IonCol size="12" className="paddingFree ">
          <div className="">
            <GeneralGrade
              elementName={"StationSelection"}
              grade={settingStation?.StationSelection}
              setGrade={(grade) => {
                setSettingStation((prev) => ({
                  ...prev,
                  StationSelection: grade,
                }));
              }}
              isDesktop={props.isDesktop}
            ></GeneralGrade>
          </div>
        </IonCol>
      </IonRow>
      {<div className="dividerLine" />}

      <IonRow className="SSROw2">
        <IonCol
          size={props.isDesktop ? "6" : "12"}
          className="paddingFree rightSidePadding"
        >
          <TimeAccuracyElement
            elementName={"SettingStation"}
            time={settingStation.SettingStation.time}
            accuracy={settingStation.SettingStation.accuracy}
            targetSeconds={settingStation.SettingStation.goalSeconds}
            onTimeChanged={(seconds) => {
              setSettingStation((prev) => ({
                ...prev,
                SettingStation: {
                  ...prev.SettingStation,
                  time: seconds,
                },
              }));
            }}
            onAccuracyChanged={(accuracy) => {
              setSettingStation((prev) => ({
                ...prev,
                SettingStation: {
                  ...prev.SettingStation,
                  accuracy:
                    prev.SettingStation.accuracy === null ? null : accuracy,
                },
              }));
            }}
          ></TimeAccuracyElement>
        </IonCol>
        {!props.isDesktop ? <Divider /> : null}
        <IonCol
          size={props.isDesktop ? "6" : "12"}
          className="paddingFree leftSidePadding"
        >
          <TimeAccuracyElement
            elementName={"HIZDATRUTatField"}
            time={settingStation.HIZDATRUTatField.time}
            accuracy={settingStation.HIZDATRUTatField.accuracy}
            targetSeconds={settingStation.HIZDATRUTatField.goalSeconds}
            onTimeChanged={(seconds) => {
              setSettingStation((prev) => ({
                ...prev,
                HIZDATRUTatField: {
                  ...prev.HIZDATRUTatField,
                  time: seconds,
                },
              }));
            }}
            onAccuracyChanged={(accuracy) => {
              setSettingStation((prev) => ({
                ...prev,
                HIZDATRUTatField: {
                  ...prev.HIZDATRUTatField,
                  accuracy:
                    prev.HIZDATRUTatField.accuracy === null ? null : accuracy,
                },
              }));
            }}
          ></TimeAccuracyElement>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default SettingStation;
