// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.personalPerformanceDetailsDiv {
  width: 100%;
  height: 95%;
  min-width: 400px;
  min-height: 450px;
  display: flex;
}

/* .personalPerformanceDetailsDiv > .bar-component {
  height: 95%;
  width: 100%;
  padding-inline-start: 16px;
} */
`, "",{"version":3,"sources":["webpack://./src/components/Mobile/PersonalZone/Competency/PersonalPerformanceDetails/PersonalPerformanceDetails.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,WAAW;EACX,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;AACf;;AAEA;;;;GAIG","sourcesContent":[".personalPerformanceDetailsDiv {\n  width: 100%;\n  height: 95%;\n  min-width: 400px;\n  min-height: 450px;\n  display: flex;\n}\n\n/* .personalPerformanceDetailsDiv > .bar-component {\n  height: 95%;\n  width: 100%;\n  padding-inline-start: 16px;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
