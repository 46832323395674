import React from "react";
import { IonPage } from "@ionic/react";
import { useTranslation } from "react-i18next";
import "./Reports.css";

const Reports = () => {
  const { t } = useTranslation();
  return (
    <IonPage className="reports">
      <div className="coming-soon">{t("comingSoon")}</div>
    </IonPage>
  );
};

export default Reports;
