// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.compareGradesDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  direction: ltr;
}
.compareGradesDiv:lang(he) {
  direction: rtl;
}
.compareSpinner {
  display: flex;
  height: 17px;
  width: 17px;
  align-items: center;
  justify-content: center;
  align-self: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/HeaderMenu/HeaderMenu.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,WAAW;EACX,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,aAAa;EACb,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AACpB","sourcesContent":[".compareGradesDiv {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-evenly;\n  width: 100%;\n  direction: ltr;\n}\n.compareGradesDiv:lang(he) {\n  direction: rtl;\n}\n.compareSpinner {\n  display: flex;\n  height: 17px;\n  width: 17px;\n  align-items: center;\n  justify-content: center;\n  align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
