import {
  IonItem,
  IonContent,
  IonList,
  IonModal,
  IonHeader,
  IonButtons,
  IonSearchbar,
  IonToolbar,
  IonText,
} from "@ionic/react";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./SearchForceSelect.css";
import ISimpleTrainee from "../../../../Interfaces/ISimpleTrainee";
import Spinner from "../../Spinner/Spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import PMLabel from "../../../themeComponents/PMLabel";
import PMButton from "../../../themeComponents/PMButton";
import PMInput from "../../../Desktop/TrainingPlan/PMInput";
import EIconsSrc from "../../../../Interfaces/EIconsSrc";
import useGetFilterForce from "./useGetFilterForces";

interface IProps {
  setSoldierDetails: React.Dispatch<
    React.SetStateAction<ISimpleTrainee | undefined>
  >;
  soldierDetails: ISimpleTrainee | undefined;
  forceType: string;
}
interface ISearchModal {
  setSoldierDetails: React.Dispatch<
    React.SetStateAction<ISimpleTrainee | undefined>
  >;
  soldierDetails: ISimpleTrainee | undefined;
  onCancel: () => void;
  onClear: () => void;
  onSelect: (force: ISimpleTrainee | undefined) => void;
}
const SearchModal: React.FC<ISearchModal> = (
  props: ISearchModal
): JSX.Element => {
  const { t } = useTranslation();

  const {
    loading,
    setSearchText,
    searchText,
    displayedArray,
    hasMoreTrainees,
    loadMoreData,
  } = useGetFilterForce();

  const searchBarInput = (ev: any) => {
    setSearchText(ev.target.value);
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <PMButton
              label={{
                fontColor: "light",
                fontFamily: "Light",
                fontSize: "medium",
              }}
              fill="clear"
              size="small"
              onClickHandler={props.onClear}
            >
              {t("clear")}
            </PMButton>
          </IonButtons>{" "}
          <IonButtons slot="start">
            <PMButton
              label={{
                fontColor: "light",
                fontFamily: "Light",
                fontSize: "medium",
              }}
              fill="clear"
              size="small"
              onClickHandler={props.onCancel}
            >
              {t("cancel")}
            </PMButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            className="searchbarForcesModal"
            onIonInput={searchBarInput}
            placeholder={t("searchForce")}
          ></IonSearchbar>
        </IonToolbar>
      </IonHeader>

      <IonContent color="light" class="ion-padding">
        {loading ? (
          <Spinner className="spinner-trainees-list" />
        ) : (
          <IonList id="modal-list" inset={true}>
            <InfiniteScroll
              dataLength={displayedArray.length}
              next={loadMoreData}
              hasMore={hasMoreTrainees}
              loader={<Spinner className="spinner-trainees-list" />}
            >
              {" "}
              {displayedArray.length ? (
                displayedArray.map((item) => (
                  <IonItem
                    key={item.uniqId}
                    onClick={() => {
                      props.onSelect(item);
                    }}
                    color={
                      props.soldierDetails?.uniqId === item.uniqId
                        ? "orange"
                        : undefined
                    }
                  >
                    <PMLabel fontColor="light">{item.name}</PMLabel>
                  </IonItem>
                ))
              ) : searchText.length ? (
                <IonItem
                  mode="md"
                  className={"notFoundSoldier itemMS"}
                  lines={"none"}
                >
                  <IonText className={"notFoundSoldier"}>
                    {t("soldierNotFound")}
                  </IonText>
                </IonItem>
              ) : null}
            </InfiniteScroll>
          </IonList>
        )}
      </IonContent>
    </>
  );
};

const SearchForceSelect: React.FC<IProps> = (props: IProps): JSX.Element => {
  const modal = useRef<HTMLIonModalElement>(null);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onClear = () => {
    props.setSoldierDetails(undefined);
    closeModal();
  };
  const onSelect = (force: ISimpleTrainee | undefined) => {
    props.setSoldierDetails(force);
    closeModal();
  };
  const onCancel = () => {
    closeModal();
  };
  const closeModal = () => {
    modal.current?.dismiss();
    setIsOpen(false);
  };
  return (
    <>
      <div className="selectorInputWrap" onClick={() => setIsOpen(true)}>
        <PMInput
          inputType={"text"}
          placeholder={t(props.forceType)}
          inputName={t(props.forceType)}
          inputValue={props.soldierDetails?.name}
          icon={EIconsSrc.SEARCH_OUTLINE}
          iconInInput
          fontSize="large"
          cssClass="selectorInput"
          height="settingsHeight"
        />
      </div>

      <IonModal
        isOpen={isOpen}
        initialBreakpoint={0.5}
        breakpoints={[0, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]}
        ref={modal}
        className="selectForceModal"
        onDidDismiss={onCancel}
      >
        <SearchModal
          setSoldierDetails={props.setSoldierDetails}
          soldierDetails={props.soldierDetails}
          onClear={onClear}
          onCancel={onCancel}
          onSelect={onSelect}
        />
      </IonModal>
    </>
  );
};

export default SearchForceSelect;
