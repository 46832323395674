import { FC, useEffect, useRef, useState } from "react";
import { IonCol, IonRow } from "@ionic/react";
import "./TimeAccuracyElement.css";
import PMLabel from "../../themeComponents/PMLabel";
import PMIcon from "../../themeComponents/PMIcon";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import PMButton from "../../themeComponents/PMButton";
import { useTranslation } from "react-i18next";
import SwitchButton from "../../Mobile/SiteManagement/ManualStations/SwitchButton";
import ReactInputMask from "react-input-mask";
import useStopwatch from "./useStopwatch";
interface IProps {
  elementName?: string;
  targetSeconds: number;
  isWithoutAccuracy?: boolean;
  onTimeChanged: (seconds: number | undefined) => void;
  onAccuracyChanged?: (accuracy: boolean | undefined) => void;
  accuracy: boolean | undefined | null;
  time: number | undefined;
}
const TimeAccuracyElement: FC<IProps> = (props: IProps) => {
  const {
    elementName,
    targetSeconds,
    isWithoutAccuracy,
    onAccuracyChanged,
    onTimeChanged,
  } = props;

  const { t } = useTranslation();
  const [accuracy, setAccuracy] = useState<boolean | undefined>(
    props.accuracy === null ? undefined : props.accuracy
  );
  const [timerEditable, setTimerEditable] = useState<boolean>(false);
  const inputRef = useRef<any>(null);

  let offsetDate = new Date();
  offsetDate.setSeconds(offsetDate.getSeconds() + (props.time || 0));
  const [initTime, setInitTime] = useState<number>(0);

  const { isRunning, pause, reset, start, milliseconds } =
    useStopwatch(initTime);

  useEffect(() => {
    if (props.accuracy !== null) setAccuracy(props.accuracy);
  }, [props.accuracy]);

  useEffect(() => {
    if (!isRunning && milliseconds) {
      onTimeChanged(milliseconds / 1000);
    } // eslint-disable-next-line
  }, [milliseconds, isRunning]);

  useEffect(() => {
    if (!props.time) {
      setInitTime(0);
    } else
      setInitTime((prev) => {
        return prev === props.time ? prev : props.time || 0;
      });

    // eslint-disable-next-line
  }, [props.time]);

  useEffect(() => {
    onAccuracyChanged && onAccuracyChanged(accuracy);
    // eslint-disable-next-line
  }, [accuracy]);

  useEffect(() => {
    setTimerEditable(false);
  }, [isRunning]);
  const setTimerMillisecondsFromInput = (inputValue: string) => {
    let seconds =
      (Number(inputValue?.split(":")[0]) ||
        Number(inputValue?.split("_")[0]) * 10 ||
        0) *
        60 +
      (isNaN(Number(inputValue?.split(":")[1]))
        ? Number(inputValue?.split(":")[1]?.split("_")[0]) * 10
        : Number(inputValue?.split(":")[1]) || 0);

    setInitTime(seconds);
  };
  const coul1size = "3";
  const coul2size = "4.5";

  const handleClickOutside = () => {
    if (!inputRef.current) {
      setTimerEditable(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <IonCol
      className={`timeAccuracyElement ${
        isWithoutAccuracy ? "withoutAccuracy" : ""
      }`}
      onBlur={() => {
        onTimeChanged(milliseconds / 1000);
      }}
    >
      {elementName && (
        <IonRow className="TArow elementRowTitle">
          <PMLabel fontFamily="Regular" fontSize="medium" fontColor="light">
            {t(elementName)}
          </PMLabel>
        </IonRow>
      )}
      <div>
        <IonRow className="TArow titleCol">
          <IonCol size={coul1size}></IonCol>
          <IonCol size={"6"} className="titleCol">
            <PMLabel fontFamily="Regular" fontSize="small" fontColor="light">
              {`${t("targetTime")} ${targetSeconds} ${t("seconds")}`}
            </PMLabel>
          </IonCol>
          <IonCol></IonCol>
        </IonRow>
        <IonRow className="TArow timeRow">
          <IonCol size={coul1size} className=" elementNameColTT">
            <PMLabel fontFamily="Regular" fontSize="medium" fontColor="light">
              {t("time")}
            </PMLabel>
          </IonCol>
          <IonCol
            size={coul2size}
            className={`timerValueWrap timerInputCol ${
              milliseconds > 0
                ? milliseconds / 1000 > targetSeconds
                  ? "timerFailed"
                  : "timerSucceed"
                : ""
            }`}
          >
            {!isRunning && timerEditable ? (
              <ReactInputMask
                ref={inputRef}
                onBlur={() => {
                  setTimerEditable(false);
                }}
                className={`timerInput `}
                type="text"
                size={11}
                alwaysShowMask
                mask="99:99"
                contentEditable={timerEditable}
                onChange={(e) => {
                  setTimerMillisecondsFromInput(e.target.value);
                }}
              />
            ) : (
              <IonRow className="stopwatchRow">
                <IonCol className="stopwatchCol">
                  <PMLabel
                    cssClass={`inputTimerText`}
                    fontFamily="Light"
                    fontSize="xxLarge"
                    fontColor="light"
                    onClick={() => {
                      setTimerEditable(true);
                    }}
                  >
                    {`${String(milliseconds % 1000)
                      .slice(0, 2)
                      .padStart(2, "0")}`}
                  </PMLabel>
                </IonCol>
                <IonCol size="0.5" className="stopwatchCol">
                  <PMLabel
                    cssClass={`inputTimerText`}
                    fontFamily="Light"
                    fontSize="xxLarge"
                    fontColor="light"
                    onClick={() => {
                      pause();
                      setTimerEditable(true);
                    }}
                  >
                    {`:`}
                  </PMLabel>
                </IonCol>
                <IonCol className="stopwatchCol">
                  <PMLabel
                    cssClass={`inputTimerText`}
                    fontFamily="Light"
                    fontSize="xxLarge"
                    fontColor="light"
                    onClick={() => {
                      pause();
                      setTimerEditable(true);
                    }}
                  >
                    {`${String(
                      Math.floor((milliseconds / 1000) % 60) || 0
                    ).padStart(2, "0")}`}
                  </PMLabel>
                </IonCol>
                <IonCol size="0.5" className="stopwatchCol">
                  <PMLabel
                    cssClass={`inputTimerText`}
                    fontFamily="Light"
                    fontSize="xxLarge"
                    fontColor="light"
                    onClick={() => {
                      pause();
                      setTimerEditable(true);
                    }}
                  >
                    {`:`}
                  </PMLabel>
                </IonCol>
                <IonCol className="stopwatchCol">
                  <PMLabel
                    cssClass={`inputTimerText`}
                    fontFamily="Light"
                    fontSize="xxLarge"
                    fontColor="light"
                    onClick={() => {
                      pause();
                      setTimerEditable(true);
                    }}
                  >
                    {`${String(
                      Math.floor(milliseconds / 1000 / 60) || 0
                    ).padStart(2, "0")}`}
                  </PMLabel>
                </IonCol>{" "}
              </IonRow>
            )}
          </IonCol>
          <IonCol size="3.5" className="timerButtonCol">
            <PMButton
              onClickHandler={isRunning ? pause : start}
              label={{
                fontColor: isRunning ? "light" : "dark",
                fontFamily: "Light",
                fontSize: "medium",
              }}
              color={isRunning ? "danger" : "orange"}
              size="small"
            >
              {isRunning ? t("stop") : t("start")}
            </PMButton>
          </IonCol>
          <IonCol size="1" className="timerRestartCol">
            <PMIcon
              disabled={isRunning || milliseconds === 0}
              iconSrc={EIconsSrc.RESTART}
              onClick={() => {
                onTimeChanged(undefined);
                setInitTime(0);
                reset();
              }}
              cssClass="timerIcons"
            ></PMIcon>
          </IonCol>
        </IonRow>
      </div>
      {!isWithoutAccuracy ? (
        <IonRow className="TArow">
          <IonCol size={coul1size} className=" elementNameColTT">
            <PMLabel fontFamily="Regular" fontSize="medium" fontColor="light">
              {t("accuracy")}
            </PMLabel>
          </IonCol>
          <IonCol size={coul2size}>
            <IonRow className="switchButtonRow">
              <SwitchButton
                handleToggle={setAccuracy}
                isOn={accuracy}
              ></SwitchButton>
            </IonRow>
          </IonCol>
          <IonCol></IonCol>
        </IonRow>
      ) : null}
    </IonCol>
  );
};

export default TimeAccuracyElement;
