// css
import { IonRow } from "@ionic/react";
import "./FrameTrainingDesktopResults.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import usePlans from "../../../components/CustomHooks/usePlans";
import PerformanceTemplate from "../PerformanceDisplay/performanceTemplate";
import IndicatorFinalGrade from "./IndicatorFinalGrade";
import TrainingTypeResults from "./TrainingTypeResults";
import useOneSelectedForce from "./useOneSelectedForce";
import useGetGorillaGrades from "../../../components/CustomHooks/useGetGorillaGrades";
import useNavigation from "../../../components/CustomHooks/useNavigation";
import { routes } from "../../../services/routeRoles";
import {
  EGorillaForces,
  EGorillaIndicators,
  gorillaTrainingTypes,
} from "../../../Interfaces/Gorilla/GorillaTrainings";
import NotExistLabel from "../../../components/Shared/NotExistLabel/NotExistLabel";
import EPicturesSrc from "../../../Interfaces/EPicturesSrc";

const FrameTrainingDesktopResults: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const { navigate } = useNavigation();
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<boolean>();

  const { onPlanSelectedHandler, plansOptions, selectedPlan } = usePlans(
    undefined,
    undefined,
    true
  );
  const { selectedForce, onCheckForce, forces } = useOneSelectedForce();
  const { forceData, loading, notes } = useGetGorillaGrades(
    selectedPlan,
    forces,
    refresh,
    setRefresh
  );

  const chart =
    selectedForce[0] && !loading ? (
      <IonRow className="singleGunnerContent scrollL">
        <IndicatorFinalGrade
          grade={forceData?.grade}
          threshold={forceData?.threshold}
          notes={notes}
        />

        {forceData?.indicatorResults
          .filter((training) =>
            gorillaTrainingTypes.includes(training.name as EGorillaForces)
          )
          .map((indicator, index) => (
            <TrainingTypeResults
              key={index}
              threshold={indicator.threshold}
              title={indicator.name}
              weight={indicator.weight}
              grade={indicator.grade}
              moreDetailsHandler={
                indicator.id !== EGorillaIndicators.NCO
                  ? () => {
                      navigate(`${routes.stationView}/${indicator.name}`, {
                        forceId: selectedForce[0].id,
                        planId: selectedPlan?.id,
                      });
                    }
                  : undefined
              }
              resultsArray={
                indicator.trainingTypeResults.length > 1
                  ? indicator.trainingTypeResults.map((indicator: any) => ({
                      grade: indicator.grade,
                      name: indicator.name,
                      weight: indicator.weight,
                    }))
                  : indicator.trainingTypeResults[0].elementResults.map(
                      (indicator: any) => ({
                        grade: indicator.grade,
                        name: indicator.name,
                        weight: indicator.weight,
                      })
                    )
              }
            />
          ))}
      </IonRow>
    ) : (
      <div className="not-selected-data">
        <NotExistLabel
          text={selectedForce[0] ? "loadingData" : "noSelectedForces"}
          className="not-selected"
        />
        <img
          alt="shooting-range-pic"
          className="shooting-range-image"
          src={EPicturesSrc.SHOOTING_RANGE}
        />
      </div>
    );
  return (
    <div className="gorillaPage">
      <PerformanceTemplate
        key={"dashboard"}
        loadingText={t("loadingData")}
        limit={1}
        chart={chart}
        isDrawerOpen={isDrawerOpen}
        loading={loading}
        orbatForces={selectedForce}
        refreshButtonText={t("refresh")}
        onClickExportButton={() => {
          setRefresh(true);
        }}
        plansOptions={plansOptions}
        selectedPlan={selectedPlan}
        onPlanSelectedHandler={onPlanSelectedHandler}
        setChecked={onCheckForce}
        setDrawerOpen={setDrawerOpen}
        title={`${selectedForce.length ? selectedForce[0]?.name : ""}`}
      ></PerformanceTemplate>
    </div>
  );
};
export default FrameTrainingDesktopResults;
