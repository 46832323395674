// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selectionFaultModal {
  --width: max-content;
  --min-width: 300px;
  --height: max-content;
  --background: var(--ion-color-dark);
  --border-radius: 4px;
  --border-color: var(--ion-color-disabled);
  --border-style: solid;
  --border-width: 1px;
  --backdrop-opacity: 0.7 !important;
}
.faultsModalContent {
  height: 100%;
  max-width: 365px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.saveCancelRowFModal {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
}

.faultsListCol {
  height: 100%;
  --ion-grid-column-padding: 0;
  min-height: 150px;
  overflow-y: auto;
  max-height: 400px;
  overflow-y: auto;
}

.faultsTitleRow {
  height: 50px;
  padding: 15px;
  margin-bottom: 20px;
  padding-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/SPIKEGorilla/TroubleshootingSelectionModal.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,qBAAqB;EACrB,mCAAmC;EACnC,oBAAoB;EACpB,yCAAyC;EACzC,qBAAqB;EACrB,mBAAmB;EACnB,kCAAkC;AACpC;AACA;EACE,YAAY;EACZ,gBAAgB;EAChB,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;AACA;EACE,aAAa;EACb,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,4BAA4B;EAC5B,iBAAiB;EACjB,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":[".selectionFaultModal {\n  --width: max-content;\n  --min-width: 300px;\n  --height: max-content;\n  --background: var(--ion-color-dark);\n  --border-radius: 4px;\n  --border-color: var(--ion-color-disabled);\n  --border-style: solid;\n  --border-width: 1px;\n  --backdrop-opacity: 0.7 !important;\n}\n.faultsModalContent {\n  height: 100%;\n  max-width: 365px;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n.saveCancelRowFModal {\n  display: flex;\n  justify-content: flex-end;\n  padding: 15px;\n}\n\n.faultsListCol {\n  height: 100%;\n  --ion-grid-column-padding: 0;\n  min-height: 150px;\n  overflow-y: auto;\n  max-height: 400px;\n  overflow-y: auto;\n}\n\n.faultsTitleRow {\n  height: 50px;\n  padding: 15px;\n  margin-bottom: 20px;\n  padding-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
