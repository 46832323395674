// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.desktopNotes {
  height: max-content;
  width: 505px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 320px;
  background-color: var(--ion-color-light);
  padding: 20px;
}
.emptyNotesWrap {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.emptyNotesImage {
  padding: 20px;
}
.sendButton {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.commentsModalTitleDesktop {
  display: flex;
  flex: 1 1;
  height: min-content;
  position: absolute;
  top: 24px;
  right: 24px;
  left: unset;
  justify-content: flex-end;
}
.commentsModalTitleDesktop:lang(he) {
  right: unset;
  left: 24px;
}
.commentsModalTitleDesktop .pmButton {
  width: 38px;
  height: 38px;
  max-height: 42px;
}
.mainNotesDesktopWrap {
  width: 100%;
  height: max-content;
  padding: 15px;
  background-color: var(--ion-color-light);
}
.mainNotesGrid {
  height: 100%;
  overflow-y: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/SPIKEGorilla/Observer/MainNotesTabDesktop.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,iBAAiB;EACjB,wCAAwC;EACxC,aAAa;AACf;AACA;EACE,aAAa;EACb,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,yBAAyB;EACzB,qBAAqB;AACvB;AACA;EACE,aAAa;EACb,SAAO;EACP,mBAAmB;EACnB,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,WAAW;EACX,yBAAyB;AAC3B;AACA;EACE,YAAY;EACZ,UAAU;AACZ;AACA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,mBAAmB;EACnB,aAAa;EACb,wCAAwC;AAC1C;AACA;EACE,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".desktopNotes {\n  height: max-content;\n  width: 505px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  min-height: 320px;\n  background-color: var(--ion-color-light);\n  padding: 20px;\n}\n.emptyNotesWrap {\n  display: flex;\n  width: 100%;\n  height: 100%;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n.emptyNotesImage {\n  padding: 20px;\n}\n.sendButton {\n  display: flex;\n  justify-content: flex-end;\n  align-items: flex-end;\n}\n.commentsModalTitleDesktop {\n  display: flex;\n  flex: 1;\n  height: min-content;\n  position: absolute;\n  top: 24px;\n  right: 24px;\n  left: unset;\n  justify-content: flex-end;\n}\n.commentsModalTitleDesktop:lang(he) {\n  right: unset;\n  left: 24px;\n}\n.commentsModalTitleDesktop .pmButton {\n  width: 38px;\n  height: 38px;\n  max-height: 42px;\n}\n.mainNotesDesktopWrap {\n  width: 100%;\n  height: max-content;\n  padding: 15px;\n  background-color: var(--ion-color-light);\n}\n.mainNotesGrid {\n  height: 100%;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
