import { IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import { useEffect, useState, useContext, FC } from "react";

import { useTranslation } from "react-i18next";
import useForcesChecked from "../../../components/CustomHooks/useForcesChecked";
import MobileHeader from "../../../components/Mobile/MobileHeader/MobileHeader";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import { ITabOptions } from "../../../Interfaces/IDataHistory";
import { IDateRange } from "../../../Interfaces/IDatePicker";
import IForceTreeNode from "../../../Interfaces/IForceTreeNode";
import { userRoles } from "../../../services/routeRoles";
import ForcesContext from "../../../context/ForcesContext/forcesContext";

import "./DataHistoryMobile.css";
import HistoryListMobile from "./HistoryListMobile";
import OrbatTreeModal from "../../../components/Mobile/OrbatTreeModal/OrbatTreeModal";
import SegmentTitle from "./SegmentTitle";
import SegmentsMenu from "./SegmentsMenu";
import { UserContext } from "../../../context/UserContext/userContext";
import usePlans from "../../../components/CustomHooks/usePlans";
import EDateDrawer from "../../../Enums/EDateDrawer";
import PMLabel from "../../../components/themeComponents/PMLabel";
import { useDataHistoryTabsHeaders } from "../../../components/CustomHooks/useDataHistoryTabsHeaders";
import DateDrawerRange from "../../../components/Mobile/DateDrawer/DateDrawerRange";

interface IProps {}

const DataHistoryMobile: FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation();

  const [selectedSegment, setSelectedSegment] = useState<
    ITabOptions | undefined
  >();

  const [selectedSoldier, setSelectedSoldier] = useState<IForceTreeNode[]>(
    [] as IForceTreeNode[]
  );

  const [rangeDate, setRangeDate] = useState<IDateRange | undefined>();

  //state to control what to display in the modal
  const [drawerState, setDrawerState] = useState<
    EDateDrawer.selectEnd | EDateDrawer.selectStart
  >(EDateDrawer.selectStart);

  const { tabsOptions } = useDataHistoryTabsHeaders();
  // useContext hooks
  const { user } = useContext(UserContext);
  const { activePlan } = usePlans();
  const { forces, isForcesTreeOpen, setIsForcesTreeOpen } =
    useContext(ForcesContext);
  const { forcesToRemove, newForces } = useForcesChecked(selectedSoldier);

  useEffect(() => {
    setSelectedSoldier(forces);
  }, [forces]);

  useEffect(() => {
    if (
      user.relatedForce?.is_soldier &&
      user.role === userRoles.Viewer &&
      Number(user.relatedForce.id) === Number(user.forceToDisplayInOrbat?.id)
    ) {
      setRangeDate(undefined);
    }
  }, [user]);

  useEffect(() => {
    tabsOptions.length && setSelectedSegment(tabsOptions[0]);
  }, [tabsOptions]);
  return (
    <IonPage>
      <IonContent scrollY={false}>
        <MobileHeader
          iconEndSrc={EIconsSrc.MENU_ICON}
          isShowForcesTree
          title=""
        />
        <IonGrid className="mobile_dh_grid">
          <IonRow>
            <PMLabel
              fontColor="xLight"
              fontFamily="Light"
              fontSize="medium"
              textAlign="start"
              cssClass="planTitle"
            >
              {activePlan
                ? `${t("activePlan")} ${activePlan.name}`
                : t("noActivePlanSelected")}
            </PMLabel>
          </IonRow>
          <IonRow>
            <SegmentsMenu
              segmentsOptions={tabsOptions}
              selectedSegment={selectedSegment}
              setSelectedSegment={setSelectedSegment}
            />
          </IonRow>
          <IonRow className="title-name">
            <SegmentTitle selectedSegment={selectedSegment} />
          </IonRow>

          {selectedSegment && (
            <HistoryListMobile
              trainingTypeId={selectedSegment.tabId}
              rangeDate={rangeDate}
              selectedSoldier={selectedSoldier}
              setSelectedSoldier={setSelectedSoldier}
              forcesToRemove={forcesToRemove}
              newForces={newForces!}
              activePlanId={activePlan?.id}
            />
          )}
        </IonGrid>
        <DateDrawerRange
          idToControl="data-history-drawer"
          rangeDate={rangeDate}
          setRangeDate={setRangeDate}
          drawerState={drawerState}
          setDrawerState={setDrawerState}
        />
      </IonContent>
      <OrbatTreeModal
        limit={3}
        isOpen={isForcesTreeOpen}
        close={() => setIsForcesTreeOpen(false)}
        mode="secondary"
      />
    </IonPage>
  );
};

export default DataHistoryMobile;
