import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import Axios from "../../../Axios";
import { baseUrlPMBackend } from "../../../Configurations/consts";
import customToast from "../../Shared/Toast/CustomToast";
import ForcesListContext from "../../../context/ForcesContext/forcesListContext";
import { getForceFullName } from "../../../services/helpers";
import { UserContext } from "../../../context/UserContext/userContext";
import { userRoles } from "../../../services/routeRoles";
/**
 *
 * @returns array with of trainees
 */
const asyncFetchTrainees = async (forceId: number | undefined) => {
  const res = await Axios.get(
    `${baseUrlPMBackend}forces/getSoldiersIdsAndNames`,
    {
      params: { forceId: forceId },
    }
  );
  return res.data;
};

export const useGetTrainees = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  const { soldiersNamesAndIds, setSoldiersNamesAndIds } =
    useContext(ForcesListContext);

  let { data, isLoading, isError } = useQuery<
    {
      name: string;
      forceType: string;
      uniqId: number;
    }[]
  >({
    queryKey: ["soldiersNamesAndIds"],
    queryFn: () => asyncFetchTrainees(user.forceToDisplayInOrbat?.id),
    enabled:
      !soldiersNamesAndIds.length &&
      (user.role === userRoles.Admin ||
        user.forceToDisplayInOrbat?.id !== undefined),
  });

  useEffect(() => {
    if (!isLoading && !isError && data && data?.length) {
      //det the context with the indicators data
      setSoldiersNamesAndIds(
        data.map(
          (trainee: { name: string; forceType: string; uniqId: number }) => ({
            ...trainee,
            name: getForceFullName(trainee.name, trainee.forceType, t),
          })
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (isError) {
      customToast.error(t("errorWhileLoadingTraineesData"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return { soldiersNamesAndIds };
};
