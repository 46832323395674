import React from "react";
import FinalGradeDisplay from "../../../../Shared/FinalGradeDisplay/FinalGradeDisplay";
import Spinner from "../../../../Shared/Spinner/Spinner";
import "./FinalGrade.css";

interface IFinalGrade {
  lethality: number;
  isLoading: boolean;
  forceName: string;
  forceColor: string;
}
const FinalGrade = (props: IFinalGrade) => {
  return (
    <div className="finalGradeDiv">
      {!props.isLoading ? (
        <FinalGradeDisplay
          soldierFinalGrade={{
            id: 1,
            grade: props.lethality,
            name: props.forceName,
            legendColor: props.forceColor,
            isAttended: true,
          }}
          threshold={100}
          isMobile
        ></FinalGradeDisplay>
      ) : (
        <Spinner className="competency-spinner" />
      )}
    </div>
  );
};

export default FinalGrade;
