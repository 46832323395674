import { IonCol, IonRow, IonLabel } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { IElement } from "../../../Interfaces/ITrainingPlan";
import Element from "./Element";
import "./Elements.css";
import i18n from "../../../services/i18next";
import { IErrorState } from "../../CustomHooks/useCheckSumErrors";
import { ITrainingPlanProps } from "../../../Interfaces/TrainingPlanContext/ITrainingPlan";

interface IElementsProps extends ITrainingPlanProps {
  elements: IElement[] | undefined;
}

const Elements: React.FC<IElementsProps> = (props: IElementsProps) => {
  const { elements } = props;

  const { t } = useTranslation();

  return (
    <IonCol className="elementsWrap">
      <IonRow className="elementsHeadersRow elementsTitleRow">
        <IonCol size="3.05" className="elNameCol">
          <IonLabel className="elementsHeader ">{t("gradeElement")}</IonLabel>
        </IonCol>
        <IonCol size="3.1">
          <IonLabel className="elementsHeader">{t("elementWeight")}</IonLabel>
        </IonCol>
        <IonCol size="1.3">
          <div className="elThresholdsDiv">
            <IonLabel className="elementsHeader">
              {t("lowerThreshold")}
            </IonLabel>
          </div>
        </IonCol>
        <IonCol size="1.3">
          <div className="elThresholdsDiv">
            <IonLabel className="elementsHeader">
              {t("upperThreshold")}
            </IonLabel>
          </div>
        </IonCol>
        <IonCol size="1.3">
          <div className="elThresholdsDiv">
            <IonLabel className="elementsHeader ">
              {t("requiredThreshold")}
            </IonLabel>
          </div>
        </IonCol>
        <IonCol size="1" className="checkboxHeader">
          <div className="isForDashboardCheckbox">
            <IonLabel className="elementsHeader isForDashboardLabel ">
              {t("isForDashboard")}
            </IonLabel>
          </div>
        </IonCol>
      </IonRow>
      <IonRow className="elementsRow elementRow ">
        {elements &&
          elements
            // .filter((element) => element.stationId === stationId)
            .sort((a, b) =>
              t(a.name).localeCompare(t(b.name), i18n.language, {
                numeric: true,
                sensitivity: "base",
              })
            )
            .map((element, index) => (
              <Element
                key={index}
                index={index}
                element={element}
                setIsErrorExist={props.setIsErrorExist}
                elementsForDashboard={props.elementsForDashboard}
              ></Element>
            ))}
      </IonRow>
    </IonCol>
  );
};

export default Elements;
