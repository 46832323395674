import { useContext, useEffect, useState } from "react";
import {
  IElement,
  IIndicator,
  ITrainingType,
} from "../../Interfaces/ITrainingPlan";
import { useSelector } from "react-redux";
import { ITrainingPlanReducer } from "../../redux/reducers/trainingPlan";
import { AppState } from "../../redux/store/plansStore";
import { AppContext } from "../../context/AppContext/AppContext";
import { commanderDashboardMaximumElementsForIndicator } from "../../Configurations/consts";
export interface Errors {
  weight?: string;
  upperThreshold?: string;
  lowerThreshold?: string;
  requiredThreshold?: string;
  threshold?: string;
}
export interface IErrorState {
  elements: { [key: string]: boolean };
  trainingTypes: { [key: string]: boolean };
  indicators: { [key: string]: boolean };
}
const errorType = "sumMoreThan100";
const useCheckSumErrors = (
  setIsErrorExist: React.Dispatch<React.SetStateAction<IErrorState>>,
  element?: IElement,
  trainingType?: ITrainingType,
  indicator?: IIndicator
) => {
  const [elementsForDashboard, setElementsForDashboard] = useState<string[]>(
    []
  );
  const indicatorsState = useSelector<AppState, ITrainingPlanReducer>(
    (state) => state.indicators
  );
  const [errors, setErrors] = useState<Errors>({});

  useEffect(() => {
    console.log(indicatorsState);

    const hasErrors = Object.values(errors).some((error) => error?.length);
    if (element)
      setIsErrorExist((prev: any) => ({
        ...prev,
        elements: { ...prev.elements, [String(element.elementId)]: hasErrors },
      }));

    if (trainingType)
      setIsErrorExist((prev: any) => ({
        ...prev,
        trainingTypes: {
          ...prev.trainingTypes,
          [String(trainingType.trainingTypeId)]: hasErrors,
        },
      }));
    if (indicator) {
      setIsErrorExist((prev: any) => ({
        ...prev,
        indicators: {
          ...prev.indicators,
          [String(indicator.indicatorId)]:
            hasErrors ||
            //if the elements for dashboard > maximum the training plan can't be saved
            getDashboardElements().length >
              commanderDashboardMaximumElementsForIndicator,
        },
      }));
    }

    // eslint-disable-next-line
  }, [errors, indicatorsState]);

  useEffect(() => {
    const calculateSum = (property: any) => (elements: any[] | undefined) =>
      elements?.reduce(
        (sum: number, element: any) => sum + Number(element[property]),
        0
      ) || 0;

    let myObj: any = indicatorsState.indicators;

    if (element) {
      const foundIndicator = myObj.find(
        (i: any) => i.indicatorId === element.indicatorId
      );
      if (foundIndicator) {
        const foundTrainingType = foundIndicator.trainingTypes.find(
          (t: any) => t.trainingTypeId === element.trainingTypeId
        );
        if (foundTrainingType) {
          myObj = foundTrainingType.elements;
        }
      }
    } else if (trainingType) {
      const foundIndicator = myObj.find(
        (i: any) => i.indicatorId === trainingType.indicatorId
      );
      if (foundIndicator) {
        myObj = foundIndicator.trainingTypes;
      }
    }

    const propertiesToCheck: any[] = ["weight"];

    const errorsToUpdate: Errors = propertiesToCheck.reduce((er, property) => {
      const sum = calculateSum(property)(myObj);
      let elementKey: keyof (IElement | ITrainingType | IIndicator) = property;
      let j: keyof Errors = property;
      if (
        sum > 100 &&
        ((element && element[elementKey]) ||
          (trainingType && trainingType[elementKey]) ||
          (indicator && indicator[elementKey]))
      ) {
        er[property] = errorType;
      } else if (errors[j] === errorType) {
        er[property] = "";
      }
      return er;
    }, {});

    setErrors((prev: any) => ({ ...prev, ...errorsToUpdate }));
    // eslint-disable-next-line
  }, [element, trainingType, indicatorsState.indicators]);

  const getDashboardElements = () => {
    if (indicatorsState.indicators.length) {
      //get the indicator's elements with is for dashboard value = true as a unique element name array
      let indicatorElements = indicatorsState.indicators
        .find((i) => i.indicatorId === indicator?.indicatorId)
        ?.trainingTypes?.map((trainingType) => trainingType.elements)
        .flat(1)
        .filter(
          (element) => element.isForDashboard || element.isOnlyForDashboard
        )
        .map((element) => element.name);
      let uniqElementsArray = Array.from(new Set(indicatorElements));

      return uniqElementsArray;
    }
    return [];
  };

  useEffect(() => {
    //when one of the indicator elements changes update the
    // elementsForDashboard that it's available to be for dashboard for the indicator get the first 4 elements
    if (indicator)
      setElementsForDashboard(
        getDashboardElements().slice(
          0,
          commanderDashboardMaximumElementsForIndicator
        )
      );
  }, [indicatorsState, indicator]);
  return {
    errors,
    setErrors,
    elementsForDashboard,
  };
};

export default useCheckSumErrors;
