import { IonItem, IonItemGroup } from "@ionic/react";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./SearchForceSelectDropdown.css";
import ISimpleTrainee from "../../../../Interfaces/ISimpleTrainee";
import PMLabel from "../../../themeComponents/PMLabel";
import PMInput from "../../../Desktop/TrainingPlan/PMInput";
import EIconsSrc from "../../../../Interfaces/EIconsSrc";
import useGetFilterForce from "./useGetFilterForces";
import PMCollapse from "../../PMCollapse/PMCollapse";

interface IProps {
  setSoldierDetails: React.Dispatch<
    React.SetStateAction<ISimpleTrainee | undefined>
  >;
  soldierDetails: ISimpleTrainee | undefined;
  forceType: string;
}

const SearchForceSelectDropdown: React.FC<IProps> = (
  props: IProps
): JSX.Element => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);
  const { setSearchText, searchText, displayedArray } = useGetFilterForce();
  const inputRefs = useRef<(HTMLIonItemElement | null)[]>([]);

  const onSelect = (force: ISimpleTrainee | undefined) => {
    props.setSoldierDetails(force);
  };

  useEffect(() => {
    if (!isOpen) {
      setSearchText(props.soldierDetails?.name || "");
    }
  }, [isOpen]);

  useEffect(() => {
    if (props.soldierDetails) {
      setSearchText(props.soldierDetails.name);
    } else {
      setSearchText("");
    }
  }, [props.soldierDetails]);

  useEffect(() => {
    if (focusedIndex !== null && inputRefs.current[focusedIndex]) {
      inputRefs.current[focusedIndex]?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [focusedIndex]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!isOpen) return;

    if (event.key === "ArrowDown") {
      setFocusedIndex((prevIndex) => {
        if (prevIndex === null) return 0;
        return (prevIndex + 1) % displayedArray.length;
      });
    } else if (event.key === "ArrowUp") {
      setFocusedIndex((prevIndex) => {
        if (prevIndex === null) return displayedArray.length - 1;
        return (prevIndex - 1 + displayedArray.length) % displayedArray.length;
      });
    } else if (event.key === "Enter" && focusedIndex !== null) {
      setIsOpen(false);
      onSelect(displayedArray[focusedIndex]);
    }
  };

  const HeaderInput = () => (
    <PMInput
      inputType={"text"}
      placeholder={t(props.forceType)}
      inputName={t(props.forceType)}
      inputValue={searchText}
      icon={searchText.length ? EIconsSrc.CLOSE : EIconsSrc.SEARCH_OUTLINE}
      iconInInput
      fontSize="large"
      onChangeHandler={(_field, value) => {
        setSearchText(value);
      }}
      cssClass="searchForceDropdownInput"
      onClick={() => {
        setIsOpen(true);
      }}
      onIconClick={() => {
        if (searchText.length) {
          props.setSoldierDetails(undefined);
          setSearchText("");
        }
      }}
    />
  );

  return (
    <div onKeyDown={handleKeyDown} tabIndex={0}>
      <PMCollapse
        hederItem={HeaderInput}
        children={
          <IonItemGroup>
            {displayedArray.map((item, index) => (
              <IonItem
                ref={(el) => (inputRefs.current[index] = el)}
                lines="none"
                className={`searchForceItem ${
                  focusedIndex === index ? "focused" : ""
                }`}
                key={index}
                onClick={() => {
                  setIsOpen(false);
                  onSelect(item);
                }}
                color={
                  props.soldierDetails?.uniqId === item.uniqId
                    ? "orange"
                    : undefined
                }
              >
                <PMLabel fontColor="light">{item.name}</PMLabel>
              </IonItem>
            ))}
          </IonItemGroup>
        }
        placeholder={t(props.forceType)}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </div>
  );
};

export default SearchForceSelectDropdown;
