import { IonButton, IonIcon } from "@ionic/react";
import React, { Dispatch, FC, useState } from "react";
import PMLabel from "./PMLabel";
import { IPMButton, IPMIcon, IPMLabel } from "../../Interfaces/IPMTheme";
import "./PopOverButton.css";
import "./PMIcon.css";
import PMButton from "./PMButton";
import PopoverMenu from "../Shared/Popover/PopoverMenu";
import PopoverItem from "./PopoverItem";
import { useTranslation } from "react-i18next";
import PMIcon from "./PMIcon";
interface IProps extends IPMButton {
  popOverItems: { title: string; onClick: () => void; icon?: string }[];
}
const PopOverButton: FC<IProps> = (props: IProps) => {
  const {} = props;
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const { t } = useTranslation();

  return (
    <div>
      <PMButton
        size={props.size}
        color={props.color}
        fill={props.fill}
        icon={props.icon}
        cssClass={props.cssClass}
        isDisabled={props.isDisabled}
        isHidden={props.isHidden}
        onClickHandler={(e: any) => {
          e.persist();
          setShowPopover({ showPopover: true, event: e });
        }}
        label={props.label}
      >
        {props.children}
      </PMButton>

      <PopoverMenu
        popoverState={popoverState}
        onDismiss={() =>
          setShowPopover({ showPopover: false, event: undefined })
        }
        className="popOverButton"
      >
        {props.popOverItems.map((popOverItem, index) => (
          <PopoverItem
            key={index}
            fontColor="light"
            onClickHandler={() => {
              popOverItem.onClick();
              setShowPopover({ showPopover: false, event: undefined });
            }}
            background="Blight"
          >
            <div
              className={` pmButtonWrap ${
                !popOverItem.icon ? "noIconButton" : ""
              }`}
            >
              <PMLabel fontFamily="Light" fontSize="medium" textAlign="center">
                {t(popOverItem.title)}
              </PMLabel>

              {popOverItem.icon ? (
                <PMIcon
                  cssClass="popoverButtonIcon"
                  iconSrc={popOverItem.icon}
                  size="medium"
                ></PMIcon>
              ) : null}
            </div>
          </PopoverItem>
        ))}
      </PopoverMenu>
    </div>
  );
};
export default PopOverButton;
