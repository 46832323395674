// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.syncProgressbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  justify-self: center;
}
.progressbar {
  margin-inline-start: 20px;
  width: 18vw;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/SyncProgressBar/SyncProgressBar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;EAC9B,oBAAoB;AACtB;AACA;EACE,yBAAyB;EACzB,WAAW;AACb","sourcesContent":[".syncProgressbar {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  justify-self: center;\n}\n.progressbar {\n  margin-inline-start: 20px;\n  width: 18vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
