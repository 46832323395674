import { useContext, useEffect, useState } from "react";
import {
  IElementsPlanValue,
  ITrainingTypesPlanValue,
} from "../../../Interfaces/IPlanValues";
import { usePlanValuesForSelectedPlan } from "./usePlanValuesForSelectedPlan";
import { IElement, IIndicator } from "../../../Interfaces/ITrainingPlan";
import IElementType from "../../../Interfaces/IElementType";
import { IndicatorsContext } from "../../../context/IndicatorsContext/IndicatorsContext";
import IIndicatorType from "../../../Interfaces/IIndicatorType";
import { TrainingTypesContext } from "../../../context/TrainingTypesContext/TrainingTypesContext";
import { ElementsContext } from "../../../context/ElementsContext/ElementsContext";
/**
 *
 * @returns array with of elements
 */
export const useTrainingPlanValues = (
  planId?: number,
  isAllPlans?: boolean
) => {
  const { plansValuesForSelectedPlan, refetch } = usePlanValuesForSelectedPlan(
    planId,
    isAllPlans
  );
  const [trainingPlanIndicators, setTrainingPlanIndicators] =
    useState<IIndicator[]>();
  const { elements } = useContext(ElementsContext);
  const { trainingTypes } = useContext(TrainingTypesContext);
  const { indicators } = useContext(IndicatorsContext);
  useEffect(() => {
    if (plansValuesForSelectedPlan) {
            try {
        let indicatorsWithValues: IIndicator[] =
          plansValuesForSelectedPlan.indicatorsPlanValues
            .filter((indicator) =>
              indicators.find(
                (i: IIndicatorType) => i.id === indicator.indicatorId
              )
            )
            .map((indicator) => {
              let indicatorType = indicators.find(
                (i: IIndicatorType) => i.id === indicator.indicatorId
              );
              let trainingTypesWithValues: ITrainingTypesPlanValue[] =
                plansValuesForSelectedPlan.trainingTypesPlanValues.filter(
                  (trainingType) =>
                    trainingTypes.find(
                      (t) => t.id === trainingType.trainingTypeId
                    )?.indicatorId === indicator.indicatorId
                );
              return {
                ...indicator,
                indicatorId: indicatorType!.id,
                name: indicatorType!.name,
                trainingTypes: trainingTypesWithValues.map((trainingType) => {
                  let trainingTypeType = trainingTypes.find(
                    (t) => t.id === trainingType.trainingTypeId
                  );
                  let elementsTypes: IElementType[] = elements.filter(
                    (e) => e.trainingTypeId === trainingType.trainingTypeId
                  );
                  let elementsWithValues: IElement[] = [];
                  elementsTypes.forEach((element: IElementType) => {
                    let elementWithValues: IElementsPlanValue | undefined =
                      plansValuesForSelectedPlan.elementsPlanValues.find(
                        (e) => e.elementId === element.id
                      );

                    let elem: IElement = elementWithValues
                      ? {
                          ...element,
                          ...elementWithValues,
                          elementId: element.id,
                          indicatorId: indicatorType!.id,
                          viewOnly: false,
                        }
                      : {
                          ...element,
                          elementId: element.id,
                          indicatorId: indicatorType!.id,
                          isForDashboard: false,
                          lowerThreshold: null,
                          requiredThreshold: null,
                          upperThreshold: null,
                          viewOnly: false,
                          weight: null,
                        };

                    if (elementWithValues || !element.isArchived)
                      elementsWithValues.push(elem);
                  });
                  return {
                    ...trainingType,
                    name: trainingTypeType!.name,
                    trainingTypeId: trainingTypeType!.id,
                    elements: elementsWithValues,
                    indicatorId: indicatorType!.id,
                  };
                }),
              };
            });
        setTrainingPlanIndicators(indicatorsWithValues);
      } catch (error) {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plansValuesForSelectedPlan]);

  return { trainingPlanIndicators, refetch };
};
