// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainNotes {
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-inline-start: var(--pm_spike_gorilla);
  padding-inline-end: var(--pm_spike_gorilla);
  padding-block-start: var(--pm_spike_gorilla);
}

.withBorderHeatsWrap {
  border-style: dashed;
  border-width: 0;
  border-top-width: 1px;
  border-color: var(--ion-color-disabled);
}
.commentsAndEndTraining {
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  max-height: min-content;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/SPIKEGorilla/NotesAndEndTraining.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,uBAAuB;EACvB,6CAA6C;EAC7C,2CAA2C;EAC3C,4CAA4C;AAC9C;;AAEA;EACE,oBAAoB;EACpB,eAAe;EACf,qBAAqB;EACrB,uCAAuC;AACzC;AACA;EACE,WAAW;EACX,6BAA6B;EAC7B,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB","sourcesContent":[".mainNotes {\n  height: 100px;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n  padding-inline-start: var(--pm_spike_gorilla);\n  padding-inline-end: var(--pm_spike_gorilla);\n  padding-block-start: var(--pm_spike_gorilla);\n}\n\n.withBorderHeatsWrap {\n  border-style: dashed;\n  border-width: 0;\n  border-top-width: 1px;\n  border-color: var(--ion-color-disabled);\n}\n.commentsAndEndTraining {\n  width: 100%;\n  background-color: transparent;\n  display: flex;\n  flex-direction: column;\n  max-height: min-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
