// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.usersDiv {
  width: 100%;
  height: 100%;
  padding-inline-start: 24px;
}

.usersListTitle {
  padding-inline-start: 15px !important;
}

.listHeader {
  font-size: 18px;
  padding: 0;
}

.usersListHeadersRow {
  padding-inline-start: 35px !important;
  padding-inline-end: 5px !important;
  width: calc(95% - 8px);
  padding-top: 28px;
  padding-bottom: 8px;
  color: var(--ion-color-fontXLight);
}
.usersList {
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  min-width: 750px;
}
.usersListRow {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  height: 70%;
}

.searchBarRow {
  display: flex;
  width: 460px;
  align-items: flex-start;
  justify-content: flex-start;
}
.userHeaderCol {
  padding: 5px;
}

`, "",{"version":3,"sources":["webpack://./src/components/Desktop/Settings/ManageUsers/Users.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,0BAA0B;AAC5B;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,qCAAqC;EACrC,kCAAkC;EAClC,sBAAsB;EACtB,iBAAiB;EACjB,mBAAmB;EACnB,kCAAkC;AACpC;AACA;EACE,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,uBAAuB;EACvB,2BAA2B;AAC7B;AACA;EACE,YAAY;AACd","sourcesContent":[".usersDiv {\n  width: 100%;\n  height: 100%;\n  padding-inline-start: 24px;\n}\n\n.usersListTitle {\n  padding-inline-start: 15px !important;\n}\n\n.listHeader {\n  font-size: 18px;\n  padding: 0;\n}\n\n.usersListHeadersRow {\n  padding-inline-start: 35px !important;\n  padding-inline-end: 5px !important;\n  width: calc(95% - 8px);\n  padding-top: 28px;\n  padding-bottom: 8px;\n  color: var(--ion-color-fontXLight);\n}\n.usersList {\n  height: 100%;\n  overflow-y: auto;\n  overflow-x: auto;\n  min-width: 750px;\n}\n.usersListRow {\n  display: flex;\n  flex-direction: column;\n  overflow-y: hidden;\n  height: 70%;\n}\n\n.searchBarRow {\n  display: flex;\n  width: 460px;\n  align-items: flex-start;\n  justify-content: flex-start;\n}\n.userHeaderCol {\n  padding: 5px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
