import { useState, useEffect, useRef } from "react";

const useStopwatch = (initTime?: number) => {
  const [isRunning, setIsRunning] = useState(false);
  const [elapsedTime, setElapsedTime] = useState((initTime || 0) * 1000); // initTime in seconds, convert to milliseconds
  const startTimeRef = useRef<any>(0);
  const intervalRef = useRef<any>(null);

  useEffect(() => {
    if (isRunning) {
      startTimeRef.current = Date.now() - elapsedTime;
      intervalRef.current = setInterval(() => {
        setElapsedTime(Date.now() - startTimeRef.current);
      }, 1); // Update every 10 milliseconds
    } else {
      clearInterval(intervalRef.current);
    }

    return () => clearInterval(intervalRef.current);
  }, [isRunning]);
  useEffect(() => {
    setElapsedTime((initTime || 0) * 1000);
    startTimeRef.current = Date.now() - (initTime || 0) * 1000;
  }, [initTime]);
  const start = () => {
    setIsRunning(true);
  };

  const stop = () => {
    setIsRunning(false);
  };

  const pause = () => {
    setIsRunning(false);
  };

  const reset = () => {
    setIsRunning(false);
    setElapsedTime((initTime || 0) * 1000);
    startTimeRef.current = Date.now() - (initTime || 0) * 1000;
  };

  return {
    isRunning,
    milliseconds: elapsedTime,
    start,
    stop,
    pause,
    reset,
  };
};

export default useStopwatch;
