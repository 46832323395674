// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.labelButton {
  display: flex;
  padding-inline-end: 10px;
  margin-top: 10px;
}

.activeHashtag {
  --background: var(--ion-color-orange) !important;
}

.inactiveHashtag {
  --background: var(--ion-color-primary);
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/Hashtags/HashtagLabel.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,wBAAwB;EACxB,gBAAgB;AAClB;;AAEA;EACE,gDAAgD;AAClD;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":[".labelButton {\n  display: flex;\n  padding-inline-end: 10px;\n  margin-top: 10px;\n}\n\n.activeHashtag {\n  --background: var(--ion-color-orange) !important;\n}\n\n.inactiveHashtag {\n  --background: var(--ion-color-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
