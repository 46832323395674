import { FC } from "react";
import "./Divider.css";
interface IProps {
  isFull?: boolean;
  solid?: boolean;
}
const Divider: FC<IProps> = (props: IProps): JSX.Element => {
  return (
    <div
      className={`borderDivider ${props.isFull ? "fullDivider" : ""}
    ${props.solid ? "solidDivider" : ""}`}
    ></div>
  );
};

export default Divider;
