import IHeat from "../../../../Interfaces/IHeat";

export const heatList: IHeat[] = [
  {
    id: 0,
    name: "איפוס",
    repetitions: 4,
    bullets: "12",
    hashtags: "איפוס",
  },
  {
    id: 2,
    name: "איפוס",
    repetitions: 4,
    bullets: "20",
    hashtags: "איפוס",
  },
  {
    id: 3,
    name: "איפוס",
    repetitions: 5,
    bullets: "25",
    hashtags: "איפוס",
  },
  {
    id: 4,
    name: "מקבץ הישגי",
    repetitions: 5,
    bullets: "25",
    hashtags: "מקבץ_הישגי",
  },
  {
    id: 5,
    name: "עמידה אינסט' 1",
    repetitions: 1,
    bullets: "6",
    hashtags: "עמידה_אינסט_1",
  },
  {
    id: 6,
    name: "עמידה אינסט' 2",
    repetitions: 1,
    bullets: "8",
    hashtags: "עמידה_אינסט_2",
  },
  {
    id: 7,
    name: "רד חפה 1",
    repetitions: 1,
    bullets: "6",
    hashtags: "רד_חפה_1",
  },
  {
    id: 8,
    name: "רד חפה 2",
    repetitions: 1,
    bullets: "8",
    hashtags: "רד_חפה_2",
  },
  {
    id: 9,
    name: "שישה מקבצים",
    repetitions: 1,
    bullets: "30",
    hashtags: "שישה_מקבצים",
  },
  {
    id: 10,
    name: "התקלות טווח קצר",
    repetitions: 1,
    bullets: "20",
    hashtags: "התקלות_טווח_קצר",
  },
  {
    id: 11,
    name: "עמידה אינסט' 2 - לילה",
    repetitions: 1,
    bullets: "6",
    hashtags: "עמידה_אינסט_2_לילה",
  },
  {
    id: 12,
    name: "רד חפה 2 - לילה",
    repetitions: 1,
    bullets: "6",
    hashtags: "רד_חפה_2_לילה",
  },
  {
    id: 13,
    name: "מעצור גמור",
    repetitions: 1,
    bullets: "12",
    hashtags: "מעצור_גמר",
  },
  {
    id: 14,
    name: "אזורי פגיעה גמר 1",
    repetitions: 1,
    bullets: "18",
    hashtags: "אזורי_פגיעה_גמר_1",
  },
  {
    id: 15,
    name: "אזורי פגיעה גמר 2",
    repetitions: 1,
    bullets: "12",
    hashtags: "אזורי_פגיעה_גמר_2",
  },
  {
    id: 16,
    name: "אזורי פגיעה גמר 2",
    repetitions: 1,
    bullets: "12",
    hashtags: "אזורי_פגיעה_גמר_2",
  },
  {
    id: 17,
    name: "1 מעבר בין מטרות",
    repetitions: 1,
    bullets: "12",
    hashtags: "מעבר_בין_מטרות_1",
  },
  {
    id: 18,
    name: "2 מעבר בין מטרות",
    repetitions: 1,
    bullets: "24",
    hashtags: "מעבר_בין_מטרות_2",
  },
  {
    id: 19,
    name: "הסטים - טווח קצר",
    repetitions: 1,
    bullets: "18",
    hashtags: "הסטים_טווח_קצר",
  },
  {
    id: 20,
    name: "בוחן 150",
    repetitions: 1,
    bullets: "15",
    hashtags: "בוחן_150",
  },
  {
    id: 21,
    name: "מעצור גמר - לילה",
    repetitions: 1,
    bullets: "12",
    hashtags: "מעצור_גמר_לילה",
  },
  {
    id: 22,
    name: "מעבר בין מטרות 1 - לילה",
    repetitions: 1,
    bullets: "12",
    hashtags: "מעבר_בין_מטרות_1_לילה",
  },
  {
    id: 23,
    name: "כריעה",
    repetitions: 1,
    bullets: "5",
    hashtags: "כריעה",
  },
  {
    id: 60,
    name: "כריעה",
    repetitions: 1,
    bullets: "5",
    hashtags: "כריעה",
  },
  {
    id: 24,
    name: "בוחן_רמה",
    repetitions: 1,
    bullets: "10",
    hashtags: "בוחן_רמה",
  },
  {
    id: 25,
    name: 'יל"מ כריעה 1',
    repetitions: 1,
    bullets: "3",
    hashtags: "ילמ_כריעה_1",
  },
  {
    id: 26,
    name: 'יל"מ כריעה 2',
    repetitions: 1,
    bullets: "3",
    hashtags: "ילמ_כריעה_2",
  },
  {
    id: 27,
    name: 'בוחן יל"מ',
    repetitions: 1,
    bullets: "10",
    hashtags: "בוחן_ילמ",
  },
  {
    id: 28,
    name: "יישור מקורב 1",
    repetitions: 1,
    bullets: "7",
    hashtags: "יישור_מקורב_1",
  },
  {
    id: 29,
    name: "יישור מקורב 1 - לילה",
    repetitions: 1,
    bullets: "7",
    hashtags: "יישור_מקורב_1_לילה",
  },
  {
    id: 30,
    name: "ווידוא איפוס",
    repetitions: 1,
    bullets: "5",
    hashtags: "ווידוא_איפוס",
  },
  {
    id: 31,
    name: "יסודות הקליעה טווח בינוני 1",
    repetitions: 1,
    bullets: "5",
    hashtags: "יסודות_הקליעה_טווח_בינוני_1",
  },
  {
    id: 32,
    name: "יסודות הקליעה טווח בינוני 1",
    repetitions: 1,
    bullets: "10",
    hashtags: "יסודות_הקליעה_טווח_בינוני_1",
  },
  {
    id: 33,
    name: "יסודות הקליעה טווח בינוני 2",
    repetitions: 1,
    bullets: "10",
    hashtags: "יסודות_הקליעה_טווח_בינוני_2",
  },
  {
    id: 34,
    name: "יסודות הקליעה טווח בינוני 3",
    repetitions: 1,
    bullets: "10",
    hashtags: "יסודות_הקליעה_טווח_בינוני_3",
  },
  {
    id: 35,
    name: "יסודות הקליעה טווח רחוק 1",
    repetitions: 1,
    bullets: "10",
    hashtags: "יסודות_הקליעה_טווח_רחוק_1",
  },
  {
    id: 36,
    name: "יסודות הקליעה טווח רחוק 2",
    repetitions: 1,
    bullets: "10",
    hashtags: "יסודות_הקליעה_טווח_רחוק_2",
  },
  {
    id: 37,
    name: "יסודות הקליעה טווח רחוק 3",
    repetitions: 1,
    bullets: "10",
    hashtags: "יסודות_הקליעה_טווח_רחוק_3",
  },
  {
    id: 38,
    name: "מקבץ הישגי",
    repetitions: 1,
    bullets: "5",
    hashtags: "מקבץ_הישגי",
  },
  {
    id: 39,
    name: "בוחן רמה",
    repetitions: 2,
    bullets: "10",
    hashtags: "בוחן_רמה",
  },
  {
    id: 40,
    name: 'בוחן יל"מ',
    repetitions: 2,
    bullets: "10",
    hashtags: "בוחן_ילמ",
  },
  {
    id: 41,
    name: "בוחן 100",
    repetitions: 2,
    bullets: "10",
    hashtags: "בוחן_100",
  },
  {
    id: 42,
    name: "בוחן טווח קצר",
    repetitions: 2,
    bullets: "20",
    hashtags: "בוחן_טווח_קצר",
  },
  {
    id: 43,
    name: "ירי מחסות 1",
    repetitions: 1,
    bullets: "8",
    hashtags: "ירי_מחסות_1",
  },
  {
    id: 44,
    name: "ירי מחסות 2",
    repetitions: 1,
    bullets: "8",
    hashtags: "ירי_מחסות_2",
  },
  {
    id: 45,
    name: "ירי מחסות 3",
    repetitions: 1,
    bullets: "8",
    hashtags: "ירי_מחסות_3",
  },
  {
    id: 46,
    name: "ירי מחסות 4",
    repetitions: 1,
    bullets: "8",
    hashtags: "ירי_מחסות_4",
  },
  {
    id: 47,
    name: "ירי מחסות 5",
    repetitions: 1,
    bullets: "6",
    hashtags: "ירי_מחסות_5",
  },
  {
    id: 48,
    name: "ירי מחסות 5",
    repetitions: 1,
    bullets: "6",
    hashtags: "ירי_מחסות_5",
  },
  {
    id: 49,
    name: "מטרות נעות 1 - עמידה",
    repetitions: 1,
    bullets: "6",
    hashtags: "מטרות_נעות_1_עמידה",
  },
  {
    id: 50,
    name: "מטרות נעות 2 - כריעה",
    repetitions: 1,
    bullets: "6",
    hashtags: "מטרות_נעות_2_כריעה",
  },
  {
    id: 51,
    name: "מטרות נעות 3 - שכיבה",
    repetitions: 1,
    bullets: "6",
    hashtags: "מטרות_נעות_3_שכיבה",
  },
  {
    id: 52,
    name: "מטרות נעות 4 - שכיבה",
    repetitions: 1,
    bullets: "6",
    hashtags: "מטרות_נעות_4_שכיבה",
  },
  {
    id: 53,
    name: "איפוס",
    repetitions: 2,
    bullets: "10",
    hashtags: "איפוס",
  },
  {
    id: 54,
    name: "מקבץ הישגי",
    repetitions: 2,
    bullets: "10",
    hashtags: "מקבץ_הישגי",
  },
  {
    id: 55,
    name: "היסטים",
    repetitions: 1,
    bullets: "18",
    hashtags: "היסטים",
  },
  {
    id: 56,
    name: "בוחן 300",
    repetitions: 1,
    bullets: "30",
    hashtags: "בוחן_300",
  },
  {
    id: 57,
    name: "התקלות מעבר בין מצבים",
    repetitions: 1,
    bullets: "12",
    hashtags: "התקלות_מעבר_בין_מצבים",
  },
  {
    id: 58,
    name: "בוחן טווח קצר",
    repetitions: 1,
    bullets: "20",
    hashtags: "בוחן_טווח_קצר",
  },
  {
    id: 59,
    name: "בוחן טווח קצר",
    repetitions: 1,
    bullets: "20",
    hashtags: "בוחן_טווח_קצר",
  },
];
