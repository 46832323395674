// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hashtagsModal {
  --width: 95vw;
  --height: 90vh;
  opacity: 1;
  --background: var(--ion-color-primary);
}

.hashtagsModalDiv {
  display: flex;
  height: 100%;
  padding: 10px;
}

.polarAreaDiv {
  display: flex;
  align-items: center;
  width: 70%;
}

.hashtagsLabelsDiv {
  display: flex;
  width: 30%;
}

.title {
  position: fixed;

  display: flex;
  flex-direction: column;
  padding-top: 0 !important;
  padding-inline-start: 10px;
}

.trainingTypeName {
  font-size: 30px;
  color: var(--ion-color-orange);
}

.forceName {
  font-size: 20px;
  color: var(--ion-color-fontLight);
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/Hashtags/HashtagsModal.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,UAAU;EACV,sCAAsC;AACxC;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,UAAU;AACZ;;AAEA;EACE,eAAe;;EAEf,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,0BAA0B;AAC5B;;AAEA;EACE,eAAe;EACf,8BAA8B;AAChC;;AAEA;EACE,eAAe;EACf,iCAAiC;AACnC","sourcesContent":[".hashtagsModal {\n  --width: 95vw;\n  --height: 90vh;\n  opacity: 1;\n  --background: var(--ion-color-primary);\n}\n\n.hashtagsModalDiv {\n  display: flex;\n  height: 100%;\n  padding: 10px;\n}\n\n.polarAreaDiv {\n  display: flex;\n  align-items: center;\n  width: 70%;\n}\n\n.hashtagsLabelsDiv {\n  display: flex;\n  width: 30%;\n}\n\n.title {\n  position: fixed;\n\n  display: flex;\n  flex-direction: column;\n  padding-top: 0 !important;\n  padding-inline-start: 10px;\n}\n\n.trainingTypeName {\n  font-size: 30px;\n  color: var(--ion-color-orange);\n}\n\n.forceName {\n  font-size: 20px;\n  color: var(--ion-color-fontLight);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
