import { useState } from "react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { useTranslation } from "react-i18next";
import DatePicker from "../DataPosting/DatePicker";
import Input from "../TrainingPlan/TrainingPlanInput";
import Axios from "../../../Axios";
import { baseUrlPMBackend } from "../../../Configurations/consts";
import customToast from "../../Shared/Toast/CustomToast";
import PMButton from "../../themeComponents/PMButton";
import PMLoading from "../../Shared/Alert/PMLoading";
import DragModal from "../DragModal/DragModal";
import "./DataTransferTool.css";
import PMTitle from "../../themeComponents/PMTitle";
import OrbatInput from "../../Shared/Orbat/Input/OrbatInput";
import IForceTreeNode from "../../../Interfaces/IForceTreeNode";
import AdminPasswordModal from "../AdminPassword/AdminPasswordModal";
import { checkSuperSoferPassword } from "../../../services/passwordsHandler";
import usePlans from "../../CustomHooks/usePlans";
import PlansDropdown from "../../Shared/PlansDropdown/PlansDropdown";
import PMIcon from "../../themeComponents/PMIcon";
import EIconsSrc from "../../../Interfaces/EIconsSrc";

interface IProps {
  isOpen: boolean;
  onDismiss: () => void;
}

const DataTransferTool = (props: IProps) => {
  const { plansOptions, onPlanSelectedHandler, selectedPlan } = usePlans(
    undefined,
    false
  );
  const {
    plansOptions: prevPlansOptions,
    onPlanSelectedHandler: onPrevPlanSelectedHandler,
    selectedPlan: prevSelectedPlan,
  } = usePlans(undefined, false);
  const { t } = useTranslation();
  const [isInProgress, setIsInProgress] = useState<boolean>(false);
  const [isTimeError, setIsTimeError] = useState<boolean>(false);
  const [date, setDate] = useState<Date>(new Date());
  const [time, setTime] = useState<{ startTime: string; endTime: string }>();
  const [selectedForces, setSelectedForces] = useState<IForceTreeNode[]>(
    [] as IForceTreeNode[]
  );
  const [invalid, setInvalid] = useState<boolean>(false);
  const [isShowSuperSoferModal, setIsShowSuperSoferModal] =
    useState<boolean>(false);
  // Handles date changes
  const dateChangeHandler = (range: any) => {
    setDate(new Date(range));
  };

  // Handles time changes
  const onChangeTimeHandler = (time: string) => {
    const startTime = time.split(" - ")[0];
    const endTime = time.split(" - ")[1];
    const MAX_HOURS = 23;
    const MAX_MINUTES = 59;

    setIsTimeError(
      +startTime.replace(/_/g, "0").split(":")[0] > MAX_HOURS ||
        +startTime.replace(/_/g, "0").split(":")[1] > MAX_MINUTES ||
        +endTime.replace(/_/g, "0").split(":")[0] > MAX_HOURS ||
        +endTime.replace(/_/g, "0").split(":")[1] > MAX_MINUTES ||
        +startTime.replace(/_/g, "0").split(":")[0] > +endTime.split(":")[0]
    );

    setTime({ startTime: startTime, endTime: endTime });
  };

  // Handles plan switch
  const switchPlanHandler = async () => {
    if (selectedPlan) {
      setIsInProgress(true);
      try {
        let res;
        customToast.info(t("startingSwitchProcess"));
        if (date && time) {
          if (isTimeError) {
            customToast.error(t("timeError"));
          } else {
            let startDate = new Date(date);
            let endDate = new Date(date);
            //define start-date time
            startDate.setHours(+time.startTime.split(":")[0]);
            startDate.setMinutes(+time.startTime.split(":")[1]);

            //define end-date time
            endDate.setHours(+time.endTime.split(":")[0]);
            endDate.setMinutes(+time.endTime.split(":")[1]);

            //transfer end-date and start-date to utc date
            let utcStartDate = startDate.toISOString();
            let utcEndDate = endDate.toISOString();

            res = await Axios.post(`${baseUrlPMBackend}plans/switchPlan`, {
              planId: selectedPlan.id,
              startDate: utcStartDate,
              endDate: utcEndDate,
              forcesIds: selectedForces.map((force) => force.id),
            });
          }
        } else {
          res = await Axios.post(`${baseUrlPMBackend}plans/switchPlan`, {
            planId: selectedPlan.id,
            prevPlanId: prevSelectedPlan?.id,
            forcesIds: selectedForces.map((force) => force.id),
          });
        }
        if (res?.status === 200) {
          customToast.success(t("switchPlanSuccess"));
          setTime(undefined);
          onPlanSelectedHandler(undefined);
          setIsInProgress(false);
          onPrevPlanSelectedHandler(undefined);
        } else {
          customToast.error(t("switchPlanFailure"));
          setIsInProgress(false);
        }
      } catch (error) {
        console.log(error);
        customToast.error(t("switchPlanFailure"));
        setIsInProgress(false);
      }
    }
  };

  const onSendPasswordHandler = async (password: string) => {
    const isPasswordValid = await checkSuperSoferPassword(password, setInvalid);
    if (isPasswordValid) {
      setIsShowSuperSoferModal(false);
      switchPlanHandler();
    }
  };
  /** when replace data from plan to plan enter password */
  const openSuperSoferModal = () => {
    setInvalid(false);
    setIsShowSuperSoferModal(true);
  };
  return (
    <>
      <DragModal
        cssClass="trainingPlanModal"
        onDismiss={props.onDismiss}
        isOpen={props.isOpen}
      >
        <div className="loader" hidden={!isInProgress}>
          <PMLoading message={t("loading")} isOpen={isInProgress}></PMLoading>
        </div>
        <IonGrid className="planToolGrid">
          <div>
            <IonRow className="titleRowTPT">
              <PMTitle
                fontSize="xxLarge"
                fontColor="light"
                fontFamily="Regular"
              >
                {t("trainingPlanTool")}
              </PMTitle>
              <PMIcon
                size="xLarge"
                iconSrc={EIconsSrc.CLOSE}
                onClick={props.onDismiss}
              ></PMIcon>
            </IonRow>
            <IonRow className="toolInputsRow">
              <IonCol size="3" className="fieldColumnTPT">
                <OrbatInput
                  wrapperCss="trainingPlanToolOrbatInput"
                  inputValue={`${t("selectForcesOptional")}`}
                  placeholder={`${t("selectForcesOptional")}`}
                  inputWidth="long"
                  isForDropDown
                  collapseCssClass="treeCollapse scrollM"
                  selectedForces={selectedForces}
                  setSelectedForces={setSelectedForces}
                />
              </IonCol>
              <IonCol size="3" className="fieldColumnTPT">
                <DatePicker
                  mode="calendar"
                  date={date}
                  setDate={dateChangeHandler}
                  length="long"
                ></DatePicker>
              </IonCol>
              <IonCol size="3" className="fieldColumnTPT">
                <Input
                  isCalendarInput
                  placeholder={t("hoursRange")}
                  inputName="hours"
                  inputType="hours"
                  startTime={time?.startTime}
                  endTime={time?.endTime}
                  onChangeTimeHandler={onChangeTimeHandler}
                  length="long"
                  cssClass={`toolHoursInput ${isTimeError ? "timeError" : ""}`}
                ></Input>
              </IonCol>
              <IonCol size="3" className="fieldColumnTPT">
                <IonRow>
                  <PlansDropdown
                    onPlanSelectedHandler={onPlanSelectedHandler}
                    plansOptions={plansOptions}
                    selectedPlan={selectedPlan}
                  ></PlansDropdown>
                </IonRow>
              </IonCol>
            </IonRow>
          </div>
          <IonRow className="planToolButtonRow">
            <PMButton
              onClickHandler={
                prevPlansOptions ? openSuperSoferModal : switchPlanHandler
              }
              isDisabled={!selectedPlan || isInProgress || isTimeError}
              color="orange"
              size="small"
              label={{
                fontColor: "light",
                fontSize: "medium",
                fontFamily: "Regular",
              }}
            >
              {t("update")}
            </PMButton>
          </IonRow>
          <AdminPasswordModal
            onSendPasswordHandler={(password: string) =>
              onSendPasswordHandler(password)
            }
            onCancelHandler={() => setIsShowSuperSoferModal(false)}
            invalid={invalid}
            text={`${t("enterSuperSoferPassword")} ${t(
              "inOrderToSwitchPlanData"
            )}`}
            isOpen={isShowSuperSoferModal}
          ></AdminPasswordModal>
        </IonGrid>
      </DragModal>
    </>
  );
};

export default DataTransferTool;
