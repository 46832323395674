// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spikeIconEnable {
  opacity: 0.9;
}
.spikeIconDisabled {
  opacity: 0.7;
}
.spikeIcon {
  font-size: 38px !important;
}
.spikeIcon:active {
  opacity: 0.7;
}
.spikeIconSpan {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-inline-end: 20px;
  overflow-x: hidden;
}
.iconLabel {
  width: 38px;
  height: 23px;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  word-wrap: normal;
  overflow-x: hidden;
  text-align: center;
}
.desktop .spikeIcon {
  font-size: 60px !important;
}

.LauncherSelectorRow .spikeIcon {
  font-size: 70px !important;
}
.desktop .iconLabel {
  width: 100%;
  height: 30px;
}
.desktop.spikeIconSpan {
  margin-bottom: 10px;
  max-width: unset;
}
.desktop.spikeIconSpan {
  margin-bottom: 10px;
  max-width: unset;
}
.iconsRow .desktop.spikeIconSpan {
  margin-bottom: 10px;
  width: 65px;
  max-width: 65px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/SPIKEGorilla/SPIKEIcon.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;EACE,YAAY;AACd;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,YAAY;AACd;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,2BAA2B;EAC3B,uBAAuB;EACvB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;AAC5B;AACA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,WAAW;EACX,eAAe;AACjB","sourcesContent":[".spikeIconEnable {\n  opacity: 0.9;\n}\n.spikeIconDisabled {\n  opacity: 0.7;\n}\n.spikeIcon {\n  font-size: 38px !important;\n}\n.spikeIcon:active {\n  opacity: 0.7;\n}\n.spikeIconSpan {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-start;\n  margin-inline-end: 20px;\n  overflow-x: hidden;\n}\n.iconLabel {\n  width: 38px;\n  height: 23px;\n  overflow-y: hidden;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  word-wrap: normal;\n  overflow-x: hidden;\n  text-align: center;\n}\n.desktop .spikeIcon {\n  font-size: 60px !important;\n}\n\n.LauncherSelectorRow .spikeIcon {\n  font-size: 70px !important;\n}\n.desktop .iconLabel {\n  width: 100%;\n  height: 30px;\n}\n.desktop.spikeIconSpan {\n  margin-bottom: 10px;\n  max-width: unset;\n}\n.desktop.spikeIconSpan {\n  margin-bottom: 10px;\n  max-width: unset;\n}\n.iconsRow .desktop.spikeIconSpan {\n  margin-bottom: 10px;\n  width: 65px;\n  max-width: 65px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
