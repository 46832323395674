import { IonButton, IonIcon } from "@ionic/react";
import React, { FC, useRef } from "react";
import PMLabel from "./PMLabel";
import { IPMButton } from "../../Interfaces/IPMTheme";
import "./PMButton.css";
import "./PMIcon.css";
import PMIcon from "./PMIcon";

const PMButton: FC<IPMButton> = (props: IPMButton) => {
  const {
    onClickHandler,
    isDisabled,
    size,
    label,
    isHidden,
    icon,
    fill,
    color,
    routerLink,
    routerDirection,
    cssClass,
    children,
    id,
  } = props;
  const buttonRef = useRef<HTMLIonButtonElement>(null);

  return (
    <>
      <IonButton
        ref={buttonRef}
        id={id}
        className={`pmButton ${size}B ${
          fill !== "outline" ? "fullButtons" : ""
        } ${cssClass}`}
        color={color}
        fill={fill}
        onClick={(e) => {
          onClickHandler && onClickHandler(e);
          if (buttonRef.current) {
            buttonRef.current.blur();
          }
        }}
        hidden={isHidden}
        disabled={isDisabled}
        routerLink={routerLink}
        routerDirection={"none"}
        dir={props.dir}
      >
        <div
          className={`pmButtonWrap ${!icon || !label ? "noIconButton" : ""}`}
        >
          {label ? (
            <PMLabel
              fontSize={label.fontSize}
              fontColor={
                color === "dark" || color === "danger"
                  ? "light"
                  : fill === "outline"
                  ? "orange"
                  : fill !== "clear"
                  ? "dark"
                  : props.label?.fontColor
              }
              fontFamily={fill === "clear" ? "Regular" : "SemiBold"}
              textAlign={label.textAlign}
              margin={icon ? true : false}
            >
              {children}
            </PMLabel>
          ) : null}
          {icon ? (
            <PMIcon
              color={
                color === "dark" || color === "danger"
                  ? "light"
                  : fill === "outline"
                  ? "orange"
                  : fill !== "clear"
                  ? "dark"
                  : props.icon?.color
              }
              cssClass={`pmIcon ${icon.size}I ${icon.color} ${
                icon.disabledColor || icon.disabled
                  ? "disabledColorI"
                  : icon.color
              } ${icon.disabled ? "disabledI" : ""}`}
              iconSrc={icon.iconSrc}
            ></PMIcon>
          ) : null}
        </div>
      </IonButton>
    </>
  );
};
export default PMButton;
