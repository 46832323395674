import React from "react";
import "./PersonalPerformanceDetails.css";
import BarChart from "../../../../Shared/Charts/Bar/BarChart";
import IPlan from "../../../../../Interfaces/IPlan";
import IForceFromOrbat from "../../../../../Interfaces/IForceFromOrbat";
import IStationsChart from "../../../../../Interfaces/IStationsChart";
import ILabel from "../../../../../Interfaces/ILabel";
import IThreshold from "../../../../../Interfaces/IThreshold";
import Spinner from "../../../../Shared/Spinner/Spinner";

interface IPerformanceDetails {
  labels: ILabel[];
  thresholds: IThreshold[];
  plan: IPlan | undefined;
  isLoading: boolean;
  barChartData: IStationsChart[];
  force: IForceFromOrbat[];
  checkedForces: IForceFromOrbat[];
  forcesToRemove: IForceFromOrbat[];
}
const PersonalPerformanceDetails = (
  props: IPerformanceDetails
): JSX.Element => {
  return (
    <div className="personalPerformanceDetailsDiv">
      {props.isLoading ? (
        <Spinner className="competency-spinner" />
      ) : (
        <BarChart
          barChartData={props.barChartData}
          thresholds={props.thresholds}
          labels={props.labels}
          selectedForces={props.checkedForces}
          newForces={props.checkedForces}
          forcesToRemove={props.forcesToRemove}
          barsType={"trainingTypes"}
          plan={props.plan}
          isDesktop
        />
      )}
    </div>
  );
};

export default PersonalPerformanceDetails;
