// Dependencies
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { add } from "ionicons/icons";

import { IonCol, IonRow } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Input from "./TrainingPlanInput";
import { IPlansReducer } from "../../../redux/reducers/plans";
import Indicators from "./Indicators";
import {
  ADD_INDICATOR,
  savePlanValues,
  CANCEL_CHANGES,
  FETCH_INDICATORS_SUCCESS,
} from "../../../redux/actions/trainingPlanActions";
import { AppState } from "../../../redux/store/plansStore";

// Interfaces
import IPlan from "../../../Interfaces/IPlan";

// CSS
import "./TrainingPlansMainView.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
  savePlanAttributes,
  SAVE_PLAN_BUTTON_CHANGE,
  SELECTED_PLAN,
  SET_EDIT_MODE,
  UPDATE_PLAN,
} from "../../../redux/actions/plansActions";
import { ITrainingPlanReducer } from "../../../redux/reducers/trainingPlan";
import Alert from "../../Shared/Alert/Alert";
import { UserContext } from "../../../context/UserContext/userContext";
import DatePicker from "../DataPosting/DatePicker";
import { IDateRange } from "../../../Interfaces/IDatePicker";
import customToast from "../../Shared/Toast/CustomToast";
import PMButton from "../../themeComponents/PMButton";
import { IIndicator } from "../../../Interfaces/ITrainingPlan";
import SaveCancelButtons from "../../Shared/Buttons/SaveCancelButtons";
import { validateInput, units } from "../DataPosting/inputValidations";
import { Prompt } from "react-router";
import { compareToInitialObject } from "../../../services/helpers";
import { useTrainingPlanValues } from "../../CustomHooks/HooksWithRQ/useTrainingPlanValues";
import { IErrorState } from "../../CustomHooks/useCheckSumErrors";
import usePlans from "../../CustomHooks/usePlans";
import { IndicatorsContext } from "../../../context/IndicatorsContext/IndicatorsContext";

type IProps = {
  isShowUnsaveModal: boolean;
  planToChange: IPlan | undefined;
  setIsUnsavedPlan: Dispatch<SetStateAction<boolean>>;
  setIsShowUnsaveModal: Dispatch<SetStateAction<boolean>>;
  isUnsaved: () => boolean;
  isShowEditMode: boolean;
  setEnableGlobalPlansEditing: React.Dispatch<React.SetStateAction<boolean>>;
};

const TrainingPlansMainView: React.FC<IProps> = (props: IProps) => {
  const {
    isShowUnsaveModal,
    setIsUnsavedPlan,
    setIsShowUnsaveModal,
    isUnsaved,
    planToChange,
    isShowEditMode,
    setEnableGlobalPlansEditing,
  } = props;
  const { isAdmin } = useContext(UserContext);
  const { t } = useTranslation();
  const [plan, setPlan] = useState<IPlan | undefined>();
  const [initialPlan, setInitialPlan] = useState<IPlan | undefined>();
  const [isShowCancelModal, setIsShowCancelModal] = useState<boolean>(false);
  const [leavePlan, setLeavePlan] = useState<boolean>(false);
  const [isErrorExist, setIsErrorExist] = useState<IErrorState>(
    {} as IErrorState
  );
  const [localIndicators, setLocalIndicators] = useState<IIndicator[]>([]);
  const [planId, setPlanId] = useState<number>(-1);
  const { trainingPlanIndicators, refetch } = useTrainingPlanValues(
    plan?.id,
    plan?.allPlans
  );

  useEffect(() => {
    if (planId) {
      refetch();
    }
  }, [planId]);
  // Selects states
  const plansState = useSelector<AppState, IPlansReducer>(
    (state) => state.plans
  );
  const { plans } = usePlans();
  const indicatorsState = useSelector<AppState, ITrainingPlanReducer>(
    (state) => state.indicators
  );

  const editMode = useSelector<AppState, IPlansReducer>(
    (state) => state.plans
  ).editMode;

  const savePlan = useSelector<AppState, IPlansReducer>(
    (state) => state.plans
  ).savePlan;
  const { indicators: indicatorsTypes } = useContext(IndicatorsContext);

  const dispatch = useDispatch<Dispatch<any>>();
  const fixPlanDateForCompare = (
    plan: IPlan | undefined,
    initialPlan: IPlan | undefined
  ) => {
    let tempPlan = {
      ...plan!,
      startDate: new Date(plan?.startDate!).setHours(0, 0, 0, 0),
      endDate: new Date(plan?.endDate!).setHours(0, 0, 0, 0),
      lethality: Number(plan?.lethality),
      isActive: false,
    };
    let tempInitialPlan = {
      ...initialPlan!,
      startDate: new Date(initialPlan?.startDate!).setHours(0, 0, 0, 0),
      endDate: new Date(initialPlan?.endDate!).setHours(0, 0, 0, 0),
      lethality: Number(plan?.lethality),
      isActive: false,
    };
    return { initialFixedPlan: tempInitialPlan, originalFixedPlan: tempPlan };
  };

  const initIndicators = () => {
    if (trainingPlanIndicators) setLocalIndicators(trainingPlanIndicators);
    dispatch({
      type: FETCH_INDICATORS_SUCCESS,
      indicators: trainingPlanIndicators,
      initIndicators: trainingPlanIndicators,
    });
  };
  // Handles Add measurement clicks
  const addMeasurementHandler = () => {
    let tempId = Math.random() + 100;
    // If didn't reach maximum indicators number
    if (localIndicators.length < indicatorsTypes.length) {
      dispatch({
        type: ADD_INDICATOR,
        indicator: {
          indicatorId: tempId,
          name: "",
          weight: null,
          threshold: null,
          indicatorRenderId: tempId,
        },
      });
    } else {
      customToast.error(t("maximumIndicators"));
    }
  };

  // Handles changes in date
  const dateChangeHandler = (range: IDateRange) => {
    setPlan({
      ...plan!,
      startDate: range.startDate,
      endDate: range.endDate,
    });
  };
  useEffect(() => {
    setPlan((prev) =>
      plans?.find((plan) => plan.id === prev?.id)
        ? plans?.find((plan) => plan.id === prev?.id)
        : prev
    );
  }, [plans]);
  // Handles change in plan details (Mainly name/lethality)
  const onChangePlanDetailsHandler = (field: string, value: string): void => {
    let err: string = validateInput(value, units.Grade);
    setPlan({ ...plan!, [field]: value !== "" ? value : null, error: err });
  };
  const hasTrueValue = (errorState: IErrorState): boolean => {
    return Object.values(errorState).some((state) => {
      return Object.values(state).some((value) => value === true);
    });
  };
  // Handles plan save
  const onSaveChanges = () => {
    if (hasTrueValue(isErrorExist)) {
      customToast.error(t("planHasError"));
      return;
    }
    //on save the plan close the global plans editing
    setEnableGlobalPlansEditing(false);
    if (isAdmin) {
      // If there're errors, raise toast
      if (plan?.error) customToast.error(t("savePlanError"));
      else {
        dispatch({ type: SAVE_PLAN_BUTTON_CHANGE });
        dispatch({
          type: UPDATE_PLAN,
          plan: {
            id: plan?.id,
            name: plan?.name,
            startDate: plan?.startDate,
            endDate: plan?.endDate,
            lethality: plan?.lethality,
            isTest: plan?.isTest,
          },
        });
        dispatch(savePlanAttributes(plan!, initialPlan, "savePlan", t));
        dispatch(
          savePlanValues(
            indicatorsState.indicators,
            indicatorsState.initIndicators,
            plansState.selectedPlan?.id!,
            t
          )
        );
      }
    }
  };

  const onCancelChanges = () => {
    //check for changes
    const { initialFixedPlan, originalFixedPlan } = fixPlanDateForCompare(
      plan,
      initialPlan
    );
    if (
      isUnsaved() ||
      !compareToInitialObject(initialFixedPlan, originalFixedPlan)
    ) {
      setIsShowCancelModal(true);
    } else {
      handleConfirmation();
    }
  };

  const handleConfirmation = () => {
    //on cancel the plan saving close the global plans editing
    setEnableGlobalPlansEditing(false);
    setIsErrorExist({} as IErrorState);
    dispatch({ type: CANCEL_CHANGES });

    if (editMode) {
      dispatch({ type: SET_EDIT_MODE });
    } else {
      initSelectedPlan();
    }
  };

  const initSelectedPlan = () => {
    setPlan(plansState.selectedPlan);
    setInitialPlan(plansState.selectedPlan);
  };

  const handleConfirmationToLeavePlan = () => {
    dispatch({
      type: SELECTED_PLAN,
      selectedPlan: planToChange,
      editMode: isShowEditMode,
    });
  };
  useEffect(() => {
    initIndicators();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainingPlanIndicators]);
  useEffect(() => {
    if (plansState.selectedPlan) {
      setPlanId(plansState.selectedPlan!.id);
      setPlan(plansState.selectedPlan);
      setInitialPlan(plansState.selectedPlan);
      if (plansState.selectedPlan?.id !== planId) {
        setLocalIndicators([]);
        setIsErrorExist({} as IErrorState);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plansState.selectedPlan]);

  useEffect(() => {
    if (leavePlan) {
      setLeavePlan(false);
      handleConfirmationToLeavePlan();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planToChange, leavePlan]);

  useEffect(() => {
    const { initialFixedPlan, originalFixedPlan } = fixPlanDateForCompare(
      plan,
      initialPlan
    );
    setIsUnsavedPlan(
      compareToInitialObject(initialFixedPlan, originalFixedPlan)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan]);

  // Sets plan state to the selected plan

  useEffect(() => {
    setLocalIndicators(indicatorsState.indicators);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indicatorsState.indicators?.length]);

  useEffect(() => {
    setLocalIndicators(indicatorsState.initIndicators);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indicatorsState.initIndicators]);
  // Sets plans according to edit mode
  useEffect(() => {
    if (!editMode && savePlan <= 0) {
      setPlan(plansState.selectedPlan);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  return plan ? (
    <div className="planGrid">
      <Prompt when={isUnsaved()} message={t("noSavePlanChanges")} />

      <IonRow className="row1TP">
        <IonCol
          size="2.93"
          className={`${plansState.editMode ? "" : "viewMode"}`}
        >
          <Input
            inputName="name"
            placeholder={t("name")}
            inputType="text"
            inputValue={plan?.name}
            onChangeHandler={onChangePlanDetailsHandler}
            length="long"
            cssClass="tpInput"
            isDisabled={!plansState.editMode}
          ></Input>
        </IonCol>
        {!plan.isGlobal && (
          <IonCol
            size="2.58"
            className={`${plansState.editMode ? "" : "viewMode"}`}
          >
            <div>
              <DatePicker
                disabled={!plansState.editMode}
                mode="range"
                rangeDate={{
                  startDate: plan.startDate
                    ? new Date(Date.parse(plan.startDate.toString()))
                    : new Date(),
                  endDate: plan.endDate
                    ? new Date(Date.parse(plan.endDate.toString()))
                    : new Date(),
                }}
                setRangeDate={dateChangeHandler}
              />
            </div>
          </IonCol>
        )}
        <IonCol
          size="2.58"
          className={`${plansState.editMode ? "" : "viewMode"}`}
        >
          <Input
            isDisabled={!plansState.editMode}
            inputName="lethality"
            placeholder={t("lethalityThreshold")}
            inputType="number"
            inputValue={plan?.lethality}
            onChangeHandler={onChangePlanDetailsHandler}
            length="short"
            cssClass={`tpInput ${plan.error !== "" ? "tpInputError" : null}`}
          ></Input>
        </IonCol>
        <IonCol className={` addButtonTp`} hidden={!plansState.editMode}>
          {isAdmin ? (
            <div className="buttonWrap">
              <PMButton
                color="orange"
                fill="outline"
                icon={{ iconSrc: add, color: "orange", size: "large" }}
                onClickHandler={addMeasurementHandler}
                isDisabled={!plansState.editMode}
                label={{
                  fontSize: "medium",
                  fontColor: "light",
                  fontFamily: "Regular",
                }}
                cssClass="tpAddButton"
              >
                {t("addMeasurement")}
              </PMButton>
            </div>
          ) : null}
        </IonCol>
      </IonRow>
      <h1 className="lethalityTitle">{t("lethalityMeasurements")}</h1>
      <IonRow className="indicatorsRow">
        <Indicators
          indicators={localIndicators}
          setIsErrorExist={setIsErrorExist}
        ></Indicators>
      </IonRow>
      <IonRow className="row3TP">
        {plan.isEditable &&
        (plansState.editMode || (isUnsaved && isUnsaved())) ? (
          <IonRow className="buttons">
            <SaveCancelButtons
              onCancelClickHandler={onCancelChanges}
              onSaveClickHandler={onSaveChanges}
            ></SaveCancelButtons>
          </IonRow>
        ) : null}
      </IonRow>
      <Alert
        header={t("cancelChangesAlert")}
        isOpen={isShowCancelModal}
        setIsOpen={setIsShowCancelModal}
        actionOnSave={handleConfirmation}
        actionOnCancel={() => setIsShowCancelModal(false)}
      />
      <Alert
        header={t("noSavePlanChanges")}
        isOpen={isShowUnsaveModal}
        setIsOpen={setIsShowUnsaveModal}
        actionOnSave={() => setLeavePlan(true)}
        actionOnCancel={() => setIsShowUnsaveModal(false)}
      />
    </div>
  ) : null;
};

export default TrainingPlansMainView;
