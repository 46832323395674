// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-split-pane {
  --side-max-width: 0%;
}

ion-split-pane ion-router-outlet {
  margin-top: 33px;
  margin-bottom: 33px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/SideMenu/SideMenu.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":["ion-split-pane {\n  --side-max-width: 0%;\n}\n\nion-split-pane ion-router-outlet {\n  margin-top: 33px;\n  margin-bottom: 33px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
