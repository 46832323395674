import { IonCol, IonGrid, IonPage, IonRow, isPlatform } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";

import "./HasifaTKifaDesktop.css";
import PMLabel from "../../../themeComponents/PMLabel";
import { useTranslation } from "react-i18next";
import PlansDropdown from "../../../Shared/PlansDropdown/PlansDropdown";
import usePlans from "../../../CustomHooks/usePlans";
import NumberSelector from "../../../Shared/SPIKEGorilla/NumberSelector";
import { GorillaTrainingContext } from "../../../../context/GorillaTrainingContext/GorillaTrainingContext";
import SearchForceSelectDropdown from "../../../Shared/MenualStation/SearchSoldierComp/SearchForceSelectDropdown";
import {
  EGorillaForces,
  EGorillaTrainings,
  gorillaStations,
} from "../../../../Interfaces/Gorilla/GorillaTrainings";
import PMDropdown from "../../../Shared/PMDropdown/PMDropdown";
import PMButton from "../../../themeComponents/PMButton";
import { dataPostingRoutes } from "../../../CustomHooks/useDataPostingTabs";
import { EAppMode } from "../../../../Enums/EAppMode";
import LauncherSelector from "../../../Shared/SPIKEGorilla/LauncherSelector";

const HasifaTKifaDesktop: React.FC = (): JSX.Element => {
  const { t } = useTranslation();

  const {
    gorillaTrainingsForces,
    initHeatsAmount,
    setGorillaTrainingsForces,
    setHeatsAmount,
    setPlan,
    minimumHeatsAmount,
    resetForcesObject,
    selectedLuncherType,
    setSelectedLuncherType,
  } = useContext(GorillaTrainingContext);
  const [activeTraining, setActiveTraining] = useState<EGorillaTrainings>();
  const { selectedPlan, onPlanSelectedHandler, plansOptions } = usePlans();

  useEffect(() => {
    selectedPlan?.id && setPlan(selectedPlan);
  }, [selectedPlan]);
  useEffect(() => {
    resetForcesObject();
  }, [activeTraining]);
  useEffect(() => {
    resetForcesObject();
  }, []);
  return (
    <IonPage
      className={`desktop HasifaTkifaDesktopGrid ${
        !isPlatform("desktop") ? "touch" : ""
      }`}
    >
      <IonGrid className="HTContainerWrap ">
        <IonRow className="HTContainer">
          <IonCol className="HTContainerCol paddingFree">
            <IonRow>
              <IonCol>
                <IonRow>
                  <IonCol>
                    <PMLabel
                      fontFamily="Regular"
                      fontSize="xxLarge"
                      fontColor="light"
                    >
                      {`${t(EAppMode.HASIFA_TKIFA)} - ${t("creatingTraining")}`}
                    </PMLabel>
                  </IonCol>
                </IonRow>
                <IonRow className="HTTitleRow">
                  <IonCol size="4" className="HTColumn">
                    <PMLabel
                      fontFamily="Regular"
                      fontSize="large"
                      fontColor="light"
                    >
                      {t("defineTrainingDetails")}
                    </PMLabel>
                  </IonCol>
                  <IonCol size="4" className="HTColumn"></IonCol>
                  <IonCol size="4" className="HTColumn launcherSelectColumn">
                    {(activeTraining === EGorillaTrainings.FrameTraining ||
                      activeTraining === EGorillaTrainings.SingleGunner) && (
                      <PMLabel
                        fontFamily="Regular"
                        fontSize="large"
                        fontColor="light"
                      >
                        {t("launcherType")}
                      </PMLabel>
                    )}
                  </IonCol>
                </IonRow>
                <IonRow className="trainingDetailsRow">
                  <IonCol size="4" className="HTColumn">
                    <PMDropdown
                      placeholder={t("trainingType")}
                      options={gorillaStations.map(
                        (training: EGorillaTrainings) => {
                          return { value: t(training), key: training };
                        }
                      )}
                      selectedOption={
                        activeTraining
                          ? {
                              value: activeTraining,
                              key: activeTraining,
                            }
                          : undefined
                      }
                      onOptionChanges={(e) => {
                        setActiveTraining(e.key as EGorillaTrainings);
                      }}
                    />
                  </IonCol>
                  <IonCol size="4" className="HTColumn">
                    <PlansDropdown
                      onPlanSelectedHandler={onPlanSelectedHandler}
                      plansOptions={plansOptions}
                      selectedPlan={selectedPlan}
                    ></PlansDropdown>
                  </IonCol>
                  <IonCol size="4" className="HTColumn launcherSelectColumn">
                    {(activeTraining === EGorillaTrainings.FrameTraining ||
                      activeTraining === EGorillaTrainings.SingleGunner) && (
                      <LauncherSelector
                        launcherType={selectedLuncherType}
                        setSelectedLuncherType={setSelectedLuncherType}
                      />
                    )}
                  </IonCol>
                </IonRow>
                {activeTraining && (
                  <IonRow>
                    <IonCol>
                      <PMLabel
                        fontFamily="Regular"
                        fontSize="large"
                        fontColor="light"
                      >
                        {t("selectForce")}
                      </PMLabel>
                    </IonCol>
                  </IonRow>
                )}
                <IonRow>
                  {(activeTraining === EGorillaTrainings.FrameTraining ||
                    activeTraining === EGorillaTrainings.SingleGunner) && (
                    <IonCol size="4" className="HTColumn">
                      <SearchForceSelectDropdown
                        setSoldierDetails={(soldierDetails) => {
                          setGorillaTrainingsForces((prev: any) => {
                            prev = {
                              ...prev,

                              [EGorillaForces.SingleGunner]: soldierDetails,
                            };
                            return prev;
                          });
                        }}
                        soldierDetails={
                          gorillaTrainingsForces[EGorillaForces.SingleGunner]
                        }
                        forceType={EGorillaForces.SingleGunner}
                      ></SearchForceSelectDropdown>
                    </IonCol>
                  )}
                  {(activeTraining === EGorillaTrainings.FrameTraining ||
                    activeTraining === EGorillaTrainings.Observer) && (
                    <IonCol size="4" className="HTColumn">
                      <SearchForceSelectDropdown
                        setSoldierDetails={(soldierDetails) => {
                          setGorillaTrainingsForces((prev: any) => {
                            prev = {
                              ...prev,

                              [EGorillaForces.Observer]: soldierDetails,
                            };
                            return prev;
                          });
                        }}
                        soldierDetails={
                          gorillaTrainingsForces[EGorillaForces.Observer]
                        }
                        forceType={EGorillaForces.Observer}
                      ></SearchForceSelectDropdown>
                    </IonCol>
                  )}
                  {activeTraining === EGorillaTrainings.FrameTraining && (
                    <IonCol size="4" className="HTColumn">
                      <SearchForceSelectDropdown
                        setSoldierDetails={(soldierDetails) => {
                          setGorillaTrainingsForces((prev: any) => {
                            prev = {
                              ...prev,

                              [EGorillaForces.NCO]: soldierDetails,
                            };
                            return prev;
                          });
                        }}
                        soldierDetails={
                          gorillaTrainingsForces[EGorillaForces.NCO]
                        }
                        forceType={EGorillaForces.NCO}
                      ></SearchForceSelectDropdown>
                    </IonCol>
                  )}
                </IonRow>
                <IonRow className="HTTitleRow ">
                  <IonCol size="4" className="paddingFree HTColumn">
                    {(activeTraining === EGorillaTrainings.FrameTraining ||
                      activeTraining === EGorillaTrainings.SingleGunner) && (
                      <NumberSelector
                        number={initHeatsAmount}
                        setNumber={setHeatsAmount}
                        title={t("initHeatsAmount")}
                        minimumNumber={minimumHeatsAmount}
                        textSize={"large"}
                      />
                    )}
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
            <IonRow className="HTbuttonWrap">
              <PMButton
                color="orange"
                label={{
                  fontFamily: "Regular",
                  fontColor: "dark",
                  fontSize: "medium",
                }}
                size="medium"
                routerLink={`${dataPostingRoutes.hasifaTkifa}/${activeTraining}`}
                routerDirection="none"
                isDisabled={
                  !(
                    (selectedLuncherType !== undefined ||
                      activeTraining === EGorillaTrainings.Observer) &&
                    selectedPlan?.id !== undefined &&
                    ((activeTraining === EGorillaTrainings.FrameTraining &&
                      gorillaTrainingsForces[EGorillaForces.SingleGunner] !==
                        undefined &&
                      (gorillaTrainingsForces[EGorillaForces.NCO] !==
                        undefined ||
                        gorillaTrainingsForces[EGorillaForces.Observer] !==
                          undefined)) ||
                      (activeTraining === EGorillaTrainings.Observer &&
                        gorillaTrainingsForces[EGorillaForces.Observer] !==
                          undefined) ||
                      (activeTraining === EGorillaTrainings.SingleGunner &&
                        selectedLuncherType !== undefined &&
                        gorillaTrainingsForces[EGorillaForces.SingleGunner] !==
                          undefined))
                  )
                }
              >
                {t("createTraining")}
              </PMButton>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonPage>
  );
};

export default HasifaTKifaDesktop;
