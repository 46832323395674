import { Dispatch, FC, SetStateAction } from "react";
import "./NCOComponent.css";

import GeneralGrade from "../GeneralGrade";
import { INCO } from "../../../../Interfaces/Gorilla/INCO";
import Divider from "../Divider";
import EndTraining from "../EndTraining";
import PMLabel from "../../../themeComponents/PMLabel";

interface IProps {
  nco: INCO;
  setNco: Dispatch<SetStateAction<INCO>>;
  endTrainingHandler: () => void;
  isTrainingEnd: boolean;
  traineeName: string | undefined;
}
const NCOComponent: FC<IProps> = (props: IProps): JSX.Element => {
  const { nco, setNco, endTrainingHandler, isTrainingEnd } = props;
  return (
    <div className="NCOComponentWrap">
      <PMLabel
        fontColor="light"
        fontFamily="Bold"
        fontSize="large"
        cssClass="traineeNameNCO"
      >
        {props.traineeName}
      </PMLabel>
      {Object.keys(nco).map((key, index) => {
        let typedKey = key as keyof INCO;
        return (
          <div key={index}>
            <GeneralGrade
              elementName={key}
              grade={nco[typedKey]}
              setGrade={(grade) => {
                setNco((prev) => ({
                  ...prev,
                  [typedKey]: grade,
                }));
              }}
            ></GeneralGrade>
            {index + 1 < Object.keys(nco).length ? <Divider /> : null}
          </div>
        );
      })}
      <EndTraining
        endTrainingHandler={endTrainingHandler}
        isEndTrainingDisable={isTrainingEnd}
      ></EndTraining>
    </div>
  );
};

export default NCOComponent;
