import React, { ReactNode, useState } from "react";

interface IBistContext {
  authBISTUrban: () => void;
  isBISTUrbanAuthed: boolean;
}

export const BISTUrbanContext = React.createContext<IBistContext>({
  authBISTUrban: () => {},

  isBISTUrbanAuthed: false,
});

const BISTUrbanContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isBISTUrbanAuthed, setIsBistUrbanAuthed] = useState<boolean>(false);

  const authBISTUrban = () => {
    setIsBistUrbanAuthed(true);
  };

  return (
    <BISTUrbanContext.Provider
      value={{
        authBISTUrban,
        isBISTUrbanAuthed,
      }}
    >
      {children}
    </BISTUrbanContext.Provider>
  );
};

export default BISTUrbanContextProvider;
