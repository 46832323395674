import "./MicrosoftLoginButton.css";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
const MicrosoftLoginButton = ({ login }: any) => {
  return (
    <div>
      <button
        type="button"
        onClick={() => login()}
        className="microsoftLoginButton"
      >
        <p className="signInText">Sign in with Microsoft</p>
        <img
          src={EIconsSrc.MICROSOFT_LOGO}
          className="microsoftLogo"
          alt="microsoftLogo"
        />
      </button>
    </div>
  );
};

export default MicrosoftLoginButton;
