// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollContainerTL {
  flex-wrap: nowrap;
  overflow-x: auto !important;
  overflow-y: auto !important;
  height: 87%;
}

.scrollContainerTL::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.trashButtonClicked {
  --background: #ffffff14;
}

.titleTL {
  width: 100%;
  display: flex;
  --inner-padding-end: 0;
  --inner-padding-start: 0;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0;
  font-size: large;
  font-family: Regular !important;
}

.traineeItemTL {
  width: 100%;
  --background: var(--ion-color-primary);
  --border-color: var(--ion-color-dividerAndroid);
  --color: var(--ion-color-fontXLight);
  --min-height: 7vh !important;
  --padding-start: 10px;
  --padding-end: 10px;
}

.itemsContainerTL {
  padding: 0 !important;
  height: 100%;
  padding-top: 0;
  padding-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/TraineesList/TraineesListComponent.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,2BAA2B;EAC3B,2BAA2B;EAC3B,WAAW;AACb;;AAEA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,wBAAwB;EACxB,mBAAmB;EACnB,8BAA8B;EAC9B,aAAa;EACb,gBAAgB;EAChB,+BAA+B;AACjC;;AAEA;EACE,WAAW;EACX,sCAAsC;EACtC,+CAA+C;EAC/C,oCAAoC;EACpC,4BAA4B;EAC5B,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".scrollContainerTL {\n  flex-wrap: nowrap;\n  overflow-x: auto !important;\n  overflow-y: auto !important;\n  height: 87%;\n}\n\n.scrollContainerTL::-webkit-scrollbar {\n  width: 8px;\n  height: 8px;\n}\n\n.trashButtonClicked {\n  --background: #ffffff14;\n}\n\n.titleTL {\n  width: 100%;\n  display: flex;\n  --inner-padding-end: 0;\n  --inner-padding-start: 0;\n  flex-direction: row;\n  justify-content: space-between;\n  margin-top: 0;\n  font-size: large;\n  font-family: Regular !important;\n}\n\n.traineeItemTL {\n  width: 100%;\n  --background: var(--ion-color-primary);\n  --border-color: var(--ion-color-dividerAndroid);\n  --color: var(--ion-color-fontXLight);\n  --min-height: 7vh !important;\n  --padding-start: 10px;\n  --padding-end: 10px;\n}\n\n.itemsContainerTL {\n  padding: 0 !important;\n  height: 100%;\n  padding-top: 0;\n  padding-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
