import React, { ReactNode, useState } from "react";

interface IMarketingContext {
  isMarketing: boolean;
  setIsMarketing: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MarketingContext = React.createContext<IMarketingContext>(
  {} as IMarketingContext
);

const MarketingContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isMarketing, setIsMarketing] = useState<boolean>(false);

  return (
    <MarketingContext.Provider
      value={{
        isMarketing,
        setIsMarketing,
      }}
    >
      {children}
    </MarketingContext.Provider>
  );
};

export default MarketingContextProvider;
