// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.billiardComponentWrap {
  flex: 1 1;
  overflow-y: auto;
}
.traineeScoreInputRow {
  margin-top: 24px;
  margin-bottom: 0px;
  min-width: 250px;
}
.desktopweb .billiardTitle {
  font-size: 60px !important;
}
.billiardTitle {
  font-size: 32px !important;
}
.desktopweb .traineeScoreInputRow {
  margin-top: 0;
  max-width: 350px !important;
  padding: 0;
  margin-inline-end: 24px;
}
.billiardRow {
  width: 100%;
  height: max-content;
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;
}
.billiardCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  justify-self: center;
}
.desktopweb .billiardCol {
  width: 80%;
  overflow-x: auto;
}
.billiardLoserRow {
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 24px;
  margin-bottom: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Mobile/Billiard/BilliardComponent.css"],"names":[],"mappings":"AAAA;EACE,SAAO;EACP,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,aAAa;EACb,2BAA2B;EAC3B,UAAU;EACV,uBAAuB;AACzB;AACA;EACE,WAAW;EACX,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,UAAU;AACZ;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,UAAU;EACV,oBAAoB;AACtB;AACA;EACE,UAAU;EACV,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,2BAA2B;EAC3B,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".billiardComponentWrap {\n  flex: 1;\n  overflow-y: auto;\n}\n.traineeScoreInputRow {\n  margin-top: 24px;\n  margin-bottom: 0px;\n  min-width: 250px;\n}\n.desktopweb .billiardTitle {\n  font-size: 60px !important;\n}\n.billiardTitle {\n  font-size: 32px !important;\n}\n.desktopweb .traineeScoreInputRow {\n  margin-top: 0;\n  max-width: 350px !important;\n  padding: 0;\n  margin-inline-end: 24px;\n}\n.billiardRow {\n  width: 100%;\n  height: max-content;\n  margin-bottom: 24px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  padding: 0;\n}\n.billiardCol {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 0;\n  justify-self: center;\n}\n.desktopweb .billiardCol {\n  width: 80%;\n  overflow-x: auto;\n}\n.billiardLoserRow {\n  width: 100%;\n  justify-content: flex-start;\n  align-items: flex-start;\n  margin-top: 24px;\n  margin-bottom: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
