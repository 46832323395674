import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { IonCol, IonModal, IonRow } from "@ionic/react";
import "./HeatsMenu.css";
import { IHeatTemplate } from "../../../Interfaces/Gorilla/IHeatTemplate";
import NotesAndEndTraining from "./NotesAndEndTraining";
import Divider from "./Divider";
import HeatsMenuDesktop from "./HeatsMenuDesktop";
import { INote } from "../../../Interfaces/Gorilla/GorillaTrainings";
interface IProps {
  heatsArray: IHeatTemplate[];
  activeHeatId: number;
  setActiveHeat: (heatIndex: number) => void;
  resetHeat: (heatIndex: number) => void;
  mainNotes: INote[];
  addNote: (note: string) => void;
  deleteNote: (index: number) => void;
  editNote: (index: number, note: string) => void;
  endTrainingHandler: () => void;
  setActiveHeatId: Dispatch<SetStateAction<number>>;
  setIsModalOpen: (state: boolean) => void;
  isModalOpen: boolean;
  isNotModal?: boolean;
  isTrainingEnd: boolean;
  deleteHeat: (heatId: number) => void;
  addHeat: () => void;
}
const HeatsMenu: FC<IProps> = (props: IProps) => {
  const {
    heatsArray,
    activeHeatId,
    setActiveHeat,
    resetHeat,
    addHeat,
    deleteHeat,
    mainNotes,
    endTrainingHandler,
    isModalOpen,
    setIsModalOpen,
    setActiveHeatId,
    addNote,
    deleteNote,
    editNote,
  } = props;
  const [isCommentsModalOpen, setIsNotesModalOpen] = useState<boolean>(false);

  const modal = useRef<HTMLIonModalElement>(null);
  useEffect(() => {
    if (!props.isModalOpen) modal.current?.dismiss();
  }, [props.isModalOpen]);
  const HeatsComponent = () => (
    <IonCol className={"heatMenuContent"}>
      <IonRow className={`notedWrapRow heatsWrap `}>
        <HeatsMenuDesktop
          activeHeatId={activeHeatId}
          heatsArray={heatsArray}
          resetHeat={resetHeat}
          setActiveHeat={setActiveHeat}
          setActiveHeatId={setActiveHeatId}
          isDisableAddHeats={props.isTrainingEnd}
          addHeat={addHeat}
          deleteHeat={deleteHeat}
        />
      </IonRow>
      <Divider />
      <IonRow className="notedWrapRow">
        <NotesAndEndTraining
          endTrainingHandler={() => {
            setIsModalOpen(false);
            endTrainingHandler();
          }}
          mainNotes={mainNotes}
          isNotesModalOpen={isCommentsModalOpen}
          isEndTrainingDisable={
            !heatsArray.find((heat) => heat.isCompleted) || props.isTrainingEnd
          }
          addNote={addNote}
          deleteNote={deleteNote}
          editNote={editNote}
          setIsNotesModalOpen={setIsNotesModalOpen}
          trainingName="singleGunner"
          isTrainingEnd={props.isTrainingEnd}
        ></NotesAndEndTraining>
      </IonRow>
    </IonCol>
  );
  return props.isNotModal ? (
    HeatsComponent()
  ) : (
    <IonModal
      className="gorillaSPIKEMenu"
      isOpen={isModalOpen}
      initialBreakpoint={0.9}
      breakpoints={[0, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]}
      ref={modal}
      onDidDismiss={() => {
        setIsModalOpen(false);
      }}
    >
      {HeatsComponent()}
    </IonModal>
  );
};

export default HeatsMenu;
