const colors = [
  "#785EC3",
  "#454EA0",
  "#659CB4",
  "#8FEFE4",
  "#5F7E0C",
  "#BC5151",
];
const hashtagsColors = ["#F7517F", "#4346D7", "#BB86FC", "#FFD53F", "#2FB7FD"];
export { colors, hashtagsColors };
