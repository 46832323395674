import { FC } from "react";
import { IonCol, IonRow } from "@ionic/react";
import "./GeneralGrade.css";
import PMLabel from "../../themeComponents/PMLabel";
import { useTranslation } from "react-i18next";

interface IProps {
  setGrade: (grade: number | undefined) => void;
  elementName: string;
  grade: number | undefined;
  isDesktop?: boolean;
}

const GeneralGrade: FC<IProps> = (props: IProps) => {
  const { grade, setGrade, elementName } = props;
  const { t } = useTranslation();
  return (
    <div className={`generalGradeDiv ${props.isDesktop ? "desktop" : ""}`}>
      <IonRow className="generalGradeTitleRow">
        <PMLabel fontFamily="Regular" fontSize="medium" fontColor="light">
          {t(elementName)}
        </PMLabel>
      </IonRow>
      <IonRow className="generalGradeRow">
        {Array(10)
          .fill(1)
          .map((val, index) => (
            <IonCol
              size={props.isDesktop ? "2.1" : undefined}
              className={`gradeElement ${
                grade === index + 1 ? `selected${index + 1}` : ""
              }`}
              key={index}
              onClick={() => {
                setGrade(grade === index + 1 ? undefined : index + 1);
              }}
            >
              <PMLabel
                fontFamily="SemiBold"
                fontSize="large"
                fontColor={grade === index + 1 ? "dark" : "light"}
              >
                {index + 1}
              </PMLabel>
            </IonCol>
          ))}
      </IonRow>
    </div>
  );
};

export default GeneralGrade;
