// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gradesRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.gradesRow .gradeButton {
  flex: 1 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.6;
  border-radius: 2px;
}
.gradeButton {
  margin-inline-end: 2px;
  margin-inline-start: 2px;
}
.gradeButton.failed {
  background-color: #f23e4a;
}
.gradeButton.average {
  background-color: #ff8641;
}
.gradeButton.good {
  background-color: #ffc825;
}
.gradeButton.excellent {
  background-color: #aed65b;
}
.gradeButton.ok {
  background-color: #87d275;
}

.selectedGrade {
  opacity: 1 !important;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.8), 0 0 15px rgba(0, 0, 0, 0.8),
    0 0 15px rgba(0, 0, 0, 0.8), 0 0 15px rgba(0, 0, 0, 0.8);
}
.mobileweb .selectedGrade {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.8), 0 0 1px rgba(0, 0, 0, 0.8),
    0 0 1px rgba(0, 0, 0, 0.8), 0 0 1px rgba(0, 0, 0, 0.8);
}
.desktopweb .gradeButton:hover {
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/MenualStation/InstructorFeedback/InstructorFeedbackGrade.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,YAAY;AACd;AACA;EACE,SAAO;EACP,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,sBAAsB;EACtB,wBAAwB;AAC1B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;EACrB;4DAC0D;AAC5D;AACA;EACE;0DACwD;AAC1D;AACA;EACE,UAAU;AACZ","sourcesContent":[".gradesRow {\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  height: 100%;\n}\n.gradesRow .gradeButton {\n  flex: 1;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  opacity: 0.6;\n  border-radius: 2px;\n}\n.gradeButton {\n  margin-inline-end: 2px;\n  margin-inline-start: 2px;\n}\n.gradeButton.failed {\n  background-color: #f23e4a;\n}\n.gradeButton.average {\n  background-color: #ff8641;\n}\n.gradeButton.good {\n  background-color: #ffc825;\n}\n.gradeButton.excellent {\n  background-color: #aed65b;\n}\n.gradeButton.ok {\n  background-color: #87d275;\n}\n\n.selectedGrade {\n  opacity: 1 !important;\n  box-shadow: 0 0 15px rgba(0, 0, 0, 0.8), 0 0 15px rgba(0, 0, 0, 0.8),\n    0 0 15px rgba(0, 0, 0, 0.8), 0 0 15px rgba(0, 0, 0, 0.8);\n}\n.mobileweb .selectedGrade {\n  box-shadow: 0 0 1px rgba(0, 0, 0, 0.8), 0 0 1px rgba(0, 0, 0, 0.8),\n    0 0 1px rgba(0, 0, 0, 0.8), 0 0 1px rgba(0, 0, 0, 0.8);\n}\n.desktopweb .gradeButton:hover {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
