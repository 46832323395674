import React, { ReactNode, useState } from "react";

interface IAppContext {
  setIsAsDesktop: React.Dispatch<React.SetStateAction<boolean>>;
  isAsDesktop: boolean;
}

export const AppContext = React.createContext<IAppContext>({
  isAsDesktop: true,
  setIsAsDesktop: () => {},
});

const AppProvider: React.FC<{ children: ReactNode }> = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isAsDesktop, setIsAsDesktop] = useState<boolean>(true);

  return (
    <AppContext.Provider
      value={{
        isAsDesktop,
        setIsAsDesktop,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
