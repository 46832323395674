import ISimpleTrainee from "../ISimpleTrainee";

enum EGorillaTrainings {
  SingleGunner = "Single_Gunner",
  Observer = "Observer",
  FrameTraining = "Frame_Training",
}
enum EGorillaForces {
  SingleGunner = "Single_Gunner",
  Observer = "Observer",
  NCO = "NCO",
}
enum EGorillaIndicators {
  SingleGunner = 9,
  Observer = 10,
  NCO = 11,
}
export const gorillaStations = [
  EGorillaTrainings.FrameTraining,
  EGorillaTrainings.SingleGunner,
  EGorillaTrainings.Observer,
];
export const gorillaTrainingTypes: EGorillaForces[] = [
  EGorillaForces.SingleGunner,
  EGorillaForces.Observer,
  EGorillaForces.NCO,
];

export interface IGorillaTrainingsForces {
  [EGorillaForces.SingleGunner]: ISimpleTrainee | undefined;
  [EGorillaForces.Observer]: ISimpleTrainee | undefined;
  [EGorillaForces.NCO]: ISimpleTrainee | undefined;
}

export interface INote {
  note: string;
  isEditable: boolean;
  noteId: number;
  heatId?: number;
}
export { EGorillaTrainings, EGorillaForces, EGorillaIndicators };
