import { Dispatch, FC, SetStateAction } from "react";
import "./NumberSelector.css";
import PMLabel from "../../themeComponents/PMLabel";
import PMIcon from "../../themeComponents/PMIcon";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import { IonCol, IonRow } from "@ionic/react";

interface IProps {
  setNumber: Dispatch<SetStateAction<number>>;
  number: number;
  title?: string;
  maximumNumber?: number;
  minimumNumber?: number;
  textSize?: "small" | "medium" | "large";
}

const NumberSelector: FC<IProps> = (props: IProps) => {
  const { number, setNumber, title, maximumNumber, minimumNumber } = props;

  return (
    <div className="numberSelectorRow">
      <IonRow className="numberSelectorCol">
        {title && (
          <IonCol>
            <PMLabel
              textAlign="center"
              fontFamily="Regular"
              fontSize={props.textSize ? props.textSize : "medium"}
              fontColor="light"
            >
              {title}
            </PMLabel>
          </IonCol>
        )}
      </IonRow>
      <IonRow>
        <IonCol>
          <span className="numberSelector">
            <PMIcon
              disabled={!!maximumNumber && number >= maximumNumber}
              iconSrc={EIconsSrc.PLUS}
              size="large"
              color="xLight"
              onClick={() => {
                if (!maximumNumber || number <= maximumNumber)
                  setNumber((prev) => prev + 1);
              }}
            ></PMIcon>
            <PMLabel
              textAlign="center"
              fontFamily="Regular"
              fontSize="xLarge"
              fontColor="light"
            >
              {number}
            </PMLabel>
            <PMIcon
              iconSrc={EIconsSrc.MINUS}
              color="xLight"
              size="large"
              disabled={!number || (!!minimumNumber && number <= minimumNumber)}
              onClick={() => {
                if (number && (!minimumNumber || number >= minimumNumber))
                  setNumber((prev) => prev - 1);
              }}
            ></PMIcon>
          </span>{" "}
        </IonCol>
      </IonRow>
    </div>
  );
};

export default NumberSelector;
