// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-list {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.title {
  font-family: "Regular" !important;
  font-size: large;
  color: var(--ion-color-fontXLight);
}

.mobile-list-wrapper {
  height: 84%;
}

.list-item {
  width: 100%;
  --background: var(--ion-color-header);
  --padding-start: 10px;
  --padding-end: 5px;
  --inner-padding-end: 5px;
  --color: var(--ion-color-fontXLight);
  display: flex;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Mobile/List/List.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,iCAAiC;EACjC,gBAAgB;EAChB,kCAAkC;AACpC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,qCAAqC;EACrC,qBAAqB;EACrB,kBAAkB;EAClB,wBAAwB;EACxB,oCAAoC;EACpC,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".mobile-list {\n  width: 100%;\n  height: 100%;\n  overflow-y: auto;\n}\n\n.title {\n  font-family: \"Regular\" !important;\n  font-size: large;\n  color: var(--ion-color-fontXLight);\n}\n\n.mobile-list-wrapper {\n  height: 84%;\n}\n\n.list-item {\n  width: 100%;\n  --background: var(--ion-color-header);\n  --padding-start: 10px;\n  --padding-end: 5px;\n  --inner-padding-end: 5px;\n  --color: var(--ion-color-fontXLight);\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
