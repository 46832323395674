import { FC, useEffect, useRef, useState } from "react";
import "./MainNotesTabDesktop.css";
import { IonRow, IonCol, IonGrid } from "@ionic/react";
import EIconsSrc from "../../../../Interfaces/EIconsSrc";
import PMIcon from "../../../themeComponents/PMIcon";
import PMLabel from "../../../themeComponents/PMLabel";
import SaveCancelButtons from "../../Buttons/SaveCancelButtons";
import { useTranslation } from "react-i18next";
import PMButton from "../../../themeComponents/PMButton";
import { INote } from "../../../../Interfaces/Gorilla/GorillaTrainings";

interface IProps {
  notes: INote[];
  addNote: (note: string) => void;
  deleteNote: (index: number) => void;
  editNote: (index: number, note: string) => void;
}

const MainNotesTabDesktop: FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation();
  const { notes, addNote, deleteNote, editNote } = props;
  const [noteToEdit, setNoteToEdit] = useState<INote>();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const maxNotes = 5;
  const [isAddNote, setIsAddNote] = useState<boolean>(true);
  const [editingNote, setEditingNote] = useState<string>("");
  useEffect(() => {
    if (!notes.length) {
      setIsAddNote(true);
    }
  }, [notes]);
  useEffect(() => {
    if (isAddNote || noteToEdit) {
      const timer = setTimeout(() => {
        if (textAreaRef.current) {
          textAreaRef.current.focus();
        }
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [isAddNote, noteToEdit]);

  const maxLetters = 500;
  useEffect(() => {
    setEditingNote(noteToEdit?.note || "");
  }, [noteToEdit]);
  useEffect(() => {
    setEditingNote("");
  }, [isAddNote]);

  return (
    <IonGrid className="mainNotesGrid scrollM">
      <div className="commentsWrap">
        <IonRow className="commentsModalTitleDesktop">
          <PMButton
            icon={{ iconSrc: EIconsSrc.ADD, color: "dark", size: "large" }}
            color="orange"
            onClickHandler={() => {
              setIsAddNote(true);
            }}
            isDisabled={
              !(maxNotes > notes.length && !(!!noteToEdit || isAddNote))
            }
          ></PMButton>
        </IonRow>
        {isAddNote || editingNote ? (
          <div className="mainNotesDesktopWrap">
            <IonRow>
              {isAddNote ? (
                <PMLabel
                  fontFamily="SemiBold"
                  fontSize="large"
                  fontColor="light"
                >
                  {t("addNewComment")}
                </PMLabel>
              ) : null}
            </IonRow>
            <IonRow>
              {!!noteToEdit || isAddNote ? (
                <>
                  <IonRow className="commentsRow">
                    <textarea
                      ref={textAreaRef}
                      className="commentsInput scrollS"
                      value={editingNote}
                      onChange={(event) => {
                        setEditingNote(event.target.value);
                      }}
                      maxLength={maxLetters}
                    ></textarea>
                  </IonRow>
                  <IonRow className="commentEditorModalTitle">
                    <PMLabel
                      fontFamily="ExtraLight"
                      fontSize="small"
                      fontColor="light"
                      textAlign="center"
                    >
                      {`${editingNote.length}/${maxLetters}`}
                    </PMLabel>
                  </IonRow>
                </>
              ) : null}
            </IonRow>
            {!!noteToEdit || isAddNote ? (
              <IonRow className="acceptDeclineButtonsRow">
                <SaveCancelButtons
                  disabled={!editingNote.length}
                  onCancelClickHandler={() => {
                    if (notes.length) setIsAddNote(false);
                    else setEditingNote("");
                    setNoteToEdit(undefined);
                  }}
                  onSaveClickHandler={() => {
                    if (isAddNote) {
                      addNote(editingNote);
                      setIsAddNote(false);
                    } else {
                      if (noteToEdit) editNote(noteToEdit?.noteId, editingNote);

                      setNoteToEdit(undefined);
                    }
                  }}
                  saveText={t("save")}
                ></SaveCancelButtons>
              </IonRow>
            ) : null}
          </div>
        ) : null}
        <IonRow className="commentsRow">
          <IonCol className="commentsCol">
            {notes.length || noteToEdit || isAddNote
              ? notes
                  .filter((note) => note.noteId !== noteToEdit?.noteId)
                  .map((note, index) => (
                    <IonRow
                      className="commentRow mainNotesDesktopWrap"
                      key={index}
                    >
                      <IonCol size="0.6" className="commentIndexCol">
                        <PMLabel
                          fontFamily="SemiBold"
                          fontSize="medium"
                          fontColor="light"
                        >
                          {`${index + 1}.`}
                        </PMLabel>
                      </IonCol>
                      <IonCol className="commentCol mainCommentCol">
                        <PMLabel
                          fontFamily="Light"
                          fontSize="medium"
                          fontColor="light"
                          cssClass="commentText"
                        >
                          {`${
                            note.heatId ? `${t("heat")} ${note.heatId} - ` : ""
                          }${note.note}`}
                        </PMLabel>
                      </IonCol>
                      <IonCol size="0.5" className="iconCol">
                        <PMIcon
                          iconSrc={EIconsSrc.PENCIL}
                          size="large"
                          color="light"
                          onClick={() => setNoteToEdit(note)}
                          disabled={!note.isEditable}
                        ></PMIcon>
                      </IonCol>
                      <IonCol size="0.5" className="iconCol">
                        <PMIcon
                          iconSrc={EIconsSrc.TRASH_ICON}
                          size="xLarge"
                          color="light"
                          onClick={() => deleteNote(note.noteId)}
                          disabled={!note.isEditable}
                        ></PMIcon>
                      </IonCol>
                    </IonRow>
                  ))
              : null}
          </IonCol>
        </IonRow>
      </div>
    </IonGrid>
  );
};

export default MainNotesTabDesktop;
