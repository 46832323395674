import { IonCol, IonItem } from "@ionic/react";
import React from "react";
import "./BilliardToggle.css";
import { useTranslation } from "react-i18next";
import PMLabel from "../../themeComponents/PMLabel";
import EIconsSrc from "../../../Interfaces/EIconsSrc";

interface IBilliardToggle<T> {
  firstOptionNames: string[];
  firstOptionValue: T;
  secondOptionNames: string[];
  secondOptionValue: T;
  state: T | undefined;
  setState: (state: T | undefined) => void;
  isDesktop?: boolean;
}
interface ItemProps {
  isSelected: boolean;
  onClickHandler: () => void;
  options: string[];
  backgroundPicture?: EIconsSrc;
}
const Item = (props: ItemProps) => {
  const { t } = useTranslation();
  return (
    <div
      style={{ backgroundImage: `url(${props.backgroundPicture})` }}
      onClick={props.onClickHandler}
      className={props.isSelected ? "toggleItem selectedToggle" : "toggleItem"}
    >
      {props.options.map((element, index) => (
        <React.Fragment key={index}>
          <IonItem lines="none" className="toggleElement">
            <PMLabel fontSize="xxLarge" cssClass="toggleText">
              {t(element)}
            </PMLabel>
          </IonItem>
          {index < props.options.length - 1 && (
            <IonItem lines="none" className="toggleAndElement">
              <PMLabel fontSize="xxLarge" cssClass="toggleText">
                &
              </PMLabel>
            </IonItem>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

const BilliardToggle = <T extends boolean | string | number>(
  props: IBilliardToggle<T>
) => {
  const { t } = useTranslation();
  return (
    <div className={`billiardToggleRow `} dir={"rtl"}>
      <IonCol className="toggleCol startCol">
        <Item
          onClickHandler={() => props.setState(props.firstOptionValue)}
          isSelected={props.state === props.firstOptionValue}
          options={props.firstOptionNames}
          backgroundPicture={
            props.isDesktop ? EIconsSrc.GREEN_BALL : EIconsSrc.GREEN_BALL_MOBILE
          }
        />
      </IonCol>
      <IonCol className="toggleCol">
        <PMLabel cssClass="toggleVSText" fontFamily="Light" fontSize="xxLarge">
          {t("vs")}
        </PMLabel>
      </IonCol>
      <IonCol className="toggleCol endCol">
        <Item
          onClickHandler={() => props.setState(props.secondOptionValue)}
          isSelected={props.state === props.secondOptionValue}
          options={props.secondOptionNames}
          backgroundPicture={
            props.isDesktop ? EIconsSrc.BLUE_BALL : EIconsSrc.BLUE_BALL_MOBILE
          }
        />
      </IonCol>
    </div>
  );
};

export default BilliardToggle;
