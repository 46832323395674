import { FC } from "react";
import { IonCol, IonRow } from "@ionic/react";
import "./ElementRow.css";
import SPIKEIcon from "./SPIKEIcon";
import PMLabel from "../../themeComponents/PMLabel";
import { ITargetsPropertiesOptions } from "../../../Interfaces/Gorilla/ITargetProperties";
import ESPIKEIcons from "../../../Interfaces/ESPIOKEIcons";
import { useTranslation } from "react-i18next";

interface IProps {
  iconsArray: { icon: ESPIKEIcons; name: ITargetsPropertiesOptions }[];
  elementName: string;
  result: ITargetsPropertiesOptions | undefined;
  onSelect: (name: ITargetsPropertiesOptions) => void;
  isMobile?: boolean;
}

const ElementRow: FC<IProps> = (props: IProps) => {
  const { iconsArray, elementName, result, onSelect } = props;
  const { t } = useTranslation();
  return (
    <IonRow className="spikeElementRow">
      <IonCol size="2" className="elementColS nameColElement">
        <PMLabel
          fontColor="light"
          fontFamily="Light"
          fontSize="medium"
          cssClass="elementSpikeName"
        >
          {t(elementName)}
        </PMLabel>
      </IonCol>

      <IonCol className="elementColS">
        <IonRow className="iconsRow">
          {iconsArray.map((icon, index) => (
            <SPIKEIcon
              src={icon.icon}
              key={index}
              onClick={() => onSelect(icon.name)}
              state={
                !result
                  ? "enable"
                  : result === icon.name
                  ? "selected"
                  : "disable"
              }
              name={icon.name}
              isMobile={props.isMobile}
            />
          ))}
        </IonRow>
      </IonCol>
    </IonRow>
  );
};

export default ElementRow;
