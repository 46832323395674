import { useTranslation } from "react-i18next";
import React, { Dispatch, FC, SetStateAction } from "react";
import { IonButton, IonText } from "@ionic/react";
import Alert from "../../../../../Shared/Alert/Alert";
import "./ResultsButtons.css";
import PMButton from "../../../../../themeComponents/PMButton";

type IProps = {
  onUpdateData: () => void;
  goToNextHeat: (unsavedResults: boolean | undefined) => void;
  openAlert: boolean;
  setOpenAlert: Dispatch<SetStateAction<boolean>>;
  isLastHeat: boolean;
  unsavedResults: boolean | undefined;
};

const ResultsButtons: FC<IProps> = (props: IProps): JSX.Element => {
  const {
    onUpdateData,
    goToNextHeat,
    openAlert,
    setOpenAlert,
    isLastHeat,
    unsavedResults,
  } = props;
  const { t } = useTranslation();

  const onCancal = () => setOpenAlert(false);

  const onSave = async () => {
    if (unsavedResults) {
      onUpdateData();
      goToNextHeat(false);
    }
  };

  return (
    <div className="results-buttons-wrapper">
      <PMButton
        isDisabled={!unsavedResults}
        onClickHandler={onUpdateData}
        fill="solid"
        color="orange"
        size="large"
        label={{
          fontColor: "light",
          fontFamily: "Regular",
          fontSize: "medium",
        }}
      >
        {t("saveResults")}
      </PMButton>

      <PMButton
        isDisabled={!unsavedResults}
        onClickHandler={() => goToNextHeat(unsavedResults)}
        fill="solid"
        color="orange"
        size="large"
        label={{
          fontColor: "light",
          fontFamily: "Regular",
          fontSize: "medium",
        }}
      >
        {t("passToNextHeat")}
      </PMButton>

      <Alert
        isOpen={openAlert && (isLastHeat || unsavedResults!)}
        setIsOpen={setOpenAlert}
        header={
          unsavedResults
            ? `${t("noSaveResults")}`
            : isLastHeat
            ? `${t("reachedLastHeat")}`
            : ""
        }
        actionOnSave={onSave}
        actionOnCancel={onCancal}
        actionText={unsavedResults ? "save" : "OK"}
      />
    </div>
  );
};

export default ResultsButtons;
