import { useContext, useEffect, useState } from "react";
import { ITargetsPropertiesResults } from "../../Interfaces/Gorilla/ITargetProperties";

import { ISystemEncryption } from "../../Interfaces/Gorilla/ISystemEncryption";
import { IShootingExecution } from "../../Interfaces/Gorilla/IShootingExecution";
import {
  ESingleGunnerSteps,
  IHeatTemplate,
  ISingleGunnerStep,
} from "../../Interfaces/Gorilla/IHeatTemplate";
import { GorillaTrainingContext } from "../../context/GorillaTrainingContext/GorillaTrainingContext";
import { mobileRoutes } from "../../services/routeRoles";
import useNavigation from "./useNavigation";
import { sendDrillToBBackend } from "../Desktop/DataPosting/dataPostingHelpers";
import EEventType from "../../Interfaces/EEventType";
import {
  EGorillaForces,
  INote,
} from "../../Interfaces/Gorilla/GorillaTrainings";
import GorillaConsts, {
  initHeatsStepTemplate,
  initialHeatTemplate,
  initialTargetsPropertiesResultsTemplate,
} from "./GorillaConsts";
import { dataPostingRoutes } from "./useDataPostingTabs";
import customToast from "../Shared/Toast/CustomToast";
import { useTranslation } from "react-i18next";

const SingleGunnerHeatsHook = (
  isDesktop?: boolean,
  nextTraining?: () => void
) => {
  const { t } = useTranslation();
  const { replace } = useNavigation();
  const [singleGunnerSteps, setSingleGunnerSteps] = useState<
    Record<ESingleGunnerSteps, ISingleGunnerStep>
  >(initHeatsStepTemplate);
  const [isTrainingEnd, setIsTrainingEnd] = useState<boolean>(false);

  const { gorillaTrainingsForces, initHeatsAmount, plan, selectedLuncherType } =
    useContext(GorillaTrainingContext);
  const [heatsArray, setHeatsArray] = useState<IHeatTemplate[]>([]);
  const {
    initialMalfunctionOperationTemplate,
    initialShootingExecutionTemplate,
    initialSystemEncryptionTemplate,
  } = GorillaConsts(plan?.id);
  const [activeHeatId, setActiveHeatId] = useState<number>(0);
  const [currentStepType, setCurrentStepType] = useState<ESingleGunnerSteps>(
    ESingleGunnerSteps.SystemEncryption
  );
  const [targetsPropertiesResults, setTargetsPropertiesResults] =
    useState<ITargetsPropertiesResults>(
      initialTargetsPropertiesResultsTemplate
    );
  const [systemEncryption, setSystemEncryption] = useState<ISystemEncryption>(
    initialSystemEncryptionTemplate
  );
  const [malfunctionsOperation, setMalfunctionOperation] =
    useState<IMalfunctionOperationOptions>(initialMalfunctionOperationTemplate);
  const [shootingExecution, setShootingExecution] =
    useState<IShootingExecution>(initialShootingExecutionTemplate);
  const [mainNotes, setMainNotes] = useState<Record<number, INote[]>>({});
  const [notesToDisplay, setNotesToDisplay] = useState<INote[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const setHeatsAmount = (amount: number) => {
    let heatsArray = new Array(amount)
      .fill(initialHeatTemplate)
      .map((heat, index) => ({
        ...heat,
        heatId: index + 1,
      }));

    setHeatsArray(heatsArray);
    setActiveHeatId(heatsArray[0].heatId);
  };

  const saveHeat = () => {
    setHeatsArray((prev) => {
      return [...prev].map((heat) => {
        if (heat.heatId === activeHeatId)
          return {
            ...heat,
            malfunctionsOperation: malfunctionsOperation,
            shootingExecution: shootingExecution,
            systemEncryption: systemEncryption,
            targetsPropertiesResults: targetsPropertiesResults,
            stepsState: singleGunnerSteps,
            isCompleted: !Object.values(singleGunnerSteps).some(
              (step) => !step.isCompleted && step.isMustCompleted
            ),
          };
        else return heat;
      });
    });
  };
  const saveHeatAndMoveToNext = () => {
    setIsModalOpen(true);
    saveHeat();
    let heatIndex = heatsArray.findIndex(
      (heat) => heat.heatId === activeHeatId
    );
    if (heatIndex + 1 < heatsArray.length)
      setActiveHeatId(heatsArray[heatIndex + 1].heatId);
    else {
      nextTraining && nextTraining();
    }
  };
  const setActiveHeat = (heatIndex: number) => {
    setHeatsArray((prev) => {
      return [...prev].map((heat) => {
        if (heat.heatId === activeHeatId)
          return {
            ...heat,
            malfunctionsOperation: malfunctionsOperation,
            shootingExecution: shootingExecution,
            systemEncryption: systemEncryption,
            targetsPropertiesResults: targetsPropertiesResults,
            stepsState: singleGunnerSteps,
            isCompleted: !Object.values(singleGunnerSteps).some(
              (step) => !step.isCompleted && step.isMustCompleted
            ),
          };
        else return heat;
      });
    });
    setActiveHeatId(heatIndex);
  };

  const resetHeat = (heatId: number) => {
    setHeatsArray((prev) => {
      return [...prev].map((heat) => {
        if (heat.heatId === heatId)
          return {
            ...heat,
            malfunctionsOperation: undefined,
            shootingExecution: undefined,
            systemEncryption: undefined,
            targetsPropertiesResults: undefined,
            isCompleted: false,
            isTrainingEnd: false,
            stepsState: initHeatsStepTemplate,
          };
        else return heat;
      });
    });

    if (heatId === activeHeatId) initSingleGunnerObjects();
    setMainNotes((prev) => {
      const updatedMainNotes = { ...prev };
      delete updatedMainNotes[heatId];
      return updatedMainNotes;
    });
  };
  const addHeat = () => {
    setHeatsArray((prev) => [
      ...prev,
      {
        ...initialHeatTemplate,
        heatId: Number(prev[prev?.length - 1]?.heatId) + 1 || 1,
      },
    ]);
  };
  const deleteHeat = (heatId: number) => {
    setHeatsArray((prev) => {
      return [...prev].filter((heat) => heat.heatId !== heatId);
    });
    //delete the heat notes
    setMainNotes((prev) => {
      const updatedMainNotes = { ...prev };
      delete updatedMainNotes[heatId];
      return updatedMainNotes;
    });
  };
  const initSingleGunnerObjects = () => {
    setMalfunctionOperation(initialMalfunctionOperationTemplate);
    setShootingExecution(initialShootingExecutionTemplate);
    setSystemEncryption(initialSystemEncryptionTemplate);
    setTargetsPropertiesResults(initialTargetsPropertiesResultsTemplate);
    setSingleGunnerSteps(initHeatsStepTemplate);
  };
  const isShootingExecutionComplete = (): boolean => {
    let isCompleted = true;
    if (
      systemEncryption.OperatingLauncherHeatGrade === undefined ||
      Object.values(shootingExecution).includes(undefined) ||
      Object.values(shootingExecution.OperatingTime).includes(undefined) ||
      Object.values(malfunctionsOperation).find(
        (element) =>
          element.isSelected && Object.values(element).includes(undefined)
      )
    )
      isCompleted = false;
    return isCompleted;
  };
  const [isEndTrainingModalOpen, setIsEndTrainingModalOpen] =
    useState<boolean>(false);
  const endTrainingHandler = () => {
    setIsEndTrainingModalOpen(true);
  };
  const closeConfirmationModal = () => {
    setIsEndTrainingModalOpen(false);
  };
  const endTrainingHandlerAfterConfirmation = () => {
    try {
      closeConfirmationModal();

      let isError = false;
      let singleGunnerForceId = gorillaTrainingsForces.Single_Gunner?.id;
      const shootingExecutionTrainingType = 21;
      const malfunctionsOperationTrainingType = 22;
      const launcherOperatingTrainingType = 23;
      setHeatsArray((prev) => prev.filter((heat) => heat.isCompleted));
      heatsArray.forEach((heat) => {
        if (heat.isCompleted) {
          let drillShootingExecution = {
            SoldierID: singleGunnerForceId,
            MessageType: EEventType.SPIKE_MESSAGE_TYPE,
            TimeStamp: new Date(),
            TrainingTypeID: shootingExecutionTrainingType,
            WarheadFit: heat.shootingExecution?.WarheadFit,
            OperatingTime: heat.shootingExecution?.OperatingTime.time,
            Hit: heat.shootingExecution?.Hit,
            ExamineDamageEffect: heat.shootingExecution?.ExamineDamageEffect,
            LOSToTarget: heat.targetsPropertiesResults?.LOSToTarget,
            AreaZone: heat.targetsPropertiesResults?.areaZone,
            Launcher: selectedLuncherType,
            ShootToTarget: heat.targetsPropertiesResults?.shootToTarget,
            ShootingType: heat.targetsPropertiesResults?.shootingType,
            TargetState: heat.targetsPropertiesResults?.targetState,
            TargetType: heat.targetsPropertiesResults?.targetType,
            Time: heat.targetsPropertiesResults?.time,
            Notes: JSON.stringify(
              mainNotes[heat.heatId]?.map((note) => note.note)
            ),
            DrillStartTime: new Date(),
            PlanId: plan?.id,
          };
          sendDrillToBBackend(
            drillShootingExecution,
            () => {},
            () => {
              isError = true;
            }
          );
          if (
            Object.values(heat.malfunctionsOperation || {}).find(
              (val) => val !== undefined
            )
          ) {
            let malfunctionsOperationDrill = {
              SoldierID: singleGunnerForceId,
              MessageType: EEventType.SPIKE_MESSAGE_TYPE,
              TimeStamp: new Date(),
              TrainingTypeID: malfunctionsOperationTrainingType,
              ISNTShootingLowHigh:
                heat.malfunctionsOperation?.ISNTShootingLowHigh.time,
              BrokenLauncher: heat.malfunctionsOperation?.BrokenLauncher.time,
              HotBattery: heat.malfunctionsOperation?.HotBattery.time,
              HotMissile: heat.malfunctionsOperation?.HotMissile.time,
              ImmobilizerPress:
                heat.malfunctionsOperation?.ImmobilizerPress.time,
              ImproveOnly: heat.malfunctionsOperation?.ImproveOnly.time,
              LockBreak: heat.malfunctionsOperation?.LockBreak.time,
              ShootingStopTime: heat.malfunctionsOperation?.ShootingStop.time,
              ShootingStopAccuracy:
                heat.malfunctionsOperation?.ShootingStop.accuracy,
              DrillStartTime: new Date(),
              PlanId: plan?.id,
            };
            sendDrillToBBackend(
              malfunctionsOperationDrill,
              () => {},
              () => {
                isError = true;
              }
            );
          }
          if (
            Object.values(heat.systemEncryption || {}).find(
              (val) => val !== undefined
            )
          ) {
            let launcherOperatingDrill = {
              SoldierID: singleGunnerForceId,
              MessageType: EEventType.SPIKE_MESSAGE_TYPE,
              TimeStamp: new Date(),
              TrainingTypeID: launcherOperatingTrainingType,
              CombinedTime: heat.systemEncryption?.Combined.time,
              CombinedAccuracy: heat.systemEncryption?.Combined.accuracy,
              ControlUnitTime: heat.systemEncryption?.ControlUnit?.time,
              ControlUnitAccuracy: heat.systemEncryption?.ControlUnit?.accuracy,
              OperatingLauncherHeatGrade:
                heat.systemEncryption?.OperatingLauncherHeatGrade,
              DrillStartTime: new Date(),
              PlanId: plan?.id,
            };
            sendDrillToBBackend(
              launcherOperatingDrill,
              () => {},
              () => {
                isError = true;
              }
            );
          }
        }
      });

      if (!isError) {
        setIsTrainingEnd(true);
        setHeatsArray((prev) => {
          return [...prev].map((heat) => ({
            ...heat,
            isTrainingEnd: heat.isCompleted,
          }));
        });
      } else {
        throw "error";
      }
    } catch {
      customToast.error(
        `${t("dataSendError")} ${t("for")} ${EGorillaForces.SingleGunner}`
      );
    }
  };
  useEffect(() => {
    if (
      isTrainingEnd &&
      !(
        gorillaTrainingsForces[EGorillaForces.Observer] ||
        gorillaTrainingsForces[EGorillaForces.NCO]
      )
    ) {
      //send all data for the current force with the current plan id with the training types data.

      replace(
        isDesktop
          ? `${dataPostingRoutes.hasifaTkifa}`
          : `${mobileRoutes.stations}`
      );
    }
  }, [isTrainingEnd]);
  const addNote = (note: string) => {
    if (activeHeatId !== null) {
      setMainNotes((prev) => ({
        ...prev,
        [activeHeatId]: prev[activeHeatId]
          ? [
              ...prev[activeHeatId],
              {
                note: note,
                isEditable: true,
                noteId: Math.round(Math.random() * 100 + 100),
                heatId: activeHeatId,
              },
            ]
          : [
              {
                note: note,
                isEditable: true,
                noteId: Math.round(Math.random() * 100 + 100),
                heatId: activeHeatId,
              },
            ],
      }));
    }
  };
  const editNote = (noteId: number, noteText: string) => {
    if (activeHeatId !== null) {
      setMainNotes((prev) => {
        const updatedNotes = { ...prev };
        if (updatedNotes[activeHeatId]?.length) {
          updatedNotes[activeHeatId] = updatedNotes[activeHeatId].map((note) =>
            note.noteId === noteId ? { ...note, note: noteText } : note
          );
        }
        return updatedNotes;
      });
    }
  };
  const deleteNote = (noteId: number) => {
    if (activeHeatId !== null) {
      setMainNotes((prevMainNotes) => {
        const updatedNotes = { ...prevMainNotes };
        if (updatedNotes[activeHeatId]?.length) {
          updatedNotes[activeHeatId] = updatedNotes[activeHeatId].filter(
            (note) => note.noteId !== noteId
          );
        }
        return updatedNotes;
      });
    }
  };

  useEffect(() => {
    setSingleGunnerSteps((prevState) => {
      return {
        ...prevState,
        [ESingleGunnerSteps.TargetsProperties]: {
          ...prevState[ESingleGunnerSteps.TargetsProperties],
          isCompleted: !Object.values(targetsPropertiesResults).includes(
            undefined
          ),
        },
        [ESingleGunnerSteps.ShootingExecution]: {
          ...prevState[ESingleGunnerSteps.ShootingExecution],
          isCompleted: isShootingExecutionComplete(),
        },

        [ESingleGunnerSteps.SystemEncryption]: {
          ...prevState[ESingleGunnerSteps.SystemEncryption],
          isCompleted:
            prevState[ESingleGunnerSteps.SystemEncryption].isCompleted ||
            currentStepType > ESingleGunnerSteps.SystemEncryption,
        },
        [ESingleGunnerSteps.MalfunctionOperation]: {
          ...prevState[ESingleGunnerSteps.MalfunctionOperation],
          isCompleted:
            prevState[ESingleGunnerSteps.MalfunctionOperation].isCompleted ||
            currentStepType > ESingleGunnerSteps.MalfunctionOperation,
        },
        [ESingleGunnerSteps.MainNotes]: {
          ...prevState[ESingleGunnerSteps.MainNotes],
          isCompleted: mainNotes[activeHeatId]?.length > 0,
        },
      };
    });
    saveHeat();
    // eslint-disable-next-line
  }, [
    targetsPropertiesResults,
    shootingExecution,
    malfunctionsOperation,
    systemEncryption,
    currentStepType,
    mainNotes,
  ]);

  useEffect(() => {
    let heat = heatsArray.find((heat) => heat.heatId === activeHeatId);
    setMalfunctionOperation(
      heat?.malfunctionsOperation || initialMalfunctionOperationTemplate
    );
    setShootingExecution(
      heat?.shootingExecution || initialShootingExecutionTemplate
    );
    setSystemEncryption(
      heat?.systemEncryption || initialSystemEncryptionTemplate
    );
    setTargetsPropertiesResults(
      heat?.targetsPropertiesResults || initialTargetsPropertiesResultsTemplate
    );
    setCurrentStepType(
      (heat?.stepsState &&
        Object.values(heat?.stepsState).find((step) => !step.isCompleted)
          ?.id) ||
        ESingleGunnerSteps.SystemEncryption
    );
    setSingleGunnerSteps(heat?.stepsState || initHeatsStepTemplate);

    // eslint-disable-next-line
  }, [activeHeatId]);

  useEffect(() => {
    let activeHeat = heatsArray.find((heat) => heat.heatId === activeHeatId);
    if (!activeHeat && heatsArray[0]?.heatId)
      setActiveHeatId(heatsArray[0].heatId);
  }, [heatsArray]);

  useEffect(() => {
    setHeatsAmount(initHeatsAmount || 0);
  }, [initHeatsAmount]);

  useEffect(() => {
    setNotesToDisplay(
      Object.entries(mainNotes)
        .map(([noteHeatId, notes]) => {
          let isEditable = Number(activeHeatId) === Number(noteHeatId);
          return notes.map((note) => ({ ...note, isEditable: isEditable }));
        })
        .flat(1)
    );
  }, [mainNotes, activeHeatId, heatsArray]);
  return {
    isShootingExecutionComplete,
    initSingleGunnerObjects,
    saveHeatAndMoveToNext,
    setShootingExecution,
    setMalfunctionOperation,
    setSystemEncryption,
    setTargetsPropertiesResults,
    setHeatsAmount,
    addNote,
    deleteNote,
    editNote,
    mainNotes: notesToDisplay,
    addHeat,
    deleteHeat,
    resetHeat,
    setActiveHeat,
    endTrainingHandler,
    systemEncryption,
    shootingExecution,
    malfunctionsOperation,
    targetsPropertiesResults,
    activeHeatId,
    setActiveHeatId,
    heatsArray,
    currentStepType,
    setCurrentStepType,
    gorillaTrainingsForces,
    traineeName: gorillaTrainingsForces.Single_Gunner?.name,
    isModalOpen,
    setIsModalOpen,
    singleGunnerSteps,
    setSingleGunnerSteps,
    selectedLuncherType,
    isEndTrainingModalOpen,
    closeConfirmationModal,
    endTrainingHandlerAfterConfirmation,
    isTrainingEnd,
  };
};
export default SingleGunnerHeatsHook;
