import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import Axios from "../../../Axios";
import { baseUrlPMBackend } from "../../../Configurations/consts";
import { ShootingRangeContext } from "../../../context/ShootingRangeContext/ShootingRangeContext";
import IShootingDay from "../../../Interfaces/IShootingDay";
import customToast from "../../Shared/Toast/CustomToast";

const getShootingDays = async () =>
  (
    await Axios.get(
      `${baseUrlPMBackend}stations/shooting-range/getShootingDaysTypes`
    )
  ).data;

export const useShootingDays = () => {
  const { t } = useTranslation();
  const { shootingDays, setShootingDays } = useContext(ShootingRangeContext);

  let { data, isLoading, isError } = useQuery<IShootingDay[]>({
    queryKey: ["shootingDays"],
    queryFn: getShootingDays,
    enabled: !shootingDays.length,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!isLoading && !isError && data && data.length) {
      //set the context with the force types data
      setShootingDays(
        data
          .map((shootingDay) => ({
            ...shootingDay,
            name: t(shootingDay.name),
            id: Number(shootingDay.id),
          }))
          .sort((day1: IShootingDay, day2: IShootingDay) => day1.id - day2.id)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (isError) {
      customToast.error(t("errorWhileLoadingHeats"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return { shootingDays };
};
