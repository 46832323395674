import {
  IonBadge,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonText,
} from "@ionic/react";
import React, { FC } from "react";
import IDatasetsChart from "../../../Interfaces/IDatasetsChart";
import IGrade from "../../../Interfaces/IGrade";
import "./LabelsList.css";

interface IProps {
  forces: IDatasetsChart[];
  grades: IGrade[];
  threshold: number;
}

const LabelsList: FC<IProps> = (props: IProps): JSX.Element => {
  const { forces, grades } = props;

  const getGrade = (grades: IGrade[], forceId: number): number => {
    const currentGrade: number | undefined = grades.find(
      (grade: IGrade) => +grade.id === forceId
    )?.grade;

    return currentGrade ? Math.round(currentGrade) : 0;
  };

  return (
    <IonItemGroup className="leadersItemGroup">
      {forces.map((force: IDatasetsChart, index) => (
        <IonItem key={index} className="legendItem" lines="none" mode="md">
          <IonBadge
            className="badge"
            slot="start"
            style={{ backgroundColor: force.borderColor }}
          />
          <IonLabel className="labelText">{force.label}</IonLabel>
          <IonText className="labelGradeLL">
            {force.id === 0
              ? props.threshold
              : getGrade(grades, Number(force.id))}
          </IonText>
        </IonItem>
      ))}
    </IonItemGroup>
  );
};

export default LabelsList;
