import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./InstructorFeedbackComponent.css";
import IFElement from "./InstructorFeedbackElement";
import { IonCol, IonLabel, IonRow } from "@ionic/react";
import PMLabel from "../../../themeComponents/PMLabel";

import InstructorFeedbackComponentHook from "../../../CustomHooks/InstructorFeedbackComponentHook";
import { InstructorFeedbackElementsData } from "../../../../Interfaces/IInstructorFeedback";
interface IProps {
  traineeId: number | undefined;
  isDesktop?: boolean;
  isSendClicked?: boolean;
  setIsComplete: (state: boolean) => void;
  planId: number | undefined;
  trainingTypeId: number;
  elementsData: InstructorFeedbackElementsData[] | undefined;
  selectedSegmentTitle: string | undefined;
  afterPostHandler: () => void;
}

const InstructorFeedbackComponent: React.FC<IProps> = (
  props: IProps
): JSX.Element => {
  const {
    traineeId,
    isDesktop,
    isSendClicked,
    setIsComplete,
    elementsData,
    trainingTypeId,
    afterPostHandler,
  } = props;
  const { t } = useTranslation();
  const {
    isTraineeChanged,
    setUIFGrades,
    postData,
    setIsTraineeChanged,
    isComplete,
  } = InstructorFeedbackComponentHook(
    t,
    traineeId,
    elementsData,
    trainingTypeId,
    props.selectedSegmentTitle,
    props.planId,
    isDesktop,
    afterPostHandler
  );
  useEffect(() => {
    if (isSendClicked) postData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSendClicked]);
  useEffect(() => {
    setIsComplete(isComplete);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComplete]);
  return (
    <React.Fragment>
      <IonRow className="titleRow">
        <IonCol size="2">
          <IonLabel></IonLabel>
        </IonCol>
        <IonCol size="6">
          <IonLabel></IonLabel>
        </IonCol>
        <IonCol size="3">
          <IonLabel></IonLabel>
        </IonCol>
        <IonCol className="colL">
          <PMLabel
            textAlign="center"
            fontColor="xLight"
            fontFamily="Regular"
            fontSize="medium"
          >
            {t("avgGrade")}
          </PMLabel>
        </IonCol>
      </IonRow>
      <div className="UFIWrap scrollL">
        <div className="rowUIFC">
          {elementsData?.map(
            (elementData: InstructorFeedbackElementsData, index) => (
              <IFElement
                key={elementData.dataType}
                dataType={elementData.dataType}
                elements={elementData.params}
                title={elementData.title}
                isMust={elementData.isMust}
                setGrade={setUIFGrades}
                resetAll={isTraineeChanged}
                setResetAll={setIsTraineeChanged}
                index={index}
                isTraineeSelected={!!traineeId}
              ></IFElement>
            )
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default InstructorFeedbackComponent;
