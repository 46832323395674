import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { IonCol, IonGrid, IonRow, IonSpinner } from "@ionic/react";
import IPlan from "../../../../../Interfaces/IPlan";
import IForceFromOrbat from "../../../../../Interfaces/IForceFromOrbat";
import IForceTreeNode from "../../../../../Interfaces/IForceTreeNode";
import { useTranslation } from "react-i18next";
import DashboardDoughnut from "../../../../../components/Desktop/DashboardDoughnut/DashboardDoughnut";
import PMLabel from "../../../../../components/themeComponents/PMLabel";
import useGetCommanderDashboardGrades, {
  getElementColor,
  getLethalityAsElement,
  LETHALITY_ID,
} from "../../../../../components/CustomHooks/useGetCommanderDashboardGrades";
import { getForceFullName } from "../../../../../services/helpers";
import "react-modern-drawer/dist/index.css";
import Legend from "../../../../../components/Desktop/VerticalProgressBar/Legend";
import Colors from "../../../../../Interfaces/Colors";
import TraineesModal from "../../../../../components/Desktop/DashboardTrainees/TraineesModal/TraineesModal";
import { IForce } from "../../../../../Interfaces/results/force.interface";
import { ITrainee } from "../../../../../Interfaces/results/ITrainee.interface";
import { IModalResult } from "../../../../../Interfaces/results/modal-result.interface";
import DashboardTrainingTypeComp from "../../../../../components/Desktop/DashboardComps/DashboardTrainingTypeComp";
import {
  IElementResult,
  IIndicatorResult,
  ITrainingTypeResult,
} from "../../../../../Interfaces/dataCalculator";

import "./CommanderDashboardComponent.css";

import useInitGraphsData from "../../../../../components/CustomHooks/useInitGraphsData";
import { IPlanValues } from "../../../../../Interfaces/IPlanValues";
import { EDashboardBar } from "../../../../../Enums/EDashboardBar";
import { ICardClickedParams } from "../../../../../Interfaces/IDashboardDetailsCard";

interface IProps {
  selectedPlan: IPlan | undefined;
  commanderForces: IForceFromOrbat[];
  plansValuesForSelectedPlan: IPlanValues | undefined;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  refresh?: boolean | undefined;
  onCheckForce?: (forces: IForceTreeNode[]) => void;
  orbatForces?: IForceTreeNode[];
  setCommanderForces?: Dispatch<SetStateAction<IForceFromOrbat[]>>;
  setRefresh?: Dispatch<SetStateAction<boolean>>;
}

const CommanderDashboardComponent: FC<IProps> = (
  props: IProps
): JSX.Element => {
  const { t } = useTranslation();
  const {
    onCheckForce,
    selectedPlan,
    commanderForces,
    plansValuesForSelectedPlan,
    setIsLoading,
    orbatForces,
    setCommanderForces,
    refresh,
    setRefresh,
  } = props;
  const [selectedIndicator, setSelectedIndicator] = useState<number>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [sortedElement, setSortedElement] = useState<string>("");
  const [clickedColumn, setClickedColumn] = useState<EDashboardBar>();
  const [modalTrainees, setModalTrainees] = useState<ITrainee[]>([]);
  const [trainingType, setTrainingType] = useState<ITrainingTypeResult>();
  const towDigitsElements = ["averageGrouping", "leaderGrouping"];

  const {
    trainingTypeData,
    indicatorData,
    amountOfSoldiers,
    onlySoldiers,
    selectedForcesID,
    setSelectedForcesID,
    forceData,
    loading,
    resetCommanderStates,
  } = useGetCommanderDashboardGrades(
    selectedPlan,
    commanderForces,
    refresh,
    setRefresh
  );

  const { labelsCompetency, labelsDetails } = useInitGraphsData(
    plansValuesForSelectedPlan
  );

  const getForceChildren = (orbatTree: IForceTreeNode) => {
    return orbatTree
      ? orbatTree?.nodes
        ? [
            {
              forceType: orbatTree.force_type,
              id: orbatTree.id,
              isSoldier: orbatTree.nodes === null,
              name: orbatTree.name,
            },
            ...orbatTree.nodes
              .filter((f) => f.nodes !== null)
              ?.map((node) => ({
                forceType: node.force_type,
                id: node.id,
                isSoldier: node.nodes === null,
                name: getForceFullName(node.name, node.force_type, t),
              })),
          ]
        : [
            {
              forceType: orbatTree.force_type,
              id: orbatTree.id,
              isSoldier: orbatTree.nodes === null,
              name: orbatTree.name,
            },
          ]
      : [];
  };
  useEffect(() => {
    if (orbatForces?.length) {
      setCommanderForces &&
        setCommanderForces(getForceChildren(orbatForces[0]));
    } else {
      setCommanderForces && setCommanderForces([]);
    }
    resetCommanderStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orbatForces]);

  useEffect(() => {
    setIsLoading(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const onCardClick = ({
    clickedColumn,
    element,
    indicatorId,
    trainingType,
  }: ICardClickedParams) => {
    setClickedColumn(clickedColumn);
    setIsModalOpen(true);
    setSortedElement(element);
    setTrainingType(trainingType);
    setModalTrainees(
      onlySoldiers!.map((soldier: IForce): ITrainee => {
        const parentName = soldier.hierarchicalName
          .map((affiliation) => {
            return getForceFullName(affiliation.name, affiliation.forceType, t);
          })
          .join(" / ");
        let lethalityElement = getLethalityAsElement(soldier);
        const trainee: ITrainee = {
          forceId: soldier.id,
          forceName: soldier.name,
          soldierId: soldier.soldierId,
          forceHierarchy: parentName[parentName.length - 2],
          results:
            //if its a lethality indicator the results for the element should be the training plan grades
            indicatorId === LETHALITY_ID
              ? [
                  {
                    elementId: lethalityElement.id,
                    elementName: lethalityElement.name,
                    value: lethalityElement.value,
                    color: getElementColor(lethalityElement),
                  },
                ]
              : soldier.results?.indicatorResults
                  ?.find(
                    (indicatorResult: IIndicatorResult) =>
                      Number(indicatorResult.id) === Number(indicatorId)
                  )
                  ?.trainingTypeResults.find(
                    (trainingTypeResult: ITrainingTypeResult) =>
                      Number(trainingTypeResult.id) === Number(trainingType.id)
                  )
                  ?.elementResults.map((er: IElementResult) => {
                    er = {
                      ...er,
                      value:
                        !er.value && er.value !== 0 //make sure the value is null
                          ? er.value
                          : towDigitsElements.includes(er.name)
                          ? Number(er.value).toFixed(2)
                          : Math.round(er.value),
                    };
                    const result: IModalResult = {
                      elementId: er.id,
                      elementName: er.name,
                      value: er.value,
                      color: getElementColor(er),
                    };
                    return result;
                  }),
        };
        if (trainee.results === undefined) trainee.results = [];
        return trainee;
      })
    );
  };

  return (
    <IonCol className="commanderDashboardChart">
      <TraineesModal
        isOpen={isModalOpen}
        sortedElement={sortedElement}
        onDismiss={() => setIsModalOpen(false)}
        trainees={modalTrainees}
        trainingType={trainingType}
        clickedColumn={clickedColumn}
      ></TraineesModal>
      <IonRow className="CDHeaderCol">
        <div className="CDHeaderRow">
          <PMLabel
            cssClass="CDPlanName"
            fontColor="xLight"
            fontFamily="Regular"
          >
            {orbatForces && orbatForces[0]?.name ? orbatForces[0]?.name : ""}
          </PMLabel>
          {amountOfSoldiers ? (
            <PMLabel
              cssClass="totalAmountValue"
              fontColor="xLight"
              fontFamily="Regular"
              fontSize="small"
            >
              {amountOfSoldiers} {t("soldiers")}
            </PMLabel>
          ) : null}
        </div>
        <div className="legendRow">
          {forceData?.children.map((child: IForce, index: number) =>
            !child.isSoldier ? (
              <Legend
                selectLowLevelForce={onCheckForce}
                key={child.id}
                forceName={child.name}
                color={Colors[index]}
                isSelected={
                  !selectedForcesID.length
                    ? undefined
                    : selectedForcesID.includes(Number(child.id))
                }
                setSelectedForcesID={setSelectedForcesID}
                forceId={Number(child.id)}
                force={
                  orbatForces
                    ? orbatForces[0]?.nodes?.find(
                        (f) => Number(f.id) === Number(child.id)
                      )
                    : undefined
                }
              ></Legend>
            ) : null
          )}
        </div>
      </IonRow>

      <IonRow className="CDDoughunt ">
        <IonGrid className="CDdGrid">
          <IonRow className="dRow">
            {labelsCompetency.length ? (
              [
                { id: LETHALITY_ID, label: t("lethality") }, //enable lethality doughnut
                ...labelsCompetency,
              ].map((indicator) => (
                <IonCol
                  key={indicator.id}
                  className="CDColl"
                  size={String(12 / (labelsCompetency.length + 1))}
                >
                  <DashboardDoughnut
                    key={indicator.id}
                    setSelectedIndicator={setSelectedIndicator}
                    selectedIndicator={selectedIndicator}
                    indicatorId={Number(indicator.id)}
                    indicatorName={
                      indicator.label !== "" ? t(indicator.label) : ""
                    }
                    isDisableAll={!indicatorData}
                    chartData={
                      indicatorData
                        ? indicatorData[String(indicator.id)]
                        : indicatorData
                    }
                  ></DashboardDoughnut>
                </IonCol>
              ))
            ) : (
              <IonSpinner
                className="loadingPlanValues"
                color="x-light"
              ></IonSpinner>
            )}
          </IonRow>
        </IonGrid>
      </IonRow>
      <IonRow className="CDdetails scrollL">
        {selectedIndicator ? (
          <IonCol>
            <DashboardTrainingTypeComp
              indicatorData={indicatorData}
              selectedIndicator={selectedIndicator}
              onCardClick={onCardClick}
              trainingTypeData={trainingTypeData}
              trainingTypesLabels={labelsDetails}
            ></DashboardTrainingTypeComp>
          </IonCol>
        ) : (
          <div className="CDText" hidden={selectedIndicator !== undefined}>
            <PMLabel fontColor="light" fontFamily="Light" fontSize="xxLarge">
              {t("clickForDetails")}
            </PMLabel>
          </div>
        )}
      </IonRow>
    </IonCol>
  );
};

export default CommanderDashboardComponent;
