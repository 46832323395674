// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mrkContent {
  --background: transparent;
}
.errorW {
  height: 50%;
  display: flex;
  justify-content: space-between;
}
.erroeM {
  height: 50%;
  width: 100%;
  color: red;
  border: red;
  border-style: solid;
  border-width: 0;
  border-top-width: 0.5px;
  font-size: 10px;
  margin-top: 20px;
  line-height: 10px;
  /* padding-inline-start: 8px; */
}
.valueMRK {
  margin-bottom: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/DataPosting/Mrk.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,WAAW;EACX,WAAW;EACX,UAAU;EACV,WAAW;EACX,mBAAmB;EACnB,eAAe;EACf,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,+BAA+B;AACjC;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".mrkContent {\n  --background: transparent;\n}\n.errorW {\n  height: 50%;\n  display: flex;\n  justify-content: space-between;\n}\n.erroeM {\n  height: 50%;\n  width: 100%;\n  color: red;\n  border: red;\n  border-style: solid;\n  border-width: 0;\n  border-top-width: 0.5px;\n  font-size: 10px;\n  margin-top: 20px;\n  line-height: 10px;\n  /* padding-inline-start: 8px; */\n}\n.valueMRK {\n  margin-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
