import { IonRow, IonItem, IonText } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./SearchComponents.css";
import ISimpleTrainee, { ETeam } from "../../../../Interfaces/ISimpleTrainee";
import Spinner from "../../Spinner/Spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import useGetFilterForce from "./useGetFilterForces";
import PMInput from "../../../Desktop/TrainingPlan/PMInput";

interface IProps {
  setSoldierDetails: React.Dispatch<React.SetStateAction<ISimpleTrainee[]>>;
  soldierDetails: ISimpleTrainee[];
  okHandler?: (soldiers: ISimpleTrainee[]) => void;
  isDesktop?: boolean;
  selectedForcesAmount?: number; // Added prop for maximum selected forces
  isPairs?: boolean;
}

const SearchComponents: React.FC<IProps> = ({
  setSoldierDetails,
  isDesktop,
  soldierDetails,
  okHandler,
  selectedForcesAmount = 1, // Default value set here
  isPairs = false,
}: IProps): JSX.Element => {
  const { t } = useTranslation();
  // const [pairs, setPairs] = useState<Map<number, number>>(new Map());
  const onClickHandler = (soldier: ISimpleTrainee) => {
    const forcesAmount = selectedForcesAmount * (isPairs ? 2 : 1);

    const isSelected = soldierDetails.some(
      (selected) => selected.uniqId === soldier.uniqId
    );

    let updatedSoldiers;
    const teamGreen = soldierDetails.filter(
      (s) => s.team === ETeam.teamGreen
    ).length;
    const teamBlue = soldierDetails.filter(
      (s) => s.team === ETeam.teamBlue
    ).length;

    if (isSelected) {
      // Remove soldier if already selected
      updatedSoldiers = soldierDetails.filter(
        (selected) => selected.uniqId !== soldier.uniqId
      );
    } else if (soldierDetails.length < forcesAmount) {
      // Determine color based on current counts
      const nextTeam = teamGreen > teamBlue ? ETeam.teamBlue : ETeam.teamGreen;

      updatedSoldiers = [...soldierDetails, { ...soldier, team: nextTeam }];
    } else {
      // Do nothing if max limit reached
      updatedSoldiers = forcesAmount === 1 ? [soldier] : soldierDetails;
    }

    setSoldierDetails(updatedSoldiers);

    if (forcesAmount === updatedSoldiers.length && okHandler) {
      okHandler(updatedSoldiers);
    }
  };

  const {
    loading,
    setSearchText,
    soldiersNamesAndIds,
    searchText,
    displayedArray,
    hasMoreTrainees,
    loadMoreData,
  } = useGetFilterForce();

  useEffect(() => {
    setSoldierDetails([]);
    setSearchText("");
  }, [soldiersNamesAndIds, setSoldierDetails]);

  return (
    <div className="searchCompo">
      {loading ? (
        <Spinner className="spinner-trainees-list" />
      ) : (
        <>
          <div
            className={
              isDesktop ? "searchCompWrapDesktop" : "searchCompWrapMobile"
            }
          >
            <IonRow className="searchCompRow">
              <PMInput
                inputName="searchForce"
                inputType="text"
                placeholder={t("searchForce")}
                inputValue={searchText}
                onChangeHandler={(_name, value) => {
                  setSearchText(value && value !== null ? value : "");
                }}
              />
            </IonRow>

            <IonRow className="soldiersListRow">
              {displayedArray.length ? (
                <InfiniteScroll
                  dataLength={displayedArray.length}
                  next={loadMoreData}
                  hasMore={hasMoreTrainees}
                  loader={<Spinner className="spinner-trainees-list" />}
                  className={isDesktop ? "soldiersList" : "mobileSoldiersList"}
                >
                  {displayedArray.map((trainee: ISimpleTrainee, index) => (
                    <IonItem
                      key={trainee.uniqId}
                      mode="md"
                      button
                      className={`soldierFound itemMS 
                        ${
                          isDesktop
                            ? "backgroundSearch "
                            : "soldierFoundMobile "
                        } 
                        ${
                          soldierDetails.some(
                            (selected) => selected.uniqId === trainee.uniqId
                          )
                            ? `${
                                isPairs
                                  ? soldierDetails.find(
                                      (selected) =>
                                        selected.uniqId === trainee.uniqId
                                    )?.team === ETeam.teamGreen
                                    ? "blueTItem"
                                    : "greenTItem"
                                  : "selectedDesktopSearch"
                              }`
                            : ""
                        } `}
                      lines="full"
                      onClick={() => onClickHandler(trainee)}
                    >
                      <IonText className="traineeNameSC">{`${trainee.name}  `}</IonText>
                    </IonItem>
                  ))}
                </InfiniteScroll>
              ) : searchText.length ? (
                <IonItem
                  mode="md"
                  className={
                    "notFoundSoldier itemMS" +
                    (isDesktop ? "" : "soldierFoundMobile")
                  }
                  lines="none"
                >
                  <IonText className="notFoundSoldier">
                    {t("soldierNotFound")}
                  </IonText>
                </IonItem>
              ) : null}
            </IonRow>
          </div>
        </>
      )}
    </div>
  );
};

export default SearchComponents;
