import React from "react";
import "./VerticalProgressBar.css";

interface IProps {
  value: number;
  threshold: number;
  percent100: number;
  color?: "green" | "red" | "yellow" | "gray" | "purple" | string;
  grade: number;
}
const VerticalProgressBar: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { percent100, value, threshold, color, grade } = props;
  const percent = Math.round((value / percent100) * 100);
  return (
    <div className="progress">
      <div className="bar">
        <div
          className={`percent ${color}VPB`}
          style={{ top: `${100 - percent}%`, height: `${percent}%` }}
        ></div>

        <>
          <span className="percentValue" style={{ top: `${102 - percent}%` }}>
            {Math.round(value)}
          </span>
          <span
            className="percentValueCenter"
            style={{ top: `${102 - percent}%` }}
          >
            {grade ? grade : ""}
          </span>
        </>
      </div>
      {Math.round(value) !== percent100 ? (
        <span className="percentValue">{percent100}</span>
      ) : null}
      <div
        className="threshold"
        style={{
          top: `${100 - threshold}%`,
          height: `${threshold}%`,
        }}
      ></div>
      {Math.round(threshold) !== percent100 && threshold !== value ? (
        <span
          className="percentValue"
          style={{ top: `${95 - threshold}%` }}
        ></span>
      ) : null}
    </div>
  );
};

export default VerticalProgressBar;
