import { IonIcon } from "@ionic/react";
import React, { FC } from "react";
import "./PMIcon.css";
import { IPMIcon } from "../../Interfaces/IPMTheme";
const PMIcon: FC<IPMIcon> = (props: IPMIcon) => {
  const {
    iconSrc,
    color,
    size,
    onMouseEnter,
    onMouseLeave,
    onClick,
    disabled,
    disabledColor,
    isButton,
    hidden,
    cssClass,
  } = props;
  return isButton ? (
    <div className="pmIconButtonDiv">
      <IonIcon
        mode="ios"
        slot={props.slot}
        className={`pmIcon pmIconButton ${size}I ${cssClass} ${
          disabledColor || disabled ? "disabledColorI" : color
        } ${disabled ? "disabledI " : ""} ${cssClass}`}
        src={iconSrc}
        hidden={hidden}
        onMouseEnter={disabled ? () => {} : onMouseEnter}
        onMouseLeave={disabled ? () => {} : onMouseLeave}
        onClick={disabled ? () => {} : onClick}
      ></IonIcon>
    </div>
  ) : (
    <IonIcon
      hidden={hidden}
      className={`pmIcon ${size}I  ${cssClass}  ${
        disabledColor || disabled ? "disabledColorI" : color
      } ${disabled ? "disabledI" : ""}`}
      src={iconSrc}
      onMouseEnter={disabled ? () => {} : onMouseEnter}
      onMouseLeave={disabled ? () => {} : onMouseLeave}
      onClick={disabled ? () => {} : onClick}
    ></IonIcon>
  );
};
export default PMIcon;
