import { useContext, useEffect, useState } from "react";
import { INCO } from "../../Interfaces/Gorilla/INCO";
import useNavigation from "./useNavigation";
import { mobileRoutes } from "../../services/routeRoles";
import { GorillaTrainingContext } from "../../context/GorillaTrainingContext/GorillaTrainingContext";
import EEventType from "../../Interfaces/EEventType";
import { sendDrillToBBackend } from "../Desktop/DataPosting/dataPostingHelpers";
import { EGorillaForces } from "../../Interfaces/Gorilla/GorillaTrainings";
import { dataPostingRoutes } from "./useDataPostingTabs";
import customToast from "../Shared/Toast/CustomToast";
import { useTranslation } from "react-i18next";

export enum ENCOSteps {
  SystemEncryption,
}
interface INCOStep {
  id: ENCOSteps;
  title: string;
  isCompleted: boolean;
  isPartlyCompleted: boolean;
}
const NCOHook = (isDesktop?: boolean) => {
  const { t } = useTranslation();
  const [ncoSteps, setNCOSteps] = useState<Record<ENCOSteps, INCOStep>>({
    [ENCOSteps.SystemEncryption]: {
      id: ENCOSteps.SystemEncryption,
      title: "systemEncryption",
      isCompleted: false,
      isPartlyCompleted: false,
    },
  });

  const { gorillaTrainingsForces, plan } = useContext(GorillaTrainingContext);

  const initNCO: INCO = {
    CommunicationWithGunner: undefined,
    CommunicationWithObserver: undefined,
    FiringOrderExecution: undefined,
    ScanPlanExecution: undefined,
  };
  const { replace } = useNavigation();
  const [ncoCurrentStepType, setNCOCurrentStepType] = useState<ENCOSteps>(
    ENCOSteps.SystemEncryption
  );
  const [nco, setNco] = useState<INCO>(initNCO);
  const [isTrainingEnd, setIsTrainingEnd] = useState<boolean>(false);
  const [isEndTrainingModalOpen, setIsEndTrainingModalOpen] =
    useState<boolean>(false);
  const endTrainingHandlerNCO = () => {
    setIsEndTrainingModalOpen(true);
  };
  const closeConfirmationModal = () => {
    setIsEndTrainingModalOpen(false);
  };
  const endTrainingHandlerAfterConfirmation = () => {
    try {
      closeConfirmationModal();
      let isError = false;
      const observerForceId = gorillaTrainingsForces?.NCO?.id;

      const NCOTrainingTypeId = 29;
      if (ncoSteps[ENCOSteps.SystemEncryption].isPartlyCompleted) {
        let SettingStationDrill = {
          SoldierID: observerForceId,
          MessageType: EEventType.SPIKE_MESSAGE_TYPE,
          TimeStamp: new Date(),
          TrainingTypeID: NCOTrainingTypeId,
          CommunicationWithGunner: nco?.CommunicationWithGunner,
          CommunicationWithObserver: nco?.CommunicationWithObserver,
          FiringOrderExecution: nco?.FiringOrderExecution,
          ScanPlanExecution: nco?.ScanPlanExecution,

          DrillStartTime: new Date(),
          PlanId: plan?.id,
        };
        sendDrillToBBackend(
          SettingStationDrill,
          () => {},
          () => {
            isError = true;
          }
        );

        if (!isError) {
          setIsTrainingEnd(true);
          !isDesktop && customToast.success(t("dataSendSuccess"));
        } else throw "error";
      }
    } catch {
      customToast.error(
        `${t("dataSendError")} ${t("for")} ${EGorillaForces.NCO}`
      );
    }
  };
  useEffect(() => {
    if (
      isTrainingEnd &&
      !(
        gorillaTrainingsForces[EGorillaForces.Observer] ||
        gorillaTrainingsForces[EGorillaForces.SingleGunner]
      )
    ) {
      //send all data for the current force with the current plan id with the training types data.
      replace(
        isDesktop
          ? `${dataPostingRoutes.hasifaTkifa}`
          : `${mobileRoutes.stations}`
      );
    }
  }, [isTrainingEnd]);
  useEffect(() => {
    setNCOSteps((prevState) => {
      return {
        ...prevState,
        [ENCOSteps.SystemEncryption]: {
          ...prevState[ENCOSteps.SystemEncryption],
          isCompleted: !Object.values(nco).includes(undefined),
          isPartlyCompleted: Object.values(nco).some(
            (value) => value !== undefined
          ),
        },
      };
    });
    // eslint-disable-next-line
  }, [nco]);
  return {
    endTrainingHandlerNCO,
    nco,
    setNco,
    traineeName: gorillaTrainingsForces.NCO?.name,
    isTrainingEnd,
    ncoSteps,
    ncoCurrentStepType,
    setNCOCurrentStepType,
    endTrainingHandlerAfterConfirmation,
    closeConfirmationModal,
    isEndTrainingModalOpen,
  };
};
export default NCOHook;
