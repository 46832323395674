import { FC, useContext, useEffect } from "react";
import {
  IonRouterOutlet,
  IonTabs,
  IonTabBar,
  IonTabButton,
  isPlatform,
} from "@ionic/react";
import { Route, Redirect } from "react-router";
import { useLocation } from "react-router-dom";
import "./Tabs.css";

import Leaders from "./../../../pages/Mobile/Leaders/Leaders";
import TabItem from "../../../Interfaces/ITabItem";

import { useTranslation } from "react-i18next";
import PMIcon from "../../themeComponents/PMIcon";
import PMLabel from "../../themeComponents/PMLabel";
import { UserContext } from "../../../context/UserContext/userContext";
import { mobileRoutes, routeRoles, routes } from "../../../services/routeRoles";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import Competency from "../../../pages/Mobile/Competency/Competency";
import Stations from "../../../pages/Mobile/Stations/Stations";
import SearchSoldier from "../../Shared/MenualStation/SearchSoldierComp/SearchSoldier";
import StationsTraineesList from "../../../pages/Mobile/Stations/StationsTraineesList/StationsTraineesList";
import AuthRoute from "../../Desktop/AuthRoute/AuthRoute";
import HeatSelection from "../ManualStation/ShootingRangeDataPosting/HeatSelection";
import CombatFitnessStation from "../../Shared/MenualStation/CombatFitnessStation/CombatFitnessStation";
import MedicalStation from "../../../components/Shared/MenualStation/MedicalStation/MedicalStation";
import InstructorFeedback from "../../../components/Mobile/ManualStation/InstructorFeedbackMobile/InstructorFeedback";
import MagnetDataPosting from "../ManualStation/MagnetDataPosting/MagnetDataPosting";
import ShootingDaySelectionScreen from "../ManualStation/ShootingRange/ShootingDaySelectionScreen/ShootingDaySelectionScreen";
import HeatSelectionScreen from "../ManualStation/ShootingRange/HeatsSelectionScreen/HeatSelectionScreen";
import ResultsScreen from "../ManualStation/ShootingRange/ResultsScreen/ResultsScreen";
import { URLs } from "../../../Configurations/urls";

import DataHistoryMobile from "../../../pages/Mobile/DataHistory/DataHistoryMobile";

import PlanSelectionScreen from "../ManualStation/ShootingRange/PlanSelectionScreen/PlanSelectionScreen";
import { ECompetencyMode } from "../../../Enums/ECompetencyPageMode";
import GorillaInitTraining from "../../Shared/SPIKEGorilla/GorillaInitTraining";
import FrameTraining from "../../Shared/SPIKEGorilla/FrameTraining/FrameTraining";
import Observer from "../../Shared/SPIKEGorilla/Observer/Observer";
import SingleGunner from "../../Shared/SPIKEGorilla/SingleGunner/SingleGunner";
import SiteStatus from "../../../pages/Mobile/SiteStatus/SiteStatus";
import MobileFIreSupport from "../../Shared/MenualStation/FireSupport/MobileFIreSupport";
import { AppModeContext } from "../../../context/AppModeContext/AppModeContext";
import { EAppMode } from "../../../Enums/EAppMode";
import TabletFireSupport from "../../Desktop/DataPosting/TabletFIreSupport";
import Billiard from "../../../pages/Mobile/Billiard/Billiard";

const Tabs: FC = (): JSX.Element => {
  const location = useLocation();
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { appMode } = useContext(AppModeContext);
  const TabsMenu: TabItem[] = [
    {
      url: mobileRoutes.performance,
      title: t("competencyPage"),
      icon: EIconsSrc.COMPETENCY,
    },
    {
      url: mobileRoutes.dataHistory,
      title: t("historyPage"),
      icon: EIconsSrc.DATA_HISTORY,
    },
    {
      url: mobileRoutes.leaders,
      title: t("leadersPage"),
      icon: EIconsSrc.LEADERS,
    },
  ];

  const getMobileUserTabs = () => {
    let tabs: TabItem[] = TabsMenu;

    if (user.relatedForce)
      //personal zone always at the right
      tabs = [
        {
          url: mobileRoutes.personal,
          title: t("personalZone"),
          icon: EIconsSrc.PERSONAL_ZONE,
        },
        ...tabs,
      ];
    return tabs;
  };

  useEffect(() => {
    window.addEventListener("keyboardWillShow", showListener);
    window.addEventListener("keyboardDidHide", hideListener);

    return () => {
      window.removeEventListener("keyboardWillShow", showListener);
      window.removeEventListener("keyboardDidHide", hideListener);
    };
  }, []);

  const showListener = () => {
    document
      .getElementsByClassName("tabBarOpen")[0]!
      .classList.add("keyboard-is-open");
  };
  const hideListener = () => {
    document
      .getElementsByClassName("tabBarOpen")[0]!
      .classList.remove("keyboard-is-open");
  };
  const medicalStationName = `Tourniquet_Placement`;
  const magnetFOFStationName = "Magnet_FOF";
  const combatFitnessStationName = `Combat_Fitness`;
  const magnetInstructorFeedbackStationName = "Magnet_Instructor_Feedback";
  const urbanInstructorFeedbackStationName = "Urban_Instructor_Feedback";

  return (
    <>
      <IonTabs className="mobileTabs tabBarOpen">
        <IonRouterOutlet id="main">
          <Route
            path={mobileRoutes.personal}
            render={() => {
              return getMobileUserTabs().findIndex(
                //If personal zone tab exist in the user tabs bar display personal zone as default selected otherwise display competency
                (tab) => tab.url === mobileRoutes.personal
              ) !== -1 ? (
                <Competency mode={ECompetencyMode.personal}></Competency>
              ) : (
                <Redirect to={mobileRoutes.performance}></Redirect>
              );
            }}
            exact
          />
          <Route
            path={`${routes.marketing}/:dynamicPart1?`}
            render={({ match }) => {
              const { dynamicPart1 } = match.params;
              return dynamicPart1 ? (
                <Redirect to={`/${dynamicPart1}`} />
              ) : (
                <Redirect to={routes.performance} />
              );
            }}
          />
          <Route
            path={mobileRoutes.performance}
            exact
            render={() => <Competency mode={ECompetencyMode.competency} />}
          />
          <Route path="/leaders" component={Leaders} exact />
          <Route
            path={mobileRoutes.dataHistory}
            component={DataHistoryMobile}
            exact
          />
          <AuthRoute
            path={mobileRoutes.stations}
            Component={() => <Stations />}
            requiredRoles={[...routeRoles.Admins, ...routeRoles.Instructors]}
            exact
          />
          <AuthRoute
            path="/searchSoldier/:planId/:stationName/:stationId/:trainingTypeId/:selectedForcesAmount?/:isPairs?"
            Component={SearchSoldier}
            requiredRoles={[...routeRoles.Admins, ...routeRoles.Instructors]}
            exact
          />
          <AuthRoute
            path="/live-shooting/heat-selection/:planId/:stationName/:stationId/:trainingTypeId"
            Component={HeatSelection}
            requiredRoles={[...routeRoles.Admins, ...routeRoles.Instructors]}
            exact
          />
          <AuthRoute
            path={`/${magnetInstructorFeedbackStationName}/:planId/:stationId/:trainingTypeId`}
            Component={InstructorFeedback}
            requiredRoles={[...routeRoles.Admins, ...routeRoles.Instructors]}
            exact
          />
          <AuthRoute
            path={`/${urbanInstructorFeedbackStationName}/:planId/:stationId/:trainingTypeId`}
            Component={InstructorFeedback}
            requiredRoles={[...routeRoles.Admins, ...routeRoles.Instructors]}
            exact
          />
          <AuthRoute
            path={`/${medicalStationName}/:planId/:stationId/:trainingTypeId`}
            Component={MedicalStation}
            requiredRoles={[...routeRoles.Admins, ...routeRoles.Instructors]}
            exact
          />
          <AuthRoute
            path={`/${combatFitnessStationName}/:planId/:stationId/:trainingTypeId`}
            Component={CombatFitnessStation}
            requiredRoles={[...routeRoles.Admins, ...routeRoles.Instructors]}
            exact
          />
          <AuthRoute
            path={`/${magnetFOFStationName}/:planId/:stationId/:trainingTypeId`}
            Component={MagnetDataPosting}
            requiredRoles={[...routeRoles.Admins, ...routeRoles.Instructors]}
            exact
          />

          <AuthRoute
            path="/station/:stationName/:stationId/:trainingTypeId/:isManual"
            Component={StationsTraineesList}
            requiredRoles={[...routeRoles.Admins, ...routeRoles.Instructors]}
            exact
          />
          <AuthRoute
            path={`${URLs.stations.shootingRange.plans.shootingDays.getURL()}`}
            Component={ShootingDaySelectionScreen}
            requiredRoles={[...routeRoles.Admins, ...routeRoles.Instructors]}
            exact
          />
          <AuthRoute
            path={`${URLs.stations.shootingRange.plans.shootingDays.heats.getURL()}`}
            Component={HeatSelectionScreen}
            requiredRoles={[...routeRoles.Admins, ...routeRoles.Instructors]}
            exact
          />
          <AuthRoute
            path={`${URLs.stations.shootingRange.plans.shootingDays.heats.results.getURL()}`}
            Component={ResultsScreen}
            requiredRoles={[...routeRoles.Admins, ...routeRoles.Instructors]}
            exact
          />
          <AuthRoute
            path={`${URLs.stations.shootingRange.plans.getURL()}`}
            Component={PlanSelectionScreen}
            requiredRoles={[...routeRoles.Admins, ...routeRoles.Instructors]}
            exact
          />
          <Route
            path="/"
            render={() => (
              <Redirect
                to={
                  appMode === EAppMode.FIRE_SUPPORT || //mobile: navigate to data posting on FIRE_SUPPORT or HASIFA_TKIFA  app_mode
                  appMode === EAppMode.HASIFA_TKIFA
                    ? isPlatform("tablet") && appMode === EAppMode.FIRE_SUPPORT
                      ? mobileRoutes.fireSupport
                      : mobileRoutes.stations
                    : user.relatedForce
                    ? "/personal"
                    : "/performance"
                }
              />
            )}
            exact
          />

          <Route
            path={`${URLs.stations.shootingRange.getURL()}`}
            render={() => (
              <Redirect
                to={`${URLs.stations.shootingRange.plans.shootingDays.getURL()}`}
              />
            )}
            exact
          />
          <AuthRoute
            path={`${mobileRoutes.singleGunner}/:trainingTypeId`}
            Component={SingleGunner}
            requiredRoles={[...routeRoles.Admins, ...routeRoles.Instructors]}
            exact
          />
          <AuthRoute
            path={`${mobileRoutes.observer}/:trainingTypeId`}
            Component={Observer}
            requiredRoles={[...routeRoles.Admins, ...routeRoles.Instructors]}
            exact
          />
          <AuthRoute
            path={`${mobileRoutes.frameTraining}/:trainingTypeId`}
            Component={FrameTraining}
            requiredRoles={[...routeRoles.Admins, ...routeRoles.Instructors]}
            exact
          />
          <AuthRoute
            path={`${mobileRoutes.gorillaInitTraining}/:stationName/:trainingTypeId`}
            Component={GorillaInitTraining}
            requiredRoles={[...routeRoles.Admins, ...routeRoles.Instructors]}
            exact
          />
          <AuthRoute
            path={mobileRoutes.sitStatus}
            Component={() => <SiteStatus></SiteStatus>}
            requiredRoles={[
              ...routeRoles.Admins,
              ...routeRoles.Instructors,
              ...routeRoles.Viewers,
            ]}
            exact
          />
          <AuthRoute
            path={`${mobileRoutes.fireSupport}/:planId/:stationId/:trainingTypeId`}
            Component={() => <MobileFIreSupport />}
            requiredRoles={[...routeRoles.Admins, ...routeRoles.Instructors]}
            exact
          />
          <AuthRoute
            path={`${mobileRoutes.fireSupport}`}
            Component={() => <TabletFireSupport />}
            requiredRoles={[...routeRoles.Admins, ...routeRoles.Instructors]}
            exact
          />
          <AuthRoute
            path={`${mobileRoutes.billiard}/:planId/:stationId/:trainingTypeId`}
            Component={() => <Billiard />}
            requiredRoles={[
              ...routeRoles.Admins,
              ...routeRoles.Viewers,
              ...routeRoles.Instructors,
            ]}
            // exact
          />
        </IonRouterOutlet>
        <IonTabBar slot="bottom" className="mobileTabs ">
          {getMobileUserTabs().map((item: TabItem, index: number) => {
            return (
              <IonTabButton
                className={`${
                  location.pathname === item.url ? "tab-selected" : ""
                } tab-button`}
                tab={item.title}
                href={item.url}
                key={index}
              >
                <PMIcon
                  isButton={false}
                  iconSrc={item.icon!}
                  size="large"
                  color={
                    !(location.pathname === item.url) ? "xLight" : "orange"
                  }
                />
                <PMLabel
                  textAlign="center"
                  fontSize="small"
                  fontFamily="Light"
                  fontColor={
                    !(location.pathname === item.url) ? "xLight" : "orange"
                  }
                  cssClass="tabLabelMObile"
                >
                  {item.title}
                </PMLabel>
              </IonTabButton>
            );
          })}
        </IonTabBar>
      </IonTabs>
    </>
  );
};

export default Tabs;
