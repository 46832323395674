// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lastSiteSyncPopover {
  display: flex;
  width: 200px;
  align-items: center;
  flex-direction: column;
}

.labelSpan {
  height: fit-content;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/LastSiteSync/LastSiteSync.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".lastSiteSyncPopover {\n  display: flex;\n  width: 200px;\n  align-items: center;\n  flex-direction: column;\n}\n\n.labelSpan {\n  height: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
