import { FC, useContext, useEffect, useState } from "react";
import {
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { useLocation } from "react-router-dom";
import { Redirect, Route } from "react-router";
import CommanderDashboard from "./PerformanceTabs/CommanderDashboard/CommanderDashboard";
import PerformanceIndicators from "./PerformanceTabs/PerformanceIndicators/PerformanceIndicators";
import PerformanceStations from "./PerformanceTabs/PerformanceDetails/PerformanceStations";
import PerformanceLeaders from "./PerformanceTabs/PerformanceLeaders/PerformanceLeaders";
import TabItem from "../../../Interfaces/ITabItem";
import PerformanceTrends from "./PerformanceTabs/PerformanceTrends/PerformanceTrends";
import { useTranslation } from "react-i18next";
import IForceTreeNode from "../../../Interfaces/IForceTreeNode";
import { exportToPdfAndCsv } from "../../../components/Desktop/ToExcel/exportToCsv";
import { UserContext } from "../../../context/UserContext/userContext";
import { getForceFullName } from "../../../services/helpers";
import IPlan from "../../../Interfaces/IPlan";
import useGetPerformanceGrades from "../../../components/CustomHooks/useGetPerformanceGrades";
import useForcesChecked from "../../../components/CustomHooks/useForcesChecked";
import useInitGraphsData from "../../../components/CustomHooks/useInitGraphsData";
import { IForceAllData } from "../../../Interfaces/IForceAllData";
import { routes, userRoles } from "../../../services/routeRoles";
import { pageview } from "react-ga";
import { ITrainingPlanResult } from "../../../Interfaces/dataCalculator";
import usePlans from "../../../components/CustomHooks/usePlans";
import { toPng } from "html-to-image";
import "./PerformanceDisplay.css";
import PMLabel from "../../../components/themeComponents/PMLabel";
import { ForcesContextDesktop } from "../../../context/ForcesContext/forcesContextProviderDesktop";
import Hashtags from "./PerformanceTabs/Hashtags/Hashtags";
import EExportType from "../../../Interfaces/EExportType";
import EExportMethod from "../../../Interfaces/EExportMethod";
import { exportToPdf } from "../../../components/Desktop/ToExcel/exportToPdf";
import { competencyRoutes } from "../../../services/competencyRoutes";

// defaults.global.defaultFontFamily = "Regular";

const PerformanceDisplay: FC = (): JSX.Element => {
  const { isAdmin } = useContext(UserContext);
  const {
    orbatForces,
    setOrbatForces,
    competencySelectedPlan,
    setCompetencySelectedPlan,
    setCompetencyPath,
    competencyPath,
  } = useContext(ForcesContextDesktop);
  const [loadingDetails, setLoadingDetails] = useState<boolean>(false);
  const [loadingCompetency, setCompetencyLoading] = useState<boolean>(false);
  const [fromTab, setFromTab] = useState<boolean>(true);
  const [selectedPlanHolder, setSelectedPlanHolder] = useState<IPlan>();

  const { user, isTaggingActive } = useContext(UserContext);
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(() => {
    if (isTaggingActive) return false;
    else return true;
  });

  const [isToDisplayDashboard, setIsToDisplayDashboard] =
    useState<boolean>(true);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const location = useLocation();

  const { t, i18n } = useTranslation();

  document.body.dir = i18n.dir();

  const PerformanceTabMenu: TabItem[] = [
    {
      url: `${routes.performance}${competencyRoutes.indicators}`,
      title: t("indicators"),
    },
    {
      url: `${routes.performance}${competencyRoutes.stations}`,
      title: t("stations"),
    },

    {
      url: `${routes.performance}${competencyRoutes.commanderDashboard}`,
      title: t("commanderDashboard"),
    },
    {
      url: `${routes.performance}${competencyRoutes.hashtags}`,
      title: t("hashtags"),
    },
    {
      url: `${routes.performance}${competencyRoutes.leaders}`,
      title: t("leaders"),
    },
    {
      url: `${routes.performance}${competencyRoutes.trends}`,
      title: t("trends"),
    },
  ];

  const {
    checkedForces,
    forcesToRemove,
    newForces,
    setForceTaggingRemoved,
    relevantPlansIds,
  } = useForcesChecked(orbatForces);
  const {
    onPlanSelectedHandler,
    plansOptions,
    selectedPlan,
    plans,
    plansValuesForSelectedPlan,
  } = usePlans(relevantPlansIds, true, true);

  //set the selected plan as the saved plan
  useEffect(() => {
    if (competencySelectedPlan) {
      onPlanSelectedHandler(competencySelectedPlan);
    }
    // eslint-disable-next-line
  }, [competencySelectedPlan]);
  //save the selected plan into context
  useEffect(() => {
    if (selectedPlan) setCompetencySelectedPlan(selectedPlan);
    // eslint-disable-next-line
  }, [selectedPlan]);

  useEffect(() => {
    pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    let isToDisplayDashboard =
      user.role === userRoles.Admin ||
      (!user.relatedForce?.is_soldier &&
        user.forceToDisplayInOrbat?.forceType !== "כיתה" &&
        user.forceToDisplayInOrbat?.forceType !== "קבוצה");
    setIsToDisplayDashboard(isToDisplayDashboard);
  }, [user]);

  const {
    labelsCompetency,
    labelsTrends,
    thresholdsCompetency,
    thresholdsTrends,
    trendsIndicators,
    labelsDetails,
    thresholdsDetails,
  } = useInitGraphsData(plansValuesForSelectedPlan, plans);

  const {
    indicatorsGrades,
    activePlanFinalGrades,
    trainingTypesGrades,
    planDetailsFinalGrades,
    setIsToRefreshCompetency,
    setIsToRefreshDetails,
    forcesResults,
  } = useGetPerformanceGrades(
    selectedPlan,
    checkedForces,
    newForces,
    forcesToRemove,
    setCompetencyLoading,
    setLoadingDetails,
    selectedPlanHolder
  );

  const exportToCsv = async (
    image: string,
    exportType: EExportType,
    exportMethod: EExportMethod
  ) => {
    if (checkedForces.length === 0) return;
    setDownloadLoading(true);
    let forcesData: IForceAllData[] = [];
    let imagesCompBadge: HTMLCollectionOf<Element>;
    let imagesArrayBadge = [];
    let allImagesBadge: string[] = [];

    imagesCompBadge = document.getElementsByClassName("csvBadgeImage");
    imagesArrayBadge = Array.prototype.slice.call(imagesCompBadge);
    await Promise.all(
      imagesArrayBadge?.map(async (element: HTMLElement) => {
        allImagesBadge.push(await toPng(element));
      })
    );
    allImagesBadge = allImagesBadge.filter((image) =>
      image.includes("image/png;base64")
    );
    orbatForces.forEach((force, index) => {
      let forceData: ITrainingPlanResult | undefined = forcesResults?.find(
        (forceResult) => Number(forceResult.forceId) === Number(force.id)
      );
      if (forceData)
        forcesData.push({
          forceDetails: force,
          forceGradesData: {
            isSoldier: force.is_soldier,
            forceResults: forceData,
            affiliation: "",
            isAttended: forceData.grade !== null,
            pic1: exportType === EExportType.indicators ? image : undefined,
            pic2: exportType === EExportType.stations ? image : undefined,
            pic3: allImagesBadge[index],
          },
        });
    });
    if (exportMethod === EExportMethod.excel)
      await exportToPdfAndCsv(forcesData, selectedPlan, t);
    if (
      exportMethod === EExportMethod.pdfDarkMode ||
      exportMethod === EExportMethod.pdfLightMode
    )
      await exportToPdf(
        forcesData,
        selectedPlan,
        t,
        exportMethod === EExportMethod.pdfLightMode
      );

    setDownloadLoading(false);
  };

  const tabClicked = (data: any) => {
    if (selectedPlanHolder && selectedPlanHolder.id !== selectedPlan?.id) {
      onPlanSelectedHandler(selectedPlanHolder);
    }
    if (data?.detail?.tab === t("stations")) setFromTab(true);
    else setFromTab(false);
  };

  const onCheckForce = async (forces: IForceTreeNode[]) => {
    setOrbatForces(
      forces.map((force: IForceTreeNode) => {
        let currName: string = force.name;
        currName = currName.includes(force.force_type)
          ? currName
          : getForceFullName(force.name, force.force_type, t);

        return {
          ...force,
          name: currName,
        };
      })
    );
  };
  const exportHandler = () => {
    window.open("/performance/reportModal", "_blank");
  };

  return (
    <div className="perform-grid">
      <IonTabs
        onIonTabsWillChange={tabClicked}
        className="pm-tabs full-opacity"
      >
        <IonRouterOutlet
          id="main"
          className="routerOutlet"
          style={{ margin: 0, marginInlineStart: 0 }}
        >
          <Route
            path="/"
            render={() => <Redirect to={`${routes.performance}`} />}
            exact={true}
          />
          <Route
            path={`${routes.performance}`}
            render={() =>
              isTaggingActive ? (
                <Redirect
                  to={`${routes.performance}${competencyRoutes.stations}`}
                />
              ) : (
                <Redirect
                  to={
                    competencyPath
                      ? competencyPath
                      : `${routes.performance}${competencyRoutes.indicators}`
                  }
                />
              )
            }
            exact={true}
          />
          <Route
            path={`${routes.performance}${competencyRoutes.commanderDashboard}`}
            exact={true}
            render={() =>
              !isToDisplayDashboard ? (
                <Redirect
                  to={`${routes.performance}${competencyRoutes.indicators}`}
                />
              ) : (
                <CommanderDashboard
                  exportToCsv={exportToCsv}
                  isAdmin={isAdmin}
                  isDrawerOpen={isDrawerOpen}
                  setDrawerOpen={setDrawerOpen}
                />
              )
            }
          />
          <Route
            path={`${routes.performance}${competencyRoutes.indicators}`}
            exact={true}
            render={() => (
              <PerformanceIndicators
                setChecked={onCheckForce}
                checkedForces={checkedForces}
                orbatForces={orbatForces}
                exportToCsv={exportToCsv}
                isAdmin={isAdmin}
                exportHandler={exportHandler}
                downloadLoading={downloadLoading}
                labels={labelsCompetency}
                thresholds={thresholdsCompetency}
                plan={selectedPlan!}
                indicatorsGrades={indicatorsGrades}
                finalGrades={activePlanFinalGrades}
                forcesToRemove={forcesToRemove}
                newForces={newForces ? newForces : checkedForces}
                loading={loadingCompetency}
                setIsToRefresh={setIsToRefreshCompetency}
                onPlanSelectedHandler={onPlanSelectedHandler}
                allPlansOptions={plansOptions}
                isDrawerOpen={isDrawerOpen}
                setDrawerOpen={setDrawerOpen}
              />
            )}
          />
          <Route
            path={`${routes.performance}${competencyRoutes.stations}`}
            render={() => (
              <PerformanceStations
                setChecked={onCheckForce}
                checkedForces={checkedForces}
                orbatForces={orbatForces}
                exportToCsv={exportToCsv}
                fromTab={fromTab}
                isAdmin={isAdmin}
                exportHandler={exportHandler}
                downloadLoading={downloadLoading}
                setLoading={setLoadingDetails}
                labels={labelsDetails}
                thresholds={thresholdsDetails}
                trainingTypesGrades={trainingTypesGrades}
                finalGrades={planDetailsFinalGrades}
                selectedPlan={selectedPlan!}
                forcesToRemove={forcesToRemove}
                newForces={newForces ? newForces : checkedForces}
                loading={loadingDetails}
                setIsToRefresh={setIsToRefreshDetails}
                setForceTaggingRemoved={setForceTaggingRemoved}
                allPlansOptions={plansOptions}
                onPlanSelectedHandler={onPlanSelectedHandler}
                isDrawerOpen={isDrawerOpen}
                setDrawerOpen={setDrawerOpen}
                setSelectedPlanHolder={setSelectedPlanHolder}
              />
            )}
            exact={true}
          />
          <Route
            path={`${routes.performance}${competencyRoutes.hashtags}`}
            render={() => (
              <Hashtags
                selectedPlan={selectedPlan!}
                allPlansOptions={plansOptions}
                onPlanSelectedHandler={onPlanSelectedHandler}
                downloadLoading={downloadLoading}
              ></Hashtags>
            )}
            exact={true}
          />
          <Route
            path={`${routes.performance}${competencyRoutes.leaders}`}
            exact={true}
            render={() => (
              <PerformanceLeaders
                selectedPlan={selectedPlan!}
                isPersonalZone={
                  user.relatedForce !== undefined &&
                  user.role !== userRoles.Admin
                }
                setChecked={onCheckForce}
                checkedForces={checkedForces}
                forcesToRemove={forcesToRemove}
                newForces={newForces}
                orbatForces={orbatForces}
                allPlansOptions={plansOptions}
                onPlanSelectedHandler={onPlanSelectedHandler}
                isDrawerOpen={isDrawerOpen}
                setDrawerOpen={setDrawerOpen}
              />
            )}
          />
          <Route
            path={`${routes.performance}${competencyRoutes.trends}`}
            render={() => (
              <PerformanceTrends
                checkedForces={checkedForces}
                orbatForces={orbatForces}
                setChecked={onCheckForce}
                isAdmin={isAdmin}
                plan={selectedPlan!}
                thresholds={thresholdsTrends}
                labels={labelsTrends} //if we want relevant plans just switch to relevantLabelsTrends
                forcesToRemove={forcesToRemove}
                newForces={newForces ? newForces : checkedForces}
                finalGrades={activePlanFinalGrades}
                indicators={trendsIndicators}
                isDrawerOpen={isDrawerOpen}
                setDrawerOpen={setDrawerOpen}
              />
            )}
            exact={true}
          />
        </IonRouterOutlet>

        <IonTabBar
          slot="top"
          className="ion-justify-content-start PRTabs"
          aria-disabled="true"
        >
          {PerformanceTabMenu.map((item: TabItem, index: number) => {
            return item.url ===
              `${routes.performance}${competencyRoutes.commanderDashboard}` &&
              !isToDisplayDashboard ? null : (
              <IonTabButton
                className={location.pathname === item.url ? "tab-selected" : ""}
                tab={item.title}
                href={item.url}
                key={index}
                onClick={() => {
                  setCompetencyPath(item.url);
                }}
              >
                <PMLabel
                  fontColor="light"
                  fontFamily="Light"
                  fontSize="medium"
                  textAlign="center"
                >
                  {t(item.title?.trim())}
                </PMLabel>
              </IonTabButton>
            );
          })}
        </IonTabBar>
      </IonTabs>
    </div>
  );
};

export default PerformanceDisplay;
