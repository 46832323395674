import axios, { CancelTokenSource } from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isCancelledError, useQuery } from "@tanstack/react-query";
import Axios from "../../../Axios";
import { baseUrlPMBackend } from "../../../Configurations/consts";
import {
  IElementsPlanValue,
  IIndicatorsPlanValue,
  IPlanValue,
  IPlanValues,
  ITrainingTypesPlanValue,
} from "../../../Interfaces/IPlanValues";
import customToast from "../../Shared/Toast/CustomToast";
/**
 *
 * @returns array with of elements
 */
export const usePlanValuesForSelectedPlan = (
  planId?: number,
  isAllPlans?: boolean
) => {
  const { t } = useTranslation();
  const [plansValuesForSelectedPlan, setPlansValuesForSelectedPlan] =
    useState<IPlanValues>();

  const [cancelTokenSource, setCancelTokenSource] = useState<
    CancelTokenSource | undefined
  >(undefined);

  const asyncFetchPlansValuesForPlan = async (
    planId: number,
    isAllPlans?: boolean
  ) => {
    const cancelMessage = "Request canceled due to new request";
    if (cancelTokenSource) {
      cancelTokenSource.cancel(cancelMessage);
    }
    const source = axios.CancelToken.source();
    setCancelTokenSource(source);
    const res = await Axios.get(`${baseUrlPMBackend}plans/getPlansValues`, {
      params: {
        planId: planId,
        isAllPlans: isAllPlans,
      },
      cancelToken: source.token,
    }).catch((e: any) => {
      if (e.message !== cancelMessage)
        customToast.error(t("errorWhileLoadingPlanValues"));
    });
    return res?.data || [];
  };
  let { data, isLoading, isError, error, refetch } = useQuery<IPlanValue[]>({
    queryKey: ["plansValues", planId],
    queryFn: async () => {
      try {
        return await asyncFetchPlansValuesForPlan(planId!, isAllPlans);
      } catch (error) {
        if (!axios.isCancel(error)) {
          throw error;
        }
      }
    },
    enabled:
      planId !== undefined && planId !== null && isAllPlans !== undefined,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!isLoading && !isError && data) {
      //gets th indicators plans values
      let indicatorsPlanValues: IIndicatorsPlanValue[] = data
        .filter((planValue) => !!planValue.indicatorId)
        .map((planValue) => ({
          indicatorId: Number(planValue.indicatorId),
          weight: Math.floor(planValue.weight),
          threshold: planValue.threshold,
          valueId: Number(planValue.valueId),
        }));

      //gets the training type plans values
      let trainingTypesPlanValues: ITrainingTypesPlanValue[] = data
        .filter((planValue) => !!planValue.trainingTypeId)
        .map((planValue) => ({
          trainingTypeId: Number(planValue.trainingTypeId),
          weight: Math.floor(planValue.weight),
          threshold: planValue.threshold,
        }));

      //gets the elements plans values
      let elementsPlanValues: IElementsPlanValue[] = data
        .filter((planValue) => !!planValue.elementId)
        .map((planValue) => ({
          elementId: Number(planValue.elementId),
          weight: Math.floor(planValue.weight),
          lowerThreshold: planValue.lowerThreshold,
          upperThreshold: planValue.upperThreshold,
          requiredThreshold: planValue.requiredThreshold,
          isForDashboard: planValue.isForDashboard,
        }));

      setPlansValuesForSelectedPlan({
        indicatorsPlanValues: indicatorsPlanValues,
        trainingTypesPlanValues: trainingTypesPlanValues,
        elementsPlanValues: elementsPlanValues,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { plansValuesForSelectedPlan, refetch };
};
