import React, { ReactNode, useState } from "react";
import ITrainingType from "../../Interfaces/ITrainingType";
interface ITrainingTypesContext {
  trainingTypes: ITrainingType[];
  setTrainingTypes: React.Dispatch<React.SetStateAction<ITrainingType[]>>;
}

export const TrainingTypesContext = React.createContext<ITrainingTypesContext>({
  trainingTypes: [],
  setTrainingTypes: () => {},
});

const TrainingTypesProvider: React.FC<{ children: ReactNode }> = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [trainingTypes, setTrainingTypes] = useState<ITrainingType[]>([]);

  return (
    <TrainingTypesContext.Provider
      value={{
        trainingTypes,
        setTrainingTypes,
      }}
    >
      {children}
    </TrainingTypesContext.Provider>
  );
};

export default TrainingTypesProvider;
