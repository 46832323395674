import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./SingleGunnerComponent.css";

import SystemEncryption from "./SystemEncryption";
import TargetFeature from "./TargetProperties";
import MalfunctionOperation from "./MalfunctionOperation";
import ShootingExecution from "./ShootingExecution";
import PMLabel from "../../../themeComponents/PMLabel";
import NavigationRow from "../NavigationRow";
import Steps from "../Steps";
import WarningRow from "../WarningRow";
import { IShootingExecution } from "../../../../Interfaces/Gorilla/IShootingExecution";
import { ITargetsPropertiesResults } from "../../../../Interfaces/Gorilla/ITargetProperties";
import { ISystemEncryption } from "../../../../Interfaces/Gorilla/ISystemEncryption";
import {
  ESingleGunnerSteps,
  ISingleGunnerStep,
} from "../../../../Interfaces/Gorilla/IHeatTemplate";
import { ELuncherTypes } from "../../../../Enums/EHasifaTkifa";

interface IProps {
  activeHeatId: number;
  malfunctionsOperation: IMalfunctionOperationOptions;
  shootingExecution: IShootingExecution;
  systemEncryption: ISystemEncryption;
  saveHeat: () => void;
  setMalfunctionOperation: Dispatch<
    SetStateAction<IMalfunctionOperationOptions>
  >;
  setShootingExecution: Dispatch<SetStateAction<IShootingExecution>>;
  setTargetsPropertiesResults: Dispatch<
    SetStateAction<ITargetsPropertiesResults>
  >;
  setSystemEncryption: Dispatch<SetStateAction<ISystemEncryption>>;
  targetsPropertiesResults: ITargetsPropertiesResults;
  setCurrentStepType: Dispatch<SetStateAction<ESingleGunnerSteps>>;
  currentStepType: ESingleGunnerSteps;
  isLastHeat: boolean;
  traineeName: string | undefined;
  singleGunnerSteps: Record<ESingleGunnerSteps, ISingleGunnerStep>;
  setSingleGunnerSteps: React.Dispatch<
    React.SetStateAction<Record<ESingleGunnerSteps, ISingleGunnerStep>>
  >;
  selectedLuncherType: ELuncherTypes;
}

const SingleGunnerComponent: FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    malfunctionsOperation,
    saveHeat,
    setMalfunctionOperation,
    setShootingExecution,
    setSystemEncryption,
    setTargetsPropertiesResults,
    shootingExecution,
    systemEncryption,
    targetsPropertiesResults,
    activeHeatId,
    currentStepType,
    setCurrentStepType,
    isLastHeat,
    singleGunnerSteps,
    selectedLuncherType,
  } = props;

  const steps: Partial<
    Record<ESingleGunnerSteps, { component: () => JSX.Element }>
  > = {
    [ESingleGunnerSteps.SystemEncryption]: {
      component: () => (
        <SystemEncryption
          setSystemEncryption={setSystemEncryption}
          systemEncryption={systemEncryption}
          isMobile
          selectedLuncherType={selectedLuncherType}
        ></SystemEncryption>
      ),
    },
    [ESingleGunnerSteps.TargetsProperties]: {
      component: () => (
        <TargetFeature
          setTargetsPropertiesResults={setTargetsPropertiesResults}
          targetsPropertiesResults={targetsPropertiesResults}
          isMobile
        />
      ),
    },
    [ESingleGunnerSteps.MalfunctionOperation]: {
      component: () => (
        <MalfunctionOperation
          malfunctionsOperation={malfunctionsOperation}
          setMalfunctionOperation={setMalfunctionOperation}
        ></MalfunctionOperation>
      ),
    },
    [ESingleGunnerSteps.ShootingExecution]: {
      component: () => (
        <ShootingExecution
          malfunctionsOperation={malfunctionsOperation}
          setMalfunctionOperation={setMalfunctionOperation}
          setShootingExecution={setShootingExecution}
          shootingExecution={shootingExecution}
          setSystemEncryption={setSystemEncryption}
          systemEncryption={systemEncryption}
        ></ShootingExecution>
      ),
    },
  };

  return (
    <>
      <div className="headerGorilla">
        <PMLabel
          fontColor="light"
          fontFamily="Bold"
          fontSize="xLarge"
          cssClass="traineeNameSG"
        >
          {props.traineeName}
        </PMLabel>

        <Steps
          activeStep={currentStepType}
          steps={Object.values(singleGunnerSteps)
            .filter((step) => step.id)
            .map((step) => ({
              id: step.id,
              title: t(step.title),
              isCompleted: step.isCompleted,
            }))}
        ></Steps>
        <div className="titleRowGorilla">
          <PMLabel fontColor="light" fontFamily="Regular" fontSize="medium">
            {t(
              singleGunnerSteps[currentStepType].description
                ? singleGunnerSteps[currentStepType].description
                : singleGunnerSteps[currentStepType].title
            )}
          </PMLabel>
        </div>
      </div>
      <div
        className="contentSingleGunner"
        key={`${activeHeatId} ${currentStepType}`}
      >
        {steps[currentStepType]?.component()}
      </div>
      <div className="footer">
        <WarningRow
          isToDisplay={
            !!singleGunnerSteps[currentStepType].isMustCompleted &&
            !singleGunnerSteps[currentStepType].isCompleted
          }
        />
        <NavigationRow
          isNextDisabled={
            singleGunnerSteps[currentStepType].isMustCompleted &&
            !singleGunnerSteps[currentStepType].isCompleted
          }
          isPrevHidden={currentStepType === 0}
          isLastStep={currentStepType === Object.keys(steps).length - 1}
          onNextHandler={() => {
            if (currentStepType === Object.keys(steps).length - 1) saveHeat();
            else
              setCurrentStepType((prev: number) =>
                Math.min(prev + 1, Object.keys(steps).length - 1)
              );
          }}
          onPrevHandler={() => {
            setCurrentStepType((prev: number) => Math.max(prev - 1, 0));
          }}
          isLastHeat={isLastHeat}
        ></NavigationRow>
      </div>
    </>
  );
};

export default SingleGunnerComponent;
