import React, { useEffect, useState } from "react";

import "./InstructorFeedbackGrade.css";
import PMLabel from "../../../themeComponents/PMLabel";
import { useTranslation } from "react-i18next";
import customToast from "../../Toast/CustomToast";

export interface IProps {
  onGradeSelected: (grade: number | undefined) => void;
  isToRest: boolean;
  setIsToRest: (state: boolean) => void;
  isTraineeSelected: boolean;
  resultsOptions?: (string | number)[];
}
const InstructorFeedbackGrade: React.FC<IProps> = (
  props: IProps
): JSX.Element => {
  const { t } = useTranslation();
  const [selectedGrade, setSelectedGrade] = useState<
    string | number | "failed" | "average" | "good" | "excellent" | undefined
  >();
  useEffect(() => {
    setSelectedGrade(undefined);
    props.setIsToRest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isToRest]);

  useEffect(() => {
    if (selectedGrade && !props.isTraineeSelected) {
      customToast.error(t("noSelectedSoldier"));
    }
    let grade;
    switch (selectedGrade) {
      case "failed":
        grade = 30;
        break;
      case "average":
        grade = 50;
        break;

      case "good":
        grade = 70;
        break;

      case "excellent":
        grade = 100;
        break;
      default:
        grade = Number(selectedGrade);
    }
    props.onGradeSelected(grade);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGrade]);

  const gradesOptions = ["failed", "average", "good", "excellent"];
  return (
    <div className="gradesRow">
      {(props.resultsOptions?.length
        ? props.resultsOptions
        : gradesOptions
      ).map((option, index) => (
        <span
          key={index}
          className={`gradeButton ${option} ${
            gradesOptions[index] ? gradesOptions[index] : "ok"
          } ${
            selectedGrade !== undefined &&
            String(selectedGrade) === String(option)
              ? "selectedGrade"
              : ""
          }`}
          onClick={() => {
            setSelectedGrade((prev) => {
              return prev !== undefined && String(prev) === String(option)
                ? undefined
                : String(option);
            });
          }}
        >
          <PMLabel
            fontColor="dark"
            textAlign="center"
            fontFamily={
              selectedGrade && String(selectedGrade) === String(option)
                ? "Bold"
                : "Regular"
            }
          >
            {t(option)}
          </PMLabel>
        </span>
      ))}
    </div>
  );
};

export default InstructorFeedbackGrade;
