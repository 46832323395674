import ISelectionOption from "./ISelectionOption";

export interface ISelection {
  label: string;
  id: number;
  isSelected: boolean;
}
export interface IIndicatorsSelection {
  label: string;
  id: number;
  trainingTypeSelections: ISelection[];
  isSelected: boolean;
}
export enum EChartId {
  PERFORMANCE_DETAILS = 1,
  RADAR = 2,
  LETHALITY = 3,
  TRENDS = 4,
  HASHTAGS = 5,
}
export interface IChartsSelection {
  name: string;
  isSelected: boolean;
  chartId: EChartId;
}

export interface ITooltipsOptions {
  trainingTypeName: string;
  trainingTypeId: number;
  selectionsOptions: ISelectionOption[];
  isSelected: boolean;
}
