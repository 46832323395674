// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion-list {
  width: 100%;
  overflow-y: auto;
}
.mobile_ss_list_row {
  width: calc(100% - 24px);
  height: 90%;
  overflow-y: auto;
  margin-inline-start: 16px;
  margin-inline-end: 8px;
  margin-top: 10px;
}
.siteStatusTitle {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 15px;
  padding-inline-start: 17px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Mobile/SiteStatus/SiteStatus.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,wBAAwB;EACxB,WAAW;EACX,gBAAgB;EAChB,yBAAyB;EACzB,sBAAsB;EACtB,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,iBAAiB;EACjB,0BAA0B;AAC5B","sourcesContent":[".accordion-list {\n  width: 100%;\n  overflow-y: auto;\n}\n.mobile_ss_list_row {\n  width: calc(100% - 24px);\n  height: 90%;\n  overflow-y: auto;\n  margin-inline-start: 16px;\n  margin-inline-end: 8px;\n  margin-top: 10px;\n}\n.siteStatusTitle {\n  width: 100%;\n  display: flex;\n  align-items: flex-start;\n  padding-top: 15px;\n  padding-inline-start: 17px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
