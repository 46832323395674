import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import Axios from "../../../Axios";
import { baseUrlPMBackend } from "../../../Configurations/consts";
import { ShootingRangeContext } from "../../../context/ShootingRangeContext/ShootingRangeContext";
import IShootingHeat from "../../../Interfaces/IShootingHeat";
import customToast from "../../Shared/Toast/CustomToast";

const getAllHeats = async () =>
  (
    await Axios.get(
      `${baseUrlPMBackend}stations/shooting-range/getShootingRangeHeats`
    )
  ).data;

export const useShootingRangeHeats = (shootingDayId?: number) => {
  const { t } = useTranslation();
  const { heats, setHeats } = useContext(ShootingRangeContext);
  const [heatsByShootingDay, setHeatsByShootingDay] = useState<IShootingHeat[]>(
    []
  );

  let { data, isLoading, isError } = useQuery<IShootingHeat[]>({
    queryKey: ["shootingRangeHeats"],
    queryFn: getAllHeats,
    enabled: !heats.length,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!isLoading && !isError && data && data.length) {
      //set the context with the force types data
      setHeats(
        data
          .map((heat) => ({ ...heat, name: t(heat.name), id: Number(heat.id) }))
          .sort(
            (heat1: IShootingHeat, heat2: IShootingHeat) => heat1.id - heat2.id
          )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (isError) {
      customToast.error(t("errorWhileLoadingHeats"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    setHeatsByShootingDay(
      heats.filter((heat) => +heat.shootingDayId === Number(shootingDayId))
    );
  }, [shootingDayId, heats]);

  return { heats, heatsByShootingDay };
};
