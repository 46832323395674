// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scaBtn {
  max-height: 30px;
  margin-inline-end: 10px;
}
.accordionMainWrapMulti {
  width: 300px;
  box-shadow: var(--box-shadow);
  max-width: 100%;
}
.pmAccordionContentMulti {
  z-index: 10 !important;
  height: fit-content;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
  background-color: var(--ion-color-highlighted);
}
.selectionRow,
.buttonsRow {
  --background: var(--ion-color-highlighted);
  --padding-start: 16px;
}
.multiSelectionList {
  padding: 0;
  background-color: var(--ion-color-highlighted);
}
.selectionRow:hover {
  --background: var(--ion-color-selected);
}
.selections {
  overflow-y: auto;
  max-height: 250px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/MultySelectDropdown/MultiSelectDropdown.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,uBAAuB;AACzB;AACA;EACE,YAAY;EACZ,6BAA6B;EAC7B,eAAe;AACjB;AACA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,aAAa;EACb,8CAA8C;AAChD;AACA;;EAEE,0CAA0C;EAC1C,qBAAqB;AACvB;AACA;EACE,UAAU;EACV,8CAA8C;AAChD;AACA;EACE,uCAAuC;AACzC;AACA;EACE,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".scaBtn {\n  max-height: 30px;\n  margin-inline-end: 10px;\n}\n.accordionMainWrapMulti {\n  width: 300px;\n  box-shadow: var(--box-shadow);\n  max-width: 100%;\n}\n.pmAccordionContentMulti {\n  z-index: 10 !important;\n  height: fit-content;\n  min-height: 250px;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  padding: 10px;\n  background-color: var(--ion-color-highlighted);\n}\n.selectionRow,\n.buttonsRow {\n  --background: var(--ion-color-highlighted);\n  --padding-start: 16px;\n}\n.multiSelectionList {\n  padding: 0;\n  background-color: var(--ion-color-highlighted);\n}\n.selectionRow:hover {\n  --background: var(--ion-color-selected);\n}\n.selections {\n  overflow-y: auto;\n  max-height: 250px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
