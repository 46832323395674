// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drawer-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.drawer-div-inside-container {
  width: 100%;
  margin-top: 5vh;
}
ion-datetime {
  --background: var(--ion-color-dark);
  color: var(--ion-color-orange);
}

.drawer-buttons-wrapper {
  width: 100%;
  background-color: var(--ion-color-header);
  --color: var(--ion-color-orange);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
}

.drawer-solid-button {
  margin-inline-start: 1vw;
  margin-inline-end: 1vw;
  margin-block-start: 2vh;
  height: 4.8vh;
  width: 45%;
  --color: var(--ion-color-fontXLight);
  --background: var(--ion-color-shooting-range-button);
  --border-radius: 4px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
}

.drawer-outline-button {
  margin-inline-start: 1vw;
  margin-inline-end: 1vw;
  margin-block-start: 2vh;
  height: 4.8vh;
  width: 45%;
  --color: var(--ion-color-fontXLight);
  --border-color: var(--ion-color-shooting-range-button);
  --border-radius: 4px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
}

.drawer-header-bottom-padding {
  padding-bottom: 1vh;
}
`, "",{"version":3,"sources":["webpack://./src/components/Mobile/DateDrawer/DateDrawer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;AACA;EACE,mCAAmC;EACnC,8BAA8B;AAChC;;AAEA;EACE,WAAW;EACX,yCAAyC;EACzC,gCAAgC;EAChC,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,wBAAwB;EACxB,sBAAsB;EACtB,uBAAuB;EACvB,aAAa;EACb,UAAU;EACV,oCAAoC;EACpC,oDAAoD;EACpD,oBAAoB;EACpB,0CAA0C;AAC5C;;AAEA;EACE,wBAAwB;EACxB,sBAAsB;EACtB,uBAAuB;EACvB,aAAa;EACb,UAAU;EACV,oCAAoC;EACpC,sDAAsD;EACtD,oBAAoB;EACpB,0CAA0C;AAC5C;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".drawer-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.drawer-div-inside-container {\n  width: 100%;\n  margin-top: 5vh;\n}\nion-datetime {\n  --background: var(--ion-color-dark);\n  color: var(--ion-color-orange);\n}\n\n.drawer-buttons-wrapper {\n  width: 100%;\n  background-color: var(--ion-color-header);\n  --color: var(--ion-color-orange);\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  position: fixed;\n}\n\n.drawer-solid-button {\n  margin-inline-start: 1vw;\n  margin-inline-end: 1vw;\n  margin-block-start: 2vh;\n  height: 4.8vh;\n  width: 45%;\n  --color: var(--ion-color-fontXLight);\n  --background: var(--ion-color-shooting-range-button);\n  --border-radius: 4px;\n  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);\n}\n\n.drawer-outline-button {\n  margin-inline-start: 1vw;\n  margin-inline-end: 1vw;\n  margin-block-start: 2vh;\n  height: 4.8vh;\n  width: 45%;\n  --color: var(--ion-color-fontXLight);\n  --border-color: var(--ion-color-shooting-range-button);\n  --border-radius: 4px;\n  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);\n}\n\n.drawer-header-bottom-padding {\n  padding-bottom: 1vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
