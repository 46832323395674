import React from "react";
import { useTranslation } from "react-i18next";
import "./ResponsiveShooting.css";
import DesktopManualInputTemplate from "./DesktopManualInputTemplate";
import IHeat from "../../../Interfaces/IHeat";
import HeatsModal from "./HeatsModal/HeatsModal";
import {
  handleSave,
  IbasicRow,
  selectHeat,
  sendDrillToBBackend,
} from "./dataPostingHelpers";
import UseDataPosting from "../../CustomHooks/UseDataPosting";
import SoldiersListEditor from "./SoldiersListEditor";
import IDataPosting from "../../../Interfaces/IDataPosting";
import { Column, EEditorType } from "../PMDataGrid/DataGridTypes";
import { comparisonFormatter, positiveNumber } from "./inputValidations";
import DataGrid from "../PMDataGrid/DataGrid";

const messageType: number = 6;
const rowsNumber: number = 14;
const IESTesponsiveShootingTrainingTypeId: number = 9;
const BISTResponsiveShootingTrainingTypeId: number = 18;

//  message interface
interface responsiveShootingMessage {
  MessageType: number;
  TimeStamp: Date;
  SoldierID: number | "";
  LaneID: number | "";
  TrainingTypeID: number;
  WeaponType: string;
  NumOfTargets: number | "";
  NumOfShots: number | "";
  RedHits: number | "";
  BlueHits: number | "";
  Duration: number;
  TargetsDestroyed: number | "";
  CleanTargets: string;
  Hashtags: string[];
  DrillStartTime: Date;
  DrillEndTime: Date;
  GreenHits: number | "";
  PlanId: number | undefined;
}
interface IResponsiveShootingRow extends IbasicRow {
  laneId: number;
  soldierName: string;
  soldierId: number | "";
  numberOfShots: number | "";
  numberOfTargets: number | "";
  redHits: number | "";
  blueHits: number | "";
  duration: number | "";
  cleanTargets: string;
  targetsDestroyed: number | "";
  weaponType: string;
  hashtags: string | "";
  greenHits: number | "";
}
interface IProps extends IDataPosting {
  isBist?: boolean;
}
const ResponsiveShooting: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation();
  const trainingTypeId = props.isBist
    ? BISTResponsiveShootingTrainingTypeId
    : IESTesponsiveShootingTrainingTypeId;
  const XDefaultRow: IResponsiveShootingRow = {
    soldierId: "",
    id: 0,
    soldierName: "",
    weaponType: "",
    numberOfShots: "",
    cleanTargets: "",
    duration: "",
    laneId: 0,
    numberOfTargets: "",
    targetsDestroyed: "",
    hashtags: "",
    blueHits: "",
    redHits: "",
    greenHits: "",
  };

  const {
    isHeatsModalOpen,
    isSuccess,
    setIsSuccess,
    rows,
    setRows,
    setIsHeatsModalOpen,
    refreshRowsWrap,
    resetRowsDataWrap,
    weaponsTypesList,
  } = UseDataPosting(XDefaultRow, rowsNumber, trainingTypeId, true);

  // Columns for each input type
  const columns: Column<IResponsiveShootingRow>[] = [
    {
      key: "laneId",
      header: t("lane"),
      width: 80,
    },

    {
      key: "soldierName",
      header: t("soldierName"),
      editable: true,
      renderEditCell: SoldiersListEditor,
      filterType: EEditorType.text,
      width: 200,
    },
    {
      key: "soldierId",
      header: t("militaryId"),
      editable: true,
      filterType: EEditorType.text,
      validationFunction: (row: IResponsiveShootingRow, value: any) =>
        positiveNumber(value),
    },

    {
      key: "weaponType",
      header: t("weaponType"),
      editable: true,
      filterType: EEditorType.dropdown,
      options: weaponsTypesList.map((weapon) => ({
        label: t(weapon),
        value: weapon,
      })),
    },
    {
      key: "numberOfShots",
      header: t("numberOfShots"),
      editable: true,
      filterType: EEditorType.text,
      validationFunction: (row: IResponsiveShootingRow, value: any) =>
        positiveNumber(value),
    },
    {
      key: "redHits",
      header: t("RedHits"),
      editable: true,
      filterType: EEditorType.text,
      validationFunction: (row: IResponsiveShootingRow, value: any) => {
        return comparisonFormatter("redHits", "numberOfShots", row, value, t);
      },
    },

    {
      key: "numberOfTargets",
      header: t("numOfTargets"),
      editable: true,
      filterType: EEditorType.text,
      validationFunction: (row: IResponsiveShootingRow, value: any) =>
        positiveNumber(value),
    },

    {
      key: "cleanTargets",
      header: t("CleanTargets"),
      editable: true,
      filterType: EEditorType.text,
      validationFunction: (row: IResponsiveShootingRow, value: any) => {
        return comparisonFormatter(
          "cleanTargets",
          "numberOfTargets",
          row,
          value,
          t,
          ["targetsDestroyed"]
        );
      },
    },
    {
      key: "targetsDestroyed",
      header: t("TargetsDestroyed"),
      editable: true,
      filterType: EEditorType.text,
      validationFunction: (row: IResponsiveShootingRow, value: any) => {
        return comparisonFormatter(
          "targetsDestroyed",
          "numberOfTargets",
          row,
          value,
          t,
          ["cleanTargets"]
        );
      },
    },
    {
      key: "duration",
      header: t("Duration"),
      editable: true,
      filterType: EEditorType.text,
      validationFunction: (row: IResponsiveShootingRow, value: any) =>
        positiveNumber(value),
    },
    {
      key: "hashtags",
      header: t("hashtags"),
      editable: true,
      filterType: EEditorType.text,
    },
  ];

  // Manages data sending
  const sendData = async (row: IResponsiveShootingRow) => {
    // To avoid sending empty rows, check if all elements exist
    if (
      row.soldierId !== "" &&
      (row.cleanTargets !== "" ||
        row.numberOfShots !== "" ||
        row.duration !== "" ||
        row.numberOfTargets !== "")
    ) {
      // Sets the loading
      setIsSuccess(-1);
      let rowToSend: responsiveShootingMessage;

      let weaponType = row.weaponType === "" ? "None" : row.weaponType;

      rowToSend = {
        MessageType: messageType,
        TimeStamp: new Date(),
        TrainingTypeID: trainingTypeId,
        SoldierID: row.soldierId,
        WeaponType: weaponType,
        NumOfShots: row.numberOfShots,
        BlueHits: row.blueHits,
        CleanTargets: row.cleanTargets,
        Duration: row.duration === "" ? 0 : row.duration,
        NumOfTargets: row.numberOfTargets,
        LaneID: row.laneId,
        RedHits: row.redHits,
        TargetsDestroyed: row.targetsDestroyed,
        Hashtags: row.hashtags.split(" "),
        DrillStartTime: new Date(),
        DrillEndTime: new Date(),
        GreenHits: row.greenHits,
        PlanId: props.selectedPlan?.id,
      };

      // Then sends it
      sendDrillToBBackend(
        rowToSend,
        () => {
          props.resetCompetencyForces();
        },
        () => setIsSuccess(500)
      );
    }
  };

  return (
    <DesktopManualInputTemplate
      selectedPlan={props.selectedPlan}
      plansOptions={props.plansOptions}
      onPlanSelectedHandler={props.onPlanSelectedHandler}
      pageName={"responsiveShooting"}
      onClickThirdButton={() => {
        handleSave(rows, sendData, t);
      }}
      onClickSecondButton={() => {
        refreshRowsWrap(XDefaultRow);
      }}
      onClickFirstButton={() => {
        resetRowsDataWrap(XDefaultRow);
      }}
    >
      <HeatsModal
        isOpen={isHeatsModalOpen}
        onDismiss={() => setIsHeatsModalOpen(false)}
        onHeatSelect={(heat: IHeat) => {
          selectHeat(heat, setRows, setIsHeatsModalOpen);
        }}
      ></HeatsModal>
      <DataGrid
        columns={columns}
        data={rows}
        editable
        onEdit={(updatedRow) => {
          setRows((prev) =>
            prev.map((row) => (row.id === updatedRow.id ? updatedRow : row))
          );
        }}
        minWidth={150}
      ></DataGrid>
    </DesktopManualInputTemplate>
  );
};

export default ResponsiveShooting;
