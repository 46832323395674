import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./InstructorFeedbackComponent.css";
import InstructorFeedbackComponentHook from "../../../CustomHooks/InstructorFeedbackComponentHook";
import { InstructorFeedbackElementsData } from "../../../../Interfaces/IInstructorFeedback";
import InstructorFeedbackMobileElement from "./InstructorFeedbackMobileElement";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import InstructorFeedbackParamsHook from "../../../CustomHooks/InstructorFeedbackParamsHook";
import { IonGrid, IonRow } from "@ionic/react";
import SegmentsMenu from "../../../../pages/Mobile/DataHistory/SegmentsMenu";
import Spinner from "../../../Shared/Spinner/Spinner";
import PMLabel from "../../../themeComponents/PMLabel";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { IPlanValues } from "../../../../Interfaces/IPlanValues";
import { IMobileDataPostingProps } from "../../../../Interfaces/IMobileDataPostingProps";

interface IProps extends IMobileDataPostingProps {
  traineeId: number;
  afterPostHandler?: () => void;
  isDesktop?: boolean;
  trainingTypeId: number;
  plansValuesForSelectedPlan?: IPlanValues | undefined;
}
const InstructorFeedbackComponent: React.FC<IProps> = (
  props: IProps
): JSX.Element => {
  const {
    isDesktop,
    traineeId,
    afterPostHandler,
    trainingTypeId,
    plansValuesForSelectedPlan,
    isSendClicked,
  } = props;
  const { isLoading, selectedSegment, setSelectedSegment, segmentsOptions } =
    InstructorFeedbackParamsHook(
      plansValuesForSelectedPlan?.elementsPlanValues,
      props.trainingTypeId
    );
  const { t } = useTranslation();
  const {
    setUIFGrades,
    postData,
    setIsTraineeChanged,
    isTraineeChanged,
    isComplete,
  } = InstructorFeedbackComponentHook(
    t,
    traineeId,
    selectedSegment?.elements,
    trainingTypeId,
    selectedSegment?.title,
    props.planId,
    isDesktop,
    afterPostHandler
  );
  useEffect(() => {
    props.setIsSendDisable && props.setIsSendDisable(!isComplete);
  }, [isComplete]);
  useEffect(() => {
    if (isSendClicked) {
      postData();
    }
  }, [isSendClicked]);
  return (
    <>
      <IonGrid className="instructorFeedbackGrid">
        <IonRow className="segmentRow">
          <SegmentsMenu
            segmentsOptions={segmentsOptions}
            selectedSegment={selectedSegment}
            setSelectedSegment={setSelectedSegment}
          />
        </IonRow>

        <IonRow className="searchRow">
          {segmentsOptions.length ? (
            <>
              <Swiper
                modules={[Pagination]}
                pagination={{
                  clickable: true,
                }}
                allowTouchMove
                className="mobileUFISlides"
              >
                {selectedSegment?.elements?.map(
                  (elementData: InstructorFeedbackElementsData, index) => (
                    <SwiperSlide
                      className="mobileUFISlide"
                      key={elementData.title}
                    >
                      <InstructorFeedbackMobileElement
                        key={elementData.dataType}
                        dataType={elementData.dataType}
                        elements={elementData.params}
                        title={elementData.title}
                        setGrade={setUIFGrades}
                        resetAll={isTraineeChanged}
                        setResetAll={setIsTraineeChanged}
                        index={index}
                        isMust={elementData.isMust}
                      ></InstructorFeedbackMobileElement>
                    </SwiperSlide>
                  )
                )}
              </Swiper>
            </>
          ) : (
            <>
              {!isLoading ? (
                <PMLabel
                  fontColor="light"
                  fontFamily="ExtraLight"
                  fontSize="xxLarge"
                  textAlign="center"
                >
                  {t("noInstructorFeedbackInTrainingPlan")}
                </PMLabel>
              ) : (
                <Spinner className="instructorFeedbackSpinner"></Spinner>
              )}
            </>
          )}
        </IonRow>
      </IonGrid>
    </>
  );
};

export default InstructorFeedbackComponent;
