import { FC, useContext, useEffect, useState } from "react";
import "./StationView.css";
import { useTranslation } from "react-i18next";
import PerformanceTemplate from "../../performanceTemplate";
import PMLabel from "../../../../../components/themeComponents/PMLabel";
import DashboardDoughnut from "../../../../../components/Desktop/DashboardDoughnut/DashboardDoughnut";
import usePlans from "../../../../../components/CustomHooks/usePlans";
import IForceTreeNode from "../../../../../Interfaces/IForceTreeNode";
import { exportToPdfAndCsv } from "../../../../../components/Desktop/ToExcel/exportToCsv";
import customToast from "../../../../../components/Shared/Toast/CustomToast";

import { getForceFullName } from "../../../../../services/helpers";
import useForcesChecked from "../../../../../components/CustomHooks/useForcesChecked";
import useGetCommanderDashboardGrades from "../../../../../components/CustomHooks/useGetCommanderDashboardGrades";
import {
  IElementResult,
  IIndicatorResult,
  ITrainingTypeResult,
} from "../../../../../Interfaces/dataCalculator";
import ElementCard from "./ElementCard";
import { DashboardConstants } from "../../../../../Configurations/DashboardConstants";
import InputTable from "../../../../../components/Desktop/InputTable/InputTable";
import { IonIcon } from "@ionic/react";
import EIconsSrc from "../../../../../Interfaces/EIconsSrc";
import { ITableResult } from "../../../../../Interfaces/ITableData";
import { AppModeContext } from "../../../../../context/AppModeContext/AppModeContext";
import { toPng } from "html-to-image";
import ELanguage from "../../../../../Enums/ELanguage";
import EmptyState from "../../../../../components/Shared/EmptyState/EmptyState";

const StationView: FC = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const { appMode } = useContext(AppModeContext);

  const { selectedPlan, plansOptions, onPlanSelectedHandler } = usePlans();
  const [orbatForces, setOrbatForces] = useState<IForceTreeNode[]>(
    [] as IForceTreeNode[]
  );
  const { checkedForces } = useForcesChecked(orbatForces);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(true);
  const [elementsWithDetails, setElementsWithDetails] = useState<{
    [key: string]: boolean;
  }>({});
  const [indicatorResults, setIndicatorsResults] = useState<IIndicatorResult>();
  const [trainingTypeResults, setTrainingTypeResults] =
    useState<ITrainingTypeResult>();
  const [elementsResults, setElementsResults] = useState<IElementResult[]>([]);

  const getColorByValueForTrainingType = (
    trainingType: ITrainingTypeResult | undefined
  ) => {
    return trainingType
      ? Math.round(trainingType?.grade) > trainingType?.threshold
        ? DashboardConstants.GREEN_GRADE_COLOR
        : Math.round(trainingType?.grade) > trainingType?.threshold / 2
        ? DashboardConstants.YELLOW_GRADE_COLOR
        : DashboardConstants.RED_GRADE_COLOR
      : DashboardConstants.MISSING_GRADE_COLOR;
  };

  const { forceData, resetCommanderStates, loading } =
    useGetCommanderDashboardGrades(
      selectedPlan,
      checkedForces,
      refresh,
      setRefresh
    );

  const getImageAndSendToCsv = async () => {
    let imageUrl = await toPng(document.getElementById("csvStationViewImage")!);
    let forcesData: any = [];
    if (orbatForces.length)
      forcesData.push({
        forceDetails: orbatForces[0],
        forceGradesData: {
          pic1: imageUrl,
          isSoldier: orbatForces[0].is_soldier,
          forceResults: {
            forceId: orbatForces[0].id,
            grade: 54,
            id: selectedPlan!.id,
            indicatorResults: [indicatorResults],
            isAttendAllTrainingPlan: true,
            name: selectedPlan!.name,
            threshold: 11,
            rounds: 4,
            rankings: [],
            participantsAtLeastOnes: 5,
          },
          affiliation: "",
          isAttended: true,
        },
      });
    else {
      customToast.error(t("noSelectedForces"));
    }
    await exportToPdfAndCsv(
      forcesData,
      selectedPlan,
      t,
      false,
      `${t("stationDetails")}`,
      500,
      1200
    );
  };

  useEffect(() => {
    if (refresh && forceData !== undefined) {
      resetCommanderStates();
      setRefresh(false);
    }
    let indicatorResults = forceData?.results.indicatorResults?.find(
      (indicatorResults) => {
        let trainingType = indicatorResults.trainingTypeResults.find(
          (tt) => tt.name === appMode
        );
        if (trainingType) setTrainingTypeResults(trainingType);
        return trainingType;
      }
    );
    if (indicatorResults) setIndicatorsResults(indicatorResults);
    if (!forceData) {
      setTrainingTypeResults(undefined);
      setIndicatorsResults(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceData]);

  const [hitsDataAnalysis, setHitsDataAnalysis] = useState<ITableResult>({});
  const getThreshold = (element: IElementResult): [number, string] => {
    let missilesFired =
      trainingTypeResults?.tooltipResults.tooltipElementsResults.find(
        (tooltipElement) => tooltipElement.name === "missilesFired"
      )?.value || 0;
    let hits =
      trainingTypeResults?.elementResults.find(
        (element) => element.name === "hits"
      )?.value || 0;

    switch (element.name) {
      case "hits":
        return [Number(missilesFired) || 0, "missilesFired"];
      case "doubleHits":
        return [Number(hits) || 0, "hits"];
      case "outOfSector":
        return [
          Math.max(Number(missilesFired) - Number(hits), 0),
          `${t("missilesFired")} - ${t("hits")}`,
        ];
      case "correctWarheadSelection":
        return [Number(missilesFired) || 0, "missilesFired"];
      case "correctTrajectorySelection":
        return [Number(missilesFired) || 0, "missilesFired"];
    }
    return [0, ""];
  };
  useEffect(() => {
    let tooltipToDisplay = [
      "staticTarget",
      "movingTarget",
      "NLOSTarget",
      "nightTarget",
      "manualTarget",
    ];
    let hitsDataAnalysis: ITableResult = {};
    trainingTypeResults?.tooltipResults.tooltipElementsResults
      .filter((tooltipElement) =>
        tooltipToDisplay.includes(tooltipElement.name)
      )
      .forEach((tooltip) => {
        hitsDataAnalysis[tooltip.name] = tooltip.value;
      });
    setHitsDataAnalysis(hitsDataAnalysis);
    let elementsResults = trainingTypeResults?.elementResults.map((element) => {
      let [threshold, name] = getThreshold(element);
      return element.upperThreshold < element.lowerThreshold
        ? {
            ...element,
            lowerThreshold: threshold,
            nameOf100P: name,
          }
        : {
            ...element,
            upperThreshold: threshold,
            nameOf100P: name,
          };
    });
    setElementsResults(elementsResults || []);
    // eslint-disable-next-line
  }, [trainingTypeResults]);

  const chart =
    !orbatForces[0] || loading ? (
      <EmptyState text={orbatForces[0] ? "loadingData" : "noSelectedForces"} />
    ) : (
      <div id="csvStationViewImage" className="stationDetailsContent ">
        <div>
          <div className="stationViewRow">
            <div className="stationsAndForceDetails">
              <PMLabel
                fontSize="large"
                fontColor="xLight"
                fontFamily="Regular"
                cssClass="titleCard"
                textAlign="start"
              >
                {orbatForces.length
                  ? getForceFullName(
                      orbatForces[0]?.name,
                      orbatForces[0]?.force_type,
                      t
                    )
                  : ""}
              </PMLabel>
            </div>
          </div>

          <div className="stationViewRow ">
            <div className="stationDoughnut">
              <DashboardDoughnut
                key={"DashboardDoughnut"}
                setSelectedIndicator={() => {}}
                indicatorId={2}
                indicatorName={
                  trainingTypeResults?.grade
                    ? `${Math.round(trainingTypeResults?.grade)}`
                    : `0`
                }
                isDisableAll={false}
                selectedIndicator={undefined}
                chartData={[
                  {
                    label: trainingTypeResults
                      ? `${t(trainingTypeResults?.name)}`
                      : "",
                    value: trainingTypeResults?.grade
                      ? trainingTypeResults?.grade
                      : 0,
                    color: getColorByValueForTrainingType(trainingTypeResults),
                  },
                ]}
              ></DashboardDoughnut>
            </div>
          </div>
        </div>
        <div className="stationDoughnutRow scrollL">
          {elementsResults.map((element, index) => (
            <div className="elementCardWithDetailsWrap" key={index}>
              <ElementCard
                element={element}
                key={element.id}
                isVisible={
                  elementsWithDetails[element.id] !== true ||
                  elementsWithDetails[element.id] === undefined
                }
                onExpendForDetails={
                  element.name.toLowerCase() === "hits" &&
                  Object.keys(hitsDataAnalysis).length
                    ? () => {
                        setElementsWithDetails((prev) => ({
                          ...prev,
                          [element.id]: true,
                        }));
                      }
                    : undefined
                }
              ></ElementCard>
              {elementsWithDetails[element.id] &&
              element.name.toLowerCase() === "hits" &&
              Object.keys(hitsDataAnalysis).length ? (
                <div className="tableDetailsWrap">
                  <InputTable
                    elementTitle="targetTypeMode"
                    valueTitle="successRate"
                    tableTitle={
                      i18n.language === ELanguage.he
                        ? `${t("analysis")} ${t(element.name)}`
                        : `${t(element.name)} ${t("analysis")}`
                    }
                    tableData={hitsDataAnalysis}
                  ></InputTable>
                  <IonIcon
                    src={EIconsSrc.OPEN_UP}
                    className="openCardForDetails"
                    onClick={() => {
                      setElementsWithDetails((prev) => ({
                        ...prev,
                        [element.id]: false,
                      }));
                    }}
                  ></IonIcon>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    );

  return (
    <PerformanceTemplate
      key={"stationView"}
      loadingText={t("loadingData")}
      chart={chart}
      isDrawerOpen={isDrawerOpen}
      loading={loading}
      orbatForces={orbatForces}
      selectedPlan={selectedPlan}
      plansOptions={plansOptions}
      onPlanSelectedHandler={onPlanSelectedHandler}
      setChecked={(force) => {
        setOrbatForces(force);
      }}
      setDrawerOpen={setDrawerOpen}
      title={t(`${appMode}Details`)}
      limit={1}
      onClickExportButton={getImageAndSendToCsv}
      exportButtonText={t("export")}
      onClickRefreshButton={() => {
        setRefresh(true);
      }}
      refreshButtonText={t("refresh")}
    ></PerformanceTemplate>
  );
};

export default StationView;
