import { useContext, useEffect, useState } from "react";
import { ITabOptions } from "../../Interfaces/IDataHistory";
import { IDataType } from "../../Interfaces/IDataType";
import ITrainingType from "../../Interfaces/ITrainingType";
import { routes } from "../../services/routeRoles";
import { useDataTypes } from "./HooksWithRQ/useDataTypes";

import { useTranslation } from "react-i18next";
import { TrainingTypesContext } from "../../context/TrainingTypesContext/TrainingTypesContext";

//basic row header enum
enum EBasicHeader {
  RowIndex = "rowIndex",
  DateID = "date",
  HourID = "hour",
  SoldierIdID = "soldierId",
  SoldierNameID = "soldierName",
  TrainingTypeName = "trainingTypeName",
  TrainingPlan = "trainingPlan",
}

/**
 * Generate tab option for each training type and one for all the training types together
 */
export const useDataHistoryTabsHeaders = (trainingTypeId?: number) => {
  const itcStationId = 10;
  /** Concat the basic headers with the data types*/
  const getTrainingTypeHeader = (dataTypes: any) => [
    { id: EBasicHeader.RowIndex, name: "rowIndex" },
    { id: EBasicHeader.DateID, name: "Date" },
    { id: EBasicHeader.HourID, name: "Hour" },
    { id: EBasicHeader.SoldierIdID, name: "SoldierId" },
    { id: EBasicHeader.SoldierNameID, name: "SoldierName" },
    ...dataTypes,
    { id: EBasicHeader.TrainingPlan, name: "trainingPlan" },
  ];

  // all training types tab
  let allTrainingTypesTabsOption: ITabOptions = {
    url: `${routes.dataHistory}/allTrainingTypes`,
    tabId: 0,
    stationId: itcStationId,
    title: "allTrainingTypes",
    headersArray: [
      { id: EBasicHeader.RowIndex, name: "rowIndex" },
      { id: EBasicHeader.TrainingTypeName, name: "trainingTypeName" },
      { id: EBasicHeader.DateID, name: "Date" },
      { id: EBasicHeader.HourID, name: "Hour" },
      { id: EBasicHeader.SoldierIdID, name: "SoldierId" },
      { id: EBasicHeader.SoldierNameID, name: "SoldierName" },
      { id: EBasicHeader.TrainingPlan, name: "trainingPlan" },
    ],
  };
  const { dataHistoryDataTypes } = useDataTypes();
  const { trainingTypes } = useContext(TrainingTypesContext);

  const [tabsOptions, setTabsOptions] = useState<ITabOptions[]>(
    [] as ITabOptions[]
  );
  const [dataTypesByTrainingTypeId, setDataTypesByTrainingTypeId] = useState<
    IDataType[]
  >([] as IDataType[]);
  const { t } = useTranslation();
  useEffect(() => {
    if (dataHistoryDataTypes.length && trainingTypes.length) {
      //initialize the tabs option for each training type and its data types headers
      setTabsOptions([
        allTrainingTypesTabsOption,
        ...trainingTypes
          .map((trainingType: ITrainingType) => ({
            url: `${routes.dataHistory}/${trainingType.name}`,
            tabId: trainingType.id,
            stationId: trainingType.stationId,
            title: trainingType.name,
            headersArray: getTrainingTypeHeader(
              dataHistoryDataTypes
                .filter(
                  (dataType) => dataType.trainingTypeId === trainingType.id
                )
                .map((dataType) => ({
                  ...dataType,
                  id: dataType.name,
                }))
                .sort((a, b) => t(a.name).localeCompare(t(b.name)))
            ),
          }))
          .sort((a, b) => t(a.title).localeCompare(t(b.title))),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataHistoryDataTypes, trainingTypes]);

  useEffect(() => {
    trainingTypeId !== undefined &&
      // filter the data types by the training type id
      setDataTypesByTrainingTypeId(
        dataHistoryDataTypes
          .filter((dataType) => +dataType.trainingTypeId === +trainingTypeId)
          .map((dataType) => ({ ...dataType, id: dataType.name }))
      );
  }, [trainingTypeId, dataHistoryDataTypes]);

  return { tabsOptions, dataTypesByTrainingTypeId };
};
