import { FC } from "react";
import { IonIcon } from "@ionic/react";
import "./SPIKEIcon.css";
import { IIcon } from "../../../Interfaces/ISPIKEProps";
import PMLabel from "../../themeComponents/PMLabel";
import { useTranslation } from "react-i18next";

const SPIKEIcon: FC<IIcon> = (props: IIcon) => {
  const { src, state, onClick, name } = props;
  const { t } = useTranslation();
  return (
    <span className={`spikeIconSpan ${!props.isMobile ? "desktop" : ""}`}>
      <IonIcon
        src={src}
        onClick={onClick}
        color={
          state === "selected"
            ? "orange"
            : state === "disable"
            ? "disabled"
            : "enabled"
        }
        className={`spikeIcon  ${
          state === "disable"
            ? "spikeIconDisabled"
            : state === "enable"
            ? "spikeIconEnable"
            : ""
        }`}
      ></IonIcon>
      <PMLabel
        fontFamily={state === "selected" ? "SemiBold" : "ExtraLight"}
        textAlign="center"
        fontSize={props.isMobile ? "xSmall" : "small"}
        fontColor={state === "selected" ? "orange" : "light"}
        cssClass="iconLabel"
      >
        {t(name)}
      </PMLabel>
    </span>
  );
};

export default SPIKEIcon;
