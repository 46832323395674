// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-wrapper ion-footer ion-toolbar {
  --background: var(--ion-color-primary);
  box-shadow: var(--box-shadow);
}

.footer-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-inline: 0.8vw;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,oBAAoB;AACtB","sourcesContent":[".footer-wrapper ion-footer ion-toolbar {\n  --background: var(--ion-color-primary);\n  box-shadow: var(--box-shadow);\n}\n\n.footer-content {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin-inline: 0.8vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
