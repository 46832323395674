// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainText {
  padding-top: 40vh;
  display: flex;
  justify-content: center;
}

.loginDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  direction: ltr;
}
.loginDiv:lang(he) {
  direction: rtl;
}
.inputsDiv {
  display: inherit;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  height: 20%;
  width: 100%;
}
.inputsDiv .PMInput {
  min-width: 250px;
}

.buttonDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Desktop/Login/Login.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,YAAY;EACZ,WAAW;EACX,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,6BAA6B;EAC7B,sBAAsB;EACtB,WAAW;EACX,WAAW;AACb;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":[".mainText {\n  padding-top: 40vh;\n  display: flex;\n  justify-content: center;\n}\n\n.loginDiv {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  height: 100%;\n  width: 100%;\n  direction: ltr;\n}\n.loginDiv:lang(he) {\n  direction: rtl;\n}\n.inputsDiv {\n  display: inherit;\n  align-items: center;\n  justify-content: space-evenly;\n  flex-direction: column;\n  height: 20%;\n  width: 100%;\n}\n.inputsDiv .PMInput {\n  min-width: 250px;\n}\n\n.buttonDiv {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
