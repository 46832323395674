// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bar-component {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 20px;
}
.barChartWrap {
  width: 100%;
  overflow-x: auto;
  height: 100%;
}
.mobileTrendsSlide .barChartWrap {
  overflow-x: unset;
}
.EmptyStateIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/Charts/Bar/BarChart.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,aAAa;AACf;AACA;EACE,WAAW;EACX,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".bar-component {\n  height: 100%;\n  width: 100%;\n  position: relative;\n  padding: 20px;\n}\n.barChartWrap {\n  width: 100%;\n  overflow-x: auto;\n  height: 100%;\n}\n.mobileTrendsSlide .barChartWrap {\n  overflow-x: unset;\n}\n.EmptyStateIcon {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
