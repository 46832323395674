import { FC, useState } from "react";

import { IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import "./SearchSoldier.css";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import MobileHeader from "../../../Mobile/MobileHeader/MobileHeader";
import EIconsSrc from "../../../../Interfaces/EIconsSrc";
import SearchComponents from "./SearchComponents";
import ISimpleTrainee, { ETeam } from "../../../../Interfaces/ISimpleTrainee";
import ELanguage from "../../../../Enums/ELanguage";
import NavigationRow from "../../SPIKEGorilla/NavigationRow";
import useMobileDataPostingParams from "../../../CustomHooks/useMobileDataPostingParams";

const SearchSoldier: FC = () => {
  const history = useHistory();

  const {
    stationId,
    trainingTypeId,
    stationName,
    planId,
    selectedForcesAmount,
    isPairs,
  } = useMobileDataPostingParams();
  const { t, i18n } = useTranslation();

  const [soldierDetails, setSoldierDetails] = useState<ISimpleTrainee[]>([]);

  const next = () => {
    const forcesQuery = soldierDetails
      .map(
        (force, index) =>
          `force${index + 1}Id=${force.id}&force${index + 1}Name=${
            force.name
          }&force${index + 1}Team=${force.team}`
      )
      .join("&");

    history.push(
      `/${stationName}/${planId}/${stationId}/${trainingTypeId}?${forcesQuery}`
    );
  };
  const backHandler = () => {
    history.goBack();
  };

  return (
    <IonPage className="pageMS">
      <IonContent className="contentContainerMS">
        <MobileHeader
          iconEndSrc={EIconsSrc.MENU_ICON}
          iconStartSrc={
            i18n.language === ELanguage.he
              ? EIconsSrc.BACK_ICON_HE
              : EIconsSrc.BACK_ICON_EN
          }
          title={t("addSoldierToStation")}
          iconStartHandler={backHandler}
        />
        <IonGrid className="itemsContainerMS">
          <IonCol className="searchSoldierWrapCol">
            <IonRow className="searchSoldierWrapRow">
              <div className="medicalSearchWrap">
                <SearchComponents
                  soldierDetails={soldierDetails}
                  setSoldierDetails={setSoldierDetails}
                  selectedForcesAmount={selectedForcesAmount}
                  isPairs={isPairs}
                ></SearchComponents>
              </div>
            </IonRow>
            <IonRow>
              <NavigationRow
                isPrevHidden
                onNextHandler={next}
                isNextDisabled={
                  !(
                    soldierDetails.filter((f) => f.team === ETeam.teamBlue)
                      .length > 0 &&
                    soldierDetails.filter((f) => f.team === ETeam.teamGreen)
                      .length > 0
                  ) && selectedForcesAmount > soldierDetails.length
                }
              ></NavigationRow>
            </IonRow>
          </IonCol>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default SearchSoldier;
