import { FC, Fragment } from "react";
import { IonButton } from "@ionic/react";
import PMLabel from "../../themeComponents/PMLabel";
import { useTranslation } from "react-i18next";
import "./StationButtonComp.css";

interface IButtonCompData {
  url: string;
  stationName: string;
}
const StationButtonComp: FC<IButtonCompData> = (props: IButtonCompData) => {
  const { url, stationName } = props;
  const { t } = useTranslation();

  return (
    <Fragment>
      <IonButton
        className="stationButtonComp"
        strong={false}
        routerLink={url}
        routerDirection="none"
        color="dark"
      >
        <PMLabel
          fontColor="light"
          fontFamily="Regular"
          fontSize="medium"
          textAlign="center"
          cssClass="stationButtonLabel"
        >
          {t(stationName.trim())}
        </PMLabel>
      </IonButton>
    </Fragment>
  );
};
export default StationButtonComp;
