import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import DragModal from "../../Desktop/DragModal/DragModal";
import PMLabel from "../../themeComponents/PMLabel";
import "./PlansDifferencesDetails.css";
import { useTranslation } from "react-i18next";

interface planValue {
  element_id: { value: number | null; equal: boolean };
  indicator_id: { value: number | null; equal: boolean };
  lower_threshold: { value: number | null; equal: boolean };
  name: { value: string | null; equal: boolean };
  plan_id: { value: number | null; equal: boolean };
  required_threshold: { value: number | null; equal: boolean };
  training_type_id: { value: number | null; equal: boolean };
  training_type_name: { value: string | null; equal: boolean };
  upper_threshold: { value: number | null; equal: boolean };
  weight: { value: number | null; equal: boolean };
}
interface IPlansNotEquals {
  isPlansValuesEqual: boolean;
  destPlan: IPlan;
  sourcePlan: IPlan;
  trainingPlanGap: {
    uniqDestination: planValue[];
    uniqSource: planValue[];
  };
}
interface IPlan {
  id: number;
  name: string;
  lethality: number;
  start_date: string;
  end_date: string;
}
interface IPlansDetails {
  plansNotEquals: IPlansNotEquals[];
  plansOnlyOnSource: IPlan[];
  plansEquals: IPlan[];
}
interface IProps {
  plansDifferences: IPlansDetails | undefined;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PlansDifferencesDetails: React.FC<IProps> = (props: IProps) => {
  const { plansDifferences, isOpen, setIsOpen } = props;

  const { t } = useTranslation();
  const getFieldsToDisplay = (gap: any) => {
    if (gap.indicator_name?.value)
      return ["indicator_name", "threshold", "weight"];
    else {
      if (gap.training_type_Name?.value)
        return ["training_type_name", "threshold", "weight"];
      else
        return [
          "training_type_name",
          "element_name",
          "lower_threshold",
          "upper_threshold",
          "required_threshold",
          "weight",
        ];
    }
  };
  return (
    <DragModal
      isOpen={isOpen}
      onDismiss={() => setIsOpen(false)}
      cssClass="comparePlansModal "
    >
      <IonGrid className="comparePlansGrid scrollL">
        <IonRow className="rowPDD titlePlansRow">
          <PMLabel
            textAlign="center"
            fontColor="xLight"
            fontFamily="Regular"
            fontSize="xxLarge"
            cssClass="titlePlansDiff"
          >
            {`${t("compareLocalAndDestinationPlans")}`}
          </PMLabel>
        </IonRow>
        <IonRow className="rowPDD">
          <IonCol>
            <PMLabel
              textAlign="center"
              fontColor="xLight"
              fontFamily="Regular"
              fontSize="xxLarge"
              cssClass="titlePlansDiff"
            >
              {`${t("localSite")}:`}
            </PMLabel>
          </IonCol>
          <IonCol>
            <PMLabel
              textAlign="center"
              fontColor="xLight"
              fontFamily="Regular"
              fontSize="xxLarge"
              cssClass="titlePlansDiff"
            >
              {`Click:`}
            </PMLabel>
          </IonCol>
        </IonRow>
        {plansDifferences?.plansNotEquals?.map((plan) => (
          <IonRow className="rowPDD" key={plan.sourcePlan.id}>
            <IonCol>
              <IonRow className="rowPDD">
                {plan.isPlansValuesEqual ? (
                  <>
                    <IonCol size="3">
                      <IonRow>
                        <PMLabel
                          textAlign="center"
                          fontColor="light"
                          fontFamily="Light"
                          fontSize="medium"
                        >
                          {`${t(plan.sourcePlan.name)} -    ${t("lethality")} : 
                       `}
                        </PMLabel>
                        <PMLabel
                          textAlign="center"
                          fontColor="light"
                          fontFamily="Light"
                          fontSize="medium"
                          cssClass={"notEqualFont"}
                        >
                          {` 
                        ${plan.sourcePlan.lethality}`}
                        </PMLabel>
                      </IonRow>
                    </IonCol>
                    <IonCol size="3">
                      <IonRow>
                        <PMLabel
                          textAlign="center"
                          fontColor="light"
                          fontFamily="Light"
                          fontSize="medium"
                        >
                          {`${t(plan.destPlan.name)} -    ${t("lethality")} : 
                       `}
                        </PMLabel>
                        <PMLabel
                          textAlign="center"
                          fontColor="light"
                          fontFamily="Light"
                          fontSize="medium"
                          cssClass={"notEqualFont"}
                        >
                          {` 
                        ${plan.destPlan.lethality}`}
                        </PMLabel>
                      </IonRow>
                    </IonCol>
                  </>
                ) : (
                  <IonRow className="rowPDD">
                    <IonCol>
                      {plan?.trainingPlanGap?.uniqSource?.length ? (
                        <>
                          <PMLabel
                            textAlign="center"
                            fontColor="light"
                            fontFamily="Light"
                            fontSize="medium"
                          >
                            {t(plan.sourcePlan.name)}
                          </PMLabel>

                          {plan?.trainingPlanGap?.uniqSource?.map(
                            (gap: any, index) => (
                              <IonRow key={index}>
                                {Object.keys(gap).map((field: any, index) => {
                                  return getFieldsToDisplay(gap).includes(
                                    field
                                  ) && gap[field].value !== undefined ? (
                                    <IonCol key={index} size="2">
                                      <PMLabel
                                        textAlign="center"
                                        fontColor="light"
                                        fontFamily="Light"
                                        fontSize="medium"
                                      >
                                        {t(field)}
                                      </PMLabel>
                                      <PMLabel
                                        textAlign="center"
                                        fontColor="light"
                                        fontFamily="Light"
                                        fontSize="medium"
                                        cssClass={
                                          isNaN(gap[field].value)
                                            ? ""
                                            : gap[field]?.equal
                                            ? "equalFont"
                                            : "notEqualFont"
                                        }
                                      >
                                        {` ${t(gap[field].value)}`}
                                      </PMLabel>
                                    </IonCol>
                                  ) : null;
                                })}
                              </IonRow>
                            )
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </IonCol>

                    <IonCol>
                      {plan?.trainingPlanGap?.uniqDestination?.length ? (
                        <>
                          <PMLabel
                            textAlign="center"
                            fontColor="light"
                            fontFamily="Light"
                            fontSize="medium"
                          >
                            {plan.destPlan.name}
                          </PMLabel>

                          {plan?.trainingPlanGap?.uniqDestination?.map(
                            (gap: any, index) => (
                              <IonRow key={index}>
                                {Object.keys(gap).map((field: any, index) => {
                                  return getFieldsToDisplay(gap).includes(
                                    field
                                  ) && gap[field].value !== undefined ? (
                                    <IonCol key={index} size="2">
                                      <PMLabel
                                        textAlign="center"
                                        fontColor="light"
                                        fontFamily="Light"
                                        fontSize="medium"
                                      >
                                        {t(field)}
                                      </PMLabel>
                                      <PMLabel
                                        textAlign="center"
                                        fontColor="light"
                                        fontFamily="Light"
                                        fontSize="medium"
                                        cssClass={
                                          isNaN(gap[field].value)
                                            ? ""
                                            : gap[field]?.equal
                                            ? "equalFont"
                                            : "notEqualFont"
                                        }
                                      >
                                        {` ${t(gap[field].value)}`}
                                      </PMLabel>
                                    </IonCol>
                                  ) : null;
                                })}
                              </IonRow>
                            )
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </IonCol>
                  </IonRow>
                )}
              </IonRow>
            </IonCol>
          </IonRow>
        ))}
      </IonGrid>
    </DragModal>
  );
};
export default PlansDifferencesDetails;
