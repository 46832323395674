import { FC } from "react";
import { IonMenuButton, IonMenuToggle, IonRow } from "@ionic/react";
import "./NavigationRow.css";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import PMButton from "../../themeComponents/PMButton";
import { useTranslation } from "react-i18next";
import ELanguage from "../../../Enums/ELanguage";

interface IProps {
  onPrevHandler?: () => void;
  onNextHandler?: () => void;
  isPrevDisabled?: boolean;
  isNextDisabled?: boolean;
  isPrevHidden?: boolean;
  isNextHidden?: boolean;
  isLastStep?: boolean;
  isLastHeat?: boolean;
}

const NavigationRow: FC<IProps> = (props: IProps) => {
  const {
    onNextHandler,
    onPrevHandler,
    isNextDisabled,
    isPrevDisabled,
    isNextHidden,
    isPrevHidden,
    isLastStep,
    isLastHeat,
  } = props;
  const { t, i18n } = useTranslation();

  const NextButton = () => (
    <PMButton
      cssClass={isLastStep ? "endHeatButton" : ""}
      size="fit"
      color={isLastStep ? "orange" : undefined}
      fill={isLastStep ? "solid" : "clear"}
      dir={i18n.language === ELanguage.he ? "ltr" : "rtl"}
      isDisabled={isNextDisabled}
      icon={
        !isLastStep
          ? {
              iconSrc:
                i18n.language === ELanguage.he
                  ? EIconsSrc.CHEVRON_LEFT
                  : EIconsSrc.CHEVRON_RIGHT,
              color: "xLight",
              size: "large",
            }
          : undefined
      }
      onClickHandler={onNextHandler}
      label={{
        fontSize: "large",
        fontColor: "xLight",
        fontFamily: "Regular",
      }}
    >
      {isLastStep ? t("endHeat") : t("next")}
    </PMButton>
  );

  return (
    <IonRow className="navigationRow">
      {!isPrevHidden ? (
        <PMButton
          size="fit"
          fill="clear"
          icon={{
            iconSrc:
              i18n.language === ELanguage.he
                ? EIconsSrc.CHEVRON_RIGHT
                : EIconsSrc.CHEVRON_LEFT,
            color: "xLight",
            size: "xLarge",
          }}
          isDisabled={isPrevDisabled}
          onClickHandler={onPrevHandler}
          label={{
            fontSize: "large",
            fontColor: "xLight",
            fontFamily: "Regular",
          }}
        >
          {t("prev")}
        </PMButton>
      ) : (
        <div />
      )}
      {!isNextHidden ? (
        isLastHeat && isLastStep ? (
          <NextButton />
        ) : (
          <NextButton />
        )
      ) : (
        <div />
      )}
    </IonRow>
  );
};

export default NavigationRow;
