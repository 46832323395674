import { IonText } from "@ionic/react";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  TYPES,
  MIN_RESULT,
  MAX_RESULT,
} from "../../../../../../Configurations/shootingRangeConsts";
import IShootingHeat from "../../../../../../Interfaces/IShootingHeat";
import "./ListTopics.css";

type Props = {
  heat: IShootingHeat | undefined;
};

const ListTopics: FC<Props> = (props: Props) => {
  const { heat } = props;
  const { t } = useTranslation();
  const { GROUPING, NUMBER_OF_HITS, SCORE } = TYPES;

  const getTypeOfResult = (heat: IShootingHeat | undefined): string => {
    let typeOfResult = "";
    switch (heat?.type) {
      case GROUPING:
        typeOfResult = t("sm");
        break;
      case SCORE:
        typeOfResult = `${MIN_RESULT} - ${MAX_RESULT}`;
        break;
      case NUMBER_OF_HITS:
        typeOfResult = `${t("outOf")} ${heat.bullets}`;
        break;
      default:
        break;
    }
    return ` (${typeOfResult})`;
  };

  return (
    <div className="list-topics">
      <IonText className="list-topic">{t("soldierName")}</IonText>
      <IonText className="list-topic">
        {t("result") + getTypeOfResult(heat)}
      </IonText>
    </div>
  );
};

export default ListTopics;
