import { _DeepPartialObject } from "chart.js/dist/types/utils";
import ELanguage from "../../../../Enums/ELanguage";
import { ChartOptions, LegendOptions } from "chart.js";
import { Options } from "chartjs-plugin-datalabels/types/options";
import { EGraphType } from "../../../../Enums/EGraphType";

export const LegendOption = (
  isMobile?: boolean,
  language?: string
): _DeepPartialObject<LegendOptions<"bar">> => ({
  display: !isMobile,
  rtl: language === ELanguage.he,
  position: "bottom",
  reverse: true,
  fullSize: false,

  labels: {
    boxWidth: 25,
    color: "rgba(255, 255, 255, 0.89)",
    padding: 24,
    pointStyle: "circle",
    usePointStyle: true,
    boxHeight: 8 + 3.5,
    font: {
      size: 16,
      family: "Regular",
    },
  },
  title: {
    display: false,
  },
});

export const DataLabelsOption = (
  isMobile?: boolean
): _DeepPartialObject<Options> => ({
  anchor: "end",
  align: "top",
  textAlign: "center",
  font: {
    size: isMobile ? 10 : 14,
    family: "Regular",
  },

  offset(context: any) {
    var index = context.dataIndex;
    var value = context.dataset.data[index] < 100 ? 0 : -20;
    return value;
  },
  padding: 0,
  clamp: true,
  display: true,
});
export const BarOptions = (
  categoryPercentage: number,
  barPercentage: number,
  barThickness: number,
  lineOrScatterTypeToggle: EGraphType | undefined,
  isMobile?: boolean,
  isNotGrades?: boolean
): ChartOptions<"bar"> => ({
  maintainAspectRatio: false,
  elements: {
    point: {
      radius: lineOrScatterTypeToggle == "line" ? 3 : 17,
      hoverRadius: lineOrScatterTypeToggle == "line" ? 4 : 18,
    },
  },
  datasets: {
    bar: {
      borderRadius: 4,
      maxBarThickness: barThickness,
      categoryPercentage: categoryPercentage,
      barPercentage: barPercentage,
    },
  },
  layout: {
    padding: {
      top: 0,
      bottom: 0,
      left: 10,
      right: 10,
    },
  },
  events: ["click", "mousemove"],
  responsive: true,
  scales: {
    y: {
      min: 0,
      max: undefined,
      suggestedMax: isNotGrades ? undefined : 100,
      border: {
        display: false,
      },
      grid: {
        color: "rgba(205,205,205,0.3)",
      },
      ticks: {
        minRotation: 0,
        color: "rgba(255, 255, 255, 0.89)",
        autoSkip: true,
        stepSize: undefined,
      },
    },

    x: {
      offset: lineOrScatterTypeToggle === "bar" || !lineOrScatterTypeToggle, // Make sure the line starts at the first bar

      grid: {
        display: false,
        drawOnChartArea: false,
      },
      border: {
        display: false,
      },
      ticks: {
        minRotation: 0,
        font: { family: "SemiBold", size: isMobile ? 10 : 18 },
        color: "#DDDDDD",
        stepSize: 10,
        padding: 20,
        major: {
          enabled: true,
        },
      },
    },
  },
});
