// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkboxListModal {
  --width: max-content;
  --min-width: 300px;
  --height: 65vh;
  --background: var(--ion-color-dark);
  --border-radius: 4px;
  --border-color: var(--ion-color-disabled);
  --border-style: solid;
  --border-width: 1px;
  --backdrop-opacity: 0.7 !important;
}
.checkboxListModalContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-width: 365px;
  width: 100%;
}
.saveCancelRowCheckboxListFModal {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
}
.checkboxListList {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.checkboxListListCol {
  --ion-grid-column-padding: 0;
  height: 100%;
  overflow-y: auto;
}

.checkboxListTitleRow {
  height: 50px;
  padding: 15px;
  margin-bottom: 20px;
  padding-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/CheckboxesList/CheckboxesList.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,cAAc;EACd,mCAAmC;EACnC,oBAAoB;EACpB,yCAAyC;EACzC,qBAAqB;EACrB,mBAAmB;EACnB,kCAAkC;AACpC;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,YAAY;EACZ,gBAAgB;EAChB,WAAW;AACb;AACA;EACE,aAAa;EACb,yBAAyB;EACzB,aAAa;AACf;AACA;EACE,aAAa;EACb,SAAO;EACP,sBAAsB;AACxB;;AAEA;EACE,4BAA4B;EAC5B,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":[".checkboxListModal {\n  --width: max-content;\n  --min-width: 300px;\n  --height: 65vh;\n  --background: var(--ion-color-dark);\n  --border-radius: 4px;\n  --border-color: var(--ion-color-disabled);\n  --border-style: solid;\n  --border-width: 1px;\n  --backdrop-opacity: 0.7 !important;\n}\n.checkboxListModalContent {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  height: 100%;\n  max-width: 365px;\n  width: 100%;\n}\n.saveCancelRowCheckboxListFModal {\n  display: flex;\n  justify-content: flex-end;\n  padding: 15px;\n}\n.checkboxListList {\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n}\n\n.checkboxListListCol {\n  --ion-grid-column-padding: 0;\n  height: 100%;\n  overflow-y: auto;\n}\n\n.checkboxListTitleRow {\n  height: 50px;\n  padding: 15px;\n  margin-bottom: 20px;\n  padding-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
