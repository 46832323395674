import {
  useState,
  Dispatch,
  useEffect,
  useContext,
  SetStateAction,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import IForcePointer from "../../Interfaces/IForcePointer";
import IForceTreeNode from "../../Interfaces/IForceTreeNode";
import IForceType from "../../Interfaces/IForceType";
import { UserContext } from "../../context/UserContext/userContext";
import {
  removeUnsaveForce,
  getOrbatTree,
} from "../../redux/actions/orbatTreeActions";
import { IOrbatTreeReducer } from "../../redux/reducers/orbatTree";
import { orbatTreeState } from "../../redux/store/OrbatTreeStore";
import { treeFlatting } from "../../services/helpers";
import { userRoles } from "../../services/routeRoles";
import { useTranslation } from "react-i18next";
import { useWebsocketUpdateForcesList } from "../../services/forcesUpdateSocket";

const OrbatTreeHook = (
  checked: IForceTreeNode[],
  setChecked: Dispatch<SetStateAction<IForceTreeNode[]>>,
  limit: number | undefined,
  readonly?: boolean,
  enableReorder?: boolean,
  checkForcesForParent?: (
    force: IForceTreeNode[],
    isSubordinates?: boolean
  ) => void,
  initIsDuplicated?: (() => void) | undefined
) => {
  const { t } = useTranslation();
  const [checkedForce, setCheckedForce] = useState<IForceTreeNode>(
    {} as IForceTreeNode
  );
  const dispatch = useDispatch<Dispatch<any>>();

  //redux state
  const orbatTree: IForceTreeNode = useSelector<
    orbatTreeState,
    IOrbatTreeReducer
  >((state) => state.orbatTree).orbatTree;
  const { user } = useContext(UserContext);

  const [path, setPath] = useState<(number | null)[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [forces, setForces] = useState<IForceTreeNode>({} as IForceTreeNode);
  const [thereIsForces, setThereIsForces] = useState<boolean>(true);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [updateForcesMassage] = useWebsocketUpdateForcesList();

  const [flatTree, setFlatTree] = useState<IForcePointer[]>([]);
  // Force has been selected in the search field
  const checkRelatedForceAndGetTree = (isAfterChangingParent: boolean) => {
    if (user.role === userRoles.Admin || user.forceToDisplayInOrbat) {
      if (!isAfterChangingParent) {
        setIsLoading(true);
      }
      dispatch(getOrbatTree(t));
    } else {
      if (!isAfterChangingParent) {
      }
      setThereIsForces(false);
    }
  };
  // Refetch the forces tree when receiving a WebSocket message indicating the forces tree was updated
  useEffect(() => {
    if (updateForcesMassage) dispatch(getOrbatTree(t));
  }, [updateForcesMassage]);
  // Force has been selected in the search field
  const getSelectedForce = (
    e: {
      label: string | null;
      value: IForcePointer | null | number | string | IForceType;
    } | null
  ): void => {
    if (limit && checked.length >= limit && limit > 1) {
      setOpenAlert(true);
      return;
    }

    if (e && e.value) {
      if (typeof e.value === "object") {
        findForceById(forces, e.value.id);
      }
    } else {
      setPath([]);
      onCheckForce(undefined);
    }
  };
  // Getting the path (ID's) - from root node to the givan node
  const findPath = (id: number | null) => {
    flatTree.forEach((el: IForcePointer) => {
      if (el.id === id) {
        setPath((prev: (number | null)[]) => [...prev, el.id]);

        findPath(el.parent);
      }
    });
  };
  // Find the selected force and gatting his path
  const findForceById = (tree: IForceTreeNode, id: number): void => {
    if (tree.id === id) {
      onCheckForce(tree);
      findPath(tree.id);
    } else {
      tree.nodes &&
        tree.nodes.forEach((node: IForceTreeNode) => {
          findForceById(node, id);
        });
    }
  };
  // Calls when force has been checked
  const onCheckForce = (
    newForce: IForceTreeNode | undefined,
    isSubordinates?: boolean
  ): void => {
    if (newForce) {
      initIsDuplicated && initIsDuplicated();
      setCheckedForce(newForce);
      if (checked.some((f: IForceTreeNode) => f.id === newForce.id)) {
        //unchecked force - force already exist in the checked assay
        if (readonly || enableReorder) {
          //the reorder functionality is like the read only tree - multiple force can be unchecked
          let temp: IForceTreeNode[] | undefined = checked.filter(
            (node: IForceTreeNode) => node.id !== newForce.id
          );
          setChecked(temp);
          checkForcesForParent && checkForcesForParent(temp, isSubordinates);
        } else {
          setChecked([]);
          checkForcesForParent && checkForcesForParent([]);
          setCheckedForce({} as IForceTreeNode);
        }
      } else {
        //check force
        //the reorder functionality is like the read only tree - multiple force can be checked
        let temp: IForceTreeNode[] =
          (readonly || enableReorder) && limit !== 1
            ? [...checked, newForce]
            : [newForce];
        setChecked(temp);
        checkForcesForParent && checkForcesForParent(temp, isSubordinates);
      }
    }
  };
  const removeFromPath = (id: number | null) => {
    let a = path.filter((p: number | null) => p && id && p < id);
    setPath(a);
  };
  const closeAlert = (): void => {
    setOpenAlert(false);
  };
  useEffect(() => {
    if (forces.id !== undefined) setIsLoading(false);
  }, [forces]);

  useEffect(() => {
    if (!!orbatTree) {
      setThereIsForces(true);
      setForces({ ...orbatTree });
      setFlatTree([]);
      treeFlatting(orbatTree, setFlatTree);
    } else {
      setThereIsForces(false);
    }
  }, [orbatTree]);

  useEffect(() => {
    !orbatTree && checkRelatedForceAndGetTree(false);
    if (user.role !== userRoles.Admin && !user.forceToDisplayInOrbat) {
    }
    return () => {
      dispatch(removeUnsaveForce());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    checkedForce,
    flatTree,
    getSelectedForce,
    isLoading,
    thereIsForces,
    forces,
    onCheckForce,
    checked,
    path,
    removeFromPath,
    setOpenAlert,
    openAlert,
    closeAlert,
    findPath,
    setChecked,
    setCheckedForce,
    setForces,
    setPath,
    checkRelatedForceAndGetTree,
  };
};
export default OrbatTreeHook;
