// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.appModesPopover {
  --width: 180px;
  margin-top: 30px;
}
.modeSelectionWrap {
  height: 100%;
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.radioAppMode {
  --color-checked: var(--ion-color-orange);
  margin-inline-end: 15px;
  margin-inline-start: 0px;
}
.iconColMenuMobile {
  max-width: 38px;
  display: flex;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/AppModeChange/AppModeChange.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,WAAW;EACX,wBAAwB;EACxB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,wCAAwC;EACxC,uBAAuB;EACvB,wBAAwB;AAC1B;AACA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".appModesPopover {\n  --width: 180px;\n  margin-top: 30px;\n}\n.modeSelectionWrap {\n  height: 100%;\n  width: 100%;\n  display: flex !important;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n}\n.radioAppMode {\n  --color-checked: var(--ion-color-orange);\n  margin-inline-end: 15px;\n  margin-inline-start: 0px;\n}\n.iconColMenuMobile {\n  max-width: 38px;\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
