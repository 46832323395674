import React, { useEffect, useState } from "react";
import "./ListItem.css";
import { IonItem, IonLabel, IonIcon } from "@ionic/react";
import ITraineeDetails from "../../../Interfaces/ITraineeDetails";
import { useTranslation } from "react-i18next";
import EIconSrc from "../../../Interfaces/EIconsSrc";
import { getForceFullName } from "../../../services/helpers";
import PMIcon from "../../themeComponents/PMIcon";
interface IListItem {
  traineeDetails: ITraineeDetails;
  setSingleTraineeToRemove: React.Dispatch<
    React.SetStateAction<ITraineeDetails | undefined>
  >;
  singleTraineeToRemove: ITraineeDetails | undefined;
  isAlertOpen: boolean;
  setNewColumnForTrainee(uniqId: number, stationId: number): void;
  onTraineeClicked: (traineeDetails: ITraineeDetails, isToAdd: boolean) => void;
  isITC: boolean;
}

const ListItem: React.FC<IListItem> = (props: IListItem) => {
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [HLClassName, setHLClassName] = useState<string>("");
  const [isFirst, setIsFirst] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (
      !props.isAlertOpen &&
      Number(props.singleTraineeToRemove?.uniqId) ===
        Number(props.traineeDetails?.uniqId)
    ) {
      setIsClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isAlertOpen]);

  useEffect(() => {
    //add or remove trainee from selected trainees when clicked
    !props.isITC && props.onTraineeClicked(props.traineeDetails, isClicked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClicked]);

  useEffect(() => {
    setIsFirst(false);
  }, []);

  useEffect(() => {
    setIsClicked(false);

    if (
      props.traineeDetails.newInColumn &&
      props.traineeDetails.uniqId !== -1 &&
      !isFirst
    ) {
      setHLClassName("greenItem");
      let interval = setInterval(() => {
        setHLClassName("");
        props.setNewColumnForTrainee(
          props.traineeDetails.uniqId,
          props.traineeDetails.stationId
        );
        clearInterval(interval);
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.traineeDetails]);
  return (
    <IonItem
      lines="full"
      mode="md"
      className={
        "traineeItemSqL " +
        HLClassName +
        (!(
          !(
            (isClicked && props.traineeDetails.uniqId !== -1) ||
            (isClicked && props.isAlertOpen)
          ) &&
          Number(props.singleTraineeToRemove?.uniqId) !==
            Number(props.traineeDetails?.uniqId)
        )
          ? " selectedLI"
          : "")
      }
      button={props.traineeDetails.uniqId !== -1}
      onClick={() => {
        if (props.traineeDetails.uniqId !== -1)
          setIsClicked((prev: boolean) => {
            if (!props.isAlertOpen) {
              return !prev;
            }
            return true;
          });
      }}
    >
      <IonLabel className="labelSL">
        {getForceFullName(
          props.traineeDetails.traineeName,
          props.traineeDetails.forceType,
          t
        )}
      </IonLabel>
      <div
        data-tooltip-id="trashId"
        data-tooltip-content={t("tooltipRemoveSoldier")}
      >
        <PMIcon
          slot="end"
          iconSrc={EIconSrc.TRASH_ICON}
          hidden={
            !(
              (isClicked && props.traineeDetails.uniqId !== -1) ||
              (isClicked && props.isAlertOpen)
            ) &&
            Number(props.singleTraineeToRemove?.uniqId) !==
              Number(props.traineeDetails?.uniqId)
          }
          onClick={() => {
            props.setSingleTraineeToRemove(props.traineeDetails);
          }}
          size="xLarge"
        />
      </div>
    </IonItem>
  );
};
export default ListItem;
