import React, { useEffect, useState } from "react";

import "./RateInput.css";
import PMLabel from "../../themeComponents/PMLabel";
import { IHit } from "../../../Interfaces/ITableData";

interface IProps {
  value: string | number | null;
  rate: string | number | null;
  onChangeHandler: (value: IHit) => void;
  wrongValue: boolean;
}
const RateInput: React.FC<IProps> = (props: IProps): JSX.Element => {
  const [value, setValue] = useState<{
    value: string | number | null;
    rate: string | number | null;
  }>({ rate: "", value: "" });

  useEffect(() => {
    setValue({
      value: props.value,
      rate: props.rate,
    });
    // eslint-disable-next-line
  }, [props.value, props.rate]);

  useEffect(() => {
    value &&
      props.onChangeHandler({
        hits: value.value ? Number(value.value) : null,
        shots: value.rate ? Number(value.rate) : null,
      });
    // eslint-disable-next-line
  }, [value]);
  return (
    <div className="rateInputWrap">
      <input
        className={`inputTableInput ${props.wrongValue ? "wrongValue" : ""}`}
        value={value?.value || undefined}
        onChange={(event) => {
          setValue((prev) => ({ ...prev, value: event.target.value }));
        }}
      ></input>
      <PMLabel fontColor="light" fontFamily="Bold">
        /
      </PMLabel>
      <input
        className={`inputTableInput ${props.wrongValue ? "wrongValue" : ""}`}
        value={value?.rate || undefined}
        onChange={(event) => {
          setValue((prev) => ({ ...prev, rate: event.target.value }));
        }}
      ></input>
    </div>
  );
};

export default RateInput;
