import { IonRow, IonCol } from "@ionic/react";
import React, { Dispatch, useEffect, useState } from "react";
import { Collapse } from "react-collapse";
import SmallInput from "./SmallInput";
import Elements from "./Elements";
import { useDispatch, useSelector } from "react-redux";
import { IPlansReducer } from "../../../redux/reducers/plans";
import { AppState } from "../../../redux/store/plansStore";
import { useTranslation } from "react-i18next";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import PMIcon from "../../themeComponents/PMIcon";
import PMLabel from "../../themeComponents/PMLabel";
import { units, validateInput } from "../DataPosting/inputValidations";
import "./Station.css";
import { ITrainingTypesPlanValue } from "../../../Interfaces/IPlanValues";
import { EDIT_TRAINING_TYPE } from "../../../redux/actions/trainingPlanActions";
import { ITrainingType } from "../../../Interfaces/ITrainingPlan";
import useCheckSumErrors from "../../CustomHooks/useCheckSumErrors";
import { ITrainingPlanProps } from "../../../Interfaces/TrainingPlanContext/ITrainingPlan";
import ELanguage from "../../../Enums/ELanguage";

interface IStationProps extends ITrainingPlanProps {
  trainingType: ITrainingType;
}

const Station: React.FC<IStationProps> = (props: IStationProps) => {
  const [isElementsOpened, setIsElementsOpened] = useState<boolean>(false);
  const [trainingType, setTrainingType] = useState<ITrainingType>(
    props.trainingType
  );
  const { t } = useTranslation();
  const { errors, setErrors } = useCheckSumErrors(
    props.setIsErrorExist,
    undefined,
    trainingType
  );
  const editMode = useSelector<AppState, IPlansReducer>(
    (state) => state.plans
  ).editMode;
  const dispatch = useDispatch<Dispatch<any>>();

  const { i18n } = useTranslation();

  useEffect(() => {
    setTrainingType(props.trainingType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.trainingType]);

  const updateStation = (trainingType: ITrainingTypesPlanValue) => {
    dispatch({ type: EDIT_TRAINING_TYPE, trainingType: trainingType });
  };
  const onChangeStationDetailsHandler: Function = (
    field: string,
    value: string
  ): void => {
    setErrors((prev: any) => ({
      ...prev,
      [field]: validateInput(value, field),
    }));
    setTrainingType((prev) =>
      prev
        ? {
            ...prev,
            [field]: value,
          }
        : { ...trainingType!, [field]: value }
    );
  };
  return (
    <IonCol size="12" className={`stationCol`}>
      <IonRow
        className={`stationRow ${
          isElementsOpened ? "openElementsStationRow" : ""
        }`}
        onClick={() => setIsElementsOpened(!isElementsOpened)}
        onBlur={() => {
          trainingType && updateStation(trainingType);
        }}
      >
        <IonCol size="2.6" className="stationHeader stCol">
          <div className="iconNameDiv">
            {isElementsOpened ? (
              <PMIcon
                color="light"
                iconSrc={EIconsSrc.ARROW_DOWN}
                size="large"
                cssClass="stationArrowIcon"
              ></PMIcon>
            ) : (
              <PMIcon
                color="light"
                iconSrc={
                  i18n.language === ELanguage.he
                    ? EIconsSrc.CHEVRON_LEFT
                    : EIconsSrc.CHEVRON_RIGHT
                }
                size="large"
                cssClass="stationArrowIcon"
              ></PMIcon>
            )}
            <PMLabel
              fontColor="light"
              fontSize="medium"
              fontFamily="Regular"
              cssClass="stationName"
            >
              {t(trainingType?.name ? trainingType?.name.trim() : "")}
            </PMLabel>
          </div>
        </IonCol>
        <IonCol
          size="2.58"
          className={` stCol stationValue stationWeight ${
            props.trainingType.trainingTypeId
          } ${editMode ? "" : "viewMode"}`}
        >
          <SmallInput
            inputType="number"
            length="smallLong"
            onChange={(field: string, value: string) =>
              onChangeStationDetailsHandler(field, value, units.Weight, 0)
            }
            inputName="weight"
            inputValue={trainingType?.weight}
            isPercentageInput
            isDisabled={!editMode}
            cssClass={`tpSmallInput ${
              errors.weight?.length ? "tpInputError" : null
            }`}
          ></SmallInput>
        </IonCol>
        <IonCol
          size="2.58"
          className={` ${props.trainingType.trainingTypeId} ${
            editMode ? "test" : "viewMode"
          } stCol`}
        >
          <div className="stationThreshold ">
            <SmallInput
              inputType="number"
              length="smallLong"
              onChange={(field: string, value: string) =>
                onChangeStationDetailsHandler(field, value, units.Grade, 1)
              }
              inputValue={trainingType?.threshold}
              inputName="threshold"
              isDisabled={!editMode}
              cssClass={`tpSmallInput ${
                errors.threshold?.length ? "tpInputError" : null
              }`}
            ></SmallInput>
          </div>
        </IonCol>
        <IonCol size="2.58"></IonCol>
        <IonCol size="1.33"></IonCol>
      </IonRow>
      <IonRow className="elementsRow">
        <Collapse isOpened={isElementsOpened} className="elementsCollapse">
          <Elements
            elements={trainingType?.elements}
            setIsErrorExist={props.setIsErrorExist}
            elementsForDashboard={props.elementsForDashboard}
          ></Elements>
        </Collapse>
      </IonRow>
    </IonCol>
  );
};

export default Station;
