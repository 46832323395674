// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-sheet {
  --background: var(--ion-color-primary-tint);
  --border-radius: 20px;
  direction: ltr;
}
.modal-sheet :lang(he) {
  direction: rtl;
}
.tooltip-list {
  --background: var(--ion-color-primary-tint);
  --border: none;
}
.tooltip-item {
  --color: var(--ion-color-fontXLight);
  --background: var(--ion-color-primary-tint);
}
.tooltip-header {
  --background: var(--ion-color-primary-tint);
}
`, "",{"version":3,"sources":["webpack://./src/components/Mobile/PersonalZone/Competency/TooltipData/TooltipData.css"],"names":[],"mappings":"AAAA;EACE,2CAA2C;EAC3C,qBAAqB;EACrB,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,2CAA2C;EAC3C,cAAc;AAChB;AACA;EACE,oCAAoC;EACpC,2CAA2C;AAC7C;AACA;EACE,2CAA2C;AAC7C","sourcesContent":[".modal-sheet {\n  --background: var(--ion-color-primary-tint);\n  --border-radius: 20px;\n  direction: ltr;\n}\n.modal-sheet :lang(he) {\n  direction: rtl;\n}\n.tooltip-list {\n  --background: var(--ion-color-primary-tint);\n  --border: none;\n}\n.tooltip-item {\n  --color: var(--ion-color-fontXLight);\n  --background: var(--ion-color-primary-tint);\n}\n.tooltip-header {\n  --background: var(--ion-color-primary-tint);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
