import React, { useState } from "react";
import "./Legend.css";
import PMLabel from "../../themeComponents/PMLabel";
import { useTranslation } from "react-i18next";
import PopoverMenu from "../../Shared/Popover/PopoverMenu";
import PopoverItem from "../../themeComponents/PopoverItem";
import IForceTreeNode from "../../../Interfaces/IForceTreeNode";
import { isTwoLevelsAtLeast } from "../../../services/treeSharedFunctions";
import FadeIn from "react-fade-in";

interface IProps {
  label: string;
  color: string;
  isSelected?: boolean | undefined;
  setSelectedForcesID?: React.Dispatch<React.SetStateAction<number[]>>;
  id: number;
  selectLowLevelForce?: ((forces: IForceTreeNode[]) => void) | undefined;
  force?: IForceTreeNode | undefined;
  isMultiSelect?: boolean;
  fontsize?: "xSmall" | "small" | "medium" | "large" | "xLarge" | "xxLarge";
}
const Legend: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation();

  const {
    color,
    force,
    id,
    isSelected,
    selectLowLevelForce,
    setSelectedForcesID,
    label,
  } = props;
  // const [crtlClicked, setCrtlClicked] = useState<boolean>(false); //Do not remove
  const [popoverState, setShowPopover] = useState<{
    showPopover: boolean;
    event: MouseEvent | undefined;
    text: string;
  }>({
    showPopover: false,
    event: undefined,
    text: "",
  });
  // document.addEventListener("keydown", function (event) {
  //   setCrtlClicked(event.ctrlKey);
  // });
  // document.addEventListener("keyup", function (event) {
  //   setCrtlClicked(event.ctrlKey);
  // });

  const handleRightClick = (e: any) => {
    if (!force || !isTwoLevelsAtLeast(force)) return;
    e.preventDefault();
    e.persist();
    setShowPopover({
      showPopover: true,
      event: e,
      text: `${t("displayData")} ${force?.force_type}`,
    });
  };
  const addForceIDToSelectedForces = () => {
    setSelectedForcesID &&
      setSelectedForcesID((prev) => {
        if (props.isMultiSelect) {
          if (prev?.includes(id)) return prev.filter((rowId) => rowId !== id);
          return [...prev, id];
        } else {
          if (prev?.includes(id) && prev.length === 1) return [];
          else return [id];
        }
      });
  };

  return (
    <FadeIn className="fadeLegendClass">
      <div
        className={`legend`}
        onClick={addForceIDToSelectedForces}
        onContextMenu={handleRightClick}
        id={String(id)}
      >
        {selectLowLevelForce && (
          <PopoverMenu
            popoverState={popoverState}
            onDismiss={() =>
              setShowPopover({
                showPopover: false,
                event: undefined,
                text: "",
              })
            }
          >
            <PopoverItem
              background="BxDark"
              onClickHandler={(e) => {
                selectLowLevelForce && force && selectLowLevelForce([force]);
                e.preventDefault();
                e.persist();
                setShowPopover({
                  showPopover: false,
                  event: undefined,
                  text: "",
                });
              }}
            >
              <PMLabel fontColor="light" fontFamily="Light" textAlign="center">
                {popoverState.text}
              </PMLabel>
            </PopoverItem>
          </PopoverMenu>
        )}
        <span
          className="dotLegend"
          id={String(id)}
          style={{ background: color }}
        ></span>
        <PMLabel
          fontSize={props.fontsize || "medium"}
          fontColor="xLight"
          fontFamily={isSelected ? "Bold" : "Light"}
          lineThrough={isSelected === false}
          id={String(id)}
        >
          {label}
        </PMLabel>
      </div>
    </FadeIn>
  );
};

export default Legend;
