// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-content ion-title,
.user {
  font-family: "Light" !important;
  font-size: 0.8rem;
  padding-inline-start: 0.5%;
}

.header-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 0.5%;
  margin-left: 0.5%;
  justify-content: space-between;
}

.header-content ion-img {
  width: 1%;
}

.header ion-header ion-toolbar {
  --background: var(--ion-color-primary);
}

.menuIcon {
  cursor: pointer;
}

.user {
  padding-inline-end: 8px;
  font-size: 13px;
}

.menuIcon {
  cursor: pointer;
}

.toggleButton {
  --background: "transparent";
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-inline-end: 15px;
  align-items: center;
}

.toggleButton > ion-title {
  padding-inline-end: 10px;
}

.toggleButton > ion-toggle {
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/Header/Header.css"],"names":[],"mappings":"AAAA;;EAEE,+BAA+B;EAC/B,iBAAiB;EACjB,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;EAClB,iBAAiB;EACjB,8BAA8B;AAChC;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,2BAA2B;EAC3B,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,wBAAwB;EACxB,mBAAmB;AACrB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,oBAAoB;AACtB","sourcesContent":[".header-content ion-title,\n.user {\n  font-family: \"Light\" !important;\n  font-size: 0.8rem;\n  padding-inline-start: 0.5%;\n}\n\n.header-content {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-right: 0.5%;\n  margin-left: 0.5%;\n  justify-content: space-between;\n}\n\n.header-content ion-img {\n  width: 1%;\n}\n\n.header ion-header ion-toolbar {\n  --background: var(--ion-color-primary);\n}\n\n.menuIcon {\n  cursor: pointer;\n}\n\n.user {\n  padding-inline-end: 8px;\n  font-size: 13px;\n}\n\n.menuIcon {\n  cursor: pointer;\n}\n\n.toggleButton {\n  --background: \"transparent\";\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  padding-inline-end: 15px;\n  align-items: center;\n}\n\n.toggleButton > ion-title {\n  padding-inline-end: 10px;\n}\n\n.toggleButton > ion-toggle {\n  padding: 5px;\n  padding-top: 10px;\n  padding-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
