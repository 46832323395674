import { IonButton, IonContent, IonDatetime, IonModal } from "@ionic/react";

import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import "./DateDrawer.css";
import PMLabel from "../../themeComponents/PMLabel";

type Props = {
  date: Date | undefined;
  setDate: (date: Date) => void;
  isStartDate?: boolean;
  idToControl: string;
  selectText: string;
};

const DateDrawer = (props: Props) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const { t } = useTranslation();
  const dateTimeRef = useRef<null | HTMLIonDatetimeElement>(null);
  const [tempDate, setTempDate] = useState<Date>(
    new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [flagToEdit, setFlagToEdit] = useState<boolean>(false);
  const saveSelectedDate = () => {
    if (dateTimeRef !== null) {
      let realDate: any = dateTimeRef!.current;
      let year = realDate.activePartsClone.year;
      let month = realDate.activePartsClone.month;
      let day = realDate.activePartsClone.day;

      const tempDateToCheck = new Date(year, month - 1, day, 0, 0, 0);

      //if there is an error with the selected date, choose the date of today
      if (tempDateToCheck.toString() === "Invalid Date") {
        setTempDate(
          new Date(
            props.isStartDate
              ? new Date().setHours(0, 0, 0)
              : new Date().setHours(23, 59, 59)
          )
        );
      } else {
        setTempDate(
          props.isStartDate
            ? new Date(year, month - 1, day, 0, 0, 0)
            : new Date(year, month - 1, day, 23, 59, 59)
        );
      }
    }
    setFlagToEdit(true);
  };

  useEffect(() => {
    // in order to deal with race condition between setTempDate &  props.setDrawerState.
    if (tempDate && flagToEdit) {
      // if we get here its means that we have the start date and the end date.
      props.setDate(tempDate);

      //close the drawer and reset all the variables
      if (!props.isStartDate) modal.current?.dismiss();
      setFlagToEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempDate]);

  const clearSelectedDate = () => {
    dateTimeRef.current?.reset();
  };

  return (
    <div>
      <IonModal
        ref={modal}
        className="modal-sheet"
        trigger={props.idToControl}
        initialBreakpoint={0.5}
        breakpoints={[0, 0.4, 0.6, 1]}
        keepContentsMounted={true}
      >
        <IonContent className="drawer-container">
          <div className="drawer-div-inside-container">
            <div className="drawer-header-bottom-padding">
              <PMLabel
                fontSize="large"
                fontColor="xLight"
                fontFamily="Bold"
                margin
              >
                {t(props.selectText)}
              </PMLabel>
            </div>
            <IonDatetime
              key="dateTime"
              ref={dateTimeRef}
              size="cover"
              presentation="date"
              preferWheel={true}
              locale="he-IL"
              color={"orange"}
            ></IonDatetime>
          </div>
          <div className="drawer-buttons-wrapper">
            <IonButton
              className="drawer-solid-button"
              fill="solid"
              onClick={() => saveSelectedDate()}
            >
              {t("applyDataHistoryButton")}
            </IonButton>
            <IonButton
              className="drawer-outline-button"
              onClick={() => clearSelectedDate()}
              fill="outline"
            >
              {t("clearDataHistoryButton")}
            </IonButton>
          </div>
        </IonContent>
      </IonModal>
    </div>
  );
};

export default DateDrawer;
