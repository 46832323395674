import React from "react";
import { Route } from "react-router-dom";
import { Redirect, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Competency from "../../../components/Desktop/PersonalZone/Competency/Competency";
import Reports from "../../../components/Desktop/PersonalZone/Reports/Reports";
import Notifications from "../../../components/Desktop/PersonalZone/Notifications/Notifications";

// css
import "./PersonalZone.css";

// ionic imports
import {
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";

// components

// interfaces
import TabItem from "./../../../Interfaces/ITabItem";
import { routes } from "../../../services/routeRoles";
import PMLabel from "../../../components/themeComponents/PMLabel";

const PersonalZone: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const location = useLocation();

  // Tabs buttons options
  const TabsOptions: TabItem[] = [
    {
      url: `${routes.personal}/competency`,
      title: t("competency"),
    },
    {
      url: `${routes.personal}/reports`,
      title: t("reports"),
    },
    {
      url: `${routes.personal}/notifications`,
      title: t("notificationsAndRecommendations"),
    },
  ];

  return (
    <IonTabs className="pm-tabs">
      <IonRouterOutlet id="main">
        <Route
          path={routes.personal}
          render={() => <Redirect to={`${routes.personal}/competency`} />}
          exact={true}
        />

        <Route
          path={`${routes.personal}/competency`}
          component={Competency}
          exact
        />
        <Route path={`${routes.personal}/reports`} component={Reports} exact />
        <Route
          path={`${routes.personal}/notifications`}
          component={Notifications}
          exact
        />
      </IonRouterOutlet>
      <IonTabBar
        slot="top"
        className="ion-justify-content-start personalZoneTabs"
      >
        {TabsOptions.map((item: TabItem, index: number) => {
          return (
            <IonTabButton
              className={location.pathname === item.url ? "tab-selected" : ""}
              tab={item.title}
              href={item.url}
              key={index}
            >
              <PMLabel
                fontColor="light"
                fontFamily="Light"
                fontSize="medium"
                textAlign="center"
              >
                {item.title}
              </PMLabel>
            </IonTabButton>
          );
        })}
      </IonTabBar>
    </IonTabs>
  );
};
export default PersonalZone;
