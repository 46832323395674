import { useEffect, useState } from "react";
import IForceTreeNode from "../../Interfaces/IForceTreeNode";

const useGetSubForces = (
  chosenForces: IForceTreeNode[],
  isSubordinates?: boolean
) => {
  const [subForces, setSubForces] = useState<IForceTreeNode[]>([]);

  const collectSubForces = (node: IForceTreeNode): IForceTreeNode[] => {
    if (!node) return [];

    const uniqueNodes: Map<number, IForceTreeNode> = new Map();
    const stack: IForceTreeNode[] = [node];

    while (stack.length > 0) {
      const currentNode = stack.pop();
      if (currentNode) {
        uniqueNodes.set(currentNode.id, currentNode);
        if (currentNode.nodes) {
          stack.push(...currentNode.nodes);
        }
      }
    }

    return Array.from(uniqueNodes.values());
  };

  const processForces = (
    forces: IForceTreeNode[],
    includeSubordinates?: boolean
  ) => {
    if (forces.length === 0) {
      setSubForces([]);
      return;
    }

    const uniqueForces = new Map<number, IForceTreeNode>();
    const selectedForceIds = new Set(forces.map((force) => force.id));

    if (includeSubordinates) {
      const forcesToRemove = subForces.filter(
        (subForce) => !selectedForceIds.has(subForce.id)
      );

      if (forcesToRemove.length > 0) {
        //if the force is unchecked do not insert its sub forces
        forcesToRemove.forEach((force) => {
          const subordinates = collectSubForces(force);
          subForces.forEach((existingSubForce) => {
            if (!subordinates.some((sub) => sub.id === existingSubForce.id)) {
              uniqueForces.set(existingSubForce.id, existingSubForce);
            }
          });
        });
      } else {
        //insert all sub forces for checked forces
        forces.forEach((force) => {
          const subordinates = collectSubForces(force);
          subordinates.forEach((subordinate) => {
            uniqueForces.set(subordinate.id, subordinate);
          });
        });
      }
    } else {
      forces.forEach((force) => {
        uniqueForces.set(force.id, force);
      });
    }

    setSubForces(Array.from(uniqueForces.values()));
  };

  useEffect(() => {
    processForces(chosenForces, isSubordinates);
  }, [chosenForces, isSubordinates]);

  return { subForces };
};

export default useGetSubForces;
