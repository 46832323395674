enum EIconsSrc {
  BACK_ICON_EN = "./assets/icon/left-arrow.svg",
  BACK_ICON_HE = "./assets/icon/right-arrow.svg",
  MENU_ICON = "./assets/icon/menu.svg",
  TRASH_ICON = "./assets/icon/trash.svg",
  CHECK_ICON = "./assets/icon/check.svg",
  TREE_ICON = "./assets/icon/Orbat.svg",
  TREE_ICON_EN = "./assets/icon/treeIcon-ltr.svg",
  DATA_POSTING = "./assets/icon/dataPosting.svg",
  EXPORT_ICON = "./assets/icon/export.svg",
  IMPORT_ICON = "./assets/icon/import.svg",
  REFRESH_ICON = "./assets/icon/restart.svg",
  MENU_DOTS = "./assets/icon/kababMenu.svg",
  SWITCH_FORCE = "./assets/icon/group569.svg",
  DELETE_OUTLINE = "./assets/icon/delete_vector.svg",
  ARROW_UP = "./assets/icon/arrowDropUp.svg",
  ARROW_DOWN = "./assets/icon/arrowDropDown.svg",
  CHEVRON_RIGHT = "./assets/icon/chevronRight.svg",
  CHEVRON_LEFT = "./assets/icon/chevronLeft.svg",
  ADD_FORCE = "./assets/icon/addUser.svg",
  REPORT_GENERATOR = "./assets/icon/reportGeneratorIcon.svg",
  HISTORY = "./assets/icon/baseline-history.svg",
  UP_ARROW_FULL = "./assets/icon/fill.svg",
  DOWN_ARROW_FULL = "./assets/icon/dropdown.svg",
  PERSONAL_ZONE = "./assets/icon/person.svg",
  COMPETENCY = "./assets/icon/competency.svg",
  DATA_HISTORY = "./assets/icon/data-history.svg",
  LEADERS = "./assets/icon/leaders.svg",
  NOTIFICATIONS = "./assets/icon/notifications.svg",
  TRAINING = "./assets/icon/training.svg",
  PENCIL = "./assets/icon/pencil_outline.svg",
  BAGIRA_TEXT_LOGO = "./assets/icon/Bagira_text_logo.svg",
  FILTER = "./assets/icon/filter.svg",
  FILTER_OFF = "./assets/icon/filterOff.svg",
  CLOSE = "./assets/icon/close.svg",
  ARROWS = "./assets/icon/arrows.svg",
  SEARCH = "./assets/icon/search.svg",
  SEARCH_OUTLINE = "./assets/icon/search-outline.svg",
  DATE_RANGE = "./assets/icon/dateRange.svg",
  MICROSOFT_LOGO = "./assets/icon/microsoftLogo.png",
  OPEN_UP = "./assets/icon/open-up.svg",
  OPEN_DOWN = "./assets/icon/open-down.svg",
  OK = "./assets/icon/ok.svg",
  UNSELECTED = "./assets/icon/unselected.svg",
  STOP = "./assets/icon/stop.svg",
  PAUSE = "./assets/icon/pause.svg",
  RESTART = "./assets/icon/restart.svg",
  PLAY = "./assets/icon/play.svg",
  OPEN_MENU = "./assets/icon/open_menu.svg",
  INFO = "./assets/icon/info.svg",
  RECYCLE_BIN = "./assets/icon/recycle_bin.svg",
  DASH = "./assets/icon/dash.svg",
  ADD = "./assets/icon/add.svg",
  ADD_OUTLINE = "./assets/icon/add_outline.svg",
  EXCHANGE_EN = "./assets/icon/exchange_en.svg",
  EXCHANGE_HE = "./assets/icon/exchange_he.svg",
  LOGOUT = "./assets/icon/logout.svg",
  ALERT_TRIANGLE = "./assets/icon/alert-triangle.svg",
  LIST = "./assets/icon/list.svg",
  MINUS = "./assets/icon/minus.svg",
  PLUS = "./assets/icon/plus.svg",
  TRIANGLE = "./assets/icon/triangle.svg",
  XLS_FILE_EXPORT = "./assets/icon/xls-file-export.svg",
  XLS_FILE_IMPORT = "./assets/icon/xls-file-import.svg",
  QUESTION = "./assets/icon/question.svg",
  BAR_ICON = "./assets/icon/bar_icon.svg",
  LINE_CHART_ICON = "./assets/icon/line_chart_icon.svg",
  EXPORT_PDF_LIGHT_MODE = "./assets/icon/pdf_export_light_mode.svg",
  EXPORT_PDF_DARK_MODE = "./assets/icon/pdf_export_dark_mode.svg",
  SITE_STATUS = "./assets/icon/site-management.svg",
  DAHAZE = "./assets/icon/dehaze.svg",
  LANGUAGE = "./assets/icon/language.svg",
  MODE_SELECTION = "./assets/icon/mode_selections.svg",
  ARROW_FORWARD = "./assets/icon/arrow-forward.svg",
  COMPLETE = "./assets/icon/check-circle-outline.svg",
  INCOMPLETE = "./assets/icon/timelapse.svg",
  STAR = "./assets/icon/star.svg",
  STAR_OUTLINE = "./assets/icon/star_outline.svg",
}
export default EIconsSrc;
