import { FC } from "react";
import "./EndTraining.css";
import PMLabel from "../../themeComponents/PMLabel";
import { useTranslation } from "react-i18next";
import PMButton from "../../themeComponents/PMButton";
interface IProps {
  endTrainingHandler: () => void;
  isEndTrainingDisable?: boolean;
}
const EndTraining: FC<IProps> = (props: IProps) => {
  const { endTrainingHandler, isEndTrainingDisable } = props;

  const { t } = useTranslation();

  return (
    <div className="finishTrainingHeats ">
      {/* <PMLabel
          fontFamily="Regular"
          fontSize="medium"
          fontColor="light"
          textAlign="center"
        >
          {`${t("doYouWantToEndTheTrainingNow")} ${t(trainingName)}?`}
        </PMLabel> */}
      <PMButton
        isDisabled={isEndTrainingDisable}
        color="orange"
        size="large"
        cssClass="endTrainingButton"
        label={{
          fontSize: "small",
          fontColor: "xLight",
          fontFamily: "Regular",
        }}
        onClickHandler={endTrainingHandler}
      >
        {`${t("endTraining")}`}
      </PMButton>
      <PMLabel
        textAlign="center"
        fontFamily="Regular"
        fontSize="small"
        fontColor="light"
      >
        {t("calculatedGrade")}
      </PMLabel>
    </div>
  );
};

export default EndTraining;
