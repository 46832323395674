import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./FrameTraining.css";
import { IonPage, IonRow } from "@ionic/react";
import MobileHeader from "../../../Mobile/MobileHeader/MobileHeader";
import TrainingPlanRow from "../TrainingPlanRow";
import NavigationToggle from "../../NavigationToggle/NavigationToggle";
import SingleGunnerComponent from "../SingleGunner/SingleGunnerComponent";
import ObserverComponent from "../Observer/ObserverComponent";
import HeatsMenu from "../HeatsMenu";
import SingleGunnerHeatsHook from "../../../CustomHooks/SingleGunnerHeatsHook";
import ObserverHook from "../../../CustomHooks/ObserverHook";
import NCOHook from "../../../CustomHooks/NCOHook";
import NCOComponent from "./NCOComponent";
import { GorillaTrainingContext } from "../../../../context/GorillaTrainingContext/GorillaTrainingContext";
import {
  EGorillaForces,
  EGorillaTrainings,
  IGorillaTrainingsForces,
} from "../../../../Interfaces/Gorilla/GorillaTrainings";
import { mobileRoutes } from "../../../../services/routeRoles";
import useNavigation from "../../../CustomHooks/useNavigation";
import { ESingleGunnerSteps } from "../../../../Interfaces/Gorilla/IHeatTemplate";
import MobileToast from "../../../CustomHooks/MobileToast";
import useMobileDataPostingParams from "../../../CustomHooks/useMobileDataPostingParams";

const FrameTraining: FC = (): JSX.Element => {
  useMobileDataPostingParams();

  const { t } = useTranslation();
  const { replace } = useNavigation();
  const { presentToast } = MobileToast();
  const { gorillaTrainingsForces } = useContext(GorillaTrainingContext);
  const [trainingsOptions, setTrainingsOptions] = useState<EGorillaTrainings[]>(
    [EGorillaTrainings.SingleGunner, EGorillaTrainings.Observer]
  );

  useEffect(() => {
    const frameTrainingOptions = Object.keys(gorillaTrainingsForces).filter(
      (key) =>
        gorillaTrainingsForces[key as keyof IGorillaTrainingsForces] !==
        undefined
    );
    if (!frameTrainingOptions.length)
      replace(
        `${mobileRoutes.gorillaInitTraining}/${EGorillaTrainings.FrameTraining}`
      );

    setTrainingsOptions(frameTrainingOptions as EGorillaTrainings[]);
    // eslint-disable-next-line
  }, [gorillaTrainingsForces]);

  const [selectedTrainingTypeIndex, setSelectedTrainingTypeIndex] =
    useState<number>(0);
  const {
    malfunctionsOperation,
    saveHeatAndMoveToNext,
    setMalfunctionOperation,
    setShootingExecution,
    setSystemEncryption,
    setTargetsPropertiesResults,
    shootingExecution,
    systemEncryption,
    targetsPropertiesResults,
    activeHeatId,
    addHeat,
    deleteHeat,
    mainNotes,
    resetHeat,
    addNote: addNoteGunner,
    deleteNote: deleteNoteGunner,
    editNote: editNoteGunner,
    setActiveHeat,
    endTrainingHandlerAfterConfirmation:
      endTrainingSingleGunnerHandlerAfterConfirmation,
    setActiveHeatId,
    currentStepType,
    heatsArray,
    setCurrentStepType,
    traineeName: singleGunnerTraineeName,
    isModalOpen,
    setIsModalOpen,
    setSingleGunnerSteps,
    singleGunnerSteps,
    selectedLuncherType,
    isTrainingEnd: isSingleGunnerTrainingEnd,
  } = SingleGunnerHeatsHook();
  const {
    endTrainingHandlerMOAfterConfirmation,
    openAndCover,
    setWorkRoutine,
    workRoutine,
    settingStation,
    conductingQuality,
    targetAcquisition,
    setTargetAcquisition,
    setOpenAndCover,
    setSettingStation,
    setConductingQuality,
    mainNotes: mainNotesObserver,
    addNote: addNoteObserver,
    deleteNote: deleteNoteObserver,
    editNote: editNoteObserver,
    traineeName: ncoTraineeName,
    isTrainingEnd: isObserverTrainingEnd,
  } = ObserverHook();
  const {
    nco,
    setNco,
    endTrainingHandlerAfterConfirmation,
    traineeName: observerTraineeName,
    isTrainingEnd: isNCOTrainingEnd,
  } = NCOHook();
  useEffect(() => {
    //toust
  }, [isNCOTrainingEnd, isObserverTrainingEnd, isSingleGunnerTrainingEnd]);
  useEffect(() => {
    if (
      isSingleGunnerTrainingEnd &&
      (isObserverTrainingEnd ||
        !gorillaTrainingsForces[EGorillaForces.Observer]) &&
      (isNCOTrainingEnd || !gorillaTrainingsForces[EGorillaForces.NCO])
    ) {
      presentToast(
        "success",
        `${t("dataSendSuccess")} ${t("for")} ${t(
          EGorillaTrainings.FrameTraining
        )}`
      );
    }
  }, [isNCOTrainingEnd, isObserverTrainingEnd, isSingleGunnerTrainingEnd]);

  return (
    <>
      {trainingsOptions[selectedTrainingTypeIndex] ===
      EGorillaTrainings.SingleGunner ? (
        <HeatsMenu
          endTrainingHandler={endTrainingSingleGunnerHandlerAfterConfirmation}
          heatsArray={heatsArray}
          activeHeatId={activeHeatId}
          addHeat={addHeat}
          deleteHeat={deleteHeat}
          setActiveHeat={setActiveHeat}
          resetHeat={(heatIndex) => {
            if (heatIndex === activeHeatId)
              setCurrentStepType(ESingleGunnerSteps.SystemEncryption);
            resetHeat(heatIndex);
          }}
          deleteNote={deleteNoteGunner}
          editNote={editNoteGunner}
          addNote={addNoteGunner}
          mainNotes={mainNotes}
          setActiveHeatId={setActiveHeatId}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          isTrainingEnd={isSingleGunnerTrainingEnd}
        ></HeatsMenu>
      ) : null}
      <IonPage>
        <div className="frameTrainingContainer">
          <MobileHeader />
          <TrainingPlanRow
            heatName={t("FrameTraining")}
            isMenuDisabled={
              trainingsOptions[selectedTrainingTypeIndex] !==
              EGorillaTrainings.SingleGunner
            }
            setIsModalOpen={setIsModalOpen}
          ></TrainingPlanRow>
          <IonRow className="navigationToggleRow">
            <NavigationToggle
              navigationOptions={trainingsOptions}
              selectedToggleIndex={selectedTrainingTypeIndex}
              setSelectedToggleIndex={setSelectedTrainingTypeIndex}
            ></NavigationToggle>
          </IonRow>
          <div className="frameTrainingComponentWrap">
            {trainingsOptions[selectedTrainingTypeIndex] ===
            EGorillaTrainings.SingleGunner ? (
              <SingleGunnerComponent
                traineeName={singleGunnerTraineeName}
                currentStepType={currentStepType}
                setCurrentStepType={setCurrentStepType}
                activeHeatId={activeHeatId}
                malfunctionsOperation={malfunctionsOperation}
                saveHeat={saveHeatAndMoveToNext}
                setMalfunctionOperation={setMalfunctionOperation}
                setShootingExecution={setShootingExecution}
                setSystemEncryption={setSystemEncryption}
                setTargetsPropertiesResults={setTargetsPropertiesResults}
                shootingExecution={shootingExecution}
                systemEncryption={systemEncryption}
                targetsPropertiesResults={targetsPropertiesResults}
                isLastHeat={activeHeatId + 1 === heatsArray.length}
                setSingleGunnerSteps={setSingleGunnerSteps}
                singleGunnerSteps={singleGunnerSteps}
                selectedLuncherType={selectedLuncherType!}
              />
            ) : trainingsOptions[selectedTrainingTypeIndex] ===
              EGorillaTrainings.Observer ? (
              <ObserverComponent
                endTrainingHandler={endTrainingHandlerMOAfterConfirmation}
                mainNotes={mainNotesObserver}
                openAndCover={openAndCover}
                settingStation={settingStation}
                conductingQuality={conductingQuality}
                deleteNote={deleteNoteObserver}
                editNote={editNoteObserver}
                addNote={addNoteObserver}
                setOpenAndCover={setOpenAndCover}
                setSettingStation={setSettingStation}
                setConductingQuality={setConductingQuality}
                setTargetAcquisition={setTargetAcquisition}
                setWorkRoutine={setWorkRoutine}
                targetAcquisition={targetAcquisition}
                workRoutine={workRoutine}
                isTrainingEnd={isObserverTrainingEnd}
                traineeName={observerTraineeName}
              />
            ) : (
              <NCOComponent
                nco={nco}
                setNco={setNco}
                endTrainingHandler={endTrainingHandlerAfterConfirmation}
                traineeName={ncoTraineeName}
                isTrainingEnd={isNCOTrainingEnd}
              ></NCOComponent>
            )}
          </div>
        </div>
      </IonPage>
    </>
  );
};

export default FrameTraining;
