import { IExportData, IIdGrade } from "../../../Interfaces/IExportDataForForce";
import {
  ITooltipsOptions,
  IIndicatorsSelection,
} from "../../../Interfaces/IExportReport";
import ILabel from "../../../Interfaces/ILabel";
import ITooltipData from "../../../Interfaces/ITooltipData";
import ITooltipTrainingType from "../../../Interfaces/ITooltipTrainingType";
import ITrainingType from "../../../Interfaces/ITrainingType";

const switchTrainingTypesArray = (trainingTypeTooltipsArray: string[][]) => {
  let maxLength: number = 0;
  let finalTooltipArray: string[][] = [];
  trainingTypeTooltipsArray.forEach((trainingTypeTooltipArray: string[]) => {
    maxLength =
      trainingTypeTooltipArray.length > maxLength
        ? trainingTypeTooltipArray.length
        : maxLength;
  });

  for (let i = 0; i < maxLength; i++) {
    let columnArray: string[] = [];
    for (let j = 0; j < trainingTypeTooltipsArray.length; j++) {
      if (trainingTypeTooltipsArray[j]) {
        const element = trainingTypeTooltipsArray[j][i];
        if (element) {
          columnArray.push(element);
        } else {
          columnArray.push("");
        }
      } else {
        columnArray.push("");
      }
    }
    finalTooltipArray.push(columnArray);
  }

  return finalTooltipArray;
};

const convertTooltipDataToExportData = (
  tooltipData: ITooltipData[] | undefined,
  tooltipsTitlesSelection: ITooltipsOptions[]
): IExportData[] => {
  if (!tooltipData) return [];
  let exportData: IExportData[] = [];
  tooltipData.forEach((tooltip) => {
    if (
      tooltipsTitlesSelection.find(
        (tooltipS) => tooltipS.trainingTypeName === tooltip.trainingTypeName
      )?.selectionsOptions
    ) {
      let ttTooltips = tooltipsTitlesSelection.find(
        (trainingType) =>
          Number(trainingType.trainingTypeId) === Number(tooltip.trainingTypeId)
      );
      if (ttTooltips?.isSelected) {
        let selectiiosj: ITooltipTrainingType[] = [];
        let selectedTooltips: string[] = [];
        ttTooltips.selectionsOptions.forEach((tooltipI) => {
          if (tooltipI.isSelected) selectedTooltips.push(tooltipI.option.label);
        });

        tooltip.trainingTypeTooltip.forEach((tooltips) => {
          if (selectedTooltips.includes(tooltips.name))
            selectiiosj.push(tooltips);
        });
        if (selectiiosj.length)
          exportData.push({
            exportSingleData: selectiiosj,
            id: tooltip.trainingTypeId,
            name: tooltip.trainingTypeName,
            grade: undefined,
          });
      }
    }
  });

  return exportData;
};
const convertHashtagsGradesToExportData = (
  hashtags: any,
  hashtagsSelections: ITooltipsOptions[]
) => {
  let exportDataForHashtags: IExportData[] = [];
  hashtagsSelections.forEach((trainingType) => {
    if (trainingType.isSelected) {
      let hashtagsData: IExportData;
      let hashtagsGradesArray:
        | { hashtag: string; grade: number }[]
        | undefined = hashtags
        ? hashtags[trainingType.trainingTypeId]
        : undefined;

      let newTTArray: ITooltipTrainingType[] = [];
      hashtagsGradesArray?.forEach(
        (hashtag: { hashtag: string; grade: number }) => {
          if (
            trainingType.selectionsOptions.find(
              (option) => option.option.label === hashtag.hashtag
            )?.isSelected
          )
            newTTArray.push({
              value: Number(hashtag?.grade),
              name: hashtag.hashtag,
              id: `${hashtag.hashtag}_${trainingType.trainingTypeId}`,
              isAttended: true,
            });
        }
      );
      if (newTTArray.length) {
        hashtagsData = {
          exportSingleData: newTTArray,
          id: trainingType.trainingTypeId,
          name: trainingType.trainingTypeName,
          grade: undefined,
        };
        exportDataForHashtags.push(hashtagsData);
      }
    }
  });
  return exportDataForHashtags;
};
const convertRadarDataToExportData = (
  forceIndicatorsData: IIdGrade[],
  forceTrainingTypesData: IIdGrade[],
  planAndIndicatorsToTrainingTypes: ILabel[],
  trainingTypes: ITrainingType[],
  indicatorsSelections?: IIndicatorsSelection[]
) => {
  let exportDataForIndicators: IExportData[] = [];
  indicatorsSelections?.forEach((indicator) => {
    if (indicator.isSelected) {
      let indicatorData: IExportData;
      let indicatorGrade: IIdGrade | undefined = forceIndicatorsData.find(
        (indicatorGrade: IIdGrade) =>
          Number(indicatorGrade.id) === Number(indicator.id)
      );
      let trainingTypeArray = planAndIndicatorsToTrainingTypes.filter(
        (trainingType) =>
          trainingTypes.find((tt) => +tt.id === +trainingType.id)
            ?.indicatorId === indicator.id
      );
      let newTTArray: ITooltipTrainingType[] = [];
      trainingTypeArray.forEach((tt) => {
        let ttSelection = indicator.trainingTypeSelections.find(
          (trainingTypeSArray) =>
            Number(trainingTypeSArray.id) === Number(tt.id)
        );
        if (ttSelection?.isSelected) {
          let grade = forceTrainingTypesData?.find(
            (ttGrade: IIdGrade) => Number(ttGrade.id) === Number(tt.id)
          );

          newTTArray.push({
            value: Number(grade?.grade),
            name: tt.label,
            id: tt.id,
            isAttended: grade?.isAttended ? grade?.isAttended : false,
          });
        }
      });
      if (newTTArray.length) {
        indicatorData = {
          exportSingleData: newTTArray,
          id: Number(indicator.id),
          name: indicator.label,
          grade: indicatorGrade?.grade,
        };
        exportDataForIndicators.push(indicatorData);
      }
    }
  });
  return exportDataForIndicators;
};

export {
  switchTrainingTypesArray,
  convertTooltipDataToExportData,
  convertHashtagsGradesToExportData,
  convertRadarDataToExportData,
};
