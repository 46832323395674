import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

const PromptHook = (isToShowPrompt: boolean, message: string) => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    const unblock = history.block((tx) => {
      if (isToShowPrompt) {
        if (window.confirm(t(message))) {
          unblock();
        } else {
          return false;
        }
      }
    });

    return () => {
      unblock();
    };
  }, [isToShowPrompt, history]);
};
export default PromptHook;
