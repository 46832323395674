import { Dispatch, SetStateAction, useEffect } from "react";
import Axios from "../../Axios";
import { baseUrlPMBackend } from "../../Configurations/consts";
import { useQuery } from "@tanstack/react-query";
import IHashtag from "../../Interfaces/IHashtag";

const useGetHashtagsResults = (
  forceId: number | undefined,
  planId: number | undefined,
  trainingTypeId: number | undefined
) => {
  const getHashtagsResults = async (
    forceId?: number,
    planId?: number,
    trainingTypeId?: number
  ) => {
    let forceData = await Axios.get(
      `${baseUrlPMBackend}performanceGrades/getHashtagsResults`,
      {
        params: {
          planId: planId,
          forceId: forceId,
          trainingTypeId: trainingTypeId,
        },
      }
    );

    return forceData.data;
  };
  let query = useQuery<IHashtag[]>({
    queryKey: ["hashtagsResults", [planId, forceId, trainingTypeId]],
    queryFn: () => getHashtagsResults(forceId, planId, trainingTypeId),
    enabled: forceId !== undefined && planId !== undefined,
    staleTime: 100,
  });
  let { data, isLoading } = query;

  return { hashtagsData: data, isLoading };
};
export default useGetHashtagsResults;
