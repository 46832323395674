// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textInsteadOfGraph {
  display: flex;
  justify-content: center;
  padding-top: 20vh;
  font-size: 2vw;
  color: var(--ion-color-fontLight);
  font-family: "Light" !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Desktop/NotFound/NotFoundPlan.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,iBAAiB;EACjB,cAAc;EACd,iCAAiC;EACjC,+BAA+B;AACjC","sourcesContent":[".textInsteadOfGraph {\n  display: flex;\n  justify-content: center;\n  padding-top: 20vh;\n  font-size: 2vw;\n  color: var(--ion-color-fontLight);\n  font-family: \"Light\" !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
