import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Axios from "../../Axios";
import { baseUrlPMBackend } from "../../Configurations/consts";
import IForceFromOrbat from "../../Interfaces/IForceFromOrbat";
import IPlan from "../../Interfaces/IPlan";
import { IForce } from "../../Interfaces/results/force.interface";
import { colors } from "../../Interfaces/Colors";
import { DashboardConstants } from "../../Configurations/DashboardConstants";
import Dictionary from "../../pages/IDictionary";
import {
  IElementResult,
  IIndicatorResult,
  ITrainingTypeResult,
} from "../../Interfaces/dataCalculator";
import { useQuery } from "@tanstack/react-query";
import axios, { CancelTokenSource } from "axios";

export const LETHALITY_ID = -1;
const LETHALITY_NAME = "lethalityGrade";
const useGetCommanderDashboardGrades = (
  selectedPlan: IPlan | undefined,
  force: IForceFromOrbat[],
  refresh: boolean | undefined,
  setRefresh?: Dispatch<SetStateAction<boolean>>
) => {
  const [trainingTypeData, setTrainingTypeData] = useState<Dictionary<any>>();

  const [indicatorData, setIndicatorData] = useState<Dictionary<any>>();
  const [selectedForcesID, setSelectedForcesID] = useState<number[]>([]);
  const [amountOfSoldiers, setAmountOfSoldiers] = useState<number>();
  const [onlySoldiers, setOnlySoldiers] = useState<IForce[]>();
  const setIndicatorsTrainingTypesAndElementsStates = (
    forceDataResults: IForce | undefined,
    selectedForcesID: number[]
  ) => {
    if (!forceDataResults) return;

    let selectedForceRes: IForce = forceDataResults!;
    if (selectedForcesID && selectedForcesID.length) {
      //if selected force exist run all calculation on the selected force (- the orbat force child )
      let selectedForceData: IForce[] | undefined =
        forceDataResults?.children.filter((c) =>
          selectedForcesID.includes(Number(c.id))
        );

      if (selectedForceData) {
        if (selectedForceData.length === 1)
          selectedForceRes = selectedForceData[0];
        else
          selectedForceRes = {
            ...selectedForceRes,
            children: selectedForceData,
          }; //need to get the avarage all over again - not possible for now
      }
    }

    const indicators: Dictionary<any> = [];
    const allChildren = forceDataResults!.children.map((child) => {
      if (
        !selectedForcesID ||
        !selectedForcesID.length ||
        selectedForcesID.includes(Number(child.id))
      )
        //if selected force exist filter the soldiers to be only the selected force children
        return getAllChildren(child);
      return undefined;
    });
    const onlySoldiers: IForce[] = [];
    allChildren.forEach((child) =>
      child?.forEach((grand) => onlySoldiers.push(grand))
    );

    const indicatorsForTrainingType: Dictionary<any> = {};

    let indicatorsResults: IIndicatorResult[] = [
      getLethalityAsIndicator(selectedForceRes),
      ...selectedForceRes?.results.indicatorResults,
    ];
    indicatorsResults.forEach((ir: IIndicatorResult) => {
      const trainingTypes: Dictionary<any> = {};
      const elementsForIndicators = new Map<string, any>();
      ir.trainingTypeResults.forEach((ttr: ITrainingTypeResult) => {
        const elements: Dictionary<any> = {};
        ttr.elementResults.forEach((er) => {
          let soldiersAboveRequired = 0;
          const green: any[] = [];
          const yellow: any[] = [];
          const red: any[] = [];
          const ForcesElementData = {
            green: green,
            yellow: yellow,
            red: red,
          };
          allChildren.forEach((soldiers, index) => {
            const forceElementData = {
              green: {
                value: 0,
                color: colors[index],
              },
              yellow: {
                value: 0,
                color: colors[index],
              },
              red: {
                value: 0,
                color: colors[index],
              },
            };
            soldiers?.forEach((soldier, index) => {
              const cr = getChildResult(soldier, ir, ttr, er);
              if (cr !== null) {
                const color = getElementColor(cr);
                if (color === DashboardConstants.GREEN_GRADE_COLOR) {
                  forceElementData[DashboardConstants.GREEN_GRADE_COLOR][
                    "value"
                  ] += 1;
                } else if (color === DashboardConstants.YELLOW_GRADE_COLOR) {
                  forceElementData[DashboardConstants.YELLOW_GRADE_COLOR][
                    "value"
                  ] += 1;
                } else if (color === DashboardConstants.RED_GRADE_COLOR) {
                  forceElementData[DashboardConstants.RED_GRADE_COLOR][
                    "value"
                  ] += 1;
                }
              }
            });
            ForcesElementData[DashboardConstants.GREEN_GRADE_COLOR].push({
              ...forceElementData.green,
              key: index,
            });
            soldiersAboveRequired += forceElementData.green.value;
            ForcesElementData[DashboardConstants.YELLOW_GRADE_COLOR].push({
              ...forceElementData.yellow,
              key: index,
            });
            ForcesElementData[DashboardConstants.RED_GRADE_COLOR].push({
              ...forceElementData.red,
              key: index,
            });
          });
          if (er.isForDashboard) {
            elements[er.id] = {
              id: er.id,
              name: er.name,
              threshold: er.requiredThreshold,
              lowerThreshold: er.lowerThreshold,
              upperThreshold: er.upperThreshold,
              requiredThreshold: er.requiredThreshold,
              thresholdType: er.thresholdType,
              forcesElementData: ForcesElementData,
              garde: er.grade,
            };
            if (!elementsForIndicators.has(er.name)) {
              elementsForIndicators.set(er.name, {
                name: er.name,
                participants: ttr.participants,
                soldiersAboveRequired: soldiersAboveRequired,
              });
            } else {
              const element = elementsForIndicators.get(er.name);
              elementsForIndicators.set(er.name, {
                name: er.name,
                participants: ttr.participants + element.participants,
                soldiersAboveRequired:
                  soldiersAboveRequired + element.soldiersAboveRequired,
              });
            }
          }
        });

        if (Object.keys(elements).length)
          trainingTypes[ttr.id] = {
            id: ttr.id,
            name: ttr.name,
            threshold: ttr.threshold,
            elements: elements,
            participants: ttr.participants,
            soldierMissed: ttr.soldierMissed,
            grade: ttr.grade,
          };
        setAmountOfSoldiers(ttr.participants + ttr.soldierMissed);
      });
      indicatorsForTrainingType[ir.id] = trainingTypes;
      indicators[ir.id] = Array.from(elementsForIndicators.values()).map(
        (element) => {
          const [value, color] = getElementColorForIndicator(
            element.soldiersAboveRequired,
            element.participants
          );
          return {
            label: element.name,
            value: value,
            color: color,
          };
        }
      );
    });

    setOnlySoldiers(onlySoldiers);
    setIndicatorData(indicators);
    setTrainingTypeData(indicatorsForTrainingType);
  };

  const { forceData, loading } = useGetGrades(
    force[0]?.id,
    selectedPlan?.id,
    refresh,
    setRefresh
  );

  useEffect(() => {
    if (forceData)
      setIndicatorsTrainingTypesAndElementsStates(forceData, selectedForcesID);
    else {
      resetCommanderStates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceData]);

  //filter by selected forces by the legends
  useEffect(() => {
    if (forceData && Number(forceData?.id) === Number(force[0]?.id)) {
      setIndicatorsTrainingTypesAndElementsStates(forceData, selectedForcesID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedForcesID]);

  const resetCommanderStates = () => {
    setAmountOfSoldiers(undefined);
    setIndicatorData(undefined);
    setTrainingTypeData(undefined);
    setSelectedForcesID([]);
  };

  return {
    trainingTypeData,
    setTrainingTypeData,
    indicatorData,
    setIndicatorData,

    forceData,
    amountOfSoldiers,
    setAmountOfSoldiers,
    onlySoldiers,
    selectedForcesID,
    setSelectedForcesID,
    loading,
    resetCommanderStates,
  };
};

// recursive function to find the force data
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getDataFromExistingResults = (
  forceData: IForce,
  forceId: number
): IForce | null => {
  if (Number(forceData?.id) === Number(forceId)) return forceData;
  let children = forceData.children;
  let forceDataToReturn: IForce | null = null;
  if (children.length > 0)
    for (let i = 0; forceDataToReturn == null && i < children.length; i++) {
      forceDataToReturn = getDataFromExistingResults(children[i], forceId);
    }
  return forceDataToReturn;
};
export default useGetCommanderDashboardGrades;

export const getElementColor = (element: IElementResult): string => {
  if (element.value === null) {
    return DashboardConstants.MISSING_GRADE_COLOR;
  }
  const isNegative = element.upperThreshold < element.lowerThreshold;
  // TODO :: Consts should move to config / All of this functions should be in PM-Backend
  if (
    (!isNegative && element.value >= element.requiredThreshold) ||
    (isNegative && element.value <= element.requiredThreshold)
  ) {
    return DashboardConstants.GREEN_GRADE_COLOR;
  } else if (
    (!isNegative &&
      element.value < element.requiredThreshold &&
      element.value >= Math.round(Number(element.upperThreshold) / 2)) ||
    (isNegative &&
      element.value > element.requiredThreshold &&
      element.value <= Math.round(Number(element.lowerThreshold) / 2))
  ) {
    return DashboardConstants.YELLOW_GRADE_COLOR;
  } else {
    return DashboardConstants.RED_GRADE_COLOR;
  }
};
/** return the lethality grade as element result structure */
export const getLethalityAsElement = (force: IForce) => {
  let grade = force.results.grade
    ? Math.round(force.results.grade)
    : force.results.grade;

  return {
    grade: grade,
    id: LETHALITY_ID,
    isForDashboard: true,
    lowerThreshold: 0,
    name: LETHALITY_NAME,
    requiredThreshold: force.results.threshold,
    requiredThresholdPercentage: force.results.threshold,
    thresholdType: "percentage",
    upperThreshold: 100,
    value: grade,
    weight: 100,
  };
};

/** return the lethality grade as indicator result structure */
const getLethalityAsIndicator = (force: IForce) => {
  //count all training types participants and soldiers missed
  let participants = force?.results.indicatorResults
    .map((indicator) => indicator.trainingTypeResults)
    .flat(1)
    .reduce((a, b) => a + b.participants, 0);
  let soldierMissed = force?.results.indicatorResults
    .map((indicator) => indicator.trainingTypeResults)
    .flat(1)
    .reduce((a, b) => a + b.soldierMissed, 0);
  let grade = force.results.grade
    ? Math.round(force.results.grade)
    : force.results.grade;
  return {
    grade: grade,
    id: LETHALITY_ID,
    name: LETHALITY_NAME,
    requiredThreshold: 0,
    rounds: force.results.rounds,
    threshold: force.results.threshold,
    trainingTypeResults: [
      {
        grade: grade,
        id: LETHALITY_ID,
        name: LETHALITY_NAME,
        participants: participants,
        rounds: force.results.rounds,
        soldierMissed: soldierMissed,
        weight: 100,
        threshold: force.results.threshold,
        hashtagsResults: [],
        elements: [],
        tooltipResults: {
          standardDeviation: 0,
          tooltipElementsResults: [],
        },
        requiredThreshold: 0,
        elementResults: [getLethalityAsElement(force)],
      },
    ],
    weight: 100,
  };
};
const getElementColorForIndicator = (
  passingSoldiers: number,
  participants: number
): [number, string] => {
  const percentage = (passingSoldiers / participants) * 100;
  if (isNaN(percentage)) return [0, DashboardConstants.MISSING_GRADE_COLOR];
  else if (percentage >= DashboardConstants.INDICATOR_PASSING_GRADE) {
    return [percentage, DashboardConstants.GREEN_GRADE_COLOR];
  } else if (
    percentage < DashboardConstants.INDICATOR_PASSING_GRADE &&
    percentage >= DashboardConstants.INDICATOR_WARNING_GRADE
  ) {
    return [percentage, DashboardConstants.YELLOW_GRADE_COLOR];
  } else {
    return [percentage, DashboardConstants.RED_GRADE_COLOR];
  }
};

const getAllChildren = (force: IForce): IForce[] => {
  let children: IForce[] = [];

  if (force.children.length > 0) {
    force.children.forEach(
      (child) => (children = children.concat(getAllChildren(child)))
    );
  } else if (force.isSoldier) {
    children.push(force);
  }
  return children;
};
const getChildResult = (
  child: IForce,
  ir: IIndicatorResult,
  ttr: ITrainingTypeResult,
  er: IElementResult
): IElementResult | null => {
  if (er.id === LETHALITY_ID) return getLethalityAsElement(child);
  const childIR = child?.results?.indicatorResults?.find(
    (cir: IIndicatorResult) => cir.id === ir.id
  );
  if (childIR) {
    const childTTR = childIR?.trainingTypeResults.find(
      (cttr: ITrainingTypeResult) => cttr.id === ttr.id
    );
    if (childTTR) {
      const childER = childTTR?.elementResults.find(
        (cer: IElementResult) => cer.id === er.id
      );
      if (childER) {
        return childER;
      }
    }
  }
  return null;
};

export const useGetGrades = (
  forceId: number | undefined,
  planId: number | undefined,
  isRefresh: boolean | undefined,
  setRefresh?: Dispatch<SetStateAction<boolean>>
) => {
  const [cancelTokenSource, setCancelTokenSource] = useState<
    CancelTokenSource | undefined
  >(undefined);

  const getCommanderDashboardGrades = async (
    forceId?: number,
    planId?: number
  ) => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Request canceled due to new request");
    }
    const source = axios.CancelToken.source();
    setCancelTokenSource(source);
    let forceData = await Axios.get(
      `${baseUrlPMBackend}performanceGrades/getDashboardResults`,
      {
        params: {
          planId: planId,
          forceId: forceId,
        },
        cancelToken: source.token,
      }
    );

    return forceData.data;
  };
  let query = useQuery<IForce>({
    queryKey: ["commanderDashboardGrades", [planId, forceId]],
    queryFn: () => getCommanderDashboardGrades(forceId, planId),
    enabled: forceId !== undefined && planId !== undefined,
    staleTime: 100,
  });
  let { data, isLoading } = query;

  useEffect(() => {
    //if refresh clicked refetch the results
    if (isRefresh && forceId !== undefined && planId !== undefined) {
      setRefresh && setRefresh(false);

      query.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh]);

  //set loading to be true if plan id changed

  return { forceData: data, loading: isLoading };
};
