import IPlan from "../Interfaces/IPlan";
import { IIndicator } from "../Interfaces/ITrainingPlan";
import ExcelJS, { BorderStyle, Column } from "exceljs";
import { saveAs } from "file-saver";
import { TFunction } from "i18next";

export const exportTrainingPlan = async (
  plan: IPlan,
  trainingPlanIndicators: IIndicator[] | undefined,
  t: TFunction
) => {
  const borderStyle: BorderStyle = "medium";

  const fontSize = 14;
  let rows: any[] = [];
  const workbook = new ExcelJS.Workbook();
  let worksheet = workbook.addWorksheet("sheet", {
    pageSetup: {
      orientation: "portrait",
      fitToWidth: 1,
      fitToHeight: 0,

      margins: {
        bottom: 0,
        footer: 0,
        left: 0.3,
        top: 0,
        right: 0.3,
        header: 0,
      },
    },

    views: [{ rightToLeft: true }],
  });
  const trainingPlanHeader = getTrainingPlanColumns().map((col) => t(col));
  const trainingPlanData = [
    "",
    plan.name,
    plan.startDate && plan.endDate
      ? `${new Date(plan.startDate)?.getDate()}/${
          new Date(plan.startDate)?.getMonth() + 1
        }-${new Date(plan.endDate)?.getDate()}/${
          new Date(plan.endDate)?.getMonth() + 1
        }/${new Date(plan.endDate)?.getFullYear()}`
      : "",
    "",
    "",
    Number(plan.lethality),
  ];
  rows.push(trainingPlanHeader);
  rows.push(trainingPlanData);
  rows.push([]);
  let columns = getTrainingPlanValuesColumns().map((col) => t(col));
  rows.push(columns);
  rows.push([]);
  let borderRows: number[] = [];
  // Iterate through your data and populate the worksheet
  trainingPlanIndicators?.forEach((indicator) => {
    rows.push([
      indicator.indicatorId,
      t(indicator.name),
      "",
      "",
      Number(indicator.weight),
      Number(indicator.threshold),
    ]);
    // rows.push([]);
    indicator.trainingTypes.forEach((trainingType) => {
      rows.push([
        trainingType.trainingTypeId,
        "",
        t(trainingType.name),
        "",
        Number(trainingType.weight),
        Number(trainingType.threshold),
      ]);
      // rows.push([]);
      trainingType.elements.forEach((element) => {
        rows.push([
          element.elementId,
          "",
          "",
          t(element.name),
          Number(element.weight),
          Number(element.requiredThreshold),
          Number(element.lowerThreshold),
          Number(element.upperThreshold),
          element.isForDashboard,
        ]);
      });
      rows.push([]);
    });
  });
  let columnsObj = [];
  for (let index = 0; index < columns.length; index++) {
    columnsObj.push({
      header: "",
      key: String(index),
      style: { font: { size: fontSize, name: "Ariel" } },
    });
  }
  worksheet.columns = columnsObj;

  worksheet.columns.forEach((column: Partial<Column>, index) => {
    column.width = index === 2 ? 60 : 25;
    column.alignment = { horizontal: "center" };
  });

  rows.forEach((e: string[], index: number) => {
    const rowIndex = index + 1;
    worksheet.addRow({
      ...e,
      amountRemaining: {
        formula: `=C${rowIndex}-D${rowIndex}`,
      },
      percentRemaining: {
        formula: `=E${rowIndex}/C${rowIndex}`,
      },
    });
  });
  borderRows.forEach((rowNumber) => {
    worksheet.getRow(rowNumber).border = {
      bottom: { style: borderStyle },
    };
  });

  worksheet.getRow(2).font = { bold: true, size: fontSize };
  worksheet.getRow(5).font = { bold: true, size: fontSize };
  const hiddenColumn = worksheet.getColumn("A");
  hiddenColumn.hidden = true;

  // Save the workbook
  const filename = plan.name;
  const buffer = await workbook.xlsx.writeBuffer();
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const fileExtension = ".xlsx";
  const blob = new Blob([buffer], { type: fileType });
  saveAs(blob, filename + fileExtension); // Save the file
};
export const getTrainingPlanValuesColumns = () => {
  return [
    "Id",
    "measurement",
    "trainingTypeName",
    "gradeElement",
    "weight",
    "thresholdGrade",
    "lowerThreshold",
    "upperThreshold",
    "isForDashboard",
  ];
};
export const getTrainingPlanColumns = () => {
  return ["", "trainingPlanName", "dates", "", "", "lethalityThreshold"];
};
