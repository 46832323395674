import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";

import DataPostingMobileTemplate from "../../DataPosting/DataPostingMobileTemplate";
import MagnetFOFComponent from "./MagnetFOFComponent";
const MagnetDataPosting: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams<{
    stationName: string;
    trainingTypeId: string;
    stationId: string;
    laneId: string;
    traineeName: string;
    traineeId: string;
    planId: string;
  }>();
  const traineeId = parseInt(params.traineeId);
  const traineeName = params.traineeName;
  const trainingTypeId = parseInt(params.trainingTypeId);
  const laneId = parseInt(params.laneId);
  const stationName = params.stationName;
  const returnToSoldiersList = () => {
    history.goBack();
  };

  return (
    <DataPostingMobileTemplate
      stationName={stationName}
      traineeName={traineeName}
    >
      <MagnetFOFComponent
        laneId={laneId}
        stationName={stationName}
        traineeId={traineeId}
        traineeName={traineeName}
        trainingTypeId={trainingTypeId}
        afterPostHandler={returnToSoldiersList}
      />
    </DataPostingMobileTemplate>
  );
};

export default MagnetDataPosting;
