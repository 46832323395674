import React, { FC } from "react";
import { useContext } from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import { UserContext } from "../../../context/UserContext/userContext";
import { routes, userRoles } from "../../../services/routeRoles";
import { environment } from "../../../Configurations/consts";
import EEnvironment from "../../../Enums/EEnvironment";

interface RouteProps {
  Component: FC<RouteComponentProps>;
  path: string;
  exact?: boolean;
  requiredRoles: string[];
}

const AuthRoute = ({
  Component,
  path,
  exact = false,
  requiredRoles,
}: RouteProps): JSX.Element => {
  const { user, isLoggedIn } = useContext(UserContext);
  const userHasRequiredRole = requiredRoles.includes(user.role);

  return (
    <Route
      exact={exact}
      path={path}
      render={(props: RouteComponentProps) =>
        environment?.toString() === EEnvironment.production &&
        ["/siteManagement"].includes(path) ? (
          <Redirect to={routes.notFound} />
        ) : userHasRequiredRole && isLoggedIn ? (
          <Component {...props} />
        ) : !userHasRequiredRole &&
          isLoggedIn &&
          user.role !== userRoles.Unauthorized ? (
          <Redirect to={routes.unauthorized} />
        ) : user.role === userRoles.Unauthorized ? (
          <Redirect to={routes.noAccess} />
        ) : isLoggedIn &&
          user.role.length &&
          user.role !== userRoles.Unauthorized ? (
          <Redirect to={window.location.pathname} />
        ) : (
          <Redirect to={window.location.pathname} />
        )
      }
    />
  );
};

export default AuthRoute;
