import React, { ReactNode, useState } from "react";
import ForcesListContext from "./forcesListContext";
import ISimpleTrainee from "../../Interfaces/ISimpleTrainee";

const ForcesListContextProvider: React.FC<{ children: ReactNode }> = (props: {
  children: ReactNode;
}) => {
  const [soldiersNamesAndIds, setSoldiersNamesAndIds] = useState<
    ISimpleTrainee[]
  >([]);

  return (
    <ForcesListContext.Provider
      value={{ soldiersNamesAndIds, setSoldiersNamesAndIds }}
    >
      {props.children}
    </ForcesListContext.Provider>
  );
};

export default ForcesListContextProvider;
