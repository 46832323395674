// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* HorizontalProgressBar.css */

.progress-container {
  width: 100%;
  background-color: var(--ion-color-enable);
  height: 16px; /* Adjust the height as needed */
  position: relative;
  border-radius: 4px;
  margin: 5px;
  box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 1);
}

.progress-bar {
  height: 100%;
  line-height: 20px;
  text-align: center;
  position: absolute;
  top: 0;
  background-color: var(--ion-color-success);
  transition: width 0.5s; /* This controls the animation speed for the primary progress bar */
  border-radius: 4px;
}
.progress-bar-moving::before {
  content: "";
  height: 100%;
  width: 0;
  background-color: white;
  border-radius: 4px;
  opacity: 0.2;
  position: absolute;
  top: 0;

  animation: moveSecondaryProgress 3s forwards infinite; /* Adjust the animation duration as needed */
  max-width: 100%; /* Set the maximum width to 100% */
}
.progress-bar-moving::before {
  left: 0;
}
.progress-bar-moving:lang(he)::before {
  right: 0;
}
@keyframes moveSecondaryProgress {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/HorizontalProgressBar/HorizontalProgressBar.css"],"names":[],"mappings":"AAAA,8BAA8B;;AAE9B;EACE,WAAW;EACX,yCAAyC;EACzC,YAAY,EAAE,gCAAgC;EAC9C,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;EACX,6CAA6C;AAC/C;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,MAAM;EACN,0CAA0C;EAC1C,sBAAsB,EAAE,mEAAmE;EAC3F,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,YAAY;EACZ,QAAQ;EACR,uBAAuB;EACvB,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,MAAM;;EAEN,qDAAqD,EAAE,4CAA4C;EACnG,eAAe,EAAE,kCAAkC;AACrD;AACA;EACE,OAAO;AACT;AACA;EACE,QAAQ;AACV;AACA;EACE;IACE,SAAS;EACX;EACA;IACE,WAAW;EACb;AACF","sourcesContent":["/* HorizontalProgressBar.css */\n\n.progress-container {\n  width: 100%;\n  background-color: var(--ion-color-enable);\n  height: 16px; /* Adjust the height as needed */\n  position: relative;\n  border-radius: 4px;\n  margin: 5px;\n  box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 1);\n}\n\n.progress-bar {\n  height: 100%;\n  line-height: 20px;\n  text-align: center;\n  position: absolute;\n  top: 0;\n  background-color: var(--ion-color-success);\n  transition: width 0.5s; /* This controls the animation speed for the primary progress bar */\n  border-radius: 4px;\n}\n.progress-bar-moving::before {\n  content: \"\";\n  height: 100%;\n  width: 0;\n  background-color: white;\n  border-radius: 4px;\n  opacity: 0.2;\n  position: absolute;\n  top: 0;\n\n  animation: moveSecondaryProgress 3s forwards infinite; /* Adjust the animation duration as needed */\n  max-width: 100%; /* Set the maximum width to 100% */\n}\n.progress-bar-moving::before {\n  left: 0;\n}\n.progress-bar-moving:lang(he)::before {\n  right: 0;\n}\n@keyframes moveSecondaryProgress {\n  0% {\n    width: 0%;\n  }\n  100% {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
