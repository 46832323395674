// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggleRow {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  max-height: 50px;
  align-items: center;
  justify-content: center;
}

.selectedToggle {
  --background: var(--ion-color-orange) !important;
}
.toggleCol {
  flex: 1 1;
  height: 100%;
  min-height: 40px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.toggleItem {
  flex: 1 1;
  height: 100%;
  --background: #47484d;
}

.toggleText {
  width: 100%;
  text-align: center;
  font-family: Regular !important;
  color: var(--ion-color-fontLight);
}
.selectedToggle .toggleText {
  color: var(--ion-color-fontDark);
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/Buttons/PMToggle.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,gDAAgD;AAClD;AACA;EACE,SAAO;EACP,YAAY;EACZ,gBAAgB;EAChB,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,SAAO;EACP,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,+BAA+B;EAC/B,iCAAiC;AACnC;AACA;EACE,gCAAgC;AAClC","sourcesContent":[".toggleRow {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: row;\n  max-height: 50px;\n  align-items: center;\n  justify-content: center;\n}\n\n.selectedToggle {\n  --background: var(--ion-color-orange) !important;\n}\n.toggleCol {\n  flex: 1;\n  height: 100%;\n  min-height: 40px;\n  padding: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.toggleItem {\n  flex: 1;\n  height: 100%;\n  --background: #47484d;\n}\n\n.toggleText {\n  width: 100%;\n  text-align: center;\n  font-family: Regular !important;\n  color: var(--ion-color-fontLight);\n}\n.selectedToggle .toggleText {\n  color: var(--ion-color-fontDark);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
