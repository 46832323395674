import { FC, useState } from "react";
import { IonRow } from "@ionic/react";
import "./TroubleshootingSelectionRow.css";
import { useTranslation } from "react-i18next";
import TroubleshootingSelectionModal from "./TroubleshootingSelectionModal";
import PMLabel from "../../themeComponents/PMLabel";
import PMIcon from "../../themeComponents/PMIcon";
import EIconsSrc from "../../../Interfaces/EIconsSrc";

interface IProps {
  malfunctionsOperation: IMalfunctionOperationOptions;
  onChecked: (isChecked: boolean, faultName: string) => void;
}

const TroubleshootingSelectionRow: FC<IProps> = (props: IProps) => {
  const { malfunctionsOperation, onChecked } = props;
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <IonRow className="malfunctionsSelectionRow">
      <TroubleshootingSelectionModal
        closeModal={() => {
          setIsModalOpen(false);
        }}
        isOpen={isModalOpen}
        onCheckedChanged={onChecked}
        malfunctionsOperation={malfunctionsOperation}
      ></TroubleshootingSelectionModal>
      <PMLabel fontFamily="Regular" fontSize="medium" fontColor="light">
        {t("malfunctionsOperation")}
      </PMLabel>
      <PMIcon
        size="xLarge"
        iconSrc={EIconsSrc.LIST}
        onClick={() => {
          setIsModalOpen(true);
        }}
      ></PMIcon>
    </IonRow>
  );
};

export default TroubleshootingSelectionRow;
