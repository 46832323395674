import { FC, useState } from "react";

import "./MedicalStation.css";
import { useHistory, useParams } from "react-router-dom";
import MedicalComponent from "./MedicalComponent";
import DataPostingMobileTemplate from "../../../Mobile/DataPosting/DataPostingMobileTemplate";

const MedicalStation: FC = () => {
  const params = useParams<{
    traineeName: string;
    traineeId: string;
    trainingTypeId: string;
    stationId: string;
    stationName: string;
    planId: string;
  }>();

  const backHandler = () => {
    history.goBack();
  };
  const traineeId = parseInt(params.traineeId);
  const traineeName = params.traineeName;
  const trainingTypeId = parseInt(params.trainingTypeId);

  const stationName = params.stationName;
  const history = useHistory();

  return (
    <DataPostingMobileTemplate
      stationName={stationName}
      traineeName={traineeName}
    >
      <MedicalComponent
        traineeId={traineeId}
        trainingTypeId={trainingTypeId}
        afterPostHandler={backHandler}
      ></MedicalComponent>
    </DataPostingMobileTemplate>
  );
};

export default MedicalStation;
