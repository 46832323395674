// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popoverItem {
  min-width: 140px;
  --color: var(--ion-color-fontLight);
  font-family: "Regular";
  display: flex;
  --background: transparent;
  --background-hover: transparent;
  --background-hover-opacity: 1;
  --inner-padding-end: 0;
  --padding-start: 0;
  direction: ltr;
}
.popoverItem:lang(he) {
  direction: rtl;
}
.popOverDiv {
  padding-inline-start: 8px;
  padding-inline-end: 8px;
}

.popOverDiv:hover {
  opacity: 0.7;
}

.smallPI {
  font-size: small;
}
.mediumPI {
  font-size: medium;
}
.largePI {
  font-size: larger;
}
.xLight {
  --color: var(--ion-color-fontXLight);
}
.light {
  --color: var(--ion-color-fontLight);
}
.dark {
  --color: var(--ion-color-fontWhite);
}
.xDark {
  --color: var(--ion-color-fontWhite);
}
.ExtraLight {
  font-family: "ExtraLight";
}
.ExtraBold {
  font-family: "ExtraBold";
}
.Bold {
  font-family: "Bold";
}
.Light {
  font-family: "Light";
}
.Regular {
  font-family: "Regular";
}
.BxLight {
  background: var(--ion-color-medium);
}
.Blight {
  background: var(--ion-color-light);
}
.Bdark {
  background: var(--ion-color-dark);
}
.BxDark {
  background: var(--ion-color-primary);
}
`, "",{"version":3,"sources":["webpack://./src/components/themeComponents/PopoverItem.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mCAAmC;EACnC,sBAAsB;EACtB,aAAa;EACb,yBAAyB;EACzB,+BAA+B;EAC/B,6BAA6B;EAC7B,sBAAsB;EACtB,kBAAkB;EAClB,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,yBAAyB;EACzB,uBAAuB;AACzB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,oCAAoC;AACtC;AACA;EACE,mCAAmC;AACrC;AACA;EACE,mCAAmC;AACrC;AACA;EACE,mCAAmC;AACrC;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,mBAAmB;AACrB;AACA;EACE,oBAAoB;AACtB;AACA;EACE,sBAAsB;AACxB;AACA;EACE,mCAAmC;AACrC;AACA;EACE,kCAAkC;AACpC;AACA;EACE,iCAAiC;AACnC;AACA;EACE,oCAAoC;AACtC","sourcesContent":[".popoverItem {\n  min-width: 140px;\n  --color: var(--ion-color-fontLight);\n  font-family: \"Regular\";\n  display: flex;\n  --background: transparent;\n  --background-hover: transparent;\n  --background-hover-opacity: 1;\n  --inner-padding-end: 0;\n  --padding-start: 0;\n  direction: ltr;\n}\n.popoverItem:lang(he) {\n  direction: rtl;\n}\n.popOverDiv {\n  padding-inline-start: 8px;\n  padding-inline-end: 8px;\n}\n\n.popOverDiv:hover {\n  opacity: 0.7;\n}\n\n.smallPI {\n  font-size: small;\n}\n.mediumPI {\n  font-size: medium;\n}\n.largePI {\n  font-size: larger;\n}\n.xLight {\n  --color: var(--ion-color-fontXLight);\n}\n.light {\n  --color: var(--ion-color-fontLight);\n}\n.dark {\n  --color: var(--ion-color-fontWhite);\n}\n.xDark {\n  --color: var(--ion-color-fontWhite);\n}\n.ExtraLight {\n  font-family: \"ExtraLight\";\n}\n.ExtraBold {\n  font-family: \"ExtraBold\";\n}\n.Bold {\n  font-family: \"Bold\";\n}\n.Light {\n  font-family: \"Light\";\n}\n.Regular {\n  font-family: \"Regular\";\n}\n.BxLight {\n  background: var(--ion-color-medium);\n}\n.Blight {\n  background: var(--ion-color-light);\n}\n.Bdark {\n  background: var(--ion-color-dark);\n}\n.BxDark {\n  background: var(--ion-color-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
