import { IonPage } from "@ionic/react";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./NoAccess.css";
import { UserContext } from "../../../context/UserContext/userContext";
import MicrosoftLogout from "../../../components/CustomHooks/MicrosoftLogout";

const NoAccess = () => {
  const { logoutUser } = MicrosoftLogout();

  const { t } = useTranslation();
  const { logout } = useContext(UserContext);
  useEffect(() => {
    //after 5 secondes logout the user and refresh the page
    let timeout = setTimeout(() => {
      logout(true);
      logoutUser();

      window.location.replace("/");
    }, 5000);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line
  }, []);
  return (
    <IonPage>
      <h1 className="mainText">{t("noAccess")}</h1>
    </IonPage>
  );
};

export default NoAccess;
