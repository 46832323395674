// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stationsHeader {
  font-size: 17px !important;
  font-family: "light" !important;
  color: var(--ion-color-fontXLight) !important;
  padding-bottom: 2%;
}

.stationsHeadersRow {
  display: flex;
  justify-content: space-between;
  padding-inline-start: 40px;
}

.openElementsStationRow {
  background-color: rgba(255, 255, 255, 0.03);
}

.stationsCol {
  padding: 0;
}
.longWidth {
  width: 16vw !important;
  max-width: 16vw !important;
}
.shortWidth {
  width: 14vw !important;
  max-width: 14vw !important;
}

.stationsRow {
  padding-inline-start: 28px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/TrainingPlan/Stations.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,+BAA+B;EAC/B,6CAA6C;EAC7C,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,0BAA0B;AAC5B;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,UAAU;AACZ;AACA;EACE,sBAAsB;EACtB,0BAA0B;AAC5B;AACA;EACE,sBAAsB;EACtB,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".stationsHeader {\n  font-size: 17px !important;\n  font-family: \"light\" !important;\n  color: var(--ion-color-fontXLight) !important;\n  padding-bottom: 2%;\n}\n\n.stationsHeadersRow {\n  display: flex;\n  justify-content: space-between;\n  padding-inline-start: 40px;\n}\n\n.openElementsStationRow {\n  background-color: rgba(255, 255, 255, 0.03);\n}\n\n.stationsCol {\n  padding: 0;\n}\n.longWidth {\n  width: 16vw !important;\n  max-width: 16vw !important;\n}\n.shortWidth {\n  width: 14vw !important;\n  max-width: 14vw !important;\n}\n\n.stationsRow {\n  padding-inline-start: 28px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
