import React, { Dispatch, useContext, useEffect, useState } from "react";
import { IonCol, IonGrid, IonPage, IonRow } from "@ionic/react";
import TrainingPlansSideBar from "../../../components/Desktop/TrainingPlan/TrainingPlansSideBar";
import TrainingPlansMainView from "../../../components/Desktop/TrainingPlan/TrainingPlansMainView";
import AdminPassword from "../../../components/Desktop/AdminPassword/AdminPassword";
import { UserContext } from "../../../context/UserContext/userContext";
import { useTranslation } from "react-i18next";
import { checkPassword } from "../../../services/passwordsHandler";
import IPlan from "../../../Interfaces/IPlan";
import "./TrainingPlan.css";
import { useDispatch, useSelector } from "react-redux";
import { SET_EDIT_MODE } from "../../../redux/actions/plansActions";
import { ITrainingPlanReducer } from "../../../redux/reducers/trainingPlan";
import { AppState } from "../../../redux/store/plansStore";

const TrainingPlan: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<Dispatch<any>>();

  const { authTp, isTpAuthed } = useContext(UserContext);
  const [invalid, setInvalid] = useState<boolean>(false);
  const [isUnsavedPlan, setIsUnsavedPlan] = useState<boolean>(false);
  const [isShowUnsaveModal, setIsShowUnsaveModal] = useState<boolean>(false);
  const [planToChange, setPlanToChange] = useState<IPlan>();
  const [isShowEditMode, setIsShowEditMode] = useState<boolean>(false);
  const [enableGlobalPlansEditing, setEnableGlobalPlansEditing] =
    useState<boolean>(false);
  const [timeoutID, setTimeoutID] = useState<NodeJS.Timeout>();
  const MAX_SUPER_SOFER_PASSWORD_VALID_MINUTES = 60;
  useEffect(() => {
    if (enableGlobalPlansEditing) {
      //if the plan is opened more then 60 minutes close the the editing mode
      const timeoutID = setTimeout(function () {
        setEnableGlobalPlansEditing(false);
        dispatch({ type: SET_EDIT_MODE, editMode: false });
      }, MAX_SUPER_SOFER_PASSWORD_VALID_MINUTES * 60 * 1000);
      setTimeoutID(timeoutID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableGlobalPlansEditing]);

  useEffect(() => {
    return () => {
      //clear timeout when leveeing the page
      clearTimeout(timeoutID);
    };
  }, [timeoutID]);

  const onSendPasswordHandler = async (password: string) => {
    setInvalid(false);
    const isPasswordValid = await checkPassword(password, setInvalid);
    if (isPasswordValid) authTp();
  };
  const indicatorsState = useSelector<AppState, ITrainingPlanReducer>(
    (state) => state.indicators
  );
  const deepEqual = (obj1: any, obj2: any) => {
    if (obj1 === obj2) {
      return true;
    }

    if (
      typeof obj1 !== "object" ||
      typeof obj2 !== "object" ||
      obj1 === null ||
      obj2 === null
    ) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  };
  const isUnsaved = (): boolean => {
    return (
      isUnsavedPlan ||
      !deepEqual(indicatorsState.indicators, indicatorsState.initIndicators)
    );
  };

  return isTpAuthed ? (
    <IonPage>
      <div className="contentTP">
        <IonGrid className="viewsGrid">
          <IonRow className="viewsRow">
            <IonCol size="2.3" className="viewCol sideBar">
              <TrainingPlansSideBar
                isUnsaved={isUnsaved}
                setIsShowUnsaveModal={setIsShowUnsaveModal}
                setPlanToChange={setPlanToChange}
                setIsShowEditMode={setIsShowEditMode}
                setEnableGlobalPlansEditing={setEnableGlobalPlansEditing}
                enableGlobalPlansEditing={enableGlobalPlansEditing}
              ></TrainingPlansSideBar>
            </IonCol>
            <IonCol className="viewCol mainView">
              <TrainingPlansMainView
                isShowUnsaveModal={isShowUnsaveModal}
                planToChange={planToChange}
                setIsUnsavedPlan={setIsUnsavedPlan}
                setIsShowUnsaveModal={setIsShowUnsaveModal}
                isUnsaved={isUnsaved}
                isShowEditMode={isShowEditMode}
                setEnableGlobalPlansEditing={setEnableGlobalPlansEditing}
              ></TrainingPlansMainView>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </IonPage>
  ) : (
    <IonPage>
      <div className="contentTP">
        <AdminPassword
          onSendPasswordHandler={(password: string) =>
            onSendPasswordHandler(password)
          }
          text={`${t("enterPassword")} ${t("inOrderToDisplayTrainingPlans")}`}
          invalid={invalid}
        ></AdminPassword>
      </div>
    </IonPage>
  );
};

export default TrainingPlan;
