import { FC } from "react";
import { IonCol, IonItem } from "@ionic/react";
import "./HeatMenuRow.css";
import { useTranslation } from "react-i18next";
import PMIcon from "../../themeComponents/PMIcon";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import PMLabel from "../../themeComponents/PMLabel";
interface IProps {
  heatName: string;
  isCompleted: boolean;
  isActive: boolean;
  onClick: () => void;
  isSelected: boolean;
  onDoubleClickHandler: () => void;
}

const HeatMenuRow: FC<IProps> = (props: IProps) => {
  const { heatName, isCompleted, isActive, isSelected } = props;
  const { t } = useTranslation();
  return (
    <div
      onDoubleClick={() => {
        props.onDoubleClickHandler();
      }}
    >
      <IonItem
        onClick={props.onClick}
        lines="none"
        className={`heatMenuRow ${isSelected ? "activeHeat" : ""}`}
      >
        <IonCol size="4" className="heatMenuCol start">
          <PMLabel
            fontFamily={isSelected ? "Bold" : "Regular"}
            fontSize={"large"}
            fontColor={isSelected ? "dark" : "light"}
          >
            {t(heatName)}
          </PMLabel>
        </IonCol>
        <IonCol size="3" className="heatMenuCol">
          {isActive ? (
            <PMLabel
              fontFamily={isSelected ? "Bold" : "Regular"}
              fontSize="large"
              fontColor={isSelected ? "dark" : "light"}
            >
              {t("activeHeat")}
            </PMLabel>
          ) : (
            <PMIcon
              size={"xSmall"}
              iconSrc={isCompleted ? EIconsSrc.OK : EIconsSrc.DASH}
              color={isCompleted ? "success" : isSelected ? "dark" : "light"}
            ></PMIcon>
          )}
        </IonCol>
      </IonItem>
    </div>
  );
};

export default HeatMenuRow;
