import { IonButton, IonLabel, IonSearchbar } from "@ionic/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import IHashtag from "../../../Interfaces/IHashtag";
import HashtagLabel from "./HashtagLabel";
import "./HashtagsLabels.css";

interface HashtagsLabelsProps {
  hashtags: IHashtag[];
  updateHashtag: (hashtag: IHashtag) => void;
  isLoading: boolean;
  selectAllHashtags: () => void;
  clearHashtagsSelection: () => void;
}
const HashtagsLabels = (props: HashtagsLabelsProps) => {
  const [filterText, setFilterText] = useState<string>("");
  const { t } = useTranslation();

  return (
    <div className="labelsDiv">
      <div className="hashtagsSearchDiv">
        <IonSearchbar
          value={filterText}
          onIonChange={(e) => setFilterText(e.detail.value!)}
          className="hashtagsSearch"
          placeholder={t("filter")}
        ></IonSearchbar>
        <IonButton onClick={props.selectAllHashtags}>
          {t("selectAllHashtags")}
        </IonButton>
        <IonButton onClick={props.clearHashtagsSelection}>
          {t("clearHashtags")}
        </IonButton>
      </div>
      <div className="hashtagsLabels scrollS">
        {props.hashtags.length > 0 &&
          props.hashtags
            .filter((hashtag: IHashtag) =>
              hashtag.name ? hashtag.name.includes(filterText) : false
            )
            .map((hashtag: IHashtag) => (
              <HashtagLabel
                key={hashtag.name}
                hashtag={hashtag}
                updateHashtag={(hashtag: IHashtag) =>
                  props.updateHashtag(hashtag)
                }
              ></HashtagLabel>
            ))}
        {props.hashtags.length === 0 && !props.isLoading ? (
          <div className="noHashtags">
            <IonLabel>{t("noHashtagsFound")}</IonLabel>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default HashtagsLabels;
