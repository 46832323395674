import React from "react";
import { Route } from "react-router-dom";
import { Redirect, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// css
import "./GorillaTabs.css";

// ionic imports
import {
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";

// components

// interfaces
import TabItem from "../../../Interfaces/ITabItem";
import { routes } from "../../../services/routeRoles";
import PMLabel from "../../../components/themeComponents/PMLabel";
import FrameTrainingDesktopResults from "./FrameTrainingDesktopResults";
import SingleGunnerDesktopResults from "./SingleGunnerDesktopResults";
import ObserverDesktopResults from "./ObserverDesktopResults";
import { EGorillaTrainings } from "../../../Interfaces/Gorilla/GorillaTrainings";

const GorillaTabs: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const location = useLocation();

  // Tabs buttons options
  const TabsOptions: TabItem[] = [
    {
      url: `${routes.stationView}/${EGorillaTrainings.SingleGunner}`,
      title: t("SingleGunner"),
    },
    {
      url: `${routes.stationView}/${EGorillaTrainings.Observer}`,
      title: t("Observer"),
    },
    {
      url: `${routes.stationView}/${EGorillaTrainings.FrameTraining}`,
      title: t("FrameTraining"),
    },
  ];

  return (
    <IonTabs className="pm-tabs full-opacity">
      <IonRouterOutlet id="main">
        <Route
          path={routes.stationView}
          render={() => (
            <Redirect
              to={`${routes.stationView}/${EGorillaTrainings.SingleGunner}`}
            />
          )}
          exact={true}
        />

        <Route
          path={`${routes.stationView}/${EGorillaTrainings.SingleGunner}`}
          component={SingleGunnerDesktopResults}
          exact
        />
        <Route
          path={`${routes.stationView}/${EGorillaTrainings.Observer}`}
          component={ObserverDesktopResults}
          exact
        />
        <Route
          path={`${routes.stationView}/${EGorillaTrainings.FrameTraining}`}
          component={FrameTrainingDesktopResults}
          exact
        />
      </IonRouterOutlet>
      <IonTabBar
        slot="top"
        className="ion-justify-content-start PRTabs"
        aria-disabled="true"
      >
        {TabsOptions.map((item: TabItem, index: number) => {
          return (
            <IonTabButton
              className={location.pathname === item.url ? "tab-selected" : ""}
              tab={item.title}
              href={item.url}
              key={index}
            >
              <PMLabel
                fontColor="light"
                fontFamily="Light"
                fontSize="medium"
                textAlign="center"
              >
                {t(item.title.trim())}
              </PMLabel>
            </IonTabButton>
          );
        })}
      </IonTabBar>
    </IonTabs>
  );
};
export default GorillaTabs;
