import { FC, SetStateAction } from "react";
import "./SingleGunner.css";

import { useTranslation } from "react-i18next";
import PMLabel from "../../../themeComponents/PMLabel";
import TroubleshootingElement from "../TroubleshootingElement";
import { IonGrid } from "@ionic/react";

type IProps = {
  malfunctionsOperation: IMalfunctionOperationOptions;
  setMalfunctionOperation: (
    value: SetStateAction<IMalfunctionOperationOptions>
  ) => void;
};

const MalfunctionOperation: FC<IProps> = (props: IProps): JSX.Element => {
  const { malfunctionsOperation, setMalfunctionOperation } = props;
  const { t } = useTranslation();
  return (
    <IonGrid>
      <div className="malfunctionOperationWrap">
        <PMLabel
          fontColor="light"
          fontFamily="Light"
          fontSize="medium"
          cssClass="malfunctionOperationTitle"
        >
          {t("selectMalfunctionsToOperate")}
        </PMLabel>
        {Object.keys(malfunctionsOperation).map((key, index) => {
          const typedKey = key as keyof IMalfunctionOperationOptions;
          return (
            <TroubleshootingElement
              key={index}
              fault={{ ...malfunctionsOperation[typedKey], name: typedKey }}
              onCheckedChanged={(isChecked, key) => {
                setMalfunctionOperation((prev) => ({
                  ...prev,
                  [key]: { ...prev[typedKey], isSelected: isChecked },
                }));
              }}
            ></TroubleshootingElement>
          );
        })}
      </div>
    </IonGrid>
  );
};

export default MalfunctionOperation;
