// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-container {
  position: relative;
  width: 95%;
  height: 32px;
  background-color: #202325;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  overflow: hidden;
  margin-inline-start: auto;
  margin-inline-end: auto;
  padding: 2px;
}

.option {
  height: 100%;
  background-color: transparent;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 8px;
  font-family: Light;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #979c9e;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: small;
  font-family: Regular;
}

.option.selected {
  background-color: #3a3f43;
}

.slider {
  position: absolute;
  height: 90%;
  background-color: #3a3f43;
  transition: left 0.3s ease;
  justify-content: center;
  display: flex;
  align-items: center;
  color: var(--ion-color-orange);
  border-radius: 8px;
  margin-inline-end: 0px;
  margin-inline-start: 2px;
  font-family: Regular;
  font-size: small;
  text-align: center;
}
.slider:lang(he) {
  margin-inline-end: 2px;
  margin-inline-start: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/NavigationToggle/NavigationToggle.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;EAClB,gBAAgB;EAChB,yBAAyB;EACzB,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,6BAA6B;EAC7B,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,cAAc;EACd,eAAe;EACf,uDAAuD;EACvD,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,yBAAyB;EACzB,0BAA0B;EAC1B,uBAAuB;EACvB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;EAClB,sBAAsB;EACtB,wBAAwB;EACxB,oBAAoB;EACpB,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,sBAAsB;EACtB,sBAAsB;AACxB","sourcesContent":[".toggle-container {\n  position: relative;\n  width: 95%;\n  height: 32px;\n  background-color: #202325;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border-radius: 8px;\n  overflow: hidden;\n  margin-inline-start: auto;\n  margin-inline-end: auto;\n  padding: 2px;\n}\n\n.option {\n  height: 100%;\n  background-color: transparent;\n  cursor: pointer;\n  transition: 0.3s;\n  border-radius: 8px;\n  font-family: Light;\n  text-align: center;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: #979c9e;\n  cursor: pointer;\n  transition: background-color 0.3s ease, color 0.3s ease;\n  font-size: small;\n  font-family: Regular;\n}\n\n.option.selected {\n  background-color: #3a3f43;\n}\n\n.slider {\n  position: absolute;\n  height: 90%;\n  background-color: #3a3f43;\n  transition: left 0.3s ease;\n  justify-content: center;\n  display: flex;\n  align-items: center;\n  color: var(--ion-color-orange);\n  border-radius: 8px;\n  margin-inline-end: 0px;\n  margin-inline-start: 2px;\n  font-family: Regular;\n  font-size: small;\n  text-align: center;\n}\n.slider:lang(he) {\n  margin-inline-end: 2px;\n  margin-inline-start: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
