// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.microsoftLoginButton {
  height: 55px;
  width: 16vw;
  min-width: 250px;
  background: #2f2f2f;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-left: 12px;
  padding-right: 12px;
  justify-self: center;
  margin-top: 30px;
  border-radius: 3px;
  box-shadow: var(--box-shadow);
}
.microsoftLoginButton:hover {
  opacity: 0.6;
  background-color: var(--ion-color-disabled);
}
.microsoftLoginButton:active {
  opacity: 0.8;
  background-color: var(--ion-color-disabled);
}
.microsoftLogo {
  height: 30px;
  width: 30px;
  margin-inline-start: 12px;
}
.signInText {
  font-family: Segoe UI;
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/MicrosoftLoginButton/MicrosoftLoginButton.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,6BAA6B;EAC7B,kBAAkB;EAClB,mBAAmB;EACnB,oBAAoB;EACpB,gBAAgB;EAChB,kBAAkB;EAClB,6BAA6B;AAC/B;AACA;EACE,YAAY;EACZ,2CAA2C;AAC7C;AACA;EACE,YAAY;EACZ,2CAA2C;AAC7C;AACA;EACE,YAAY;EACZ,WAAW;EACX,yBAAyB;AAC3B;AACA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB","sourcesContent":[".microsoftLoginButton {\n  height: 55px;\n  width: 16vw;\n  min-width: 250px;\n  background: #2f2f2f;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-around;\n  padding-left: 12px;\n  padding-right: 12px;\n  justify-self: center;\n  margin-top: 30px;\n  border-radius: 3px;\n  box-shadow: var(--box-shadow);\n}\n.microsoftLoginButton:hover {\n  opacity: 0.6;\n  background-color: var(--ion-color-disabled);\n}\n.microsoftLoginButton:active {\n  opacity: 0.8;\n  background-color: var(--ion-color-disabled);\n}\n.microsoftLogo {\n  height: 30px;\n  width: 30px;\n  margin-inline-start: 12px;\n}\n.signInText {\n  font-family: Segoe UI;\n  font-weight: 600;\n  font-size: 15px;\n  color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
