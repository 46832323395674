// Dependencies
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { add } from "ionicons/icons";

import { IonCol, IonRow } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Input from "./TrainingPlanInput";
import { IPlansReducer } from "../../../redux/reducers/plans";
import Indicators from "./Indicators";
import {
  ADD_INDICATOR,
  savePlanValues,
  CANCEL_CHANGES,
  FETCH_INDICATORS_SUCCESS,
} from "../../../redux/actions/trainingPlanActions";
import { AppState } from "../../../redux/store/plansStore";

// Interfaces
import IPlan from "../../../Interfaces/IPlan";

// CSS
import "./TrainingPlansMainView.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
  savePlanAttributes,
  SAVE_PLAN_BUTTON_CHANGE,
  SELECTED_PLAN,
  UPDATE_PLAN,
  SET_EDIT_MODE,
} from "../../../redux/actions/plansActions";
import { ITrainingPlanReducer } from "../../../redux/reducers/trainingPlan";
import Alert from "../../Shared/Alert/Alert";
import { UserContext } from "../../../context/UserContext/userContext";
import DatePicker from "../DataPosting/DatePicker";
import { IDateRange } from "../../../Interfaces/IDatePicker";
import customToast from "../../Shared/Toast/CustomToast";
import PMButton from "../../themeComponents/PMButton";
import { IIndicator } from "../../../Interfaces/ITrainingPlan";
import SaveCancelButtons from "../../Shared/Buttons/SaveCancelButtons";
import { validateInput, units } from "../DataPosting/inputValidations";
import { Prompt } from "react-router";
import { compareToInitialObject } from "../../../services/helpers";
import { useTrainingPlanValues } from "../../CustomHooks/HooksWithRQ/useTrainingPlanValues";
import { IErrorState } from "../../CustomHooks/useCheckSumErrors";
import { IndicatorsContext } from "../../../context/IndicatorsContext/IndicatorsContext";

type IProps = {
  isShowUnsaveModal: boolean;
  planToChange: IPlan | undefined;
  setIsShowUnsaveModal: Dispatch<SetStateAction<boolean>>;
  isUnsaved: () => boolean;
  setEnableGlobalPlansEditing: React.Dispatch<React.SetStateAction<boolean>>;
};

const TrainingPlansMainView: React.FC<IProps> = (props: IProps) => {
  const {
    isShowUnsaveModal,
    setIsShowUnsaveModal,
    isUnsaved,
    planToChange,
    setEnableGlobalPlansEditing,
  } = props;
  const { isAdmin } = useContext(UserContext);
  const { t } = useTranslation();
  const [isShowCancelModal, setIsShowCancelModal] = useState<boolean>(false);
  const [isErrorExist, setIsErrorExist] = useState<IErrorState>(
    {} as IErrorState
  );
  const [localIndicators, setLocalIndicators] = useState<IIndicator[]>([]);
  // Selects states
  const plansState = useSelector<AppState, IPlansReducer>(
    (state) => state.plans
  );
  const indicatorsState = useSelector<AppState, ITrainingPlanReducer>(
    (state) => state.indicators
  );

  const { indicators: indicatorsTypes } = useContext(IndicatorsContext);
  const { trainingPlanIndicators, refetch } = useTrainingPlanValues(
    plansState.selectedPlan?.id,
    plansState.selectedPlan?.allPlans
  );
  const dispatch = useDispatch<Dispatch<any>>();
  const fixPlanDateForCompare = (
    plan: IPlan | undefined,
    initialPlan: IPlan | undefined
  ) => {
    let tempPlan = {
      ...plan!,
      startDate: new Date(plan?.startDate!).setHours(0, 0, 0, 0),
      endDate: new Date(plan?.endDate!).setHours(0, 0, 0, 0),
      lethality: Number(plan?.lethality),
      isActive: false,
    };
    let tempInitialPlan = {
      ...initialPlan!,
      startDate: new Date(initialPlan?.startDate!).setHours(0, 0, 0, 0),
      endDate: new Date(initialPlan?.endDate!).setHours(0, 0, 0, 0),
      lethality: Number(plan?.lethality),
      isActive: false,
    };
    return { initialFixedPlan: tempInitialPlan, originalFixedPlan: tempPlan };
  };
  const getDefaults = () => {
    const totalWeight = 100;
    const threshold = 75;
    const percentageUnits = "percentage";
    const indicatorCount = indicatorsState.indicators?.length || 0;

    // Calculate equal weight for indicator
    const indicatorEqualWeight = Math.floor(
      indicatorCount > 0 ? totalWeight / indicatorCount : 0
    );
    let indicators = indicatorsState.indicators?.map((indicator) => {
      const trainingTypesCount = indicator.trainingTypes?.length;

      // Calculate equal weight for training types
      const trainingTypeEqualWeight = Math.floor(
        trainingTypesCount > 0 ? totalWeight / trainingTypesCount : 0
      );
      return {
        ...indicator,
        weight: indicatorEqualWeight,
        threshold: threshold,
        trainingTypes: indicator.trainingTypes?.map((trainingType) => {
          // Calculate equal weight for element
          const elementsCount = trainingType.elements.length;
          const elementEqualWeight = Math.floor(
            elementsCount > 0 ? totalWeight / elementsCount : 0
          );
          return {
            ...trainingType,
            weight: trainingTypeEqualWeight,
            threshold: threshold,
            elements: trainingType.elements.map((element) => ({
              ...element,
              weight: elementEqualWeight,
              lowerThreshold: 0,
              upperThreshold: element.units !== percentageUnits ? 10 : 100,
              requiredThreshold: element.units !== percentageUnits ? 7 : 70,
            })),
          };
        }),
      };
    });

    return indicators;
  };
  //set defaults weights and thresholds for all existing indicators
  const setDefaultWeightAndThresholds = () => {
    const indicators = getDefaults();
    dispatch({
      type: FETCH_INDICATORS_SUCCESS,
      indicators: indicators,
    });
    setLocalIndicators(indicators);
  };

  const initIndicators = () => {
    if (trainingPlanIndicators) {
      setLocalIndicators(trainingPlanIndicators);
    }
    dispatch({
      type: FETCH_INDICATORS_SUCCESS,
      indicators: trainingPlanIndicators,
      initIndicators: trainingPlanIndicators,
    });
  };
  // Handles Add measurement clicks
  const addMeasurementHandler = () => {
    let tempId = Math.random() + 100;
    // If didn't reach maximum indicators number
    if (localIndicators.length < indicatorsTypes.length) {
      dispatch({
        type: ADD_INDICATOR,
        indicator: {
          indicatorId: tempId,
          name: "",
          weight: null,
          threshold: null,
          indicatorRenderId: tempId,
        },
      });
    } else {
      customToast.error(t("maximumIndicators"));
    }
  };

  // Handles changes in date
  const dateChangeHandler = (range: IDateRange) => {
    dispatch({
      type: SELECTED_PLAN,
      selectedPlan: {
        ...plansState.selectedPlan,
        startDate: range.startDate,
        endDate: range.endDate,
      },
    });
  };

  // Handles change in plan details (Mainly name/lethality)
  const onChangePlanDetailsHandler = (field: string, value: string): void => {
    let err: string = validateInput(value, units.Grade);
    // setPlan({ ...plan!, [field]: value !== "" ? value : null, error: err });
    dispatch({
      type: SELECTED_PLAN,
      selectedPlan: {
        ...plansState.selectedPlan,
        [field]: value !== "" ? value : null,
        error: err,
      },
    });
  };
  const hasTrueValue = (errorState: IErrorState): boolean => {
    return Object.values(errorState).some((state) => {
      return Object.values(state).some((value) => value === true);
    });
  };
  // Handles plan save
  const onSaveChanges = () => {
    console.log(isErrorExist);

    if (hasTrueValue(isErrorExist)) {
      customToast.error(t("planHasError"));
      return;
    }
    // On save, close the global plans editing
    setEnableGlobalPlansEditing(false);
    if (isAdmin) {
      // If there're errors, raise toast
      if (plansState.selectedPlan?.error) {
        customToast.error(t("savePlanError"));
      } else {
        dispatch({ type: SAVE_PLAN_BUTTON_CHANGE });
        dispatch({
          type: UPDATE_PLAN,
          plan: {
            id: plansState.selectedPlan?.id,
            name: plansState.selectedPlan?.name,
            startDate: plansState.selectedPlan?.startDate,
            endDate: plansState.selectedPlan?.endDate,
            lethality: plansState.selectedPlan?.lethality,
            isTest: plansState.selectedPlan?.isTest,
          },
        });

        // Use Promise.all to handle both save actions
        Promise.all([
          dispatch(
            savePlanAttributes(
              plansState.selectedPlan!,
              plansState.initialPlan,
              "savePlan",
              t
            )
          ),
          dispatch(
            savePlanValues(
              indicatorsState.indicators,
              indicatorsState.initIndicators,
              plansState.selectedPlan?.id!,
              t
            )
          ),
        ])
          .then(() => {
            // Show success toast if both succeed
            customToast.success(t("planSavedSuccessfull"));
            dispatch({ type: SET_EDIT_MODE, editMode: false });
          })
          .catch((error) => {
            // Handle any errors from either function
            customToast.error(t("errorWhileSavePlan"));
          });
      }
    }
  };

  const onCancelChanges = () => {
    //check for changes
    const { initialFixedPlan, originalFixedPlan } = fixPlanDateForCompare(
      plansState.selectedPlan,
      plansState.initialPlan
    );
    if (
      isUnsaved() ||
      !compareToInitialObject(initialFixedPlan, originalFixedPlan)
    ) {
      setIsShowCancelModal(true);
    } else {
      handleSaveConfirmation();
    }
  };
  const handleSaveConfirmation = () => {
    //on cancel the plan saving close the global plans editing
    setEnableGlobalPlansEditing(false);
    setIsErrorExist({} as IErrorState);
    dispatch({ type: CANCEL_CHANGES });
    dispatch({
      type: SELECTED_PLAN,
      selectedPlan: plansState.initialPlan,
      editMode: false,
    });
  };

  const handleConfirmationToLeavePlan = () => {
    dispatch({
      type: SELECTED_PLAN,
      selectedPlan: planToChange,
      editMode: false,
    });
  };
  useEffect(() => {
    initIndicators();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainingPlanIndicators]);

  useEffect(() => {
    refetch();
    setLocalIndicators([]);
    setIsErrorExist({} as IErrorState);
  }, [plansState.selectedPlan?.id]);

  // Sets plan state to the selected plan

  useEffect(() => {
    setLocalIndicators(indicatorsState.indicators);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indicatorsState.indicators?.length]);

  useEffect(() => {
    setLocalIndicators(indicatorsState.initIndicators);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indicatorsState.initIndicators]);
  // Sets plans according to edit mode

  return plansState.selectedPlan ? (
    <div className="planGrid">
      <Prompt when={isUnsaved()} message={t("noSavePlanChanges")} />

      <IonRow className="row1TP">
        <IonCol
          size="2.93"
          className={`${plansState.editMode ? "" : "viewMode"}`}
        >
          <Input
            inputName="name"
            placeholder={t("name")}
            inputType="text"
            inputValue={plansState.selectedPlan?.name}
            onChangeHandler={onChangePlanDetailsHandler}
            length="long"
            cssClass="tpInput"
            isDisabled={
              !plansState.editMode || plansState.selectedPlan.isGlobal
            }
          ></Input>
        </IonCol>
        {!plansState.selectedPlan.isGlobal && (
          <IonCol
            size="2.58"
            className={`${plansState.editMode ? "" : "viewMode"}`}
          >
            <div>
              <DatePicker
                disabled={!plansState.editMode}
                mode="range"
                rangeDate={{
                  startDate: plansState.selectedPlan.startDate
                    ? new Date(
                        Date.parse(plansState.selectedPlan.startDate.toString())
                      )
                    : new Date(),
                  endDate: plansState.selectedPlan.endDate
                    ? new Date(
                        Date.parse(plansState.selectedPlan.endDate.toString())
                      )
                    : new Date(),
                }}
                setRangeDate={dateChangeHandler}
              />
            </div>
          </IonCol>
        )}
        <IonCol
          size="2.58"
          className={`${plansState.editMode ? "" : "viewMode"}`}
        >
          <Input
            isDisabled={!plansState.editMode}
            inputName="lethality"
            placeholder={t("lethalityThreshold")}
            inputType="number"
            inputValue={plansState.selectedPlan?.lethality}
            onChangeHandler={onChangePlanDetailsHandler}
            length="short"
            cssClass={`tpInput ${
              plansState.selectedPlan.error !== "" ? "tpInputError" : null
            }`}
          ></Input>
        </IonCol>
        <IonCol className={` addButtonTp`} hidden={!plansState.editMode}>
          <PMButton
            color="orange"
            fill="outline"
            onClickHandler={setDefaultWeightAndThresholds}
            isDisabled={!plansState.editMode}
            label={{
              fontSize: "medium",
              fontColor: "light",
              fontFamily: "Regular",
            }}
            cssClass="tpAddButton"
          >
            {t("setDefaultsValues")}
          </PMButton>
          {isAdmin ? (
            <div className="buttonWrap">
              <PMButton
                color="orange"
                fill="outline"
                icon={{ iconSrc: add, color: "orange", size: "large" }}
                onClickHandler={addMeasurementHandler}
                isDisabled={!plansState.editMode}
                label={{
                  fontSize: "medium",
                  fontColor: "light",
                  fontFamily: "Regular",
                }}
                cssClass="tpAddButton"
              >
                {t("addMeasurement")}
              </PMButton>
            </div>
          ) : null}
        </IonCol>
      </IonRow>
      <h1 className="lethalityTitle">{t("lethalityMeasurements")}</h1>
      <IonRow className="indicatorsRow">
        <Indicators
          indicators={localIndicators}
          setIsErrorExist={setIsErrorExist}
        ></Indicators>
      </IonRow>
      <IonRow className="row3TP">
        {plansState.editMode || plansState.editModeDashboard ? (
          <IonRow className="buttons">
            <SaveCancelButtons
              onCancelClickHandler={onCancelChanges}
              onSaveClickHandler={onSaveChanges}
            ></SaveCancelButtons>
          </IonRow>
        ) : null}
      </IonRow>
      <Alert
        header={t("cancelChangesAlert")}
        isOpen={isShowCancelModal}
        setIsOpen={setIsShowCancelModal}
        actionOnSave={handleSaveConfirmation}
        actionOnCancel={() => setIsShowCancelModal(false)}
      />
      <Alert
        header={t("noSavePlanChanges")}
        isOpen={isShowUnsaveModal}
        setIsOpen={setIsShowUnsaveModal}
        actionOnSave={() => handleConfirmationToLeavePlan()}
        actionOnCancel={() => setIsShowUnsaveModal(false)}
      />
    </div>
  ) : null;
};

export default TrainingPlansMainView;
