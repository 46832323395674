import { FC, useEffect, useState } from "react";
import { IonContent, IonPage } from "@ionic/react";
import "./StationsTraineesList.css";
import ITraineeDetails from "../../../../Interfaces/ITraineeDetails";

import { useHistory } from "react-router-dom";
import Axios from "../../../../Axios";
import { baseUrlPMBackend } from "../../../../Configurations/consts";
import MobileHeader from "../../../../components/Mobile/MobileHeader/MobileHeader";
import EIconsSrc from "../../../../Interfaces/EIconsSrc";
import { useTranslation } from "react-i18next";
import IPMCheckOut from "../../../../Interfaces/IPMCheckOut";
import TraineeComponents from "../../../../components/Shared/TraineesList/TraineesListComponent";
import customToast from "../../../../components/Shared/Toast/CustomToast";
import EEventType from "../../../../Interfaces/EEventType";
import ELanguage from "../../../../Enums/ELanguage";
import useMobileDataPostingParams from "../../../../components/CustomHooks/useMobileDataPostingParams";

const StationsTraineesList: FC = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { trainees, trainingTypeId, stationName, planId, stationId, isManual } =
    useMobileDataPostingParams();
  const backHandler = () => {
    history.goBack();
  };
  const [isStateCleaning, setIsStateCleaning] = useState<boolean>(false);
  const [stationTraineesList, setTraineesList] = useState<ITraineeDetails[]>(
    []
  );

  const checkCleaning = async () => {
    sendPMCheckouts(stationCheckedTraineesList);
    setTraineesList((prev: ITraineeDetails[]) => {
      prev = prev.filter(
        (trainee) => !stationCheckedTraineesList.includes(trainee)
      );
      setCheckedTraineesList([]);
      return prev;
    });
    setIsStateCleaning(false);
  };

  const [stationCheckedTraineesList, setCheckedTraineesList] = useState<
    ITraineeDetails[]
  >([]);

  const stopCleaning = () => {
    setIsStateCleaning(false);
  };
  const cleaningHandler = () => {
    setIsStateCleaning(true);
  };
  const sendPMCheckouts = (stationCheckedTraineesList: ITraineeDetails[]) => {
    stationCheckedTraineesList.forEach((trainee) => {
      if (trainee.laneId !== null) {
        const laneCheckOut: IPMCheckOut = {
          MessageType: EEventType.PM_CHECKOUT,
          SoldierID: trainee?.traineeId,
          LaneID: String(trainee?.laneId),
          TimeStamp: new Date(),
          ForceId: trainee?.uniqId,
        };
        Axios.post(
          `${baseUrlPMBackend}checkInOutOperation/PMCheckout`,
          laneCheckOut
        )
          .then((res) => {
            customToast.success(`${t("checkoutSucceed")}`);
          })
          .catch((error) => {
            customToast.error(`${t("checkoutFailed")}`);
            console.error(error);
          });
      }
    });
  };

  const menuHandler = () => {};

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchTrainees = async () => {
      await loadTraineesInStation().then((data: ITraineeDetails[]) => {
        setTraineesList(data);
      });
    };
    const loadTraineesInStation: Function = async () => {
      setIsLoading(true);

      let traineesInStation: ITraineeDetails[] = [];
      try {
        const URI = baseUrlPMBackend + "siteManagement/getTraineesInStation";
        let traineesInStationResponse = await Axios.get(URI, {
          params: {
            stationId: stationId,
          },
        });
        traineesInStation =
          traineesInStationResponse.data === null
            ? []
            : traineesInStationResponse.data;
        setIsLoading(false);
      } catch {
        traineesInStation = [];
        fetchTrainees();
      }
      return traineesInStation;
    };
    setTraineesList([]);
    if (stationId) fetchTrainees();
  }, [stationId]);

  return (
    <IonPage>
      <IonContent className="contentContainerTL">
        <MobileHeader
          iconEndSrc={
            isStateCleaning ? EIconsSrc.CHECK_ICON : EIconsSrc.MENU_ICON
          }
          iconStartSrc={
            i18n.language === ELanguage.he
              ? EIconsSrc.BACK_ICON_HE
              : EIconsSrc.BACK_ICON_EN
          }
          title={isStateCleaning ? t("remove") : t(stationName.trim())}
          iconEndHandler={isStateCleaning ? checkCleaning : menuHandler}
          iconStartHandler={isStateCleaning ? stopCleaning : backHandler}
        />
        <TraineeComponents
          cleaningHandler={cleaningHandler}
          isLoading={isLoading}
          isManual={isManual}
          trainingTypeId={trainingTypeId}
          stationName={stationName}
          stationId={stationId}
          planId={planId}
          isStateCleaning={isStateCleaning}
          setIsLoading={setIsLoading}
          setCheckedTraineesList={setCheckedTraineesList}
          stationTraineesList={stationTraineesList}
          setTraineesList={setTraineesList}
        />
      </IonContent>
    </IonPage>
  );
};
export default StationsTraineesList;
