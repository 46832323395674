import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Axios from "../../Axios";
import { baseUrlPMBackend } from "../../Configurations/consts";
import {
  ITrainingPlanResult,
  IIndicatorResult,
} from "../../Interfaces/dataCalculator";
import IIndicator from "../../Interfaces/ITrendIndicator";
import IForceFromOrbat from "../../Interfaces/IForceFromOrbat";
import IPlan from "../../Interfaces/IPlan";
import IPlanGrade from "../../Interfaces/IPlanGrade";
import ITrendsIndicatorData from "../../Interfaces/ITrendsIndicatorData";
import ITrendsDataForForce from "../../Interfaces/ITrendsDataForForce";
import customToast from "../Shared/Toast/CustomToast";
import { useQuery } from "@tanstack/react-query";
interface IGradesResults {
  planId: number;
  forcesResults: ITrainingPlanResult[];
}

const useGetPerformanceTrends = (
  plans: IPlan[] | undefined,
  newForces: IForceFromOrbat[],
  forcesToRemove: IForceFromOrbat[],
  checkedForces: IForceFromOrbat[],
  indicators: IIndicator[],
  setLoading: (state: boolean) => void,
  isMobile?: boolean
) => {
  const [trendsBarChartsDataMapObj, setTrendsBarChartsDataMapObj] = useState<
    Map<number, Map<number, IPlanGrade[]>> | undefined
  >();
  const [trendsBarChartsData, setTrendsBarChartsData] = useState<
    ITrendsIndicatorData[]
  >([] as ITrendsIndicatorData[]);
  const [isToRefresh, setIsToRefresh] = useState<boolean>(false);

  const setData = (isToConcat: boolean, trendsResults: IGradesResults[]) => {
    setIsToRefresh(false);
    setLoading(false);
    let indicatorsToData: Map<number, Map<number, IPlanGrade[]>> = new Map();
    if (trendsBarChartsDataMapObj && isToConcat)
      setTrendsBarChartsDataMapObj(
        orderResponseAsNeeded(trendsBarChartsDataMapObj, trendsResults)
      );
    else
      setTrendsBarChartsDataMapObj(
        orderResponseAsNeeded(indicatorsToData, trendsResults)
      );
  };

  const orderResponseAsNeeded = (
    indicatorsToData: Map<number, Map<number, IPlanGrade[]>>,
    trendsResults: IGradesResults[]
  ) => {
    if (!indicatorsToData.size || indicatorsToData.size !== indicators.length) {
      indicatorsToData.set(-1, new Map());
      indicators.forEach((indicator) =>
        indicatorsToData.set(indicator.id, new Map())
      );
    }

    trendsResults.forEach((planData: IGradesResults) => {
      planData.forcesResults.forEach((forceData) => {
        //insert the lethality grade
        indicators.forEach((indicator) => {
          let forceGradesData =
            indicatorsToData.get(indicator.id) !== undefined
              ? indicatorsToData.get(indicator.id)!.get(forceData.forceId)
              : undefined;
          if (forceGradesData) {
            indicatorsToData.set(
              indicator.id,
              indicatorsToData.get(indicator.id)!.set(forceData.forceId, [
                ...forceGradesData.filter(
                  (res) => Number(res.id) !== Number(planData.planId)
                ),
                {
                  grade: indicator.id === -1 ? Number(forceData.grade) : 0,
                  id: planData.planId,
                  isAttended:
                    indicator.id === -1 && forceData.grade !== null
                      ? true
                      : false,
                  finalGrade: forceData.grade,
                },
              ])
            );
          } else {
            indicatorsToData?.set(
              indicator.id,
              indicatorsToData.get(indicator.id)!.set(forceData.forceId, [
                {
                  grade: indicator.id === -1 ? Number(forceData.grade) : 0,
                  id: planData.planId,
                  isAttended: forceData.grade !== null,
                  finalGrade: forceData.grade,
                },
              ])
            );
          }
        });

        //insert all the indicators
        forceData.indicatorResults.forEach((indicator: IIndicatorResult) => {
          if (indicator.grade) {
            let forceGradesData =
              indicatorsToData.get(indicator.id) !== undefined
                ? indicatorsToData.get(indicator.id)!.get(forceData.forceId)
                : undefined;
            forceGradesData = forceGradesData?.filter(
              (plan) => Number(plan.id) !== Number(planData.planId)
            );
            if (forceGradesData) {
              indicatorsToData.set(
                Number(indicator.id),
                indicatorsToData
                  .get(Number(indicator.id))!
                  .set(forceData.forceId, [
                    ...forceGradesData,
                    {
                      grade: Number(indicator.grade),
                      id: planData.planId,
                      isAttended: indicator.grade ? true : false,
                      finalGrade: forceData.grade,
                    },
                  ])
              );
            }
          }
        });
      });
    });
    setTrendsBarChartsData(
      getTrendDataAsArray(
        indicatorsToData,
        trendsResults.map((plan) => plan.planId)
      )
    );
    return indicatorsToData;
  };
  const getTrendDataAsArray = (
    indicatorToData: Map<number, Map<number, IPlanGrade[]>>,
    plansIdsArray: number[]
  ) => {
    let orbatForces = Array.from(new Set([...checkedForces, ...newForces]));
    let trendsForcesData: ITrendsIndicatorData[] = [];
    indicatorToData.forEach((indicator, indicatorId) => {
      if (indicator.size < orbatForces.length) {
        orbatForces.forEach((force) => {
          if (
            indicatorToData.get(Number(indicatorId))!.get(force.id) ===
            undefined
          ) {
            let plansArray: IPlanGrade[] = plansIdsArray.map((planId) => {
              return {
                finalGrade: 0,
                grade: 0,
                id: planId,
                isAttended: false,
                name: "",
              };
            });
            indicatorToData.set(
              Number(indicatorId),
              indicatorToData
                .get(Number(indicatorId))!
                .set(force.id, plansArray)
            );
          }
        });
      }

      let forcesData: ITrendsDataForForce[] = [];
      indicator.forEach((plansGrades, forceId) => {
        let myForce = [...checkedForces, ...newForces].find(
          (force) => Number(force.id) === Number(forceId)
        );
        forcesData.push({
          gradesArray: plansGrades.sort((a, b) => a.id)!,
          id: Number(forceId),
          name: myForce ? myForce.name : "",
        });
      });
      trendsForcesData.push({
        indicatorId: Number(indicatorId),
        data: forcesData,
      });
    });
    return trendsForcesData;
  };

  const { trendsResults, query } = useGetTrendsGrades(
    plans,
    isMobile || isToRefresh ? checkedForces : newForces,
    setLoading
  );
  useEffect(() => {
    if (trendsResults && indicators.length) {
      setData(!isMobile && !isToRefresh, trendsResults.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trendsResults, indicators]);

  useEffect(() => {
    if (isToRefresh) {
      setLoading(true);
      query.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isToRefresh]);

  useEffect(() => {
    if (forcesToRemove.length) {
      setTrendsBarChartsData((prev) => {
        return prev.map((indicator: ITrendsIndicatorData) => {
          return {
            ...indicator,
            data: indicator.data.filter(
              (forceIndicator) =>
                forcesToRemove.find(
                  (force) => Number(force.id) === Number(forceIndicator.id)
                ) === undefined
            ),
          };
        });
      });
      setTrendsBarChartsDataMapObj((prev) => {
        prev?.forEach((indicator) => {
          forcesToRemove.forEach((force) => {
            indicator.delete(Number(force.id));
          });
        });
        return prev;
      });
    }
  }, [forcesToRemove]);

  return {
    trendsBarChartsData,
    setIsToRefresh,
  };
};
const useGetTrendsGrades = (
  plans: IPlan[] | undefined,
  forces: IForceFromOrbat[],
  setLoading: (state: boolean) => void
) => {
  const { t } = useTranslation();

  const getAllGradesData = async (
    plans: IPlan[],
    forces: IForceFromOrbat[]
  ) => {
    return await Axios.get(
      `${baseUrlPMBackend}performanceGrades/getTrendsResults`,
      {
        params: {
          forcesIds: forces.map((force) => force.id),
          plansIds: plans.map((plan) => plan?.id),
        },
      }
    );
  };

  let query = useQuery<any>({
    queryKey: ["trendsGrades", [forces]],
    queryFn: () => getAllGradesData(plans || [], forces),
    enabled: forces.length > 0 && plans && plans?.length > 0,
  });
  useEffect(() => {
    if (forces.length) setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forces]);
  let { isError, data: trendsResults } = query;
  useEffect(() => {
    if (isError) customToast.error(t("errorWhileLoadingData"));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);
  return { trendsResults, query };
};
export default useGetPerformanceTrends;
