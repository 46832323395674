import { useContext, useState } from "react";
import {
  IonGrid,
  IonRow,
  IonContent,
  IonPage,
  IonAccordionGroup,
  AccordionGroupCustomEvent,
} from "@ionic/react";
import "./SiteStatus.css";
import MobileHeader from "../../../components/Mobile/MobileHeader/MobileHeader";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import StationAccordion from "./StationAccordion/StationAccordion";
import PMLabel from "../../../components/themeComponents/PMLabel";
import { useTranslation } from "react-i18next";
import { StationsContext } from "../../../context/StationsContext/StationsContext";

const SiteStatus = () => {
  const { stations } = useContext(StationsContext);
  const handleScroll = () => {};
  const [currentOpenStationId, setCurrentOpenStationId] = useState<number>();
  const accordionGroupChange = (e: AccordionGroupCustomEvent) => {
    setCurrentOpenStationId(Number(e.detail.value));
  };
  const { t } = useTranslation();
  return (
    <IonPage className="contentContainerSBL">
      <IonContent className="contentContainerSBL" scrollY={false}>
        <MobileHeader iconEndSrc={EIconsSrc.MENU_ICON} />
        <IonGrid className="gridContainerSBL">
          <IonRow className="siteStatusTitle">
            <PMLabel fontSize="medium" fontColor={"light"} fontFamily={"Bold"}>
              {`${t("siteManagement")} `}
            </PMLabel>
          </IonRow>
          <IonRow className="mobile_ss_list_row" onScroll={handleScroll}>
            <IonAccordionGroup
              className="accordion-list"
              onIonChange={accordionGroupChange}
            >
              {stations
                .filter((station) => station.isCheckIn)
                ?.map((station) => (
                  <StationAccordion
                    key={station.stationId}
                    station={station}
                    isOpen={currentOpenStationId === station.stationId}
                  ></StationAccordion>
                ))}
            </IonAccordionGroup>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default SiteStatus;
