import { FC } from "react";
import "./Billiard.css";
import DataPostingMobileTemplate from "../../../components/Mobile/DataPosting/DataPostingMobileTemplate";
import { useHistory } from "react-router";
import BilliardComponent from "./BilliardComponent";
import useMobileDataPostingParams from "../../../components/CustomHooks/useMobileDataPostingParams";

const Billiard: FC = (): JSX.Element => {
  const history = useHistory();

  const { planId, trainees, trainingTypeId, stationName } =
    useMobileDataPostingParams();

  const backHandler = () => {
    history.goBack();
  };

  return (
    <DataPostingMobileTemplate stationName={stationName} traineeName={""}>
      <BilliardComponent
        trainees={trainees}
        trainingTypeId={Number(trainingTypeId)}
        afterPostHandler={backHandler}
        planId={Number(planId)}
      ></BilliardComponent>
    </DataPostingMobileTemplate>
  );
};
export default Billiard;
