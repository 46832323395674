import { FC, useEffect, useState } from "react";
import { IonCol, IonModal, IonRow } from "@ionic/react";
import "./TroubleshootingSelectionModal.css";
import { useTranslation } from "react-i18next";
import SaveCancelButtons from "../Buttons/SaveCancelButtons";
import PMLabel from "../../themeComponents/PMLabel";
import TroubleshootingElement from "./TroubleshootingElement";

interface IProps {
  isOpen: boolean;
  malfunctionsOperation: IMalfunctionOperationOptions;
  onCheckedChanged: (state: boolean, faultName: string) => void;
  closeModal: () => void;
}

const TroubleshootingSelectionModal: FC<IProps> = (props: IProps) => {
  const { isOpen, malfunctionsOperation, onCheckedChanged, closeModal } = props;
  const { t } = useTranslation();
  const [tempState, setTempState] = useState<{ [key: string]: boolean }>({});
  useEffect(() => {
    Object.keys(malfunctionsOperation).forEach((key) => {
      const typedKey = key as keyof IMalfunctionOperationOptions;

      setTempState((prev) => ({
        ...prev,
        [key]: malfunctionsOperation[typedKey].isSelected,
      }));
    });
  }, [isOpen, malfunctionsOperation]);

  const onCancel = () => {
    setTempState({});
    closeModal();
  };
  const onSave = () => {
    Object.keys(tempState).forEach((key) => {
      const typedKey = key as keyof IMalfunctionOperationOptions;
      onCheckedChanged(tempState[key], typedKey);
    });
    closeModal();
  };

  return (
    <IonModal
      showBackdrop
      isOpen={isOpen}
      className="selectionFaultModal"
      onDidDismiss={onCancel}
    >
      <div className="faultsModalContent">
        <IonRow className="faultsTitleRow">
          <PMLabel fontFamily="Regular" fontSize="medium" fontColor="light">
            {t("selectMalfunctions")}
          </PMLabel>
        </IonRow>
        <IonRow className="faultsList">
          <IonCol className="faultsListCol">
            {Object.keys(malfunctionsOperation).map((faultKey, index) => {
              const typedKey = faultKey as keyof IMalfunctionOperationOptions;

              return (
                <div className="malfunctionsElementWrap" key={index}>
                  <TroubleshootingElement
                    fault={{
                      ...malfunctionsOperation[typedKey],
                      name: typedKey,
                      isSelected: tempState[typedKey],
                    }}
                    key={index}
                    onCheckedChanged={(isChecked, key) => {
                      setTempState((prev) => ({ ...prev, [key]: isChecked }));
                    }}
                  ></TroubleshootingElement>
                </div>
              );
            })}
          </IonCol>
        </IonRow>
        <IonRow className="saveCancelRowFModal">
          <SaveCancelButtons
            onCancelClickHandler={onCancel}
            onSaveClickHandler={onSave}
            saveText="select"
          ></SaveCancelButtons>
        </IonRow>
      </div>
    </IonModal>
  );
};

export default TroubleshootingSelectionModal;
