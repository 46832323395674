const instructorFeedbackElementsData: { [key: string]: number } = {
  SingleMovementAndAnnouncements: 3,
  SinglePractice: 4,
  SingleWeaponOperations: 2,
  CoupleSequenceAndCovering: 4,
  CoupleCommunicationAndAnnouncements: 3,
  CouplePractice: 3,
  CoupleCommandAndControl: 2,
  ClassAdvancementPathShelterExploit: 3,
  ClassAnnounceAndCommands: 3,
  ClassPractice: 3,
  ClassWeaponsOperationsAndTargetEngagement: 3,
};

export { instructorFeedbackElementsData };
