import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./OnlineBadge.css";

import { baseUrlPMBackendBasic } from "../../../Configurations/consts";
import PMLabel from "../../themeComponents/PMLabel";
interface IDateTime {
  date: string;
  time: string;
}
const OnlineBadge: React.FC = () => {
  const { t } = useTranslation();
  const intervalTimeOutSeconds = 10;
  const intervalRef = useRef<any>(null);
  const getInFormat = (number: number) => {
    let formattedNumber = number.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    return formattedNumber;
  };
  const getCurrentDateDateTime = () => {
    let today = new Date();

    const date: string = today.getDate() + "/" + (today.getMonth() + 1);

    const time = `${getInFormat(today.getHours())}:${getInFormat(
      today.getMinutes()
    )}:${getInFormat(today.getSeconds())}`;

    return { date: date, time: time };
  };

  const [isOnline, setIsOnline] = useState<boolean>(false);
  const [lastUpdate, setLastUpdate] = useState<IDateTime>(
    getCurrentDateDateTime()
  );
  const checkConnection = async () => {
    const timeout = new Promise((resolve, reject) => {
      setTimeout(reject, 1000, "Request timed out");
    });
    const request =
      baseUrlPMBackendBasic &&
      fetch(baseUrlPMBackendBasic, {
        headers: new Headers({
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        }),
      });
    try {
      await Promise.race([timeout, request]);
      setIsOnline(true);
      setLastUpdate(getCurrentDateDateTime());
    } catch (error) {
      setIsOnline(false);
    }
  };
  useEffect(() => {
    setLastUpdate(getCurrentDateDateTime());
    checkConnection();
    intervalRef.current && clearInterval(intervalRef.current);
    intervalRef.current = setInterval(
      () => checkConnection(),
      intervalTimeOutSeconds * 1000
    );
    return () => {
      setLastUpdate(getCurrentDateDateTime());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="onlineBadge">
      <span className={"dot " + (isOnline ? "green" : "red")}></span>
      <PMLabel fontSize="medium" fontColor="light" fontFamily="Light">
        {isOnline ? t("online") : t("lastUpdate")}
      </PMLabel>
      <PMLabel fontSize="medium" fontColor="light" fontFamily="Light">
        {isOnline ? "" : `${lastUpdate.date}  ${lastUpdate.time}`}
      </PMLabel>
    </div>
  );
};

export default OnlineBadge;
