// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leadersColLD {
  background-color: var(--ion-color-backgroundPrimary) !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto !important;
  width: 100%;
  padding: 2% !important;
  padding-top: 0 !important;
}

.leadersTitleLD {
  --background: transparent !important;
  --color: #fffffff5;
  --padding-start: 25px !important;
  --padding-end: 25px !important;
  font-size: large;
}
.titleLabel {
  font-family: "Regular" !important;
  font-size: x-large !important;
}

.rowLeadersData {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  width: 100%;
  overflow-y: auto;
}
.leadersChart {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.legendRowLeaders {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: min-content;
  align-items: flex-start;
  justify-content: flex-start;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Desktop/PerformanceDisplay/PerformanceTabs/PerformanceLeaders/PerformanceLeaders.css"],"names":[],"mappings":"AAAA;EACE,+DAA+D;EAC/D,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,WAAW;EACX,sBAAsB;EACtB,yBAAyB;AAC3B;;AAEA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,gCAAgC;EAChC,8BAA8B;EAC9B,gBAAgB;AAClB;AACA;EACE,iCAAiC;EACjC,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAO;EACP,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;EACvB,2BAA2B;AAC7B","sourcesContent":[".leadersColLD {\n  background-color: var(--ion-color-backgroundPrimary) !important;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow-y: auto !important;\n  width: 100%;\n  padding: 2% !important;\n  padding-top: 0 !important;\n}\n\n.leadersTitleLD {\n  --background: transparent !important;\n  --color: #fffffff5;\n  --padding-start: 25px !important;\n  --padding-end: 25px !important;\n  font-size: large;\n}\n.titleLabel {\n  font-family: \"Regular\" !important;\n  font-size: x-large !important;\n}\n\n.rowLeadersData {\n  display: flex;\n  flex-direction: row;\n  flex: 1;\n  width: 100%;\n  overflow-y: auto;\n}\n.leadersChart {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  width: 100%;\n}\n\n.legendRowLeaders {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  height: min-content;\n  align-items: flex-start;\n  justify-content: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
