// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rateInputWrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  direction: ltr;
}
.inputTableInput {
  width: 40%;
  background-color: var(--ion-color-enable);
  border: none;
  font-family: Regular;
  color: var(--ion-color-fontLight);
  font-size: medium;
  font-weight: 300;
  text-align: center;
}
.wrongValue {
  color: var(--ion-color-danger);
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/RateInput/RateInput.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,cAAc;AAChB;AACA;EACE,UAAU;EACV,yCAAyC;EACzC,YAAY;EACZ,oBAAoB;EACpB,iCAAiC;EACjC,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,8BAA8B;AAChC","sourcesContent":[".rateInputWrap {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  direction: ltr;\n}\n.inputTableInput {\n  width: 40%;\n  background-color: var(--ion-color-enable);\n  border: none;\n  font-family: Regular;\n  color: var(--ion-color-fontLight);\n  font-size: medium;\n  font-weight: 300;\n  text-align: center;\n}\n.wrongValue {\n  color: var(--ion-color-danger);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
