// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hitsAnalyzeCol {
  flex: 1 1;
  display: flex;
  overflow-y: auto;
  overflow-x: auto;
  justify-content: center;
}

.hitsAnalyzeRow {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  max-height: 500px;
  width: 100%;
}
.iconAndHitsWrap {
  width: 70px;
  height: 35px;
  background-color: #3b3d42;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.iconTeatHitAnalyzed {
  text-align: end;
  display: flex;
  justify-content: flex-end;
  min-width: 27px;
}
.gorillaHitsIcon {
  color: #a4a4a4;
}

.gorillaHitsIcon.disabled {
  opacity: 0.3;
}

.targetType {
  height: 100%;
  padding-top: 20px;
}

.hitsAnalyzeTableRow {
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.hitsAnalyzeTableRow .titleRowHA {
  width: 70px;
  margin-inline-start: 5px;
  margin-inline-end: 5px;
}
.hitsAnalyzeIconRow {
  margin: 5px;
}
.hitsAnalyzeTableRow.marginBottom {
  margin-bottom: 20px;
}
.targetTypeTitle {
  height: 45px;
}
.targetsDataColWrap {
  display: flex;
  flex-direction: row;
}
.targetTypeName {
  width: 100%;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Desktop/Gorilla/HitsAnalyze.css"],"names":[],"mappings":"AAAA;EACE,SAAO;EACP,aAAa;EACb,gBAAgB;EAChB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,iBAAiB;EACjB,WAAW;AACb;AACA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,aAAa;EACb,yBAAyB;EACzB,eAAe;AACjB;AACA;EACE,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,WAAW;EACX,wBAAwB;EACxB,sBAAsB;AACxB;AACA;EACE,WAAW;AACb;AACA;EACE,mBAAmB;AACrB;AACA;EACE,YAAY;AACd;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,aAAa;AACf","sourcesContent":[".hitsAnalyzeCol {\n  flex: 1;\n  display: flex;\n  overflow-y: auto;\n  overflow-x: auto;\n  justify-content: center;\n}\n\n.hitsAnalyzeRow {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-height: 500px;\n  max-height: 500px;\n  width: 100%;\n}\n.iconAndHitsWrap {\n  width: 70px;\n  height: 35px;\n  background-color: #3b3d42;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-evenly;\n  align-items: center;\n}\n.iconTeatHitAnalyzed {\n  text-align: end;\n  display: flex;\n  justify-content: flex-end;\n  min-width: 27px;\n}\n.gorillaHitsIcon {\n  color: #a4a4a4;\n}\n\n.gorillaHitsIcon.disabled {\n  opacity: 0.3;\n}\n\n.targetType {\n  height: 100%;\n  padding-top: 20px;\n}\n\n.hitsAnalyzeTableRow {\n  height: 45px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n}\n.hitsAnalyzeTableRow .titleRowHA {\n  width: 70px;\n  margin-inline-start: 5px;\n  margin-inline-end: 5px;\n}\n.hitsAnalyzeIconRow {\n  margin: 5px;\n}\n.hitsAnalyzeTableRow.marginBottom {\n  margin-bottom: 20px;\n}\n.targetTypeTitle {\n  height: 45px;\n}\n.targetsDataColWrap {\n  display: flex;\n  flex-direction: row;\n}\n.targetTypeName {\n  width: 100%;\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
