import { FC, useEffect, useState } from "react";
import "./StationAccordion.css";
import { IonAccordion, IonItem } from "@ionic/react";
import { caretDown, trailSign } from "ionicons/icons";
import IStationDetails from "../../../../Interfaces/IStationDetails";
import PMLabel from "../../../../components/themeComponents/PMLabel";
import { useTranslation } from "react-i18next";
import Axios from "../../../../Axios";
import { baseUrlPMBackend } from "../../../../Configurations/consts";
import ITraineeDetails from "../../../../Interfaces/ITraineeDetails";
import EEventType from "../../../../Interfaces/EEventType";
import { useWebsocketCheckIn } from "../../../../services/siteManagementSocket";
import ICheckInOutData from "../../../../Interfaces/ICheckInData";
import StationAccordionItem from "../StationAccordionItem/StationAccordionItem";
import { getForceFullName } from "../../../../services/helpers";
import SearchBar from "../../../../components/Shared/SearchBar/SearchBar";
interface IProps {
  station: IStationDetails;
  isOpen: boolean;
}
const StationAccordion: FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();
  const [stationTraineesList, setTraineesList] = useState<ITraineeDetails[]>(
    []
  );
  const [filteredStationTraineesList, setFilteredTraineesList] = useState<
    ITraineeDetails[]
  >([]);
  const [searchText, setSearchText] = useState<string>("");
  const [isNewInColumn, setNewInColumn] = useState<boolean>(false);

  const loadTraineesInStation: Function = async () => {
    let traineesInStation: ITraineeDetails[] = [];
    try {
      const URI = baseUrlPMBackend + "siteManagement/getTraineesInStation";
      let traineesInStationResponse = await Axios.get(URI, {
        params: {
          stationId: props.station.stationId,
        },
      });
      traineesInStation =
        traineesInStationResponse.data === null
          ? []
          : traineesInStationResponse.data;
    } catch {
      traineesInStation = [];
      fetchTrainees();
    }
    return traineesInStation;
  };
  const fetchTrainees = async () => {
    await loadTraineesInStation().then((data: ITraineeDetails[]) => {
      setTraineesList(data);
    });
  };
  const [receivedMessage] = useWebsocketCheckIn();
  const addTraineeToStation = (receivedMessage: ICheckInOutData) => {
    removeTraineeFromStation(receivedMessage, true);

    if (Number(receivedMessage.stationId) === Number(props.station.stationId)) {
      setNewInColumn(true);
      let interval = setInterval(() => {
        setNewInColumn(false);
        clearInterval(interval);
        setTraineesList((prev) =>
          prev.map((trainee) => ({ ...trainee, newInColumn: false }))
        );
      }, 3000);
      setTraineesList((prev) => [
        ...prev,
        {
          ...receivedMessage.traineeDetails,
          newInColumn: true,
          traineeName: getForceFullName(
            receivedMessage.traineeDetails.traineeName,
            receivedMessage.traineeDetails.forceType,
            t
          ),
        },
      ]);
    }
  };
  const removeTraineeFromStation = (
    receivedMessage: ICheckInOutData,
    isRemoveFromAllStations?: boolean
  ) => {
    if (
      isRemoveFromAllStations ||
      Number(receivedMessage.stationId) === Number(props.station.stationId)
    ) {
      setTraineesList((prev) =>
        prev.filter(
          (trainee) =>
            Number(trainee.uniqId) !==
            Number(receivedMessage.traineeDetails.uniqId)
        )
      );
    }
  };

  useEffect(() => {
    switch (receivedMessage?.eventType) {
      case EEventType.CHECK_IN:
        addTraineeToStation(receivedMessage);
        break;
      case EEventType.CHECK_OUT:
        removeTraineeFromStation(receivedMessage);
        break;
      case EEventType.SITE_CHECKOUT:
        removeTraineeFromStation(receivedMessage, true);
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receivedMessage]);

  useEffect(() => {
    setTraineesList([]);
    if (props.station.stationId) fetchTrainees();
  }, [props.station.stationId]);
  useEffect(() => {
    setFilteredTraineesList(
      stationTraineesList.filter(
        (trainee) =>
          !searchText.length ||
          getForceFullName(trainee.traineeName, trainee.forceType, t).includes(
            searchText
          )
      )
    );
  }, [searchText, stationTraineesList]);
  return (
    <IonAccordion
      key={props.station.stationId}
      value={String(props.station.stationId)}
      toggleIcon={caretDown}
      className="stationAccordion"
      readonly={!stationTraineesList.length}
    >
      <IonItem slot="header" color="dark" className="stationAccordionItem">
        <PMLabel
          fontSize="large"
          fontColor={isNewInColumn ? "success" : "light"}
          fontFamily={props.isOpen ? "Bold" : "Regular"}
        >
          {`${t(props.station.stationName)} (${stationTraineesList.length})`}
        </PMLabel>
      </IonItem>

      <div slot="content" className="siteStatusTrainees">
        {stationTraineesList.length >= 10 ? (
          <div className="siteStatusSearchRow">
            <SearchBar
              placeholder={t("searchForce")}
              onChange={(text) => setSearchText(text)}
              value={searchText}
            ></SearchBar>
          </div>
        ) : null}
        {filteredStationTraineesList.map((trainee, index) => (
          <StationAccordionItem
            traineeDetails={trainee}
            key={index}
          ></StationAccordionItem>
        ))}
      </div>
    </IonAccordion>
  );
};
export default StationAccordion;
