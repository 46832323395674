// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selectorInput {
  width: 100%;
  pointer-events: none;
  font-size: medium !important;
}
.selectorInputWrap:active {
  opacity: 0.8;
}
.selectForceModal {
  --bottom: 10%;
}
.searchbarForcesModal {
  color: var(--ion-color-fontWhite);
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/MenualStation/SearchSoldierComp/SearchForceSelect.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,oBAAoB;EACpB,4BAA4B;AAC9B;AACA;EACE,YAAY;AACd;AACA;EACE,aAAa;AACf;AACA;EACE,iCAAiC;AACnC","sourcesContent":[".selectorInput {\n  width: 100%;\n  pointer-events: none;\n  font-size: medium !important;\n}\n.selectorInputWrap:active {\n  opacity: 0.8;\n}\n.selectForceModal {\n  --bottom: 10%;\n}\n.searchbarForcesModal {\n  color: var(--ion-color-fontWhite);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
