import { IonRow, IonItem, IonRadioGroup, IonRadio } from "@ionic/react";
import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { useTranslation } from "react-i18next";
import EEventType from "../../../../Interfaces/EEventType";
import SwitchButton from "../../../Mobile/SiteManagement/ManualStations/SwitchButton";
import Timer from "react-compound-timer";
import "./MedicalComponent.css";
import customToast from "../../Toast/CustomToast";
import PMTitle from "../../../themeComponents/PMTitle";
import PMButton from "../../../themeComponents/PMButton";
import PMLabel from "../../../themeComponents/PMLabel";
import { sendDrillToBBackend } from "../../../Desktop/DataPosting/dataPostingHelpers";
import { IMobileDataPostingProps } from "../../../../Interfaces/IMobileDataPostingProps";
interface IProps extends IMobileDataPostingProps {
  traineeId: number;
  trainingTypeId: number;
  afterPostHandler?: () => void;
  isDesktop?: boolean;
}
const MedicalComponent: React.FC<IProps> = (props: IProps): JSX.Element => {
  const {
    traineeId,
    trainingTypeId,
    afterPostHandler,
    isDesktop,
    isSendClicked,
  } = props;
  const [selected, setSelected] = useState<"timer" | "manual">("timer");
  const [timerSeconds, setTimer] = useState<number>(0);

  const { t } = useTranslation();
  let startDateTime: Date = new Date();

  const [isThreatNeutralized, setThreatNeutralize] = useState<
    boolean | undefined
  >(undefined);
  const [isSecured, setSecuring] = useState<boolean | undefined>(undefined);
  const [isBleedStops, setBleedingStop] = useState<boolean | undefined>(
    undefined
  );
  const [isReported, setReport] = useState<boolean | undefined>(undefined);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [overalltime, setOverallTime] = useState<string>("");
  const [manualTime, setManualTime] = useState<string>("");

  const [timerButtonStart, setTimerButtonStart] = useState<boolean>(false);
  const [isInProgress, setIsInProgress] = useState<boolean>(false);

  const medicalParams = [
    {
      title: t("threatNeutralized"),
      value: isThreatNeutralized,
      func: setThreatNeutralize,
    },
    {
      title: t("secure"),
      value: isSecured,
      func: setSecuring,
    },
    {
      title: t("stopBleeding"),
      value: isBleedStops,
      func: setBleedingStop,
    },
    {
      title: t("report"),
      value: isReported,
      func: setReport,
    },
  ];

  /**
   * return an array with the hour, minutes and secons as a string
   * @param overalltime
   */
  const getDurationParams = (overalltime: string) => {
    const overallTime: string[] = overalltime.split(":");
    const minutes = overallTime[0];
    const seconds = overallTime[1];
    return [minutes, seconds];
  };

  /**
   * returns the duration in seconds
   * @param overalltime
   */
  const getDurationInSeconds = (overalltime: string) => {
    let durationParams = getDurationParams(overalltime);
    return parseInt(durationParams[0]) * 60 + parseInt(durationParams[1]);
  };
  const timerButtonClicked = (start: any, stop: any) => {
    if (start && stop)
      setTimerButtonStart((prev: boolean) => {
        if (prev) {
          stop();
          if (selected === "timer") {
            let seconds = timerSeconds / 1000;
            setOverallTime(
              `${String(Math.floor(seconds / 60)).padStart(2, "0")}:${String(
                Math.floor(seconds % 60)
              ).padStart(2, "0")}`
            );
          }
        } else {
          start();
        }
        return !prev;
      });
  };
  /**
   * post the data to b-backend
   */
  const postData = () => {
    if (isNaN(traineeId) || traineeId === undefined) {
      customToast.error(t("noSelectedSoldier"));
      return;
    }
    setIsInProgress(true);
    const timeStamp: Date = new Date();
    const duration = getDurationInSeconds(
      selected === "timer"
        ? `${String(Math.floor(timerSeconds / 1000 / 60)).padStart(
            2,
            "0"
          )}:${String(Math.floor((timerSeconds / 1000) % 60)).padStart(
            2,
            "0"
          )}:${String(Math.floor(timerSeconds % 1000)).padStart(2, "0")}`
        : overalltime
    );

    const medicalDrill = {
      MessageType: EEventType.MEDICAL_MESSAGE_TYPE,
      TimeStamp: timeStamp,
      TrainingTypeID: trainingTypeId,
      SoldierID: traineeId,
      DrillStartTime: startDateTime,
      DrillEndTime: timeStamp,
      Duration: duration,
      Neutralization: isThreatNeutralized,
      Secure: isSecured,
      StopBleeding: isBleedStops,
      Report: isReported,
      PlanId: props.planId,
    };
    const displayToast = true;

    sendDrillToBBackend(
      medicalDrill,
      afterPostHandler,
      () => {
        setIsInProgress(false);
      },
      t,
      displayToast
    );
  };

  useEffect(() => {
    /**
     * check if the user insert the all required input
     * @param overalltime
     */
    const isCompleteInput = () => {
      let durationParams: string[] = getDurationParams(overalltime);
      let seconds = durationParams[1];
      let minutes = durationParams[0];

      let isFullSeconds: boolean = !(
        isNaN(parseInt(seconds?.split("")[1])) ||
        isNaN(parseInt(seconds?.split("")[0]))
      );
      let isFullMinutes: boolean = !(
        isNaN(parseInt(minutes?.split("")[1])) ||
        isNaN(parseInt(minutes?.split("")[0]))
      );

      let isLogic: boolean =
        parseInt(minutes) < 60 && parseInt(seconds) < 60 ? true : false;

      let duration = parseInt(seconds) * 60 * 60 + parseInt(minutes) * 60;
      setIsComplete(
        !isNaN(duration) &&
          isFullSeconds &&
          isFullMinutes &&
          isLogic &&
          !(
            isThreatNeutralized === undefined ||
            isSecured === undefined ||
            isBleedStops === undefined ||
            isReported === undefined
          )
      );
    };
    isCompleteInput();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBleedStops, isReported, isThreatNeutralized, isSecured, overalltime]);
  useEffect(() => {
    setReport(undefined);
    setBleedingStop(undefined);
    setThreatNeutralize(undefined);
    setSecuring(undefined);
    setOverallTime("");
    setManualTime("");
    setTimer(0);
    setTimerButtonStart(false);
  }, [traineeId]);
  useEffect(() => {
    if (isSendClicked) postData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSendClicked]);
  useEffect(() => {
    props.setIsSendDisable && props.setIsSendDisable(!isComplete);
  }, [isComplete]);
  return (
    <React.Fragment>
      <div className="medicalWrap">
        <IonRow className="rowMedical">
          <IonItem
            mode="md"
            class="manualInputContainerM itemM"
            lines="full"
            key={traineeId}
          >
            <PMTitle fontColor="light" fontFamily="Light" fontSize="medium">
              {t("time")}
            </PMTitle>
            <div className="inputWrap">
              <IonRadioGroup
                value={selected}
                onIonChange={(e) => {
                  setSelected(e.detail.value);

                  setOverallTime(
                    e.detail.value === "timer"
                      ? `${String(Math.floor(timerSeconds / 60)).padStart(
                          2,
                          "0"
                        )}:${String(Math.floor(timerSeconds % 60)).padStart(
                          2,
                          "0"
                        )}`
                      : manualTime
                  );
                }}
              >
                <IonItem className="radioItem">
                  <Timer
                    initialTime={0}
                    direction="forward"
                    startImmediately={false}
                    timeToUpdate={10}
                  >
                    {({ start, stop, getTime }: any) => {
                      setTimer(getTime());
                      return (
                        <>
                          <PMButton
                            label={{
                              fontColor: "light",
                              fontSize: "medium",
                              fontFamily: "Light",
                            }}
                            color="orange"
                            size="small"
                            isDisabled={selected !== "timer"}
                            onClickHandler={() => {
                              timerButtonClicked(start, stop);
                            }}
                          >
                            {!timerButtonStart ? t("start") : t("stop")}
                          </PMButton>
                        </>
                      );
                    }}
                  </Timer>

                  <IonRadio
                    className="radioMedical"
                    slot="start"
                    value="timer"
                  />
                </IonItem>
                <IonItem className="radioItem">
                  <PMLabel
                    fontColor="light"
                    fontFamily="Light"
                    fontSize="medium"
                    textAlign="center"
                  >
                    {t("manualInput")}
                  </PMLabel>
                  <IonRadio
                    className="radioMedical"
                    slot="start"
                    value="manual"
                  />
                </IonItem>
              </IonRadioGroup>

              <InputMask
                className="overallInputM"
                type="text"
                size={11}
                alwaysShowMask
                mask="99:99:99"
                contentEditable={selected === "manual"}
                value={
                  selected === "timer"
                    ? `${String(Math.floor(timerSeconds / 1000 / 60)).padStart(
                        2,
                        "0"
                      )}:${String(
                        Math.floor((timerSeconds / 1000) % 60)
                      ).padStart(2, "0")}:${String(
                        Math.floor(timerSeconds % 1000)
                      ).padStart(2, "0")}`
                    : manualTime
                }
                onChange={(e) => {
                  setOverallTime(e.target.value);
                  setManualTime(e.target.value);
                }}
              />
            </div>
          </IonItem>
        </IonRow>
        {medicalParams.map((param, index) => [
          <IonRow key={index} className="rowMedical">
            <IonItem
              mode="md"
              class="manualInputContainerM itemM"
              lines="full"
              key={index}
            >
              <PMTitle fontColor="light" fontFamily="Light" fontSize="medium">
                {param.title}
              </PMTitle>
              <SwitchButton
                isOn={param.value}
                handleToggle={(value: boolean) => param.func(value)}
              />
            </IonItem>
          </IonRow>,
        ])}
      </div>
    </React.Fragment>
  );
};

export default MedicalComponent;
