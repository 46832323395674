// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20vh;
  color: var(--ion-color-loading-spinner);
}

.competency-spinner {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.spinner-container {
  position: fixed;
  top: 55%;
  left: 50%;
}

.spinner-trainees-list {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ion-color-loading-spinner);
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--ion-color-fontLight) !important;
  margin-top: 15px;
}

.spinner-tree {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--ion-color-fontLight) !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/Spinner/Spinner.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,uCAAuC;AACzC;;AAEA;EACE,aAAa;EACb,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,QAAQ;EACR,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,uCAAuC;AACzC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,4CAA4C;EAC5C,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,4CAA4C;AAC9C","sourcesContent":[".loading-spinner {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 20vh;\n  color: var(--ion-color-loading-spinner);\n}\n\n.competency-spinner {\n  display: flex;\n  width: 100%;\n  height: 100%;\n  align-items: center;\n  justify-content: center;\n}\n\n.spinner-container {\n  position: fixed;\n  top: 55%;\n  left: 50%;\n}\n\n.spinner-trainees-list {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: var(--ion-color-loading-spinner);\n}\n\n.spinner {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  color: var(--ion-color-fontLight) !important;\n  margin-top: 15px;\n}\n\n.spinner-tree {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  color: var(--ion-color-fontLight) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
