import { FC, useContext } from "react";
import {
  IonCol,
  IonGrid,
  IonRow,
  IonContent,
  IonPage,
  isPlatform,
} from "@ionic/react";
import "./Stations.css";
import StationButtonComp from "../../../components/Mobile/SiteManagement/StationButtonComp";
import { environment } from "../../../Configurations/consts";
import MobileHeader from "../../../components/Mobile/MobileHeader/MobileHeader";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import EEnvironment from "../../../Enums/EEnvironment";
import OrbatTreeModal from "../../../components/Mobile/OrbatTreeModal/OrbatTreeModal";
import ForcesContext from "../../../context/ForcesContext/forcesContext";
import {
  Magnet_FOF,
  SHOOTING_RANGE_STATION_NAME,
} from "../../../Configurations/shootingRangeConsts";
import usePlans from "../../../components/CustomHooks/usePlans";
import { StationsContext } from "../../../context/StationsContext/StationsContext";
import { EAppMode } from "../../../Enums/EAppMode";
import { mobileRoutes } from "../../../services/routeRoles";
import { URLs } from "../../../Configurations/urls";
import {
  EGorillaTrainings,
  gorillaStations,
} from "../../../Interfaces/Gorilla/GorillaTrainings";
interface IProps {}
const Stations: FC<IProps> = () => {
  const { activePlan } = usePlans();
  const cloudStationsNames = [SHOOTING_RANGE_STATION_NAME, Magnet_FOF];
  const { isForcesTreeOpen, setIsForcesTreeOpen } = useContext(ForcesContext);
  const { stations } = useContext(StationsContext);

  return (
    <IonPage className="contentContainerSBL">
      <IonContent className="contentContainerSBL" scrollY={false}>
        <MobileHeader iconEndSrc={EIconsSrc.MENU_ICON} />
        <IonGrid className="gridContainerSBL">
          <div className="scrollContainerSBL mobileScroll">
            <IonRow className="rowContainerSBL">
              {stations
                .filter(
                  (station) =>
                    station.isManual &&
                    ((cloudStationsNames.includes(station.stationName) &&
                      environment === EEnvironment.production) ||
                      (!cloudStationsNames.includes(station.stationName) &&
                        environment !== EEnvironment.production) ||
                      environment === EEnvironment.staging)
                )
                ?.map((station) => {
                  const url =
                    station.stationName.toLocaleLowerCase() ===
                      EAppMode.FIRE_SUPPORT.toLocaleLowerCase() &&
                    isPlatform("tablet") //fire support on tablet displayed as desktop
                      ? mobileRoutes.fireSupport
                      : station.stationName === SHOOTING_RANGE_STATION_NAME //shooting range station url
                      ? URLs.stations.shootingRange.plans.getURL()
                      : gorillaStations.includes(
                          //hasifa tkifa stations
                          station.stationName as EGorillaTrainings
                        )
                      ? `${mobileRoutes.gorillaInitTraining}/${station.stationName}`
                      : `searchSoldier/${activePlan}/${station.stationName}/${station.stationId}/${station.trainingTypeId}`; //other stations
                  return (
                    <IonCol key={station.stationId} className={"buttonCol"}>
                      <StationButtonComp
                        stationName={station.stationName}
                        url={url}
                      ></StationButtonComp>
                    </IonCol>
                  );
                })}
            </IonRow>
          </div>
          <OrbatTreeModal
            limit={3}
            isOpen={isForcesTreeOpen}
            close={() => setIsForcesTreeOpen(false)}
            mode="secondary"
          />
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Stations;
