import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import List from "../../../List/List";
import MobileHeader from "../../../MobileHeader/MobileHeader";
import MobileTitleRow from "../../../MobileHeader/MobileTitleRow";
import SearchBar from "../../../../Shared/SearchBar/SearchBar";
import { URLs } from "../../../../../Configurations/urls";
import useNavigation from "../../../../CustomHooks/useNavigation";
import Spinner from "../../../../Shared/Spinner/Spinner";
import NotExistLabel from "../../../../Shared/NotExistLabel/NotExistLabel";
import OrbatTreeModal from "../../../OrbatTreeModal/OrbatTreeModal";
import ForcesContext from "../../../../../context/ForcesContext/forcesContext";
import { searchOutline } from "ionicons/icons";
import IShootingHeat from "../../../../../Interfaces/IShootingHeat";
import "./HeatSelectionScreen.css";
import { useShootingRangeHeats } from "../../../../CustomHooks/HooksWithRQ/useShootingHeats";
import EIconsSrc from "../../../../../Interfaces/EIconsSrc";
import { IonPage } from "@ionic/react";
import ELanguage from "../../../../../Enums/ELanguage";

type IProps = {};

const HeatSelectionScreen: FC<IProps> = (props: IProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const { navigate, returnToPreviousPage } = useNavigation();

  const params = useParams<{ shootingDayId: string; planId: string }>();

  const [inputHidden, setInputHidden] = useState<boolean>(true);
  const [filteredHeats, setFilteredHeats] = useState<IShootingHeat[]>();
  const [searchText, setSearchText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const { isForcesTreeOpen, setIsForcesTreeOpen } = useContext(ForcesContext);
  const { heatsByShootingDay } = useShootingRangeHeats(+params.shootingDayId);

  useEffect(() => {
    filterList(searchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    if (heatsByShootingDay) {
      setLoading(false);
    }
    setFilteredHeats(heatsByShootingDay);
  }, [heatsByShootingDay]);

  const navigateToResults = (item: IShootingHeat) => {
    navigate(
      `${URLs.stations.shootingRange.plans.shootingDays.heats.results.getURL(
        +params.planId,
        +params.shootingDayId,
        item.id
      )}`
    );
  };

  const changeSearchView = (): void => {
    setInputHidden((prev) => !prev);
    setSearchText("");
  };

  const filterList = (text: string): void => {
    setFilteredHeats(
      heatsByShootingDay?.filter((items) => items.name.includes(text))
    );
  };

  const isHeatsExist = (): boolean =>
    !!(heatsByShootingDay && filteredHeats && heatsByShootingDay.length > 0);

  const isShowable = (): boolean => isHeatsExist() && filteredHeats!.length > 0;

  return (
    <IonPage className="heats-selection">
      <MobileHeader
        isUserDisplay={false}
        title={t("Shooting_Range")}
        iconStartSrc={
          i18n.language === ELanguage.he
            ? EIconsSrc.BACK_ICON_HE
            : EIconsSrc.BACK_ICON_EN
        }
        iconStartHandler={returnToPreviousPage}
        isShowForcesTree
      />
      <div className="heats-list">
        <div className="heats-search">
          {inputHidden ? (
            <MobileTitleRow
              icon={true}
              iconSrc={searchOutline}
              title={t("selectHeatType")}
              onIconClick={changeSearchView}
            />
          ) : (
            <SearchBar
              color="dark"
              value={searchText}
              placeholder={t("searchHeat")}
              onChange={(text) => setSearchText(text)}
              onClear={changeSearchView}
            />
          )}
        </div>
        {isShowable() ? (
          <List
            items={filteredHeats!}
            handleClick={(listItem: IShootingHeat | undefined) =>
              navigateToResults(listItem!)
            }
          />
        ) : isHeatsExist() ? (
          <NotExistLabel className="no-heats" text="noHeatsFound" />
        ) : loading ? (
          <Spinner className="spinner-container" />
        ) : (
          <NotExistLabel className="no-heats" text="noHeatsExist" />
        )}
      </div>
      <OrbatTreeModal
        limit={1}
        isOpen={isForcesTreeOpen}
        close={() => setIsForcesTreeOpen(false)}
        mode="secondary"
      />
    </IonPage>
  );
};

export default HeatSelectionScreen;
