import { Dispatch, FC, SetStateAction, useState } from "react";
import "./MainNotesTab.css";
import NotesAndEndTraining from "../NotesAndEndTraining";
import { INote } from "../../../../Interfaces/Gorilla/GorillaTrainings";

interface IProps {
  endTrainingHandler: () => void;
  mainNotes: INote[];
  addNote: (note: string) => void;
  deleteNote: (index: number) => void;
  editNote: (index: number, note: string) => void;
  isTrainingEnd: boolean;
}

const MainNotesTab: FC<IProps> = (props: IProps): JSX.Element => {
  const {
    endTrainingHandler,
    isTrainingEnd,
    mainNotes,
    addNote,
    deleteNote,
    editNote,
  } = props;
  const [isCommentsModalOpen, setIsNotesModalOpen] = useState<boolean>(false);
  return (
    <div className="generalCommentsTab">
      <NotesAndEndTraining
        endTrainingHandler={endTrainingHandler}
        mainNotes={mainNotes}
        isNotesModalOpen={isCommentsModalOpen}
        setIsNotesModalOpen={setIsNotesModalOpen}
        deleteNote={deleteNote}
        editNote={editNote}
        addNote={addNote}
        trainingName={"Observer"}
        isTrainingEnd={isTrainingEnd}
      ></NotesAndEndTraining>
    </div>
  );
};

export default MainNotesTab;
