import React from "react";
import { IonItem, IonLabel, IonList } from "@ionic/react";
import "./List.css";
import { useTranslation } from "react-i18next";

type Props<T> = {
  items: T[];
  handleClick: (value?: T) => void;
  title?: string;
  numbered?: boolean;
};

const List = <T extends { id: number; name: string }>(
  props: Props<T>
): JSX.Element => {
  const { items, handleClick, numbered } = props;
  const { t } = useTranslation();
  return (
    <div className="mobile-list-wrapper">
      <IonList className="mobile-list">
        {items.map((item: T, index: number) => (
          <IonItem
            className="list-item"
            key={item.id}
            onClick={() => handleClick(item)}
            button
          >
            <IonLabel key={item.id}>
              {numbered && `${index + 1} - `}
              {`${t(item.name)}`}
            </IonLabel>
          </IonItem>
        ))}
      </IonList>
    </div>
  );
};

export default List;
