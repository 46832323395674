import React, { FC, useEffect, useState, memo, useContext } from "react";
import IForceFromOrbat from "../../../../../Interfaces/IForceFromOrbat";
import IForceTreeNode from "../../../../../Interfaces/IForceTreeNode";
import { useTranslation } from "react-i18next";
import { getForceFullName } from "../../../../../services/helpers";
import "react-modern-drawer/dist/index.css";
import customToast from "../../../../../components/Shared/Toast/CustomToast";
import { isTwoLevelsAtLeast } from "../../../../../services/treeSharedFunctions";

import "./CommanderDashboard.css";
import PerformanceTemplate from "../../performanceTemplate";
import usePlans from "../../../../../components/CustomHooks/usePlans";

import CommanderDashboardComponent from "./CommanderDashboardComponent";
import { ForcesContextDesktop } from "../../../../../context/ForcesContext/forcesContextProviderDesktop";
import { useGetRelevantPlans } from "../../../../../components/CustomHooks/HooksWithRQ/useGetRelevantPlans";
import EExportMethod from "../../../../../Interfaces/EExportMethod";

interface IProps {
  exportToCsv: (
    imageUrl: string,
    exportType: number,
    exportMethod: EExportMethod
  ) => void;
  isAdmin?: boolean | true;
  isDrawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CommanderDashboard: FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    dashboardOrbatForces,
    setDashboardOrbatForces,
    dashboardSelectedPlan,
    setDashboardSelectedPlan,
  } = useContext(ForcesContextDesktop);
  const { isDrawerOpen, setDrawerOpen } = props;
  const [loading, setIsLoading] = useState<boolean>(false);
  const [commanderForces, setCommanderForces] = useState<IForceFromOrbat[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const { relevantPlansIds } = useGetRelevantPlans(
    commanderForces.map((force) => force.id)
  );

  const {
    onPlanSelectedHandler,
    plansOptions,
    selectedPlan,
    plansValuesForSelectedPlan,
  } = usePlans(relevantPlansIds, true, true);
  //set the selected plan as the saved plan
  useEffect(() => {
    if (dashboardSelectedPlan) {
      onPlanSelectedHandler(dashboardSelectedPlan);
    }
    // eslint-disable-next-line
  }, [dashboardSelectedPlan]);
  //save the selected plan into context
  useEffect(() => {
    if (selectedPlan) setDashboardSelectedPlan(selectedPlan);
    // eslint-disable-next-line
  }, [selectedPlan]);

  const onCheckForce = (forces: IForceTreeNode[]) => {
    if (forces[0] && !isTwoLevelsAtLeast(forces[0])) {
      customToast.error(t("platoonAndUP"));
      setDashboardOrbatForces([]);
    } else {
      setDashboardOrbatForces((prev) => {
        let result = forces.map((force: IForceTreeNode) => {
          let currName: string = force.name;
          currName = currName.includes(force.force_type)
            ? currName
            : getForceFullName(force.name, force.force_type, t);

          return {
            ...force,
            name: currName,
          };
        });
        if (JSON.stringify(prev) === JSON.stringify(result)) return prev;
        return result;
      });
    }
  };
  return (
    <PerformanceTemplate
      iButtonsDisabled={!dashboardOrbatForces.length}
      key={"dashboard"}
      loadingText={t("loadingData")}
      limit={1}
      chart={
        <CommanderDashboardComponent
          commanderForces={commanderForces}
          plansValuesForSelectedPlan={plansValuesForSelectedPlan}
          selectedPlan={selectedPlan}
          refresh={refresh}
          setRefresh={setRefresh}
          onCheckForce={onCheckForce}
          setIsLoading={setIsLoading}
          orbatForces={dashboardOrbatForces}
          setCommanderForces={setCommanderForces}
        ></CommanderDashboardComponent>
      }
      isDrawerOpen={isDrawerOpen}
      loading={loading}
      orbatForces={dashboardOrbatForces}
      refreshButtonText={t("refresh")}
      onClickRefreshButton={() => {
        setRefresh(true);
      }}
      plansOptions={plansOptions}
      selectedPlan={selectedPlan}
      onPlanSelectedHandler={onPlanSelectedHandler}
      setChecked={onCheckForce}
      setDrawerOpen={setDrawerOpen}
      title={t("commanderDashboard")}
      displayPlatoonAndAbove
    ></PerformanceTemplate>
  );
};

export default memo(CommanderDashboard);
