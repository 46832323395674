import { IonItem, IonRow } from "@ionic/react";
import "./PMCollapse.css";
import { caretDown, caretUp } from "ionicons/icons";
import PMLabel from "../../themeComponents/PMLabel";
import {
  useRef,
  useEffect,
  SetStateAction,
  Dispatch,
  useState,
  ReactNode,
} from "react";
import { Collapse } from "react-collapse";
import PMIcon from "../../themeComponents/PMIcon";
import { useTranslation } from "react-i18next";

interface IProps {
  label?: string | undefined;
  icon?: any;
  children: any;
  isInnerItem?: boolean;
  placeholder: string;
  disabled?: boolean;
  isOpen?: boolean;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  hederItem?: any;
  cssClass?: string;
}
const PMCollapse = (props: IProps) => {
  const { t } = useTranslation();
  const collapseRef = useRef<null | HTMLIonRowElement>(null);
  const [internalIsOpen, setInternalIsOpen] = useState<boolean>(false);
  const isOpen = props.isOpen !== undefined ? props.isOpen : internalIsOpen;
  const setIsOpen =
    props.setIsOpen !== undefined ? props.setIsOpen : setInternalIsOpen;

  const handleClickOutside = (event: MouseEvent) => {
    if (
      collapseRef.current &&
      !collapseRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <IonRow
      ref={collapseRef}
      className={`collapseMainWrap ${props.isInnerItem ? "inner" : ""} ${
        props.disabled ? "disabled" : ""
      }`}
    >
      {props.hederItem ? (
        props.hederItem()
      ) : (
        <IonItem
          className={`pmCollapseItem ${props.isInnerItem ? "inner" : ""} ${
            isOpen ? "expanded" : ""
          } ${props.label ? "withLabel" : ""}`}
          button
          lines="none"
          onClick={() => {
            setIsOpen((prev) => !prev);
          }}
        >
          <div className="PMCollapseItem">
            <PMLabel
              fontSize={props.label ? "small" : "medium"}
              fontColor={"light"}
              fontFamily="Light"
              cssClass={`floatingLabelPMCollapse ${
                props.label ? "floating" : ""
              }`}
            >
              {t(props.placeholder)}
            </PMLabel>

            {props.label ? (
              <PMLabel
                fontSize={"medium"}
                fontColor={"light"}
                fontFamily="Light"
                cssClass="placeholderPMCollapse"
              >
                {t(props.label)}
              </PMLabel>
            ) : null}
          </div>
          <PMIcon
            slot="end"
            size="medium"
            color={props.disabled ? "disabledColorI" : "light"}
            iconSrc={isOpen ? caretUp : caretDown}
          ></PMIcon>
        </IonItem>
      )}
      <Collapse isOpened={isOpen} theme={{ collapse: "slow-collapse" }}>
        <div className={`pmCollapseContent ${props.cssClass}`}>
          <div className="pmCollapseContentScroll scrollS" slot="content">
            {props.children}
          </div>
        </div>
      </Collapse>
    </IonRow>
  );
};

export default PMCollapse;
