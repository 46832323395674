import { IonItem, IonLabel } from "@ionic/react";
import React, { useEffect, useState, memo } from "react";
import "./PerformanceLeaders.css";
import LeadersList from "../../../../../components/Shared/Leaders/LeadersList";
import { useTranslation } from "react-i18next";
import {
  ILeadersSlideProps,
  LeadersProps,
} from "../../../../../Interfaces/ILeaders";
import PerformanceTemplate from "../../performanceTemplate";
import IForceFromOrbat from "../../../../../Interfaces/IForceFromOrbat";
import useGetLeaders from "../../../../../components/CustomHooks/useGetLeaders";
import exportToCsv from "../../../../../components/Desktop/ToExcel/export-to-csv";
import generatePDF from "../../../../../components/Desktop/ToExcel/generatePDFHelper";
import EExportMethod from "../../../../../Interfaces/EExportMethod";
import Legend from "../../../../../components/Desktop/VerticalProgressBar/Legend";
import Colors from "../../../../../Interfaces/Colors";
import IForceTreeNode from "../../../../../Interfaces/IForceTreeNode";
import { getForceFullName } from "../../../../../services/helpers";
import { flattenTreeNodes } from "../../../../../services/orbatExportsFunction";

const PerformanceLeaders: React.FC<LeadersProps> = (
  props: LeadersProps
): JSX.Element => {
  const { t } = useTranslation();
  const [hiddenForcesIds, setHiddenForcesIds] = useState<number[]>([]);

  const {
    checkedForces,
    setChecked,
    selectedPlan,
    allPlansOptions,
    onPlanSelectedHandler,
    isPersonalZone,
    isDrawerOpen,
    setDrawerOpen,
    forcesToRemove,
    newForces,
    orbatForces,
  } = props;
  const [myNewForces, setMyNewForces] = useState<IForceFromOrbat[]>();

  useEffect(() => {
    setMyNewForces((prev) => {
      if (!prev) return checkedForces;
      return newForces;
    });
  }, [newForces, checkedForces]);

  const { individuals, units, loading } = useGetLeaders(
    selectedPlan,
    checkedForces,
    myNewForces || [],
    forcesToRemove,
    isPersonalZone,
    orbatForces
  );

  //Hide the hidden forces and nodes
  const filterAndFlattenForces = () => {
    return orbatForces
      .filter((force) => !hiddenForcesIds.includes(force.id)) // Filter out hidden forces
      .flatMap((force) => flattenTreeNodes(force)) // Flatten the tree nodes
      .map((force) => force.id); // Extract the force IDs
  };

  const leadersSlidesProps: ILeadersSlideProps[] = [
    {
      state: units.filter((unit) => {
        const visibleForceIds = filterAndFlattenForces();
        return visibleForceIds.includes(unit.id); // Check if unit id is in visible force ids
      }),
      title: t("unitsTitle"),
    },
    {
      state: individuals.filter((indi) => {
        const visibleForceIds = filterAndFlattenForces();
        return visibleForceIds.includes(indi.id); // Check if individual id is in visible force ids
      }),
      title: t("individualsTitle"),
    },
  ];

  /**
   * organize leaders data and export to csv
   * @returns
   */
  const exportToCsvs = async (exportMethod: EExportMethod) => {
    let currentDate: Date = new Date();
    let titlesArray = [t("name"), t("affiliation"), t("finalGrade")];

    let fileName = `PerformanceLeadersReport_${String(
      currentDate.getDate()
    ).padStart(2, "0")}${String(currentDate.getMonth() + 1).padStart(2, "0")}${
      currentDate.getFullYear() % 100
    }${currentDate.getHours()}${currentDate.getMinutes()}${currentDate.getSeconds()}`;
    let rowIndex = 6;
    let headersRows: number[] = [];
    let rows: string[][] = [[], [], [], []];
    let rowsIndexToBorder: number[][] = [];
    let rowsToCenter: number[] = [];
    let headersRowsIndexToBold: number[][] = [];
    if (checkedForces.length === 0) return;
    rows = [...rows, [t("leadersReport")], [], []];
    headersRows.push(rowIndex);
    rowIndex += 3;
    rows.push([`${t("trainingPlan")}: ${selectedPlan.name}`]);
    headersRowsIndexToBold.push([rowIndex, 1]);
    let dataForPDF: any[] = [];
    dataForPDF.push({
      type: "title",
      data: t("leadersReport"),
      subTitle: `${t("trainingPlan")}: ${selectedPlan.name}`,
    });

    rowIndex += 1;
    let leadersData: any[] = [];
    leadersSlidesProps.forEach((element) => {
      leadersData = [];
      rows.push([element.title]);
      headersRows.push(rowIndex);
      rows.push([""]);

      rowIndex += 2;
      rows.push(titlesArray);

      rowsToCenter.push(rowIndex);
      headersRowsIndexToBold.push([rowIndex, 3]);
      rowIndex += 1;

      element.state.forEach((row, index) => {
        rows.push([
          row.name,
          row.affiliation.join(" / "),
          String(Math.round(row?.grade)),
        ]);
        leadersData.push({
          name: row.name,
          affiliation: row.affiliation.join(" / "),
          index: String(index),
          grade: String(Math.round(row?.grade)),
        });
      });
      new Array(element.state.length).fill(0).forEach((_val, index) => {
        rowsIndexToBorder.push([rowIndex + index, titlesArray.length]);
      });
      rowIndex += element.state.length;
      rows.push([""]);
      rowIndex += 1;
      dataForPDF.push({
        type: `leaders`,
        title: element.title,
        data: leadersData,
      });
    });

    if (
      exportMethod === EExportMethod.pdfDarkMode ||
      exportMethod === EExportMethod.pdfLightMode
    )
      await generatePDF(
        dataForPDF,
        `${fileName}.pdf`,
        exportMethod === EExportMethod.pdfLightMode
      );
    if (exportMethod === EExportMethod.excel)
      await exportToCsv(
        fileName,
        headersRows,
        rows,
        titlesArray.length,
        [],
        headersRowsIndexToBold,
        rowsIndexToBorder,
        [],
        [],
        [],
        [],
        [],
        t,
        false,
        undefined,
        undefined,
        700,
        1500,
        undefined,
        rowsToCenter,
        true
      );
  };

  const chart = (
    <div className="leadersChart">
      <div className="legendRowLeaders">
        {orbatForces.map((force: IForceTreeNode, index) => (
          <Legend
            key={index}
            forceName={force.name}
            forceId={force.id}
            color={Colors[index]}
            setSelectedForcesID={setHiddenForcesIds}
            isSelected={hiddenForcesIds.includes(force.id) ? false : undefined}
            force={force}
            isMultiSelect
          />
        ))}
      </div>
      <div className="rowLeadersData scrolL" id="csvLeadersImage">
        {leadersSlidesProps?.map((slide, index) => (
          <div key={index} className="leadersColLD">
            <IonItem
              key={index}
              lines="none"
              mode="md"
              className="leadersTitleLD"
            >
              <IonLabel className="titleLabel">{slide.title}</IonLabel>
            </IonItem>
            <LeadersList leaders={slide.state} />
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="leadersPage">
      <PerformanceTemplate
        iButtonsDisabled={!checkedForces.length}
        key={"leaders"}
        loadingText={t("loadingData")}
        chart={chart}
        isDrawerOpen={isDrawerOpen}
        loading={loading}
        orbatForces={orbatForces}
        selectedPlan={selectedPlan}
        plansOptions={allPlansOptions}
        onPlanSelectedHandler={onPlanSelectedHandler}
        setChecked={setChecked}
        setDrawerOpen={setDrawerOpen}
        title={t("leaders")}
        onClickExportButton={(exportMethod) => {
          exportToCsvs(exportMethod);
        }}
        exportButtonText={t("export")}
      ></PerformanceTemplate>
    </div>
  );
};

export default memo(PerformanceLeaders);
