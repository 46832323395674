import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Plugin,
  Chart,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  ChartOptions,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

import "./DashboardDoughnut.css";

import { useTranslation } from "react-i18next";
import { commanderDashboardMaximumElementsForIndicator } from "../../../Configurations/consts";
import ELanguage from "../../../Enums/ELanguage";

interface IDoughunt {
  label: string;
  value: number;
  color: "red" | "green" | "yellow" | "notAttendedColor" | string;
}
Chart.register(ArcElement, Tooltip, Legend, Title, ChartDataLabels);
Chart.defaults.plugins.legend.display = false;

interface Doughunt {
  chartData: IDoughunt[];
  indicatorName: string;
  indicatorId: number;
  selectedIndicator: number | undefined;
  setSelectedIndicator: Dispatch<SetStateAction<number | undefined>>;
  isDisableAll: boolean;
}

const DashboardDoughnut = (props: Doughunt) => {
  const {
    chartData,
    indicatorId,
    indicatorName,
    selectedIndicator,
    setSelectedIndicator,
    isDisableAll,
  } = props;

  const { i18n, t } = useTranslation();

  let emptyDoughnut = new Array(
    commanderDashboardMaximumElementsForIndicator
  ).fill({
    color: "gray",
    label: "",
    value: 0,
  });

  const [doughnutData, setDoughnutData] = useState<IDoughunt[]>(emptyDoughnut);
  useEffect(() => {
    setDoughnutData(
      chartData?.length
        ? chartData.slice(0, commanderDashboardMaximumElementsForIndicator)
        : emptyDoughnut
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]);

  const getChartData = () => {
    let data: number[] = [];
    doughnutData.forEach((t) => {
      let val =
        t.value > 85 && t.value !== 100
          ? 80
          : t.value < 23 && t.value !== 0
          ? 23
          : t.value;

      let part = 100 / doughnutData.length;

      data.push((Number(val) * part) / 100);
      data.push(part - (Number(val) * part) / 100);
    });
    return data;
  };
  const getChartDataOutside = () => {
    let data: number[] = [];
    doughnutData.forEach(() => {
      data.push(100 / doughnutData.length);
    });
    return data;
  };
  const getBackgroundColor = () => {
    let colors: string[] = [];
    doughnutData.forEach((t) => {
      switch (t.color) {
        case "green":
          selectedIndicator !== indicatorId && selectedIndicator !== undefined
            ? colors.push("#2c4234") //disabled
            : colors.push("#06d114"); //color
          selectedIndicator !== indicatorId && selectedIndicator !== undefined
            ? colors.push("#303337") //disabled background
            : colors.push("#303E36"); //background
          break;
        case "yellow":
          selectedIndicator !== indicatorId && selectedIndicator !== undefined
            ? colors.push("#443d33")
            : colors.push("#f5a30c");
          selectedIndicator !== indicatorId && selectedIndicator !== undefined
            ? colors.push("#313337")
            : colors.push("#3E3A35");
          break;

        case "red":
          selectedIndicator !== indicatorId && selectedIndicator !== undefined
            ? colors.push("#433438")
            : colors.push("#EB4141");
          selectedIndicator !== indicatorId && selectedIndicator !== undefined
            ? colors.push("#323237")
            : colors.push("#413335");
          break;

        case "notAttendedColor":
          selectedIndicator !== indicatorId && selectedIndicator !== undefined
            ? colors.push("#393B3F")
            : colors.push("#4A4D54");
          selectedIndicator !== indicatorId && selectedIndicator !== undefined
            ? colors.push("#393B3F")
            : colors.push("#4A4D54");
          break;
        case "gray":
          selectedIndicator !== indicatorId && selectedIndicator !== undefined
            ? colors.push("#36383B")
            : colors.push("#36383B");
          selectedIndicator !== indicatorId && selectedIndicator !== undefined
            ? colors.push("#36383B")
            : colors.push("#36383B");
          break;
      }
    });
    return colors;
  };
  const getBackgroundColorOutside = () => {
    let colors: string[] = [];
    doughnutData.forEach((t) => {
      switch (t.color) {
        case "green":
          selectedIndicator !== indicatorId && selectedIndicator !== undefined
            ? colors.push("#303337") //disabled
            : colors.push("#303e36"); //color
          break;
        case "yellow":
          selectedIndicator !== indicatorId && selectedIndicator !== undefined
            ? colors.push("#313337")
            : colors.push("#3e3a35");
          break;

        case "red":
          selectedIndicator !== indicatorId && selectedIndicator !== undefined
            ? colors.push("#323237")
            : colors.push("#413335");
          break;
        case "notAttendedColor":
          selectedIndicator !== indicatorId && selectedIndicator !== undefined
            ? colors.push("#2D3033")
            : colors.push("#3B3D43");
          break;
        case "gray":
          selectedIndicator !== indicatorId && selectedIndicator !== undefined
            ? colors.push("#36383B")
            : colors.push("#36383B");
          break;
      }
    });
    return colors;
  };
  const data = {
    datasets: [
      {
        data: getChartData(),
        borderWidth: 0.2,
        radius: "92%",
        borderColor: "#232426",
        backgroundColor: getBackgroundColor(),
      },
    ],
  };
  const outsideData = {
    labels: doughnutData.map((data) => data.label),

    datasets: [
      {
        radius: "95%",
        data: getChartDataOutside(),
        borderWidth: 0.3,
        borderColor: "#232426",
        backgroundColor: getBackgroundColorOutside(),
      },
    ],
  };
  // round corners and shadow
  const plugins: any = {
    beforeDraw: (chart: any, args: any, options: any) => {
      const { ctx } = chart;
      ctx.shadowColor = "rgba(0, 0, 0, 0.4)";
      ctx.shadowBlur = 10;
      ctx.shadowOffsetX = 0;
      ctx.shadowOffsetY = 0;
    },

    afterDraw: function (chart: any) {
      if (chart) {
        for (let i = 0; i < chart.config.data.datasets[0].data.length; i++) {
          if (
            i % 2 === 0 &&
            chart.config.data.datasets[0].data[i] <
              (100 / chart.config.data.datasets[0].data.length) * 2 &&
            chart.config.data.datasets[0].data[i] > 0
          ) {
            var ctx = chart.ctx;
            let arc = chart.getDatasetMeta(0).data[i];
            arc.round = {
              x: (chart.chartArea.left + chart.chartArea.right) / 2,
              y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
              radius: (arc.outerRadius + arc.innerRadius) / 2, // Access from arc
              thickness: (arc.outerRadius - arc.innerRadius) / 2, // Access from arc
              backgroundColor: arc.options.backgroundColor,
            };
            var endAngle = Math.PI / 2 - arc.endAngle; // updated
            ctx.save();
            ctx.shadowColor = "transparent";
            ctx.shadowBlur = 0;
            ctx.shadowOffsetX = 0;
            ctx.shadowOffsetY = 0;
            ctx.translate(arc.round.x, arc.round.y);
            ctx.fillStyle = arc.round.backgroundColor;
            ctx.beginPath();
            ctx.arc(
              arc.round.radius * Math.sin(endAngle),
              arc.round.radius * Math.cos(endAngle),
              arc.round.thickness * 0.95,
              0,
              2 * Math.PI
            );
            ctx.closePath();
            ctx.fill();
            ctx.restore();
          }
        }
      }
    },
  };
  const writeText = (chart: any) => {
    if (chart) {
      const ctx = chart.ctx;

      for (let i = 0; i < chart.config.data.datasets[0].data.length; i++) {
        let arc = chart.getDatasetMeta(0).data[i];
        let startAngle = arc.startAngle;
        let endAngle = arc.endAngle;
        let centerAngle = (startAngle + endAngle) / 2;
        let radius = (arc.outerRadius + arc.innerRadius) / 2;

        // Define the text you want to draw
        let text =
          i18n.language === ELanguage.he
            ? reverseString(t(chart.config.data?.labels[i] || ""))
            : t(chart.config.data?.labels[i] || "");

        const arcLength =
          (commanderDashboardMaximumElementsForIndicator -
            chart.config.data.datasets[0].data.length) *
          10;
        if (text.length < arcLength) {
          text = String(text).padStart(
            Math.floor((arcLength - text.length) / 2) + text.length,
            " "
          );
          text = String(text).padEnd(arcLength, " ");
        }
        let textLength = text.length;
        const angleStep = (endAngle - startAngle) / textLength; // Angle for each letter
        const totalTextAngle = angleStep * textLength; // Total angle for the text
        const startingAngle = centerAngle - totalTextAngle / 2; // Starting point to center the text

        // Position each letter along the center of the arc
        for (let j = 0; j < textLength; j++) {
          // Calculate angle for this letter
          let letterAngle = startingAngle + j * angleStep + angleStep / 2; // Adjusted for centering

          // Calculate the letter position using the angle
          let letterX = arc.x + radius * Math.cos(letterAngle);
          let letterY = arc.y + radius * Math.sin(letterAngle);

          ctx.save();
          ctx.fillStyle =
            selectedIndicator !== indicatorId && selectedIndicator !== undefined
              ? "rgb(101, 102, 100)"
              : "#A6A7A7";
          ctx.font = "Normal 12px Regular"; // Set font
          ctx.textAlign = "center"; // Center align text
          ctx.textBaseline = "middle"; // Middle align text

          ctx.translate(letterX, letterY);
          ctx.rotate(letterAngle + Math.PI / 2); // Rotate clockwise for readability

          ctx.fillText(text[j], 0, 0); // Draw the letter at the new origin

          ctx.restore(); // Restore the context to its previous state
        }
      }
    }
  };
  const roundedTextPlugins: Plugin<"doughnut"> = {
    id: "roundedTextPlugin",
    afterDraw: writeText,
  };

  const clickHandler = () => {
    setSelectedIndicator((prev) =>
      prev === indicatorId ? undefined : indicatorId
    );
  };
  const reverseString = (str: string) => {
    let splitString = str.split("");
    let reverseArray = splitString.reverse();
    let joinArray = reverseArray.join("");
    return joinArray;
  };
  const options: ChartOptions<"doughnut"> = {
    responsive: true,
    maintainAspectRatio: true,
    events: [],
    animation: { animateRotate: false, animateScale: false },
    rotation: 180,
    cutout: "55%",
    plugins: { datalabels: { display: false } },
  };
  const outsideOptions: ChartOptions<"doughnut"> = {
    ...options,

    cutout: "87%",
  };

  return (
    <div
      className="doughnutDiv"
      onClick={isDisableAll ? () => {} : clickHandler}
    >
      <Doughnut
        key={selectedIndicator}
        data={outsideData}
        options={outsideOptions}
        plugins={[roundedTextPlugins]}
        width={10}
        height={10}
      ></Doughnut>
      <div className="pieContainer clickable">
        <Doughnut
          data={data as any}
          options={options}
          plugins={[plugins]}
          width={5}
          height={5}
        ></Doughnut>
      </div>
      <div className="textDiv clickable">
        <p
          className={`textThreshold ${
            selectedIndicator !== indicatorId && selectedIndicator !== undefined
              ? "textThresholdDisabled"
              : ""
          }`}
        >
          {indicatorName}
        </p>
      </div>
    </div>
  );
};
export default DashboardDoughnut;
