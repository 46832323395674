import { baseUrlPMBackendBasic } from "./consts";

const address = {
  pmBackend: `${baseUrlPMBackendBasic}api`,
  stations: "/stations",
  shootingRange: "/shooting-range",
  shootingDays: "/shooting-days",
  heats: "/heats",
  results: "/results",
  forces: "/forces",
  soldiers: "/soldiers",
  children: "/children",
  plans: "/plans",
  users: "/users",
  login: "/login",
  trackingLogins: "/tracking-logins",
  add: "/add",
  history: "/history",
  syncData: "/syncData",
  lastUpdateTime: "/lastSynced",
  passedParams: {
    shootingDayId: "/:shootingDayId",
    heatId: "/:heatId",
    planId: "/:planId",
  },
  comparePlans: "/comparePlans",
  compareGarde: "/compareGrades",
};

const paths = {
  stations: {
    getPath: () => `${address.stations}`,
    shootingRange: {
      getPath: () => `${paths.stations.getPath() + address.shootingRange}`,

      plans: {
        getPath: (id?: number) =>
          `${paths.stations.shootingRange.getPath() + address.plans}${
            id ? "/" + id : ""
          }`,
        shootingDays: {
          getPath: (id?: number, planId?: number) =>
            `${paths.stations.shootingRange.plans.getPath(planId)}${
              address.shootingDays
            }${id ? "/" + id : ""}`,
          heats: {
            getPath: (shootingDayId?: number, id?: number, planId?: number) =>
              `${
                paths.stations.shootingRange.plans.shootingDays.getPath(
                  shootingDayId,
                  planId
                ) + address.heats
              }${id ? "/" + id : ""}`,
            results: {
              getPath: (
                shootingDayId: number,
                heatId: number,
                planId: number
              ) =>
                `${
                  paths.stations.shootingRange.plans.shootingDays.heats.getPath(
                    shootingDayId,
                    heatId,
                    planId
                  ) + address.results
                }`,
            },
          },
        },
      },
    },
  },
  forces: {
    getPath: () => `${address.forces}`,
    soldiers: {
      getPath: () => `${paths.forces.getPath() + address.soldiers}`,
    },
    children: {
      getPath: () => `${paths.forces.getPath() + address.children}`,
      soldiers: {
        getPath: (parentForceId: number) =>
          `${paths.forces.children.getPath() + address.soldiers}${
            "/" + parentForceId
          }`,
      },
    },
  },
  plans: {
    getPath: () => `${address.plans}`,
  },
  users: {
    getPath: () => `${address.users}`,
  },
  trackingLogins: {
    getPath: () => `${address.trackingLogins}`,
    add: {
      getPath: () => `${paths.trackingLogins.getPath() + address.add}`,
    },
    history: {
      getPath: () => `${paths.trackingLogins.getPath() + address.history}`,
    },
  },
  syncService: {
    getPath: () => `${address.syncData}`,
    lastUpdateTime: {
      getPath: () => `${paths.syncService.getPath() + address.lastUpdateTime}`,
    },
    comparePlans: {
      getPath: () => `${paths.syncService.getPath() + address.comparePlans}`,
    },
    compareGrades: {
      getPath: () => `${paths.syncService.getPath() + address.compareGarde}`,
    },
  },
};

export const URLs = {
  stations: {
    shootingRange: {
      getURL: () => `${paths.stations.shootingRange.getPath()}`,

      plans: {
        getURL: () => `${paths.stations.shootingRange.plans.getPath()}`,
        shootingDays: {
          getURL: (id?: number, planId?: number) =>
            `${
              planId
                ? paths.stations.shootingRange.plans.shootingDays.getPath(
                    id,
                    planId
                  )
                : paths.stations.shootingRange.plans.getPath(planId) +
                  address.passedParams.planId +
                  address.shootingDays
            }`,
          heats: {
            getURL: (shootingDayId?: number, id?: number, planId?: number) =>
              `${
                shootingDayId
                  ? paths.stations.shootingRange.plans.shootingDays.heats.getPath(
                      shootingDayId,
                      id,
                      planId
                    )
                  : URLs.stations.shootingRange.plans.shootingDays.getURL() +
                    address.passedParams.shootingDayId +
                    address.heats
              }`,
            results: {
              getURL: (
                planId?: number,
                shootingDayId?: number,
                heatId?: number
              ) =>
                `${
                  URLs.stations.shootingRange.plans.shootingDays.heats.getURL(
                    shootingDayId,
                    heatId,
                    planId
                  ) +
                  (shootingDayId && heatId ? "" : address.passedParams.heatId) +
                  address.results
                }`,
            },
          },
        },
      },
    },
    getURL: () => `${paths.stations.getPath()}`,
  },
};

export const APIs = {
  forces: {
    children: {
      getChildrenSoldiersById: (forceId: number) =>
        `${
          address.pmBackend + paths.forces.children.soldiers.getPath(forceId)
        }`,
    },
  },
  plans: {
    getAll: () => `${address.pmBackend + paths.plans.getPath()}`,
    active: {
      getActivePlan: () => `${address.pmBackend + paths.plans.getPath()}`,
    },
  },
  users: {},
  trackingLogins: {
    addUserToTracking: () =>
      `${address.pmBackend + paths.trackingLogins.add.getPath()}`,
    getAll: () =>
      `${address.pmBackend + paths.trackingLogins.history.getPath()}`,
  },
  syncService: {
    getLastUpdateTime: () =>
      `${address.pmBackend + paths.syncService.lastUpdateTime.getPath()}`,
    syncData: () => `${address.pmBackend + paths.syncService.getPath()}`,
    comparePlans: () =>
      `${address.pmBackend + paths.syncService.comparePlans.getPath()}`,
    compareGrades: () =>
      `${address.pmBackend + paths.syncService.compareGrades.getPath()}`,
  },
};
