import "./PlansDropdown.css";
import PMCollapse from "../PMCollapse/PMCollapse";
import IPlan from "../../../Interfaces/IPlan";
import { useTranslation } from "react-i18next";
import { IonItem, IonList } from "@ionic/react";
import PMLabel from "../../themeComponents/PMLabel";
import { useState } from "react";

interface IProps {
  selectedPlan?: IPlan | undefined;
  plansOptions?: IPlan[];
  onPlanSelectedHandler?: ((plan: IPlan) => void) | undefined;
  isDisabled?: boolean;
}
const PlansDropdown = (props: IProps) => {
  const { selectedPlan, plansOptions, onPlanSelectedHandler } = props;
  const { t } = useTranslation();
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  return (
    <PMCollapse
      disabled={props.isDisabled}
      placeholder={t("chooseTrainingPlan")}
      label={
        selectedPlan && selectedPlan?.id !== undefined
          ? `${selectedPlan.name} ${
              selectedPlan.isActive ? `(${t("active")})` : ""
            }`
          : undefined
      }
      isOpen={isCollapseOpen}
      setIsOpen={setIsCollapseOpen}
    >
      <IonList className="plansDropdownList">
        {plansOptions?.map((plan, index) => (
          <IonItem
            key={index}
            mode="md"
            className={`planDropdownItem ${
              Number(plan.id) === Number(selectedPlan?.id) ? "selectedPlan" : ""
            }`}
            lines="none"
            onClick={() => {
              onPlanSelectedHandler && onPlanSelectedHandler(plan);
              setIsCollapseOpen(false);
            }}
          >
            <PMLabel
              cssClass="planLabelItem"
              fontColor="light"
              fontFamily={plan.isActive ? "Bold" : "Light"}
            >
              {`${t(plan.name)} ${plan.isActive ? `(${t("active")})` : ""}`}
            </PMLabel>
          </IonItem>
        ))}
      </IonList>
    </PMCollapse>
  );
};

export default PlansDropdown;
