// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pmSearchBar {
  --placeholder-color: var(--ion-color-fontLight);
  padding-inline-start: 10px;
  --border-radius: 4px;
  opacity: 1;
  --icon-color: var(--ion-color-orange);
  --color: var(--ion-color-orange);
  --placeholder-opacity: 1;
  --placeholder-font-weight: 600;
  font-weight: 600;
  height: 50px;
}
.sc-ion-searchbar-md .searchbar-search-icon,
.sc-ion-searchbar-md-h .searchbar-search-icon {
  right: unset !important;
  left: 16px !important;
}

.sc-ion-searchbar-md .searchbar-clear-button,
.sc-ion-searchbar-md-h .searchbar-clear-button {
  left: unset !important;
  right: 13px !important;
}
.sc-ion-searchbar-md .searchbar-search-icon.sc-ion-searchbar-md:lang(he),
.sc-ion-searchbar-md-h .searchbar-search-icon.sc-ion-searchbar-md:lang(he) {
  left: unset !important;
  right: 16px !important;
}
.sc-ion-searchbar-md .searchbar-clear-button.sc-ion-searchbar-md:lang(he),
.sc-ion-searchbar-md-h .searchbar-clear-button.sc-ion-searchbar-md:lang(he) {
  right: unset !important;
  left: 13px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/SearchBar/SearchBar.css"],"names":[],"mappings":"AAAA;EACE,+CAA+C;EAC/C,0BAA0B;EAC1B,oBAAoB;EACpB,UAAU;EACV,qCAAqC;EACrC,gCAAgC;EAChC,wBAAwB;EACxB,8BAA8B;EAC9B,gBAAgB;EAChB,YAAY;AACd;AACA;;EAEE,uBAAuB;EACvB,qBAAqB;AACvB;;AAEA;;EAEE,sBAAsB;EACtB,sBAAsB;AACxB;AACA;;EAEE,sBAAsB;EACtB,sBAAsB;AACxB;AACA;;EAEE,uBAAuB;EACvB,qBAAqB;AACvB","sourcesContent":[".pmSearchBar {\n  --placeholder-color: var(--ion-color-fontLight);\n  padding-inline-start: 10px;\n  --border-radius: 4px;\n  opacity: 1;\n  --icon-color: var(--ion-color-orange);\n  --color: var(--ion-color-orange);\n  --placeholder-opacity: 1;\n  --placeholder-font-weight: 600;\n  font-weight: 600;\n  height: 50px;\n}\n.sc-ion-searchbar-md .searchbar-search-icon,\n.sc-ion-searchbar-md-h .searchbar-search-icon {\n  right: unset !important;\n  left: 16px !important;\n}\n\n.sc-ion-searchbar-md .searchbar-clear-button,\n.sc-ion-searchbar-md-h .searchbar-clear-button {\n  left: unset !important;\n  right: 13px !important;\n}\n.sc-ion-searchbar-md .searchbar-search-icon.sc-ion-searchbar-md:lang(he),\n.sc-ion-searchbar-md-h .searchbar-search-icon.sc-ion-searchbar-md:lang(he) {\n  left: unset !important;\n  right: 16px !important;\n}\n.sc-ion-searchbar-md .searchbar-clear-button.sc-ion-searchbar-md:lang(he),\n.sc-ion-searchbar-md-h .searchbar-clear-button.sc-ion-searchbar-md:lang(he) {\n  right: unset !important;\n  left: 13px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
