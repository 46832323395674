import React, { Dispatch, SetStateAction } from "react";

import "./InstructorFeedbackElement.css";
import { InstructorFeedbackGrades } from "../../../../Interfaces/IInstructorFeedback";
import { IonCol, IonList, IonRow } from "@ionic/react";
import PMLabel from "../../../themeComponents/PMLabel";
import InstructorFeedbackElementHook from "../../../CustomHooks/InstructorFeedbackElementHook";
import InstructorFeedbackGrade from "./InstructorFeedbackGrade";

export interface IProps {
  title: string;
  elements: string[];
  dataType: string;
  setGrade: Dispatch<SetStateAction<InstructorFeedbackGrades[]>>;
  resetAll: boolean;
  setResetAll: (state: boolean) => void;
  index: number;
  isMust: boolean;
  isTraineeSelected: boolean;
}
const IFElement: React.FC<IProps> = (props: IProps): JSX.Element => {
  const {
    title,
    elements,
    dataType,
    setGrade,
    resetAll,
    setResetAll,
    index,
    isMust,
    isTraineeSelected,
  } = props;
  const { setElementGrade, finalGrade } = InstructorFeedbackElementHook(
    elements,
    dataType,
    setGrade,
    resetAll,
    setResetAll
  );
  return (
    <React.Fragment>
      <IonRow
        className={"wrapUIFRow" + (index === 0 ? " firstRowElement" : "")}
      >
        <IonCol size="2" className="borderEnd">
          {isMust ? <span className="isMustCircle">{isMust}</span> : null}
          <div className="IFWrapTitle">
            <PMLabel
              textAlign="center"
              fontColor="xLight"
              fontFamily="Regular"
              fontSize="large"
            >
              {title}
            </PMLabel>
          </div>
        </IonCol>
        <IonCol size="6" className="columnIF borderEnd">
          <IonList className="UIFData">
            {elements.map((element, index) => (
              <IonRow key={index} className="elementRowUIF textRow">
                <div className="elementWrap elementWrapUnderLine">
                  <PMLabel
                    textAlign="start"
                    fontColor="xLight"
                    fontFamily="Light"
                    fontSize="medium"
                  >
                    {element}
                  </PMLabel>
                </div>
              </IonRow>
            ))}
          </IonList>
        </IonCol>
        <IonCol size="3" className="columnIF borderEnd">
          <IonList className="UIFData">
            {elements.map((element, index) => (
              <IonRow key={index} className="elementRowUIF">
                <div className="elementWrap ">
                  <InstructorFeedbackGrade
                    onGradeSelected={(grade: number | undefined) => {
                      setElementGrade(element, grade);
                    }}
                    isToRest={resetAll}
                    setIsToRest={setResetAll}
                    isTraineeSelected={isTraineeSelected}
                  ></InstructorFeedbackGrade>
                </div>
              </IonRow>
            ))}
          </IonList>
        </IonCol>
        <IonCol className="gradeColumn">
          <div className="UIFWrapFinalGrade">
            <PMLabel fontSize="xxLarge" fontColor="xLight" fontFamily="Regular">
              {finalGrade}
            </PMLabel>
          </div>
        </IonCol>
      </IonRow>
    </React.Fragment>
  );
};

export default IFElement;
