// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hashtagsLabels {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */

  padding-top: 10px;
  padding-inline-start: 5px;
  max-height: 90%;
  overflow-y: auto;
}

.hashtagsSearchDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hashtagsSearch {
  --background: var(--ion-color-primary) !important;
  --color: var(--ion-color-fontLight) !important;
  min-width: 80%;
  max-width: 80%;
}

.labelsDiv {
  width: 80%;
}

.noHashtags {
  display: flex;
  width: 100%;
  height: 200px;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: var(--ion-color-fontLight);
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/Hashtags/HashtagsLabels.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,oCAAoC;;EAEpC,iBAAiB;EACjB,yBAAyB;EACzB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,iDAAiD;EACjD,8CAA8C;EAC9C,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,iCAAiC;AACnC","sourcesContent":[".hashtagsLabels {\n  display: flex;\n  flex-wrap: wrap;\n  /* justify-content: space-between; */\n\n  padding-top: 10px;\n  padding-inline-start: 5px;\n  max-height: 90%;\n  overflow-y: auto;\n}\n\n.hashtagsSearchDiv {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.hashtagsSearch {\n  --background: var(--ion-color-primary) !important;\n  --color: var(--ion-color-fontLight) !important;\n  min-width: 80%;\n  max-width: 80%;\n}\n\n.labelsDiv {\n  width: 80%;\n}\n\n.noHashtags {\n  display: flex;\n  width: 100%;\n  height: 200px;\n  justify-content: center;\n  align-items: center;\n  font-size: 30px;\n  color: var(--ion-color-fontLight);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
