// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dotLegend {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  display: inline-block;
  margin-inline-end: 8px;
  background: yellowgreen;
  box-shadow: var(--box-shadow);
  margin-top: 3px;
}
.legend {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-inline-end: 20px;
  cursor: pointer;
}
.fadeLegendClass {
  height: 100%;
  display: flex;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/VerticalProgressBar/Legend.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,qBAAqB;EACrB,sBAAsB;EACtB,uBAAuB;EACvB,6BAA6B;EAC7B,eAAe;AACjB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB;AACA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".dotLegend {\n  height: 14px;\n  width: 14px;\n  border-radius: 50%;\n  display: inline-block;\n  margin-inline-end: 8px;\n  background: yellowgreen;\n  box-shadow: var(--box-shadow);\n  margin-top: 3px;\n}\n.legend {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-inline-end: 20px;\n  cursor: pointer;\n}\n.fadeLegendClass {\n  height: 100%;\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
