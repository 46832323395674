// css
import { IonRow } from "@ionic/react";
import "./TrainingTypeResults.css";
import { useTranslation } from "react-i18next";
import FinalGradeDisplay from "../../../components/Shared/FinalGradeDisplay/FinalGradeDisplay";
import PMLabel from "../../../components/themeComponents/PMLabel";
import ElementsResultWrap from "./ElementsResultWrap";
import PMButton from "../../../components/themeComponents/PMButton";
import { IElementResultRow } from "../../../Interfaces/Gorilla/IGorillaDesktopResults";

interface IProps {
  weight: number | undefined;
  grade: number | null | undefined;
  threshold: number | undefined;
  title: string | undefined;
  resultsArray: IElementResultRow[];
  isWithSuccessRate?: boolean;
  isWithWeight?: boolean;
  moreDetailsHandler?: () => void;
}
const TrainingTypeResults: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className="trainingTypWrap">
      <div className="singleGunnerTrainingTypWrap scrollS">
        <div>
          <IonRow className="trainingTypeGradWrap">
            <div className="trainingTypeName">
              <PMLabel
                fontColor="light"
                fontFamily="Bold"
                cssClass="gorillaTitleFont"
              >
                {`${t(props.title)} (${
                  Math.round(Number(props.weight)) || 0
                }%)`}
              </PMLabel>{" "}
            </div>

            <div className="trainingTypeGradDiv">
              <FinalGradeDisplay
                threshold={props.threshold || 0}
                soldierFinalGrade={{
                  grade: props.grade || 0,
                  legendColor: "transparent",
                  id: 0,
                  isAttended: props.grade !== null,
                  name: "",
                }}
              ></FinalGradeDisplay>
            </div>
          </IonRow>
          <ElementsResultWrap
            isWithWeight={props.isWithWeight}
            isWithSuccessRate={props.isWithSuccessRate}
            resultsArray={props.resultsArray}
          ></ElementsResultWrap>
        </div>
        <IonRow className="moreDetailsButtonRow">
          {props.moreDetailsHandler ? (
            <PMButton
              color="orange"
              fill="outline"
              size="fit"
              label={{
                fontColor: "light",
                fontFamily: "Light",
                fontSize: "medium",
              }}
              onClickHandler={props.moreDetailsHandler}
            >
              {t("moreDetails")}
            </PMButton>
          ) : null}{" "}
        </IonRow>
      </div>
    </div>
  );
};
export default TrainingTypeResults;
