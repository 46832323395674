// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.withBorderHeatsWrap {
  border-style: dashed;
  border-width: 0;
  border-top-width: 1px;
  border-color: var(--ion-color-disabled);
}

.finishTrainingHeats {
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-inline-start: var(--pm_spike_gorilla);
  padding-inline-end: var(--pm_spike_gorilla);
}
.endTrainingButton {
  margin-top: 14px;
  margin-bottom: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/SPIKEGorilla/EndTraining.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,eAAe;EACf,qBAAqB;EACrB,uCAAuC;AACzC;;AAEA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,6CAA6C;EAC7C,2CAA2C;AAC7C;AACA;EACE,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".withBorderHeatsWrap {\n  border-style: dashed;\n  border-width: 0;\n  border-top-width: 1px;\n  border-color: var(--ion-color-disabled);\n}\n\n.finishTrainingHeats {\n  height: 100px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding-inline-start: var(--pm_spike_gorilla);\n  padding-inline-end: var(--pm_spike_gorilla);\n}\n.endTrainingButton {\n  margin-top: 14px;\n  margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
