// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainView {
  border-radius: 4px;
  position: relative;
  background-color: var(--ion-color-dark);
}

.sideBar {
  border-radius: 4px;
  background-color: var(--ion-color-enable);
}

.viewsRow {
  height: 100%;
}

.viewCol {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}

.viewsGrid {
  padding: 0 !important;
  margin: 24px;
  box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.16);
}
.contentTP {
  height: 100%;
  display: flex;
  background-color: var(--ion-color-primary);
  border-radius: 4px;
  z-index: 120 !important;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Desktop/TrainingPlan/TrainingPlan.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,uCAAuC;AACzC;;AAEA;EACE,kBAAkB;EAClB,yCAAyC;AAC3C;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,+CAA+C;AACjD;AACA;EACE,YAAY;EACZ,aAAa;EACb,0CAA0C;EAC1C,kBAAkB;EAClB,uBAAuB;EACvB,uBAAuB;AACzB","sourcesContent":[".mainView {\n  border-radius: 4px;\n  position: relative;\n  background-color: var(--ion-color-dark);\n}\n\n.sideBar {\n  border-radius: 4px;\n  background-color: var(--ion-color-enable);\n}\n\n.viewsRow {\n  height: 100%;\n}\n\n.viewCol {\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 0 !important;\n}\n\n.viewsGrid {\n  padding: 0 !important;\n  margin: 24px;\n  box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.16);\n}\n.contentTP {\n  height: 100%;\n  display: flex;\n  background-color: var(--ion-color-primary);\n  border-radius: 4px;\n  z-index: 120 !important;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
