import { FC } from "react";
import { IonRow } from "@ionic/react";
import "./WarningRow.css";
import PMLabel from "../../themeComponents/PMLabel";
import { useTranslation } from "react-i18next";
import PMIcon from "../../themeComponents/PMIcon";
import EIconsSrc from "../../../Interfaces/EIconsSrc";

interface IProps {
  isToDisplay: boolean;
}

const WarningRow: FC<IProps> = (props: IProps) => {
  const { isToDisplay } = props;
  const { t } = useTranslation();
  return (
    <>
      {isToDisplay ? (
        <IonRow className="warningRow">
          <PMIcon
            disabled={true}
            iconSrc={EIconsSrc.ALERT_TRIANGLE}
            size="xLarge"
            cssClass="warningIcon"
          ></PMIcon>
          <PMLabel
            fontFamily="Regular"
            fontSize="medium"
            fontColor="light"
            textAlign="center"
          >
            {t("spikeWarningText")}
          </PMLabel>
        </IonRow>
      ) : null}
    </>
  );
};

export default WarningRow;
