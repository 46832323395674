import { FC, useContext, useEffect, useState } from "react";
import "./RadarGraph.css";
import { Radar } from "react-chartjs-2";
import { IChartRadarData } from "../../../../Interfaces/IChartData";
import IRadarData from "../../../../Interfaces/IRadarData";
import IForceFromOrbat from "../../../../Interfaces/IForceFromOrbat";
import IRadarInfo from "../../../../Interfaces/IRadarInfo";
import IChartGrade from "../../../../Interfaces/IChartGrade";
import ILabel from "../../../../Interfaces/ILabel";
import IThreshold from "../../../../Interfaces/IThreshold";
import IDatasetsChart from "../../../../Interfaces/IDatasetsChart";
import { useTranslation } from "react-i18next";
import NotFoundPlan from "../../../../pages/Desktop/NotFound/NotFoundPlan";
import IPlan from "../../../../Interfaces/IPlan";
import { sortByLabelId } from "../../../../services/helpers";
import { IIndicatorResult } from "../../../../Interfaces/dataCalculator";
import Spinner from "../../Spinner/Spinner";
import { AppContext } from "../../../../context/AppContext/AppContext";
import ELanguage from "../../../../Enums/ELanguage";

import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  ChartOptions,
  Legend,
  ChartDataset,
} from "chart.js";

// Register required components for Chart.js
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);
interface IProps {
  selectedForces: (IForceFromOrbat | undefined)[] | undefined;
  radarData: IRadarInfo[] | undefined;
  thresholds: IThreshold[] | undefined;
  labels: ILabel[] | undefined;
  newForces: (IForceFromOrbat | undefined)[] | undefined;
  forcesToRemove: IForceFromOrbat[] | undefined;
  id?: string;
  className?: string;
  setDataForMobileLabels?: (data: IDatasetsChart[]) => void;
  isModal?: boolean;
  color?: string;
  planId?: number | undefined;
  plan?: IPlan | undefined;
  hidden?: boolean;
}

const RadarGraph: FC<IProps> = (props: IProps): JSX.Element => {
  const { isAsDesktop } = useContext(AppContext);
  const isMobile: boolean = !isAsDesktop;

  const { t, i18n } = useTranslation();
  const {
    selectedForces,
    radarData,
    thresholds,
    labels,
    forcesToRemove,
    setDataForMobileLabels,
    isModal,
    color,
    planId,
    plan,
  } = props;

  const [loading, setLoading] = useState<boolean>(true);
  const [grades, setGrades] = useState<IChartGrade[]>();
  const [data, setData] = useState<any>({
    labels: [],
    datasets: [],
  });
  let allGrades: IChartGrade[] = [];

  useEffect(() => {
    let timer = setTimeout(() => setLoading(false), 500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (isMobile && setDataForMobileLabels) {
      setDataForMobileLabels([...data.datasets]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const initializeThresholdAndLabels = (prev: IChartRadarData) => {
    let newData: IChartRadarData = { ...prev };
    let sortedThresholds = sortByLabelId(thresholds, labels);
    sortedThresholds = sortedThresholds.filter((element: any) =>
      labels?.find((label) => Number(label.id) === Number(element.id))
    );
    newData["labels"] = labels?.map((labelItem: ILabel) => t(labelItem.label));
    newData.datasets.splice(0);
    newData.datasets = [
      {
        order: 0,
        label: t("thresholdForOperationalIndicators"),
        borderColor: "rgb(0,176,117)",
        borderWidth: 3.5,
        pointBackgroundColor: "rgb(0, 176, 117)",
        pointBorderColor: "transparent",
        pointHoverBackgroundColor: "transparent",
        pointHoverBorderColor: "transparent",
        data: sortedThresholds?.map(
          (thresholdItem: IThreshold) => thresholdItem.threshold
        ),
        borderDash: [5, 3],
        fill: false,
      },
      ...newData.datasets,
    ];
    return newData;
  };

  useEffect(() => {
    if (thresholds && labels) {
      setData((prev: IChartRadarData) => initializeThresholdAndLabels(prev));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thresholds, labels]);

  useEffect(() => {
    const getRadarGrades = () => {
      radarData?.map((radarItem: IRadarInfo) => {
        let sortedIndicators = sortByLabelId(radarItem.indicators, labels);
        sortedIndicators = sortedIndicators.filter((element: any) =>
          labels?.find((label) => Number(label.id) === Number(element.id))
        );
        allGrades.push({
          id: radarItem.id,
          grades: sortedIndicators?.length
            ? sortedIndicators?.map((indicator: IRadarData) => indicator.grade)
            : labels?.map(() => 0),
        });
        return radarItem.indicators?.map(
          (indicator: IIndicatorResult) => indicator.grade
        );
      });

      return allGrades;
    };
    if (selectedForces) {
      setGrades(() => getRadarGrades());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radarData]);

  useEffect(() => {
    if (grades?.length) {
      setData((prev: IChartRadarData) => {
        let datasets: ChartDataset<"radar">[] = [prev.datasets[0]];

        grades.forEach((gardeObject) => {
          let force = selectedForces?.find(
            (force) => Number(force?.id) === Number(gardeObject.id)
          );
          if (force) {
            let forceData = grades
              .find(
                (currentGrade) => Number(currentGrade.id) === Number(force!.id)
              )
              ?.grades?.map((grade) => Math.round(grade));
            datasets.push({
              order: force!.id,
              label: force!.name,
              backgroundColor: "rgba(205,205,205,0.2)",
              borderColor: isModal === true && color ? color : force!.color,
              borderWidth: 3.5,
              pointBackgroundColor:
                isModal === true && color ? color : force!.color,
              pointBorderColor: "transparent",
              pointHoverBackgroundColor: "transparent",
              pointHoverBorderColor: "transparent",
              data: forceData || [],
            });
          }
        });

        return { ...prev, datasets: [...datasets] };
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grades]);

  useEffect(() => {
    if (forcesToRemove?.length) {
      setData((prev: IChartRadarData) => {
        const updatedDatasets = prev.datasets.filter((forceElement: any) => {
          const isForceToRemove = forcesToRemove.some(
            (forceToRemove: IForceFromOrbat) =>
              Number(forceToRemove.id) === Number(forceElement.order)
          );
          return !isForceToRemove;
        });

        return {
          ...prev,
          datasets: updatedDatasets,
        };
      });
    }
  }, [forcesToRemove]);
  const options: ChartOptions<"radar"> = {
    borderColor: "rgba(255, 0, 0, 1)",
    color: "rgba(255, 0, 0, 1)",
    backgroundColor: "rgba(255, 0, 0, 1)",
    font: {
      size: 8,
      family: "SemiBold",
    },
    elements: {
      point: { borderWidth: 4 },
    },

    scales: {
      r: {
        beginAtZero: true,
        angleLines: { display: true, color: "rgba(205,205,205,0.3)" },
        grid: { color: "rgba(205,205,205,0.3)" },
        pointLabels: {
          font: { family: "SemiBold", size: 20 },
          color: "#DDDDDD",
        },
        ticks: {
          color: "#DDDDDD",
          backdropColor: "#4B4D52",
          font: { family: "SemiBold", size: 12 },
          showLabelBackdrop: true,
          stepSize: 10,
          display: !isMobile,
        },
        min: 0,
        max: 100,
      },
    },
    layout: {
      padding: {
        top: 0,
        bottom: 0,
        left: 10,
        right: 10,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      datalabels: {
        anchor: "end",
        align: "end",
        color: "#DDDDDD",
        textAlign: "center",
        font: {
          size: 12,
          family: "SemiBold",
        },
        display: !isMobile,
        offset: 2,
        padding: 0,
      },
      tooltip: {
        backgroundColor: "#36383E",
        titleMarginBottom: 6,
        caretPadding: 3,
        caretSize: 5,
        cornerRadius: 10,
        displayColors: false,
        titleAlign: "right",
        bodyAlign: "right",
        callbacks: {
          title: (tooltipItem) =>
            data.labels ? data.labels[tooltipItem[0].dataIndex] : null,
        },
      },
      legend: {
        display: !isMobile,
        rtl: i18n.language === ELanguage.he,
        position: "bottom",
        reverse: true,
        fullSize: false,
        labels: {
          boxWidth: 25,
          color: "rgba(255, 255, 255, 0.89)",
          padding: 10,
          pointStyle: "circle",
          usePointStyle: true,
          boxHeight: 10 + 3.5,
          font: {
            size: isModal === true ? 14 : 20,
            family: "Regular",
          },
          filter: (item) => {
            return item.datasetIndex !== 0;
          },
        },
        title: {
          display: false,
        },
      },
    },
  };
  return (
    <div className={`radarContainer ${props.className}`} id={props.id}>
      {loading ? (
        <Spinner />
      ) : labels?.length !== 0 ? (
        props.hidden ? null : (
          <Radar data={data} options={options} />
        )
      ) : (
        <div className="notFoundText">
          {planId || plan ? (
            <NotFoundPlan text={t("notFoundPlanText")} />
          ) : (
            <NotFoundPlan text={t("noActivePlanSelected")} />
          )}
        </div>
      )}
    </div>
  );
};

export default RadarGraph;
