import { IonRow, IonCol, IonLabel } from "@ionic/react";
import React, { useEffect, useState } from "react";

import "./FIreSupportComponent.css";
import PMLabel from "../../themeComponents/PMLabel";
import { useTranslation } from "react-i18next";
import FireSupportTrainingType from "../../Shared/MenualStation/FireSupport/FireSupportTrainingType";
import UseGetFireSupportDataPosting, {
  IFireSupportTrainingTypeData,
} from "../../CustomHooks/UseGetFireSupportDataPosting";
import { sendDrillToBBackend } from "./dataPostingHelpers";
import customToast from "../../Shared/Toast/CustomToast";
import MobileToast from "../../CustomHooks/MobileToast";
import { IMobileDataPostingProps } from "../../../Interfaces/IMobileDataPostingProps";

interface IProps extends IMobileDataPostingProps {
  traineeId: number | undefined;
  afterPostHandler?: () => void;
  isDesktop?: boolean;
  isComplete: boolean;
  setIsComplete: (state: boolean) => void;
}
const FireSupportComponent: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation();
  const [isRestAll, setIsResetAll] = useState<boolean>(false);
  const { presentToast } = MobileToast();
  const [finalGrade, setFinalGrade] = useState<number>(0);

  const { dataPostingTrainingTypes, setDataPostingTrainingTypes } =
    UseGetFireSupportDataPosting();
  /**
   * update the selected grade by element id
   * @param elementId
   * @param grade
   */
  const setElementGarde = (
    elementId: number,
    grade: string | number | undefined
  ) => {
    setDataPostingTrainingTypes((prev) => [
      ...prev.map((trainingType) => ({
        ...trainingType,
        elements: trainingType.elements.map((element) =>
          element.id === elementId
            ? {
                ...element,
                selectedGrade: grade,
              }
            : element
        ),
      })),
    ]);
  };
  //update the assessment by training type id
  const setAssessment = (trainingTypeId: number, assessment: string) => {
    setDataPostingTrainingTypes((prev) =>
      prev.map((trainingType) =>
        trainingType.id === trainingTypeId
          ? {
              ...trainingType,
              assessment: assessment,
            }
          : trainingType
      )
    );
  };

  useEffect(() => {
    //checking if all elements have a selected garde
    let isComplete = true;
    let finalGarde = 0;
    dataPostingTrainingTypes.forEach((trainingType) => {
      let isCompleteTT = !trainingType.elements.find((e) => !e.selectedGrade);
      finalGarde += trainingType.elements.reduce(
        (a, b) => a + (Number(b?.selectedGrade) || 0),
        0
      );
      if (isComplete) isComplete = isCompleteTT;
    });
    setFinalGrade(finalGarde);
    props.setIsComplete(isComplete);
  }, [dataPostingTrainingTypes]);

  //reset all elements grades and reset assessments for each training type
  const resetAllData = () => {
    setDataPostingTrainingTypes((prev) =>
      prev.map((trainingType) => ({
        ...trainingType,
        elements: trainingType.elements.map((element) => ({
          ...element,
          selectedGrade: undefined,
        })),
        assessment: "",
      }))
    );
  };
  //send each training type data to b-backend
  const sendData = () => {
    let isShowToast = false;
    dataPostingTrainingTypes.map((trainingType) => {
      let rowToSend: any = {
        SoldierID: props.traineeId,
        MessageType: 6,
        TimeStamp: new Date(),
        TrainingTypeID: trainingType.id,
        DrillStartTime: new Date(),
        PlanId: props.planId,
        Assessment: trainingType.assessment,
      };
      trainingType.elements.map((element) => {
        rowToSend[element.dataTypeName] = element.selectedGrade;
      });
      // Then sends it
      sendDrillToBBackend(
        rowToSend,
        () => {
          if (!isShowToast) {
            if (props.isDesktop) customToast.success(t("dataSendSuccess"));
            else {
              presentToast("success", `${t("dataSendSuccess")} `);
            }
            isShowToast = true;
            setIsResetAll(true);
            props.afterPostHandler && props.afterPostHandler();
          }
        },
        () => {
          if (!isShowToast) {
            if (props.isDesktop) customToast.error(t("dataSendError"));
            else {
              presentToast("danger", `${t("dataSendError")} `);
            }
            isShowToast = true;
          }
        }
      );
    });
  };

  //reset all data when new trainee selected
  useEffect(() => {
    setIsResetAll(true);
  }, [props.traineeId]);

  //reset all data when isRestAll = true
  useEffect(() => {
    if (isRestAll) resetAllData();
  }, [isRestAll]);

  // send the data to b-backend when the send button clicked
  useEffect(() => {
    if (props.isSendClicked) sendData();
  }, [props.isSendClicked]);

  return (
    <React.Fragment>
      <IonCol className="fireSupportComponent">
        <IonRow className="titleRowHT">
          <IonCol size="10">
            <IonLabel></IonLabel>
          </IonCol>

          <IonCol className="colL">
            <PMLabel
              textAlign="center"
              fontColor="xLight"
              fontFamily="Regular"
              fontSize="large"
            >
              {t("Assessment")}
            </PMLabel>
          </IonCol>
        </IonRow>
        <IonRow className="UFIWrap">
          <div className="rowUIFC">
            {dataPostingTrainingTypes?.map(
              (trainingTypeData: IFireSupportTrainingTypeData, index) => (
                <FireSupportTrainingType
                  key={index}
                  elements={trainingTypeData.elements}
                  title={trainingTypeData.name}
                  setGrade={setElementGarde}
                  resetAll={isRestAll}
                  setResetAll={setIsResetAll}
                  isTraineeSelected={
                    props.traineeId !== undefined && !isNaN(props.traineeId)
                  }
                  setAssessment={(assessment: string) =>
                    setAssessment(trainingTypeData.id, assessment)
                  }
                  assessment={trainingTypeData.assessment}
                ></FireSupportTrainingType>
              )
            )}
          </div>
        </IonRow>
      </IonCol>{" "}
      <IonRow className="FinalGardeRowFS">
        <IonCol size="1.5"></IonCol>
        <IonCol className="finalGradeColFS">
          <PMLabel
            fontColor="light"
            fontFamily="ExtraBold"
            cssClass="finalGradeFS"
          >
            {t("finalGrade")}
          </PMLabel>
        </IonCol>
        <IonCol size="">
          <PMLabel
            fontColor="light"
            fontFamily="ExtraBold"
            cssClass="finalGradeFS grade"
          >
            {finalGrade}
          </PMLabel>
        </IonCol>
      </IonRow>
    </React.Fragment>
  );
};

export default FireSupportComponent;
