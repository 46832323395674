// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rankingsDiv {
  width: 400px;
  height: 220px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.rankingsList {
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-top: 10px;
  font-size: 18px;
}
.RankingWrap {
  width: 100%;
  height: 100%;
}

.rankingDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1 1;
  flex-basis: 35%;
  margin: 10px;
}

.rankingName {
  font-weight: 600;
  color: var(--ion-color-fontXLight);
}
.rankingValue {
  font-weight: 400;
  color: var(--ion-color-fontLight);
}
.legendContainerRanking {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.legendTextStyleRanking {
  color: var(--ion-color-fontXLight);
  margin: 10px;
  text-align: center;
}

.legendStyleRanking {
  display: "inline-block";
  border-radius: 50%;
  width: 12px;
  height: 12px;
}
.legendTextRanking {
  margin-inline-start: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Mobile/PersonalZone/Competency/Rankings/Rankings.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,eAAe;;EAEf,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;EAC9B,SAAO;EACP,eAAe;EACf,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,kCAAkC;AACpC;AACA;EACE,gBAAgB;EAChB,iCAAiC;AACnC;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,kCAAkC;EAClC,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;AACA;EACE,yBAAyB;AAC3B","sourcesContent":[".rankingsDiv {\n  width: 400px;\n  height: 220px;\n  display: flex;\n  flex-direction: column;\n  overflow-y: auto;\n}\n\n.rankingsList {\n  height: 90%;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n\n  margin-top: 10px;\n  font-size: 18px;\n}\n.RankingWrap {\n  width: 100%;\n  height: 100%;\n}\n\n.rankingDiv {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  flex: 1;\n  flex-basis: 35%;\n  margin: 10px;\n}\n\n.rankingName {\n  font-weight: 600;\n  color: var(--ion-color-fontXLight);\n}\n.rankingValue {\n  font-weight: 400;\n  color: var(--ion-color-fontLight);\n}\n.legendContainerRanking {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n}\n.legendTextStyleRanking {\n  color: var(--ion-color-fontXLight);\n  margin: 10px;\n  text-align: center;\n}\n\n.legendStyleRanking {\n  display: \"inline-block\";\n  border-radius: 50%;\n  width: 12px;\n  height: 12px;\n}\n.legendTextRanking {\n  margin-inline-start: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
