// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dataListDiv {
  width: 100%;
  height: 100%;
}
.dataListInput {
  background-color: transparent;
  width: 100%;
  height: 100%;
  padding-top: auto;
  padding-bottom: auto;
  border: none;
  box-shadow: none;
  font-family: "Light";
  padding-inline-start: 8px;
  font: 12px "Light", Helvetica, sans-serif;
}
.dataListInput:focus {
  box-shadow: none !important;
}

.dataListDiv input:focus {
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/DataPosting/SoldiersListEditor.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,6BAA6B;EAC7B,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,oBAAoB;EACpB,YAAY;EACZ,gBAAgB;EAChB,oBAAoB;EACpB,yBAAyB;EACzB,yCAAyC;AAC3C;AACA;EACE,2BAA2B;AAC7B;;AAEA;EACE,aAAa;AACf","sourcesContent":[".dataListDiv {\n  width: 100%;\n  height: 100%;\n}\n.dataListInput {\n  background-color: transparent;\n  width: 100%;\n  height: 100%;\n  padding-top: auto;\n  padding-bottom: auto;\n  border: none;\n  box-shadow: none;\n  font-family: \"Light\";\n  padding-inline-start: 8px;\n  font: 12px \"Light\", Helvetica, sans-serif;\n}\n.dataListInput:focus {\n  box-shadow: none !important;\n}\n\n.dataListDiv input:focus {\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
