import { FC } from "react";
import { useTranslation } from "react-i18next";
import "./EmptyState.css";
import NotExistLabel from "../NotExistLabel/NotExistLabel";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
type Props = { text: string };

const EmptyState: FC<Props> = (props: Props) => {
  const { text } = props;
  const { t } = useTranslation();

  return (
    <div className="EmptyStateIcon">
      <NotExistLabel text={t(text)} className="not-selected" />
      <img
        alt="empty-state-pic"
        className="emptyStateImage"
        src={EIconsSrc.EMPTY_STATE}
      />
    </div>
  );
};

export default EmptyState;
