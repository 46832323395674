import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import readExcelFile from "read-excel-file";
import { useTranslation } from "react-i18next";
import "./ImportTranslationsFile.css";
import PMIcon from "../../themeComponents/PMIcon";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import PMLabel from "../../themeComponents/PMLabel";
import ExcelJS from "exceljs";
import { BorderStyle, Column } from "exceljs";
import { TFunction } from "i18next";
import { saveAs } from "file-saver";
import ELanguage from "../../../Enums/ELanguage";
import PopoverItem from "../../themeComponents/PopoverItem";
import Axios from "../../../Axios";
import customToast from "../Toast/CustomToast";
import { baseUrlPMBackend } from "../../../Configurations/consts";
import PopoverMenu from "../Popover/PopoverMenu";

interface IProps {}
const fileHeaders = ["Phrase", "English", "Hebrew", "German", "Ukrainian"];
const firstRowIndex = 1;
const phraseIndex = 0;

const ExportImportTranslationsFile: React.FC<IProps> = (props: IProps) => {
  const { t, i18n } = useTranslation();
  const [file, setFile] = useState<(string | null)[][]>();
  const importTranslationsFiles = async (file: (string | null)[][]) => {
    if (!validateFileStructure(file)) {
      customToast.error(t("translationsFileStructError"));
      return;
    }

    const translations: { [key: string]: { [key: string]: string } } = {
      [ELanguage.he]: {},
      [ELanguage.en]: {},
      [ELanguage.de]: {},
      [ELanguage.ua]: {},
    };

    const languageIndices = {
      [ELanguage.he]: file[firstRowIndex].indexOf(fileHeaders[2]),
      [ELanguage.en]: file[firstRowIndex].indexOf(fileHeaders[1]),
      [ELanguage.de]: file[firstRowIndex].indexOf(fileHeaders[3]),
      [ELanguage.ua]: file[firstRowIndex].indexOf(fileHeaders[4]),
    };

    file.forEach((row) => {
      const phrase = row[phraseIndex];
      if (phrase) {
        Object.keys(languageIndices).forEach((lang) => {
          const index = languageIndices[lang as ELanguage];
          if (row[index]) {
            translations[lang][phrase] = row[index] || "";
          }
        });
      }
    });

    try {
      await Promise.all(
        Object.keys(translations).map((lang) =>
          senFileToBackend(translations[lang], lang)
        )
      );
      customToast.success(t("translationsImportedSuccessfully"));
    } catch (error) {
      customToast.error(t("translationsImportedError"));
    }
  };

  // Send the translations to the backend
  const senFileToBackend = (
    translations: { [key: string]: string },
    language: string
  ) => {
    const formData = new FormData();
    formData.append(
      "file",
      new Blob([JSON.stringify(translations)], { type: "application/json" }),
      "translations.json"
    );
    formData.append("language", language);

    return Axios.post(
      `${baseUrlPMBackend}localization/updateTranslations`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  };

  const validateFileStructure = (rows: (string | null)[][]): boolean => {
    return (
      fileHeaders.every((header: any) =>
        rows[firstRowIndex].includes(header)
      ) && rows.every((row) => row.length === fileHeaders.length)
    );
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    readExcelFile(acceptedFiles[0]).then((rows: (string | null)[][]) => {
      setFile(rows);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "application/vnd.ms-excel": [".xlsx"] },
  });

  useEffect(() => {
    if (file) {
      importTranslationsFiles(file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <>
      <div
        className="exportImportTranslationContainer"
        onClick={() => exportI18nextTranslations(i18n, t)}
      >
        <PopoverItem fontColor="light" fontFamily="Light" fontSize="medium">
          <PMLabel
            textAlign="center"
            fontColor="light"
            fontFamily="Light"
            fontSize="medium"
          >
            {t("export")}
          </PMLabel>
          <PMIcon
            size="large"
            color="light"
            iconSrc={EIconsSrc.XLS_FILE_EXPORT}
          ></PMIcon>
        </PopoverItem>
      </div>
      <div className="exportImportTranslationContainer" {...getRootProps()}>
        <PopoverItem fontColor="light" fontFamily="Light" fontSize="medium">
          <input {...getInputProps()} />
          <PMLabel
            textAlign="center"
            fontColor="light"
            fontFamily="Light"
            fontSize="medium"
          >
            {t("import")}
          </PMLabel>
          <PMIcon iconSrc={EIconsSrc.XLS_FILE_IMPORT} size="large" />
        </PopoverItem>
      </div>
    </>
  );
};

const exportI18nextTranslations = async (i18n: any, t: TFunction) => {
  try {
    let englishTranslations = i18n.getResource(ELanguage.en, "translation", "");
    let hebrewTranslations = i18n.getResource(ELanguage.he, "translation", "");
    let germanTranslations = i18n.getResource(ELanguage.de, "translation", "");
    let UKrainianTranslations = i18n.getResource(
      ELanguage.ua,
      "translation",
      ""
    );

    let keysSet = new Set([
      ...Object.keys(englishTranslations),
      ...Object.keys(hebrewTranslations),
      ...Object.keys(germanTranslations),
      ...Object.keys(UKrainianTranslations),
    ]);
    const phrasesArray = Array.from(keysSet);

    const borderStyle: BorderStyle = "medium";

    const fontSize = 14;
    let rows: any[] = [];
    const workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet("sheet", {
      pageSetup: {
        orientation: "portrait",
        fitToWidth: 1,
        fitToHeight: 0,

        margins: {
          bottom: 0,
          footer: 0,
          left: 0.3,
          top: 0,
          right: 0.3,
          header: 0,
        },
      },

      views: [{ rightToLeft: false }],
    });

    rows.push(fileHeaders);
    rows.push([]);

    let borderRows: number[] = [];
    // Iterate through your data and populate the worksheet
    phrasesArray
      .sort((a, b) => a.localeCompare(b))
      ?.forEach((phrase) => {
        rows.push([
          phrase,
          englishTranslations[phrase],
          hebrewTranslations[phrase],
          germanTranslations[phrase],
          UKrainianTranslations[phrase],
        ]);
      });
    let columnsObj = [];
    for (let index = 0; index < fileHeaders.length; index++) {
      columnsObj.push({
        header: "",
        key: String(index),
        style: { font: { size: fontSize, name: "Ariel" } },
      });
    }
    worksheet.columns = columnsObj;

    worksheet.columns.forEach((column: Partial<Column>, index: number) => {
      column.width = 60;
      column.alignment = { horizontal: index === 2 ? "right" : "left" };
    });

    rows.forEach((e: string[], index: number) => {
      const rowIndex = index + 1;
      worksheet.addRow({
        ...e,
        amountRemaining: {
          formula: `=C${rowIndex}-D${rowIndex}`,
        },
        percentRemaining: {
          formula: `=E${rowIndex}/C${rowIndex}`,
        },
      });
    });
    borderRows.forEach((rowNumber) => {
      worksheet.getRow(rowNumber).border = {
        bottom: { style: borderStyle },
      };
    });

    worksheet.getRow(2).font = { bold: true, size: fontSize };
    worksheet.getRow(2).alignment = { horizontal: "center" };

    // Save the workbook
    const filename = "translations";
    const buffer = await workbook.xlsx.writeBuffer();
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const fileExtension = ".xlsx";
    const blob = new Blob([buffer], { type: fileType });
    saveAs(blob, filename + fileExtension); // Save the file
  } catch (error) {
    customToast.error(t("translationsExportError"));
  }
};

const ExportImportPopover = () => {
  const { t } = useTranslation();
  const [popoverState, setShowPopover] = useState<{
    showPopover: boolean;
    event: MouseEvent | undefined;
  }>({
    showPopover: false,
    event: undefined,
  });

  return (
    <PopoverItem
      fontColor="light"
      fontFamily="Light"
      fontSize="medium"
      background="Blight"
      onClickHandler={(e) =>
        setShowPopover({
          showPopover: true,
          event: e.nativeEvent,
        })
      }
    >
      <PMLabel
        textAlign="center"
        fontColor="light"
        fontFamily="Light"
        fontSize="medium"
      >
        {t("translations")}
      </PMLabel>

      <PopoverMenu
        className="appModesPopover"
        popoverState={popoverState}
        onDismiss={() =>
          setShowPopover({
            showPopover: false,
            event: undefined,
          })
        }
      >
        <ExportImportTranslationsFile />
      </PopoverMenu>
    </PopoverItem>
  );
};
export default ExportImportPopover;
