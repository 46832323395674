import { IonCol, IonRow } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";

import "./InputTable.css";
import PMLabel from "../../themeComponents/PMLabel";
import { IHit, ITableData, ITableResult } from "../../../Interfaces/ITableData";
import RateInput from "../../Shared/RateInput/RateInput";

interface IProps {
  elementTitle: string;
  valueTitle: string;
  tableTitle?: string;
  tableData: ITableData | ITableResult;
  onChangeHandler?: (
    field: string,
    value: string | IHit,
    isForHits?: boolean
  ) => void;
  maximumValue?: number | null;
  maximumRate?: number | null;
  editable?: boolean;
}
const InputTable: React.FC<IProps> = (props: IProps): JSX.Element => {
  const {
    elementTitle,
    tableData,
    valueTitle,
    tableTitle,
    onChangeHandler,
    editable,
  } = props;

  const { t } = useTranslation();
  const getSumValue = (): number => {
    return Object.values(tableData).reduce((a, b) => {
      let value = b?.hits || 0;
      return a + value;
    }, 0);
  };
  const getSumRate = (): number => {
    return Object.values(tableData).reduce((a, b) => {
      let value = b?.shots || 0;
      return a + value;
    }, 0);
  };

  const isWrongValue = (val: IHit | null): boolean => {
    let hit = val?.hits;
    let shot = val?.shots;
    return hit && shot && hit > shot ? true : false;
  };

  return (
    <div className="inputTable">
      <IonRow className="inputTableRow">
        <PMLabel
          fontFamily="Regular"
          fontColor="light"
          fontSize="large"
          textAlign="center"
        >
          {t(tableTitle)}
        </PMLabel>
      </IonRow>
      <IonRow className="inputTableRow">
        <IonCol size="7" className="inputTableCol ">
          <PMLabel
            fontFamily="Regular"
            fontColor="light"
            fontSize="small"
            textAlign="center"
          >
            {t(elementTitle)}
          </PMLabel>
        </IonCol>
        <IonCol className="inputTableCol ">
          <PMLabel
            fontFamily="Regular"
            fontColor="light"
            fontSize="small"
            textAlign="center"
          >
            {t(valueTitle)}
          </PMLabel>
        </IonCol>
      </IonRow>{" "}
      {Object.keys(tableData).map((key, index) => (
        <IonRow className="inputTableRow" key={index}>
          <IonCol size="7" className="inputTableCol">
            <PMLabel
              fontFamily="Regular"
              fontColor="light"
              fontSize="small"
              textAlign="center"
            >
              {t(key)}
            </PMLabel>
          </IonCol>
          <IonCol className="inputTableCol valueColIT">
            {editable && onChangeHandler ? (
              <RateInput
                wrongValue={
                  (props.maximumValue &&
                    Number(props.maximumValue) < getSumValue()) ||
                  (props.maximumRate &&
                    Number(props.maximumRate) < getSumRate()) ||
                  isWrongValue(tableData[key] as IHit)
                }
                value={(tableData[key] as IHit)?.hits || ""}
                rate={(tableData[key] as IHit)?.shots || ""}
                onChangeHandler={(value: IHit) => {
                  editable &&
                    onChangeHandler &&
                    onChangeHandler(key, value, true);
                }}
              ></RateInput>
            ) : (
              <PMLabel
                fontColor="light"
                fontFamily="Regular"
                textAlign="center"
              >
                {tableData[key]}
              </PMLabel>
            )}
          </IonCol>
        </IonRow>
      ))}
    </div>
  );
};

export default InputTable;
