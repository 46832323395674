import {
  IonRadioGroup,
  IonIcon,
  IonPage,
  IonRadio,
  IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import useGetPerformanceData from "../../../components/CustomHooks/UseGetPerformanceData";
import { IDataHistory } from "../../../Interfaces/IDataHistory";
import { IDateRange } from "../../../Interfaces/IDatePicker";
import DatePicker from "../../../components/Desktop/DataPosting/DatePicker";
// css
import { useTranslation } from "react-i18next";
import "./DataHistory.css";
import { close } from "ionicons/icons";
import {
  MAX_SELECTED,
  baseUrlPMBackend,
  environment,
} from "../../../Configurations/consts";
import Axios from "../../../Axios";
import customToast from "../../../components/Shared/Toast/CustomToast";
import AdminPasswordModal from "../../../components/Desktop/AdminPassword/AdminPasswordModal";
import { getForceFullName } from "../../../services/helpers";
import PMButton from "../../../components/themeComponents/PMButton";
import DataTransferTool from "../../../components/Desktop/DataTransferTool/DataTransferTool";
import { UserContext } from "../../../context/UserContext/userContext";
import PMLabel from "../../../components/themeComponents/PMLabel";
import { checkSuperUserPassword } from "../../../services/passwordsHandler";
import IDrillSummarizedData from "../../../Interfaces/IDataHistoryData";
import EEnvironment from "../../../Enums/EEnvironment";
import Spinner from "../../../components/Shared/Spinner/Spinner";
import SaveCancelButtons from "../../../components/Shared/Buttons/SaveCancelButtons";
import EDatatypesTypes from "../../../Interfaces/EDatatypesTypes";
import OrbatInput from "../../../components/Shared/Orbat/Input/OrbatInput";
import { useWeapons } from "../../../components/CustomHooks/HooksWithRQ/useWeapons";
import { useDataHistoryTabsHeaders } from "../../../components/CustomHooks/useDataHistoryTabsHeaders";
import { IDataType } from "../../../Interfaces/IDataType";
import PlansDropdown from "../../../components/Shared/PlansDropdown/PlansDropdown";
import {
  Column,
  EEditorType,
} from "../../../components/Desktop/PMDataGrid/DataGridTypes";
import DataGrid from "../../../components/Desktop/PMDataGrid/DataGrid";

const DataHistory: React.FC<IDataHistory> = (
  props: IDataHistory
): JSX.Element => {
  const {
    headers,
    trainingTypeId,
    rangeDate,
    setRangeDate,
    selectedSoldier: selectedForces,
    setSelectedSoldier: setSelectedForces,
    selectedPlan,
    plansOptions,
    onPlanSelectedHandler,
  } = props;
  const keysToHideInDetails = [
    "id",
    "DrillId",
    "drillId",
    "DrillStartTime",
    "DrillEndTime",
    "rowIndex",
    "forceType",
    "isDetails",
    "date",
    "soldierId",
    "soldierName",
    "trainingTypeName",
    "trainingPlan",
    "hour",
    "trainingTypeId",
  ];
  const trainingPlanHeader = "trainingPlan";
  const [selectedRows, setSelectedRowIds] = useState<Set<string | number>>(
    new Set()
  );
  const [deletedRows, setDeletedRowIds] = useState<Set<string | number>>(
    new Set()
  );

  const { t } = useTranslation();
  const { isAdmin } = useContext(UserContext);
  const [columns, setColumns] = useState<Column<IDrillSummarizedData>[]>([]);
  const [isDeleteRows, setDeleteRows] = useState<boolean>(false);

  const [invalid, setInvalid] = useState<boolean>(false);
  const [isTrainingTool, setIsTrainingTool] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [filteredSoldierUniqueIds, setFilteredSoldierUniqueIds] =
    useState<number[]>();
  const [isEditRowClicked, setEditRowClicked] = useState<boolean>(false);
  const [isEditRowInProcess, setEditRowInProcess] = useState<boolean>(false);
  const [weaponTypes, setWeaponTypes] = useState<string[]>([]);
  const [tempEditedRow, setTempEditedRow] = useState<IDrillSummarizedData>(
    {} as IDrillSummarizedData
  );
  const setRangeDateAsAllDay = (dateRange: IDateRange) => {
    setRangeDate({
      startDate: new Date(dateRange.startDate!.setHours(0, 0, 0, 0)),
      endDate: new Date(dateRange.endDate!.setHours(23, 59, 59)),
    });
  };

  useEffect(() => {
    setFilteredSoldierUniqueIds((prev) => {
      let newIds = selectedForces.map((force) => force.id);
      if (
        !prev ||
        JSON.stringify(prev?.sort()) !== JSON.stringify(newIds.sort())
      )
        return newIds;
      return prev;
    });
  }, [selectedForces]);

  const { dataTypesByTrainingTypeId } =
    useDataHistoryTabsHeaders(trainingTypeId);
  useEffect(() => {
    //get the editable data type according to the data types of the current training types
    let editableDataTypesName = dataTypesByTrainingTypeId.map(
      (dataType: IDataType) => dataType.name
    );

    setColumns((prev) => {
      return headers.map((header, index) => {
        return {
          width: index === 0 ? 80 : undefined,
          key: header.id,
          header: t(header.name),
          filterType:
            index === 0
              ? undefined
              : header.id === trainingPlanHeader
              ? EEditorType.dropdown
              : EEditorType.text,
          editable: editableDataTypesName.includes(String(header.id)),
          options:
            header.id === trainingPlanHeader
              ? plansOptions.map((plan) => ({
                  label: t(plan.name),
                  value: t(plan.name),
                }))
              : undefined,
        };
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainingTypeId, headers, isEditRowInProcess, weaponTypes]);

  const { weaponsTypesList } = useWeapons();

  useEffect(() => {
    setWeaponTypes(weaponsTypesList.map((weaponType) => weaponType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weaponsTypesList]);

  useEffect(() => {
    setInvalid(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteRows, isEditRowClicked]);

  const { loading, data, setData, setLoading, setCameToEnd } =
    useGetPerformanceData(
      trainingTypeId,
      rangeDate,
      filteredSoldierUniqueIds || [],
      selectedPlan?.id,
      deletedRows
    );

  const deleteRows = async () => {
    setIsLoading(true);
    if (selectedRows && selectedRows.size) {
      await Axios.delete(`${baseUrlPMBackend}dataHistory/deleteRow`, {
        params: {
          drillsIds: Array.from(selectedRows),
          isProduction: environment === EEnvironment.production,
        },
      })
        .then(() => {
          setDeletedRowIds(selectedRows);
          setIsLoading(false);
          setData((prev: IDrillSummarizedData[]) => {
            return prev
              .filter(
                (row: IDrillSummarizedData) => !selectedRows.has(row["drillId"])
              )
              .map((row: IDrillSummarizedData, index: number) => {
                return { ...row, rowIndex: index + 1 };
              });
          });
          customToast.success(t("rowDeletedSuccessfully"));
          setSelectedRowIds(new Set());
        })
        .catch((res) => {
          setIsLoading(false);

          res.response?.status === 401
            ? customToast.error(t("unauthorizedToDeleteRow"))
            : customToast.error(t("errorWhileDeleteRow"));
        });
    }
  };

  /*
   * check for the edited row if value is correct according to the edited datatype
   */
  const checkRowValidation = (row: any) => {
    let isValid = true;
    Object.keys(row).forEach((rowKey) => {
      let dataType = dataTypesByTrainingTypeId.find(
        (dataType) => dataType.name === rowKey
      );
      if (dataType !== undefined) {
        if (
          //number
          (EDatatypesTypes.number.includes(dataType.type) &&
            isNaN(Number(row[rowKey]))) ||
          //boolean
          (EDatatypesTypes.boolean.includes(dataType.type) &&
            row[rowKey] !== "true" &&
            row[rowKey] !== "false" &&
            row[rowKey] !== t("true") &&
            row[rowKey] !== t("false")) ||
          (EDatatypesTypes.fraction.includes(dataType.type) &&
            isNaN(Number(row[rowKey].split("/")[0])) &&
            isNaN(Number(row[rowKey].split("/")[1])))
        )
          isValid = false;
        return;
      }
    });
    return isValid;
  };
  useEffect(() => {
    setEditRowInProcess(false);
  }, [selectedRows]);
  const editRow = async () => {
    if (selectedRows && selectedRows.size === 1) {
      //search the edited row to save
      let rowToSave = {
        ...data.find((row) => selectedRows.has(row.drillId)),
      };
      let isValid = rowToSave && checkRowValidation(rowToSave);
      if (isValid && rowToSave !== undefined) {
        //for each value of the row check if its a data type and was edited
        Object.keys(rowToSave).forEach((key) => {
          let isDataType =
            dataTypesByTrainingTypeId.find(
              (dataType) => dataType.name === key
            ) !== undefined;
          if (!isDataType || rowToSave![key] === tempEditedRow[key]) {
            delete rowToSave![key];
          } else {
            rowToSave![key] === t("true") && (rowToSave![key] = "true");
            rowToSave![key] === t("false") && (rowToSave![key] = "false");
          }
        });

        setLoading(true);
        await Axios.post(`${baseUrlPMBackend}dataHistory/editRow`, {
          drillData: rowToSave,
          drillId: tempEditedRow.drillId,
        })
          .then(() => {
            customToast.success(t("rowEditedSuccessfully"));
            setLoading(false);
            setEditRowInProcess(false);
            setSelectedRowIds(new Set());
          })
          .catch((res) => {
            customToast.error(t("errorWhileEditRow"));
            setLoading(false);
          });
      } else {
        customToast.error(t("errorInOnOfTheFields"));
      }
    }
  };

  /**
   * restore the edited row to the old row if edit was cancel
   */
  const cancelEditingRow = () => {
    setEditRowInProcess(false);
    setData((prev) => {
      return prev.map((row) => {
        if (selectedRows.has(row.drillId)) {
          return tempEditedRow;
        }
        return row;
      });
    });
  };

  /**
   * set the selected row as editable and store the row in the temp object
   */
  const enableRowToEdit = () => {
    if (selectedRows && selectedRows.size === 1) {
      let rowToEdit = data.find((row) => selectedRows.has(row.drillId));
      if (rowToEdit) {
        setTempEditedRow(rowToEdit);
      }
    }
  };
  const onSendPasswordHandler = async (password: string) => {
    const isPasswordValid = await checkSuperUserPassword(password, setInvalid);
    if (isPasswordValid) {
      //is password enter for deleting rows
      if (isDeleteRows) {
        deleteRows();
        setDeleteRows(false);
      }
      //if password enter for editing row
      if (isEditRowClicked) {
        enableRowToEdit();
        setEditRowClicked(false);
        setEditRowInProcess(true); //start the edit process
      }
    }
  };

  const handleSelectionChange = (
    selectedDrill: IDrillSummarizedData
    // isChecked: boolean
  ) => {
    if (!isEditRowInProcess) {
      setSelectedRowIds((prev) => {
        let newSet = new Set(prev);
        if (!newSet.has(selectedDrill.id)) newSet.add(selectedDrill.id);
        else newSet.delete(selectedDrill.id);
        return newSet;
      });
    }
  };
  const handleEdit = (updatedRow: IDrillSummarizedData) => {
    setData((prevData) =>
      prevData.map((row) => (row.id === updatedRow.id ? updatedRow : row))
    );
  };

  return (
    <IonPage key={props.trainingTypeId}>
      <div className="DHContent">
        <DataTransferTool
          isOpen={isTrainingTool}
          onDismiss={() => setIsTrainingTool(false)}
        ></DataTransferTool>
        <IonRow className="headerDataHistory">
          <PMLabel fontColor="light" fontFamily="Regular" fontSize="xxLarge">
            {t(props.trainingTypeName)}
          </PMLabel>
        </IonRow>
        <IonRow className="instructionsDataHistory">
          <PMLabel fontColor="light" fontFamily="Regular" fontSize="large">
            {t("selectDateOrPlan")}
          </PMLabel>
        </IonRow>
        <IonRow className="filtersRow">
          <div className="rowDH">
            <IonRadioGroup
              value={props.inputType}
              onIonChange={(e: any) => {
                if (e.detail.value === "date" || e.detail.value === "plan")
                  props.setInputType(e.detail.value);
              }}
              className="radioDHGroup"
            >
              <div
                className="radioDHItem"
                onClick={() => {
                  props.setInputType("date");
                }}
              >
                <div className="datePickerDH">
                  <DatePicker
                    mode="range"
                    rangeDate={rangeDate}
                    setRangeDate={setRangeDateAsAllDay}
                    disabled={props.inputType !== "date"}
                  ></DatePicker>
                </div>
                <IonRadio
                  className="radioDataHistory"
                  slot="start"
                  value={"date"}
                />
              </div>
              <div
                className="radioDHItem"
                onClick={() => {
                  props.setInputType("plan");
                }}
              >
                <div className="dropdownWrapDH">
                  <PlansDropdown
                    onPlanSelectedHandler={onPlanSelectedHandler}
                    plansOptions={plansOptions}
                    selectedPlan={selectedPlan}
                    isDisabled={props.inputType !== "plan"}
                  ></PlansDropdown>
                </div>

                <IonRadio
                  className="radioDataHistory"
                  slot="start"
                  value={"plan"}
                />
              </div>
            </IonRadioGroup>
            <OrbatInput
              wrapperCss="forcesPickerDH"
              inputValue={`${t("selectForces")}`}
              placeholder={`${t("selectForces")}`}
              inputWidth="long"
              isForDropDown
              collapseCssClass="treeCollapse scrollM"
              selectedForces={selectedForces}
              setSelectedForces={setSelectedForces}
              limit={MAX_SELECTED}
            />
          </div>

          <div className="trainingPlanTool">
            <PMButton
              label={{
                fontColor: "xLight",
                fontSize: "medium",
                fontFamily: "Light",
              }}
              size="fit"
              color="orange"
              fill="outline"
              cssClass="dhbuttons"
              isHidden={!isAdmin || environment === EEnvironment.production}
              onClickHandler={() => setIsTrainingTool(true)}
            >
              {t("trainingPlanTool")}
            </PMButton>
            {environment !== EEnvironment.production &&
            Number(trainingTypeId) ? (
              <PMButton
                label={{
                  fontColor: "xLight",
                  fontSize: "medium",
                  fontFamily: "Light",
                }}
                cssClass="dhbuttons"
                color="orange"
                fill="outline"
                isDisabled={
                  !selectedRows.size ||
                  selectedRows.size > 1 ||
                  (!isAdmin && environment === EEnvironment.production)
                }
                onClickHandler={() => {
                  setEditRowClicked(true);
                }}
              >
                {t("editRow")}
              </PMButton>
            ) : null}{" "}
            {environment !== EEnvironment.production ? (
              <PMButton
                label={{
                  fontColor: "xLight",
                  fontSize: "medium",
                  fontFamily: "Light",
                }}
                cssClass="dhbuttons"
                color="orange"
                fill="outline"
                isDisabled={
                  !selectedRows.size ||
                  (!isAdmin && environment === EEnvironment.production)
                }
                onClickHandler={() => {
                  setDeleteRows((prev) => !prev);
                }}
              >
                {t("deleteDataHistoryRow")}
              </PMButton>
            ) : null}
          </div>
        </IonRow>
        <IonRow>
          <div className="selectedSoldiers">
            {selectedForces.map((soldier) => (
              <span className="soldierBadge" key={soldier.id + soldier.name}>
                {soldier.name.includes(soldier.force_type)
                  ? soldier.name
                  : getForceFullName(soldier.name, soldier.force_type, t)}
                <IonIcon
                  mode="ios"
                  className="soldierRemoveIcon"
                  size="small"
                  icon={close}
                  onClick={() => {
                    setSelectedForces((prev) => {
                      return prev.filter((force) => force.id !== soldier.id);
                    });
                  }}
                />
              </span>
            ))}
          </div>
        </IonRow>
        <IonRow className="gridRow ">
          <DataGrid
            columns={columns}
            data={data}
            expandable={!trainingTypeId}
            selectable={true}
            onSelectionChange={handleSelectionChange}
            editable={false}
            editableRowIds={isEditRowInProcess ? selectedRows : undefined}
            onEdit={handleEdit}
            isWithFilter
            detailsKeysToHide={keysToHideInDetails}
            selectedRowIds={selectedRows}
            setLoadMore={(isInBottom: boolean) => {
              setCameToEnd((prev) => (prev !== isInBottom ? isInBottom : prev));
            }}
            minWidth={150}
          />
        </IonRow>
        <IonRow className="saveCancelButtonsDH">
          <SaveCancelButtons
            onSaveClickHandler={editRow}
            onCancelClickHandler={cancelEditingRow}
            disabled={!isEditRowInProcess}
          ></SaveCancelButtons>
        </IonRow>
        <div className="spinnerContainer">
          {loading || isLoading ? (
            <IonRow>
              <Spinner className="spinner-container" />
            </IonRow>
          ) : null}
        </div>
        <AdminPasswordModal
          isOpen={isDeleteRows || isEditRowClicked}
          onSendPasswordHandler={(password: string) =>
            onSendPasswordHandler(password)
          }
          onCancelHandler={() => {
            isDeleteRows && setDeleteRows(false);
            isEditRowClicked && setEditRowClicked(false);
          }}
          invalid={invalid}
          text={`${t("enterSuperUserPassword")} ${
            isDeleteRows ? t("deleteSuperUser") : t("superUserToEdit")
          }`}
        ></AdminPasswordModal>
      </div>
    </IonPage>
  );
};

export default DataHistory;
