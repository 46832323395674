// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchGrid {
  height: 100%;
  width: 100%;
  padding: 0;
}

.searchCol {
  height: 100%;
  --padding: 0;
}

.col2Medical {
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/DataPosting/MedicalDesktop.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB","sourcesContent":[".searchGrid {\n  height: 100%;\n  width: 100%;\n  padding: 0;\n}\n\n.searchCol {\n  height: 100%;\n  --padding: 0;\n}\n\n.col2Medical {\n  padding: 0;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  padding-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
