import { Dispatch, FC, SetStateAction } from "react";
import "./SingleGunner.css";
import { useTranslation } from "react-i18next";
import { IShootingExecution } from "../../../../Interfaces/Gorilla/IShootingExecution";
import PMLabel from "../../../themeComponents/PMLabel";
import AccuracyElement from "../AccuracyElement";
import Divider from "../Divider";
import GeneralGrade from "../GeneralGrade";
import TimeAccuracyElement from "../TimeAccuracyElement";
import TroubleshootingSelectionRow from "../TroubleshootingSelectionRow";
import { ISystemEncryption } from "../../../../Interfaces/Gorilla/ISystemEncryption";

type IProps = {
  malfunctionsOperation: IMalfunctionOperationOptions;
  setMalfunctionOperation: (
    value: SetStateAction<IMalfunctionOperationOptions>
  ) => void;
  setShootingExecution: Dispatch<SetStateAction<IShootingExecution>>;
  shootingExecution: IShootingExecution;
  setSystemEncryption: Dispatch<SetStateAction<ISystemEncryption>>;
  systemEncryption: ISystemEncryption;
};

const ShootingExecution: FC<IProps> = (props: IProps): JSX.Element => {
  const {
    malfunctionsOperation,
    setMalfunctionOperation,
    shootingExecution,
    setShootingExecution,
    setSystemEncryption,
    systemEncryption,
  } = props;
  const { t } = useTranslation();

  return (
    <div className="shootingExecutionWrap">
      <AccuracyElement
        onChange={(isChecked) => {
          setShootingExecution((prev) => ({
            ...prev,
            WarheadFit: isChecked,
          }));
        }}
        elementName={`WarheadFit`}
        accuracy={shootingExecution.WarheadFit}
      ></AccuracyElement>
      <div className="titleRowGorilla">
        <PMLabel fontColor="light" fontFamily="Regular" fontSize="medium">
          {t("OperatingTime")}
        </PMLabel>
      </div>
      <TimeAccuracyElement
        time={shootingExecution.OperatingTime.time}
        accuracy={shootingExecution.OperatingTime.accuracy}
        targetSeconds={shootingExecution.OperatingTime.goalSeconds}
        onTimeChanged={(seconds) => {
          setShootingExecution((prev) => ({
            ...prev,
            OperatingTime: { ...prev.OperatingTime, time: seconds },
          }));
        }}
        isWithoutAccuracy
      ></TimeAccuracyElement>
      <TroubleshootingSelectionRow
        onChecked={(isChecked: boolean, faultName: string) => {
          const typedKey = faultName as keyof IMalfunctionOperationOptions;

          setMalfunctionOperation((prev) => ({
            ...prev,
            [faultName]: { ...prev[typedKey], isSelected: isChecked },
          }));
        }}
        malfunctionsOperation={malfunctionsOperation}
      ></TroubleshootingSelectionRow>
      {Object.keys(malfunctionsOperation)
        .filter((key) => {
          const typedKey = key as keyof IMalfunctionOperationOptions;
          return malfunctionsOperation[typedKey].isSelected;
        })
        .map((key, index) => {
          const typedKey = key as keyof IMalfunctionOperationOptions;

          return (
            <TimeAccuracyElement
              key={index}
              elementName={key}
              time={malfunctionsOperation[typedKey].time}
              accuracy={malfunctionsOperation[typedKey].accuracy}
              targetSeconds={malfunctionsOperation[typedKey].goalSeconds}
              onTimeChanged={(seconds) => {
                setMalfunctionOperation((prev) => ({
                  ...prev,
                  [typedKey]: {
                    ...prev[typedKey],
                    time: seconds,
                    isExecuted:
                      (prev[typedKey].accuracy !== undefined &&
                        prev[typedKey].accuracy !== null) ||
                      seconds !== undefined,
                  },
                }));
              }}
              onAccuracyChanged={(accuracy) => {
                setMalfunctionOperation((prev) => ({
                  ...prev,
                  [typedKey]: {
                    ...prev[typedKey],
                    isExecuted:
                      accuracy !== undefined ||
                      prev[typedKey].time !== undefined,

                    accuracy:
                      prev[typedKey].accuracy === null ? null : accuracy,
                  },
                }));
              }}
              isWithoutAccuracy={
                malfunctionsOperation[typedKey].accuracy === null
              }
            ></TimeAccuracyElement>
          );
        })}
      <Divider />
      <AccuracyElement
        elementName={"Hit"}
        onChange={(isChecked) => {
          setShootingExecution((prev) => ({
            ...prev,
            Hit: isChecked,
          }));
        }}
        accuracy={shootingExecution.Hit}
      ></AccuracyElement>
      <AccuracyElement
        elementName={`ExamineDamageEffect`}
        onChange={(isChecked) => {
          setShootingExecution((prev) => ({
            ...prev,
            ExamineDamageEffect: isChecked,
          }));
        }}
        accuracy={shootingExecution.ExamineDamageEffect}
      ></AccuracyElement>
      <div className="titleRowGorilla">
        <PMLabel fontColor="light" fontFamily="Regular" fontSize="medium">
          {t("systemEncryption")}
        </PMLabel>
      </div>
      <GeneralGrade
        elementName="OperatingLauncherHeatGrade"
        grade={systemEncryption.OperatingLauncherHeatGrade}
        setGrade={(grade) => {
          setSystemEncryption((prev) => ({
            ...prev,
            OperatingLauncherHeatGrade: grade,
          }));
        }}
      ></GeneralGrade>
    </div>
  );
};

export default ShootingExecution;
