// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headerContainerSqL {
  --background: var(--ion-color-dark);
  --padding-bottom: 10px;
  --padding-top: 10px;
  --color: var(--ion-color-fontLight);
  font-size: large;
  border-color: var(--ion-color-dark);
  padding: 2px;
}
.stationTitle {
  font-family: Light !important;
  text-align: center;
  width: 100%;
}
.listContainerSqL {
  border-radius: 7px;
  background-color: var(--ion-color-light);
}

.backgroundList {
  background-color: var(--ion-color-ligthGray);
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/SiteManagement/SquaresList.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,sBAAsB;EACtB,mBAAmB;EACnB,mCAAmC;EACnC,gBAAgB;EAChB,mCAAmC;EACnC,YAAY;AACd;AACA;EACE,6BAA6B;EAC7B,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE,4CAA4C;AAC9C","sourcesContent":[".headerContainerSqL {\n  --background: var(--ion-color-dark);\n  --padding-bottom: 10px;\n  --padding-top: 10px;\n  --color: var(--ion-color-fontLight);\n  font-size: large;\n  border-color: var(--ion-color-dark);\n  padding: 2px;\n}\n.stationTitle {\n  font-family: Light !important;\n  text-align: center;\n  width: 100%;\n}\n.listContainerSqL {\n  border-radius: 7px;\n  background-color: var(--ion-color-light);\n}\n\n.backgroundList {\n  background-color: var(--ion-color-ligthGray);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
