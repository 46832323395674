// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.singleGunnerPanel {
  height: 100%;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.panelContentWrap {
  display: flex;
  flex-direction: column;
  height: calc(100% - 70px);
  overflow-y: scroll;
}
.panelContentWrap::-webkit-scrollbar-track {
  background: transparent;
}

.singleGunnerPanel .pmAccordionItem {
  --background: var(--ion-color-dark) !important;
  --inner-padding-end: 10px !important;
  --inner-padding-start: 0px !important;
  --padding-end: 0px !important;
  --padding-start: 0px !important;
}
.singleGunnerPanel .accordionMainWrap {
  width: 100% !important;
  box-shadow: none;
}
.singleGunnerPanel .accordionMainWrap {
  --inner-padding-end: 0 !important;
}

.singleGunnerPanel .accordion-expanded .pmAccordionItem,
.singleGunnerPanel .accordion-expanding .pmAccordionItem {
  border-color: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/DataPosting/HASIFA_TKIFA/SingleGunner/SingleGunnerPanel.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,kBAAkB;AACpB;AACA;EACE,uBAAuB;AACzB;;AAEA;EACE,8CAA8C;EAC9C,oCAAoC;EACpC,qCAAqC;EACrC,6BAA6B;EAC7B,+BAA+B;AACjC;AACA;EACE,sBAAsB;EACtB,gBAAgB;AAClB;AACA;EACE,iCAAiC;AACnC;;AAEA;;EAEE,yBAAyB;AAC3B","sourcesContent":[".singleGunnerPanel {\n  height: 100%;\n  width: 100%;\n  padding: 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n.panelContentWrap {\n  display: flex;\n  flex-direction: column;\n  height: calc(100% - 70px);\n  overflow-y: scroll;\n}\n.panelContentWrap::-webkit-scrollbar-track {\n  background: transparent;\n}\n\n.singleGunnerPanel .pmAccordionItem {\n  --background: var(--ion-color-dark) !important;\n  --inner-padding-end: 10px !important;\n  --inner-padding-start: 0px !important;\n  --padding-end: 0px !important;\n  --padding-start: 0px !important;\n}\n.singleGunnerPanel .accordionMainWrap {\n  width: 100% !important;\n  box-shadow: none;\n}\n.singleGunnerPanel .accordionMainWrap {\n  --inner-padding-end: 0 !important;\n}\n\n.singleGunnerPanel .accordion-expanded .pmAccordionItem,\n.singleGunnerPanel .accordion-expanding .pmAccordionItem {\n  border-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
