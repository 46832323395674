import React, { Dispatch, FC, SetStateAction } from "react";
import { IonList } from "@ionic/react";
import IShootingRangeForce from "../../../../../../Interfaces/IShootingRangeForce";
import ListTopics from "./ListTopics";
import TraineeListItem from "./TraineeListItem";
import "./TraineesList.css";
import IShootingHeat from "../../../../../../Interfaces/IShootingHeat";
import { MAX_TRAINEE_DISPLAYED } from "../../../../../../Configurations/shootingRangeConsts";
import { checkValidation } from "../../../../../../services/shootingRangeFunctions";

type Props = {
  trainees: IShootingRangeForce[];
  modifiedTrainees: IShootingRangeForce[];
  heat: IShootingHeat | undefined;
  setTrainees: Dispatch<SetStateAction<IShootingRangeForce[] | undefined>>;
  setModifiedTrainees: Dispatch<SetStateAction<IShootingRangeForce[]>>;
};

const TraineesList: FC<Props> = (props: Props): JSX.Element => {
  const { trainees, modifiedTrainees, heat, setTrainees, setModifiedTrainees } =
    props;
  const traineesClassName: string =
    trainees.length > MAX_TRAINEE_DISPLAYED
      ? "regular-trainees-list-wrapper"
      : "small-trainees-list-wrapper";

  const setResult = (value: string, item: IShootingRangeForce): void => {
    let isValidValue: boolean = checkValidation(heat, Number(value));

    setTrainees((prev: IShootingRangeForce[] | undefined) =>
      prev?.map((trainee: IShootingRangeForce) =>
        trainee.id === item.id
          ? {
              ...trainee,
              result: value,
              isValidResult: isValidValue,
            }
          : trainee
      )
    );
    setModifiedTrainees(changeModifiedArray(item, value, modifiedTrainees));
  };

  const changeModifiedArray = (
    currentItem: IShootingRangeForce,
    currentResult: string,
    modifiedArray: IShootingRangeForce[]
  ): IShootingRangeForce[] => {
    const { id } = currentItem;
    let newArray: IShootingRangeForce[] = [...modifiedArray];
    if (currentResult === "") {
      newArray = removeItemById(id, modifiedArray);
    } else {
      if (!isAlreadyChanged(id, modifiedArray)) {
        newArray.push({ ...currentItem, result: currentResult });
      } else if (isResultChanged(id, currentResult, modifiedArray)) {
        newArray = modifiedArray.map((item: IShootingRangeForce) =>
          item.id === id ? { ...item, result: currentResult } : item
        );
      }
    }
    return newArray;
  };

  const removeItemById = (
    id: number,
    arr: IShootingRangeForce[]
  ): IShootingRangeForce[] =>
    arr.filter((item: IShootingRangeForce) => item.id !== id);

  const isAlreadyChanged = (id: number, list: IShootingRangeForce[]): boolean =>
    !!list.find((item: IShootingRangeForce) => item.id === id);

  const isResultChanged = (
    id: number,
    currentResult: string,
    list: IShootingRangeForce[]
  ): boolean =>
    !(
      list.find((item: IShootingRangeForce) => item.id === id)!.result ===
      currentResult
    );

  return (
    <div className={traineesClassName}>
      <ListTopics heat={heat} />
      <IonList className="mobile-trainees-list">
        {trainees.map((trainee: IShootingRangeForce, index: number) => (
          <TraineeListItem
            key={trainee.id}
            item={trainee}
            index={index}
            setResult={setResult}
          />
        ))}
      </IonList>
    </div>
  );
};

export default TraineesList;
