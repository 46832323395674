import React, { useEffect, useRef, useState } from "react";
import { Calendar, DateRange } from "react-date-range";
import { convertDateToString } from "../../../services/helpers";
import { calendarOutline } from "ionicons/icons";
import Input from "../TrainingPlan/TrainingPlanInput";
import "./DatePicker.css";
import { useTranslation } from "react-i18next";
import { IDatePicker } from "../../../Interfaces/IDatePicker";

const DatePicker: React.FC<IDatePicker> = (props) => {
  const { mode } = props;
  const { t } = useTranslation();

  const [isShowDatePicker, setIsShowPicker] = useState<boolean>(false);
  const dateRangeRef = useRef<HTMLDivElement>(null);

  const dateChangeHandler = (range: any) => {
    props.setRangeDate &&
      props.setRangeDate({
        startDate: range.selection.startDate,
        endDate: range.selection.endDate,
      });
  };
  const handleViewClick = (event: MouseEvent) => {
    if (!dateRangeRef.current?.contains(event.target as HTMLElement)) {
      setIsShowPicker(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleViewClick);

    return () => {
      document.removeEventListener("mousedown", handleViewClick);
    };
  }, []);

  return mode === "range" ? (
    <React.Fragment>
      <div className="dateInputDP" onClick={() => setIsShowPicker(true)}>
        <div className="dateInputWrapper">
          <Input
            placeholder={t("date")}
            inputName="date"
            inputType="text"
            inputValue={
              mode === "range" && props.rangeDate
                ? convertDateToString(props.rangeDate!.startDate!) +
                  "-" +
                  convertDateToString(props.rangeDate!.endDate!) +
                  ""
                : ""
            }
            length="short"
            iconInInput
            icon={calendarOutline}
            cssClass="tpInput"
            isDisabled={props.disabled}
          />
        </div>
      </div>
      {isShowDatePicker && !props.disabled ? (
        <div className="dateRangePickerDP">
          <div ref={dateRangeRef} className="dateWrap">
            <DateRange
              ranges={[
                {
                  startDate: props.rangeDate
                    ? new Date(
                        Date.parse(props.rangeDate.startDate!.toString())
                      )
                    : new Date(),
                  endDate: props.rangeDate
                    ? new Date(Date.parse(props.rangeDate.endDate!.toString()))
                    : new Date(),
                  key: "selection",
                },
              ]}
              weekStartsOn={0}
              editableDateInputs={true}
              moveRangeOnFirstSelection={false}
              showMonthAndYearPickers={false}
              showDateDisplay={false}
              onChange={dateChangeHandler}
            ></DateRange>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="dateInputDP" onClick={() => setIsShowPicker(true)}>
        <span className="dateInputWrapper">
          <Input
            placeholder={t("date")}
            inputName="date"
            inputType="text"
            inputValue={convertDateToString(
              props.date ? props.date : new Date()
            )}
            length={props.length || "short"}
            iconInInput
            icon={calendarOutline}
          />
        </span>
      </div>
      {isShowDatePicker && props.setDate ? (
        <div className="dateRangePickerDP">
          <div ref={dateRangeRef} className="dateWrap">
            <Calendar
              date={props.date ? props.date : new Date()}
              onChange={props.setDate}
            />
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default DatePicker;
