import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import readExcelFile from "read-excel-file";
import { useTranslation } from "react-i18next";
import "./ImportTranslationsFile.css";
import PMIcon from "../../themeComponents/PMIcon";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import PMLabel from "../../themeComponents/PMLabel";
import ExcelJS from "exceljs";
import { BorderStyle, Column } from "exceljs";
import { TFunction } from "i18next";
import { saveAs } from "file-saver";
import { download } from "ionicons/icons";
import ELanguage from "../../../Enums/ELanguage";

interface IProps {}

const ImportTranslationsFile: React.FC<IProps> = (props: IProps) => {
  const { t, i18n } = useTranslation();
  const [file, setFile] = useState<(string | null)[][]>();

  useEffect(() => {
    if (file) {
      if (validateFileStructure(file)) {
        let hebrewTranslations: { [key: string]: string } = {};
        let englishTranslations: { [key: string]: string } = {};
        let germanTranslations: { [key: string]: string } = {};
        let UkrainianTranslations: { [key: string]: string } = {};
        file.forEach((row, index) => {
          if (row[0] && row[1]) englishTranslations[row[0]] = row[1];
          if (row[0] && row[2]) UkrainianTranslations[row[0]] = row[2];
          if (row[0] && row[3]) hebrewTranslations[row[0]] = row[3];
          if (row[0] && row[4]) germanTranslations[row[0]] = row[4];
        });
        console.log(JSON.stringify({ translation: germanTranslations }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const validateFileStructure = (rows: (string | null)[][]): boolean => {
    return (
      rows.every((row) => row.length === 5) &&
      rows[1][0] === "Phrase" &&
      rows[1][1] === "English" &&
      rows[1][2] === "Ukrainian" &&
      rows[1][3] === "Hebrew" &&
      rows[1][4] === "German"
    );
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    readExcelFile(acceptedFiles[0]).then((rows: (string | null)[][]) => {
      setFile(rows);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "application/vnd.ms-excel": [".xlsx"] },
  });

  return (
    <div className="exportImportWrap">
      <div className="exportImportTranslationContainer" {...getRootProps()}>
        <input {...getInputProps()} />
        <PMLabel
          textAlign="center"
          fontColor="light"
          fontFamily="Light"
          fontSize="medium"
        >
          {t("import")}
        </PMLabel>
        <PMIcon iconSrc={EIconsSrc.XLS_FILE_IMPORT} size="large" />
      </div>
      <div
        className="exportImportTranslationContainer"
        onClick={() => exportI18nextTranslations(i18n, t)}
      >
        <PMLabel
          textAlign="center"
          fontColor="light"
          fontFamily="Light"
          fontSize="medium"
        >
          {t("export")}
        </PMLabel>
        <PMIcon size="large" color="light" iconSrc={download}></PMIcon>
      </div>
    </div>
  );
};

export default ImportTranslationsFile;
const exportI18nextTranslations = async (i18n: any, t: TFunction) => {
  let englishTranslations = i18n.getResource(ELanguage.en, "translation", "");
  let hebrewTranslations = i18n.getResource(ELanguage.he, "translation", "");
  let germanTranslations = i18n.getResource(ELanguage.de, "translation", "");
  let UKrainianTranslations = i18n.getResource(ELanguage.ua, "translation", "");

  let keysSet = new Set([
    ...Object.keys(englishTranslations),
    ...Object.keys(hebrewTranslations),
    ...Object.keys(germanTranslations),
    ...Object.keys(UKrainianTranslations),
  ]);
  const phrasesArray = Array.from(keysSet);

  const borderStyle: BorderStyle = "medium";

  const fontSize = 14;
  let rows: any[] = [];
  const workbook = new ExcelJS.Workbook();
  let worksheet = workbook.addWorksheet("sheet", {
    pageSetup: {
      orientation: "portrait",
      fitToWidth: 1,
      fitToHeight: 0,

      margins: {
        bottom: 0,
        footer: 0,
        left: 0.3,
        top: 0,
        right: 0.3,
        header: 0,
      },
    },

    views: [{ rightToLeft: false }],
  });
  const headers = ["Phrase", "English", "Hebrew", "German", "UKrainian"];

  rows.push(headers);
  rows.push([]);

  let borderRows: number[] = [];
  // Iterate through your data and populate the worksheet
  phrasesArray
    .sort((a, b) => a.localeCompare(b))
    ?.forEach((phrase) => {
      rows.push([
        phrase,
        englishTranslations[phrase],
        hebrewTranslations[phrase],
        germanTranslations[phrase],
        UKrainianTranslations[phrase],
      ]);
    });
  let columnsObj = [];
  for (let index = 0; index < headers.length; index++) {
    columnsObj.push({
      header: "",
      key: String(index),
      style: { font: { size: fontSize, name: "Ariel" } },
    });
  }
  worksheet.columns = columnsObj;

  worksheet.columns.forEach((column: Partial<Column>, index: number) => {
    column.width = 60;
    column.alignment = { horizontal: index === 2 ? "right" : "left" };
  });

  rows.forEach((e: string[], index: number) => {
    const rowIndex = index + 1;
    worksheet.addRow({
      ...e,
      amountRemaining: {
        formula: `=C${rowIndex}-D${rowIndex}`,
      },
      percentRemaining: {
        formula: `=E${rowIndex}/C${rowIndex}`,
      },
    });
  });
  borderRows.forEach((rowNumber) => {
    worksheet.getRow(rowNumber).border = {
      bottom: { style: borderStyle },
    };
  });

  worksheet.getRow(2).font = { bold: true, size: fontSize };
  worksheet.getRow(2).alignment = { horizontal: "center" };

  // Save the workbook
  const filename = "translations";
  const buffer = await workbook.xlsx.writeBuffer();
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const fileExtension = ".xlsx";
  const blob = new Blob([buffer], { type: fileType });
  saveAs(blob, filename + fileExtension); // Save the file
};
