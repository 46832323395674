// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trainees-list-item {
  width: 100%;
  --background: var(--ion-color-header);
  --padding-start: 5px;
  --padding-end: 5px;
  --inner-padding-end: 5px;
  --color: var(--ion-color-orange);
  display: flex;
  justify-content: space-between;
  --border-color: var(--ion-color-text-secondary);
}

.result-input {
  display: flex; /* on cick --ion-color-selected */
  background-color: var(--ion-color-enable);
  width: 68px;
  max-width: 68px;
  height: 56px;
  margin-block: 1vh;
  text-align: center;
  --padding-start: 0;
  --placeholder-color: var(--ion-color-text-secondary);
  margin-inline-start: 38vw;
  border-radius: 6px;
}

.soldier-name {
  margin-inline: 2.5vw;
}

.soldier-index {
  margin-inline-start: 1.5vw;
}

.soldier {
  display: flex;
  flex: 1 1;
  max-width: 40%;
  flex-direction: row;
}

.input-error {
  border: 2px solid var(--ion-color-danger-input);
}
`, "",{"version":3,"sources":["webpack://./src/components/Mobile/ManualStation/ShootingRange/ResultsScreen/ShootingRangeTraineesList/TraineeListItem.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,qCAAqC;EACrC,oBAAoB;EACpB,kBAAkB;EAClB,wBAAwB;EACxB,gCAAgC;EAChC,aAAa;EACb,8BAA8B;EAC9B,+CAA+C;AACjD;;AAEA;EACE,aAAa,EAAE,iCAAiC;EAChD,yCAAyC;EACzC,WAAW;EACX,eAAe;EACf,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,oDAAoD;EACpD,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,SAAO;EACP,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,+CAA+C;AACjD","sourcesContent":[".trainees-list-item {\n  width: 100%;\n  --background: var(--ion-color-header);\n  --padding-start: 5px;\n  --padding-end: 5px;\n  --inner-padding-end: 5px;\n  --color: var(--ion-color-orange);\n  display: flex;\n  justify-content: space-between;\n  --border-color: var(--ion-color-text-secondary);\n}\n\n.result-input {\n  display: flex; /* on cick --ion-color-selected */\n  background-color: var(--ion-color-enable);\n  width: 68px;\n  max-width: 68px;\n  height: 56px;\n  margin-block: 1vh;\n  text-align: center;\n  --padding-start: 0;\n  --placeholder-color: var(--ion-color-text-secondary);\n  margin-inline-start: 38vw;\n  border-radius: 6px;\n}\n\n.soldier-name {\n  margin-inline: 2.5vw;\n}\n\n.soldier-index {\n  margin-inline-start: 1.5vw;\n}\n\n.soldier {\n  display: flex;\n  flex: 1;\n  max-width: 40%;\n  flex-direction: row;\n}\n\n.input-error {\n  border: 2px solid var(--ion-color-danger-input);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
