import { IonTitle } from "@ionic/react";
import React, { FC } from "react";
import "./PMTitle.css";
import { IPMLabel } from "../../Interfaces/IPMTheme";
const PMTitle: FC<IPMLabel> = (props: IPMLabel) => {
  const { fontSize, children, fontColor, fontFamily, cssClass, textAlign } =
    props;
  return (
    <>
      <IonTitle
        class="item-text-wrap"
        className={`pmTitle ${fontSize}T ${fontColor} ${fontFamily} ${cssClass} align${textAlign}`}
      >
        {children}
      </IonTitle>
    </>
  );
};
export default PMTitle;
