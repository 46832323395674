import { AxiosResponse } from "axios";
import axios from "../Axios";
import { APIs } from "../Configurations/urls";
import ILastSync from "../Interfaces/ILastSync";

const getLastSync = async (): Promise<AxiosResponse<ILastSync[]>> => {
  try {
    return await axios.get(APIs.syncService.getLastUpdateTime());
  } catch (error) {
    throw error;
  }
};

const syncData = async (): Promise<any> => {
  try {
    return await axios.get(APIs.syncService.syncData());
  } catch (error) {
    throw error;
  }
};
const comparePlans = () => {
  return axios.get(APIs.syncService.comparePlans());
};
const compareGrades = () => {
  return axios.get(APIs.syncService.compareGrades());
};

export { getLastSync, syncData, comparePlans, compareGrades };
