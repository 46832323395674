// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trainingTypeGradDiv {
  width: 100px;
}

.trainingTypeGradDiv .gradeText {
  font-size: 30px !important;
  margin-bottom: 7px;
}

.trainingTypeName {
  padding-top: 13px;
}
.trainingTypWrap {
  flex: 1 1;
  min-width: 350px;
  max-width: 450px;
  min-height: 280px;
  padding-top: 16px;

  margin-inline-start: 8px;
  margin-inline-end: 8px;
}
.singleGunnerTrainingTypWrap {
  height: 100%;
  width: 100%;
  background-color: var(--ion-color-dark);
  padding-inline-start: 8px;
  padding-inline-end: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.trainingTypeGradWrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 100px;
  margin-bottom: 8px;
}
.gorillaTitleFont {
  font-size: 20px;
}
.moreDetailsButtonRow {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Desktop/Gorilla/TrainingTypeResults.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,0BAA0B;EAC1B,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;AACA;EACE,SAAO;EACP,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;;EAEjB,wBAAwB;EACxB,sBAAsB;AACxB;AACA;EACE,YAAY;EACZ,WAAW;EACX,uCAAuC;EACvC,yBAAyB;EACzB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;;AAEA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,uBAAuB;EACvB,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,eAAe;AACjB;AACA;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;AAC3B","sourcesContent":[".trainingTypeGradDiv {\n  width: 100px;\n}\n\n.trainingTypeGradDiv .gradeText {\n  font-size: 30px !important;\n  margin-bottom: 7px;\n}\n\n.trainingTypeName {\n  padding-top: 13px;\n}\n.trainingTypWrap {\n  flex: 1;\n  min-width: 350px;\n  max-width: 450px;\n  min-height: 280px;\n  padding-top: 16px;\n\n  margin-inline-start: 8px;\n  margin-inline-end: 8px;\n}\n.singleGunnerTrainingTypWrap {\n  height: 100%;\n  width: 100%;\n  background-color: var(--ion-color-dark);\n  padding-inline-start: 8px;\n  padding-inline-end: 8px;\n  padding-bottom: 8px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n\n.trainingTypeGradWrap {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  height: 100px;\n  margin-bottom: 8px;\n}\n.gorillaTitleFont {\n  font-size: 20px;\n}\n.moreDetailsButtonRow {\n  width: 100%;\n  display: flex;\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
