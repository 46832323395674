// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.col2CombatFitness {
  padding: 0;

  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/DataPosting/CombatFitnessDesktop.css"],"names":[],"mappings":"AAAA;EACE,UAAU;;EAEV,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB","sourcesContent":[".col2CombatFitness {\n  padding: 0;\n\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  padding-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
