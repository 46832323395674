import { IonCol, IonRow } from "@ionic/react";
import React from "react";
import { IElement } from "../../../../Interfaces/ITrainingPlan";
import { ITrainee } from "../../../../Interfaces/results/ITrainee.interface";
import { IModalResult } from "../../../../Interfaces/results/modal-result.interface";
import PMLabel from "../../../themeComponents/PMLabel";
import "./Trainee.css";

interface ITraineeProps {
  trainee: ITrainee;
  elements: IElement[];
  trainingTypeId?: number;
  ranking: number;
  sortedElement: string;
}

const Trainee = (props: ITraineeProps) => {
  return (
    <IonRow className="traineeRow">
      <IonCol
        size="0.1"
        className={`badgeCol ${
          props.trainee.results.find(
            (result: IModalResult) => result.elementName === props.sortedElement
          )?.color
        }Badge`}
      ></IonCol>
      <IonCol size="0.4" className="rankCol">
        <PMLabel cssClass="rankLabel">{props.ranking}</PMLabel>
      </IonCol>
      <IonCol size="2">
        <PMLabel cssClass="nameLabel">{props.trainee.forceName}</PMLabel>
      </IonCol>
      <IonCol size="1.3" className="classCol">
        {props.trainee.forceHierarchy}
      </IonCol>
      <IonCol size="8.2">
        <IonRow>
          {props.elements !== undefined
            ? Object.values(props.elements)
                .map((element: IElement) =>
                  props.trainee.results.find(
                    (result: IModalResult) =>
                      result.elementName === element.name
                  )
                )
                .map((result: IModalResult, index: number) => (
                  <IonCol className="forceCol" key={index}>
                    <PMLabel
                      cssClass={`valueCol TM${
                        props?.sortedElement === result?.elementName
                          ? result?.color
                          : ""
                      }`}
                    >
                      {result && result.value != null ? result.value : "-"}
                    </PMLabel>
                  </IonCol>
                ))
            : null}{" "}
        </IonRow>
      </IonCol>
    </IonRow>
  );
};

export default Trainee;
