import { useContext, useEffect, useState } from "react";
import { EAppMode } from "../../Enums/EAppMode";
import TabItem from "../../Interfaces/ITabItem";
import { routes } from "../../services/routeRoles";
import { StationsContext } from "../../context/StationsContext/StationsContext";

export enum EDataPostingTrainingTypes {
  responsiveShootingIEST = "IEST_Responsive_Shooting",
  responsiveShootingBIST = "BIST_Responsive_Shooting",
  simulativeShootingIEST = "IEST_Simulative_Shooting",
  simulativeShootingBIST = "BIST_Simulative_Shooting",
  BISTUrban = "BIST_Urban",
  medical = "Tourniquet_Placement",
  combatFitness = "Combat_Fitness",
  urbanInstructorFeedback = "Urban_Instructor_Feedback",
  magnetInstructorFeedback = "Magnet_Instructor_Feedback",
  shootingRange = "Shooting_Range",
  magnetFOF = "Magnet_FOF",
  billiard = "Billiard",
}

export enum dataPostingRoutes {
  responsiveShootingIEST = `${routes.dataPosting}/${EDataPostingTrainingTypes.responsiveShootingIEST}`,
  responsiveShootingBIST = `${routes.dataPosting}/${EDataPostingTrainingTypes.responsiveShootingBIST}`,
  simulativeShootingIEST = `${routes.dataPosting}/${EDataPostingTrainingTypes.simulativeShootingIEST}`,
  simulativeShootingBIST = `${routes.dataPosting}/${EDataPostingTrainingTypes.simulativeShootingBIST}`,
  BISTUrban = `${routes.dataPosting}/${EDataPostingTrainingTypes.BISTUrban}`,
  medical = `${routes.dataPosting}/${EDataPostingTrainingTypes.medical}`,
  combatFitness = `${routes.dataPosting}/${EDataPostingTrainingTypes.combatFitness}`,
  urbanInstructorFeedback = `${routes.dataPosting}/${EDataPostingTrainingTypes.urbanInstructorFeedback}`,
  magnetInstructorFeedback = `${routes.dataPosting}/${EDataPostingTrainingTypes.magnetInstructorFeedback}`,
  shootingRange = `${routes.dataPosting}/${EDataPostingTrainingTypes.shootingRange}`,
  magnetFOF = `${routes.dataPosting}/${EDataPostingTrainingTypes.magnetFOF}`,
  spike = `${routes.dataPosting}/${EAppMode.SPIKE}`,
  fireSupport = `${routes.dataPosting}/${EAppMode.FIRE_SUPPORT}`,
  hasifaTkifa = `${routes.dataPosting}/${EAppMode.HASIFA_TKIFA}`,
  billiard = `${routes.dataPosting}/${EDataPostingTrainingTypes.billiard}`,
}

const tabs: TabItem[] = [
  {
    url: dataPostingRoutes.responsiveShootingIEST,
    title: EDataPostingTrainingTypes.responsiveShootingIEST,
    stationId: 1,
  },
  {
    url: dataPostingRoutes.responsiveShootingBIST,
    title: EDataPostingTrainingTypes.responsiveShootingBIST,
    stationId: 1,
  },
  {
    url: dataPostingRoutes.simulativeShootingIEST,
    title: EDataPostingTrainingTypes.simulativeShootingIEST,
    stationId: 1,
  },
  {
    url: dataPostingRoutes.simulativeShootingBIST,
    title: EDataPostingTrainingTypes.simulativeShootingBIST,
    stationId: 1,
  },
  {
    url: dataPostingRoutes.BISTUrban,
    title: EDataPostingTrainingTypes.BISTUrban,
    stationId: 4,
  },
  {
    url: dataPostingRoutes.medical,
    title: EDataPostingTrainingTypes.medical,
    stationId: 9,
  },
  {
    url: dataPostingRoutes.combatFitness,
    title: EDataPostingTrainingTypes.combatFitness,
    stationId: 8,
  },
  {
    url: dataPostingRoutes.urbanInstructorFeedback,
    title: EDataPostingTrainingTypes.urbanInstructorFeedback,
    stationId: 13,
  },
  {
    url: dataPostingRoutes.magnetInstructorFeedback,
    title: EDataPostingTrainingTypes.magnetInstructorFeedback,
    stationId: 15,
  },

  {
    url: dataPostingRoutes.shootingRange,
    title: EDataPostingTrainingTypes.shootingRange,
    stationId: 12,
  },
  {
    url: dataPostingRoutes.magnetFOF,
    title: EDataPostingTrainingTypes.magnetFOF,
    stationId: 3,
  },
  {
    url: dataPostingRoutes.fireSupport,
    title: EAppMode.FIRE_SUPPORT,
    stationId: 19,
  },
  {
    url: dataPostingRoutes.spike,
    title: EAppMode.SPIKE,
    stationId: 14,
  },
  {
    url: dataPostingRoutes.hasifaTkifa,
    title: EAppMode.HASIFA_TKIFA,
    stationId: 16,
  },
  {
    url: dataPostingRoutes.billiard,
    title: EAppMode.BILLIARD,
    stationId: 20,
  },
];

const UseDataPostingTabs = () => {
  const [tabOptions, setTabOptions] = useState<TabItem[]>([]);
  const { stations } = useContext(StationsContext);
  useEffect(() => {
    setTabOptions(
      tabs.filter((tab) => stations.find((s) => s.id === tab.stationId))
    );
  }, [stations]);

  return { tabOptions };
};

export default UseDataPostingTabs;
