import React from "react";
import { useIonPopover } from "@ionic/react";
import { useTranslation } from "react-i18next";
import PMLabel from "../../themeComponents/PMLabel";
import "./About.css";
import packageJson from "../../../../package.json";

const About: React.FC = () => {
  const { t } = useTranslation();
  const [present, dismiss] = useIonPopover(AboutContent, {
    onHide: () => dismiss(),
  });

  return (
    <PMLabel
      onClick={(e) =>
        present({
          event: e.nativeEvent,
        })
      }
      textAlign="center"
      fontColor="light"
      fontFamily="Light"
      fontSize="medium"
    >
      {t("about")}
    </PMLabel>
  );
};

const AboutContent: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="aboutPopover">
      <PMLabel
        textAlign="start"
        fontColor="light"
        fontFamily="Light"
        fontSize="medium"
      >
        {t("credits")}
        <br></br>
        <br></br>
        {t("creditsDevelopers")}
        <br></br>
        <br></br>
        {t("version") + ": " + packageJson.version}
      </PMLabel>
    </div>
  );
};
export { About, AboutContent };
