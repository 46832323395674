// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manageUsersContainer {
  height: calc(100% - 48px);
  margin: 24px;
  border-radius: 4px;
  box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.16);
}

.manageUsers {
  height: 100%;
}

.usersGrid {
  height: 100%;
  width: 100%;
  background-color: var(--ion-color-header);
  padding: 0;
}

.manageUsersRow {
  height: 100%;
}

.usersRow {
  height: 100%;
  width: 100%;
}

.usersCol {
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/Settings/ManageUsers/ManageUsers.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,+CAA+C;AACjD;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,yCAAyC;EACzC,UAAU;AACZ;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,YAAY;AACd","sourcesContent":[".manageUsersContainer {\n  height: calc(100% - 48px);\n  margin: 24px;\n  border-radius: 4px;\n  box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.16);\n}\n\n.manageUsers {\n  height: 100%;\n}\n\n.usersGrid {\n  height: 100%;\n  width: 100%;\n  background-color: var(--ion-color-header);\n  padding: 0;\n}\n\n.manageUsersRow {\n  height: 100%;\n}\n\n.usersRow {\n  height: 100%;\n  width: 100%;\n}\n\n.usersCol {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
