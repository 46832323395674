// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.elementName {
  font-size: 17px !important;
  font-family: "light" !important;
  color: var(--ion-color-fontWhite) !important;
  /* margin-inline-start: 7px; */
}

.thresholdsInputsDiv {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.elementRow {
  display: flex;
  justify-content: space-between;
}

.elementWrapper {
  padding: 0;
}

.thresholds {
  padding-inline-end: 0;
  max-width: 14vw;
  display: flex;
  justify-content: space-between;
}
.testt {
  display: flex;
  flex-direction: row;
}

.checkboxRow {
  align-items: center;
}

.elementCol {
  border-bottom: 0.05px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 3px !important;
  padding-top: 3px;
}

.elementCol:last-child {
  border-bottom: none;
}

.elementBulletIcon {
  font-size: 8px !important;
}

.elementBulletCol {
  display: flex;
  align-items: center;
  justify-content: center;
}
.isForDashboardCheckbox .selectorCheckbox {
  margin: 0;
}
.elementBorder {
  border-width: 0px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  width: 85%;
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/TrainingPlan/Element.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,+BAA+B;EAC/B,4CAA4C;EAC5C,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,WAAW;AACb;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,oDAAoD;EACpD,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,SAAS;AACX;AACA;EACE,iBAAiB;EACjB,8CAA8C;EAC9C,UAAU;EACV,mBAAmB;AACrB","sourcesContent":[".elementName {\n  font-size: 17px !important;\n  font-family: \"light\" !important;\n  color: var(--ion-color-fontWhite) !important;\n  /* margin-inline-start: 7px; */\n}\n\n.thresholdsInputsDiv {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n}\n\n.elementRow {\n  display: flex;\n  justify-content: space-between;\n}\n\n.elementWrapper {\n  padding: 0;\n}\n\n.thresholds {\n  padding-inline-end: 0;\n  max-width: 14vw;\n  display: flex;\n  justify-content: space-between;\n}\n.testt {\n  display: flex;\n  flex-direction: row;\n}\n\n.checkboxRow {\n  align-items: center;\n}\n\n.elementCol {\n  border-bottom: 0.05px solid rgba(255, 255, 255, 0.2);\n  padding-bottom: 3px !important;\n  padding-top: 3px;\n}\n\n.elementCol:last-child {\n  border-bottom: none;\n}\n\n.elementBulletIcon {\n  font-size: 8px !important;\n}\n\n.elementBulletCol {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.isForDashboardCheckbox .selectorCheckbox {\n  margin: 0;\n}\n.elementBorder {\n  border-width: 0px;\n  border-top: 1px solid rgba(255, 255, 255, 0.2);\n  width: 85%;\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
