// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.site-row {
  display: flex;
  justify-content: center;
  height: 54px;
  align-items: center;
  text-align: center;
  background-color: var(--ion-color-light);
  border-radius: 4px;
  margin-bottom: 5px;
  margin-inline-start: 20px;
  margin-inline-end: 20px;
}

.sites-list {
  max-height: 50%;
  margin-block-start: 2vh;
  overflow-y: auto;
}

.tracking-label {
  padding-inline-start: 6vw;
}

.entries {
  padding-inline-start: 11vw;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/TrackingLogins/TrackingLoginsList/TrackingLoginsList.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,wCAAwC;EACxC,kBAAkB;EAClB,kBAAkB;EAClB,yBAAyB;EACzB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".site-row {\n  display: flex;\n  justify-content: center;\n  height: 54px;\n  align-items: center;\n  text-align: center;\n  background-color: var(--ion-color-light);\n  border-radius: 4px;\n  margin-bottom: 5px;\n  margin-inline-start: 20px;\n  margin-inline-end: 20px;\n}\n\n.sites-list {\n  max-height: 50%;\n  margin-block-start: 2vh;\n  overflow-y: auto;\n}\n\n.tracking-label {\n  padding-inline-start: 6vw;\n}\n\n.entries {\n  padding-inline-start: 11vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
