// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HTContainerWrap {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: var(--ion-color-extra-dark);
  padding: 24px;
}

.HTContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: var(--ion-color-dark);
  box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.16);
  padding: 24px;
}
.HTContainerCol {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.HTTitleRow {
  margin-top: 20px;
}
.HTContainerWrap .inputDate {
  width: 100% !important;
  max-width: 300px;
}
.HTbuttonWrap {
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  align-items: flex-end;
  justify-self: flex-end;
}
.HTColumn {
  max-width: 315px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/DataPosting/HASIFA_TKIFA/Observer/ObserverDesktopComponent.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,6CAA6C;EAC7C,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uCAAuC;EACvC,+CAA+C;EAC/C,aAAa;AACf;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;AACA;EACE,gBAAgB;AAClB;AACA;EACE,sBAAsB;EACtB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,oBAAoB;EACpB,yBAAyB;EACzB,qBAAqB;EACrB,sBAAsB;AACxB;AACA;EACE,2BAA2B;AAC7B","sourcesContent":[".HTContainerWrap {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  background-color: var(--ion-color-extra-dark);\n  padding: 24px;\n}\n\n.HTContainer {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  background-color: var(--ion-color-dark);\n  box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.16);\n  padding: 24px;\n}\n.HTContainerCol {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n.HTTitleRow {\n  margin-top: 20px;\n}\n.HTContainerWrap .inputDate {\n  width: 100% !important;\n  max-width: 300px;\n}\n.HTbuttonWrap {\n  display: flex;\n  align-self: flex-end;\n  justify-content: flex-end;\n  align-items: flex-end;\n  justify-self: flex-end;\n}\n.HTColumn {\n  max-width: 315px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
