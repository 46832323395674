import React from "react";

import "./NCODesktopComponent.css";
import { IonCol, IonRow } from "@ionic/react";
import { INCO } from "../../../../../Interfaces/Gorilla/INCO";
import GeneralGrade from "../../../../Shared/SPIKEGorilla/GeneralGrade";
import HasifaTkifaTrainingTemplate from "../HasifaTkifaTrainingTemplate";
interface IProps {
  nco: INCO;
  setNco: React.Dispatch<React.SetStateAction<INCO>>;
  isTrainingEnd: boolean;
  isEndTrainingDisabled: boolean;
  nextTraining: () => void;
}
const NCODesktopComponent: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { isTrainingEnd, nco, setNco, isEndTrainingDisabled, nextTraining } =
    props;
  const Component = () => (
    <IonCol className="NCOGradeWrapCol scrollS">
      {Object.keys(nco).map((key, index) => {
        let typedKey = key as keyof INCO;
        return (
          <IonRow key={index}>
            <GeneralGrade
              isDesktop
              elementName={key}
              grade={nco[typedKey]}
              setGrade={(grade) => {
                setNco((prev) => ({
                  ...prev,
                  [typedKey]: grade,
                }));
              }}
            ></GeneralGrade>
          </IonRow>
        );
      })}
    </IonCol>
  );
  return (
    <HasifaTkifaTrainingTemplate
      headerTitle="systemEncryption"
      component={Component}
      endButtonText="next"
      isDisabledEndButton={isTrainingEnd}
      isComponentCompleted={isTrainingEnd}
      endButtonHandler={nextTraining}
      endButtonIcon
    ></HasifaTkifaTrainingTemplate>
  );
};

export default NCODesktopComponent;
