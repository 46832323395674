// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/DataGrid/EditableCell.css */

.editable-cell {
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  border: 1px solid #434449;
  height: var(--data-grid-input-height);
  box-shadow: none;
  padding-inline-start: 4px;
  display: flex;
  align-items: center;
  border-radius: 2px;
}

.no-border {
  border: none;
}
.cell-warning {
  color: red;
  font-size: 12px;
  margin-top: 2px;
}

.ECInput {
  width: 100%;
  background-color: transparent;
  color: var(--ion-color-fontWhite);
  height: 100%;
  border: none;
  box-shadow: none;
  padding: 0px;
  font-size: medium;
  font-family: Regular !important;
}
.ECInput:focus {
  outline: none;
  border: none;
}

.WarningCell {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-top: 3px;
  padding-bottom: 3px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/PMDataGrid/EditableCell.css"],"names":[],"mappings":"AAAA,6CAA6C;;AAE7C;EACE,eAAe;EACf,yBAAiB;UAAjB,iBAAiB;EACjB,yBAAyB;EACzB,qCAAqC;EACrC,gBAAgB;EAChB,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;AACA;EACE,UAAU;EACV,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,6BAA6B;EAC7B,iCAAiC;EACjC,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;EACjB,+BAA+B;AACjC;AACA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":["/* src/components/DataGrid/EditableCell.css */\n\n.editable-cell {\n  cursor: pointer;\n  user-select: none;\n  border: 1px solid #434449;\n  height: var(--data-grid-input-height);\n  box-shadow: none;\n  padding-inline-start: 4px;\n  display: flex;\n  align-items: center;\n  border-radius: 2px;\n}\n\n.no-border {\n  border: none;\n}\n.cell-warning {\n  color: red;\n  font-size: 12px;\n  margin-top: 2px;\n}\n\n.ECInput {\n  width: 100%;\n  background-color: transparent;\n  color: var(--ion-color-fontWhite);\n  height: 100%;\n  border: none;\n  box-shadow: none;\n  padding: 0px;\n  font-size: medium;\n  font-family: Regular !important;\n}\n.ECInput:focus {\n  outline: none;\n  border: none;\n}\n\n.WarningCell {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  justify-content: center;\n  padding-top: 3px;\n  padding-bottom: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
