import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import Axios from "../../../Axios";
import { baseUrlPMBackend } from "../../../Configurations/consts";
import { WeaponsContext } from "../../../context/WeaponsContext/WeaponsContext";
import IWeapon from "../../../Interfaces/IWeapon";
import customToast from "../../Shared/Toast/CustomToast";

/**
 *
 * @returns array with the weapons names
 */
const asyncFetchWeapons = async () => {
  const res = await Axios.get(`${baseUrlPMBackend}forces/getWeapons`);
  return res.data;
};

export const useWeapons = () => {
  const { t } = useTranslation();
  const { weapons, setWeapons } = useContext(WeaponsContext);
  const [weaponsTypesList, setWeaponsTypesList] = useState<string[]>(
    weapons.map((weapon) => weapon.name)
  );

  let { data, isLoading, isError } = useQuery<IWeapon[]>({
    queryKey: ["weapons"],
    queryFn: asyncFetchWeapons,
    staleTime: Infinity,
    enabled: !weapons.length, //load weapons from backend in the weapons array in the context is empty
  });
  useEffect(() => {
    if (!isLoading && !isError && data && data.length) {
      //the weapons returned correctly set the weapons in the context
      setWeapons(data);
      setWeaponsTypesList(data.map((weapon) => weapon.name));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  useEffect(() => {
    if (isError) {
      customToast.error(t("errorWhileLoadingWeapons"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);
  return { weapons, weaponsTypesList };
};
