import React, { Dispatch, SetStateAction } from "react";

import "./ObserverDesktopComponent.css";
import {
  EObserverSteps,
  IConductingQuality,
  IOpenAndCover,
  ISettingStation,
  ITargetAcquisition,
  IWorkRoutine,
} from "../../../../../Interfaces/Gorilla/IObserver";
import { ITabOptions } from "../../../../../Interfaces/IDataHistory";
import SettingStation from "../../../../Shared/SPIKEGorilla/Observer/SettingStation";
import TargetAcquisition from "../../../../Shared/SPIKEGorilla/Observer/TargetAcquisition";
import ConductingQuality from "../../../../Shared/SPIKEGorilla/Observer/ConductingQuality";
import WorkRoutine from "../../../../Shared/SPIKEGorilla/Observer/WorkRoutine";
import OpenAndCover from "../../../../Shared/SPIKEGorilla/Observer/OpenAndCover";
import HasifaTkifaTrainingTemplate from "../HasifaTkifaTrainingTemplate";
import MainNotesTabDesktop from "../../../../Shared/SPIKEGorilla/Observer/MainNotesTabDesktop";
import { INote } from "../../../../../Interfaces/Gorilla/GorillaTrainings";
interface ISegment extends ITabOptions {
  component: () => JSX.Element;
}
interface IProps {
  setCurrentStepType: Dispatch<SetStateAction<EObserverSteps>>;
  currentStepType: EObserverSteps;
  openAndCover: IOpenAndCover;
  setWorkRoutine: React.Dispatch<React.SetStateAction<IWorkRoutine>>;
  workRoutine: IWorkRoutine;
  settingStation: ISettingStation;
  conductingQuality: IConductingQuality;
  setTargetAcquisition: React.Dispatch<
    React.SetStateAction<ITargetAcquisition>
  >;
  setOpenAndCover: React.Dispatch<React.SetStateAction<IOpenAndCover>>;
  setSettingStation: React.Dispatch<React.SetStateAction<ISettingStation>>;
  setConductingQuality: React.Dispatch<
    React.SetStateAction<IConductingQuality>
  >;
  addNote: (note: string) => void;
  deleteNote: (index: number) => void;
  editNote: (index: number, note: string) => void;
  generalCommentsMO: INote[];
  isTrainingEnd: boolean;
  targetAcquisition: ITargetAcquisition;
  isEndTrainingDisabled?: boolean;
  nextTraining: () => void;
}
const ObserverDesktopComponent: React.FC<IProps> = (
  props: IProps
): JSX.Element => {
  const {
    currentStepType,
    workRoutine,
    setCurrentStepType,
    conductingQuality,
    generalCommentsMO,
    isTrainingEnd,
    openAndCover,
    setConductingQuality,
    setOpenAndCover,
    setSettingStation,
    setTargetAcquisition,
    setWorkRoutine,
    settingStation,
    targetAcquisition,
    addNote,
    deleteNote,
    editNote,
    isEndTrainingDisabled,
    nextTraining,
  } = props;

  const steps: ISegment[] = [
    {
      tabId: 1,
      title: "SettingStation",
      url: "observer/SettingStation",
      component: () => (
        <SettingStation
          settingStation={settingStation}
          setSettingStation={setSettingStation}
          isDesktop
        ></SettingStation>
      ),
    },
    {
      tabId: 2,
      title: "targetAcquisition",
      url: "observer/TargetAcquisition",
      component: () => (
        <TargetAcquisition
          setTargetAcquisition={setTargetAcquisition}
          targetAcquisition={targetAcquisition}
          isDesktop
        ></TargetAcquisition>
      ),
    },
    {
      tabId: 3,
      title: "ConductingQuality",
      url: "observer/ConductingQuality",
      component: () => (
        <ConductingQuality
          conductingQuality={conductingQuality}
          setConductingQuality={setConductingQuality}
          isDesktop
        ></ConductingQuality>
      ),
    },
    {
      tabId: 4,
      title: "workRoutine",
      url: "observer/workRoutine",
      component: () => (
        <WorkRoutine
          setWorkRoutine={setWorkRoutine}
          workRoutine={workRoutine}
          isDesktop
        ></WorkRoutine>
      ),
    },
    {
      tabId: 5,
      title: "openAndCover",
      url: "observer/openAndCover",
      component: () => (
        <OpenAndCover
          openAndCover={openAndCover}
          setOpenAndCover={setOpenAndCover}
          isDesktop
        ></OpenAndCover>
      ),
    },
    {
      tabId: 6,
      title: "mainNotes",
      url: "observer/mainNotes",
      component: () => (
        <MainNotesTabDesktop
          notes={generalCommentsMO}
          deleteNote={deleteNote}
          editNote={editNote}
          addNote={addNote}
        ></MainNotesTabDesktop>
      ),
    },
  ];
  return (
    <HasifaTkifaTrainingTemplate
      component={steps[currentStepType]?.component}
      startButtonHandler={() => {
        setCurrentStepType((prev) => prev - 1);
      }}
      endButtonHandler={
        currentStepType === steps.length - 1
          ? nextTraining
          : () => {
              setCurrentStepType((prev) => prev + 1);
            }
      }
      endButtonText={"next"}
      endButtonIcon={true}
      startButtonText="prev"
      startButtonIcon
      isDisabledStartButton={currentStepType <= 0}
      isDisabledEndButton={
        (isTrainingEnd || isEndTrainingDisabled) &&
        currentStepType >= steps.length - 1
      }
      headerTitle={steps[currentStepType]?.title}
      isComponentCompleted={isTrainingEnd}
    ></HasifaTkifaTrainingTemplate>
  );
};

export default ObserverDesktopComponent;
