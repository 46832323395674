// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heatsModal {
  --height: 40vh;
  --width: 15vw;
}

.heatsGrid {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.headerRow {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.heatsRow {
  overflow-y: scroll;
  height: 85%;
}

.heatsRow::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.heatRow {
  width: 100%;
  cursor: pointer;
}

.heatItem:hover {
  --background: #4f5155;
}

.heatItem {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/DataPosting/HeatsModal/HeatsModal.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".heatsModal {\n  --height: 40vh;\n  --width: 15vw;\n}\n\n.heatsGrid {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n}\n\n.headerRow {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  padding-bottom: 20px;\n}\n\n.heatsRow {\n  overflow-y: scroll;\n  height: 85%;\n}\n\n.heatsRow::-webkit-scrollbar {\n  width: 5px;\n  height: 5px;\n}\n\n.heatRow {\n  width: 100%;\n  cursor: pointer;\n}\n\n.heatItem:hover {\n  --background: #4f5155;\n}\n\n.heatItem {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
