// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.observerCol {
  display: flex;
  flex-direction: column;
  height: min-content;
  width: 100%;
  --ion-grid-column-padding: 0;
}
.lineDivider {
  min-height: 16px;
}
.secondRowObserver {
  /* padding-inline-end: 120px; */
}
`, "",{"version":3,"sources":["webpack://./src/pages/Desktop/Gorilla/ObserverDesktopResults.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;EACX,4BAA4B;AAC9B;AACA;EACE,gBAAgB;AAClB;AACA;EACE,+BAA+B;AACjC","sourcesContent":[".observerCol {\n  display: flex;\n  flex-direction: column;\n  height: min-content;\n  width: 100%;\n  --ion-grid-column-padding: 0;\n}\n.lineDivider {\n  min-height: 16px;\n}\n.secondRowObserver {\n  /* padding-inline-end: 120px; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
