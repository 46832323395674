// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LauncherSelectorRow {
  height: min-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.mobileLauncherSelector {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.launcherSelectorLabelCol {
  margin-inline-end: 25px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/SPIKEGorilla/LauncherSelector.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,uBAAuB;AACzB;AACA;EACE,mBAAmB;EACnB,2BAA2B;EAC3B,mBAAmB;AACrB;AACA;EACE,uBAAuB;AACzB","sourcesContent":[".LauncherSelectorRow {\n  height: min-content;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: center;\n}\n.mobileLauncherSelector {\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n}\n.launcherSelectorLabelCol {\n  margin-inline-end: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
