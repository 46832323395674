// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.openCardForDetails {
  margin-bottom: -20px;
  width: 52px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Desktop/PerformanceDisplay/PerformanceTabs/StationView/ElementCard.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,WAAW;AACb","sourcesContent":[".openCardForDetails {\n  margin-bottom: -20px;\n  width: 52px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
