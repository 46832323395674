// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.personalTrendsDiv {
  width: 90%;
  height: 540px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.personalTrendsDiv .swiper-wrapper {
  height: 100% !important;
}
.personalTrendsDiv .swiper {
  height: 95% !important;
  width: 100% !important;
  padding-bottom: 30px;
}
.trendSlide {
  height: 100%;
  display: flex;
  justify-content: center;
  width: 100%;
}
.mobilTrendsSlides {
  height: 100%;
  width: 100%;
}
.mobilTrendsSlides {
  direction: ltr;
}
.mobilTrendsSlides:lang(he) {
  direction: rtl;
}
.swiper-pagination-bullet-active {
  background: var(--ion-color-fontXLight) !important;
  padding: 5px;
}
.swiper-pagination-bullet {
  background: var(--ion-color-fontLight);
}
.mobileTrendsSlide {
  height: 100%;
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Mobile/PersonalZone/Competency/PersonalTrends/PersonalTrends.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;AACzB;AACA;EACE,sBAAsB;EACtB,sBAAsB;EACtB,oBAAoB;AACtB;AACA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,WAAW;AACb;AACA;EACE,YAAY;EACZ,WAAW;AACb;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,kDAAkD;EAClD,YAAY;AACd;AACA;EACE,sCAAsC;AACxC;AACA;EACE,YAAY;EACZ,WAAW;EACX,oBAAoB;EACpB,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".personalTrendsDiv {\n  width: 90%;\n  height: 540px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.personalTrendsDiv .swiper-wrapper {\n  height: 100% !important;\n}\n.personalTrendsDiv .swiper {\n  height: 95% !important;\n  width: 100% !important;\n  padding-bottom: 30px;\n}\n.trendSlide {\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  width: 100%;\n}\n.mobilTrendsSlides {\n  height: 100%;\n  width: 100%;\n}\n.mobilTrendsSlides {\n  direction: ltr;\n}\n.mobilTrendsSlides:lang(he) {\n  direction: rtl;\n}\n.swiper-pagination-bullet-active {\n  background: var(--ion-color-fontXLight) !important;\n  padding: 5px;\n}\n.swiper-pagination-bullet {\n  background: var(--ion-color-fontLight);\n}\n.mobileTrendsSlide {\n  height: 100%;\n  width: 100%;\n  padding-bottom: 20px;\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
