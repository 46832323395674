import React from "react";
import { close, checkmark } from "ionicons/icons";
import "./SwitchButton.css";
import PMIcon from "../../../themeComponents/PMIcon";

interface ISwitchButton {
  isOn: boolean | undefined;
  handleToggle: Function;
}
const SwitchButton: React.FC<ISwitchButton> = (props: ISwitchButton) => {
  return (
    <div className="switchButton">
      <span
        onClick={() =>
          props.handleToggle((prev: boolean | undefined) =>
            prev ? undefined : true
          )
        }
        className={
          "switchSpan true " + (props.isOn === true ? "selected " : "")
        }
      >
        <PMIcon color="light" size="large" iconSrc={checkmark}></PMIcon>
      </span>
      <span
        onClick={() =>
          props.handleToggle((prev: boolean | undefined) =>
            prev === undefined || prev ? false : undefined
          )
        }
        className={
          "switchSpan false  " + (props?.isOn === false ? "selected " : "")
        }
      >
        <PMIcon color="light" size="large" iconSrc={close}></PMIcon>
      </span>
    </div>
  );
};
export default SwitchButton;
