// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warningModal {
  --width: 85%;
  --max-width: 472px;
  --height: 216px;
  --background: var(--ion-color-light);
  --border-radius: 4px;
  --backdrop-opacity: 0.7 !important;
  align-items: center;
  padding-bottom: 180px;
  display: flex;
}

.warningModalContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
}
.textWarningRow {
  display: flex;
  flex: 1 1;
}
.acceptDeclineButtonsRow {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.warningModalContent .acceptDeclineButtonsRow {
  justify-content: center;
}
.warningTextRow {
  display: flex;
  justify-content: center;
  align-items: center;
}
.warningText {
  margin-bottom: 5px;
  margin-top: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/SPIKEGorilla/WarningModal.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,oCAAoC;EACpC,oBAAoB;EACpB,kCAAkC;EAClC,mBAAmB;EACnB,qBAAqB;EACrB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;AACf;AACA;EACE,aAAa;EACb,SAAO;AACT;AACA;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,qBAAqB;AACvB;AACA;EACE,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[".warningModal {\n  --width: 85%;\n  --max-width: 472px;\n  --height: 216px;\n  --background: var(--ion-color-light);\n  --border-radius: 4px;\n  --backdrop-opacity: 0.7 !important;\n  align-items: center;\n  padding-bottom: 180px;\n  display: flex;\n}\n\n.warningModalContent {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n  padding: 24px;\n}\n.textWarningRow {\n  display: flex;\n  flex: 1;\n}\n.acceptDeclineButtonsRow {\n  width: 100%;\n  display: flex;\n  justify-content: flex-end;\n  align-items: flex-end;\n}\n.warningModalContent .acceptDeclineButtonsRow {\n  justify-content: center;\n}\n.warningTextRow {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.warningText {\n  margin-bottom: 5px;\n  margin-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
