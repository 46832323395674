// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spikeElementRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-inline-start: var(--pm_spike_gorilla);
  padding-inline-end: var(--pm_spike_gorilla);
  width: 100%;
  margin-bottom: 10px;
}
.iconsRow {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  min-width: max-content;
}
.elementColS {
  width: max-content;
  --ion-grid-column-padding: 0;
}
.desktop .elementSpikeName {
  margin-bottom: 20px;
}
.nameColElement {
  max-width: 150px !important;
  min-width: 150px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/SPIKEGorilla/ElementRow.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,6CAA6C;EAC7C,2CAA2C;EAC3C,WAAW;EACX,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,sBAAsB;AACxB;AACA;EACE,kBAAkB;EAClB,4BAA4B;AAC9B;AACA;EACE,mBAAmB;AACrB;AACA;EACE,2BAA2B;EAC3B,gBAAgB;AAClB","sourcesContent":[".spikeElementRow {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  padding-inline-start: var(--pm_spike_gorilla);\n  padding-inline-end: var(--pm_spike_gorilla);\n  width: 100%;\n  margin-bottom: 10px;\n}\n.iconsRow {\n  width: 100%;\n  height: 100%;\n  overflow-x: hidden;\n  display: flex;\n  align-items: center;\n  min-width: max-content;\n}\n.elementColS {\n  width: max-content;\n  --ion-grid-column-padding: 0;\n}\n.desktop .elementSpikeName {\n  margin-bottom: 20px;\n}\n.nameColElement {\n  max-width: 150px !important;\n  min-width: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
