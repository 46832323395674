// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rowUIFC {
  width: calc(100% - 20px);
  height: 100%;
  overflow-y: scroll;
}

.rowUIFC::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

.titleRow {
  width: 100%;
  padding-inline-end: 49px;
  background-color: var(--ion-color-dark);
}

.avgGradeTitle {
  width: 100%;
  display: flex;
  justify-content: center !important;
  text-align: center !important;
}

.colL {
  display: flex;
  justify-content: center;
}

.UFIWrap {
  flex: 1 1;
  overflow-y: auto;
  background-color: var(--ion-color-dark);
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/MenualStation/InstructorFeedback/InstructorFeedbackComponent.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,WAAW;EACX,wBAAwB;EACxB,uCAAuC;AACzC;;AAEA;EACE,WAAW;EACX,aAAa;EACb,kCAAkC;EAClC,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,SAAO;EACP,gBAAgB;EAChB,uCAAuC;EACvC,uBAAuB;EACvB,uBAAuB;EACvB,aAAa;AACf","sourcesContent":[".rowUIFC {\n  width: calc(100% - 20px);\n  height: 100%;\n  overflow-y: scroll;\n}\n\n.rowUIFC::-webkit-scrollbar {\n  width: 9px;\n  height: 9px;\n}\n\n.titleRow {\n  width: 100%;\n  padding-inline-end: 49px;\n  background-color: var(--ion-color-dark);\n}\n\n.avgGradeTitle {\n  width: 100%;\n  display: flex;\n  justify-content: center !important;\n  text-align: center !important;\n}\n\n.colL {\n  display: flex;\n  justify-content: center;\n}\n\n.UFIWrap {\n  flex: 1;\n  overflow-y: auto;\n  background-color: var(--ion-color-dark);\n  justify-content: center;\n  align-items: flex-start;\n  padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
