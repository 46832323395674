// css
import { IonList } from "@ionic/react";
import "./ElementsResultWrap.css";
import { IElementResultRow } from "../../../Interfaces/Gorilla/IGorillaDesktopResults";
import ElementResultRow from "./ElementResultRow";
import Divider from "../../../components/Shared/SPIKEGorilla/Divider";

interface IProps {
  resultsArray: IElementResultRow[];
  isWithSuccessRate?: boolean;
  isWithWeight?: boolean;
}
const ElementsResultWrap: React.FC<IProps> = (props: IProps): JSX.Element => {
  return (
    <div className="elementsResultWrap">
      {" "}
      <ElementResultRow
        result={{
          grade: "justGrade",
          name: "gradeElement",
          successRate: props.isWithSuccessRate ? "successes" : undefined,
          weight: props.isWithWeight ? "weight" : undefined,
        }}
        isWithSuccessRate={props.isWithSuccessRate}
        isWithWeight={props.isWithWeight}
        isHeader
      ></ElementResultRow>
      <IonList className="elementsResultList">
        {props.resultsArray.map((result, index) => (
          <span key={index}>
            <ElementResultRow
              isWithSuccessRate={props.isWithSuccessRate}
              isWithWeight={props.isWithWeight}
              result={result}
              key={index}
            ></ElementResultRow>
            {index + 1 < props.resultsArray.length ? <Divider solid /> : null}
          </span>
        ))}
      </IonList>
    </div>
  );
};
export default ElementsResultWrap;
