import React from "react";
import { useTranslation } from "react-i18next";
import PMButton from "../../themeComponents/PMButton";
import "./SaveCancelButtons.css";
interface ISaveCancelButtons {
  onCancelClickHandler: () => void;
  onSaveClickHandler: () => void;
  disabled?: boolean;
  saveText?: string;
  isWarnings?: boolean;
  declineText?: string;
  isButtonCancelOutLine?: boolean;
}

const SaveCancelButtons = (props: ISaveCancelButtons) => {
  const {
    onCancelClickHandler,
    onSaveClickHandler,
    disabled,
    saveText,
    isWarnings,
    declineText,
    isButtonCancelOutLine,
  } = props;
  const { t } = useTranslation();
  return (
    <>
      <PMButton
        cssClass={`${true ? "SCButton" : ""} cancelButton ${
          isWarnings ? "warningsButton" : ""
        }`}
        fill={isButtonCancelOutLine ? "outline" : "clear"}
        color="orange"
        size="small"
        onClickHandler={onCancelClickHandler}
        label={{
          fontSize: "medium",
          fontFamily: "Light",
          fontColor: "disabled",
        }}
      >
        {declineText ? t(declineText) : t("cancel")}
      </PMButton>
      <PMButton
        cssClass={`${isButtonCancelOutLine ? "SCButton" : ""}  ${
          isWarnings ? "warningsButton" : ""
        } saveButton`}
        color={isWarnings ? "danger" : "orange"}
        size="small"
        onClickHandler={onSaveClickHandler}
        label={{
          fontSize: "medium",
          fontFamily: "Regular",
          fontColor: isWarnings ? "light" : "dark",
        }}
        isDisabled={disabled}
      >
        {saveText ? t(saveText) : t("save")}
      </PMButton>
    </>
  );
};

export default SaveCancelButtons;
