import { combineComponents } from "./CombineComponents";
import WeaponsProvider from "./WeaponsContext/WeaponsContext";
import UserProvider from "./UserContext/userContext";
import ForcesContextProvider from "./ForcesContext/forcesContextProvider";
import ForcesListContextProvider from "./ForcesContext/forcesListContextProvider";
import ShootingRangeContextProvider from "./ShootingRangeContext/ShootingRangeContext";
import ForceTypesContextProvider from "./ForcesTypes/ForcesTypesContext";
import StationsContextProvider from "./StationsContext/StationsContext";
import TrainingTypesProvider from "./TrainingTypesContext/TrainingTypesContext";
import DataTypesContextProvider from "./DataTypesContext/DataTypesContext";
import ElementsContextProvider from "./ElementsContext/ElementsContext";
import IndicatorsContextProvider from "./IndicatorsContext/IndicatorsContext";
import MarketingContextProvider from "./MarketingContext/MarketingContext";
import ForcesContextProviderDesktop from "./ForcesContext/forcesContextProviderDesktop";
import SyncContextProvider from "./SyncContext/SyncContext";
import GorillaTrainingProvider from "./GorillaTrainingContext/GorillaTrainingContext";
import AppModeContextProvider from "./AppModeContext/AppModeContext";
import BISTUrbanContextProvider from "./BISTUrbanContext/BISTUrbanContext";
import AppProvider from "./AppContext/AppContext";
//all the context provider need to be in the index.tsx page. we use combineComponents in order to avoid multiple components in the index page
const providers = [
  WeaponsProvider,
  UserProvider,
  ForcesContextProvider,
  ForcesListContextProvider,
  ShootingRangeContextProvider,
  ForceTypesContextProvider,
  StationsContextProvider,
  TrainingTypesProvider,
  DataTypesContextProvider,
  ElementsContextProvider,
  IndicatorsContextProvider,
  MarketingContextProvider,
  ForcesContextProviderDesktop,
  SyncContextProvider,
  GorillaTrainingProvider,
  AppModeContextProvider,
  BISTUrbanContextProvider,
  AppProvider,
];

export const AppContextProvider = combineComponents(...providers);
