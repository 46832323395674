// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.titleRowSBL {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  width: 100%;
}

.titleSBL {
  font-family: "Regular" !important;
  font-size: large;
  color: var(--ion-color-fontXLight);
}

.icon-in-header {
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  width: 100%;
}

.mobile-header-icon {
  margin-inline-end: 3vw;
}
`, "",{"version":3,"sources":["webpack://./src/components/Mobile/MobileHeader/MobileTitleRow.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,iCAAiC;EACjC,gBAAgB;EAChB,kCAAkC;AACpC;;AAEA;EACE,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;EACb,WAAW;AACb;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".titleRowSBL {\n  display: flex;\n  justify-content: flex-start;\n  margin-bottom: 20px;\n  width: 100%;\n}\n\n.titleSBL {\n  font-family: \"Regular\" !important;\n  font-size: large;\n  color: var(--ion-color-fontXLight);\n}\n\n.icon-in-header {\n  justify-content: space-between;\n  flex-direction: row;\n  display: flex;\n  width: 100%;\n}\n\n.mobile-header-icon {\n  margin-inline-end: 3vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
