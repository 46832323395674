import React, { FC, useEffect, useState } from "react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { getAllLoginsTracking } from "../../../apis/TrackingLoginsAPI";
import ITrackingLogins from "../../../Interfaces/ITrackingLogins";
import DragModal from "../../Desktop/DragModal/DragModal";
import PMLabel from "../../themeComponents/PMLabel";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import PMIcon from "../../themeComponents/PMIcon";
import TrackingLoginsList from "../../Desktop/TrackingLogins/TrackingLoginsList/TrackingLoginsList";
import DatePicker from "../../Desktop/DataPosting/DatePicker";
import { IDateRange } from "../../../Interfaces/IDatePicker";
import "./TrackingUsersLogins.css";
import { ILoginSiteItem } from "../../../Interfaces/ILoginSiteItem";
import PMButton from "../../themeComponents/PMButton";
import Spinner from "../Spinner/Spinner";
interface IProps {
  trackingUsersLoginsClicked: boolean;
  setTrackingUsersLoginsClicked: React.Dispatch<React.SetStateAction<boolean>>;
}

const TrackingUsersLogins: FC<IProps> = (props: IProps) => {
  const { trackingUsersLoginsClicked, setTrackingUsersLoginsClicked } = props;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loginsHistory, setLoginsHistory] = useState<ITrackingLogins[]>();
  const [loginsHistoryToDisplay, setLoginsHistoryToDisplay] =
    useState<ITrackingLogins[]>();
  const [rangeDate, setRangeDate] = useState<IDateRange | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (trackingUsersLoginsClicked) {
      getLoginsHistory();
      setTrackingUsersLoginsClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingUsersLoginsClicked]);

  useEffect(() => {
    setLoading(false);
    setLoginsHistoryToDisplay(loginsHistory);
  }, [loginsHistory]);

  useEffect(() => {
    if (loginsHistory)
      if (rangeDate) {
        setLoginsHistoryToDisplay(
          loginsHistory?.filter(
            (item: ITrackingLogins) =>
              new Date(item.date) >= new Date(rangeDate!.startDate!) &&
              new Date(item.date) <= new Date(rangeDate!.endDate!)
          )
        );
      } else setLoginsHistoryToDisplay(loginsHistory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangeDate, loginsHistory]);

  const getLoginsHistory = async () => {
    setLoading(true);
    await getAllLoginsTracking().then(
      (response: AxiosResponse<ITrackingLogins[] | undefined>) =>
        setLoginsHistory(response.data)
    );
  };

  const createSitesList = (array: ITrackingLogins[] | undefined) => {
    if (array) {
      return groupBySiteId(array);
    }
  };

  const groupBySiteId = (arr: ITrackingLogins[]): ILoginSiteItem[] => {
    const result = arr?.reduce(
      (accumulator: { [key: string]: ILoginSiteItem }, current) => {
        const siteId = current.siteId;
        const siteName = current.siteName;
        if (!accumulator[siteName]) {
          accumulator[siteName] = { siteName, count: 0 };
        }
        if (siteId) accumulator[siteName].count++;
        return accumulator;
      },
      {}
    );

    return Object.values(result);
  };

  const setRangeDateAsAllDay = (dateRange: IDateRange) => {
    setRangeDate({
      startDate: new Date(dateRange.startDate!.setHours(0, 0, 0, 0)),
      endDate: new Date(dateRange.endDate!.setHours(23, 59, 59)),
    });
  };

  return (
    <>
      <PMLabel
        textAlign="center"
        fontColor="light"
        fontFamily="Light"
        fontSize="medium"
        onClick={() => setIsOpen(true)}
      >
        {t("trackingUsersHistory")}
      </PMLabel>
      <DragModal
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
        cssClass="tracking-history-modal"
      >
        <>
          <div className="spinnerTrackingLogin">
            {loading ? <Spinner></Spinner> : null}
          </div>
          <IonGrid className="tracking-logins-history-modal-grid">
            <IonRow className="tracking-logins-header-row">
              <IonCol className="tracking-logins-header-column">
                <PMLabel
                  cssClass="tracking-logins-modal-title"
                  fontColor="xLight"
                  fontFamily="Light"
                  fontSize="medium"
                >
                  {t("trackingUsersHistory")}
                </PMLabel>
              </IonCol>
              <IonCol className="close-modal-icon-column">
                <PMIcon
                  iconSrc={EIconsSrc.CLOSE}
                  cssClass="close-modal-icon"
                  onClick={() => setIsOpen(false)}
                ></PMIcon>
              </IonCol>
            </IonRow>
            <IonRow className="tracking-logins-dates">
              <IonCol size="2.3">
                <PMLabel
                  cssClass="tracking-date-label"
                  fontColor="xLight"
                  fontFamily="Light"
                  fontSize="large"
                >
                  {t("selectDateRange")}
                </PMLabel>
              </IonCol>
              <IonCol>
                <DatePicker
                  mode="range"
                  rangeDate={rangeDate}
                  setRangeDate={setRangeDateAsAllDay}
                />
              </IonCol>
              <PMButton
                onClickHandler={() => setRangeDate(undefined)}
                label={{
                  fontColor: "light",
                  fontFamily: "Light",
                  fontSize: "medium",
                }}
                fill={"outline"}
                color="orange"
                size="small"
              >
                {t("clearFilter")}
              </PMButton>
              <PMButton
                onClickHandler={() => setTrackingUsersLoginsClicked(true)}
                label={{
                  fontColor: "light",
                  fontFamily: "Light",
                  fontSize: "medium",
                }}
                fill={"outline"}
                color="orange"
                size="small"
                cssClass="refreshTracking"
              >
                {t("refresh")}
              </PMButton>
            </IonRow>
            <IonRow className="tracking-logins-titles">
              <IonCol className="sites-list-column">
                <PMLabel
                  cssClass="tracking-header-label"
                  fontColor="xLight"
                  fontFamily="Light"
                >
                  {t("site")}
                </PMLabel>
              </IonCol>
              <IonCol className="sites-list-column">
                <PMLabel
                  cssClass="tracking-header-label"
                  fontColor="xLight"
                  fontFamily="Light"
                >
                  {t("entries")}
                </PMLabel>
              </IonCol>
            </IonRow>
            <TrackingLoginsList
              sitesList={createSitesList(loginsHistoryToDisplay)}
            />
          </IonGrid>
        </>
      </DragModal>
    </>
  );
};
export default TrackingUsersLogins;
