import React from "react";

import "./FireSupportTrainingType.css";
import { IonCol, IonList, IonRow, IonTextarea } from "@ionic/react";
import PMLabel from "../../../themeComponents/PMLabel";
import InstructorFeedbackGrade from "../InstructorFeedback/InstructorFeedbackGrade";
import { IFireSupportElementData } from "../../../CustomHooks/UseGetFireSupportDataPosting";

export interface IProps {
  title: string;
  elements: IFireSupportElementData[];
  setGrade: (elementId: number, garde: number | string | undefined) => void;
  resetAll: boolean;
  setResetAll: (state: boolean) => void;
  isTraineeSelected: boolean;
  setAssessment: (setAssessment: string) => void;
  assessment: string;
}
const FireSupportTrainingType: React.FC<IProps> = (
  props: IProps
): JSX.Element => {
  const {
    title,
    elements,
    setGrade,
    resetAll,
    setResetAll,
    setAssessment,
    assessment,
  } = props;

  return (
    <React.Fragment>
      <IonRow className={"wrapFSRow"}>
        <IonCol size="2.5" className="borderEnd">
          <div className="FSWrapTitle">
            <PMLabel
              textAlign="center"
              fontColor="xLight"
              fontFamily="Regular"
              fontSize="large"
            >
              {title}
            </PMLabel>
          </div>
        </IonCol>
        <IonCol size="5" className="columnFS borderEnd">
          <IonList className="FSData">
            {elements.map((element, index) => (
              <IonRow key={index} className="elementRowFS textRowFSTT">
                <div className="elementWrapFS elementWrapUnderLine">
                  <PMLabel
                    textAlign="start"
                    fontColor="xLight"
                    fontFamily="Light"
                    fontSize="medium"
                  >
                    {element.name}
                  </PMLabel>
                </div>
              </IonRow>
            ))}
          </IonList>
        </IonCol>
        <IonCol size="3.5" className="columnFS borderEnd">
          <IonList className="FSData">
            {elements.map((element, index) => (
              <IonRow key={index} className="elementRowFS">
                <div className="elementWrapFS ">
                  <InstructorFeedbackGrade
                    onGradeSelected={(grade: number | undefined) => {
                      setGrade(element.id, grade);
                    }}
                    isToRest={resetAll}
                    setIsToRest={setResetAll}
                    isTraineeSelected={props.isTraineeSelected}
                    resultsOptions={element.gradeOptions}
                  ></InstructorFeedbackGrade>
                </div>
              </IonRow>
            ))}
          </IonList>
        </IonCol>

        <IonCol className="assessmentColumnIF">
          <IonTextarea
            className="FSAssessment scrollS"
            maxlength={200}
            rows={2}
            value={assessment}
            onIonChange={(e) => {
              e.detail.value && setAssessment(e.detail.value);
            }}
          ></IonTextarea>
        </IonCol>
      </IonRow>
    </React.Fragment>
  );
};

export default FireSupportTrainingType;
