import { IonItem, IonList, IonListHeader } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { ITooltipElementsResult } from "../../../../../Interfaces/dataCalculator";
import IStationChartData from "../../../../../Interfaces/IStationChartData";
import IStationsChart from "../../../../../Interfaces/IStationsChart";
import PMButton from "../../../../themeComponents/PMButton";
import PMLabel from "../../../../themeComponents/PMLabel";
import "./TooltipData.css";

type IClickHandler = {
  showStation?: boolean;
  index?: number;
  header?: string;
  isGoBack?: boolean;
};

type Props = {
  header: string;
  showSection: string;
  listData:
    | IStationsChart[]
    | IStationChartData[]
    | ITooltipElementsResult[]
    | undefined;
  onClickHandler: ({
    showStation,
    index,
    header,
    isGoBack,
  }: IClickHandler) => void;
  expand: boolean;
};

const TooltipList = (props: Props) => {
  const { header, showSection, listData, onClickHandler } = props;

  const { t } = useTranslation();

  const goBackButton = () => {
    onClickHandler({ isGoBack: true, showStation: false });
  };
  return (
    <IonList className="tooltip-list">
      <IonListHeader className="tooltip-header">
        <PMLabel textAlign="start" fontFamily="Regular" fontColor="xLight">
          {t(header)}
        </PMLabel>
        {showSection !== "forceName" ? ( // if this is not the first list to show
          <PMButton
            label={{
              fontColor: "light",
              fontSize: "small",
              fontFamily: "Light",
            }}
            size="fit"
            color="dark"
            fill="solid"
            onClickHandler={() => goBackButton()}
          >
            {t("goBack")}
          </PMButton>
        ) : (
          <></>
        )}
      </IonListHeader>

      {listData &&
        listData.map(
          (
            data:
              | IStationsChart
              | IStationChartData
              | ITooltipElementsResult
              | any,
            index: number
          ) => {
            return (
              <div key={index}>
                {showSection === "forceName" ? (
                  <IonItem
                    className="tooltip-item"
                    key={index}
                    button
                    onClick={() =>
                      onClickHandler({ showStation: false, index: index })
                    }
                  >
                    <PMLabel
                      textAlign="start"
                      fontFamily="Regular"
                      fontColor="xLight"
                    >
                      {t(data.name)}
                    </PMLabel>
                  </IonItem>
                ) : showSection === "forceTrainingType" ? (
                  <IonItem
                    className="tooltip-item"
                    key={index + 10}
                    button
                    onClick={() =>
                      onClickHandler({
                        showStation: true,
                        index: index,
                        header: data.name,
                      })
                    }
                  >
                    <PMLabel
                      textAlign="start"
                      fontFamily="Regular"
                      fontColor="xLight"
                    >
                      {t(data.name)}
                    </PMLabel>
                  </IonItem>
                ) : (
                  <IonItem className="tooltip-item" key={index + 20}>
                    <PMLabel
                      textAlign="start"
                      fontFamily="Regular"
                      fontColor="xLight"
                    >
                      {t(data.name)}
                    </PMLabel>
                    <PMLabel
                      textAlign="start"
                      fontFamily="Regular"
                      fontColor="light"
                    >
                      {data.value}
                    </PMLabel>
                  </IonItem>
                )}
              </div>
            );
          }
        )}
    </IonList>
  );
};

export default TooltipList;
