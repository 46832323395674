import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import customToast from "../../Shared/Toast/CustomToast";
import DesktopManualInputTemplate from "./DesktopManualInputTemplate";
import "./BISTUrban.css";

import {
  handleSave,
  IbasicRow,
  sendDrillToBBackend,
} from "./dataPostingHelpers";
import UseDataPosting from "../../CustomHooks/UseDataPosting";
import SoldiersListEditor from "./SoldiersListEditor";
import IDataPosting from "../../../Interfaces/IDataPosting";
import { checkSuperUserPassword } from "../../../services/passwordsHandler";
import { IonPage } from "@ionic/react";
import AdminPassword from "../AdminPassword/AdminPassword";
import { BISTUrbanContext } from "../../../context/BISTUrbanContext/BISTUrbanContext";
import {
  comparisonFormatter,
  positiveNumber,
  positiveNumberNtoM,
} from "./inputValidations";
import { Column, EEditorType } from "../PMDataGrid/DataGridTypes";
import DataGrid from "../PMDataGrid/DataGrid";

const messageType: number = 6;
const rowsNumber: number = 14;
const trainingTypeId: number = 1;

// BIST urban row (ui) interface
interface IBISTUrbanRow extends IbasicRow {
  weaponType: string;
  numberOfTargets: number | "";
  numberOfShots: number | "";
  numberOfHits: number | "";
  targetsDestroyed: number | "";
  duration: number | "";
  cleanTargets: number | "";
  score: number | "";
  hashtags: string | "";
  soldierId: number | "";
}

// BIST urban message interface
interface BISTUrbanMessage {
  MessageType: number;
  TimeStamp: Date;
  TrainingTypeID: number;
  SoldierID: number | "";
  DrillStartTime: Date;
  WeaponType: string;
  Duration: number | "";
  NumOfTargets: number | "";
  NumOfShots: number | "";
  RedHits: number | "";
  TargetsDestroyed: number | "";
  CleanTargets: number | "";
  Hashtags: string[];
  PlanId: number | undefined;
  Score: number | "";
}

const BISTUrban: React.FC<IDataPosting> = (
  props: IDataPosting
): JSX.Element => {
  const { t } = useTranslation();
  const XDefaultRow: IBISTUrbanRow = {
    id: 0,
    weaponType: " ",
    numberOfShots: "",
    numberOfHits: "",
    numberOfTargets: "",
    targetsDestroyed: "",
    cleanTargets: "",
    duration: "",
    hashtags: "",
    soldierId: "",
    soldierName: "",
    score: "",
  };
  const [invalid, setInvalid] = useState<boolean>(false);
  const { isBISTUrbanAuthed, authBISTUrban } = useContext(BISTUrbanContext);

  const {
    isSuccess,
    setIsSuccess,
    rows,
    setRows,
    refreshRowsWrap,
    resetRowsDataWrap,
    weaponsTypesList,
  } = UseDataPosting(XDefaultRow, rowsNumber, trainingTypeId);

  // Columns for each input type
  const columns: Column<IBISTUrbanRow>[] = [
    {
      key: "soldierName",
      header: t("soldierName"),
      editable: true,
      renderEditCell: SoldiersListEditor,
      filterType: EEditorType.text,
      width: 200,
    },
    {
      key: "soldierId",
      header: t("militaryId"),
      editable: true,
      filterType: EEditorType.text,
      validationFunction: (row: IBISTUrbanRow, value: any) =>
        positiveNumber(value),
    },
    {
      key: "weaponType",
      header: t("weaponType"),
      editable: true,
      filterType: EEditorType.dropdown,
      options: weaponsTypesList.map((weapon) => ({
        label: t(weapon),
        value: weapon,
      })),
    },
    {
      key: "duration",
      header: t("Duration"),
      editable: true,
      filterType: EEditorType.text,
      validationFunction: (row: IBISTUrbanRow, value: any) =>
        positiveNumber(value),
    },
    {
      key: "numberOfTargets",
      header: t("numOfTargets"),
      editable: true,
      filterType: EEditorType.text,
      validationFunction: (row: IBISTUrbanRow, value: any) =>
        positiveNumber(value),
    },
    {
      key: "numberOfShots",
      header: t("numberOfShots"),
      editable: true,
      filterType: EEditorType.text,
      validationFunction: (row: IBISTUrbanRow, value: any) =>
        positiveNumber(value),
    },
    {
      key: "numberOfHits",
      header: t("numberOfHits"),
      editable: true,
      filterType: EEditorType.text,
      validationFunction: (row: IBISTUrbanRow, value: any) => {
        return comparisonFormatter(
          "numberOfHits",
          "numberOfShots",
          row,
          value,
          t
        );
      },
    },
    {
      key: "targetsDestroyed",
      header: t("numOfDestroyed"),
      editable: true,
      filterType: EEditorType.text,
      validationFunction: (row: IBISTUrbanRow, value: any) =>
        positiveNumber(value),
    },
    {
      key: "cleanTargets",
      header: t("CleanTargets"),
      editable: true,
      filterType: EEditorType.text,
      validationFunction: (row: IBISTUrbanRow, value: any) =>
        positiveNumber(value),
    },
    {
      key: "score",
      header: t("score"),
      editable: true,
      filterType: EEditorType.text,
      validationFunction: (row: IBISTUrbanRow, value: any) =>
        positiveNumberNtoM(value, 100, 0, t),
    },
    {
      key: "hashtags",
      header: t("hashtagsExcName"),
      editable: true,
      filterType: EEditorType.text,
    },
  ];

  // Manages data sending
  const sendData = async (row: IBISTUrbanRow) => {
    // To avoid sending empty rows, check if all elements exist
    if (row.soldierId !== "") {
      if (
        row.soldierName === "" ||
        row.numberOfTargets === "" ||
        row.numberOfHits === "" ||
        row.numberOfShots === "" ||
        row.targetsDestroyed === "" ||
        row.cleanTargets === ""
      ) {
        customToast.error(t("fillBISTUrbanError"));
      } else {
        // Sets the loading
        setIsSuccess(-1);
        let rowToSend: BISTUrbanMessage;
        let weaponType = row.weaponType === " " ? "None" : row.weaponType;

        rowToSend = {
          SoldierID: row.soldierId,
          MessageType: messageType,
          TimeStamp: new Date(),
          TrainingTypeID: trainingTypeId,
          WeaponType: weaponType,
          NumOfShots: row.numberOfShots,
          RedHits: row.numberOfHits,
          NumOfTargets: row.numberOfTargets,
          TargetsDestroyed: row.targetsDestroyed,
          Hashtags: row.hashtags.split(" "),
          DrillStartTime: new Date(),
          PlanId: props.selectedPlan?.id,
          CleanTargets: row.cleanTargets,
          Duration: row.duration,
          Score: row.score,
        };
        // Then sends it
        sendDrillToBBackend(
          rowToSend,
          () => {
            props.resetCompetencyForces();
          },
          () => setIsSuccess(500)
        );
      }
    }
  };

  const onSendPasswordHandler = async (password: string) => {
    let isValid = await checkSuperUserPassword(password, setInvalid);
    if (isValid) authBISTUrban();
  };

  return !isBISTUrbanAuthed ? (
    <IonPage>
      <div className="bistUrbanPassword">
        <AdminPassword
          onSendPasswordHandler={onSendPasswordHandler}
          text={t("enterSuperUserPassword")}
          invalid={invalid}
        ></AdminPassword>
      </div>
    </IonPage>
  ) : (
    <DesktopManualInputTemplate
      selectedPlan={props.selectedPlan}
      plansOptions={props.plansOptions}
      onPlanSelectedHandler={props.onPlanSelectedHandler}
      pageName={"BIST_Urban"}
      onClickFirstButton={() => {
        resetRowsDataWrap(XDefaultRow);
      }}
      onClickSecondButton={() => {
        refreshRowsWrap(XDefaultRow);
      }}
      onClickThirdButton={() => {
        handleSave(rows, sendData, t);
      }}
    >
      <DataGrid
        columns={columns}
        data={rows}
        editable
        onEdit={(updatedRow) => {
          setRows((prev) =>
            prev.map((row) => (row.id === updatedRow.id ? updatedRow : row))
          );
        }}
        minWidth={150}
      ></DataGrid>
    </DesktopManualInputTemplate>
  );
};

export default BISTUrban;
