// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.elementsHeader {
  font-size: 17px !important;
  font-family: "Regular" !important;
  color: var(--ion-color-fontXLight) !important;
  padding-bottom: 2%;
}

.thresholdsDiv {
  display: flex;
  justify-content: space-between;
}

.elThresholdsDiv {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.thresholdsInputsDiv {
  max-width: 14vw;
}
.thresholdHeaderWrapper {
  overflow-x: visible !important;
}

.checkboxHeader {
  display: flex;
  justify-content: center;
}
.elementsHeadersRow {
  display: flex;
  justify-content: space-between;
  width: 82%;
}

.isForDashboardLabel {
  text-align: center;
}

.elNameCol {
  display: flex;
  align-items: center;
  padding-inline-start: 25px;
}
.elementsTitleRow {
  padding-inline-start: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/TrainingPlan/Elements.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,iCAAiC;EACjC,6CAA6C;EAC7C,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,WAAW;AACb;AACA;EACE,eAAe;AACjB;AACA;EACE,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,UAAU;AACZ;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,0BAA0B;AAC5B;AACA;EACE,yBAAyB;AAC3B","sourcesContent":[".elementsHeader {\n  font-size: 17px !important;\n  font-family: \"Regular\" !important;\n  color: var(--ion-color-fontXLight) !important;\n  padding-bottom: 2%;\n}\n\n.thresholdsDiv {\n  display: flex;\n  justify-content: space-between;\n}\n\n.elThresholdsDiv {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n}\n.thresholdsInputsDiv {\n  max-width: 14vw;\n}\n.thresholdHeaderWrapper {\n  overflow-x: visible !important;\n}\n\n.checkboxHeader {\n  display: flex;\n  justify-content: center;\n}\n.elementsHeadersRow {\n  display: flex;\n  justify-content: space-between;\n  width: 82%;\n}\n\n.isForDashboardLabel {\n  text-align: center;\n}\n\n.elNameCol {\n  display: flex;\n  align-items: center;\n  padding-inline-start: 25px;\n}\n.elementsTitleRow {\n  padding-inline-start: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
