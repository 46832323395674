// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#main-site-mana {
  background-color: var(--ion-color-primary);
}
.SMTabs {
  min-height: 60px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Desktop/SiteManagment/siteManagementTabs.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;AAC5C;AACA;EACE,gBAAgB;AAClB","sourcesContent":["#main-site-mana {\n  background-color: var(--ion-color-primary);\n}\n.SMTabs {\n  min-height: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
