import { useEffect, useState } from "react";
import {
  IonInput,
  IonItem,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import EEventType from "../../../../Interfaces/EEventType";
import customToast from "../../../Shared/Toast/CustomToast";
import PMLabel from "../../../themeComponents/PMLabel";
import { sendDrillToBBackend } from "../../../Desktop/DataPosting/dataPostingHelpers";

import "./MagnetFOFComponent.css";
import { useWeapons } from "../../../CustomHooks/HooksWithRQ/useWeapons";
import { IMobileDataPostingProps } from "../../../../Interfaces/IMobileDataPostingProps";
interface IProps extends IMobileDataPostingProps {
  traineeId: number;
  afterPostHandler?: () => void;
  isDesktop?: boolean;
  trainingTypeId: number;
  stationName: string;
  laneId: number;
  traineeName: string;
}
const MagnetFOFComponent: React.FC<IProps> = (props: IProps): JSX.Element => {
  const [numOfTargets, setNumOfTargets] = useState<string>("");
  const [numOfShots, setNumOfShots] = useState<string>("");
  const [numOfHits, setNumOfHits] = useState<string>("");
  const [numOfDestroyed, setNumOfDestroyed] = useState<string>("");
  const [hashtags, setHashtags] = useState<string>("");
  const [overalltime] = useState<string>("");
  const [weaponType, setWeaponType] = useState<string>("");
  const { t } = useTranslation();
  const startDateTime: Date = new Date();

  const traineeId = props.traineeId;
  const traineeName = props.traineeName;
  const trainingTypeId = props.trainingTypeId;
  const laneId = props.laneId;
  const stationName = props.stationName;
  const { weaponsTypesList } = useWeapons();

  /**
   * return an array with the hour, minutes and seconds as a string
   * @param overalltime
   */
  const getDurationParams = (overalltime: string) => {
    const overallTime: string[] = overalltime.split(":");
    const minutes = overallTime[0];
    const seconds = overallTime[1];
    return [minutes, seconds];
  };

  /**
   * returns the duration in seconds
   * @param overalltime
   */
  const getDurationInSeconds = (overalltime: string) => {
    let durationParams = getDurationParams(overalltime);
    return parseInt(durationParams[0]) * 60 + parseInt(durationParams[1]);
  };

  /**
   * post the data to b-backend
   */
  const sendData = () => {
    if (traineeId === undefined) {
      // if (isDesktop) customToast.error(t("noSelectedSoldier"));
      customToast.error(t("traineeNotFound"));
      return;
    }
    if (numOfDestroyed === "" || numOfTargets === "") {
      customToast.error(t("fillMagnetError"));
    } else {
      props.setIsSendDisable && props.setIsSendDisable(true);
      const timeStamp: Date = new Date();
      const duration = getDurationInSeconds(overalltime);
      let currentWeaponType = weaponType === "No Weapon" ? "None" : weaponType;
      const magnetDrill = {
        MessageType: EEventType.MAGNET_MESSAGE_TYPE,
        TimeStamp: timeStamp,
        LaneID: laneId,
        TrainingTypeID: trainingTypeId,
        SoldierID: traineeId,
        DrillStartTime: startDateTime,
        DrillEndTime: timeStamp,
        Duration: duration,
        WeaponType: currentWeaponType,
        NumOfTargets: numOfTargets,
        NumOfShots: numOfShots,
        RedHits: numOfHits,
        TargetsDestroyed: numOfDestroyed,
        Hashtags: hashtags.split(" "),
        PlanId: props.planId,
      };
      const displayToast = true;
      sendDrillToBBackend(
        magnetDrill,
        props.afterPostHandler,
        () => props.setIsSendDisable && props.setIsSendDisable(false),
        t,
        displayToast
      );
    }
  };
  useEffect(() => {
    if (props.isSendClicked) {
      sendData();
    }
  }, [props.isSendClicked]);
  return (
    <IonRow className="magnetDataRow">
      <IonRow className="magnetInputRow">
        <IonItem mode="md" class="manualInputContainerM itemM" lines="full">
          <PMLabel
            fontSize="medium"
            fontFamily="Light"
            fontColor="light"
            position="floating"
          >
            {t("weaponType")}
          </PMLabel>
          <IonSelect
            interface="action-sheet"
            value={weaponType}
            onIonChange={(e) => setWeaponType(e.detail.value)}
          >
            {weaponsTypesList.map((weaponType: string, index: number) => (
              <IonSelectOption
                key={index}
                className="weaponOpt"
                value={weaponType}
              >
                {t(weaponType)}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
      </IonRow>
      <IonRow className="magnetInputRow">
        <IonItem class="manualInputContainerM itemM" lines="full">
          <PMLabel
            fontSize="medium"
            fontFamily="Light"
            fontColor="light"
            position="floating"
          >
            {t("numOfTargets")}
          </PMLabel>
          <IonInput
            onIonChange={(e) => {
              setNumOfTargets(e.detail.value!);
            }}
            value={numOfTargets}
            type="number"
          ></IonInput>
        </IonItem>
      </IonRow>
      <IonRow className="magnetInputRow">
        <IonItem class="manualInputContainerM itemM" lines="full">
          <PMLabel
            fontSize="medium"
            fontFamily="Light"
            fontColor="light"
            position="floating"
          >
            {t("numOfShots")}
          </PMLabel>
          <IonInput
            onIonChange={(e) => {
              setNumOfShots(e.detail.value!);
            }}
            value={numOfShots}
            type="number"
          ></IonInput>
        </IonItem>
      </IonRow>
      <IonRow className="magnetInputRow">
        <IonItem class="manualInputContainerM itemM" lines="full">
          <PMLabel
            fontSize="medium"
            fontFamily="Light"
            fontColor="light"
            position="floating"
          >
            {t("numOfHits")}
          </PMLabel>
          <IonInput
            onIonChange={(e) => {
              setNumOfHits(e.detail.value!);
            }}
            value={numOfHits}
            type="number"
          ></IonInput>
        </IonItem>
      </IonRow>
      <IonRow className="magnetInputRow">
        <IonItem class="manualInputContainerM itemM" lines="full">
          <PMLabel
            fontSize="medium"
            fontFamily="Light"
            fontColor="light"
            position="floating"
          >
            {t("numOfDestroyed")}
          </PMLabel>
          <IonInput
            onIonChange={(e) => {
              setNumOfDestroyed(e.detail.value!);
            }}
            value={numOfDestroyed}
            type="number"
          ></IonInput>
        </IonItem>
      </IonRow>
      <IonRow className="magnetInputRow">
        <IonItem mode="md" class="manualInputContainerM itemM" lines="full">
          <PMLabel
            fontSize="medium"
            fontFamily="Light"
            fontColor="light"
            position="floating"
          >
            {t("hashtagsExcName")}
          </PMLabel>
          <IonInput
            onIonChange={(e) => {
              setHashtags(e.detail.value!);
            }}
            value={hashtags}
            type="text"
          ></IonInput>
        </IonItem>
      </IonRow>
    </IonRow>
  );
};

export default MagnetFOFComponent;
