// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.traineesModal {
  width: 85%;
  height: 90%;
  /* margin-inline-start: 5.1vw; */
}
.traineesModalHe {
  direction: rtl;
}

.traineesModalEn {
  direction: ltr;
}
.traineesModalGrid {
  width: 100%;
  height: 100%;
  background-color: rgba(42, 44, 48, 1);
  padding: 0;
  display: flex;
  flex-direction: column;
}
.backdropCol {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

.backdropIcon {
  cursor: pointer;
  padding-inline-end: 15px;
}

.TMtitleCol {
  padding-top: 10px;
  padding-bottom: 2px;
  padding-inline-start: 25px;
}

.elementsCheckboxRow {
  padding-bottom: 29px;
  padding-inline-start: 58px;
}

.traineesListRow {
  margin-inline-start: 15px;
  margin-inline-end: 15px;
  height: calc(100% - 120px);
}

.dashboardModalHeader {
  font-size: 30px;
}
.TMHeaderRow {
  height: 60px;
  padding-top: 5px;
}
.TMSearchRow {
  height: 70px;
  min-width: 310px;
  padding-inline-start: 0 !important;
}

.TMSearchBarRow {
  height: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/DashboardTrainees/TraineesModal/TraineesModal.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,WAAW;EACX,gCAAgC;AAClC;AACA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;AACA;EACE,WAAW;EACX,YAAY;EACZ,qCAAqC;EACrC,UAAU;EACV,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,aAAa;EACb,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,wBAAwB;AAC1B;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,0BAA0B;AAC5B;;AAEA;EACE,oBAAoB;EACpB,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;EACzB,uBAAuB;EACvB,0BAA0B;AAC5B;;AAEA;EACE,eAAe;AACjB;AACA;EACE,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,gBAAgB;EAChB,kCAAkC;AACpC;;AAEA;EACE,YAAY;AACd","sourcesContent":[".traineesModal {\n  width: 85%;\n  height: 90%;\n  /* margin-inline-start: 5.1vw; */\n}\n.traineesModalHe {\n  direction: rtl;\n}\n\n.traineesModalEn {\n  direction: ltr;\n}\n.traineesModalGrid {\n  width: 100%;\n  height: 100%;\n  background-color: rgba(42, 44, 48, 1);\n  padding: 0;\n  display: flex;\n  flex-direction: column;\n}\n.backdropCol {\n  display: flex;\n  justify-content: flex-end;\n  padding-top: 10px;\n}\n\n.backdropIcon {\n  cursor: pointer;\n  padding-inline-end: 15px;\n}\n\n.TMtitleCol {\n  padding-top: 10px;\n  padding-bottom: 2px;\n  padding-inline-start: 25px;\n}\n\n.elementsCheckboxRow {\n  padding-bottom: 29px;\n  padding-inline-start: 58px;\n}\n\n.traineesListRow {\n  margin-inline-start: 15px;\n  margin-inline-end: 15px;\n  height: calc(100% - 120px);\n}\n\n.dashboardModalHeader {\n  font-size: 30px;\n}\n.TMHeaderRow {\n  height: 60px;\n  padding-top: 5px;\n}\n.TMSearchRow {\n  height: 70px;\n  min-width: 310px;\n  padding-inline-start: 0 !important;\n}\n\n.TMSearchBarRow {\n  height: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
