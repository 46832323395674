import React from "react";
import { IonPage } from "@ionic/react";
import { useTranslation } from "react-i18next";
import "./Notifications.css";

const Notifications = () => {
  const { t } = useTranslation();

  return (
    <IonPage className="notifications">
      <div className="coming-soon">{t("comingSoon")}</div>
    </IonPage>
  );
};

export default Notifications;
