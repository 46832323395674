import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ForcesContext from "../../../../../context/ForcesContext/forcesContext";
import EIconsSrc from "../../../../../Interfaces/EIconsSrc";
import { URLs } from "../../../../../Configurations/urls";
import IShootingDay from "../../../../../Interfaces/IShootingDay";
import useNavigation from "../../../../CustomHooks/useNavigation";
import NotExistLabel from "../../../../Shared/NotExistLabel/NotExistLabel";
import Spinner from "../../../../Shared/Spinner/Spinner";
import List from "../../../List/List";
import MobileHeader from "../../../MobileHeader/MobileHeader";
import MobileTitleRow from "../../../MobileHeader/MobileTitleRow";
import OrbatTreeModal from "../../../OrbatTreeModal/OrbatTreeModal";
import "./ShootingDaySelectionScreen.css";
import { useParams } from "react-router";
import { useShootingDays } from "../../../../CustomHooks/HooksWithRQ/useShootingDays";
import { IonPage } from "@ionic/react";
import ELanguage from "../../../../../Enums/ELanguage";

type IProps = {};

const ShootingDaySelectionScreen: FC<IProps> = (props: IProps): JSX.Element => {
  const { t, i18n } = useTranslation();

  const { navigate, returnToPreviousPage } = useNavigation();
  const [loading, setLoading] = useState<boolean>(true);
  const { forces, isForcesTreeOpen, setIsForcesTreeOpen } =
    useContext(ForcesContext);

  const params = useParams<{ planId: string }>();

  const { shootingDays } = useShootingDays();
  useEffect(() => {
    if (shootingDays) {
      setLoading(false);
    }
  }, [shootingDays]);

  const navigateToHeats = (item: IShootingDay) => {
    navigate(
      `${URLs.stations.shootingRange.plans.shootingDays.heats.getURL(
        item.id,
        undefined,
        +params.planId
      )}`
    );
  };

  return (
    <IonPage className="shooting-day-selection">
      <MobileHeader
        isUserDisplay={false}
        title={t("Shooting_Range")}
        iconStartSrc={
          i18n.language === ELanguage.he
            ? EIconsSrc.BACK_ICON_HE
            : EIconsSrc.BACK_ICON_EN
        }
        iconStartHandler={returnToPreviousPage}
        isShowForcesTree
      />
      <div className="shooting-days-list">
        {forces.length > 0 ? (
          <>
            <MobileTitleRow title={t("selectShootingDay")} />
            {loading ? (
              <Spinner />
            ) : (
              <List
                items={
                  shootingDays?.sort(
                    (currentDay: IShootingDay, nextDay: IShootingDay) =>
                      currentDay.id - nextDay.id
                  )!
                }
                handleClick={(listItem: IShootingDay | undefined) =>
                  navigateToHeats(listItem!)
                }
              />
            )}
          </>
        ) : (
          <NotExistLabel
            text="noSelectedForces"
            className="no-selected-forces"
          />
        )}
      </div>
      <OrbatTreeModal
        limit={1}
        isOpen={isForcesTreeOpen}
        close={() => setIsForcesTreeOpen(false)}
        mode="secondary"
      />
    </IonPage>
  );
};

export default ShootingDaySelectionScreen;
