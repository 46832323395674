import { useEffect, useState } from "react";
import IForceTreeNode from "../../../Interfaces/IForceTreeNode";
import { getForceFullName } from "../../../services/helpers";
import IForceFromOrbat from "../../../Interfaces/IForceFromOrbat";
import { useTranslation } from "react-i18next";

const useOneSelectedForce = () => {
  const { t } = useTranslation();
  const [selectedForce, setSelectedForce] = useState<IForceTreeNode[]>([]);
  const [forces, setForces] = useState<IForceFromOrbat[]>([]);
  const getForceChildren = (orbatTree: IForceTreeNode) => {
    return orbatTree
      ? orbatTree?.nodes
        ? [
            {
              forceType: orbatTree.force_type,
              id: orbatTree.id,
              isSoldier: orbatTree.nodes === null,
              name: orbatTree.name,
            },
            ...orbatTree.nodes
              .filter((f) => f.nodes !== null)
              ?.map((node) => ({
                forceType: node.force_type,
                id: node.id,
                isSoldier: node.nodes === null,
                name: getForceFullName(node.name, node.force_type, t),
              })),
          ]
        : [
            {
              forceType: orbatTree.force_type,
              id: orbatTree.id,
              isSoldier: orbatTree.nodes === null,
              name: orbatTree.name,
            },
          ]
      : [];
  };
  useEffect(() => {
    if (selectedForce?.length) {
      forces && setForces(getForceChildren(selectedForce[0]));
    } else {
      forces && setForces([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedForce]);

  const onCheckForce = (forces: IForceTreeNode[]) => {
    setSelectedForce((prev) => {
      let result = forces.map((force: IForceTreeNode) => {
        let currName: string = force.name;
        currName = currName.includes(force.force_type)
          ? currName
          : getForceFullName(force.name, force.force_type, t);

        return {
          ...force,
          name: currName,
        };
      });
      if (JSON.stringify(prev) === JSON.stringify(result)) return prev;
      return result;
    });
  };
  return {
    forces,
    onCheckForce,
    selectedForce,
    setSelectedForce,
  };
};
export default useOneSelectedForce;
