// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.indicatorGrid {
  background-color: var(--ion-color-dark) !important;
  border-radius: 4px;
  box-shadow: 0px 0px 7px -2px rgba(0, 0, 0, 0.75);
  padding-inline-start: 25px;
}

.indicatorItem {
  width: 16vw !important;
  height: 6.2vh !important;

  border-radius: 2px !important;

  --background: rgba(255, 255, 255, 0.03);
  --color: var(--ion-color-fontWhite);
}

.viewMode {
  pointer-events: none;
}

.indicatorDropdownPlaceholder {
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: large !important;
  font-family: "Bold";
}
.indicatorDropdownPlaceholder:lang(he) {
  max-width: 90%;
}

.indicatorTrashCol {
  display: flex;
  justify-content: flex-end;
}

.tpTrashIcon:hover {
  color: var(--ion-color-text-primary-shade) !important;
}
.indicatorCol {
  --ion-grid-column-padding: 0;
  padding-top: 5px;
}
.tpDropdown {
  min-height: 45px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/TrainingPlan/Indicator.css"],"names":[],"mappings":"AAAA;EACE,kDAAkD;EAClD,kBAAkB;EAClB,gDAAgD;EAChD,0BAA0B;AAC5B;;AAEA;EACE,sBAAsB;EACtB,wBAAwB;;EAExB,6BAA6B;;EAE7B,uCAAuC;EACvC,mCAAmC;AACrC;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,8BAA8B;EAC9B,2BAA2B;EAC3B,mBAAmB;AACrB;AACA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,qDAAqD;AACvD;AACA;EACE,4BAA4B;EAC5B,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".indicatorGrid {\n  background-color: var(--ion-color-dark) !important;\n  border-radius: 4px;\n  box-shadow: 0px 0px 7px -2px rgba(0, 0, 0, 0.75);\n  padding-inline-start: 25px;\n}\n\n.indicatorItem {\n  width: 16vw !important;\n  height: 6.2vh !important;\n\n  border-radius: 2px !important;\n\n  --background: rgba(255, 255, 255, 0.03);\n  --color: var(--ion-color-fontWhite);\n}\n\n.viewMode {\n  pointer-events: none;\n}\n\n.indicatorDropdownPlaceholder {\n  max-width: 100%;\n  display: flex;\n  justify-content: space-between;\n  font-size: large !important;\n  font-family: \"Bold\";\n}\n.indicatorDropdownPlaceholder:lang(he) {\n  max-width: 90%;\n}\n\n.indicatorTrashCol {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.tpTrashIcon:hover {\n  color: var(--ion-color-text-primary-shade) !important;\n}\n.indicatorCol {\n  --ion-grid-column-padding: 0;\n  padding-top: 5px;\n}\n.tpDropdown {\n  min-height: 45px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
