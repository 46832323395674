// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legendItem {
  margin-top: 3%;
  color: var(--ion-color-fontXLight) !important;
  --background: var(--ion-color-light) !important;
  border-radius: 1ch;
  overflow-y: auto;
  display: flex;
  justify-content: space-around;
}

.leadersItemGroup {
  height: 100%;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
  align-self: center;
  flex-wrap: nowrap;
  overflow-y: auto !important;
}

.badge {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-top: 3px;
  margin-inline-end: 15px;
  margin-inline-start: 5px;
}

.labelGradeLL {
  font-size: medium;
  font-family: "Light" !important;
}

.labelText {
  font-size: medium;
  font-family: "Light" !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Mobile/LabelsList/LabelsList.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,6CAA6C;EAC7C,+CAA+C;EAC/C,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,6BAA6B;AAC/B;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,iBAAiB;EACjB,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,qBAAqB;EACrB,eAAe;EACf,uBAAuB;EACvB,wBAAwB;AAC1B;;AAEA;EACE,iBAAiB;EACjB,+BAA+B;AACjC;;AAEA;EACE,iBAAiB;EACjB,+BAA+B;AACjC","sourcesContent":[".legendItem {\n  margin-top: 3%;\n  color: var(--ion-color-fontXLight) !important;\n  --background: var(--ion-color-light) !important;\n  border-radius: 1ch;\n  overflow-y: auto;\n  display: flex;\n  justify-content: space-around;\n}\n\n.leadersItemGroup {\n  height: 100%;\n  width: 100%;\n  padding-top: 0px;\n  padding-bottom: 0px;\n  align-self: center;\n  flex-wrap: nowrap;\n  overflow-y: auto !important;\n}\n\n.badge {\n  height: 12px;\n  width: 12px;\n  border-radius: 50%;\n  display: inline-block;\n  margin-top: 3px;\n  margin-inline-end: 15px;\n  margin-inline-start: 5px;\n}\n\n.labelGradeLL {\n  font-size: medium;\n  font-family: \"Light\" !important;\n}\n\n.labelText {\n  font-size: medium;\n  font-family: \"Light\" !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
