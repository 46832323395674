import React, { useState, useEffect, useContext, Dispatch } from "react";

// css
import "./Orbat.css";

// components
import ForceDetails from "../../../Shared/Orbat/ForceDetails/ForceDetails";
import OrbatTree from "../../../Shared/Orbat/OrbatTree/OrbatTree";
import axios from "../../../../Axios";

// redux
import { useDispatch, useSelector } from "react-redux";
import { orbatTreeState } from "../../../../redux/store/OrbatTreeStore";
import { IOrbatTreeReducer } from "../../../../redux/reducers/orbatTree";
import {
  resetFlags,
  updateForceInTree,
  addForce,
  OrbatTreeActionTypes,
} from "../../../../redux/actions/orbatTreeActions";

// services
import { useWebsocketRegister } from "../../../../services/siteManagementSocket";
import { useTranslation } from "react-i18next";
import {
  flattenObject,
  getForceById,
  instanceOfIForceType,
  treeFlatting,
} from "../../../../services/helpers";

// ionic imports
import { IonCol, IonGrid, IonRow } from "@ionic/react";

// interfaces
import IForceTreeNode from "../../../../Interfaces/IForceTreeNode";
import {
  baseUrlPMBackend,
  MAX_SELECTED,
} from "../../../../Configurations/consts";
import Alert from "../../../Shared/Alert/Alert";
import customToast from "./../../../Shared/Toast/CustomToast";
import { UserContext } from "../../../../context/UserContext/userContext";
import IForceType from "./../../../../Interfaces/IForceType";
import PMTitle from "../../../themeComponents/PMTitle";
import PMLoading from "../../../Shared/Alert/PMLoading";
import SaveCancelButtons from "../../../Shared/Buttons/SaveCancelButtons";
import { environment } from "../../../../Configurations/consts";
import EEnvironment from "../../../../Enums/EEnvironment";
import IForcePointer from "../../../../Interfaces/IForcePointer";
import IIsDuplicatedForce from "../../../../Interfaces/IIsDuplicatedForce";
import { ForcesContextDesktop } from "../../../../context/ForcesContext/forcesContextProviderDesktop";

const Orbat: React.FC = () => {
  const { isAdmin, isInstructor } = useContext(UserContext);
  const { resetCompetencyForces } = useContext(ForcesContextDesktop);

  const { t } = useTranslation();
  const dispatch = useDispatch<Dispatch<any>>();
  const [registerMessage] = useWebsocketRegister();
  const [loading, setLoading] = useState<boolean>(false);
  const [isTagIdExist, setIsTagIdExist] = useState<boolean>(false);
  const [checkedForceHasTagId, setCheckedForceHasTagId] =
    useState<boolean>(false);
  const [forceWithExistingTag, setForceWithExistingTag] =
    useState<IForceTreeNode>({} as IForceTreeNode);
  const [forceToRemove, setForceToRemove] = useState<IForceTreeNode>();
  const [checkedForce, setCheckedForce] = useState<IForceTreeNode>(
    {} as IForceTreeNode
  );

  const [originalCheckedForce, setOriginalCheckedForce] =
    useState<IForceTreeNode>({} as IForceTreeNode);
  const [deletingLoading, setDeletingLoading] = useState<boolean>(false);
  const [openRestoreAlert, setOpenRestoreAlert] = useState<{
    isOpen: boolean;
    operation:
      | OrbatTreeActionTypes.ADD_FORCE
      | OrbatTreeActionTypes.UPDATE_FORCE
      | undefined;
  }>({ isOpen: false, operation: undefined });
  const [alertOKClicked, setAlertOKClicked] = useState<boolean>(false);
  const [flatTree, setFlatTree] = useState<IForcePointer[]>([]);
  const [isDuplicated, setIsDuplicated] = useState<IIsDuplicatedForce>();

  //redux state
  const updateOccured: boolean = useSelector<orbatTreeState, IOrbatTreeReducer>(
    (state) => state.orbatTree
  ).updateOccured;
  const updateResStatus: string = useSelector<
    orbatTreeState,
    IOrbatTreeReducer
  >((state) => state.orbatTree).updateResStatus;
  const updateErrorMsg: string = useSelector<orbatTreeState, IOrbatTreeReducer>(
    (state) => state.orbatTree
  ).updateErrorMsg;
  const AddOccured: boolean = useSelector<orbatTreeState, IOrbatTreeReducer>(
    (state) => state.orbatTree
  ).addOccured;
  const addResStatus: string = useSelector<orbatTreeState, IOrbatTreeReducer>(
    (state) => state.orbatTree
  ).addResStatus;
  const addErrorMsg: string = useSelector<orbatTreeState, IOrbatTreeReducer>(
    (state) => state.orbatTree
  ).addErrorMsg;
  const orbatTree: IForceTreeNode = useSelector<
    orbatTreeState,
    IOrbatTreeReducer
  >((state) => state.orbatTree).orbatTree;

  const [enableReorder, setEnableReorder] = useState<boolean>(false);
  const [draggedElement, setDraggedElement] = useState<
    HTMLDivElement | undefined
  >();

  const disableEditing = environment?.toString() === EEnvironment.production;

  useEffect(() => {
    initIsDuplicated();
  }, []);

  useEffect(() => {
    if (orbatTree) {
      setFlatTree([]);
      treeFlatting(orbatTree, setFlatTree);
      //if orbat changed update the checked force details
      setCheckedForce((prev) => {
        let updatedForce = getForceById(orbatTree, prev.id);
        return updatedForce ? updatedForce : prev;
      });
    }
  }, [orbatTree]);

  useEffect(() => {
    if (!checkedForce.is_soldier && isDuplicated) {
      setIsDuplicated((prev: IIsDuplicatedForce | undefined) => ({
        ...prev!,
        isDuplicatedSoldier: {
          personal_id: false,
          soldier_id: false,
          tag_id: false,
          weapon_id: false,
          magazine_id: false,
          laser_id: false,
          head_sensor_id: false,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedForce]);

  useEffect(() => {
    if (updateOccured) {
      resetCompetencyForces();
      if (updateResStatus === "OK") {
        setForceWithExistingTag({} as IForceTreeNode);
        customToast.success(t("forceUpdateSuccessMsg"));
      } else if (updateResStatus === "error") {
        if (
          updateErrorMsg &&
          (updateErrorMsg?.includes("notNullMsg") ||
            updateErrorMsg?.includes("wrongLengthMsg"))
        ) {
          if (updateErrorMsg.includes("notNullMsg")) {
            customToast.error(
              `${t("notNullMsg1")} ${t(updateErrorMsg.split("=")[1])} ${t(
                "notNullMsg2"
              )}`
            );
          }
          if (updateErrorMsg.includes("wrongLengthMsg")) {
            customToast.error(
              `${t("wrongLengthMsg1")} ${t(updateErrorMsg.split("=")[1])} ${t(
                "wrongLengthMsg2"
              )}`
            );
          }
        } else if (updateErrorMsg.includes("soldierIdExist")) {
          let [isHeDeleted] = updateErrorMsg.split("?").slice(1);

          if (isHeDeleted.split("=")[1] === "true") {
            setOpenRestoreAlert({
              isOpen: true,
              operation: OrbatTreeActionTypes.UPDATE_FORCE,
            });
          } else {
            customToast.error("traineeExistAndNotDeleted");
          }
        } else {
          customToast.error(t("updateForcesError"));
        }
      }
      setLoading(false);
      dispatch(resetFlags());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateOccured]);

  useEffect(() => {
    if (AddOccured) {
      if (addResStatus === "OK") {
        customToast.success(t("forceAddedSuccessfullyMsg"));
        setCheckedForce({} as IForceTreeNode);
      } else if (addResStatus === "error") {
        if (
          addErrorMsg.includes("notNullMsg") ||
          addErrorMsg.includes("wrongLengthMsg")
        ) {
          if (addErrorMsg.includes("notNullMsg")) {
            customToast.error(
              `${t("notNullMsg1")} ${t(addErrorMsg.split("=")[1])} ${t(
                "notNullMsg2"
              )}`
            );
          }
          if (addErrorMsg.includes("wrongLengthMsg")) {
            customToast.error(
              `${t("wrongLengthMsg1")} ${t(addErrorMsg.split("=")[1])} ${t(
                "wrongLengthMsg2"
              )}`
            );
          }
        } else if (addErrorMsg.includes("soldierIdExist")) {
          let [isHeDeleted] = addErrorMsg.split("?").slice(1);

          if (isHeDeleted.split("=")[1] === "true") {
            setOpenRestoreAlert({
              isOpen: true,
              operation: OrbatTreeActionTypes.ADD_FORCE,
            });
          } else {
            customToast.error("traineeExistAndNotDeleted");
          }
        }
      }

      setLoading(false);
      dispatch(resetFlags());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AddOccured]);

  useEffect(() => {
    if (registerMessage) {
      if (checkedForce.id) {
        changeTagIdHandler(registerMessage.soldierRFID);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerMessage]);

  useEffect(() => {
    if (openRestoreAlert.isOpen && alertOKClicked) {
      actionOnSave();
      setAlertOKClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertOKClicked]);

  const initIsDuplicated = (): void => {
    const initDuplicates = {
      isDuplicatedSoldier: {
        personal_id: false,
        soldier_id: false,
        tag_id: false,
        weapon_id: false,
        magazine_id: false,
        laser_id: false,
        head_sensor_id: false,
      },
      isDuplicatedGroup: { personal_id: false, name: false },
    };
    setIsDuplicated(initDuplicates);
  };

  const changeTagIdHandler = async (tagId: number) => {
    let res = await axios.get(`${baseUrlPMBackend}forces/getForceByByTagId`, {
      params: {
        tagId: tagId,
      },
    });

    if (res.data) {
      setForceWithExistingTag(res.data);
      setIsTagIdExist(true);
    } else {
      if (checkedForce.tag_id) {
        setCheckedForceHasTagId(true);
      } else {
        changeTagBySocket();
      }
    }
  };

  const onSave = async () => {
    try {
      setLoading(true);
      if (checkedForce.id > 0) {
        if (forceWithExistingTag.id) {
          dispatch(
            updateForceInTree({
              ...forceWithExistingTag,
              tag_id: null,
              weapon_sight_id: checkedForce.weapon_sight_id,
              force_type: checkedForce.force_type,
              personal_id: checkedForce.personal_id,
              magazine_id: checkedForce.magazine_id,
              laser_id: checkedForce.laser_id,
              head_sensor_id: checkedForce.head_sensor_id,
            })
          );
        }
        dispatch(updateForceInTree(checkedForce));
      } else {
        dispatch(addForce(checkedForce, false));
      }
    } catch (e) {
      console.log(`Error in send changes. Error: ${e}`);
    }
  };

  const onCancel = () => {
    if (checkedForce.id < 0) {
      setForceToRemove(checkedForce);
    } else {
      setCheckedForce(originalCheckedForce);
    }
  };

  const onForceChecked = (checkedForce: IForceTreeNode[]) => {
    if (checkedForce[0]) {
      setCheckedForce(checkedForce[0]);
      setOriginalCheckedForce(checkedForce[0]);
    } else {
      setCheckedForce({} as IForceTreeNode);
      setOriginalCheckedForce({} as IForceTreeNode);
    }
  };

  const changeTagBySocket = () => {
    onChangeForceDetail("tag_id", registerMessage!.soldierRFID.toString());
  };

  const onChangeForceDetail = (
    field: string,
    value: string | boolean | IForceType
  ): void => {
    resetCompetencyForces();
    if (value !== null && value !== undefined) {
      if (typeof value === "object" && instanceOfIForceType(value)) {
        let changedForce: IForceTreeNode = {
          ...checkedForce,
          name:
            checkedForce.name === t("newForce") && !value.is_soldier
              ? t("newUnit")
              : checkedForce.name,
          is_soldier: value.is_soldier,
          force_type: value.name,
          soldier_id: value.is_soldier ? checkedForce.soldier_id : null,
          weapon_id: value.is_soldier ? checkedForce.weapon_id : null,
          weapon_sight_id: value.is_soldier
            ? checkedForce.weapon_sight_id
            : null,
          tag_id: value.is_soldier ? checkedForce.tag_id : null,
          magazine_id: value.is_soldier ? checkedForce.magazine_id : null,
          laser_id: value.is_soldier ? checkedForce.laser_id : null,
          head_sensor_id: value.is_soldier ? checkedForce.head_sensor_id : null,
        };
        setCheckedForce(changedForce);
      } else {
        setCheckedForce((prev) => ({ ...prev, [field]: value }));
      }
      if (
        field === "personal_id" ||
        field === "soldier_id" ||
        field === "weapon_id" ||
        field === "tag_id" ||
        field === "magazine_id" ||
        field === "laser_id" ||
        field === "head_sensor_id" ||
        field === "name" ||
        field === "force_type"
      ) {
        setIsDuplicated(checkForDuplicateValues(field, value));
      }
    }
  };

  const checkForDuplicateValues = (
    field:
      | "personal_id"
      | "soldier_id"
      | "weapon_id"
      | "tag_id"
      | "magazine_id"
      | "laser_id"
      | "head_sensor_id"
      | "name"
      | "force_type",
    value: string | boolean | IForceType
  ): IIsDuplicatedForce => {
    let isDuplicatedSoldierField: boolean = false;
    let isDuplicatedPersonalIdGroup: boolean = false;
    if (value !== "") {
      let duplicateForces = flatTree.filter((soldier) =>
        field === "force_type"
          ? checkedForce.id !== soldier.id &&
            checkedForce.name.trim() === soldier.name.trim()
          : field === "personal_id" || field === "name"
          ? soldier[field]?.trim() === String(value).trim() &&
            checkedForce.id !== soldier.id
          : field === "weapon_id"
          ? +value !== 0 &&
            Number(soldier[field]) === +value &&
            checkedForce.id !== soldier.id
          : Number(soldier[field]) === +value && checkedForce.id !== soldier.id
      );
      if (checkedForce.is_soldier) {
        if (duplicateForces.find((force) => force.soldier_id !== null)) {
          isDuplicatedSoldierField = true;
        }
      } else if (
        (field === "personal_id" ||
          field === "name" ||
          field === "force_type") &&
        duplicateForces.find((force) => force.soldier_id === null)
      ) {
        isDuplicatedPersonalIdGroup = true;
      }
    }
    return {
      ...isDuplicated,
      isDuplicatedGroup: {
        ...isDuplicated!.isDuplicatedGroup,
        [field !== "force_type" ? "name" : field]: isDuplicatedPersonalIdGroup,
      },
      isDuplicatedSoldier: {
        ...isDuplicated!.isDuplicatedSoldier,
        [field]: isDuplicatedSoldierField,
      },
    };
  };

  const tagIdExchange = () => {
    changeTagBySocket();
  };

  const saveValidation = () => {
    let valid = true;
    if (
      checkedForce.is_soldier &&
      (!checkedForce.soldier_id || +checkedForce.soldier_id === 0)
    ) {
      valid = false;
      customToast.error(t("soldierIdMissingAlert"));
    } else if (
      checkedForce.is_soldier &&
      checkedForce.soldier_id?.toString().length !== 7
    ) {
      valid = false;
      customToast.error(t("soldierIdLengthAlert"));
    } else if (!!flattenObject(isDuplicated).find((item) => item === true)) {
      valid = false;
      customToast.error(t("duplicateDetails"));
    }
    // If we'll want to re-enable only english and numbers
    //   else if (
    //     !/^[A-Za-z0-9]*$/.test(
    //       checkedForce.weapon_id ? checkedForce.weapon_id : ""
    //     )
    //   ) {
    //   valid = false;
    //   customToast.error(t("weaponIdEnglishOnlyAlert"));
    // }

    if (valid) {
      onSave();
    }
  };

  const actionOnSave = () => {
    setLoading(true);

    if (openRestoreAlert.operation === OrbatTreeActionTypes.ADD_FORCE) {
      dispatch(addForce(checkedForce, true));
    } else if (
      openRestoreAlert.operation === OrbatTreeActionTypes.UPDATE_FORCE
    ) {
      dispatch(updateForceInTree(checkedForce, true));
    }
  };
  return (
    <div
      className="orbat-container"
      onDrop={() => {
        draggedElement?.remove();
      }}
    >
      <PMLoading
        isOpen={deletingLoading}
        spinner="bubbles"
        message={t("deletingInProgress")}
      ></PMLoading>
      <div className="orbat">
        <PMLoading isOpen={loading} message={t("loadingData")} />
        <IonGrid className="grid-orbat">
          <IonRow className="main-row">
            <IonCol size="2" className="orbat-tree">
              <OrbatTree
                checked={onForceChecked}
                readonly={false}
                limit={MAX_SELECTED}
                forceToRemove={forceToRemove}
                isAdmin={isAdmin}
                setDeletingLoading={setDeletingLoading}
                disableEditing={disableEditing}
                initIsDuplicated={initIsDuplicated}
                enableReorder={enableReorder}
                setEnableReorder={setEnableReorder}
                draggedElement={draggedElement}
                setDraggedElement={setDraggedElement}
              />
            </IonCol>
            <IonCol size="8" className="force-details">
              <IonRow className="container-row">
                <IonRow className="titleRowOrbatDetails">
                  <PMTitle
                    fontColor="light"
                    fontFamily="Light"
                    fontSize="large"
                  >
                    {t("detailsHeader")}
                  </PMTitle>
                </IonRow>
                <IonCol size="10" className="details">
                  <ForceDetails
                    disableEditing={disableEditing || enableReorder} //force details is disabled when editing is disabled or when the re-order functionality is on
                    checkedForce={checkedForce}
                    onChange={onChangeForceDetail}
                    isAdmin={isAdmin}
                    isDuplicated={isDuplicated!}
                  />
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol size="2" className="buttons-col" align-self-end>
              {isAdmin || isInstructor ? (
                <SaveCancelButtons
                  disabled={disableEditing || enableReorder}
                  onCancelClickHandler={onCancel}
                  onSaveClickHandler={saveValidation}
                ></SaveCancelButtons>
              ) : null}
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
      <Alert
        header={t("forceHasTagIdAlert")}
        isOpen={checkedForceHasTagId}
        setIsOpen={setCheckedForceHasTagId}
        actionOnSave={changeTagBySocket}
        actionOnCancel={() => setCheckedForceHasTagId(false)}
      />
      <Alert
        header={t("tagIdExixstAlert")}
        isOpen={isTagIdExist}
        setIsOpen={setIsTagIdExist}
        actionOnSave={tagIdExchange}
        actionOnCancel={() => setIsTagIdExist(false)}
      />
      <Alert
        header={t("resoreForceAlertMsg")}
        subHeader={t("resoreForceAlertSubMsg")}
        isOpen={openRestoreAlert.isOpen}
        setIsOpen={(state: boolean) =>
          setOpenRestoreAlert((prev) => ({
            operation: undefined,
            isOpen: false,
          }))
        }
        actionOnSave={() => {
          setAlertOKClicked(true);
        }}
        actionOnCancel={() =>
          setOpenRestoreAlert((prev) => ({
            operation: undefined,
            isOpen: false,
          }))
        }
      />
    </div>
  );
};

export default Orbat;
