// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.elementResultsRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  padding-inline-start: 16px;
  padding-inline-end: 16px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.elementResultsItem {
  --background: "transparent";
  --border-radius: 4px;
  display: flex;
  flex-direction: column;
  min-height: 40px;
  height: 40px;
}

.elementResultsName {
  font-family: Regular;
  font-size: medium;
  color: var(--ion-color-fontLight);
}
.elementResultCol {
  min-width: 50px;
  --ion-grid-column-padding: 0;
  display: flex;
  justify-content: center;
}
.elementResultCol.name {
  justify-content: flex-start !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Desktop/Gorilla/ElementResultRow.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;EAChB,0BAA0B;EAC1B,wBAAwB;EACxB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,2BAA2B;EAC3B,oBAAoB;EACpB,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,oBAAoB;EACpB,iBAAiB;EACjB,iCAAiC;AACnC;AACA;EACE,eAAe;EACf,4BAA4B;EAC5B,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,sCAAsC;AACxC","sourcesContent":[".elementResultsRow {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  min-height: 50px;\n  padding-inline-start: 16px;\n  padding-inline-end: 16px;\n  padding-top: 3px;\n  padding-bottom: 3px;\n}\n.elementResultsItem {\n  --background: \"transparent\";\n  --border-radius: 4px;\n  display: flex;\n  flex-direction: column;\n  min-height: 40px;\n  height: 40px;\n}\n\n.elementResultsName {\n  font-family: Regular;\n  font-size: medium;\n  color: var(--ion-color-fontLight);\n}\n.elementResultCol {\n  min-width: 50px;\n  --ion-grid-column-padding: 0;\n  display: flex;\n  justify-content: center;\n}\n.elementResultCol.name {\n  justify-content: flex-start !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
