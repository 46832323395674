import React from "react";
import { useTranslation } from "react-i18next";
import PMLabel from "../../themeComponents/PMLabel";
import { useIonPopover } from "@ionic/react";
import ILastSync from "../../../Interfaces/ILastSync";
import "./LastSiteSync.css";
import { convertDateToString } from "../../../services/helpers";

interface ILastSyncProps {
  lastSynced: ILastSync[];
}

const LastSiteSync = (props: ILastSyncProps) => {
  const { t } = useTranslation();

  const PopoverList = () => {
    return (
      <div className="lastSiteSyncPopover">
        {props.lastSynced.map((site: ILastSync, index: number) => (
          <PMLabel
            textAlign="center"
            fontColor="light"
            fontFamily="Light"
            fontSize="medium"
            key={index}
          >
            {t("site" + site.id) +
              ":  " +
              new Date(site.lastSynced).toString().split(" ")[4] +
              " " +
              convertDateToString(new Date(site.lastSynced))}
          </PMLabel>
        ))}
      </div>
    );
  };

  // Initiated here so that I can use PopoverList function
  const [present, dismiss] = useIonPopover(PopoverList, {
    onHide: () => dismiss(),
  });

  return (
    <PMLabel
      onClick={(e) =>
        present({
          event: e.nativeEvent,
        })
      }
      textAlign="center"
      fontColor="light"
      fontFamily="Light"
      fontSize="medium"
    >
      {t("lastSynced")}
    </PMLabel>
  );
};

export default LastSiteSync;
