import { IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import { useEffect, useState, useContext, FC } from "react";
import "./Leaders.css";
import { useTranslation } from "react-i18next";
import MobileHeader from "../../../components/Mobile/MobileHeader/MobileHeader";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import LeadersList from "../../../components/Shared/Leaders/LeadersList";
import LeadersToggle from "../../../components/Shared/Buttons/PMToggle";
import OrbatTreeModal from "../../../components/Mobile/OrbatTreeModal/OrbatTreeModal";
import ForcesContext from "../../../context/ForcesContext/forcesContext";

import Spinner from "../../../components/Shared/Spinner/Spinner";
import { UserContext } from "../../../context/UserContext/userContext";
import { userRoles } from "../../../services/routeRoles";
import usePlans from "../../../components/CustomHooks/usePlans";
import useGetLeaders from "../../../components/CustomHooks/useGetLeaders";
import useForcesChecked from "../../../components/CustomHooks/useForcesChecked";

const Leaders: FC = (): JSX.Element => {
  const { user } = useContext(UserContext);

  const {
    forces: orbatForces,
    isForcesTreeOpen,
    setIsForcesTreeOpen,
  } = useContext(ForcesContext);

  const [isUnits, setIsUnits] = useState<boolean>(true);
  const [isLeadersForTrainee, setIsLeadersForTrainee] =
    useState<boolean>(false);
  const { t } = useTranslation();
  const { activePlan } = usePlans();
  const { checkedForces, forcesToRemove, newForces } =
    useForcesChecked(orbatForces);
  useEffect(() => {
    /**
     * if the user is a trainee and doesn't has any other related force, the user can see all his department results but the forces tree is hidden
     */
    if (
      user.relatedForce?.is_soldier === true && //if the current force is a trainee/soldier
      user.role === userRoles.Viewer && //if the role permission is viewer
      user.relatedForce.id === user.forceToDisplayInOrbat?.id //if there is only one related force (might be string == number)
    )
      setIsLeadersForTrainee(true);
  }, [user]);
  const { individuals, units, loading } = useGetLeaders(
    activePlan,
    checkedForces,
    newForces,
    forcesToRemove,
    isLeadersForTrainee,
    orbatForces
  );
  return (
    <IonPage>
      <IonContent className="contentContainerL" scrollY={false}>
        <MobileHeader
          iconEndSrc={EIconsSrc.MENU_ICON}
          isShowForcesTree={!isLeadersForTrainee}
        />
        <IonGrid className="gridLeaders">
          <div className="colLeaders">
            <IonRow className="rowToggleLeaders">
              <IonCol size="9" className="togglesCol">
                <LeadersToggle
                  firstOptionText={t("unitsTitle")}
                  secondOptionText={t("individualsTitle")}
                  state={isUnits}
                  setState={setIsUnits}
                />
              </IonCol>

              <IonCol size="3" className="traineeTreeButtonCol">
                {/* {!isLeadersForTrainee ? (
                  <PMButton
                    label={{
                      fontColor: "light",
                      fontSize: "small",
                      fontFamily: "Light",
                    }}
                    size="fit"
                    color="light"
                    fill="solid"
                    onClickHandler={() => setIsForcesTreeOpen(true)}
                    cssClass="traineeTreeButton"
                  >
                    {t("forcesTree")}
                  </PMButton>
                ) : null} */}
              </IonCol>
            </IonRow>
            <IonRow className="leadersSlides">
              <IonContent scrollY={false} className="contentLeaders">
                <div className="listLeaders" key={1}>
                  {loading ? (
                    <Spinner className="spinner-trainees-list" />
                  ) : (
                    <LeadersList
                      leaders={isUnits ? units : individuals}
                      platform="mobile"
                    />
                  )}
                </div>
              </IonContent>
            </IonRow>
            <OrbatTreeModal
              limit={3}
              isOpen={isForcesTreeOpen}
              close={() => {
                setIsForcesTreeOpen(false);
              }}
              mode="secondary"
            />
          </div>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Leaders;
