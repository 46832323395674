import { Dispatch, FC, SetStateAction } from "react";
import "./LauncherSelector.css";
import PMLabel from "../../themeComponents/PMLabel";
import PMIcon from "../../themeComponents/PMIcon";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import { IonCol, IonRow } from "@ionic/react";
import ESPIKEIcons from "../../../Interfaces/ESPIOKEIcons";
import SPIKEIcon from "./SPIKEIcon";
import { ELuncherTypes } from "../../../Enums/EHasifaTkifa";
import { useTranslation } from "react-i18next";

interface IProps {
  setSelectedLuncherType: Dispatch<SetStateAction<ELuncherTypes | undefined>>;
  launcherType: ELuncherTypes | undefined;
  isMobile?: boolean;
}

const LauncherSelector: FC<IProps> = (props: IProps) => {
  const { launcherType, setSelectedLuncherType, isMobile } = props;
  const { t } = useTranslation();
  return (
    <div
      className={`LauncherSelectorRow ${
        isMobile ? "mobileLauncherSelector" : ""
      }`}
    >
      <IonRow>
        <IonCol className="paddingFree">
          <SPIKEIcon
            src={ESPIKEIcons.COMBINED}
            onClick={() => {
              setSelectedLuncherType(ELuncherTypes.Combined);
            }}
            state={
              !launcherType
                ? "enable"
                : launcherType === ELuncherTypes.Combined
                ? "selected"
                : "disable"
            }
            name={t(ELuncherTypes.Combined)}
            isMobile={isMobile}
          />
        </IonCol>
        <IonCol className="paddingFree">
          <SPIKEIcon
            src={ESPIKEIcons.CONTROL}
            onClick={() => {
              setSelectedLuncherType(ELuncherTypes.ControlUnit);
            }}
            state={
              !launcherType
                ? "enable"
                : launcherType === ELuncherTypes.ControlUnit
                ? "selected"
                : "disable"
            }
            name={t(ELuncherTypes.ControlUnit)}
            isMobile={isMobile}
          />
        </IonCol>
      </IonRow>
    </div>
  );
};

export default LauncherSelector;
