import { IonCol, IonGrid, IonRow, IonTitle } from "@ionic/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import User from "./User";
import Axios from "../../../../Axios";
import { baseUrlPMBackend } from "../../../../Configurations/consts";
import IUser from "../../../../Interfaces/IUser";
import Alert from "../../../Shared/Alert/Alert";
import customToast from "../../../Shared/Toast/CustomToast";
import SearchBar from "../../../Shared/SearchBar/SearchBar";

import "./Users.css";

interface IUsersProps {
  users: IUser[];
  setUsers: (users: (prev: IUser[]) => IUser[]) => void;
  setIdToEdit: (id: number) => void;
}
const Users = (props: IUsersProps) => {
  const { t } = useTranslation();
  const [isShowDeleteAlert, setIsShowDeleteAlert] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<number>(-1);
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    if (idToDelete !== -1) {
      setIsShowDeleteAlert(true);
    }
  }, [idToDelete]);

  // Handles delete request
  const userDeleteHandler = async () => {
    let res = await Axios.delete(`${baseUrlPMBackend}users/deleteUser`, {
      params: { userId: idToDelete },
    });

    if (res.status === 200) {
      customToast.success(t("userDeleteSuccess"));
      props.setUsers((prev) => prev.filter((user) => user.id !== idToDelete));
    } else {
      customToast.error(t("userDeleteFail"));
    }
  };

  const userMatchesSearchText = (user: IUser, searchText: string): boolean => {
    const searchWords = searchText.toLowerCase().split(" ");
    // for each word in the search check if the user includes
    return searchWords.every((word) => {
      return (
        (user.emailAddress &&
          user.emailAddress.toLowerCase().includes(word.toLowerCase())) || //add the option to filter by email address
        (user.displayName &&
          user.displayName.toLowerCase().includes(word.toLowerCase())) || //add the option to filter by display name
        (user.relatedForce?.brigade_name &&
          user.relatedForce?.brigade_name
            .toLowerCase()
            .includes(word.toLowerCase())) || //add the option to filter by brigade_name
        (user.role && t(user.role).toLowerCase().includes(word.toLowerCase())) //add the option to filter by role
      );
    });
  };
  //filter the users by the search text
  const filterUsers = (users: IUser[]): IUser[] => {
    return users.filter((user) => userMatchesSearchText(user, searchText));
  };

  return (
    <IonGrid className="usersDiv">
      <Alert
        header={t("deleteUser")}
        isOpen={isShowDeleteAlert}
        setIsOpen={setIsShowDeleteAlert}
        actionOnSave={userDeleteHandler}
        actionOnCancel={() => {
          setIsShowDeleteAlert(false);
          setIdToDelete(-1);
        }}
      />
      <IonRow className="searchBarRow">
        <SearchBar
          color="dark"
          value={searchText}
          onChange={setSearchText}
        ></SearchBar>
      </IonRow>
      <IonCol size="10">
        <IonRow className="usersListHeadersRow">
          <IonCol size="2.2">
            <IonTitle className="listHeader">{t("displayName")}</IonTitle>
          </IonCol>
          <IonCol size="1.7">
            <IonTitle className="listHeader">{t("role")}</IonTitle>
          </IonCol>
          <IonCol size="3">
            <IonTitle className="listHeader">{t("emailOrUsername")}</IonTitle>
          </IonCol>
          <IonCol size="2.5">
            <IonTitle className="listHeader">{t("force")} </IonTitle>
          </IonCol>
          <IonCol size="2">
            <IonTitle className="listHeader">{t("brigade")} </IonTitle>
          </IonCol>
        </IonRow>
      </IonCol>
      <IonRow className="usersListRow">
        <div className="usersList">
          {props.users &&
            filterUsers(props.users).map((user, index) => (
              <User
                key={index}
                user={user}
                userDeleteHandler={userDeleteHandler}
                setIdToDelete={setIdToDelete}
                setIdToEdit={props.setIdToEdit}
              ></User>
            ))}
        </div>
      </IonRow>
    </IonGrid>
  );
};

export default Users;
