import { ReactNode, ComponentType } from "react";

type ComponentWrapper = (props: { children: ReactNode }) => JSX.Element;

export const combineComponents = (...components: any[]): ComponentWrapper => {
  return components.reduce(
    (
      AccumulatedComponents: ComponentWrapper,
      CurrentComponent: ComponentType<any>
    ) => {
      return ({ children }: { children: ReactNode }): JSX.Element => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        );
      };
    },
    ({ children }: { children: ReactNode }): JSX.Element => <>{children}</>
  );
};
