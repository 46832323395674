import { IonGrid, IonRow, IonCol, isPlatform } from "@ionic/react";
import React, { useEffect, useState } from "react";
import ISimpleTrainee from "../../../Interfaces/ISimpleTrainee";
import SearchComponents from "../../Shared/MenualStation/SearchSoldierComp/SearchComponents";
import DesktopManualInputTemplate from "./DesktopManualInputTemplate";
import IDataPosting from "../../../Interfaces/IDataPosting";

import "./FIreSupport.css";
import { EAppMode } from "../../../Enums/EAppMode";
import FireSupportComponent from "./FIreSupportComponent";

const FireSupport: React.FC<IDataPosting> = (
  props: IDataPosting
): JSX.Element => {
  const [soldierDetails, setSoldierDetails] = useState<ISimpleTrainee[]>([]);

  const [isSendDataClicked, setSendDataClicked] = useState<boolean>(false);

  const [isSendEnabled, setIsComplete] = useState<boolean>(false);

  useEffect(() => {
    setSendDataClicked(false);
  }, [isSendDataClicked]);
  return (
    <DesktopManualInputTemplate
      selectedPlan={props.selectedPlan}
      plansOptions={props.plansOptions}
      onPlanSelectedHandler={props.onPlanSelectedHandler}
      pageName={EAppMode.FIRE_SUPPORT}
      onClickThirdButton={() => setSendDataClicked(true)}
      isSendDisabled={!isSendEnabled}
    >
      <IonGrid className="searchGrid">
        <IonRow className="searchRow">
          <IonCol size="2" className="searchCol">
            <SearchComponents
              soldierDetails={soldierDetails}
              setSoldierDetails={setSoldierDetails}
              isDesktop={true}
            />
          </IonCol>
          <IonCol size="10" className="col2UIF">
            <FireSupportComponent
              traineeId={Number(soldierDetails[0]?.id)}
              isSendClicked={isSendDataClicked}
              isComplete={isSendEnabled}
              setIsComplete={setIsComplete}
              planId={props.selectedPlan?.id}
              afterPostHandler={props.resetCompetencyForces}
              isDesktop
            ></FireSupportComponent>
          </IonCol>
        </IonRow>
      </IonGrid>
    </DesktopManualInputTemplate>
  );
};

export default FireSupport;
