import plansReducer from "./plans";
import trainingPlanReducer from "./trainingPlan";
import orbatTree from "./orbatTree";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  indicators: trainingPlanReducer,
  plans: plansReducer,
  orbatTree: orbatTree,
});

export default rootReducer;
