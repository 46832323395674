// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.finalGradeFS {
  font-size: 24px;
}
.FinalGardeRowFS {
  display: flex;
  width: 100%;
  justify-content: flex-start;

  height: min-content;
}
.finalGradeFS.grade {
  margin-inline-start: 35px;
}
.finalGradeColFS {
  max-width: max-content;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/DataPosting/FIreSupport.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;AACA;EACE,aAAa;EACb,WAAW;EACX,2BAA2B;;EAE3B,mBAAmB;AACrB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,sBAAsB;AACxB","sourcesContent":[".finalGradeFS {\n  font-size: 24px;\n}\n.FinalGardeRowFS {\n  display: flex;\n  width: 100%;\n  justify-content: flex-start;\n\n  height: min-content;\n}\n.finalGradeFS.grade {\n  margin-inline-start: 35px;\n}\n.finalGradeColFS {\n  max-width: max-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
