import React from "react";

import "./TabletFIreSupport.css";
import FireSupport from "./FIreSupport";
import usePlans from "../../CustomHooks/usePlans";
import MobileHeader from "../../Mobile/MobileHeader/MobileHeader";
import { IonRow } from "@ionic/react";

const TabletFireSupport: React.FC = (): JSX.Element => {
  const { selectedPlan, plansOptions, onPlanSelectedHandler } = usePlans();

  return (
    <>
      <IonRow>
        <MobileHeader></MobileHeader>
      </IonRow>
      <FireSupport
        onPlanSelectedHandler={onPlanSelectedHandler}
        plansOptions={plansOptions}
        selectedPlan={selectedPlan}
        resetCompetencyForces={() => {}}
      ></FireSupport>
    </>
  );
};

export default TabletFireSupport;
