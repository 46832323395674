import { Dispatch, FC, SetStateAction } from "react";
import "./Observer.css";
import GeneralGrade from "../GeneralGrade";
import { IConductingQuality } from "../../../../Interfaces/Gorilla/IObserver";
import { IonCol } from "@ionic/react";

type IProps = {
  conductingQuality: IConductingQuality;
  setConductingQuality: Dispatch<SetStateAction<IConductingQuality>>;
  isDesktop?: boolean;
};

const ConductingQuality: FC<IProps> = (props: IProps): JSX.Element => {
  const { conductingQuality, setConductingQuality } = props;

  return (
    <IonCol className="observerContentWrap scrollS ">
      <div className="TACol">
        <GeneralGrade
          elementName={"ConductingQuality"}
          grade={conductingQuality?.ConductingQuality}
          setGrade={(grade) => {
            setConductingQuality((prev) => ({
              ...prev,
              ConductingQuality: grade,
            }));
          }}
          isDesktop={props.isDesktop}
        ></GeneralGrade>
      </div>
    </IonCol>
  );
};

export default ConductingQuality;
