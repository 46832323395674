import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PMLabel from "../../../../../components/themeComponents/PMLabel";
import VerticalProgressBar from "../../../../../components/Desktop/VerticalProgressBar/VerticalProgressBar";

import { IonIcon, IonRow } from "@ionic/react";
import { getElementColor } from "../../../../../components/CustomHooks/useGetCommanderDashboardGrades";
import { IElementResult } from "../../../../../Interfaces/dataCalculator";
import EIconsSrc from "../../../../../Interfaces/EIconsSrc";
import "./ElementCard.css";
interface IProps {
  element: IElementResult;
  onExpendForDetails?: () => void;
  isVisible?: boolean;
}
interface IMapReader {
  value: string | number;
  threshold: string | number;
  percent100: string | number;
  valueColor: string;
  valueText: string;
  nameOf100P: string;
}

const Circe = (props: {
  type: "threshold" | "percent100" | "value";
  color?: string;
}) => (
  <span className={`circleSV ${props.type}SV valueSV${props.color} `}></span>
);

const MapReader = (props: IMapReader) => {
  const { t } = useTranslation();

  return (
    <IonRow className="SVrow mapReaderRow">
      <div className="SVColumn">
        <IonRow className="SVrow">
          <Circe type="threshold"></Circe>

          <PMLabel fontSize="small" fontColor="xLight" fontFamily="Regular">
            {t("requiredThreshold")}
          </PMLabel>
        </IonRow>
      </div>
      <div className="SVColumn">
        <IonRow className="SVrow">
          <Circe type="value" color={props.valueColor}></Circe>

          <PMLabel fontSize="small" fontColor="xLight" fontFamily="Regular">
            {props.valueText}
          </PMLabel>
        </IonRow>
      </div>
      <div className="SVColumn">
        <IonRow className="SVrow">
          <Circe type="percent100"></Circe>

          <PMLabel fontSize="small" fontColor="xLight" fontFamily="Regular">
            {t(props.nameOf100P)}
          </PMLabel>
        </IonRow>
      </div>
    </IonRow>
  );
};
const ElementCard: FC<IProps> = (props: IProps): JSX.Element => {
  const { element } = props;
  const { t } = useTranslation();
  const [isNegative, setInNegative] = useState<boolean>(
    element.upperThreshold < element.lowerThreshold
  );
  useEffect(() => {
    setInNegative(element.upperThreshold < element.lowerThreshold);
  }, [element]);
  return (
    <div className="progressBarCard" key={element.id}>
      <VerticalProgressBar
        percent100={Math.round(
          isNegative ? element.lowerThreshold : element.upperThreshold
        )}
        value={Math.round(
          Math.min(
            element.value,
            isNegative ? element.lowerThreshold : element.upperThreshold
          )
        )}
        threshold={Math.round(element.requiredThreshold)}
        color={getElementColor({
          ...element,
          requiredThreshold:
            (element.requiredThreshold / 100) *
            (isNegative ? element.lowerThreshold : element.upperThreshold),
        })}
        grade={Math.round(element.grade)}
      ></VerticalProgressBar>
      <PMLabel
        fontSize="large"
        fontColor="xLight"
        fontFamily="Regular"
        cssClass="titleCard"
      >
        {t(element.name)}
      </PMLabel>
      <MapReader
        percent100={
          isNegative ? element.lowerThreshold : element.upperThreshold
        }
        value={element.value}
        threshold={element.requiredThreshold}
        valueColor={getElementColor({
          ...element,
          requiredThreshold:
            (element.requiredThreshold / 100) *
            (isNegative ? element.lowerThreshold : element.upperThreshold),
        })}
        valueText={t(element.name)}
        nameOf100P={element.nameOf100P || ""}
      ></MapReader>
      {props.onExpendForDetails && props.isVisible ? (
        <IonIcon
          src={EIconsSrc.OPEN_DOWN}
          className="openCardForDetails"
          onClick={props.onExpendForDetails}
        ></IonIcon>
      ) : null}
    </div>
  );
};
export default ElementCard;
