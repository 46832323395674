// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selected-shooting-day-input {
  margin-inline-end: 24px;
}

.inputs-row {
  display: flex;
  flex-direction: row;
  margin-inline-end: 24px;
}

.selected-soldier {
  width: 16vw;
  margin-inline-end: 24px;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  text-align: center;
}

.shooting-range-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.not-selected-data {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/DataPosting/ShootingRange/ShootingRangeInputs.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,uBAAuB;EACvB,aAAa;EACb,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".selected-shooting-day-input {\n  margin-inline-end: 24px;\n}\n\n.inputs-row {\n  display: flex;\n  flex-direction: row;\n  margin-inline-end: 24px;\n}\n\n.selected-soldier {\n  width: 16vw;\n  margin-inline-end: 24px;\n  margin-top: 0;\n  margin-bottom: 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n  text-align: center;\n}\n\n.shooting-range-image {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.not-selected-data {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  margin-top: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
