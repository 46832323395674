// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faultRow {
  min-height: 40px;
  display: flex;
  align-items: center;
  padding-inline-start: 15px;
  padding-inline-end: 15px;
}
.faultCheckboxCol {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/SPIKEGorilla/TroubleshootingElement.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,0BAA0B;EAC1B,wBAAwB;AAC1B;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB","sourcesContent":[".faultRow {\n  min-height: 40px;\n  display: flex;\n  align-items: center;\n  padding-inline-start: 15px;\n  padding-inline-end: 15px;\n}\n.faultCheckboxCol {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
