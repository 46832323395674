import { FC } from "react";

import "./MedicalStation.css";
import { useHistory } from "react-router-dom";
import MedicalComponent from "./MedicalComponent";
import DataPostingMobileTemplate from "../../../Mobile/DataPosting/DataPostingMobileTemplate";
import useMobileDataPostingParams from "../../../CustomHooks/useMobileDataPostingParams";

const MedicalStation: FC = () => {
  const history = useHistory();
  const { trainees, trainingTypeId, stationName } =
    useMobileDataPostingParams();
  const backHandler = () => {
    history.goBack();
  };

  return (
    <DataPostingMobileTemplate
      stationName={stationName}
      traineeName={trainees[0]?.name || ""}
    >
      <MedicalComponent
        traineeId={Number(trainees[0]?.id)}
        trainingTypeId={trainingTypeId}
        afterPostHandler={backHandler}
      ></MedicalComponent>
    </DataPostingMobileTemplate>
  );
};

export default MedicalStation;
