import { IonRow, IonCol } from "@ionic/react";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import MultiSelectDropdown from "../MultySelectDropdown/MultiSelectDropdown";
import {
  IChartsSelection,
  IIndicatorsSelection,
  ITooltipsOptions,
} from "../../../Interfaces/IExportReport";

import "./ExportModalCollapse.css";
import { useTranslation } from "react-i18next";
import Checkbox from "../../Shared/Checkbox/Checkbox";
import PMLabel from "../../themeComponents/PMLabel";
import IPlan from "../../../Interfaces/IPlan";
import PMCollapse from "../../Shared/PMCollapse/PMCollapse";
import PlansDropdown from "../../Shared/PlansDropdown/PlansDropdown";

interface IProps {
  isSelectionOpened: boolean;
  chartsType: IChartsSelection[];
  indicatorsSelection: IIndicatorsSelection[];
  hashtags: ITooltipsOptions[];
  tooltipsTitles: ITooltipsOptions[];
  setChartsData: Dispatch<SetStateAction<IChartsSelection[]>>;
  setHashtags: Dispatch<SetStateAction<ITooltipsOptions[]>>;
  setIndicatorsSelection: Dispatch<SetStateAction<IIndicatorsSelection[]>>;
  setTooltipsTitles: Dispatch<SetStateAction<ITooltipsOptions[]>>;
  plansOptions: IPlan[];
  onPlanSelectedHandler: (planOption: IPlan) => void;
  selectedPlan: IPlan | undefined;
}

const ExportModalCollapse: React.FC<IProps> = (props: IProps) => {
  const {
    chartsType,
    indicatorsSelection,
    // hashtags,
    tooltipsTitles,
    setChartsData,
    setHashtags,
    setIndicatorsSelection,
    setTooltipsTitles,
    selectedPlan,
    plansOptions,
    onPlanSelectedHandler,
  } = props;
  const { t } = useTranslation();
  const [selectedGraphAmount, setSelectedGraphAmount] = useState<number>(0);
  const [
    selectedIndicatorsAndStationsAmount,
    setSelectedIndicatorsAndStationsAmount,
  ] = useState<number>(0);
  const [selectedStationsTooltipsAmount, setSelectedStationsTooltipsAmount] =
    useState<number>(0);
  // const [isHashtagsOpen, setIsHashtagsOpen] = useState<boolean>(false);
  useEffect(() => {
    setSelectedIndicatorsAndStationsAmount(
      indicatorsSelection
        .filter((a) => a.isSelected)
        .reduce(
          (a, b) =>
            a + b.trainingTypeSelections.filter((a) => a.isSelected).length,
          0
        ) + indicatorsSelection.filter((a) => a.isSelected).length
    );
    setSelectedGraphAmount(
      chartsType.filter((chart) => chart.isSelected).length
    );
    setSelectedStationsTooltipsAmount(
      tooltipsTitles.filter(
        (a) =>
          a.isSelected && a.selectionsOptions.filter((a) => a.isSelected).length
      ).length
    );
  }, [tooltipsTitles, chartsType, indicatorsSelection]);
  const setChartsChangeHandler = (chartId: number, checked: boolean) => {
    setChartsData((prev) => {
      return prev.map((chart) => {
        if (chart.chartId === chartId) return { ...chart, isSelected: checked };
        return chart;
      });
    });
  };

  // const setHashtagsChangeHandler = (
  //   hashtag?: {
  //     id: string;
  //     labelName: string;
  //   },
  //   checked?: boolean,
  //   isSelect?: boolean,
  //   trainingTypeId?: number
  // ) => {
  //   let trainingTypeId2 = hashtag ? hashtag.id.split("_")[0] : trainingTypeId;
  //   setHashtags((prev) => {
  //     return prev.map((trainingType) => {
  //       if (Number(trainingType.trainingTypeId) === Number(trainingTypeId2))
  //         return {
  //           ...trainingType,
  //           selectionsOptions: trainingType.selectionsOptions.map(
  //             (hashtagOption) => {
  //               if (
  //                 hashtagOption.option.label === hashtag?.labelName ||
  //                 isSelect !== undefined
  //               )
  //                 return {
  //                   ...hashtagOption,
  //                   isSelected:
  //                     isSelect !== undefined
  //                       ? isSelect
  //                       : checked
  //                       ? checked
  //                       : false,
  //                 };
  //               else return hashtagOption;
  //             }
  //           ),
  //         };
  //       return trainingType;
  //     });
  //   });
  // };
  const setIndicatorsChangeHandler = (
    indicatorId: number,
    checked: boolean
  ) => {
    setIndicatorsSelection((prev) => {
      return prev.map((indicator) => {
        if (indicator.id === indicatorId)
          return {
            ...indicator,
            isSelected: checked,
            trainingTypeSelections: !checked
              ? indicator.trainingTypeSelections.map((t) => ({
                  ...t,
                  isSelected: checked,
                }))
              : indicator.trainingTypeSelections,
          };
        return indicator;
      });
    });
  };

  const setTrainingTypesChangeHandler = (
    indicatorId: number,
    trainingTypeId: number,
    checked: boolean
  ) => {
    setIndicatorsSelection((prev) => {
      return prev.map((indicator) => {
        if (indicator.id === indicatorId)
          return {
            ...indicator,
            isSelected: checked ? checked : indicator.isSelected,
            trainingTypeSelections: indicator.trainingTypeSelections.map(
              (trainingType) => {
                if (trainingType.id === trainingTypeId)
                  return {
                    ...trainingType,
                    isSelected: checked,
                  };
                else return trainingType;
              }
            ),
          };
        return indicator;
      });
    });
    setHashtags((prev) => {
      return prev.map((trainingType) => {
        if (Number(trainingType.trainingTypeId) === Number(trainingTypeId))
          return {
            ...trainingType,
            isSelected: checked,
          };
        return trainingType;
      });
    });
    setTooltipsTitles((prev) => {
      return prev.map((trainingType) => {
        if (Number(trainingType.trainingTypeId) === Number(trainingTypeId))
          return {
            ...trainingType,
            isSelected: checked,
          };
        return trainingType;
      });
    });
  };
  const getRTLTextInFormat = (text: string | number) =>
    ` \u202A(+${text})\u202C`;
  const setCheckedTooltipsHandler = (
    data?: {
      id: string;
      labelName: string;
    },
    checked?: boolean,
    isSelect?: boolean,
    trainingTypeId?: number
  ) => {
    let trainingTypeName = data?.id.split("_")[0];
    setTooltipsTitles((prev) => {
      return prev.map((trainingType) => {
        if (
          trainingType.trainingTypeName === trainingTypeName ||
          Number(trainingType.trainingTypeId) === Number(trainingTypeId)
        )
          return {
            ...trainingType,
            selectionsOptions: trainingType.selectionsOptions.map((tooltip) => {
              if (
                tooltip.option.label === data?.labelName ||
                isSelect !== undefined
              )
                return {
                  ...tooltip,
                  isSelected:
                    isSelect !== undefined
                      ? isSelect
                      : checked
                      ? checked
                      : false,
                };
              else return tooltip;
            }),
          };
        return trainingType;
      });
    });
  };
  return (
    <IonRow>
      <IonCol className="borderCol">
        <PlansDropdown
          onPlanSelectedHandler={onPlanSelectedHandler}
          plansOptions={plansOptions}
          selectedPlan={selectedPlan}
        ></PlansDropdown>
      </IonCol>
      <IonCol className="borderCol">
        <PMCollapse
          placeholder={t("selectGraphs")}
          label={
            selectedGraphAmount
              ? `${chartsType.filter((chart) => chart.isSelected)[0]?.name}${
                  selectedGraphAmount > 1
                    ? ` ${getRTLTextInFormat(selectedGraphAmount - 1)}`
                    : ""
                }`
              : undefined
          }
        >
          <div className="optionsCol">
            {chartsType.map((chart) => (
              <div className="selectRow" key={chart.chartId}>
                <Checkbox
                  onChange={(event) =>
                    setChartsChangeHandler(chart.chartId, event.detail.checked)
                  }
                  isChecked={chart.isSelected}
                  name={chart.name}
                  value={chart.name}
                  color="light"
                ></Checkbox>
                <PMLabel fontColor="light" fontFamily="Light" fontSize="medium">
                  {t(chart.name)}
                </PMLabel>
              </div>
            ))}
          </div>
        </PMCollapse>
      </IonCol>
      <IonCol className="borderCol">
        <PMCollapse
          placeholder={t("selectIndicatorsAndTrainingTypesDetails")}
          label={
            selectedIndicatorsAndStationsAmount
              ? `${t(
                  indicatorsSelection.filter((a) => a.isSelected)[0]?.label
                )}${
                  selectedIndicatorsAndStationsAmount > 1
                    ? ` ${getRTLTextInFormat(
                        selectedIndicatorsAndStationsAmount - 1
                      )}`
                    : ""
                }`
              : undefined
          }
        >
          <div className="optionsCol">
            {indicatorsSelection?.map((indicator) => (
              <div key={indicator.id}>
                <div className="selectRow">
                  <Checkbox
                    onChange={(event) =>
                      setIndicatorsChangeHandler(
                        indicator.id,
                        event.detail.checked
                      )
                    }
                    name={indicator.label}
                    value={indicator.label}
                    isChecked={indicator.isSelected}
                    color="light"
                  ></Checkbox>
                  <PMLabel
                    fontColor="light"
                    fontFamily="Light"
                    fontSize="medium"
                  >
                    {t(indicator.label)}
                  </PMLabel>
                </div>
                <div>
                  {indicator.trainingTypeSelections.map((trainingType) => (
                    <div
                      className="selectRow tooltipRow"
                      key={`${trainingType.id}${indicator.id}`}
                    >
                      <Checkbox
                        onChange={(event) =>
                          setTrainingTypesChangeHandler(
                            indicator.id,
                            trainingType.id,
                            event.detail.checked
                          )
                        }
                        name={trainingType.label}
                        value={trainingType.label}
                        isChecked={trainingType.isSelected}
                        color="light"
                      ></Checkbox>
                      <PMLabel
                        fontColor="light"
                        fontFamily="Light"
                        fontSize="medium"
                      >
                        {t(trainingType.label)}
                      </PMLabel>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </PMCollapse>
      </IonCol>
      <IonCol className="borderCol">
        <PMCollapse
          placeholder={t("selectTooltips")}
          label={
            selectedStationsTooltipsAmount
              ? `${t(
                  tooltipsTitles.filter(
                    (a) =>
                      a.isSelected &&
                      a.selectionsOptions.filter((a) => a.isSelected).length
                  )[0]?.trainingTypeName
                )}${
                  selectedStationsTooltipsAmount > 1
                    ? ` ${getRTLTextInFormat(
                        selectedStationsTooltipsAmount - 1
                      )}`
                    : ""
                }`
              : undefined
          }
        >
          <div className="optionsCol flexCol">
            {tooltipsTitles?.map((trainingType, index) =>
              trainingType.isSelected ? (
                <div className="dropdownSelections" key={index}>
                  <MultiSelectDropdown
                    isForModal={true}
                    setCheckedHandler={(data, checked, isSelect) =>
                      setCheckedTooltipsHandler(
                        data,
                        checked,
                        isSelect,
                        trainingType.trainingTypeId
                      )
                    }
                    title={t(trainingType.trainingTypeName)}
                    selectionPotions={trainingType.selectionsOptions}
                    isInnerItem
                  ></MultiSelectDropdown>
                </div>
              ) : null
            )}
          </div>
        </PMCollapse>
      </IonCol>
    </IonRow>
  );
};

export default ExportModalCollapse;
