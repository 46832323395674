import { useEffect, useContext } from "react";
import { MarketingContext } from "../../context/MarketingContext/MarketingContext";
import { useLocation } from "react-router";
import { UserContext } from "../../context/UserContext/userContext";
const whitelist = [""];
const SESSION_MINUTES = 10;

const useInactivityLogout = () => {
  const { isMarketing } = useContext(MarketingContext);
  const { logout } = useContext(UserContext);
  const location = useLocation();
  let timeout: NodeJS.Timeout | null = null;

  const restartAutoReset = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      if (isMarketing) {
        logout();
      }
    }, 60000 * SESSION_MINUTES);
  };

  const onMouseMove = () => {
    restartAutoReset();
  };

  useEffect(() => {
    if (isMarketing) {
      // Whitelist certain pages
      let preventReset = false;
      for (const path of whitelist) {
        if (path === location.pathname) {
          preventReset = true;
        }
      }
      if (preventReset) {
        return;
      }

      // initiate timeout
      restartAutoReset();

      // listen for mouse events
      window.addEventListener("mousemove", onMouseMove);
    }
    // cleanup
    return () => {
      if (timeout) {
        clearTimeout(timeout);
        window.removeEventListener("mousemove", onMouseMove);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
};

export default useInactivityLogout;
