// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobileUFISlides {
  height: 100%;
  width: 100%;
}
.mobileUFISlides {
  direction: ltr;
}
.mobileUFISlides:lang(he) {
  direction: rtl;
}
.swiper-pagination-bullet-active {
  background: var(--ion-color-fontXLight) !important;
  padding: 5px;
}
.swiper-pagination-bullet {
  background: var(--ion-color-fontLight);
}
.mobileUFISlide {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 100%;
}
.instructorFeedbackGrid {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  width: 100%;
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/Mobile/ManualStation/InstructorFeedbackMobile/InstructorFeedbackComponent.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;AACb;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,kDAAkD;EAClD,YAAY;AACd;AACA;EACE,sCAAsC;AACxC;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAO;EACP,WAAW;AACb;AACA;EACE,aAAa;EACb,SAAO;EACP,sBAAsB;EACtB,WAAW;EACX,UAAU;AACZ","sourcesContent":[".mobileUFISlides {\n  height: 100%;\n  width: 100%;\n}\n.mobileUFISlides {\n  direction: ltr;\n}\n.mobileUFISlides:lang(he) {\n  direction: rtl;\n}\n.swiper-pagination-bullet-active {\n  background: var(--ion-color-fontXLight) !important;\n  padding: 5px;\n}\n.swiper-pagination-bullet {\n  background: var(--ion-color-fontLight);\n}\n.mobileUFISlide {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  width: 100%;\n}\n.instructorFeedbackGrid {\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  width: 100%;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
