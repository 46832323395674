import { createContext, Dispatch, SetStateAction } from "react";
import IForceTreeNode from "../../Interfaces/IForceTreeNode";

interface Context {
  forces: IForceTreeNode[];
  setForces: (newForces: IForceTreeNode[]) => void;
  isForcesTreeOpen: boolean;
  setIsForcesTreeOpen: Dispatch<SetStateAction<boolean>>;
}

const ForcesContext = createContext<Context>({} as Context);

export default ForcesContext;
