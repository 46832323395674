import { Dispatch, FC, SetStateAction } from "react";
import "./Observer.css";
import { useTranslation } from "react-i18next";
import Divider from "../Divider";
import GeneralGrade from "../GeneralGrade";
import { IWorkRoutine } from "../../../../Interfaces/Gorilla/IObserver";
import PMLabel from "../../../themeComponents/PMLabel";
import { IonGrid } from "@ionic/react";

type IProps = {
  setWorkRoutine: Dispatch<SetStateAction<IWorkRoutine>>;
  workRoutine: IWorkRoutine;
  isDesktop?: boolean;
};

const WorkRoutine: FC<IProps> = (props: IProps): JSX.Element => {
  const { setWorkRoutine, workRoutine } = props;
  const { t } = useTranslation();

  return (
    <IonGrid className="observerContentWrap scrollS">
      {Object.keys(workRoutine).map((key, index) => {
        let typedKey = key as keyof IWorkRoutine;
        return (
          <div key={index} className="TACol">
            {index === 0 && (
              <div className="titleRowMO">
                <PMLabel
                  fontSize="large"
                  fontFamily="Regular"
                  fontColor="light"
                >
                  {t("operationRoutineAndCombatZoneUnderstanding")}
                </PMLabel>
              </div>
            )}
            {index > 0 && !props.isDesktop ? <Divider /> : null}
            <GeneralGrade
              elementName={key}
              grade={workRoutine[typedKey]}
              setGrade={(grade) => {
                setWorkRoutine((prev) => ({
                  ...prev,
                  [typedKey]: grade,
                }));
              }}
              isDesktop={props.isDesktop}
            ></GeneralGrade>
          </div>
        );
      })}
    </IonGrid>
  );
};

export default WorkRoutine;
