import { IonRow } from "@ionic/react";
import React, { useState } from "react";
import RadarGraph from "../../../../../components/Shared/Charts/Radar/RadarGraph";
import IForceFromOrbat from "../../../../../Interfaces/IForceFromOrbat";
import IThreshold from "../../../../../Interfaces/IThreshold";
import ILabel from "../../../../../Interfaces/ILabel";
import LabelsList from "../../../../../components/Mobile/LabelsList/LabelsList";
import IDatasetsChart from "../../../../../Interfaces/IDatasetsChart";
import IGrade from "../../../../../Interfaces/IGrade";
import IPlan from "../../../../../Interfaces/IPlan";
import "./IndicatorsRadar.css";
import IRadarInfo from "../../../../../Interfaces/IRadarInfo";
import Spinner from "../../../../Shared/Spinner/Spinner";

interface IRadarProps {
  labels: ILabel[];
  thresholds: IThreshold[];
  plan: IPlan | undefined;
  isLoading: boolean;
  radarData: IRadarInfo[];
  force: IForceFromOrbat[];
  planGrades: IGrade[];
  threshold: number;
  checkedForces: IForceFromOrbat[];
  forcesToRemove: IForceFromOrbat[];
}

const IndicatorsRadar = (props: IRadarProps): JSX.Element => {
  const [forcesForLabels, setForcesForLabels] = useState<IDatasetsChart[]>([]);

  return (
    <div className="indicatorsRadarDiv">
      <IonRow className="indicatorsRadarRow">
        {props.isLoading ? (
          <Spinner className="competency-spinner" />
        ) : (
          <RadarGraph
            radarData={props.radarData}
            selectedForces={props.checkedForces}
            thresholds={props.thresholds}
            labels={props.labels}
            newForces={props.checkedForces}
            forcesToRemove={props.forcesToRemove}
            setDataForMobileLabels={setForcesForLabels}
            plan={props.plan}
          />
        )}
      </IonRow>
      {!props.isLoading && (
        <IonRow className="indicatorsRadarLegend">
          <LabelsList
            forces={forcesForLabels}
            grades={props.planGrades}
            threshold={props.threshold}
          />
        </IonRow>
      )}
    </div>
  );
};

export default IndicatorsRadar;
