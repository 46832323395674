// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.indicatorsRadarDiv {
  width: 400px;
  height: 520px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.indicatorsRadarRow > .radarContainer {
  height: 100% !important;
  pointer-events: none;
}

.indicatorsRadarRow {
  height: 72%;
  width: 100%;
  min-width: 350px;
}

.indicatorsRadarLegend {
  width: 90%;
  height: 25%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Mobile/PersonalZone/Competency/IndicatorsRadar/IndicatorsRadar.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,WAAW;AACb","sourcesContent":[".indicatorsRadarDiv {\n  width: 400px;\n  height: 520px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.indicatorsRadarRow > .radarContainer {\n  height: 100% !important;\n  pointer-events: none;\n}\n\n.indicatorsRadarRow {\n  height: 72%;\n  width: 100%;\n  min-width: 350px;\n}\n\n.indicatorsRadarLegend {\n  width: 90%;\n  height: 25%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
