import { IonCol, IonRow } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import Input from "./TrainingPlanInput";
import "./Indicator.css";
import "react-dropdown/style.css";
import { Option } from "react-dropdown";
import Dropdown from "../../Shared/Dropdown/Dropdown";
import Stations from "./Stations";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/plansStore";
import { IPlansReducer } from "../../../redux/reducers/plans";
import {
  DELETE_INDICATOR,
  EDIT_INDICATOR,
  SET_INDICATOR,
} from "../../../redux/actions/trainingPlanActions";
import IIndicatorType from "../../../Interfaces/IIndicatorType";
import { useTranslation } from "react-i18next";
import PMIcon from "../../themeComponents/PMIcon";
import { trash } from "ionicons/icons";
import { units, validateInput } from "../DataPosting/inputValidations";
import {
  IElement,
  IIndicator,
  ITrainingType,
} from "../../../Interfaces/ITrainingPlan";
import { IIndicatorsPlanValue } from "../../../Interfaces/IPlanValues";
import useCheckSumErrors, {
  IErrorState,
} from "../../CustomHooks/useCheckSumErrors";
import { IndicatorsContext } from "../../../context/IndicatorsContext/IndicatorsContext";
import { TrainingTypesContext } from "../../../context/TrainingTypesContext/TrainingTypesContext";
import { ElementsContext } from "../../../context/ElementsContext/ElementsContext";

interface IIndicatorComp {
  indicator: IIndicator;
  typeOptions: IIndicatorType[];
  setIsErrorExist: React.Dispatch<React.SetStateAction<IErrorState>>;
}

const Indicator: React.FC<IIndicatorComp> = (props: IIndicatorComp) => {
  const [indicator, setIndicator] = useState<IIndicator>(props.indicator);

  const [options, setOptions] = useState<string[]>([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { indicators } = useContext(IndicatorsContext);
  const { trainingTypes } = useContext(TrainingTypesContext);
  const { elements } = useContext(ElementsContext);
  const editMode = useSelector<AppState, IPlansReducer>(
    (state) => state.plans
  ).editMode;
  const { errors, setErrors, elementsForDashboard } = useCheckSumErrors(
    props.setIsErrorExist,
    undefined,
    undefined,
    indicator
  );

  const updateIndicator = (indicator: IIndicatorsPlanValue) => {
    dispatch({ type: EDIT_INDICATOR, indicator: indicator });
  };
  useEffect(() => {
    setIndicator(props.indicator);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.indicator]);

  // Dropdown options list useEffect
  useEffect(() => {
    setOptions([]);

    props.typeOptions.map((type) =>
      setOptions((options) => [...options!, t(type.name.trim())])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.typeOptions]);

  // Handles the dropdown (indicator type) changes
  const nameChangeHandler = (arg: Option) => {
    let name: string = arg.label?.toString() ? arg.label?.toString() : "";
    let id: number = indicators.find(
      (indicatorType) => t(indicatorType.name) === arg.label
    )?.id!;
    let trainingTypesValues: ITrainingType[] = trainingTypes
      .filter((t) => t.indicatorId === id)
      .map((tt) => {
        let elementsValues: IElement[] = elements
          .filter((e) => e.trainingTypeId === tt.id && !e.isArchived)
          .map((e) => {
            return {
              elementId: e.id,
              indicatorId: id,
              trainingTypeId: tt.id,
              isForDashboard: false,
              weight: null,
              requiredThreshold: null,
              lowerThreshold: null,
              upperThreshold: null,
              viewOnly: false,
              id: e.id,
              isArchived: e.isArchived,
              isOnlyForDashboard: e.isOnlyForDashboard,
              name: e.name,
              units: e.units,
              description: e.description,
            };
          });
        return {
          elements: elementsValues,
          indicatorId: id,
          name: tt.name,
          trainingTypeId: tt.id,
          threshold: null,
          weight: null,
        };
      });
    // Resets the local indicator state
    setIndicator({
      ...indicator,
      indicatorId: id,
      name: name,
      weight: null,
      threshold: null,
      trainingTypes: trainingTypesValues,
    });

    // Updates the redux indicator state
    dispatch({
      type: SET_INDICATOR,
      indicator: {
        ...indicator,
        indicatorId: id,
        name: name,
        weight: null,
        threshold: null,
        trainingTypes: trainingTypesValues,
      },
      idToUpdate: indicator?.indicatorId,
    });
  };

  const onChangeIndicatorDetailsHandler: Function = (
    field: string,
    value: string
  ): void => {
    setErrors((prev: any) => ({
      ...prev,
      [field]: validateInput(value, field),
    }));
    setIndicator((prev) =>
      prev
        ? {
            ...prev,
            [field]: value,
          }
        : { ...indicator!, [field]: value }
    );
  };

  const onDeleteIndicatorHandler = () => {
    dispatch({ type: DELETE_INDICATOR, id: indicator?.indicatorId });
  };

  return indicator ? (
    <IonCol size="12" className="indicatorGrid">
      <IonRow
        className="indicatorAttributesRow "
        onBlur={() => {
          indicator && updateIndicator(indicator);
        }}
      >
        <IonCol
          size="2.93"
          className={`attributesCol  ${editMode ? "" : "viewMode"}`}
        >
          <Dropdown
            options={options}
            onChange={nameChangeHandler}
            value={t(indicator?.name.trim()!)}
            placeholder={t("measurement")}
            controlClassName="Dropdown tpDropdown"
            arrowClassName="DropdownArrow tpDropdownArrow"
            placeholderClassName="indicatorDropdownPlaceholder"
            menuClassName="DropdownMenu"
            disabled={!editMode}
          ></Dropdown>
        </IonCol>
        <IonCol className="indicatorCol" size="2.6">
          <Input
            inputType="number"
            placeholder={t("measurementWeight")}
            inputName="weight"
            inputValue={indicator?.weight}
            onChangeHandler={(field: string, value: string) =>
              onChangeIndicatorDetailsHandler(field, value, units.Weight, 0)
            }
            length="short"
            isPercentageInput
            isDisabled={!editMode}
            cssClass={`tpInput ${
              errors.weight?.length ? "tpInputError" : null
            }`}
          ></Input>
        </IonCol>
        <IonCol className="indicatorCol" size="2.58">
          <Input
            inputType="number"
            placeholder={t("thresholdGrade")}
            inputName="threshold"
            inputValue={indicator?.threshold}
            onChangeHandler={(field: string, value: string) =>
              onChangeIndicatorDetailsHandler(field, value, units.Grade, 1)
            }
            length="short"
            isDisabled={!editMode}
            cssClass={`tpInput ${
              errors.threshold?.length ? "tpInputError" : null
            }`}
          ></Input>
        </IonCol>
        <IonCol className="indicatorTrashCol">
          {editMode ? (
            <PMIcon
              size="large"
              color="light"
              iconSrc={trash}
              onClick={onDeleteIndicatorHandler}
              cssClass="clickable tpTrashIcon"
            ></PMIcon>
          ) : null}
        </IonCol>
      </IonRow>
      <IonRow>
        {indicator?.name.length ? (
          <Stations
            trainingTypes={indicator.trainingTypes}
            setIsErrorExist={props.setIsErrorExist}
            elementsForDashboard={elementsForDashboard}
          ></Stations>
        ) : null}
      </IonRow>
    </IonCol>
  ) : null;
};

export default Indicator;
