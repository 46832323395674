import { useState } from "react";
import "./MobileFIreSupport.css";
import { useHistory } from "react-router-dom";
import FireSupportComponent from "../../../Desktop/DataPosting/FIreSupportComponent";
import DataPostingMobileTemplate from "../../../Mobile/DataPosting/DataPostingMobileTemplate";
import useMobileDataPostingParams from "../../../CustomHooks/useMobileDataPostingParams";

const MobileFIreSupport = (): JSX.Element => {
  const { trainees, trainingTypeId, stationName } =
    useMobileDataPostingParams();
  const history = useHistory();

  const [isComplete, setIsComplete] = useState<boolean>(false);

  const backHandler = () => {
    history.goBack();
  };

  return (
    <DataPostingMobileTemplate
      stationName={stationName}
      traineeName={trainees[0]?.name || ""}
      isSendDisabled={!isComplete}
    >
      <FireSupportComponent
        traineeId={Number(trainees[0]?.id)}
        afterPostHandler={backHandler}
        isComplete={isComplete}
        setIsComplete={setIsComplete}
      ></FireSupportComponent>
    </DataPostingMobileTemplate>
  );
};

export default MobileFIreSupport;
