import React, { FC, useContext, useEffect, useState } from "react";
import "./ElementsInStation.css";
import IPlan from "../../../../../Interfaces/IPlan";
import { MAX_SELECTED } from "../../../../../Configurations/consts";
import IForceTreeNode from "../../../../../Interfaces/IForceTreeNode";
import IFinalGradeDisplay from "../../../../../Interfaces/IFinalGradeDisplay";
import IForceFromOrbat from "../../../../../Interfaces/IForceFromOrbat";
import IStationsChart from "../../../../../Interfaces/IStationsChart";
import { useTranslation } from "react-i18next";
import ILabel from "../../../../../Interfaces/ILabel";
import IThreshold from "../../../../../Interfaces/IThreshold";
import { useHistory } from "react-router-dom";
import { useWebsocketTagging } from "../../../../../services/siteManagementSocket";
import { UserContext } from "../../../../../context/UserContext/userContext";
import { IonPage } from "@ionic/react";
import EExportMethod from "../../../../../Interfaces/EExportMethod";
interface IProps {
  orbatForces: IForceTreeNode[];
  checkedForces: IForceFromOrbat[];
  setChecked: (forces: IForceTreeNode[]) => void;
  exportToCsv: (
    imageUrl: string,
    exportType: number,
    exportMethod: EExportMethod
  ) => void;
  fromTab: boolean;
  isAdmin?: boolean | true;
  exportHandler: () => void;
  downloadLoading: boolean;
  thresholds: IThreshold[];
  labels: ILabel[];
  selectedPlan: IPlan;
  trainingTypesGrades: IStationsChart[];
  finalGrades: IFinalGradeDisplay[];
  newForces: IForceFromOrbat[];
  forcesToRemove: IForceFromOrbat[];
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedPlanHolder: React.Dispatch<
    React.SetStateAction<IPlan | undefined>
  >;
  setIsToRefresh: (state: boolean) => void;
  setForceTaggingRemoved: (forceId: number | undefined) => void;
  allPlansOptions: IPlan[];
  onPlanSelectedHandler: (option: IPlan) => void;
  isDrawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ElementsInStation: FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation();
  const history: any = useHistory();
  const {
    orbatForces,
    setChecked,
    fromTab,
    selectedPlan,
    newForces,
    forcesToRemove,
    checkedForces,
    setForceTaggingRemoved,
    onPlanSelectedHandler,
    setSelectedPlanHolder,
  } = props;

  const [myNewForces, setMyNewForces] = useState<IForceFromOrbat[]>();

  useEffect(() => {
    setMyNewForces((prev) => {
      if (!prev) return checkedForces;
      return newForces;
    });
  }, [newForces, checkedForces]);

  const [hashtagsModalData, setHashtagsModalData] = useState<{
    isOpen: boolean;
    plan: IPlan | undefined;
    forceId: number;
    trainingTypeId: number;
    forceName: string;
    trainingTypeName: string;
  }>({
    isOpen: false,
    plan: undefined,
    forceId: -1,
    trainingTypeId: -1,
    forceName: "",
    trainingTypeName: "",
  });

  const [taggedSoldiers, setTaggedSoldiers] = useState<IForceTreeNode[]>();
  const [tagging, setTagging] = useState<IForceTreeNode | undefined>();

  const [isRemove, setIsRemove] = useState<boolean>(false);

  const taggingMessage: IForceTreeNode | undefined = useWebsocketTagging();
  const { isTaggingActive } = useContext(UserContext);

  useEffect(() => {
    if (tagging && isTaggingActive) {
      setTaggedSoldiers((prev: IForceTreeNode[] | undefined) => {
        if (
          !prev?.find((soldier: IForceTreeNode) => soldier.id === tagging.id)
        ) {
          if (prev) {
            if (prev.length >= MAX_SELECTED) {
              prev.shift();
            }
            return [...prev, { ...tagging, id: +tagging.id }];
          } else {
            return [
              {
                ...tagging,
                id: +tagging.id,
              },
            ];
          }
        } else {
          return prev;
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagging]);

  useEffect(() => {
    if (taggingMessage) {
      setTagging(taggingMessage);
    }
  }, [taggingMessage]);

  useEffect(() => {
    if (selectedPlan && isTaggingActive) {
      if (
        forcesToRemove &&
        forcesToRemove[0] &&
        taggedSoldiers?.find(
          (soldier: IForceTreeNode) => soldier.id === forcesToRemove![0].id
        )
      ) {
        setIsRemove(true);
        setTaggedSoldiers((prev: IForceTreeNode[] | undefined) =>
          prev?.filter(
            (prev: IForceTreeNode) => prev.id !== forcesToRemove[0].id
          )
        );
      }
    }
  }, [forcesToRemove, isTaggingActive, selectedPlan, taggedSoldiers]);

  useEffect(() => {
    if (history.location.state) {
      let planId = history.location.state?.data;
      if (history.location.state.taggingMessage) {
        setTagging(history.location.state.taggingMessage);
      }
      if (history.location.state.taggingMessage === undefined) {
        if (Number(selectedPlan?.id) !== Number(planId)) {
          let newSelectedPlan = props.allPlansOptions.find(
            (plan) => Number(plan.id) === Number(planId)
          );
          if (newSelectedPlan) onPlanSelectedHandler(newSelectedPlan);
          setSelectedPlanHolder(selectedPlan);
          setMyNewForces(checkedForces);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.state]);

  useEffect(() => {
    if (fromTab && !history.location.state) {
      setMyNewForces(checkedForces);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromTab]);

  const limitToMaxPremittedSelectedForces = (
    selectedForces: IForceTreeNode[]
  ) => {
    if (selectedForces.length > MAX_SELECTED) {
      setForceTaggingRemoved(selectedForces.shift()?.id);
    }
    return selectedForces;
  };

  useEffect(() => {
    if (taggedSoldiers && !isRemove) {
      let temp: IForceTreeNode[] = taggedSoldiers?.filter(
        (force: IForceTreeNode) =>
          !orbatForces?.find((OForce) => OForce.id === force.id)
      );
      let forcesToCheck = orbatForces ? [...orbatForces, ...temp] : temp;
      setChecked(limitToMaxPremittedSelectedForces(forcesToCheck));
    } else if (taggedSoldiers && isRemove) {
      setIsRemove(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taggedSoldiers, isRemove]);
  return (
    // <PerformanceTemplate
    //   loadingText={
    //     loading ? t("loadingData") : downloadLoading ? t("downloadFile") : ""
    //   }
    //   chart={
    //     <div className="barD">
    //       <div className="grapsWrap"></div>
    //     </div>
    //   }
    //   iButtonsDisabled={!checkedForces.length}
    //   finalGrades={finalGrades}
    //   refreshButtonText={t("refresh")}
    //   exportButtonText={t("export")}
    //   onClickRefreshButton={() => setIsToRefresh(true)}
    //   onClickExportButton={() => getImageAndSendToCsv()}
    //   isDrawerOpen={isDrawerOpen}
    //   loading={loading || downloadLoading}
    //   orbatForces={orbatForces}
    //   selectedPlan={selectedPlan}
    //   plansOptions={allPlansOptions}
    //   onPlanSelectedHandler={onPlanSelectedHandler}
    //   setChecked={setChecked}
    //   setDrawerOpen={setDrawerOpen}
    //   title={t("hashtags")}
    // ></PerformanceTemplate>

    <IonPage className="notifications">
      <div className="coming-soon">{t("comingSoon")}</div>
    </IonPage>
  );
};

export default ElementsInStation;
