// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.elementsResultList {
  height: min-content;
  overflow-y: auto;
  background-color: #3b3d42;
  padding: 0;
}
.elementsResultWrap {
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  height: min-content;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Desktop/Gorilla/ElementsResultWrap.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,yBAAyB;EACzB,UAAU;AACZ;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,oBAAoB;EACpB,mBAAmB;AACrB","sourcesContent":[".elementsResultList {\n  height: min-content;\n  overflow-y: auto;\n  background-color: #3b3d42;\n  padding: 0;\n}\n.elementsResultWrap {\n  display: flex;\n  flex-direction: column;\n  padding-bottom: 16px;\n  height: min-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
