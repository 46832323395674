import { FC } from "react";

import "./InstructorFeedback.css";
import { useHistory } from "react-router-dom";
import InstructorFeedbackComponent from "./InstructorFeedbackComponent";
import DataPostingMobileTemplate from "../../DataPosting/DataPostingMobileTemplate";
import useMobileDataPostingParams from "../../../CustomHooks/useMobileDataPostingParams";

const InstructorFeedback: FC = () => {
  const history = useHistory();
  const { trainees, trainingTypeId, stationName } =
    useMobileDataPostingParams();
  const backHandler = () => {
    history.goBack();
  };

  return (
    <DataPostingMobileTemplate
      stationName={stationName}
      traineeName={trainees[0]?.name || ""}
    >
      <InstructorFeedbackComponent
        traineeId={Number(trainees[0]?.id)}
        afterPostHandler={backHandler}
        trainingTypeId={Number(trainingTypeId)}
      ></InstructorFeedbackComponent>
    </DataPostingMobileTemplate>
  );
};

export default InstructorFeedback;
