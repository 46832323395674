// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.passModalWrap {
  height: 100%;
  display: flex;
  background-color: var(--ion-color-primary);
  border-radius: 4px;
  z-index: 120 !important;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/AdminPassword/AdminPasswordModal.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,0CAA0C;EAC1C,kBAAkB;EAClB,uBAAuB;EACvB,uBAAuB;AACzB","sourcesContent":[".passModalWrap {\n  height: 100%;\n  display: flex;\n  background-color: var(--ion-color-primary);\n  border-radius: 4px;\n  z-index: 120 !important;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
