import React, { FC, useContext, useEffect, useRef, useState } from "react";
import "./PerformanceStations.css";
import BarChart from "../../../../../components/Shared/Charts/Bar/BarChart";
import IPlan from "../../../../../Interfaces/IPlan";
import { MAX_SELECTED } from "../../../../../Configurations/consts";
import IForceTreeNode from "../../../../../Interfaces/IForceTreeNode";
import IFinalGradeDisplay from "../../../../../Interfaces/IFinalGradeDisplay";
import IForceFromOrbat from "../../../../../Interfaces/IForceFromOrbat";
import IStationsChart from "../../../../../Interfaces/IStationsChart";
import { useTranslation } from "react-i18next";
import ILabel from "../../../../../Interfaces/ILabel";
import IThreshold from "../../../../../Interfaces/IThreshold";
import { useHistory } from "react-router-dom";
import EExportType from "../../../../../Interfaces/EExportType";
import HashtagsModal from "../../../../../components/Desktop/Hashtags/HashtagsModal";
import { useWebsocketTagging } from "../../../../../services/siteManagementSocket";
import { UserContext } from "../../../../../context/UserContext/userContext";
import PerformanceTemplate from "../../performanceTemplate";
import EExportMethod from "../../../../../Interfaces/EExportMethod";
import { toPng } from "html-to-image";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import PMLabel from "../../../../../components/themeComponents/PMLabel";
import classNames from "classnames";
import PMIcon from "../../../../../components/themeComponents/PMIcon";
import EIconsSrc from "../../../../../Interfaces/EIconsSrc";
import { translateString } from "../../../../../services/helpers";
import Legend from "../../../../../components/Desktop/VerticalProgressBar/Legend";

interface IProps {
  orbatForces: IForceTreeNode[];
  checkedForces: IForceFromOrbat[];
  setChecked: (forces: IForceTreeNode[]) => void;
  exportToCsv: (
    imageUrl: string,
    exportType: number,
    exportMethod: EExportMethod
  ) => void;
  fromTab: boolean;
  isAdmin?: boolean | true;
  exportHandler: () => void;
  downloadLoading: boolean;
  thresholds: IThreshold[];
  labels: ILabel[];
  selectedPlan: IPlan;
  trainingTypesGrades: IStationsChart[];
  finalGrades: IFinalGradeDisplay[];
  newForces: IForceFromOrbat[];
  forcesToRemove: IForceFromOrbat[];
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedPlanHolder: React.Dispatch<
    React.SetStateAction<IPlan | undefined>
  >;
  setIsToRefresh: (state: boolean) => void;
  setForceTaggingRemoved: (forceId: number | undefined) => void;
  allPlansOptions: IPlan[];
  onPlanSelectedHandler: (option: IPlan) => void;
  isDrawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PerformanceStations: FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation();
  const history: any = useHistory();
  const {
    orbatForces,
    setChecked,
    fromTab,
    downloadLoading,
    thresholds,
    labels,
    selectedPlan,
    trainingTypesGrades,
    finalGrades,
    newForces,
    forcesToRemove,
    checkedForces,
    setIsToRefresh,
    loading,
    setForceTaggingRemoved,
    allPlansOptions,
    onPlanSelectedHandler,
    setSelectedPlanHolder,
    isDrawerOpen,
    setDrawerOpen,
  } = props;

  const [myNewForces, setMyNewForces] = useState<IForceFromOrbat[]>();

  useEffect(() => {
    setMyNewForces((prev) => {
      if (!prev) return checkedForces;
      return newForces;
    });
  }, [newForces, checkedForces]);

  const [hashtagsModalData, setHashtagsModalData] = useState<{
    isOpen: boolean;
    plan: IPlan | undefined;
    forceId: number;
    trainingTypeId: number;
    forceName: string;
    trainingTypeName: string;
  }>({
    isOpen: false,
    plan: undefined,
    forceId: -1,
    trainingTypeId: -1,
    forceName: "",
    trainingTypeName: "",
  });

  const [taggedSoldiers, setTaggedSoldiers] = useState<IForceTreeNode[]>();
  const [tagging, setTagging] = useState<IForceTreeNode | undefined>();
  const [isRemove, setIsRemove] = useState<boolean>(false);
  const taggingMessage: IForceTreeNode | undefined = useWebsocketTagging();
  const { isTaggingActive } = useContext(UserContext);
  const [selectedTrainingTypeBar, setSelectedTrainingTypeBar] = useState<{
    forceId: number;
    trainingTypeId: number;
    trainingTypeName: string;
  }>();

  useEffect(() => {
    if (tagging && isTaggingActive) {
      setTaggedSoldiers((prev: IForceTreeNode[] | undefined) => {
        if (
          !prev?.find((soldier: IForceTreeNode) => soldier.id === tagging.id)
        ) {
          if (prev) {
            if (prev.length >= MAX_SELECTED) {
              prev.shift();
            }
            return [...prev, { ...tagging, id: +tagging.id }];
          } else {
            return [
              {
                ...tagging,
                id: +tagging.id,
              },
            ];
          }
        } else {
          return prev;
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagging]);

  useEffect(() => {
    if (taggingMessage) {
      setTagging(taggingMessage);
    }
  }, [taggingMessage]);

  useEffect(() => {
    if (selectedPlan && isTaggingActive) {
      if (
        forcesToRemove &&
        forcesToRemove[0] &&
        taggedSoldiers?.find(
          (soldier: IForceTreeNode) => soldier.id === forcesToRemove![0].id
        )
      ) {
        setIsRemove(true);
        setTaggedSoldiers((prev: IForceTreeNode[] | undefined) =>
          prev?.filter(
            (prev: IForceTreeNode) => prev.id !== forcesToRemove[0].id
          )
        );
      }
    }
  }, [forcesToRemove, isTaggingActive, selectedPlan, taggedSoldiers]);

  useEffect(() => {
    if (history.location.state) {
      let planId = history.location.state?.data;
      if (history.location.state.taggingMessage) {
        setTagging(history.location.state.taggingMessage);
      }
      if (history.location.state.taggingMessage === undefined) {
        if (Number(selectedPlan?.id) !== Number(planId)) {
          let newSelectedPlan = allPlansOptions.find(
            (plan) => Number(plan.id) === Number(planId)
          );
          if (newSelectedPlan) onPlanSelectedHandler(newSelectedPlan);
          setSelectedPlanHolder(selectedPlan);
          setMyNewForces(checkedForces);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.state]);

  useEffect(() => {
    if (fromTab && !history.location.state) {
      setMyNewForces(checkedForces);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromTab]);

  const limitToMaxPremittedSelectedForces = (
    selectedForces: IForceTreeNode[]
  ) => {
    if (selectedForces.length > MAX_SELECTED) {
      setForceTaggingRemoved(selectedForces.shift()?.id);
    }
    return selectedForces;
  };

  const getImageAndSendToCsv = async (exportMethod: EExportMethod) => {
    let imageUrl = await toPng(document.getElementById("csvBarImage")!);
    props.exportToCsv(imageUrl, EExportType.stations, exportMethod);
  };

  const onBarClick = (
    plan: IPlan | undefined,
    forceId: number,
    trainingTypeId: number,
    forceName: string,
    trainingTypeName: string
  ) => {
    setSelectedTrainingTypeBar({ forceId, trainingTypeId, trainingTypeName });
  };
  useEffect(() => {
    if (taggedSoldiers && !isRemove) {
      let temp: IForceTreeNode[] = taggedSoldiers?.filter(
        (force: IForceTreeNode) =>
          !orbatForces?.find((OForce) => OForce.id === force.id)
      );
      let forcesToCheck = orbatForces ? [...orbatForces, ...temp] : temp;
      setChecked(limitToMaxPremittedSelectedForces(forcesToCheck));
    } else if (taggedSoldiers && isRemove) {
      setIsRemove(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taggedSoldiers, isRemove]);
  const selectedRef = useRef<any>(null);

  useEffect(() => {
    if (selectedRef.current) {
      // Use requestAnimationFrame to ensure the DOM is ready
      requestAnimationFrame(() => {
        selectedRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      });
    }
  }, [selectedTrainingTypeBar]);
  const StationsTooltips = (//component to display the tooltips details in the left panel
    <IonGrid className="stationsTooltipsColumn">
      <IonRow className="stationDetailsForceRow">
        <PMIcon
          iconSrc={EIconsSrc.CLOSE}
          onClick={() => setSelectedTrainingTypeBar(undefined)}
          size="large"
        ></PMIcon>
      </IonRow>
      <IonRow className="stationDetailsForceRow">
        <PMLabel
          fontFamily="SemiBold"
          fontSize="xxLarge"
          fontColor="light"
          cssClass="stationDetailsTitle"
        >
          {selectedTrainingTypeBar?.trainingTypeName}
        </PMLabel>
      </IonRow>
      <IonCol className="forceDetailsRow scrollM">
        {trainingTypesGrades
          .filter(
            //filter only the attended forces
            (forceData) =>
              forceData.gradesArray?.find(
                (trainingTypesGrades) =>
                  trainingTypesGrades.id ===
                  selectedTrainingTypeBar?.trainingTypeId
              )?.grade
          )
          .map((forceData, index) => {
            let trainingTypeData = forceData.gradesArray?.find(
              (trainingTypesGrades) =>
                trainingTypesGrades.id ===
                selectedTrainingTypeBar?.trainingTypeId
            );
            const isSelected =
              selectedTrainingTypeBar?.forceId === forceData.id;

            return (
              <IonCol
                key={index}
                className={classNames("tooltipsSection", {
                  selected: isSelected,
                })}
                ref={isSelected ? selectedRef : null} // Attach ref if selected in order to scroll to its place
              >
                <IonRow className="stationDetailsForceRow">
                  <Legend
                    color={forceData.forceColor || ""}
                    forceName={forceData.name}
                    forceId={forceData.id}
                    fontsize={"xxLarge"}
                  />
                </IonRow>
                <IonRow className="stationDetailsForceRow">
                  <PMLabel
                    fontColor="light"
                    fontFamily="Regular"
                    fontSize="large"
                    cssClass="stationDetailsAvgGrade"
                  >
                    {`${t("avgGrade")}: ${Math.round(
                      trainingTypeData?.grade || 0
                    )}`}
                  </PMLabel>
                </IonRow>

                {trainingTypeData?.tooltipResults.tooltipElementsResults
                  .filter((tooltip) => tooltip.value)
                  .map((tooltip, index) => (
                    <IonRow key={index} className="selectedTooltipsRow">
                      <PMLabel fontColor="light">
                        {`${t(tooltip.name)}: ${translateString(
                          tooltip.value,
                          t
                        )}`}
                      </PMLabel>
                    </IonRow>
                  ))}
                <IonRow className="selectedTooltipsRow">
                  <PMLabel fontColor="light">
                    {`${t("standardDeviation")}: ${Math.round(
                      trainingTypeData?.tooltipResults.standardDeviation || 0
                    )}`}
                  </PMLabel>
                </IonRow>
              </IonCol>
            );
          })}
      </IonCol>
    </IonGrid>
  );
  return (
    <PerformanceTemplate
      loadingText={
        loading ? t("loadingData") : downloadLoading ? t("downloadFile") : ""
      }
      chart={
        <div className="barD">
          <div className="grapsWrap">
            <HashtagsModal
              isOpen={hashtagsModalData.isOpen}
              plan={hashtagsModalData.plan}
              forceId={hashtagsModalData.forceId}
              trainingTypeId={hashtagsModalData.trainingTypeId}
              forceName={hashtagsModalData.forceName}
              trainingTypeName={hashtagsModalData.trainingTypeName}
              onModalClose={() =>
                setHashtagsModalData({
                  ...hashtagsModalData,
                  isOpen: false,
                  plan: undefined,
                })
              }
            ></HashtagsModal>
            <BarChart
              id="csvBarImage"
              barChartData={trainingTypesGrades}
              thresholds={thresholds}
              labels={labels}
              selectedForces={checkedForces}
              newForces={myNewForces}
              forcesToRemove={forcesToRemove}
              barsType={"trainingTypes"}
              plan={selectedPlan}
              isDesktop
              onBarClick={onBarClick}
            />
          </div>
        </div>
      }
      detailsComponent={selectedTrainingTypeBar ? StationsTooltips : undefined}
      iButtonsDisabled={!checkedForces.length}
      refreshButtonText={t("refresh")}
      exportButtonText={t("export")}
      onClickRefreshButton={() => setIsToRefresh(true)}
      onClickExportButton={(exportMethod) => getImageAndSendToCsv(exportMethod)}
      isDrawerOpen={isDrawerOpen}
      loading={loading || downloadLoading}
      orbatForces={orbatForces}
      selectedPlan={selectedPlan}
      plansOptions={allPlansOptions}
      onPlanSelectedHandler={onPlanSelectedHandler}
      setChecked={setChecked}
      setDrawerOpen={setDrawerOpen}
      title={t("stations")}
    ></PerformanceTemplate>
  );
};

export default PerformanceStations;
