import React, { useContext, useEffect, useState } from "react";

import { IonCol, IonGrid, IonPage, IonRow } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { GorillaTrainingContext } from "../../../../context/GorillaTrainingContext/GorillaTrainingContext";
import {
  EGorillaForces,
  EGorillaTrainings,
  gorillaTrainingTypes,
} from "../../../../Interfaces/Gorilla/GorillaTrainings";
import { ITabOptions } from "../../../../Interfaces/IDataHistory";
import SegmentsMenu from "../../../../pages/Mobile/DataHistory/SegmentsMenu";
import NCOHook, { ENCOSteps } from "../../../CustomHooks/NCOHook";
import ObserverHook from "../../../CustomHooks/ObserverHook";
import PromptHook from "../../../CustomHooks/PromptHook";
import SingleGunnerHeatsHook from "../../../CustomHooks/SingleGunnerHeatsHook";
import { dataPostingRoutes } from "../../../CustomHooks/useDataPostingTabs";
import useNavigation from "../../../CustomHooks/useNavigation";
import VerticalSteps from "../../../Shared/SPIKEGorilla/VerticalSteps";
import WarningModal from "../../../Shared/SPIKEGorilla/WarningModal";
import customToast from "../../../Shared/Toast/CustomToast";
import PMButton from "../../../themeComponents/PMButton";
import PMLabel from "../../../themeComponents/PMLabel";
import "./FrameTrainingDesktop.css";
import HasifaTkifaEndPage, {
  EEndPageState,
} from "./HasifaTkifaEndPage/HasifaTkifaEndPage";
import NCODesktopComponent from "./NCO/NCODesktopComponent";
import ObserverDesktopComponent from "./Observer/ObserverDesktopComponent";
import SingleGunnerDesktopComponent from "./SingleGunner/SingleGunnerDesktopComponent";
import SingleGunnerPanel from "./SingleGunner/SingleGunnerPanel";
import { EObserverSteps } from "../../../../Interfaces/Gorilla/IObserver";
interface ITrainingOption extends ITabOptions {
  force: EGorillaForces;
}
const FrameTrainingDesktop: React.FC = (): JSX.Element => {
  const { t } = useTranslation();

  const [selectedTraining, setSelectedTraining] = useState<
    ITrainingOption | undefined
  >({
    tabId: 0,
    force: EGorillaForces.SingleGunner,
    title: "",
    url: "",
  });
  const { gorillaTrainingsForces } = useContext(GorillaTrainingContext);
  const { replace } = useNavigation();
  const [frameTrainings, setFrameTrainings] = useState<ITrainingOption[]>([]);
  const [isFinishedAllTrainingTabOpen, setIsFinishedAllTrainingTabOpen] =
    useState<boolean>(false);
  const [
    isToEndFrameTrainingConfirmation,
    setIsToEndFrameTrainingConfirmation,
  ] = useState<boolean>(false);
  const [frameTrainingState, setFrameTrainingState] = useState<EEndPageState>(
    EEndPageState.incomplete
  );
  useEffect(() => {
    setFrameTrainings(
      gorillaTrainingTypes
        .filter((force) => gorillaTrainingsForces[force] !== undefined)
        .map((training, index) => ({
          tabId: index,
          title: t(training),
          url: "",
          force: training,
        }))
    );
  }, [gorillaTrainingTypes, gorillaTrainingsForces]);
  useEffect(() => {
    if (frameTrainings.length) setSelectedTraining(frameTrainings[0]);
  }, [frameTrainings]);
  const nextTraining = () => {
    //ok but need to fix the issue of something didn't happened
    setSelectedTraining((prev) => {
      let nextTrainingIndex =
        frameTrainings.findIndex((training) => training.tabId === prev?.tabId) +
        1;
      setIsFinishedAllTrainingTabOpen(
        frameTrainings[nextTrainingIndex] === undefined
      );
      return frameTrainings[nextTrainingIndex]
        ? frameTrainings[nextTrainingIndex]
        : prev;
    });
  };
  const {
    malfunctionsOperation,
    saveHeatAndMoveToNext,
    setMalfunctionOperation,
    setShootingExecution,
    setSystemEncryption,
    setTargetsPropertiesResults,
    shootingExecution,
    systemEncryption,
    targetsPropertiesResults,
    activeHeatId,
    mainNotes: mainNotesGunner,
    resetHeat,
    addHeat,
    deleteHeat,
    setActiveHeat,
    setActiveHeatId,
    currentStepType,
    heatsArray,
    setCurrentStepType,
    singleGunnerSteps,
    addNote: addNoteGunner,
    deleteNote: deleteNoteGunner,
    editNote: editNoteGunner,
    selectedLuncherType,
    endTrainingHandlerAfterConfirmation:
      endGunnerTrainingHandlerAfterConfirmation,
    isTrainingEnd: isSingleGunnerTrainingEnd,
  } = SingleGunnerHeatsHook(true, nextTraining);

  const {
    openAndCover,
    setWorkRoutine,
    workRoutine,
    settingStation,
    conductingQuality,
    targetAcquisition,
    setTargetAcquisition,
    setOpenAndCover,
    setSettingStation,
    setConductingQuality,
    mainNotes: mainNotesObserver,
    isTrainingEnd: isObserverTrainingEnd,
    observerSteps,
    observerCurrentStepType,
    setObserverCurrentStepType,
    addNote: addNoteObserver,
    deleteNote: deleteNoteObserver,
    editNote: editNoteObserver,
    endTrainingHandlerMOAfterConfirmation:
      endObserverTrainingHandlerMOAfterConfirmation,
  } = ObserverHook(true);

  const {
    nco,
    setNco,
    isTrainingEnd: isNCOTrainingEnd,
    ncoSteps,
    ncoCurrentStepType,
    setNCOCurrentStepType,
    endTrainingHandlerAfterConfirmation: endNCOTrainingHandlerAfterConfirmation,
  } = NCOHook(true);

  useEffect(() => {
    if (
      isSingleGunnerTrainingEnd &&
      (isObserverTrainingEnd ||
        !gorillaTrainingsForces[EGorillaForces.Observer]) &&
      (isNCOTrainingEnd || !gorillaTrainingsForces[EGorillaForces.NCO])
    ) {
      customToast.success(
        `${t("dataSendSuccess")} ${t("for")} ${t(
          EGorillaTrainings.FrameTraining
        )}`
      );
      replace(`${dataPostingRoutes.hasifaTkifa}`);
    }
  }, [isNCOTrainingEnd, isObserverTrainingEnd, isSingleGunnerTrainingEnd]);

  PromptHook(
    !(
      isSingleGunnerTrainingEnd &&
      (isObserverTrainingEnd ||
        !gorillaTrainingsForces[EGorillaForces.Observer]) &&
      (isNCOTrainingEnd || !gorillaTrainingsForces[EGorillaForces.NCO])
    ),
    "hasifaTkifaLeave"
  );

  const endFrameTraining = () => {
    setIsToEndFrameTrainingConfirmation(false);
    endGunnerTrainingHandlerAfterConfirmation();
    endObserverTrainingHandlerMOAfterConfirmation();
    endNCOTrainingHandlerAfterConfirmation();
  };
  useEffect(() => {
    //set frame training steps
    let isComplete = true;

    const checkStepsCompletion = (
      steps: Record<any, { isCompleted: boolean; isPartlyCompleted: boolean }>
    ) => {
      let hasPartlyCompletedStep = false;
      for (const [stepType, step] of Object.entries(steps)) {
        if ((Number(stepType) as EObserverSteps) !== EObserverSteps.MainNotes) {
          if (!step.isCompleted) {
            isComplete = false;
          }
          if (step.isPartlyCompleted) {
            hasPartlyCompletedStep = true;
          }
        }
      }
      return hasPartlyCompletedStep;
    };

    const ncoTrainingExists = frameTrainings.some(
      (training) => training.force === EGorillaForces.NCO
    );
    const observerTrainingExists = frameTrainings.some(
      (training) => training.force === EGorillaForces.Observer
    );

    let ncoPartlyComplete =
      !ncoTrainingExists || checkStepsCompletion(ncoSteps);
    let observerPartlyComplete =
      !observerTrainingExists || checkStepsCompletion(observerSteps);

    const hasCompleteHeat = heatsArray.some((heat) => heat.isCompleted);
    const allHeatsComplete = heatsArray.every((heat) => heat.isCompleted);

    if (!allHeatsComplete) {
      isComplete = false;
    }

    const hasPartlyCompleteRequirement =
      ncoPartlyComplete && observerPartlyComplete && hasCompleteHeat;

    setFrameTrainingState(
      isComplete
        ? EEndPageState.fullCompleted
        : hasPartlyCompleteRequirement
        ? EEndPageState.partlyCompleted
        : EEndPageState.incomplete
    );
  }, [ncoSteps, heatsArray, observerSteps, frameTrainings]);
  useEffect(() => {
    setIsFinishedAllTrainingTabOpen(false);
  }, [selectedTraining]);
  return (
    <IonPage>
      <IonGrid className={"FTDContainerWrap"}>
        <WarningModal
          acceptText={`${t("yes")}, ${t("finish")}`}
          onDecline={() => setIsToEndFrameTrainingConfirmation(false)}
          onAccept={endFrameTraining}
          isOpen={isToEndFrameTrainingConfirmation}
          title="endingTraining"
          warningText="endTrainingConfirmation"
          warningQuestion={`${t("endTrainingConfirmationQuestion")} ${t(
            EGorillaTrainings.FrameTraining
          )}?`}
        ></WarningModal>
        <IonCol
          size="3"
          className=" firstCol scrollS"
          onClick={() => setIsFinishedAllTrainingTabOpen(false)}
        >
          <IonRow className="titleRowHT">
            <PMLabel fontFamily="Regular" fontSize="xxLarge" fontColor="light">
              {t(EGorillaTrainings.FrameTraining)}
            </PMLabel>
          </IonRow>
          <IonRow className="FRDRow segmentRow">
            <SegmentsMenu
              segmentsOptions={frameTrainings}
              selectedSegment={selectedTraining}
              setSelectedSegment={setSelectedTraining}
            />
          </IonRow>
          <IonRow className="nameRowHT">
            {selectedTraining?.force ? (
              <PMLabel
                fontFamily="Regular"
                fontSize="xxLarge"
                fontColor="light"
              >
                {gorillaTrainingsForces[selectedTraining?.force]?.name}
              </PMLabel>
            ) : null}
          </IonRow>
          <IonRow className="FRDRow SingleGunnerPanelRow">
            {selectedTraining?.force === EGorillaForces.SingleGunner ? (
              <SingleGunnerPanel
                activeHeatId={activeHeatId}
                currentStepType={currentStepType}
                heatsArray={heatsArray}
                resetHeat={resetHeat}
                setActiveHeat={setActiveHeat}
                setActiveHeatId={setActiveHeatId}
                setCurrentStepType={setCurrentStepType}
                singleGunnerSteps={singleGunnerSteps}
                isDisableAddHeats={isSingleGunnerTrainingEnd}
                addHeat={addHeat}
                deleteHeat={deleteHeat}
              />
            ) : selectedTraining?.force === EGorillaForces.Observer ? (
              <VerticalSteps
                activeStep={observerCurrentStepType}
                steps={Object.values(observerSteps)}
                onClickHandler={(id: any) => setObserverCurrentStepType(id)}
              />
            ) : selectedTraining?.force === EGorillaForces.NCO ? (
              <VerticalSteps
                activeStep={ncoCurrentStepType}
                steps={Object.values(ncoSteps)}
                onClickHandler={(id: any) => setNCOCurrentStepType(id)}
              />
            ) : null}
          </IonRow>
          <IonRow className="endTrainingRow">
            <PMButton
              onClickHandler={() => setIsToEndFrameTrainingConfirmation(true)}
              color="orange"
              size="large"
              label={{ fontColor: "dark", fontSize: "medium" }}
              isDisabled={
                frameTrainingState === EEndPageState.incomplete ||
                isNCOTrainingEnd ||
                isObserverTrainingEnd ||
                isSingleGunnerTrainingEnd
              }
            >
              {t("endTraining")}
            </PMButton>
          </IonRow>
        </IonCol>
        <IonCol className="FTDContainer">
          {isFinishedAllTrainingTabOpen ? (
            <HasifaTkifaEndPage
              state={frameTrainingState}
              onPrevButtonHandler={() => setIsFinishedAllTrainingTabOpen(false)}
            ></HasifaTkifaEndPage>
          ) : selectedTraining?.force === EGorillaForces.SingleGunner ? (
            <SingleGunnerDesktopComponent
              currentStepType={currentStepType}
              setCurrentStepType={setCurrentStepType}
              malfunctionsOperation={malfunctionsOperation}
              setMalfunctionOperation={setMalfunctionOperation}
              setShootingExecution={setShootingExecution}
              setSystemEncryption={setSystemEncryption}
              setTargetsPropertiesResults={setTargetsPropertiesResults}
              shootingExecution={shootingExecution}
              systemEncryption={systemEncryption}
              targetsPropertiesResults={targetsPropertiesResults}
              saveHeat={saveHeatAndMoveToNext}
              mainNotes={mainNotesGunner}
              isComponentCompleted={isSingleGunnerTrainingEnd}
              singleGunnerSteps={singleGunnerSteps}
              addNote={addNoteGunner}
              deleteNote={deleteNoteGunner}
              editNote={editNoteGunner}
              selectedLuncherType={selectedLuncherType!}
            ></SingleGunnerDesktopComponent>
          ) : selectedTraining?.force === EGorillaForces.Observer ? (
            <ObserverDesktopComponent
              conductingQuality={conductingQuality}
              generalCommentsMO={mainNotesObserver}
              isTrainingEnd={isObserverTrainingEnd}
              openAndCover={openAndCover}
              setConductingQuality={setConductingQuality}
              setOpenAndCover={setOpenAndCover}
              setSettingStation={setSettingStation}
              setTargetAcquisition={setTargetAcquisition}
              setWorkRoutine={setWorkRoutine}
              settingStation={settingStation}
              workRoutine={workRoutine}
              targetAcquisition={targetAcquisition}
              currentStepType={observerCurrentStepType}
              setCurrentStepType={setObserverCurrentStepType}
              deleteNote={deleteNoteObserver}
              addNote={addNoteObserver}
              editNote={editNoteObserver}
              nextTraining={nextTraining}
            ></ObserverDesktopComponent>
          ) : selectedTraining?.force === EGorillaForces.NCO ? (
            <NCODesktopComponent
              isTrainingEnd={isNCOTrainingEnd}
              nco={nco}
              setNco={setNco}
              isEndTrainingDisabled={
                !Object.values(ncoSteps).some((step) => step.isPartlyCompleted)
              }
              nextTraining={nextTraining}
            ></NCODesktopComponent>
          ) : null}
        </IonCol>
      </IonGrid>
    </IonPage>
  );
};

export default FrameTrainingDesktop;
