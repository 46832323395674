// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loadingContainer {
  background-color: var(--ion-color-opacity);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 120 !important;
}

.loadingPML {
  background-color: var(--ion-color-xdark);
  height: 7vw;
  width: 20vw;
  max-height: 100px;
  max-width: 250px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: var(--ion-color-fontLight);
  box-shadow: -5px 6px 14px 3px rgba(0, 0, 0, 0.59);
}

.loadingMessagePML {
  font-family: "Regular" !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/Alert/PMLoading.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;EAC1C,YAAY;EACZ,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,wCAAwC;EACxC,WAAW;EACX,WAAW;EACX,iBAAiB;EACjB,gBAAgB;EAChB,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,iCAAiC;EACjC,iDAAiD;AACnD;;AAEA;EACE,iCAAiC;AACnC","sourcesContent":[".loadingContainer {\n  background-color: var(--ion-color-opacity);\n  height: 100%;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  z-index: 120 !important;\n}\n\n.loadingPML {\n  background-color: var(--ion-color-xdark);\n  height: 7vw;\n  width: 20vw;\n  max-height: 100px;\n  max-width: 250px;\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  color: var(--ion-color-fontLight);\n  box-shadow: -5px 6px 14px 3px rgba(0, 0, 0, 0.59);\n}\n\n.loadingMessagePML {\n  font-family: \"Regular\" !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
