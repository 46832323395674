import React, { useContext } from "react";
import { Route } from "react-router-dom";
import { Redirect, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// css
import "./OrbatSettings.css";

// ionic imports
import {
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";

// components
import Orbat from "../../../components/Desktop/Settings/OrbatSettins/Orbat";

// interfaces
import TabItem from "./../../../Interfaces/ITabItem";
import ManageUsers from "../../../components/Desktop/Settings/ManageUsers/ManageUsers";
import PMTitle from "../../../components/themeComponents/PMTitle";
import { UserContext } from "../../../context/UserContext/userContext";
import { routes } from "../../../services/routeRoles";

const OrbatSettings: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const location = useLocation();
  const { isAdmin, isInstructor } = useContext(UserContext);

  // Tabs buttons options
  const TabsOptions: TabItem[] = [
    {
      url: `${routes.orbatSettings}/orbat`,
      title: t("forcesTree"),
    },
  ];

  if (isAdmin || isInstructor) {
    TabsOptions.push({
      url: `${routes.orbatSettings}/users`,
      title: t("manageUsers"),
    });
  }

  return (
    <IonTabs className="pm-tabs">
      <IonRouterOutlet id="main">
        <Route
          path={routes.orbatSettings}
          render={() => <Redirect to={`${routes.orbatSettings}/orbat`} />}
          exact={true}
        />

        <Route path={`${routes.orbatSettings}/orbat`} component={Orbat} exact />
        <Route
          path={`${routes.orbatSettings}/users`}
          component={ManageUsers}
          exact
        />
      </IonRouterOutlet>
      <IonTabBar slot="top" className="ion-justify-content-start settingsTabs">
        {TabsOptions.map((item: TabItem, index: number) => {
          return (
            <IonTabButton
              className={location.pathname === item.url ? "tab-selected" : ""}
              tab={item.title}
              href={item.url}
              key={index}
            >
              <PMTitle fontColor="light" fontFamily="Light" fontSize="medium">
                {item.title}
              </PMTitle>
            </IonTabButton>
          );
        })}
      </IonTabBar>
    </IonTabs>
  );
};
export default OrbatSettings;
