import { useIonToast } from "@ionic/react";

const MobileToast = () => {
  const [present] = useIonToast();

  const presentToast = (color: "danger" | "success" | "dark", text: string) => {
    present({
      message: text,
      duration: 5000,
      position: "bottom",
      color: color,
    });
  };
  return { presentToast };
};
export default MobileToast;
