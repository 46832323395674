// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pm-tabs ion-tab-button {
  --color-selected: var(--ion-color-orange);
}

.pm-tabs .tab-selected {
  border-bottom-style: solid;
  border-bottom-color: var(--ion-color-orange);
  border-bottom-width: thin;
}

#main {
  background-color: var(--ion-color-primary);
}
.dataPostingTabs {
  min-height: 60px;
}
.trainingNotExist {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.trainingNotExistLabel {
  font-size: 45px !important;
}
.dataPostingRouter {
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Desktop/DataPosting/DataPosting.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;AAC3C;;AAEA;EACE,0BAA0B;EAC1B,4CAA4C;EAC5C,yBAAyB;AAC3B;;AAEA;EACE,0CAA0C;AAC5C;AACA;EACE,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,SAAS;AACX","sourcesContent":[".pm-tabs ion-tab-button {\n  --color-selected: var(--ion-color-orange);\n}\n\n.pm-tabs .tab-selected {\n  border-bottom-style: solid;\n  border-bottom-color: var(--ion-color-orange);\n  border-bottom-width: thin;\n}\n\n#main {\n  background-color: var(--ion-color-primary);\n}\n.dataPostingTabs {\n  min-height: 60px;\n}\n.trainingNotExist {\n  width: 100%;\n  height: 80%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.trainingNotExistLabel {\n  font-size: 45px !important;\n}\n.dataPostingRouter {\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
