import React, { FC } from "react";
import "./NotFoundPlan.css";
interface IProps {
  text: string;
}
const NotFoundPlan: FC<IProps> = (props: IProps) => {
  return <span className="textInsteadOfGraph">{props.text}</span>;
};

export default NotFoundPlan;
