import React, { useContext, useEffect, useState } from "react";

import "./SingleGunnerDesktop.css";
import { useTranslation } from "react-i18next";
import { IonCol, IonGrid, IonPage, IonRow } from "@ionic/react";
import SingleGunnerHeatsHook from "../../../CustomHooks/SingleGunnerHeatsHook";
import {
  EGorillaForces,
  EGorillaTrainings,
} from "../../../../Interfaces/Gorilla/GorillaTrainings";
import PMLabel from "../../../themeComponents/PMLabel";
import SingleGunnerDesktopComponent from "./SingleGunner/SingleGunnerDesktopComponent";
import SingleGunnerPanel from "./SingleGunner/SingleGunnerPanel";
import PromptHook from "../../../CustomHooks/PromptHook";
import WarningModal from "../../../Shared/SPIKEGorilla/WarningModal";
import PMButton from "../../../themeComponents/PMButton";
import customToast from "../../../Shared/Toast/CustomToast";
import HasifaTkifaEndPage, {
  EEndPageState,
} from "./HasifaTkifaEndPage/HasifaTkifaEndPage";

const SingleGunnerDesktop: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const [isFinishedAllTrainingTabOpen, setIsFinishedAllTrainingTabOpen] =
    useState<boolean>(false);
  const [singleGunnerTrainingState, setSingleGunnerTrainingState] =
    useState<EEndPageState>(EEndPageState.incomplete);
  const nextTraining = () => {
    setIsFinishedAllTrainingTabOpen(true);
  };
  const {
    malfunctionsOperation,
    setMalfunctionOperation,
    setShootingExecution,
    setSystemEncryption,
    setTargetsPropertiesResults,
    shootingExecution,
    systemEncryption,
    targetsPropertiesResults,
    activeHeatId,
    mainNotes,
    addNote,
    deleteNote,
    editNote,
    resetHeat,
    setActiveHeat,
    endTrainingHandler,
    setActiveHeatId,
    currentStepType,
    heatsArray,
    setCurrentStepType,
    traineeName,
    singleGunnerSteps,
    saveHeatAndMoveToNext,
    selectedLuncherType,
    closeConfirmationModal,
    endTrainingHandlerAfterConfirmation,
    isEndTrainingModalOpen,
    isTrainingEnd,
    addHeat,
    deleteHeat,
  } = SingleGunnerHeatsHook(true, nextTraining);
  useEffect(() => {
    if (isTrainingEnd) {
      customToast.success(
        `${t("dataSendSuccess")} ${t("for")} ${t(
          EGorillaTrainings.SingleGunner
        )}`
      );
    }
  }, [isTrainingEnd]);
  PromptHook(!isTrainingEnd, "hasifaTkifaLeave");

  useEffect(() => {
    const hasCompleteHeat = heatsArray.some((heat) => heat.isCompleted);
    const allHeatsComplete = heatsArray.every((heat) => heat.isCompleted);

    setSingleGunnerTrainingState(
      allHeatsComplete
        ? EEndPageState.fullCompleted
        : hasCompleteHeat
        ? EEndPageState.partlyCompleted
        : EEndPageState.incomplete
    );
  }, [heatsArray]);

  return (
    <IonPage>
      <WarningModal
        acceptText={`${t("yes")}, ${t("finish")}`}
        onDecline={closeConfirmationModal}
        onAccept={endTrainingHandlerAfterConfirmation}
        isOpen={isEndTrainingModalOpen}
        title="endingTraining"
        warningText={"endTrainingConfirmation"}
        warningQuestion={`${t("endTrainingConfirmationQuestion")} ${t(
          EGorillaForces.SingleGunner
        )}?`}
      ></WarningModal>
      <IonGrid className="FTDContainerWrap">
        <IonCol
          size="3"
          className="firstCol scrollS"
          onClick={() => setIsFinishedAllTrainingTabOpen(false)}
        >
          <IonRow className="titleRowHT">
            <PMLabel fontFamily="Regular" fontSize="xxLarge" fontColor="light">
              {`${t(EGorillaTrainings.SingleGunner)}`}
            </PMLabel>
          </IonRow>
          <IonRow className="nameRowHT">
            <PMLabel fontFamily="Regular" fontSize="xxLarge" fontColor="light">
              {`${traineeName}`}
            </PMLabel>
          </IonRow>
          <SingleGunnerPanel
            activeHeatId={activeHeatId}
            currentStepType={currentStepType}
            isDisableAddHeats={isTrainingEnd}
            heatsArray={heatsArray}
            resetHeat={resetHeat}
            setActiveHeat={setActiveHeat}
            setActiveHeatId={setActiveHeatId}
            setCurrentStepType={setCurrentStepType}
            singleGunnerSteps={singleGunnerSteps}
            addHeat={addHeat}
            deleteHeat={deleteHeat}
          />
          <IonRow className="endTrainingRow">
            <PMButton
              onClickHandler={endTrainingHandler}
              color="orange"
              size="large"
              label={{ fontColor: "dark", fontSize: "medium" }}
              isDisabled={
                !Object.values(heatsArray).some((heat) => heat.isCompleted) ||
                isTrainingEnd
              }
            >
              {t("endTraining")}
            </PMButton>
          </IonRow>
        </IonCol>
        <IonCol className="FTDContainer scrollS">
          {isFinishedAllTrainingTabOpen ? (
            <HasifaTkifaEndPage
              state={singleGunnerTrainingState}
              onPrevButtonHandler={() => setIsFinishedAllTrainingTabOpen(false)}
            ></HasifaTkifaEndPage>
          ) : (
            <SingleGunnerDesktopComponent
              currentStepType={currentStepType}
              setCurrentStepType={setCurrentStepType}
              malfunctionsOperation={malfunctionsOperation}
              setMalfunctionOperation={setMalfunctionOperation}
              setShootingExecution={setShootingExecution}
              setSystemEncryption={setSystemEncryption}
              setTargetsPropertiesResults={setTargetsPropertiesResults}
              shootingExecution={shootingExecution}
              systemEncryption={systemEncryption}
              targetsPropertiesResults={targetsPropertiesResults}
              saveHeat={saveHeatAndMoveToNext}
              deleteNote={deleteNote}
              editNote={editNote}
              addNote={addNote}
              mainNotes={mainNotes}
              isComponentCompleted={isTrainingEnd}
              singleGunnerSteps={singleGunnerSteps}
              selectedLuncherType={selectedLuncherType!}
            ></SingleGunnerDesktopComponent>
          )}
        </IonCol>
      </IonGrid>
    </IonPage>
  );
};

export default SingleGunnerDesktop;
