import React, { ReactNode, useState } from "react";
import { IDataType } from "../../Interfaces/IDataType";

interface IDataTypesContext {
  dataTypes: IDataType[];
  setDataTypes: React.Dispatch<React.SetStateAction<IDataType[]>>;
}

export const DataTypesContext = React.createContext<IDataTypesContext>({
  dataTypes: [],
  setDataTypes: () => {},
});

const DataTypesContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [dataTypes, setDataTypes] = useState<IDataType[]>([]);
  return (
    <DataTypesContext.Provider value={{ dataTypes, setDataTypes }}>
      {children}
    </DataTypesContext.Provider>
  );
};

export default DataTypesContextProvider;
