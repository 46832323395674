import { IonCol, IonGrid, IonRow } from "@ionic/react";
import EIconsSrc from "../../../../Interfaces/EIconsSrc";
import PMIcon from "../../../themeComponents/PMIcon";
import PMLabel from "../../../themeComponents/PMLabel";
import TraineesList from "../TraineesList/TraineesList";
import "./TraineesModal.css";
import { ITrainee } from "../../../../Interfaces/results/ITrainee.interface";
import { useTranslation } from "react-i18next";
import DragModal from "../../DragModal/DragModal";
import { ITrainingTypeResult } from "../../../../Interfaces/dataCalculator";
import SearchBar from "../../../Shared/SearchBar/SearchBar";
import { useState } from "react";
import { EDashboardBar } from "../../../../Enums/EDashboardBar";
import ELanguage from "../../../../Enums/ELanguage";

interface IModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  sortedElement: string;
  trainingType?: ITrainingTypeResult;
  trainees: ITrainee[];
  clickedColumn: EDashboardBar | undefined;
}

const TraineesModal = (props: IModalProps) => {
  const { t, i18n } = useTranslation();
  const [searchText, setSearchText] = useState<string>("");

  return (
    <DragModal
      cssClass={`traineesModal ${
        i18n.language === ELanguage.he ? "traineesModalHe" : "traineesModalEn"
      }`}
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
    >
      <IonGrid className="traineesModalGrid">
        <IonRow className="TMHeaderRow">
          <IonCol className="TMtitleCol" size="6">
            <PMLabel
              cssClass="dashboardModalHeader"
              fontColor="xLight"
              fontFamily="Light"
            >
              {props.trainingType && t(props.trainingType.name)}
            </PMLabel>
          </IonCol>

          <IonCol className="backdropCol">
            <PMIcon
              iconSrc={EIconsSrc.CLOSE}
              cssClass="backdropIcon"
              onClick={props.onDismiss}
            ></PMIcon>
          </IonCol>
        </IonRow>
        <IonRow className="TMSearchBarRow">
          <IonCol size="2.5" className="TMSearchRow">
            <SearchBar
              color="dark"
              value={searchText}
              onChange={setSearchText}
              placeholder={t("searchForceByNameAndId")}
              onClear={() => setSearchText("")}
            ></SearchBar>
          </IonCol>
        </IonRow>
        <IonRow className="traineesListRow">
          <TraineesList
            clickedColumn={props.clickedColumn}
            searchText={searchText}
            trainees={props.trainees}
            elements={props.trainingType && props.trainingType.elements}
            sortedElement={props.sortedElement}
            trainingTypeId={props.trainingType && props.trainingType.id}
          ></TraineesList>
        </IonRow>
      </IonGrid>
    </DragModal>
  );
};

export default TraineesModal;
