import { IonCol, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import PMLabel from "../../../themeComponents/PMLabel";
import Trainee from "../Trainee/Trainee";
import "./TraineesList.css";
import { IElement } from "../../../../Interfaces/ITrainingPlan";
import { useTranslation } from "react-i18next";
import PMIcon from "../../../themeComponents/PMIcon";
import EIconsSrc from "../../../../Interfaces/EIconsSrc";
import { ITrainee } from "../../../../Interfaces/results/ITrainee.interface";
import InfiniteScroll from "react-infinite-scroll-component";
import { EDashboardBar } from "../../../../Enums/EDashboardBar";

interface ITraineesListProps {
  trainees: ITrainee[];
  trainingTypeId?: number;
  sortedElement: string;
  elements: any[];
  searchText: string;
  clickedColumn: EDashboardBar | undefined;
}

const TRAINEES_RENDER_CHUNK_SIZE = 50;

const TraineesList = (props: ITraineesListProps) => {
  const [trainees, setTrainees] = useState<ITrainee[]>(props.trainees);
  const [sortedElement, setSortedElement] = useState<string>(
    props.sortedElement
  );
  const [sortDirectionDown, setSortDirectionDown] = useState<boolean>(true);
  const [hasMoreTrainees, setHasMoreTrainees] = useState<boolean>(true);
  const [count, setCount] = useState({
    prev: 0,
    next: TRAINEES_RENDER_CHUNK_SIZE,
  });
  const [current, setCurrent] = useState<any[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (sortedElement.length)
      setTrainees(
        sortTraineesBySelectedElement(sortDirectionDown, props.searchText)
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedElement, sortDirectionDown, props.searchText]);
  // Renders more trainees
  const getMoreData = () => {
    if (!props.searchText.length) {
      // If we've reached the end of the list , set hasMore to be false
      if (current.length === trainees.length) {
        setHasMoreTrainees(false);
        return;
      }
      setTimeout(() => {
        setCurrent(
          current.concat(
            trainees.slice(
              count.prev + TRAINEES_RENDER_CHUNK_SIZE,
              count.next + TRAINEES_RENDER_CHUNK_SIZE
            )
          )
        );
      }, 500);
      setCount((prevState) => ({
        prev: prevState.prev + TRAINEES_RENDER_CHUNK_SIZE,
        next: prevState.next + TRAINEES_RENDER_CHUNK_SIZE,
      }));
    }
  };
  // Assigns clicked element as sorted element
  const headerClickHandler = (name: string) => {
    if (name === sortedElement) {
      setSortedElement("");
    } else {
      setSortedElement(name);
    }
  };

  // Sorts trainees by clicked header
  const sortTraineesBySelectedElement = (
    sortDirectionDown: boolean,
    searchText: string
  ) => {
    // Finds selected ("sorted") element
    const element = Object.values(props.elements).find(
      (element: IElement) => element.name === sortedElement
    );
    const isNegative = element.upperThreshold < element.lowerThreshold;
    // Sorted list according to element negative value (true/false)
    let sortedTrainees: ITrainee[] = trainees
      //filter trainees by the selected EDashboardBar
      .filter(
        (trainee) =>
          !sortedElement ||
          props.clickedColumn === EDashboardBar.all ||
          trainee.results.find(
            (result: any) => result.elementName === sortedElement
          )?.color === props.clickedColumn
      )
      .sort((traineeA, traineeB) => {
        const [traineeAValue, traineeBValue] = [
          traineeA.results.find(
            (result: any) => result.elementName === sortedElement
          )?.value,
          traineeB.results.find(
            (result: any) => result.elementName === sortedElement
          )?.value,
        ];
        //require in order to sort the null/ undefined values in the bottom
        if (traineeAValue === undefined || traineeAValue === null)
          return traineeBValue === undefined || traineeBValue === null ? 0 : 1;
        if (traineeBValue === undefined || traineeBValue === null) return -1;

        return isNegative || !sortDirectionDown
          ? traineeAValue - traineeBValue
          : traineeBValue - traineeAValue;
      });

    let searchTextArray = searchText.split(" ").filter((text) => text.length);
    // for every trainee check if all the words exists in the name or soldier id
    setCurrent(
      //if search text exist displays all results else displays in InfiniteScroll method
      searchTextArray.length
        ? sortedTrainees.filter((trainee) =>
            searchTextArray.every(
              (text) =>
                trainee.forceName.includes(text) ||
                String(trainee.soldierId).includes(text)
            )
          )
        : sortedTrainees.slice(0, count.next)
    );
    return sortedTrainees;
  };

  return (
    <IonCol size="12" className="traineesListCol">
      <IonRow className="traineesListHeader">
        <IonCol size="0.1" className="upDownArrowsCol">
          <PMIcon
            iconSrc={EIconsSrc.ARROWS}
            cssClass="upDownArrows"
            onClick={() => setSortDirectionDown((prev) => !prev)}
          ></PMIcon>
        </IonCol>
        <IonCol size="0.4" className="rankCol">
          <PMLabel cssClass="rankLabel">#</PMLabel>
        </IonCol>
        <IonCol size="2" className="nameCol">
          <PMLabel cssClass="nameLabel">{t("soldierName")}</PMLabel>
        </IonCol>
        <IonCol size="1.3" className="classCol">
          <PMLabel cssClass="nameLabel">{t("class")}</PMLabel>
        </IonCol>
        <IonCol size="8.2" className="">
          <IonRow className="headersTraineesRow">
            {props.elements &&
              Object.values(props.elements).map((element: IElement, index) => (
                <IonCol className="forceColHeader " key={index}>
                  <PMLabel
                    cssClass={`valueCol ${
                      sortedElement === element.name ? "selected" : ""
                    }`}
                    onClick={() => headerClickHandler(element.name)}
                    id={element.elementId?.toString()}
                    key={element.elementId}
                  >
                    {t(element.name)}
                  </PMLabel>
                  <PMIcon
                    iconSrc={EIconsSrc.ARROWS}
                    cssClass="upDownArrows"
                    onClick={() => {
                      if (sortedElement === element.name)
                        setSortDirectionDown((prev) => !prev);
                    }}
                  ></PMIcon>
                </IonCol>
              ))}
          </IonRow>
        </IonCol>
      </IonRow>
      <IonRow className="scrollTraineesRow">
        <InfiniteScroll
          dataLength={current.length}
          next={getMoreData}
          hasMore={hasMoreTrainees && !props.searchText.length} // using the infinite scroll only when the user didn't search
          loader={
            <PMLabel fontColor="light" fontFamily="Regular">
              {t("loading")}
            </PMLabel>
          }
          className="infiniteScroll"
          height={300}
        >
          {current.map((trainee: any, index: number) => (
            <Trainee
              key={index}
              trainee={trainee}
              elements={props.elements}
              trainingTypeId={props.trainingTypeId && props.trainingTypeId}
              ranking={index + 1}
              sortedElement={sortedElement}
            ></Trainee>
          ))}
        </InfiniteScroll>
      </IonRow>
    </IonCol>
  );
};

export default TraineesList;
