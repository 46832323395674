import { IonLabel } from "@ionic/react";
import { FC } from "react";
import "./PMLabel.css";
import { IPMLabel } from "../../Interfaces/IPMTheme";
const PMLabel: FC<IPMLabel> = (props: IPMLabel) => {
  const {
    fontSize,
    children,
    fontColor,
    fontFamily,
    textAlign,
    onClick,
    margin,
    underline,
    position,
    cssClass,
    id,
    lineThrough,
  } = props;
  return (
    <>
      <IonLabel
        className={` pmLabel ${fontSize}L ${fontColor}L ${fontFamily} ${textAlign}L ${cssClass}  ${
          lineThrough ? "line-through" : ""
        } ${underline ? "underline" : ""} ${margin ? "marginL" : ""}`}
        onClick={onClick}
        position={position}
        id={id}
      >
        {children}
      </IonLabel>
    </>
  );
};
export default PMLabel;
