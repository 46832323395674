import { IonPage } from "@ionic/react";
import React from "react";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import "./Loading.css";

const Loading = () => {
  return (
    <IonPage className="loadingPage">
      <img
        alt="loadingPic"
        src={EIconsSrc.BAGIRA_TEXT_LOGO}
        className="img"
      ></img>
    </IonPage>
  );
};

export default Loading;
