import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import Axios from "../../../Axios";
import { baseUrlPMBackend } from "../../../Configurations/consts";
import { DataTypesContext } from "../../../context/DataTypesContext/DataTypesContext";
import { IDataType } from "../../../Interfaces/IDataType";
import customToast from "../../Shared/Toast/CustomToast";

const notPresentsDataTypesInDataHistory = [
  "DrillStartTime",
  "DrillEndTime",
  "GreenHits",
  "BlueHits",
  "TopicsResults5",
];

/**
 *
 * @returns array with of force types
 */
const asyncFetchDataTypes = async () => {
  const res = await Axios.get(`${baseUrlPMBackend}stations/getDataTypes`);
  return res.data;
};

export const useDataTypes = () => {
  const { t } = useTranslation();
  const { dataTypes, setDataTypes } = useContext(DataTypesContext);

  const [dataHistoryDataTypes, setDataHistoryDataTypes] = useState<IDataType[]>(
    []
  );
  let { data, isLoading, isError } = useQuery<IDataType[]>({
    queryKey: ["dataTypes"],
    queryFn: asyncFetchDataTypes,
    enabled: !dataTypes.length,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!isLoading && !isError && data && data.length) {
      //det the context with the force types data
      setDataTypes(
        data!.map((dataType) => ({
          ...dataType,
          name: dataType.name.trim(),
          trainingTypeId: Number(dataType.trainingTypeId),
          id: Number(dataType.id),
        }))
      );

      setDataHistoryDataTypes((prev) => {
        return prev.length
          ? prev
          : data!
              .map((dataType) => ({
                ...dataType,
                name: dataType.name.trim(),
                trainingTypeId: Number(dataType.trainingTypeId),
                id: Number(dataType.id),
              }))!
              .filter(
                (dataType) =>
                  !notPresentsDataTypesInDataHistory.includes(dataType.name)
              );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (dataTypes?.length) {
      setDataHistoryDataTypes((prev) => {
        return prev.length
          ? prev
          : dataTypes!
              .map((dataType) => ({
                ...dataType,
                name: dataType.name.trim(),
                trainingTypeId: Number(dataType.trainingTypeId),
                id: Number(dataType.id),
              }))!
              .filter(
                (dataType) =>
                  !notPresentsDataTypesInDataHistory.includes(dataType.name)
              );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTypes]);

  useEffect(() => {
    if (isError) {
      customToast.error(t("errorWhileLoadingDataTypes"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return { dataHistoryDataTypes, dataTypes };
};
