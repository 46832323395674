import React, { ReactNode, useState } from "react";

interface ISyncContext {
  syncStarted: boolean;
  setSyncStarted: React.Dispatch<React.SetStateAction<boolean>>;
  syncEnded: boolean;
  setSyncEnded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SyncContext = React.createContext<ISyncContext>({
  syncStarted: false,
  setSyncStarted: () => {},
  syncEnded: false,
  setSyncEnded: () => {},
});

const SyncContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [syncStarted, setSyncStarted] = useState<boolean>(false);
  const [syncEnded, setSyncEnded] = useState<boolean>(false);
  return (
    <SyncContext.Provider
      value={{ setSyncEnded, setSyncStarted, syncEnded, syncStarted }}
    >
      {children}
    </SyncContext.Provider>
  );
};

export default SyncContextProvider;
