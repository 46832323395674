import React from "react";
import { IonItem } from "@ionic/react";
import "./SmallInput.css";
import { useTranslation } from "react-i18next";

interface IInputProps {
  inputType: "number" | "text";
  inputValue?: string | number | null;
  length: "smallShort" | "smallLong";
  onChange: (field: string, value: string) => void;
  inputName: string;
  isPercentageInput?: boolean | false;
  isDisabled?: false | boolean;
  invalid?: false | boolean;
  units?: string | "a";
  cssClass?: string;
}

const SmallInput: React.FC<IInputProps> = (props: IInputProps) => {
  const { t } = useTranslation();

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
    }
  };

  return (
    <IonItem
      className={`smallInputItem ${props.length} ${
        props.invalid ? "invalidInput" : null
      } ${props.cssClass}  ${
        props.isDisabled ? "disabledSmallInputItem" : null
      }`}
      onClick={(e) => e.stopPropagation()}
    >
      <input
        className={`smallInputField ${
          props.isPercentageInput ? "smallInputPercentage" : null
        }`}
        value={
          props.inputValue !== null && props.inputValue !== undefined
            ? props.inputValue
            : ""
        }
        type={props.inputType}
        onChange={(e: any) => {
          props.onChange(props.inputName, e.target.value!);
        }}
        disabled={props.isDisabled}
        pattern=" 0+\.[0-9]*[1-9][0-9]*$"
        min="0"
        onKeyDown={handleKeyDown}
        onWheel={(event) => {
          event?.currentTarget?.blur();
        }}
      />
      {props.units ? <div className="units">{t(props.units)}</div> : null}
    </IonItem>
  );
};

export default SmallInput;
