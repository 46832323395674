// Dependencies
import {
  IonCol,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
} from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";

// CSS
import "./TrainingPlanInput.css";

import InputMask from "react-input-mask";
import { useTranslation } from "react-i18next";
import Checkbox from "../../Shared/Checkbox/Checkbox";
import { eyeOutline, eyeOffOutline } from "ionicons/icons";

interface IInputProps {
  iconInInput?: boolean | false;
  isPercentageInput?: boolean;
  inputType: "text" | "number" | "hours" | "password" | "email";
  inputName: string;
  placeholder?: string | "";
  inputValue?: string | number | null;
  isDisabled?: false | boolean;
  length?: "short" | "long";
  onChangeHandler?: ((field: string, value: string) => void) | undefined;
  onBlurHandler?: () => void;
  invalid?: false | boolean;
  isPerformancePage?: boolean;
  planName?: string;
  isSettingsDesign?: false | boolean;
  startTime?: string;
  endTime?: string;
  isAllDay?: boolean;
  onChangeIsAllDayHandler?: () => void;
  onChangeTimeHandler?: (time: string) => void;
  icon?: string;
  onIconClick?: () => void;
  isEmailAddress?: boolean;
  isOnlyText?: boolean;
  isCalendarInput?: boolean;
  cssClass?: string;
}

const Input: React.FC<IInputProps> = (props: IInputProps) => {
  const { t } = useTranslation();
  const [icon, setIcon] = useState<string>(eyeOutline);

  const [passwordInputType, setPasswordInputType] = useState<
    "password" | "text"
  >("password");
  const onIconClickHandler = () => {
    setIcon((prev) => (prev === eyeOutline ? eyeOffOutline : eyeOutline));
    setPasswordInputType((prev) => (prev === "password" ? "text" : "password"));
  };

  const inputRef = useRef<HTMLIonInputElement>(null);

  // Handle wheel event to prevent scroll behavior
  const handleWheel = (event: any) => {
    event.preventDefault(); // Prevent scroll wheel from changing the input value

    // Access the shadow root and then the actual input element
    const shadowRoot = inputRef.current?.shadowRoot;
    if (shadowRoot) {
      const innerInput = shadowRoot.querySelector("input");
      if (innerInput) {
        innerInput.blur(); // Trigger blur event on the inner input
      }
    }
  };

  useEffect(() => {
    const currentInput = inputRef.current;
    if (currentInput) {
      currentInput.addEventListener("wheel", handleWheel);
      return () => {
        currentInput.removeEventListener("wheel", handleWheel);
      };
    }
  }, []);
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
    }
  };

  return (
    <IonItem
      className={`inputItem ${props.cssClass} ${
        props.isDisabled ? "disabledItem" : null
      }  ${props.isSettingsDesign ? "settingsInput" : props.length} ${
        props.inputName === "date" || props.isOnlyText ? "inputDate" : ""
      }`}
      lines="none"
    >
      {props.isPerformancePage && (
        <IonRow className={`ion-justify-content-start labelRow `}>
          <IonLabel
            position={"floating"}
            className={`inputLabel inputLabelName `}
          >
            {props.planName}
          </IonLabel>
        </IonRow>
      )}
      {
        <IonRow
          className={`labelRow ${
            props.inputType === "hours" ? "hoursLabelRow" : ""
          } ${props.isSettingsDesign ? "settingsLabel" : ""} `}
        >
          <IonLabel
            position={props.inputType === "hours" ? "stacked" : "floating"}
            className={`${props.isPerformancePage ? "inputLabel" : "tpLabel"} ${
              props.isOnlyText ? "onlyText" : ""
            }`}
          >
            {props.placeholder}
          </IonLabel>
        </IonRow>
      }
      {props.inputType === "hours" ? (
        <IonRow className="inputRow">
          <IonCol className="inputCol" size="7">
            <InputMask
              disabled={props.isDisabled || props.isAllDay}
              className={`hoursInput ${props.isAllDay ? "hoursDisabled" : ""}`}
              type="text"
              size={11}
              value={props.startTime + "-" + props.endTime}
              alwaysShowMask
              mask="99:99 - 99:99"
              onChange={(e) => {
                props.onChangeTimeHandler
                  ? props.onChangeTimeHandler(e.target.value)
                  : console.log();
              }}
            />
          </IonCol>
          {props.isCalendarInput ? null : (
            <IonCol className="checkboxCol" size="5">
              <Checkbox
                isChecked={props.isAllDay ? true : false}
                onChange={props.onChangeIsAllDayHandler}
              ></Checkbox>

              <div className="allDayText">{t("allDay")}</div>
            </IonCol>
          )}
        </IonRow>
      ) : (
        <IonRow
          className={`inputRow ${
            props.isEmailAddress ? "emailAddressInput" : ""
          }`}
        >
          <IonInput
            ref={inputRef}
            disabled={props.isDisabled}
            readonly={props.isOnlyText}
            className={`inputField  ${props.invalid ? "invalidInput" : null} ${
              props.isPercentageInput ? "inputPercentage" : null
            }  
             ${props.isPercentageInput ? "inputPercentage" : null} `}
            name={props.inputName}
            value={props.inputValue}
            type={
              props.inputType === "password"
                ? passwordInputType
                : props.inputType
            }
            min="0"
            maxlength={30}
            onIonChange={(e) => {
              props.onChangeHandler
                ? props.onChangeHandler(props.inputName, e.detail.value!)
                : console.log();
            }}
            onKeyDown={handleKeyDown}
            onBlur={props.onBlurHandler}
          ></IonInput>
          {props.isPercentageInput ? (
            <span
              className={props.isDisabled ? "isPercentageLabelDisabled" : ""}
            >
              %
            </span>
          ) : null}
          {props.iconInInput || props.inputType === "password" ? (
            <div
              className="eyeIconDiv"
              onClick={() =>
                props.onIconClick
                  ? props.onIconClick()
                  : props.inputType === "password"
                  ? onIconClickHandler()
                  : null
              }
            >
              <IonIcon
                className={`${
                  props.inputName === "password" ? "eyeIcon" : "calendarIcon"
                }`}
                icon={
                  props.icon
                    ? props.icon
                    : props.inputType === "password"
                    ? icon
                    : undefined
                }
              />
            </div>
          ) : null}
        </IonRow>
      )}
    </IonItem>
  );
};

export default Input;
