import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Axios from "../../Axios";
import { baseUrlPMBackend } from "../../Configurations/consts";
import IForceFromOrbat from "../../Interfaces/IForceFromOrbat";
import IPlan from "../../Interfaces/IPlan";
import customToast from "../Shared/Toast/CustomToast";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import axios, { CancelTokenSource } from "axios";
import { ITrainingPlanResult } from "../../Interfaces/dataCalculator";
import { ITargetsPropertiesOptions } from "../../Interfaces/Gorilla/ITargetProperties";

const useGetGorillaGrades = (
  selectedPlan: IPlan | undefined,
  force: IForceFromOrbat[],
  refresh: boolean | undefined,
  setRefresh: Dispatch<SetStateAction<boolean | undefined>>
) => {
  const [loading, setLoading] = useState<boolean>(false);

  const { forceData, hitAnalyze } = useGetGrades(
    setLoading,
    force[0]?.id,
    selectedPlan?.id,
    refresh,
    setRefresh
  );
  const [notes, setNotes] = useState<string[] | undefined>();

  useEffect(() => {
    if (forceData) {
      setLoading(false);
      setNotes([...forceData.notes.Single_Gunner, ...forceData.notes.Observer]);
    }
  }, [forceData]);
  return {
    loading,
    forceData,
    hitAnalyze,
    notes,
  };
};

export default useGetGorillaGrades;
export const useGetGrades = (
  setLoading: (state: boolean) => void,
  forceId: number | undefined,
  planId: number | undefined,
  isRefresh: boolean | undefined,
  setRefresh: Dispatch<SetStateAction<boolean | undefined>>
) => {
  const [cancelTokenSource, setCancelTokenSource] = useState<
    CancelTokenSource | undefined
  >(undefined);

  const { t } = useTranslation();
  const getGorillaGrades = async (forceId?: number, planId?: number) => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Request canceled due to new request");
    }
    const source = axios.CancelToken.source();
    setCancelTokenSource(source);
    let forceData = await Axios.get(
      `${baseUrlPMBackend}performanceGrades/getHasifaTkifaResults`,
      {
        params: {
          planId: planId,
          forceId: forceId,
        },
        cancelToken: source.token,
      }
    );

    setLoading(false);

    return forceData.data;
  };
  let query = useQuery<{
    forceData: ITrainingPlanResult;
    hitAnalyze: { hit: boolean; values: ITargetsPropertiesOptions[] }[];
  }>({
    queryKey: ["gorillaGrades", [planId, forceId]],
    queryFn: () => getGorillaGrades(forceId, planId),
    enabled: forceId !== undefined && planId !== undefined,
  });
  let { isError } = query;

  useEffect(() => {
    //if refresh clicked refetch the results
    if (isRefresh && forceId !== undefined && planId !== undefined) {
      setLoading(true);
      setRefresh(false);
      query.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh]);

  //set loading to be true if plan id changed
  useEffect(() => {
    if (forceId !== undefined && planId !== undefined) setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planId, forceId]);

  useEffect(() => {
    if (isError && !cancelTokenSource)
      customToast.error(t("errorWhileLoadingData"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return {
    forceData: forceId && planId ? query.data?.forceData : undefined,
    hitAnalyze: forceId && planId ? query.data?.hitAnalyze : undefined,
  };
};
