import React, { useState, useEffect, Dispatch, useContext } from "react";
import TreeNode from "./TreeNode/TreeNode";
import axios from "../../../../Axios";
import "./OrbatTree.css";

// context
import { UserContext } from "../../../../context/UserContext/userContext";

// redux
import { useDispatch, useSelector } from "react-redux";
import { orbatTreeState } from "../../../../redux/store/OrbatTreeStore";
import {
  deleteForce,
  resetFlags,
} from "../../../../redux/actions/orbatTreeActions";
import { IOrbatTreeReducer } from "../../../../redux/reducers/orbatTree";

// services
import { useTranslation } from "react-i18next";
import { getForceById, getForceFullName } from "../../../../services/helpers";

// ionic imports
import { IonCol, IonGrid, IonRow } from "@ionic/react";

// interfaces
import IForcePointer from "./../../../../Interfaces/IForcePointer";
import IForceTreeNode from "./../../../../Interfaces/IForceTreeNode";
import { baseUrlPMBackend } from "../../../../Configurations/consts";
import Dropdown from "../../SearchDropdown/SearchDropdown";
import ImportFile from "./ImportFile/ImportFile";
import customToast from "../../Toast/CustomToast";
import PMTitle from "../../../themeComponents/PMTitle";
import PMIcon from "../../../themeComponents/PMIcon";
import PMLabel from "../../../themeComponents/PMLabel";
import EIconsSrc from "../../../../Interfaces/EIconsSrc";
import Alert from "../../Alert/Alert";
import Spinner from "../../Spinner/Spinner";
import OrbatTreeHook from "../../../CustomHooks/OrbatTreeHook";
import { userRoles } from "../../../../services/routeRoles";
import PMTooltip from "../../../Desktop/PMTooltip/PMTooltip";

interface IProps {
  checked: (force: IForceTreeNode[], isSubordinates?: boolean) => void;
  readonly: boolean | true;
  limit?: number;
  forceToChange?: IForceTreeNode;
  forceToAdd?: IForceTreeNode;
  forceToRemove?: IForceTreeNode;
  isMobile?: boolean | false;
  checkedForces?: IForceTreeNode[];
  isAdmin?: boolean | true;
  isForDropDown?: boolean;
  isReport?: boolean;
  setDeletingLoading?: (isLoading: boolean) => void;
  disableEditing?: boolean;
  initIsDuplicated?: () => void;
  displayPlattonAndAbove?: boolean | undefined;
  isRootDisable?: boolean;
  setEnableReorder?: React.Dispatch<React.SetStateAction<boolean>>;
  enableReorder?: boolean;
  draggedElement?: HTMLDivElement | undefined;
  setDraggedElement?: (element: HTMLDivElement | undefined) => void;
  selectedForceId?: number;
}
interface IOptionsState {
  options: "reorder" | "add" | "import" | undefined;
}
const OrbatTree: React.FC<IProps> = (props: IProps) => {
  const [optionState, setOptionState] = useState<IOptionsState["options"]>();
  const { t } = useTranslation();
  const dispatch = useDispatch<Dispatch<any>>();
  const [checked, setChecked] = useState<IForceTreeNode[]>([]);

  // context
  const { user } = useContext(UserContext);
  //hooks
  const {
    findPath,
    checkedForce,
    closeAlert,
    flatTree,
    forces,
    getSelectedForce,
    isLoading,
    onCheckForce,
    openAlert,
    path,
    removeFromPath,
    setOpenAlert,
    thereIsForces,
    setCheckedForce,
    setPath,
    checkRelatedForceAndGetTree,
  } = OrbatTreeHook(
    checked,
    setChecked,
    props.limit,
    props.readonly,
    props.enableReorder,
    props.checked,
    props.initIsDuplicated
  );

  //redux state
  const orbatTree: IForceTreeNode = useSelector<
    orbatTreeState,
    IOrbatTreeReducer
  >((state) => state.orbatTree).orbatTree;
  const deleteOccured: boolean = useSelector<orbatTreeState, IOrbatTreeReducer>(
    (state) => state.orbatTree
  ).deleteOccured;
  useEffect(() => {
    //if check force by id is wanted
    if (props.selectedForceId) {
      let force = getForceById(orbatTree, props.selectedForceId);
      if (force) props.checked([force]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedForceId]);
  useEffect(() => {
    props.checkedForces?.forEach((f: IForceTreeNode) => findPath(f.id));
    props.checkedForces && setChecked(props.checkedForces);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.checkedForces]);

  useEffect(() => {
    props.forceToChange && changeNode(forces, props.forceToChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.forceToChange]);

  useEffect(() => {
    if (props.forceToAdd) {
      addChildToTree(forces, props.forceToAdd);
      props.checked([props.forceToAdd]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.forceToAdd]);

  useEffect(() => {
    props.forceToRemove && removeItemFromTree(forces, props.forceToRemove);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.forceToRemove]);

  useEffect(() => {
    props.checkedForces?.forEach((f: IForceTreeNode) => findPath(f.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatTree]);

  useEffect(() => {
    if (deleteOccured) {
      setCheckedForce({} as IForceTreeNode);
      props.checked([]);
      customToast.success(t("deleteForceMsg"));
      dispatch(resetFlags());
      props.setDeletingLoading && props.setDeletingLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orbatTree]);

  // Adding new leaf to the checked node - without saving it in the database
  const addForce: (tree: IForceTreeNode) => void = (
    tree: IForceTreeNode
  ): void => {
    let currNodes: IForceTreeNode[] | null = tree.nodes;

    if (tree.id === checkedForce.id) {
      let newForce: IForceTreeNode = {
        id: Math.floor(Math.random() * -1000),
        is_deleted: false,
        level: tree.level + 1,
        name: t("newForce"),
        nodes: null,
        parent_id: checkedForce.id,
        soldier_id: null,
        tag_id: null,
        weapon_id: "0",
        weapon_type: "None",
        weapon_sight: "None",
        weapon_sight_id: 0,
        force_type: "מתאמן",
        is_soldier: true,
        personal_id: null,
        magazine_id: null,
        laser_id: null,
        head_sensor_id: null,
      };
      let tempTree: IForceTreeNode = currNodes
        ? { ...tree, nodes: [...currNodes, newForce] }
        : { ...tree, nodes: [newForce] };

      Object.assign(tree, tempTree);
      setChecked([newForce]);
      props.checked([newForce]);
      setPath((prev: (number | null)[]) => [...prev, newForce.id]);
    } else {
      currNodes &&
        currNodes.forEach((node: IForceTreeNode) => {
          addForce(node);
        });
    }
  };

  // remove gevan item from tree
  const removeItemFromTree = (
    tree: IForceTreeNode,
    itemToRemove: IForceTreeNode
  ) => {
    if (tree.id === itemToRemove.parent_id) {
      if (tree.nodes) {
        tree.nodes = tree.nodes.filter(
          (node: IForceTreeNode) => node.id !== itemToRemove.id
        );
      }
      setChecked([tree]);
      props.checked([tree]);
      removeFromPath(itemToRemove.id);
    } else {
      tree.nodes &&
        tree.nodes.forEach((node: IForceTreeNode) => {
          removeItemFromTree(node, itemToRemove);
        });
    }
  };

  // changing details of node
  const changeNode = (
    tree: IForceTreeNode,
    newValues: IForceTreeNode
  ): void => {
    if (+newValues.id === +tree.id) {
      let newTree: IForceTreeNode = {
        id: tree.id,
        is_deleted: tree.is_deleted,
        level: tree.level,
        name: newValues.name,
        nodes: tree.nodes,
        parent_id: tree.parent_id,
        soldier_id: newValues.soldier_id,
        tag_id: newValues.tag_id,
        weapon_id: newValues.weapon_id,
        weapon_type: newValues.weapon_type,
        weapon_sight: newValues.weapon_sight,
        weapon_sight_id: newValues.weapon_sight_id,
        force_type: newValues.force_type,
        is_soldier: newValues.is_soldier,
        personal_id: newValues.personal_id,
        magazine_id: newValues.magazine_id,
        laser_id: newValues.laser_id,
        head_sensor_id: newValues.head_sensor_id,
      };
      Object.assign(tree, newTree);
      setCheckedForce(newTree);
    } else {
      tree.nodes &&
        tree.nodes.forEach((node: IForceTreeNode) => {
          changeNode(node, newValues);
        });
    }
  };

  // adding new node to tree
  const addChildToTree = (
    tree: IForceTreeNode,
    force: IForceTreeNode
  ): void => {
    if (tree.id < 0) {
      Object.assign(tree, { ...force, is_deleted: false });
      props.checked([tree]);
      setCheckedForce(tree);
    } else {
      tree.nodes &&
        tree.nodes.forEach((node: IForceTreeNode) => {
          addChildToTree(node, force);
        });
    }
  };

  // Calls when delete button clicked
  const onDeleteForce: (force: IForceTreeNode) => void = (
    force: IForceTreeNode
  ): void => {
    props.setDeletingLoading && props.setDeletingLoading(true);
    dispatch(
      deleteForce(
        force,
        customToast,
        t("forceInSite"),
        t("deleteError"),
        user,
        t,
        props.setDeletingLoading
      )
    );
  };

  const loadTreeTrigger = () => {
    checkRelatedForceAndGetTree(false);
  };

  const switchOprionState = (state: IOptionsState["options"]) => {
    setOptionState((prev) => (prev === state ? undefined : state));
  };

  const toggleReorderBtn = () => {
    switchOprionState("reorder");
    setChecked((prev) => (prev.length ? [prev[0]] : prev)); //get the first selected force
    props.setEnableReorder && props.setEnableReorder((prev: boolean) => !prev);
  };

  const changeForceParent = async (
    forcesToReorder: IForceTreeNode[],
    newParentId: number
  ) => {
    try {
      //for each one of the dragged forces send there details to the backend
      let result = await Promise.all(
        forcesToReorder.map((forceToReorder) => {
          return axios.post(`${baseUrlPMBackend}forces/updateParentId`, {
            newParentId: newParentId,
            forceId: forceToReorder.id,
            oldParentId: forceToReorder.parent_id,
          });
        })
      );
      if (result?.find((res) => res?.status !== 200)) {
        customToast.error(t("updateForcesError"));
      } else {
        setChecked([]);
        customToast.success(t("forceUpdateSuccessMsg"));
        checkRelatedForceAndGetTree(true);
      }
    } catch (e) {
      console.error(e);
      customToast.error(t("updateForcesError"));
    }
  };
  return (
    <IonGrid
      className="tree-col-grid"
      onDrop={() => {
        props.draggedElement?.remove();
      }}
    >
      <IonCol>
        <IonRow className="header-row">
          {!props.isMobile && (
            <PMTitle fontColor="light" fontFamily="Regular" fontSize="large">
              {t("forcesTree")}
            </PMTitle>
          )}
          {!props.readonly &&
            !props.disableEditing &&
            (props.isAdmin ? (
              <>
                <PMTooltip id="reorderIcon" />
                <div
                  className="orbatHeaderIcon"
                  data-tooltip-id="reorderIcon"
                  data-tooltip-content={t("ReorderBtnTooltip")}
                >
                  <PMIcon
                    isButton={false}
                    iconSrc={EIconsSrc.SWITCH_FORCE}
                    onClick={toggleReorderBtn}
                    color={"xLight"}
                    size="large"
                  />
                </div>
                <PMTooltip id="importIcon" />

                <div
                  className={`file-import-button orbatHeaderIcon`}
                  data-tooltip-id="importIcon"
                  data-tooltip-content={t("ImportBtnTooltip")}
                >
                  <ImportFile
                    isSuccess={loadTreeTrigger}
                    checkedForce={checkedForce}
                    onClick={() => {
                      setOptionState("import");
                      props.setEnableReorder && props.setEnableReorder(false);
                    }}
                    iconColor={
                      optionState !== "reorder" && checkedForce.id !== undefined
                        ? "xLight"
                        : "disabledColorI"
                    }
                  />
                </div>
                <PMTooltip id="addIcon" />
                <div
                  className="orbatHeaderIcon"
                  data-tooltip-id="addIcon"
                  data-tooltip-content={t("addBtnTooltip")}
                >
                  <PMIcon
                    iconSrc={EIconsSrc.ADD_FORCE}
                    disabled={checkedForce.is_soldier || !checkedForce.id}
                    onClick={() => {
                      setOptionState("add");
                      addForce(forces);
                      props.setEnableReorder && props.setEnableReorder(false);
                    }}
                    color={
                      optionState !== "reorder" && checkedForce
                        ? "xLight"
                        : "disabledColorI"
                    }
                    size="large"
                  />
                </div>
              </>
            ) : null)}
        </IonRow>
        <IonRow>
          <Dropdown
            isForcesTreeSearch={true}
            showIcon
            isForDropDown={props.isForDropDown}
            defaultValue={{
              label: "",
              value: {
                id: checkedForce.id,
                name: checkedForce.name,
                soldier_id: checkedForce.soldier_id,
                tag_id: checkedForce.tag_id,
                weapon_id: checkedForce.weapon_id,
                weapon_type: checkedForce.weapon_type,
                parent: checkedForce.parent_id,
                weapon_sight: checkedForce.weapon_sight,
                weapon_sight_id: checkedForce.weapon_sight_id,
                force_type: checkedForce.force_type,
                magazine_id: checkedForce.magazine_id,
                laser_id: checkedForce.laser_id,
                head_sensor_id: checkedForce.head_sensor_id,
                personal_id: checkedForce.personal_id,
              },
            }}
            options={
              flatTree.length
                ? flatTree.map((e: IForcePointer) => {
                    return {
                      label: getForceFullName(e.name, e.force_type, t),
                      value: e,
                    };
                  })
                : []
            }
            onSelect={(option) => {
              getSelectedForce(option);
            }}
          />
        </IonRow>
        <IonRow className="tree-row">
          <IonCol
            className={`tree-scroll ${
              props.isForDropDown ? "dropDownTree" : ""
            }`}
          >
            {isLoading ? (
              <Spinner className="spinner" />
            ) : thereIsForces && forces.id ? (
              <TreeNode
                node={forces}
                onChoose={onCheckForce}
                checkedForces={checked}
                onDelete={onDeleteForce}
                readonly={props.readonly}
                path={path}
                limit={props.limit}
                removeFromPath={removeFromPath}
                enableReorder={props.enableReorder === true}
                onReorder={changeForceParent}
                isAdmin={props.isAdmin}
                isReport={props.isReport}
                setOpenAlert={setOpenAlert}
                disableEditing={props.disableEditing}
                displayPlatoonAndAbove={props.displayPlattonAndAbove === true}
                isRootDisable={props.isRootDisable}
                setDraggedElement={props.setDraggedElement}
                draggedElement={props.draggedElement}
              />
            ) : (
              <PMLabel fontSize="medium" fontFamily="Bold" fontColor="light">
                {user.forceToDisplayInOrbat || user.role === userRoles.Admin
                  ? t("forcesNotExist")
                  : t("forcesNotAssigned")}
              </PMLabel>
            )}
            <Alert
              isOpen={openAlert}
              setIsOpen={setOpenAlert}
              header={`${t("maxChoosingHeader1")} ${props.limit} ${t(
                "maxChoosingHeader2"
              )}`}
              subHeader={t("cancelChoosing")}
              actionOnSave={closeAlert}
            />
          </IonCol>
        </IonRow>
      </IonCol>
    </IonGrid>
  );
};

export default OrbatTree;
