import React, { useContext } from "react";
import ForcesListContext from "../../../context/ForcesContext/forcesListContext";
import ISimpleTrainee from "../../../Interfaces/ISimpleTrainee";
import "./SoldiersListEditor.css";
import SimpleSelect from "./SimpleSelect";

const SoldiersListEditor: React.FC<{
  row: any;
  onRowChange: any;
  column: any;
  value: string;
}> = (props: { row: any; onRowChange: any; column: any; value: any }) => {
  const { soldiersNamesAndIds } = useContext(ForcesListContext);

  return soldiersNamesAndIds ? (
    <div className="editable-cell" id="editable-cell">
      <SimpleSelect
        onSelect={(value) => {
          let soldier;
          if (soldiersNamesAndIds) {
            soldier = soldiersNamesAndIds?.find(
              (soldier: ISimpleTrainee) =>
                String(soldier.id) === String(value?.value.id)
            );
          }
          let newRow = {
            ...props.row,
            [props.column.key]: soldier ? soldier?.name : value?.value.id,
          };

          if (soldier) {
            if (soldier.name !== "") {
              newRow["soldierId"] = soldier?.id;
            } else {
              newRow["soldierId"] = "";
            }
            if (soldier.id + "" !== "") {
              newRow["soldierName"] = soldier?.name;
            } else {
              newRow["soldierName"] = "";
            }
          } else {
            newRow["soldierId"] = "";
          }
          props.onRowChange(newRow);
        }}
        options={soldiersNamesAndIds.map((soldier) => ({
          label: soldier.name,
          value: soldier,
        }))}
        value={
          props.value
            ? {
                label: props.value,
                value: props.value,
              }
            : null
        }
        placeholder="soldierListDropdownPlaceholder"
      ></SimpleSelect>
    </div>
  ) : (
    <></>
  );
};

export default SoldiersListEditor;
