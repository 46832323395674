import React, { ReactNode, useState } from "react";
import IStationDetails from "../../Interfaces/IStationDetails";
interface IStationsContext {
  stations: IStationDetails[];
  setStations: React.Dispatch<React.SetStateAction<IStationDetails[]>>;
}

export const StationsContext = React.createContext<IStationsContext>({
  stations: [],
  setStations: () => {},
});

const StationsContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [stations, setStations] = useState<IStationDetails[]>([]);
  return (
    <StationsContext.Provider value={{ stations, setStations }}>
      {children}
    </StationsContext.Provider>
  );
};

export default StationsContextProvider;
