import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useContext, useEffect, useState } from "react";
import axios from "../../Axios";
import {
  scope,
  apiUri,
  environment,
  baseUrlPMBackend,
  appModeEnv,
} from "../../Configurations/consts";
import { UserContext } from "../../context/UserContext/userContext";
import EEnvironment from "../../Enums/EEnvironment";
import IUser from "../../Interfaces/IUser";
import { useTranslation } from "react-i18next";
import customToast from "../Shared/Toast/CustomToast";
import { MarketingContext } from "../../context/MarketingContext/MarketingContext";
import { AppModeContext } from "../../context/AppModeContext/AppModeContext";
import { EAppMode } from "../../Enums/EAppMode";

const useLogin = () => {
  const { setIsMarketing } = useContext(MarketingContext);
  //if the environment is marketing or the route came with the marketing starting
  const {
    login,
    isLoggedIn,
    setIsLoginLoading,
    isLoginLoading,
    setIsLocalLogin,
    isLocalLogin,
    setUseLockoutTime,
    userLockoutTime,
  } = useContext(UserContext);
  const { t } = useTranslation();
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { setAppMode } = useContext(AppModeContext);
  const [isPersonalZone, setIsPersonalZone] = useState<boolean>(false);

  const localSiteLogin = (username: string, password: string) => {
    setIsLoginLoading(true);
    localStorage.setItem("loginWithMicrosoft", "false");
    let isMarketing = false;
    if (environment === EEnvironment.staging) {
      isMarketing = true;
    }
    if (localStorage.getItem("access_token"))
      axios.defaults.headers.common["Authorization"] =
        localStorage.getItem("access_token");
    if (username && password) {
      axios
        .get(`${baseUrlPMBackend}users/login`, {
          params: {
            username: username,
            password: password,
            isMarketing: isMarketing,
          },
        })
        .then((res) => {
          if (res?.data) {
            if (isMarketing) setIsLocalLogin(true);
            axios.defaults.headers.common[
              "Authorization"
            ] = `${res.data.accessToken}`;
            axios.defaults.headers.common["isMarketing"] = `${isMarketing}`;
            localStorage.setItem("access_token", res.data.accessToken);
            localStorage.setItem("isMarketing", String(isMarketing));
            localStorage.setItem("user_name", res.data.emailAddress);
            setIsMarketing(isMarketing);

            loginAfterAuthentication(res.data);
            setIsLoginLoading(false);
          } else {
            customToast.error(t("wrongCredentials"));
          }
        })
        .catch((err) => {
          if (err.response.data.lockoutSeconds) {
            customToast.error(t("userLockout"));
            setUseLockoutTime(Number(err.response.data.lockoutSeconds));
          } else {
            customToast.error(t("wrongCredentials"));
          }
          setIsLoginLoading(false);
          setIsLocalLogin(true);
        });
    } else {
      customToast.error(t("missingInformation"));
    }
  };

  const authorizeUser = async (
    userEmailAddress: string,
    access_token: string
  ) => {
    axios.defaults.headers.common["Authorization"] = access_token;
    axios.defaults.headers.common["isMarketing"] = `${localStorage.getItem(
      "isMarketing"
    )}`;
    let userResponse: any = await axios.get(
      `${baseUrlPMBackend}users/authUser`,
      {
        params: {
          emailAddress: userEmailAddress,
          isMarketing: localStorage.getItem("isMarketing"),
        },
      }
    );
    localStorage.setItem("user_name", userEmailAddress);
    let user: IUser = userResponse?.data;
    loginAfterAuthentication(user);
  };

  const loginAfterAuthentication = (user: IUser) => {
    if (user) {
      setIsLoginLoading(false);
      setIsPersonalZone(user?.relatedForce !== undefined);
      setIsMarketing(localStorage.getItem("isMarketing") === "true");
      setAppModeForUser(user);
      login({ ...user });
    } else {
      setIsLoginLoading(false);
      setIsLocalLogin(true);
    }
  };

  const setAppModeForUser = (user: IUser) => {
    if (user.appMode) setAppMode(EAppMode[user.appMode as EAppMode]);
    else if (localStorage.getItem("appMode") !== null) {
      setAppMode(EAppMode[localStorage.getItem("appMode") as EAppMode]);
    } else if (appModeEnv) {
      //set the app mode from the environment variables
      setAppMode(EAppMode[appModeEnv as EAppMode]);
      localStorage.setItem("appMode", appModeEnv);
    }
  };

  const getIsActiveDirectoryAccountObjAndToken = async () => {
    let tokenResponse = await instance.handleRedirectPromise();
    let accountObj;
    if (tokenResponse) {
      accountObj = tokenResponse.account;
    } else {
      accountObj = await instance.getAllAccounts()[0];
    }
    return [accountObj, tokenResponse];
  };

  const saveToken = (accessToken: string) => {
    console.log("[AuthService.init] Got valid accountObj and tokenResponse");
    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    localStorage.setItem("access_token", `Bearer ${accessToken}`);
  };

  const redirectToLogin = async () => {
    console.log(
      "[AuthService.init] No accountObject or tokenResponse present. User must now login."
    );
    if (inProgress === "none" && !isLoggedIn) {
      await instance
        .loginRedirect({
          scopes: [scope ? apiUri!.toString() + "/" + scope.toString() : ""],
        })
        .then((e) => {
          console.log(e);
        })
        .catch((error) => {
          console.log(error);
        });
      window.location.reload();
    }
  };

  const catchError = (error: any) => {
    console.error(
      "[AuthService.init] Failed to handleRedirectPromise()",
      error
    );

    window.location.reload();
  };

  const createTokenByAccount = async (accountObj: any) => {
    try {
      let tokenResponse;

      if (accountObj) {
        try {
          tokenResponse = await instance.acquireTokenSilent({
            account: accountObj,
            scopes: [scope ? apiUri!.toString() + "/" + scope.toString() : ""],
          });
        } catch (err) {
          tokenResponse = await instance.acquireTokenSilent({
            scopes: [scope ? apiUri!.toString() + "/" + scope.toString() : ""],
          });
        }
        if (tokenResponse) {
          saveToken(tokenResponse.accessToken);
          await authorizeUser(
            accountObj.username,
            `Bearer ${tokenResponse.accessToken}`
          );
        } else {
          await redirectToLogin();
        }
      } else {
        await redirectToLogin();
      }
    } catch (error) {
      await redirectToLogin();
      console.log(error);
    }
  };

  const loginWithMicrosoft = async () => {
    try {
      localStorage.setItem("loginWithMicrosoft", "true");

      axios.defaults.headers.common["Authorization"] = ``;
      localStorage.removeItem("access_token");
      let [accountObj, tokenResponse]: any =
        await getIsActiveDirectoryAccountObjAndToken();
      if (accountObj && tokenResponse) {
        saveToken(tokenResponse.accessToken);
        await authorizeUser(
          accountObj.username,
          `Bearer ${tokenResponse.accessToken}`
        );
      } else {
        if (accountObj) await createTokenByAccount(accountObj);
        else {
          let accountObj = await instance.getAllAccounts()[0];
          await createTokenByAccount(accountObj);
        }
      }
    } catch (error) {
      catchError(error);
      return false;
    }
  };
  const refreshToken = async () => {
    axios.defaults.headers.common["Authorization"] = ``;
    localStorage.removeItem("access_token");
    let accountObj = await instance.getAllAccounts()[0];

    await createTokenByAccount(accountObj);
  };

  useEffect(() => {
    if (
      localStorage.getItem("loginWithMicrosoft") === "true" ||
      (localStorage.getItem("user_name") &&
        localStorage.getItem("access_token"))
    ) {
      if (localStorage.getItem("loginWithMicrosoft") !== "true") {
        authorizeUser(
          localStorage.getItem("user_name") || "",
          localStorage.getItem("access_token") || ""
        );
      }
      setIsLoginLoading(true);
      setIsLocalLogin(false);
    } else if (
      environment === EEnvironment.staging ||
      environment === EEnvironment.localSite
    ) {
      setIsLoginLoading(false);
      setIsLocalLogin(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthenticated && !isLoggedIn) {
      loginWithMicrosoft();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);
  useEffect(() => {
    if (environment === EEnvironment.production) {
      loginWithMicrosoft();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgress]);

  return {
    isPersonalZone,
    localSiteLogin,
    isLoginLoading,
    loginWithMicrosoft,
    refreshToken,
    isLocalLogin,
    setIsLocalLogin,
    setIsLoginLoading,
    userLockoutTime,
    setUseLockoutTime,
  };
};

export default useLogin;
