import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import Axios from "../../../Axios";
import { baseUrlPMBackend } from "../../../Configurations/consts";
import { ForceTypesContext } from "../../../context/ForcesTypes/ForcesTypesContext";
import IForceType from "../../../Interfaces/IForceType";
import customToast from "../../Shared/Toast/CustomToast";

/**
 *
 * @returns array with of force types
 */
const asyncFetchForcesTypes = async () => {
  const res = await Axios.get(`${baseUrlPMBackend}forces/getForceTypes`);
  return res.data;
};

export const useForceTypes = () => {
  const { t } = useTranslation();
  const { forceTypes, setForceTypes } = useContext(ForceTypesContext);

  let { data, isLoading, isError } = useQuery<IForceType[]>({
    queryKey: ["forceTypes"],
    queryFn: asyncFetchForcesTypes,
    enabled: !forceTypes.length,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!isLoading && !isError && data && data.length) {
      //det the context with the force types data
      setForceTypes(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (isError) {
      customToast.error(t("errorWhileLoadingForceTypes"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return { forceTypes };
};
