import { IonCol, IonGrid, IonItem, IonModal, IonRow } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import IHeat from "../../../../Interfaces/IHeat";
import { heatList } from "../../../Mobile/ManualStation/ShootingRangeDataPosting/heatList";
import PMLabel from "../../../themeComponents/PMLabel";
import "./HeatsModal.css";

interface IHeatsModalProps {
  onDismiss: () => void;
  onHeatSelect: (heat: IHeat) => void;
  isOpen: boolean;
}

const HeatsModal = (props: IHeatsModalProps) => {
  const { t } = useTranslation();

  return (
    <IonModal
      className="heatsModal"
      isOpen={props.isOpen}
      onDidDismiss={props.onDismiss}
      keyboardClose
    >
      <IonGrid className="heatsGrid">
        <IonCol className="heatsCol" size="12">
          <IonRow className="headerRow">
            <PMLabel fontSize="large" fontColor="xLight" fontFamily="Regular">
              {t("selectHeat")}
            </PMLabel>
          </IonRow>
          <IonRow className="heatsRow">
            {heatList.map((heat: IHeat) => (
              <IonRow
                key={heat.id}
                className="heatRow"
                onClick={() => props.onHeatSelect(heat)}
              >
                <IonItem className="heatItem">
                  <PMLabel
                    fontSize="medium"
                    fontColor="light"
                    fontFamily="Regular"
                  >
                    {heat.name + ` (${heat.bullets})`}
                  </PMLabel>
                </IonItem>
              </IonRow>
            ))}
          </IonRow>
        </IonCol>
      </IonGrid>
    </IonModal>
  );
};

export default HeatsModal;
