import { IonCard, IonCol, IonItem, IonRow, IonText } from "@ionic/react";
import React from "react";
import "./PMToggle.css";

interface ILeadersToggle {
  firstOptionText: string;
  secondOptionText: string;
  state: boolean;
  setState: (state: boolean) => void;
}
const LeadersToggle: React.FC<ILeadersToggle> = (props: ILeadersToggle) => {
  return (
    <div className="toggleRow">
      <IonCol className="toggleCol">
        <IonItem
          lines="none"
          onClick={() => props.setState(true)}
          className={props.state ? "toggleItem selectedToggle" : "toggleItem"}
        >
          <IonText className="toggleText">{props.firstOptionText}</IonText>
        </IonItem>
      </IonCol>
      <IonCol className="toggleCol">
        <IonItem
          lines="none"
          onClick={() => props.setState(false)}
          className={!props.state ? "toggleItem selectedToggle" : "toggleItem"}
        >
          <IonText className="toggleText">{props.secondOptionText}</IonText>
        </IonItem>
      </IonCol>
    </div>
  );
};
export default LeadersToggle;
