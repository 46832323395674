import { Dispatch, FC, SetStateAction, useContext } from "react";
import IForceTreeNode from "../../../../Interfaces/IForceTreeNode";
import "./OrbatInput.css";
import Dropdown from "../../SearchDropdown/SearchDropdown";
import IForcePointer from "../../../../Interfaces/IForcePointer";
import { getForceFullName } from "../../../../services/helpers";
import PMLabel from "../../../themeComponents/PMLabel";
import Alert from "../../Alert/Alert";
import Spinner from "../../Spinner/Spinner";
import TreeNode from "../OrbatTree/TreeNode/TreeNode";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../../context/UserContext/userContext";
import OrbatTreeHook from "../../../CustomHooks/OrbatTreeHook";
import PMCollapse from "../../PMCollapse/PMCollapse";

type Props = {
  limit?: number;
  inputValue: string;
  placeholder: string;
  selectedForces: IForceTreeNode[];
  setSelectedForces: Dispatch<SetStateAction<IForceTreeNode[]>>;
  isForDropDown?: boolean;
  isRootDisable?: boolean;
  inputWidth: "long" | "short" | "mid";
  collapseCssClass: string;
  wrapperCss: string;
};

const OrbatInput: FC<Props> = (props: Props): JSX.Element => {
  const {
    limit,
    selectedForces,
    isForDropDown,
    isRootDisable,
    setSelectedForces,
  } = props;
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  const {
    checkedForce,
    flatTree,
    getSelectedForce,
    isLoading,
    thereIsForces,
    forces,
    onCheckForce,
    checked,
    path,
    removeFromPath,
    setOpenAlert,
    openAlert,
    closeAlert,
  } = OrbatTreeHook(selectedForces, setSelectedForces, limit, true);

  return (
    <PMCollapse cssClass="treeCollaps"
      placeholder={t("selectForce")}
      label={
        selectedForces.length
          ? `${getForceFullName(
              selectedForces[0].name,
              selectedForces[0].force_type,
              t
            )} ${
              selectedForces.length - 1 > 0
                ? `+ ${selectedForces.length - 1}`
                : ""
            }`
          : undefined
      }
    >
      <div className="searchDropDownWrap scrollS">
        <Alert
          isOpen={openAlert}
          setIsOpen={setOpenAlert}
          header={`${t("maxChoosingHeader1")} ${props.limit} ${t(
            "maxChoosingHeader2"
          )}`}
          subHeader={t("cancelChoosing")}
          actionOnSave={closeAlert}
        />
        <Dropdown
          height={40}
          isForcesTreeSearch={true}
          showIcon
          isForDropDown={isForDropDown}
          defaultValue={{
            label: "",
            value: {
              id: checkedForce.id,
              name: checkedForce.name,
              soldier_id: checkedForce.soldier_id,
              tag_id: checkedForce.tag_id,
              weapon_id: checkedForce.weapon_id,
              weapon_type: checkedForce.weapon_type,
              parent: checkedForce.parent_id,
              weapon_sight: checkedForce.weapon_sight,
              weapon_sight_id: checkedForce.weapon_sight_id,
              force_type: checkedForce.force_type,
              magazine_id: checkedForce.magazine_id,
              laser_id: checkedForce.laser_id,
              head_sensor_id: checkedForce.head_sensor_id,
              personal_id: checkedForce.personal_id,
            },
          }}
          options={
            flatTree.length
              ? flatTree.map((e: IForcePointer) => {
                  return {
                    label: getForceFullName(e.name, e.force_type, t),
                    value: e,
                  };
                })
              : []
          }
          onSelect={getSelectedForce}
        />
      </div>
      {isLoading ? (
        <Spinner className="spinner" />
      ) : thereIsForces && forces.id ? (
        <TreeNode
          node={forces}
          onChoose={onCheckForce}
          checkedForces={checked}
          readonly={true}
          path={path}
          limit={props.limit}
          removeFromPath={removeFromPath}
          enableReorder={false}
          isAdmin={false}
          isReport={false}
          setOpenAlert={setOpenAlert}
          disableEditing={true}
          displayPlatoonAndAbove={false}
          isRootDisable={isRootDisable}
        />
      ) : (
        <PMLabel fontSize="medium" fontFamily="Bold" fontColor="light">
          {user.forceToDisplayInOrbat
            ? t("forcesNotExist")
            : t("forcesNotAssigned")}
        </PMLabel>
      )}
    </PMCollapse>
  );
};

export default OrbatInput;
