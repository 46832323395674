import { TFunction } from "i18next";
import IForceTreeNode from "../Interfaces/IForceTreeNode";
import { getForceFullName } from "./helpers";
import Papa from "papaparse";

export const flattenTreeNodes = (node: IForceTreeNode) => {
  let flattenedNodes = [node];
  if (node.nodes && node.nodes.length > 0) {
    node.nodes.forEach((childNode) => {
      flattenedNodes = flattenedNodes.concat(flattenTreeNodes(childNode));
    });
  }
  return flattenedNodes;
};
export const exportOrbat = (force: IForceTreeNode, t: TFunction) => {
  let flatTree = flattenTreeNodes(force);
  const csvData = Papa.unparse({
    fields: [
      "Force ID",
      "Trainee ID",
      "Trainee Name",
      "Force Parent ID",
      "Magazine Number",
    ],
    data: flatTree.map((node) => ({
      "Force ID": node.id,
      "Trainee ID": node.soldier_id || "",
      "Trainee Name": getForceFullName(node.name, node.force_type, t),
      "Force Parent ID":
        force.id === node.id ? "null" : node.parent_id || "null",
      "Magazine Number": node.magazine_id || "",
    })),
  });

  const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csvData], {
    type: "text/csv;charset=utf-8;",
  });
  const link = document.createElement("a");
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `${getForceFullName(force.name, force.force_type, t)}-orbat.csv`
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
