import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import PMLabel from "../../themeComponents/PMLabel";
import "./NotExistLabel.css";
type Props = { text: string; className?: string };

const NotExistLabel: FC<Props> = (props: Props) => {
  const { text, className } = props;
  const { t } = useTranslation();

  return (
    <div className={className ? className : ""}>
      <PMLabel
        textAlign="center"
        fontFamily="Light"
        fontSize="xxLarge"
        fontColor="light"
      >
        {t(text)}
      </PMLabel>
    </div>
  );
};

export default NotExistLabel;
