import React, { FC } from "react";
import { IonRow, IonText } from "@ionic/react";
import PMIcon from "../../themeComponents/PMIcon";
import "./MobileTitleRow.css";
import { useTranslation } from "react-i18next";

interface IProps {
  title?: string;
  icon?: boolean;
  iconSrc?: string;
  onIconClick?: () => void;
}

const MobileTitleRow: FC<IProps> = ({
  title,
  icon,
  iconSrc,
  onIconClick,
}: IProps) => {
  const { t } = useTranslation();
  return (
    <IonRow className="titleRowSBL">
      <div className="icon-in-header">
        <IonText className="titleSBL">{title ? t(title) : title}</IonText>
        <div className="mobile-header-icon">
          {icon && iconSrc && onIconClick ? (
            <PMIcon
              iconSrc={iconSrc}
              size="large"
              color="light"
              onClick={() => onIconClick()}
            />
          ) : null}
        </div>
      </div>
    </IonRow>
  );
};

export default MobileTitleRow;
