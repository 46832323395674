import { useEffect, useState } from "react";
import { getIO } from "./socketIo";

const forcesUpdateMessage = "forces-update-message";

interface IMessageForcesUpdate {
  isToUpdateForces: boolean;
  date: Date;
}

const useWebsocketUpdateForcesList = () => {
  const [updateForcesMassage, setUpdateForcesMassage] =
    useState<IMessageForcesUpdate>();

  useEffect(() => {
    const io = getIO();
    io?.on(forcesUpdateMessage, (message: IMessageForcesUpdate) => {
      setUpdateForcesMassage(message);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [updateForcesMassage];
};

export { useWebsocketUpdateForcesList };
