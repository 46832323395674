const colors = [
  "#785EC3",
  "#454EA0",
  "#659CB4",
  "#8FEFE4",
  "#5F7E0C",
  "#BC5151",
  
];
export default colors;
