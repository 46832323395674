import { IonCol, IonLabel, IonRow } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import Station from "./Station";

import { ITrainingType } from "../../../Interfaces/ITrainingPlan";
import "./Stations.css";
import { ITrainingPlanProps } from "../../../Interfaces/TrainingPlanContext/ITrainingPlan";

interface IStationsProps extends ITrainingPlanProps {
  trainingTypes: ITrainingType[] | undefined;
}

const Stations: React.FC<IStationsProps> = (props: IStationsProps) => {
  const { trainingTypes } = props;
  const { t, i18n } = useTranslation();

  return (
    <IonCol size="12" className="stationsCol ">
      <IonRow className="headersWrapper">
        <IonCol size="12" className="stationsCol ">
          <IonRow className="stationsHeadersRow ">
            <IonCol size="2.55">
              <IonLabel className="stationsHeader">{t("station")}</IonLabel>
            </IonCol>
            <IonCol size="2.58">
              <IonLabel className="gradeHeader stationsHeader">
                {t("stationWeight")}
              </IonLabel>
            </IonCol>
            <IonCol size="2.58" className="stThresholdCol">
              <IonLabel className="thresholdHeader stationsHeader">
                {t("thresholdGrade")}
              </IonLabel>
            </IonCol>
            <IonCol size="2.58"></IonCol>
            <IonCol size="1.33"></IonCol>
          </IonRow>
        </IonCol>
      </IonRow>

      <IonRow className="stationsRow">
        {trainingTypes &&
          trainingTypes
            .sort((a, b) =>
              t(a.name).localeCompare(t(b.name), i18n.language, {
                numeric: true,
                sensitivity: "base",
              })
            )
            .map((trainingType, index) => (
              <Station
                key={index}
                trainingType={trainingType}
                setIsErrorExist={props.setIsErrorExist}
                elementsForDashboard={props.elementsForDashboard}
              ></Station>
            ))}
      </IonRow>
    </IonCol>
  );
};

export default Stations;
