import { IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import IFinalGradeDisplay from "../../../../Interfaces/IFinalGradeDisplay";
import IForceFromOrbat from "../../../../Interfaces/IForceFromOrbat";
import useGetPerformanceGrades, {
  FilteredIndicatorsGrades,
} from "../../../CustomHooks/useGetPerformanceGrades";
import useGetPerformanceTrends from "../../../CustomHooks/UseGetPerformaneTrends";
import useInitGraphsData from "../../../CustomHooks/useInitGraphsData";
import "./Competency.css";
import i18n from "../../../../services/i18next";
import RadarGraph from "../../../Shared/Charts/Radar/RadarGraph";
import FinalGradeDisplay from "../../../Shared/FinalGradeDisplay/FinalGradeDisplay";
import BarChart from "../../../Shared/Charts/Bar/BarChart";
import IndicatorBarChart from "../../IndicatorBarChart/IndicatorBarChart";

import { Swiper, SwiperSlide } from "swiper/react";
import EIconsSrc from "../../../../Interfaces/EIconsSrc";
import ITrendIndicator from "../../../../Interfaces/ITrendIndicator";
import PMIcon from "../../../themeComponents/PMIcon";
import Spinner from "../../../Shared/Spinner/Spinner";
import PMLabel from "../../../themeComponents/PMLabel";
import PMLoading from "../../../Shared/Alert/PMLoading";
import { UserContext } from "../../../../context/UserContext/userContext";
import usePlans from "../../../CustomHooks/usePlans";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import { isTwoLevelsAtLeast } from "../../../../services/treeSharedFunctions";
import IForceTreeNode from "../../../../Interfaces/IForceTreeNode";
import { getForceById, getForceFullName } from "../../../../services/helpers";
import CommanderDashboardComponent from "../../../../pages/Desktop/PerformanceDisplay/PerformanceTabs/CommanderDashboard/CommanderDashboardComponent";
import OrbatTreeHook from "../../../CustomHooks/OrbatTreeHook";
import { useGetRelevantPlans } from "../../../CustomHooks/HooksWithRQ/useGetRelevantPlans";
import PlansDropdown from "../../../Shared/PlansDropdown/PlansDropdown";
import ELanguage from "../../../../Enums/ELanguage";
import { EGraphType } from "../../../../Enums/EGraphType";

const Competency = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [isDashboardLoading, setIsDashboardLoading] = useState<boolean>(true);
  const [isPlansLoading, setIsPlansLoading] = useState<boolean>(true);
  const [isGradesLoading, setIsGradesLoading] = useState<boolean>(true);
  const [force, setForce] = useState<IForceFromOrbat[]>([]);
  const [orbatForces, setOrbatForces] = useState<IForceTreeNode[]>([]);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [currentSwiperPage, setCurrentSwiperPage] = useState<number>(0);
  const [swiperRef, setSwiperRef] = useState<any>(null);
  const [checked, setChecked] = useState<IForceTreeNode[]>([]);

  const { forces } = OrbatTreeHook(checked, setChecked, undefined);
  const { relevantPlansIds } = useGetRelevantPlans(
    force.map((force) => force.id)
  );
  const {
    plans,
    plansOptions,
    selectedPlan,
    onPlanSelectedHandler,
    plansValuesForSelectedPlan,
    globalPlans,
  } = usePlans(relevantPlansIds, true, true);

  useEffect(() => {
    if (user.relatedForce) {
      setForce([
        {
          forceType: user.relatedForce.force_type,
          id: user.relatedForce.id,
          isSoldier: user.relatedForce.nodes === null,
          name: user.relatedForce.name,
          color: "#2334B5",
        },
      ]);
    }
  }, [user]);

  useEffect(() => {
    if (force.length && forces) {
      let forceFromOrbat = getForceById(forces, force[0].id);
      if (forceFromOrbat) setOrbatForces([forceFromOrbat]);
    }
  }, [force, forces]);

  const { indicatorsGrades, trainingTypesGrades, planDetailsFinalGrades } =
    useGetPerformanceGrades(
      selectedPlan,
      force,
      [],
      [],
      setIsGradesLoading,
      undefined,
      undefined
    );

  const {
    labelsCompetency,
    labelsTrends,
    labelsDetails,
    thresholdsDetails,
    thresholdsCompetency,
    thresholdsTrends,
    trendsIndicators,
  } = useInitGraphsData(plansValuesForSelectedPlan, globalPlans);

  const { trendsBarChartsData } = useGetPerformanceTrends(
    plans,
    force,
    [],
    [],
    trendsIndicators,
    () => {}
  );

  useEffect(() => {
    if (selectedPlan) {
      setIsDataLoading(true);
      setIsPlansLoading(false);
    }
  }, [selectedPlan]);

  useEffect(() => {
    if (
      !trainingTypesGrades.length &&
      !planDetailsFinalGrades.length &&
      !indicatorsGrades.length
    )
      setIsDataLoading(true);
    else {
      setIsDataLoading(false);
    }
  }, [trainingTypesGrades, planDetailsFinalGrades, indicatorsGrades]);

  const trendsChart = () => {
    return trendsBarChartsData.length ? (
      <div className="barPersonalZone">
        <PMLabel
          fontColor="light"
          fontSize="large"
          fontFamily="Light"
          cssClass="trendsTitle"
        >
          {t(trendsIndicators[currentSwiperPage].name)}
        </PMLabel>
        <Swiper
          className="pzSwiper"
          spaceBetween={50}
          slidesPerView={1}
          allowTouchMove
          loop
          onSlideChange={(event) => {
            setCurrentSwiperPage(event.realIndex);
          }}
          onSwiper={setSwiperRef}
        >
          {trendsIndicators.map((indicator: ITrendIndicator) => (
            <SwiperSlide key={indicator.id}>
              <div className="trendSlide" key={indicator.id}>
                <IndicatorBarChart
                  key={indicator.id}
                  indicatorId={indicator.id}
                  thresholds={thresholdsTrends}
                  labels={labelsTrends} //if we want relevant plans just switch to relevantLabelsTrends
                  selectedForces={force}
                  newForces={force}
                  forcesToRemove={undefined}
                  barChartsData={trendsBarChartsData}
                  isDesktop={true}
                  plan={selectedPlan}
                  trends
                  lineOrBarTypeToggle={EGraphType.bar}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="arrows">
          <IonRow class="ion-justify-content-center">
            <PMIcon
              isButton
              iconSrc={
                i18n.language === ELanguage.he
                  ? EIconsSrc.CHEVRON_RIGHT
                  : EIconsSrc.CHEVRON_LEFT
              }
              onClick={() => swiperRef.slidePrev()}
              size="xLarge"
              color="light"
            />
            <PMIcon
              isButton
              iconSrc={
                i18n.language === ELanguage.he
                  ? EIconsSrc.CHEVRON_LEFT
                  : EIconsSrc.CHEVRON_RIGHT
              }
              onClick={() => swiperRef.slideNext()}
              size="xLarge"
              color="light"
            />
          </IonRow>
        </div>
      </div>
    ) : (
      <Spinner className="competency-spinner" />
    );
  };

  return (
    <IonPage>
      <IonContent>
        <PMLoading
          isOpen={
            isDataLoading ||
            isGradesLoading ||
            (isTwoLevelsAtLeast(orbatForces[0]) && isDashboardLoading)
          }
          message={t("loadingData")}
          spinner="bubbles"
        ></PMLoading>
        {isPlansLoading ? (
          <Spinner className="competency-spinner" />
        ) : (
          <IonGrid className="compGrid scrollL">
            <IonRow className="compDropRow">
              <IonCol size="12">
                <div className="headerPersonalRow">
                  <PMLabel
                    fontColor="light"
                    fontFamily="Regular"
                    fontSize="large"
                  >
                    {getForceFullName(force[0].name, force[0].forceType, t)}
                  </PMLabel>
                  <PlansDropdown
                    onPlanSelectedHandler={onPlanSelectedHandler}
                    plansOptions={plansOptions}
                    selectedPlan={selectedPlan}
                  ></PlansDropdown>
                </div>
              </IonCol>
            </IonRow>
            <IonRow className="stRow">
              <IonCol size="2" className="compCol lethality">
                {planDetailsFinalGrades.map(
                  (grade: IFinalGradeDisplay, gradeIdx: number) => (
                    <FinalGradeDisplay
                      key={gradeIdx}
                      soldierFinalGrade={grade.soldierFinalGrade}
                      threshold={grade.threshold}
                      isLegend={false}
                    />
                  )
                )}
              </IonCol>
              <IonCol size="3.73" className="compCol">
                <RadarGraph
                  id="csvRadarImage"
                  radarData={FilteredIndicatorsGrades(
                    indicatorsGrades,
                    labelsCompetency
                  )}
                  selectedForces={force}
                  thresholds={thresholdsCompetency}
                  labels={labelsCompetency}
                  newForces={force}
                  forcesToRemove={undefined}
                  plan={selectedPlan}
                />
              </IonCol>
              <IonCol size="6" className="compCol">
                <BarChart
                  id="csvBarImage"
                  barChartData={trainingTypesGrades}
                  thresholds={thresholdsDetails}
                  labels={labelsDetails}
                  selectedForces={force}
                  newForces={force}
                  forcesToRemove={undefined}
                  barsType={"trainingTypes"}
                  plan={selectedPlan}
                  isDesktop
                />
              </IonCol>
            </IonRow>
            <IonRow className="ndRow">
              <IonCol size="11.9" className="compCol plansAndDashboard">
                {isTwoLevelsAtLeast(orbatForces[0]) ? (
                  <CommanderDashboardComponent
                    commanderForces={force}
                    plansValuesForSelectedPlan={plansValuesForSelectedPlan}
                    selectedPlan={selectedPlan}
                    setIsLoading={setIsDashboardLoading}
                  ></CommanderDashboardComponent>
                ) : (
                  trendsChart()
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Competency;
