import { useState, useEffect, SetStateAction, Dispatch } from "react";
import {
  InstructorFeedbackGrades,
  IElementGrade,
} from "../../Interfaces/IInstructorFeedback";
const InstructorFeedbackElementHook = (
  elements: string[],
  dataType: string,
  setGrade: Dispatch<SetStateAction<InstructorFeedbackGrades[]>>,
  resetAll: boolean,
  setResetAll: (state: boolean) => void
) => {
  const [finalGrade, setFinalGrade] = useState<number>(0);

  const setState = () => {
    let elementsGradesArray: IElementGrade[] = [];
    elements.forEach((element) => {
      elementsGradesArray.push({ name: element, grade: undefined });
    });
    return elementsGradesArray;
  };
  const [elementsGrades, setElementsGrades] = useState<IElementGrade[]>(
    setState()
  );

  const setElementGrade = (elementName: string, grade: number | undefined) => {
    setElementsGrades((prev) => {
      return prev.map((element) => {
        return element.name === elementName
          ? {
              ...element,
              grade: grade,
            }
          : element;
      });
    });
  };
  useEffect(() => {
    let finalGrade: number = 0;
    elementsGrades.forEach((element) => {
      if (element.grade === undefined || isNaN(element.grade)) return;

      finalGrade += element.grade;
    });
    finalGrade =
      finalGrade / (elementsGrades.length ? elementsGrades.length : 1);
    finalGrade = Number(finalGrade.toFixed(1));

    let newArray: InstructorFeedbackGrades[] = [];
    if (!isNaN(finalGrade)) setFinalGrade(finalGrade);
    setGrade((prev: InstructorFeedbackGrades[]) => {
      prev.forEach((elementDataType) => {
        newArray.push(
          elementDataType.dataType === dataType
            ? {
                ...elementDataType,
                value: Number(finalGrade),
                isComplete:
                  elementsGrades.find(
                    (element) => element.grade === undefined
                  ) === undefined,
              }
            : elementDataType
        );
      });
      return newArray;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementsGrades, dataType]);
  useEffect(() => {
    setResetAll(false);
    setElementsGrades(setState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetAll]);
  return { elementsGrades, setElementGrade, finalGrade };
};
export default InstructorFeedbackElementHook;
