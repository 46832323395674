// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pmTitle {
  --color: var(--ion-color-fontLight);
  font-family: "Regular";
  padding: 0;
  width: min-content;
  height: 100%;
}

.smallT {
  font-size: small !important;
}

.mediumT {
  font-size: medium !important;
}

.largeT {
  font-size: larger !important;
}
.xxLargeT {
  font-size: x-large !important;
}
.xLight {
  --color: var(--ion-color-fontXLight) !important;
}

.light {
  --color: var(--ion-color-fontLight) !important;
}

.dark {
  --color: var(--ion-color-fontWhite) !important;
}

.xDark {
  --color: var(--ion-color-fontWhite) !important;
}

.ExtraLight {
  font-family: "ExtraLight" !important;
}

.ExtraBold {
  font-family: "ExtraBold" !important;
}

.Bold {
  font-family: "Bold" !important;
}

.Light {
  font-family: "Light" !important;
}

.Regular {
  font-family: "Regular" !important;
}
.alignstart {
  align-self: flex-start;
}

.aligncenter {
  align-self: flex-end;
}
.alignend {
  align-self: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/themeComponents/PMTitle.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,sBAAsB;EACtB,UAAU;EACV,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,4BAA4B;AAC9B;AACA;EACE,6BAA6B;AAC/B;AACA;EACE,+CAA+C;AACjD;;AAEA;EACE,8CAA8C;AAChD;;AAEA;EACE,8CAA8C;AAChD;;AAEA;EACE,8CAA8C;AAChD;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,iCAAiC;AACnC;AACA;EACE,sBAAsB;AACxB;;AAEA;EACE,oBAAoB;AACtB;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".pmTitle {\n  --color: var(--ion-color-fontLight);\n  font-family: \"Regular\";\n  padding: 0;\n  width: min-content;\n  height: 100%;\n}\n\n.smallT {\n  font-size: small !important;\n}\n\n.mediumT {\n  font-size: medium !important;\n}\n\n.largeT {\n  font-size: larger !important;\n}\n.xxLargeT {\n  font-size: x-large !important;\n}\n.xLight {\n  --color: var(--ion-color-fontXLight) !important;\n}\n\n.light {\n  --color: var(--ion-color-fontLight) !important;\n}\n\n.dark {\n  --color: var(--ion-color-fontWhite) !important;\n}\n\n.xDark {\n  --color: var(--ion-color-fontWhite) !important;\n}\n\n.ExtraLight {\n  font-family: \"ExtraLight\" !important;\n}\n\n.ExtraBold {\n  font-family: \"ExtraBold\" !important;\n}\n\n.Bold {\n  font-family: \"Bold\" !important;\n}\n\n.Light {\n  font-family: \"Light\" !important;\n}\n\n.Regular {\n  font-family: \"Regular\" !important;\n}\n.alignstart {\n  align-self: flex-start;\n}\n\n.aligncenter {\n  align-self: flex-end;\n}\n.alignend {\n  align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
