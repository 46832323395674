import { ISystemEncryptionParam } from "./ISystemEncryption";
export interface IObserverStep {
  id: EObserverSteps;
  title: string;
  isCompleted: boolean;
  isPartlyCompleted: boolean;
}
export enum EObserverSteps {
  SettingStation,
  TargetAcquisition,
  ConductingQuality,
  WorkRoutine,
  OpenAndCover,
  MainNotes,
}
export interface ISettingStation {
  StationSelection: number | undefined;
  SettingStation: ISystemEncryptionParam;
  HIZDATRUTatField: ISystemEncryptionParam;
}

export interface ITargetAcquisition {
  TargetAcquisitionFastness: number | undefined;
  LasingOnSuspectedObject: boolean | undefined;
  LasingAccuracy: boolean | undefined;
  MultiRangesFilterRanges: boolean | undefined;
  LoadingSuspectIdentificationC2: boolean | undefined;
  AccurateIdentification: boolean | undefined;
}
export interface IConductingQuality {
  ConductingQuality: number | undefined;
}
export interface IWorkRoutine {
  SearchingMethod: number | undefined;
  SettledGoalsReturns: number | undefined;
  RulingForcesLocations: number | undefined;
  MeasureRangeForCoordinates: number | undefined;
}
export interface IOpenAndCover {
  EscortingMovingForce: number | undefined;
  AdjustingForceToFight: number | undefined;
}
