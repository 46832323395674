// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NCOGradeWrapCol {
  min-width: 470px;
  max-width: max-content;
  overflow-x: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/DataPosting/HASIFA_TKIFA/NCO/NCODesktopComponent.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,sBAAsB;EACtB,gBAAgB;AAClB","sourcesContent":[".NCOGradeWrapCol {\n  min-width: 470px;\n  max-width: max-content;\n  overflow-x: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
