import { FC, useContext, useEffect, useState } from "react";
import {
  IonContent,
  IonItem,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
} from "@ionic/react";
import "./GorillaInitTraining.css";
import PMLabel from "../../themeComponents/PMLabel";
import { useTranslation } from "react-i18next";
import MobileHeader from "../../Mobile/MobileHeader/MobileHeader";
import TrainingPlanRow from "./TrainingPlanRow";
import usePlans from "../../CustomHooks/usePlans";
import { useParams } from "react-router";
import {
  EGorillaForces,
  EGorillaTrainings,
} from "../../../Interfaces/Gorilla/GorillaTrainings";
import PMButton from "../../themeComponents/PMButton";
import NumberSelector from "./NumberSelector";
import { GorillaTrainingContext } from "../../../context/GorillaTrainingContext/GorillaTrainingContext";
import SearchForceSelect from "../MenualStation/SearchSoldierComp/SearchForceSelect";
import useNavigation from "../../CustomHooks/useNavigation";
import PlansDropdown from "../PlansDropdown/PlansDropdown";
import { EAppMode } from "../../../Enums/EAppMode";
import { ELuncherTypes } from "../../../Enums/EHasifaTkifa";
import ESPIKEIcons from "../../../Interfaces/ESPIOKEIcons";
import PMIcon from "../../themeComponents/PMIcon";
import LauncherSelector from "./LauncherSelector";

const GorillaInitTraining: FC = () => {
  const { t } = useTranslation();
  const [date, setDate] = useState<Date>(new Date());
  const station = useParams<{
    stationName: string;
    stationId: string;
  }>();

  const {
    gorillaTrainingsForces,
    initHeatsAmount,
    setGorillaTrainingsForces,
    setHeatsAmount,
    setPlan,
    minimumHeatsAmount,
    resetForcesObject,
    selectedLuncherType,
    setSelectedLuncherType,
  } = useContext(GorillaTrainingContext);
  const stationName = station.stationName as EGorillaTrainings;

  const { onPlanSelectedHandler, plansOptions, selectedPlan } = usePlans();
  useEffect(() => {
    resetForcesObject();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setPlan(selectedPlan);
    // eslint-disable-next-line
  }, [selectedPlan]);
  const { replace } = useNavigation();

  const getForcesInStation = () => {
    if (
      stationName === EGorillaTrainings.Observer ||
      stationName === EGorillaTrainings.SingleGunner
    ) {
      return [stationName];
    }
    return Object.keys(gorillaTrainingsForces);
  };
  return (
    <IonPage>
      <MobileHeader></MobileHeader>
      <TrainingPlanRow
        heatName={t(EAppMode.HASIFA_TKIFA)}
        withoutMenu
        withoutInfo
      ></TrainingPlanRow>
      <IonContent className="gorillaInitWrap">
        <div className="gorillaInitWrap">
          <IonRow className="gorillaInitRow">
            <PMLabel fontColor="light" fontFamily="Regular" fontSize="large">
              {`${t("trainingDefinition")} - ${t(stationName)}`}
            </PMLabel>
          </IonRow>
          <IonRow className="chooseTrainingPlanRow">
            <PMLabel
              fontColor="light"
              fontFamily="Regular"
              fontSize="medium"
              textAlign="start"
            >
              {`${t("chooseTrainingPlan")}`}
            </PMLabel>
          </IonRow>
          <IonRow className="gorillaInitRow">
            <PlansDropdown
              onPlanSelectedHandler={onPlanSelectedHandler}
              plansOptions={plansOptions}
              selectedPlan={selectedPlan}
            ></PlansDropdown>
          </IonRow>
          {(stationName === EGorillaTrainings.FrameTraining ||
            stationName === EGorillaTrainings.SingleGunner) && (
            <IonRow className="gorillaInitRow numberSelectorWrap">
              <LauncherSelector
                launcherType={selectedLuncherType}
                setSelectedLuncherType={setSelectedLuncherType}
                isMobile
              />
            </IonRow>
          )}
          {stationName === EGorillaTrainings.SingleGunner ||
          stationName === EGorillaTrainings.FrameTraining ? (
            <IonRow className="gorillaInitRow numberSelectorWrap">
              <NumberSelector
                number={initHeatsAmount}
                setNumber={setHeatsAmount}
                title={t("initHeatsAmount")}
                minimumNumber={minimumHeatsAmount}
              />
            </IonRow>
          ) : null}
          <div className="selectForcersDiv">
            <IonRow className="gorillaInitRow">
              {getForcesInStation().map((key, index) => {
                let forceType = key as EGorillaForces;
                return (
                  <div className="searchSoldierCol" key={index}>
                    <div className="searchSoldierRow">
                      <SearchForceSelect
                        setSoldierDetails={(soldierDetails) => {
                          setGorillaTrainingsForces((prev: any) => {
                            prev = {
                              ...prev,

                              [forceType]: soldierDetails,
                            };
                            return prev;
                          });
                        }}
                        soldierDetails={gorillaTrainingsForces[forceType]}
                        forceType={forceType}
                      ></SearchForceSelect>
                    </div>
                  </div>
                );
              })}
            </IonRow>
          </div>
          <IonRow className="gorillaInitRow">
            <PMButton
              onClickHandler={() => {
                replace(`/${stationName}`);
              }}
              label={{
                fontColor: "light",
                fontFamily: "Light",
                fontSize: "medium",
              }}
              color="orange"
              size="large"
              cssClass="gorillaInitButton"
              isDisabled={
                !(
                  (selectedLuncherType !== undefined ||
                    stationName === EGorillaTrainings.Observer) &&
                  selectedPlan?.id !== undefined &&
                  ((stationName === EGorillaTrainings.FrameTraining &&
                    gorillaTrainingsForces[EGorillaForces.SingleGunner] !==
                      undefined &&
                    (gorillaTrainingsForces[EGorillaForces.NCO] !== undefined ||
                      gorillaTrainingsForces[EGorillaForces.Observer] !==
                        undefined)) ||
                    (stationName === EGorillaTrainings.Observer &&
                      gorillaTrainingsForces[EGorillaForces.Observer] !==
                        undefined) ||
                    (stationName === EGorillaTrainings.SingleGunner &&
                      selectedLuncherType !== undefined &&
                      gorillaTrainingsForces[EGorillaForces.SingleGunner] !==
                        undefined))
                )
              }
            >
              {t("createTraining")}
            </PMButton>
          </IonRow>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default GorillaInitTraining;
