// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.usersDiv {
  width: 100%;
  height: 100%;
}

.usersListTitle {
  padding-inline-start: 15px !important;
}

.listHeader {
  font-size: 18px;
  padding: 0;
}

.usersListHeadersRow {
  padding-inline-start: 50px !important;
  width: 85%;
  padding-top: 28px;
  padding-bottom: 8px;
  color: var(--ion-color-fontXLight);
}
.usersList {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.usersListRow {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  height: 70%;
}

.usersList::-webkit-scrollbar {
  width: 8px;
}

.searchBarRow {
  padding-inline-start: 12px;
  width: 460px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/Settings/ManageUsers/Users.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,qCAAqC;EACrC,UAAU;EACV,iBAAiB;EACjB,mBAAmB;EACnB,kCAAkC;AACpC;AACA;EACE,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,0BAA0B;EAC1B,YAAY;AACd","sourcesContent":[".usersDiv {\n  width: 100%;\n  height: 100%;\n}\n\n.usersListTitle {\n  padding-inline-start: 15px !important;\n}\n\n.listHeader {\n  font-size: 18px;\n  padding: 0;\n}\n\n.usersListHeadersRow {\n  padding-inline-start: 50px !important;\n  width: 85%;\n  padding-top: 28px;\n  padding-bottom: 8px;\n  color: var(--ion-color-fontXLight);\n}\n.usersList {\n  height: 100%;\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n.usersListRow {\n  display: flex;\n  flex-direction: column;\n  overflow-y: hidden;\n  height: 70%;\n}\n\n.usersList::-webkit-scrollbar {\n  width: 8px;\n}\n\n.searchBarRow {\n  padding-inline-start: 12px;\n  width: 460px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
