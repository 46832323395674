import React, { FC, useEffect, useState, memo } from "react";
import RadarGraph from "../../../../../components/Shared/Charts/Radar/RadarGraph";
import "./PerformanceIndicators.css";
import IPlan from "../../../../../Interfaces/IPlan";
import IForceFromOrbat from "../../../../../Interfaces/IForceFromOrbat";
import IForceTreeNode from "../../../../../Interfaces/IForceTreeNode";
import IFinalGradeDisplay from "../../../../../Interfaces/IFinalGradeDisplay";
import ILabel from "../../../../../Interfaces/ILabel";
import { useTranslation } from "react-i18next";
import IThreshold from "../../../../../Interfaces/IThreshold";
import EExportType from "../../../../../Interfaces/EExportType";
import IRadarInfo from "../../../../../Interfaces/IRadarInfo";
import { FilteredIndicatorsGrades } from "../../../../../components/CustomHooks/useGetPerformanceGrades";
import PerformanceTemplate from "../../performanceTemplate";
import EExportMethod from "../../../../../Interfaces/EExportMethod";
import { toPng } from "html-to-image";

interface IProps {
  orbatForces: IForceTreeNode[];
  checkedForces: IForceFromOrbat[];
  setChecked: (force: IForceTreeNode[]) => void;
  exportToCsv: (
    imageUrl: string,
    exportType: number,
    exportMethod: EExportMethod
  ) => void;
  isAdmin?: boolean | true;
  exportHandler: () => void;
  downloadLoading: boolean;
  thresholds: IThreshold[];
  labels: ILabel[];
  plan: IPlan;
  indicatorsGrades: IRadarInfo[];
  finalGrades: IFinalGradeDisplay[];
  newForces: IForceFromOrbat[];
  forcesToRemove: IForceFromOrbat[];
  loading: boolean;
  setIsToRefresh: (state: boolean) => void;
  allPlansOptions: IPlan[];
  onPlanSelectedHandler: (option: IPlan) => void;
  isDrawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PerformanceIndicators: FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation();

  const {
    orbatForces,
    checkedForces,
    setChecked,
    downloadLoading,
    plan,
    thresholds,
    labels,
    indicatorsGrades,
    finalGrades,
    newForces,
    forcesToRemove,
    setIsToRefresh,
    loading,
    allPlansOptions,
    onPlanSelectedHandler,
    isDrawerOpen,
    setDrawerOpen,
  } = props;

  const [myNewForces, setMyNewForces] = useState<IForceFromOrbat[]>();
  useEffect(() => {
    setMyNewForces((prev) => {
      if (!prev) return checkedForces;
      return newForces;
    });
  }, [newForces, checkedForces]);

  const getImageAndSendToCsv = async (exportMethod: EExportMethod) => {
    let imageUrl = await toPng(document.getElementById("csvRadarImage")!);
    props.exportToCsv(imageUrl, EExportType.indicators, exportMethod);
  };

  const chart = (
    <div className="radarQ toImage">
      <div className="grapsWrap">
        <RadarGraph
          id="csvRadarImage"
          radarData={FilteredIndicatorsGrades(indicatorsGrades, labels)}
          selectedForces={checkedForces}
          thresholds={thresholds}
          labels={labels}
          newForces={myNewForces}
          forcesToRemove={forcesToRemove}
          plan={plan}
        />
      </div>
    </div>
  );
  return (
    <PerformanceTemplate
      key={"competency"}
      loadingText={
        loading ? t("loadingData") : downloadLoading ? t("downloadFile") : ""
      }
      chart={chart}
      iButtonsDisabled={!checkedForces.length}
      finalGrades={finalGrades}
      refreshButtonText={t("refresh")}
      exportButtonText={t("export")}
      onClickRefreshButton={() => setIsToRefresh(true)}
      onClickExportButton={(exportMethod) => getImageAndSendToCsv(exportMethod)}
      isDrawerOpen={isDrawerOpen}
      loading={loading || downloadLoading}
      orbatForces={orbatForces}
      plansOptions={allPlansOptions}
      selectedPlan={plan}
      onPlanSelectedHandler={onPlanSelectedHandler}
      setChecked={setChecked}
      setDrawerOpen={setDrawerOpen}
      title={t("indicators")}
    ></PerformanceTemplate>
  );
};

export default memo(PerformanceIndicators);
