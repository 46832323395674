import React, { FC } from "react";
import { IonInput, IonItem, IonLabel, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import IShootingRangeForce from "../../../../../../Interfaces/IShootingRangeForce";
import "./TraineeListItem.css";

type Props = {
  item: IShootingRangeForce;
  index: number;
  setResult: (value: string, item: IShootingRangeForce) => void;
};

const TraineeListItem: FC<Props> = (props: Props) => {
  const { item, index, setResult } = props;
  const { t } = useTranslation();

  const onChangeResultHandler = (value: string) => {
    setResult(value, item);
  };

  return (
    <IonItem className="trainees-list-item" key={item.id}>
      <div className="soldier">
        <IonText className="soldier-index"> {`${index + 1}`}</IonText>
        <IonLabel className="soldier-name" key={item.id}>{`${t(
          item.name
        )}`}</IonLabel>
      </div>
      <IonInput
        className={`result-input ${!item.isValidResult && "input-error"}`}
        placeholder={t("result")}
        type="number"
        onIonChange={(event) => onChangeResultHandler(event.detail.value!)}
        value={item.result}
      />
    </IonItem>
  );
};

export default TraineeListItem;
