import React, { ReactNode, useState } from "react";
import IIndicatorType from "../../Interfaces/IIndicatorType";
interface IIndicatorContext {
  indicators: IIndicatorType[];
  setIndicators: React.Dispatch<React.SetStateAction<IIndicatorType[]>>;
}

export const IndicatorsContext = React.createContext<IIndicatorContext>({
  indicators: [],
  setIndicators: () => {},
});

const IndicatorsContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [indicators, setIndicators] = useState<IIndicatorType[]>([]);

  return (
    <IndicatorsContext.Provider
      value={{
        indicators,
        setIndicators,
      }}
    >
      {children}
    </IndicatorsContext.Provider>
  );
};

export default IndicatorsContextProvider;
