// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchForceItem {
  --background: var(--ion-color-light);
  border-radius: 2px;
  margin: 0;
  height: 40px;
  --min-height: 40px;
}
.searchForceItem:hover {
  --background: var(--ion-color-selected);
}
.searchForceItem.focused {
  --background: var(--ion-color-selected);
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/MenualStation/SearchSoldierComp/SearchForceSelectDropdown.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,SAAS;EACT,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,uCAAuC;AACzC;AACA;EACE,uCAAuC;EACvC,aAAa;AACf","sourcesContent":[".searchForceItem {\n  --background: var(--ion-color-light);\n  border-radius: 2px;\n  margin: 0;\n  height: 40px;\n  --min-height: 40px;\n}\n.searchForceItem:hover {\n  --background: var(--ion-color-selected);\n}\n.searchForceItem.focused {\n  --background: var(--ion-color-selected);\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
