import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DropdownEditor from "../DropdownEditor";
import DrillsDataTable from "../DrillsDataTable";
import { handleSave, sendDrillToBBackend } from "../dataPostingHelpers";
import {
  ComparisonFormatter,
  PositiveNumberFormatter,
  PositiveNumberNtoMFormatter,
} from "../InputFormatters";
import SoldiersListEditor from "../SoldiersListEditor";
import IShootingDay from "../../../../Interfaces/IShootingDay";
import IShootingHeat from "../../../../Interfaces/IShootingHeat";
import IForceTreeNode from "../../../../Interfaces/IForceTreeNode";
import {
  getChildrenSoldiers,
  getForceFullName,
} from "../../../../services/helpers";
import IShootingRangeForce from "../../../../Interfaces/IShootingRangeForce";
import {
  MAX_RESULT,
  MIN_RESULT,
} from "../../../../Configurations/shootingRangeConsts";
import IShootingHeatType from "../../../../Interfaces/IShootingHeatType";
import { getFinalResultsByTypes } from "../../../../services/shootingRangeFunctions";
import EEventType from "../../../../Interfaces/EEventType";
import { errorClassName } from "../../../Shared/InputWithError/InputWithError";
import ShootingRangeInputs from "./ShootingRangeInputs";
import IShootingRangeRow from "../../../../Interfaces/IShootingRangeRow";
import IShootingRangeColumn from "../../../../Interfaces/IShootingRangeColumn";
import NotExistLabel from "../../../Shared/NotExistLabel/NotExistLabel";
import EPicturesSrc from "../../../../Interfaces/EPicturesSrc";
import IDataPosting from "../../../../Interfaces/IDataPosting";
import DesktopManualInputTemplate from "../DesktopManualInputTemplate";
import Alert from "../../../Shared/Alert/Alert";

import "./LiveShooting.css";
import { useShootingDays } from "../../../CustomHooks/HooksWithRQ/useShootingDays";
import { useShootingRangeHeats } from "../../../CustomHooks/HooksWithRQ/useShootingHeats";
import { useWeapons } from "../../../CustomHooks/HooksWithRQ/useWeapons";
import IOption from "../../../../Interfaces/IOption";
import { Column, EEditorType } from "../../PMDataGrid/DataGridTypes";
import {
  comparisonFormatter,
  positiveNumber,
  positiveNumberNtoM,
} from "../inputValidations";
import DataGrid from "../../PMDataGrid/DataGrid";

const TRAINING_TYPE_ID: number = 11;

interface IShootingRangeMessage {
  MessageType: number;
  TimeStamp: Date;
  SoldierID: number | string;
  TrainingTypeID: number;
  WeaponType: string | null;
  NumOfShots: string;
  RedHits: number | undefined;
  Grouping: number | undefined;
  Score: number | undefined;
  PlanId: number | undefined;
  DrillStartTime: Date;
  Hashtags: string[];
}

interface IDefaultRow {
  soldierName: string;
  soldierId: string;
  weaponType: string;
  numberOfShots: string;
  result: string;
}

const LiveShooting: React.FC<IDataPosting> = (
  props: IDataPosting
): JSX.Element => {
  const { t } = useTranslation();

  const defaultRows: IDefaultRow = {
    soldierName: "",
    soldierId: "",
    weaponType: "",
    numberOfShots: "",
    result: "",
  };
  const { weaponsTypesList } = useWeapons();

  const initialColumns: Column<IShootingRangeRow>[] = [
    {
      key: "soldierName",
      header: t("soldierName"),
      editable: false,
      renderEditCell: SoldiersListEditor,
      filterType: EEditorType.text,
      width: 200,
    },
    {
      key: "soldierId",
      header: t("militaryId"),
      editable: false,
      filterType: EEditorType.text,
      validationFunction: (row: IShootingRangeRow, value: any) =>
        positiveNumber(value),
    },
    {
      key: "weaponType",
      header: t("weaponType"),
      editable: false,
      filterType: EEditorType.dropdown,
      options: weaponsTypesList.map((weapon) => ({
        label: t(weapon),
        value: weapon,
      })),
    },
    {
      key: "numberOfShots",
      header: t("numberOfShots"),
      editable: false,
      filterType: EEditorType.text,
      validationFunction: (row: IShootingRangeRow, value: any) =>
        positiveNumber(value),
    },
    {
      key: "result",
      header: "result",
      editable: true,
      filterType: EEditorType.text,
      validationFunction: (row: IShootingRangeRow, value: any) =>
        positiveNumber(value),
    },
  ];

  const [forcePath, setForcePath] = useState<string>();
  const [selectedShootingDay, setSelectedShootingDay] =
    useState<IShootingDay>();
  const [shootingDayOptions, setShootingDayOptions] = useState<IOption[]>([]);
  const [selectedHeat, setSelectedHeat] = useState<IShootingHeat>();
  const [heatOptions, setHeatOptions] = useState<IOption[]>([]);
  const [selectedForces, setSelectedForces] = useState<IForceTreeNode[]>([]);
  const [trainees, setTrainees] = useState<IShootingRangeForce[]>();
  const [rows, setRows] = useState<IShootingRangeRow[]>([]);
  const [rowsToSave, setRowsToSave] = useState<Set<string | number>>(new Set());
  const [columns, setColumns] =
    useState<Column<IShootingRangeRow>[]>(initialColumns);
  const [isSuccess, setIsSuccess] = useState<number>(0);
  const [hashtags, setHashtags] = useState<string>("");
  const [unsavedResults, setUnsavedResults] = useState<boolean>();
  const [isValid, setIsValid] = useState<boolean>(true);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [isLastHeat, setIsLastHeat] = useState<boolean>(false);

  //custom Hooks
  const { shootingDays } = useShootingDays();
  const { heatsByShootingDay } = useShootingRangeHeats(selectedShootingDay?.id);

  useEffect(() => {
    setUnsavedResults(rowsToSave.size !== 0 && isValid);
  }, [rowsToSave, isValid]);

  useEffect(() => {
    if (selectedForces[0]) {
      setForcePath(selectedForces[0].name);
      setTrainees(getChildrenSoldiers(selectedForces[0], []));
    } else {
      setForcePath(undefined);
    }
  }, [selectedForces]);

  const getShootingDaysOptions = (shootingDays: IShootingDay[]): IOption[] => {
    return shootingDays.map((shootingDay: IShootingDay) => ({
      value: shootingDay.name,
      key: String(shootingDay.id),
    }));
  };

  useEffect(() => {
    shootingDays && setShootingDayOptions(getShootingDaysOptions(shootingDays));
  }, [shootingDays]);

  const getShootingRangeHeatsOptions = (
    heatsByShootingDay: IShootingHeat[]
  ): IOption[] => {
    return heatsByShootingDay
      .filter(
        (heat: IShootingHeat) =>
          +heat.shootingDayId === +selectedShootingDay!.id
      )
      .map((heat: IShootingHeat) => ({
        value: heat.name,
        key: String(heat.id),
      }));
  };

  useEffect(() => {
    heatsByShootingDay &&
      setHeatOptions(getShootingRangeHeatsOptions(heatsByShootingDay));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heatsByShootingDay]);

  useEffect(() => {
    if (heatsByShootingDay && selectedHeat) {
      getHashtags();
      setColumns((prev) => getResultColumnFormat(prev, selectedHeat.type));
      setIsLastHeat(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHeat]);

  useEffect(() => {
    if (isLastHeat) {
      setOpenAlert(true);
    }
  }, [isLastHeat]);

  useEffect(() => {
    if (trainees && selectedShootingDay && selectedHeat) {
      initializeRowsWithTraineesData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainees, selectedShootingDay, selectedHeat]);

  useEffect(() => {
    if (rows.length) {
      checkValidation();
    }
  }, [rows]);

  const onUpdateData = async (row: IShootingRangeRow) => {
    const time: Date = new Date();
    let types: IShootingHeatType = getFinalResultsByTypes(
      row.result,
      selectedHeat!.type
    );

    const shootingRangeDrill: IShootingRangeMessage = {
      MessageType: EEventType.SHOOTING_RANGE_MESSAGE_TYPE,
      TimeStamp: time,
      SoldierID: row.soldierId,
      TrainingTypeID: TRAINING_TYPE_ID,
      WeaponType: row.weaponType,
      NumOfShots: row.numberOfShots,
      RedHits: types.numberOfHits,
      Grouping: types.grouping,
      Score: types.score,
      PlanId: props.selectedPlan?.id,
      DrillStartTime: time,
      Hashtags: hashtags.split(" "),
    };

    sendDrillToBBackend(
      shootingRangeDrill,
      () => {
        setIsSuccess(200);
        props.resetCompetencyForces();
      },
      () => setIsSuccess(500)
    );
    setRowsToSave(new Set());
    setIsSuccess(0);
  };

  const checkValidation = () =>
    setIsValid(() => !document.getElementsByClassName(errorClassName).length);

  const initializeRows = () => {
    let tempRows: IShootingRangeRow[] = [];
    for (let index = 0; index < trainees!.length; index++) {
      tempRows[index] = {
        ...defaultRows,
        soldierId: trainees![index].id,
        soldierName: getForceFullName(
          trainees![index].name,
          trainees![index].forceType,
          t
        ),
        weaponType: trainees![index].weaponType,
        numberOfShots: selectedHeat!.bullets,
        id: index,
      };
    }
    return tempRows;
  };

  const initializeRowsWithTraineesData = () => {
    setRows(initializeRows());
  };

  const getHashtags = () => {
    setHashtags(
      `${selectedHeat?.name.replaceAll(
        " ",
        "_"
      )} ${selectedShootingDay?.name.replaceAll(" ", "_")}`
    );
  };

  const onChangeShootingDay = (currentValue: number) => {
    let currentShootingDay: IShootingDay | undefined = shootingDays?.find(
      (shootingDay: IShootingDay) => +shootingDay.id === currentValue
    );
    setSelectedShootingDay(currentShootingDay);
    setSelectedHeat(undefined);
  };

  const onChangeHeat = (currentValue: number) => {
    let currentHeat: IShootingHeat | undefined = heatsByShootingDay?.find(
      (heat: IShootingHeat) => heat.id === currentValue
    );
    setSelectedHeat(currentHeat);
  };

  const getResultColumnFormat = (
    currentColumns: Column<IShootingRangeRow>[],
    heatType: string
  ): Column<IShootingRangeRow>[] =>
    currentColumns.map((column: Column<IShootingRangeRow>) => {
      if (column.key === "result") {
        let validationFunction =
          heatType === "numberOfHits"
            ? {
                validationFunction: (row: IShootingRangeRow, value: any) => {
                  return comparisonFormatter(
                    "numberOfHits",
                    "numberOfShots",
                    row,
                    value,
                    t
                  );
                },
              }
            : heatType === "grouping"
            ? {
                validationFunction: (row: IShootingRangeRow, value: any) =>
                  positiveNumber(value),
              }
            : {
                validationFunction: (row: IShootingRangeRow, value: any) =>
                  positiveNumberNtoM(value, MAX_RESULT, MIN_RESULT, t),
              };
        return {
          ...column,
          header: `${t("achievementRequired")} - ${t(heatType)}`,
          ...validationFunction,
        };
      } else {
        return column;
      }
    });

  const goToNextHeat = () => {
    if (isLastHeat) {
      setOpenAlert(true);
    } else {
      const heatsInCurrentDay = heatsByShootingDay?.filter(
        (heat: IShootingHeat) =>
          +heat.shootingDayId === +selectedShootingDay!.id
      );

      const nextHeatIndex: number = lastIndex(
        heatsInCurrentDay!,
        selectedHeat!.id
      );

      if (nextHeatIndex !== heatsInCurrentDay?.length) {
        setSelectedHeat(heatsInCurrentDay![nextHeatIndex]);
        setRowsToSave(new Set());
      } else {
        setIsLastHeat(true);
      }
    }
  };

  const lastIndex = (heatsList: IShootingHeat[], currentId: number): number =>
    heatsList.findIndex((heat: IShootingHeat) => heat.id === currentId) + 1;

  const onSaveResults = () => {
    handleSave(
      rows.filter((row) => rowsToSave.has(row.id)),
      onUpdateData,
      t
    );
  };
  const handleSaveAndGoToNextHeat = () => {
    onSaveResults();
    goToNextHeat();
  };
  return (
    <DesktopManualInputTemplate
      selectedPlan={props.selectedPlan}
      plansOptions={props.plansOptions}
      onPlanSelectedHandler={props.onPlanSelectedHandler}
      pageName={"Shooting_Range"}
      headerChildren={
        <ShootingRangeInputs
          forcePath={forcePath}
          heatOptions={heatOptions}
          shootingDayOptions={shootingDayOptions}
          onChangeHeat={onChangeHeat}
          onChangeShootingDay={onChangeShootingDay}
          selectedForces={selectedForces}
          selectedHeat={selectedHeat}
          selectedShootingDay={selectedShootingDay}
          setSelectedForces={setSelectedForces}
        />
      }
      isToHideButtons={
        !(selectedForces[0] && selectedShootingDay && selectedHeat)
      }
      onClickThirdButton={handleSaveAndGoToNextHeat}
      onClickSecondButton={goToNextHeat}
      onClickFirstButton={onSaveResults}
      thirdButtonText={t("saveResultsAndPassToNextHeat")}
      secondButtonText={t("passToNextHeat")}
      firstButtonText={t("saveResults")}
      isDisabledFirstButton={!unsavedResults}
      isDisabledThirdButton={!unsavedResults}
    >
      {selectedForces[0] && selectedShootingDay && selectedHeat ? (
        <DataGrid
          columns={columns}
          data={rows}
          editable
          onEdit={(updatedRow) => {
            setRowsToSave((prev) => {
              let newd = new Set(prev);
              newd.add(updatedRow.id);
              return newd;
            });
            setRows((prev) =>
              prev.map((row) => (row.id === updatedRow.id ? updatedRow : row))
            );
          }}
        ></DataGrid>
      ) : (
        <div className="not-selected-data">
          <NotExistLabel text="noSelectedData" className="not-selected" />
          <img
            alt="shooting-range-pic"
            className="shooting-range-image"
            src={EPicturesSrc.SHOOTING_RANGE}
          />
        </div>
      )}
      <Alert
        isOpen={openAlert}
        setIsOpen={setOpenAlert}
        header={isLastHeat ? `${t("reachedLastHeat")}` : ""}
        actionOnSave={() => setOpenAlert(false)}
        actionText={"OK"}
      />
    </DesktopManualInputTemplate>
  );
};

export default LiveShooting;
