import { Dispatch, useContext, useEffect } from "react";
import { AppModeContext } from "../../context/AppModeContext/AppModeContext";
import Axios from "../../Axios";
import { useElements } from "./HooksWithRQ/useElemets";
import { useIndicators } from "./HooksWithRQ/useIndicators";
import { useStations } from "./HooksWithRQ/useStations";
import { useTrainingTypes } from "./HooksWithRQ/useTrainingTypes";
import { UserContext } from "../../context/UserContext/userContext";
import { useDispatch } from "react-redux";
import { fetchPlans } from "../../redux/actions/plansActions";
import { useTranslation } from "react-i18next";

export const useAppMode = () => {
  const { appMode } = useContext(AppModeContext);
  const { isLoggedIn } = useContext(UserContext);
  const dispatch = useDispatch<Dispatch<any>>();
  const { t } = useTranslation();

  useEffect(() => {
    Axios.defaults.headers.common["appMode"] = appMode;
  }, [appMode]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchPlans(t));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, dispatch, t, appMode]);

  useIndicators();
  useTrainingTypes();
  useStations();
  useElements();
};
