// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content.contentContainerSBL {
  --ion-background-color: var(--ion-color-primary);
  height: 100%;
  flex-wrap: nowrap;
  overflow: hidden !important;
}

ion-grid.gridContainerSBL {
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 !important;
}

.rowContainerSBL .left {
  display: flex;
  justify-content: flex-start;
  margin-inline-start: 3vw;
}
.rowContainerSBL .right {
  display: flex;
  justify-content: flex-end;
  margin-inline-end: 3vw;
}
.buttonCol {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.rowContainerSBL {
  display: flex;
  padding: 5px;
  margin-top: 20px;
}

.scrollContainerSBL {
  display: nowrap;
  align-items: center;
  justify-content: center;
  overflow-x: auto !important;
  overflow-y: auto !important;
  height: 80%;
}
.titleSBL {
  font-family: "Regular" !important;
  font-size: large;
  color: var(--ion-color-fontXLight);
}
.inputPlanRow .Dropdown-root {
  width: 83%;
}
.inputPlanRow {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
}

.inputsDropdown {
  width: 88% !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Mobile/Stations/Stations.css"],"names":[],"mappings":"AAAA;EACE,gDAAgD;EAChD,YAAY;EACZ,iBAAiB;EACjB,2BAA2B;AAC7B;;AAEA;EACE,WAAW;EACX,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,wBAAwB;AAC1B;AACA;EACE,aAAa;EACb,yBAAyB;EACzB,sBAAsB;AACxB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,uBAAuB;EACvB,2BAA2B;EAC3B,2BAA2B;EAC3B,WAAW;AACb;AACA;EACE,iCAAiC;EACjC,gBAAgB;EAChB,kCAAkC;AACpC;AACA;EACE,UAAU;AACZ;AACA;EACE,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":["ion-content.contentContainerSBL {\n  --ion-background-color: var(--ion-color-primary);\n  height: 100%;\n  flex-wrap: nowrap;\n  overflow: hidden !important;\n}\n\nion-grid.gridContainerSBL {\n  height: 90%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 0 !important;\n}\n\n.rowContainerSBL .left {\n  display: flex;\n  justify-content: flex-start;\n  margin-inline-start: 3vw;\n}\n.rowContainerSBL .right {\n  display: flex;\n  justify-content: flex-end;\n  margin-inline-end: 3vw;\n}\n.buttonCol {\n  display: flex;\n  justify-content: center;\n  margin-bottom: 10px;\n}\n.rowContainerSBL {\n  display: flex;\n  padding: 5px;\n  margin-top: 20px;\n}\n\n.scrollContainerSBL {\n  display: nowrap;\n  align-items: center;\n  justify-content: center;\n  overflow-x: auto !important;\n  overflow-y: auto !important;\n  height: 80%;\n}\n.titleSBL {\n  font-family: \"Regular\" !important;\n  font-size: large;\n  color: var(--ion-color-fontXLight);\n}\n.inputPlanRow .Dropdown-root {\n  width: 83%;\n}\n.inputPlanRow {\n  margin-top: 20px;\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  margin-bottom: 10px;\n}\n\n.inputsDropdown {\n  width: 88% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
