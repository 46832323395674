// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.singleGunnerContent {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  margin-top: 0;
}
.gorillaPage .treeAndChart {
  background-color: var(--ion-color-dark-shade) !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Desktop/Gorilla/SingleGunnerDesktopResults.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,wDAAwD;AAC1D","sourcesContent":[".singleGunnerContent {\n  display: flex;\n  flex-direction: row;\n  height: 100%;\n  width: 100%;\n  overflow-y: auto;\n  overflow-x: auto;\n  margin-top: 0;\n}\n.gorillaPage .treeAndChart {\n  background-color: var(--ion-color-dark-shade) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
