import React, { ReactNode, useState } from "react";
import IForceType from "../../Interfaces/IForceType";
interface IForcesTypesContext {
  forceTypes: IForceType[];
  setForceTypes: React.Dispatch<React.SetStateAction<IForceType[]>>;
}

export const ForceTypesContext = React.createContext<IForcesTypesContext>({
  forceTypes: [],
  setForceTypes: () => {},
});

const ForceTypesContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [forceTypes, setForceTypes] = useState<IForceType[]>([]);
  return (
    <ForceTypesContext.Provider value={{ forceTypes, setForceTypes }}>
      {children}
    </ForceTypesContext.Provider>
  );
};

export default ForceTypesContextProvider;
