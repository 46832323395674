// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigationRow {
  width: 100%;
  height: 54px;
  justify-content: space-between;
  align-items: center;
  background-color: var(--ion-color-dark-shade);
}
.endHeatButton {
  margin-inline-end: var(--pm_spike_gorilla);
  margin-inline-start: var(--pm_spike_gorilla);
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/SPIKEGorilla/NavigationRow.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,8BAA8B;EAC9B,mBAAmB;EACnB,6CAA6C;AAC/C;AACA;EACE,0CAA0C;EAC1C,4CAA4C;AAC9C","sourcesContent":[".navigationRow {\n  width: 100%;\n  height: 54px;\n  justify-content: space-between;\n  align-items: center;\n  background-color: var(--ion-color-dark-shade);\n}\n.endHeatButton {\n  margin-inline-end: var(--pm_spike_gorilla);\n  margin-inline-start: var(--pm_spike_gorilla);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
