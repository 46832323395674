// css
import { IonCol, IonRow } from "@ionic/react";
import "./HitsAnalyze.css";
import { useTranslation } from "react-i18next";
import PMIcon from "../../../components/themeComponents/PMIcon";
import ESPIKEIcons from "../../../Interfaces/ESPIOKEIcons";
import { ITargetsPropertiesOptions } from "../../../Interfaces/Gorilla/ITargetProperties";
import PMLabel from "../../../components/themeComponents/PMLabel";
import Fraction from "fraction.js";
import { IDataResults } from "../../../Interfaces/Gorilla/IHitsAnalyze";

interface IIconAndHitsProps {
  iconSrc: ESPIKEIcons;
  hitsRate?: string;
}
export function decimalToFraction(decimal: number) {
  const fraction = new Fraction(decimal);
  return `${fraction.n}/${fraction.d}`;
}

interface IProps {
  hitsAnalysisResults: { hit: boolean; values: ITargetsPropertiesOptions[] }[];
}
const IconAndHits: React.FC<IIconAndHitsProps> = (
  props: IIconAndHitsProps
): JSX.Element => {
  const { iconSrc, hitsRate } = props;

  return (
    <span className="iconAndHitsWrap">
      <PMIcon
        iconSrc={iconSrc}
        onClick={() => {}}
        size="medium"
        cssClass={`gorillaHitsIcon  ${hitsRate ? "enabled" : "disabled"}`}
      ></PMIcon>

      <PMLabel
        fontColor="light"
        fontSize="large"
        fontFamily="Regular"
        cssClass="iconTeatHitAnalyzed"
        textAlign="end"
      >
        {hitsRate}
      </PMLabel>
    </span>
  );
};

const HitsAnalyze: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation();

  const initDataResults = {
    [ITargetsPropertiesOptions.STATIC]: {
      ariaCell: {
        [ITargetsPropertiesOptions.BUILT]: undefined,
        [ITargetsPropertiesOptions.TANGLED]: undefined,
        [ITargetsPropertiesOptions.OPEN]: undefined,
      },
      shootToTarget: {
        [ITargetsPropertiesOptions.SHORT]: undefined,
        [ITargetsPropertiesOptions.MEDIUM]: undefined,
        [ITargetsPropertiesOptions.LONG]: undefined,
      },
      targetType: {
        [ITargetsPropertiesOptions.MAN]: undefined,
        [ITargetsPropertiesOptions.CAR]: undefined,
        [ITargetsPropertiesOptions.WINDOW]: undefined,
      },
    },
    [ITargetsPropertiesOptions.MOVING]: {
      ariaCell: {
        [ITargetsPropertiesOptions.BUILT]: undefined,
        [ITargetsPropertiesOptions.TANGLED]: undefined,
        [ITargetsPropertiesOptions.OPEN]: undefined,
      },
      shootToTarget: {
        [ITargetsPropertiesOptions.SHORT]: undefined,
        [ITargetsPropertiesOptions.MEDIUM]: undefined,
        [ITargetsPropertiesOptions.LONG]: undefined,
      },
      targetType: {
        [ITargetsPropertiesOptions.MAN]: undefined,
        [ITargetsPropertiesOptions.CAR]: undefined,
        [ITargetsPropertiesOptions.WINDOW]: undefined,
      },
    },
    [ITargetsPropertiesOptions.NOT_EXIST]: {
      ariaCell: {
        [ITargetsPropertiesOptions.BUILT]: undefined,
        [ITargetsPropertiesOptions.TANGLED]: undefined,
        [ITargetsPropertiesOptions.OPEN]: undefined,
      },
      shootToTarget: {
        [ITargetsPropertiesOptions.SHORT]: undefined,
        [ITargetsPropertiesOptions.MEDIUM]: undefined,
        [ITargetsPropertiesOptions.LONG]: undefined,
      },
      targetType: {
        [ITargetsPropertiesOptions.MAN]: undefined,
        [ITargetsPropertiesOptions.CAR]: undefined,
        [ITargetsPropertiesOptions.WINDOW]: undefined,
      },
    },
    [ITargetsPropertiesOptions.HIDDEN]: {
      ariaCell: {
        [ITargetsPropertiesOptions.BUILT]: undefined,
        [ITargetsPropertiesOptions.TANGLED]: undefined,
        [ITargetsPropertiesOptions.OPEN]: undefined,
      },
      shootToTarget: {
        [ITargetsPropertiesOptions.SHORT]: undefined,
        [ITargetsPropertiesOptions.MEDIUM]: undefined,
        [ITargetsPropertiesOptions.LONG]: undefined,
      },
      targetType: {
        [ITargetsPropertiesOptions.MAN]: undefined,
        [ITargetsPropertiesOptions.CAR]: undefined,
        [ITargetsPropertiesOptions.WINDOW]: undefined,
      },
    },
    [ITargetsPropertiesOptions.DRIVING]: {
      ariaCell: {
        [ITargetsPropertiesOptions.BUILT]: undefined,
        [ITargetsPropertiesOptions.TANGLED]: undefined,
        [ITargetsPropertiesOptions.OPEN]: undefined,
      },
      shootToTarget: {
        [ITargetsPropertiesOptions.SHORT]: undefined,
        [ITargetsPropertiesOptions.MEDIUM]: undefined,
        [ITargetsPropertiesOptions.LONG]: undefined,
      },
      targetType: {
        [ITargetsPropertiesOptions.MAN]: undefined,
        [ITargetsPropertiesOptions.CAR]: undefined,
        [ITargetsPropertiesOptions.WINDOW]: undefined,
      },
    },
    [ITargetsPropertiesOptions.LOCKED]: {
      ariaCell: {
        [ITargetsPropertiesOptions.BUILT]: undefined,
        [ITargetsPropertiesOptions.TANGLED]: undefined,
        [ITargetsPropertiesOptions.OPEN]: undefined,
      },
      shootToTarget: {
        [ITargetsPropertiesOptions.SHORT]: undefined,
        [ITargetsPropertiesOptions.MEDIUM]: undefined,
        [ITargetsPropertiesOptions.LONG]: undefined,
      },
      targetType: {
        [ITargetsPropertiesOptions.MAN]: undefined,
        [ITargetsPropertiesOptions.CAR]: undefined,
        [ITargetsPropertiesOptions.WINDOW]: undefined,
      },
    },
    [ITargetsPropertiesOptions.NIGHT]: {
      ariaCell: {
        [ITargetsPropertiesOptions.BUILT]: undefined,
        [ITargetsPropertiesOptions.TANGLED]: undefined,
        [ITargetsPropertiesOptions.OPEN]: undefined,
      },
      shootToTarget: {
        [ITargetsPropertiesOptions.SHORT]: undefined,
        [ITargetsPropertiesOptions.MEDIUM]: undefined,
        [ITargetsPropertiesOptions.LONG]: undefined,
      },
      targetType: {
        [ITargetsPropertiesOptions.MAN]: undefined,
        [ITargetsPropertiesOptions.CAR]: undefined,
        [ITargetsPropertiesOptions.WINDOW]: undefined,
      },
    },
  };

  const getResultsToDisplay = () => {
    const targetTypeOptions: [
      ITargetsPropertiesOptions.MAN,
      ITargetsPropertiesOptions.CAR,
      ITargetsPropertiesOptions.WINDOW
    ] = [
      ITargetsPropertiesOptions.MAN,
      ITargetsPropertiesOptions.CAR,
      ITargetsPropertiesOptions.WINDOW,
    ];
    const shootToTargetOptions: [
      ITargetsPropertiesOptions.SHORT,
      ITargetsPropertiesOptions.MEDIUM,
      ITargetsPropertiesOptions.LONG
    ] = [
      ITargetsPropertiesOptions.SHORT,
      ITargetsPropertiesOptions.MEDIUM,
      ITargetsPropertiesOptions.LONG,
    ];
    const ariaCellOptions: [
      ITargetsPropertiesOptions.OPEN,
      ITargetsPropertiesOptions.TANGLED,
      ITargetsPropertiesOptions.BUILT
    ] = [
      ITargetsPropertiesOptions.OPEN,
      ITargetsPropertiesOptions.TANGLED,
      ITargetsPropertiesOptions.BUILT,
    ];
    let dataResults: IDataResults = initDataResults;
    Object.keys(dataResults).forEach((key: any) => {
      let typedKey: keyof IDataResults = key;

      targetTypeOptions.forEach((targetOption) => {
        const resWithTarget = props.hitsAnalysisResults.filter(
          (r) => r.values.includes(key) && r.values.includes(targetOption)
        );

        dataResults[typedKey].targetType[targetOption] = {
          x: resWithTarget.reduce((a, b) => a + (b.hit ? 1 : 0), 0),
          y: resWithTarget.length,
        };
      });

      shootToTargetOptions.forEach((rangeOption) => {
        const resWithRange = props.hitsAnalysisResults.filter(
          (r) => r.values.includes(key) && r.values.includes(rangeOption)
        );

        dataResults[typedKey].shootToTarget[rangeOption] = {
          x: resWithRange.reduce((a, b) => a + (b.hit ? 1 : 0), 0),
          y: resWithRange.length,
        };
      });
      ariaCellOptions.forEach((rangeOption) => {
        const resWithRange = props.hitsAnalysisResults.filter(
          (r) => r.values.includes(key) && r.values.includes(rangeOption)
        );

        dataResults[typedKey].ariaCell[rangeOption] = {
          x: resWithRange.reduce((a, b) => a + (b.hit ? 1 : 0), 0),
          y: resWithRange.length,
        };
      });
    });

    return dataResults;
  };

  return (
    <IonCol className="hitsAnalyzeCol scrollL">
      <IonRow className="hitsAnalyzeRow ">
        <IonCol size="2" className="targetType">
          <IonRow className="hitsAnalyzeTableRow targetTypeTitle"></IonRow>
          <IonRow className="hitsAnalyzeTableRow targetTypeTitle"></IonRow>
          {Object.keys(getResultsToDisplay()).map((key, index) => (
            <div key={index}>
              <IonRow
                key={index}
                className={`hitsAnalyzeTableRow ${
                  (index + 1) % 2 === 0 ? "marginBottom" : ""
                }`}
              >
                <IonRow className={"hitsAnalyzeIconRow targetTypeName"}>
                  <PMLabel
                    fontColor="light"
                    fontFamily="Regular"
                    fontSize="medium"
                  >
                    {" "}
                    {t(`target_${key}`)}
                  </PMLabel>
                </IonRow>
              </IonRow>
            </div>
          ))}
        </IonCol>
        <IonCol size="0.3" className="targetType">
          {/**target average  value */}

          <IonRow className="hitsAnalyzeTableRow targetTypeTitle"></IonRow>
          <IonRow className="hitsAnalyzeTableRow targetTypeTitle"></IonRow>
          {Object.values(getResultsToDisplay()).map((res, index) => {
            let counter = 0;
            const totalXOverY = Object.values(res).reduce(
              (a: number, b: any) => {
                return (
                  a +
                  Object.values(b).reduce((c: number, d: any) => {
                    if (d?.y) counter++;
                    return c + (d?.x / d?.y || 0);
                  }, 0)
                );
              },
              0
            );

            return (
              <div key={index}>
                <IonRow
                  key={index}
                  className={`hitsAnalyzeTableRow ${
                    (index + 1) % 2 === 0 ? "marginBottom" : ""
                  }`}
                >
                  <IonRow className="hitsAnalyzeIconRow">
                    <PMLabel
                      fontColor="light"
                      fontFamily="Regular"
                      fontSize="medium"
                    >
                      {" "}
                      {counter ? decimalToFraction(totalXOverY / counter) : ""}
                    </PMLabel>
                  </IonRow>
                </IonRow>
              </div>
            );
          })}
        </IonCol>
        <IonCol className="targetType">
          {/**target type */}
          <IonRow className="hitsAnalyzeTableRow targetTypeTitle">
            <PMLabel fontColor="light" fontFamily="Regular" fontSize="medium">
              {t("targetType")}
            </PMLabel>
          </IonRow>
          <IonRow className="hitsAnalyzeTableRow targetTypeTitle">
            <IonRow className="hitsAnalyzeTableRow titleRowHA">
              <PMLabel fontColor="light" fontFamily="Regular" fontSize="medium">
                {t(ITargetsPropertiesOptions.MAN)}
              </PMLabel>
            </IonRow>
            <IonRow className="hitsAnalyzeTableRow titleRowHA">
              <PMLabel fontColor="light" fontFamily="Regular" fontSize="medium">
                {t(ITargetsPropertiesOptions.CAR)}
              </PMLabel>
            </IonRow>
            <IonRow className="hitsAnalyzeTableRow titleRowHA">
              <PMLabel fontColor="light" fontFamily="Regular" fontSize="medium">
                {t(ITargetsPropertiesOptions.WINDOW)}
              </PMLabel>
            </IonRow>
          </IonRow>
          {Object.values(getResultsToDisplay()).map((el, index) => (
            <div key={index}>
              <IonRow
                key={index}
                className={`hitsAnalyzeTableRow ${
                  (index + 1) % 2 === 0 ? "marginBottom" : ""
                }`}
              >
                <IonRow className="hitsAnalyzeIconRow">
                  <IconAndHits
                    iconSrc={ESPIKEIcons.INNER_MAN}
                    hitsRate={
                      el.targetType[ITargetsPropertiesOptions.MAN]?.y
                        ? `${el.targetType[ITargetsPropertiesOptions.MAN].x}/${
                            el.targetType[ITargetsPropertiesOptions.MAN].y
                          }`
                        : undefined
                    }
                  ></IconAndHits>
                </IonRow>
                <IonRow className="hitsAnalyzeIconRow">
                  <IconAndHits
                    iconSrc={ESPIKEIcons.INNER_CAR}
                    hitsRate={
                      el.targetType[ITargetsPropertiesOptions.CAR]?.y
                        ? `${el.targetType[ITargetsPropertiesOptions.CAR].x}/${
                            el.targetType[ITargetsPropertiesOptions.CAR].y
                          }`
                        : undefined
                    }
                  ></IconAndHits>
                </IonRow>
                <IonRow className="hitsAnalyzeIconRow">
                  <IconAndHits
                    iconSrc={ESPIKEIcons.INNER_WINDOW}
                    hitsRate={
                      el.targetType[ITargetsPropertiesOptions.WINDOW]?.y
                        ? `${
                            el.targetType[ITargetsPropertiesOptions.WINDOW].x
                          }/${
                            el.targetType[ITargetsPropertiesOptions.WINDOW].y
                          }`
                        : undefined
                    }
                  ></IconAndHits>
                </IonRow>
              </IonRow>
            </div>
          ))}
        </IonCol>
        <IonCol className="targetType">
          {/** aria cell */}
          <IonRow className="hitsAnalyzeTableRow targetTypeTitle">
            <PMLabel fontColor="light" fontFamily="Regular" fontSize="medium">
              {t("areaZone")}
            </PMLabel>
          </IonRow>
          <IonRow className="hitsAnalyzeTableRow targetTypeTitle">
            <IonRow className="hitsAnalyzeTableRow titleRowHA">
              <PMLabel fontColor="light" fontFamily="Regular" fontSize="medium">
                {t(ITargetsPropertiesOptions.BUILT)}
              </PMLabel>
            </IonRow>
            <IonRow className="hitsAnalyzeTableRow titleRowHA">
              <PMLabel fontColor="light" fontFamily="Regular" fontSize="medium">
                {t(ITargetsPropertiesOptions.TANGLED)}
              </PMLabel>
            </IonRow>
            <IonRow className="hitsAnalyzeTableRow titleRowHA">
              <PMLabel fontColor="light" fontFamily="Regular" fontSize="medium">
                {t(ITargetsPropertiesOptions.OPEN)}
              </PMLabel>
            </IonRow>
          </IonRow>
          {Object.values(getResultsToDisplay()).map((el, index) => (
            <div key={index}>
              <IonRow
                key={index}
                className={`hitsAnalyzeTableRow ${
                  (index + 1) % 2 === 0 ? "marginBottom" : ""
                }`}
              >
                <IonRow className="hitsAnalyzeIconRow">
                  <IconAndHits
                    iconSrc={ESPIKEIcons.INNER_BUILT}
                    hitsRate={
                      el.ariaCell[ITargetsPropertiesOptions.BUILT]?.y
                        ? `${el.ariaCell[ITargetsPropertiesOptions.BUILT].x}/${
                            el.ariaCell[ITargetsPropertiesOptions.BUILT].y
                          }`
                        : undefined
                    }
                  ></IconAndHits>
                </IonRow>
                <IonRow className="hitsAnalyzeIconRow">
                  <IconAndHits
                    iconSrc={ESPIKEIcons.INNER_COMPLEX}
                    hitsRate={
                      el.ariaCell[ITargetsPropertiesOptions.TANGLED]?.y
                        ? `${
                            el.ariaCell[ITargetsPropertiesOptions.TANGLED].x
                          }/${el.ariaCell[ITargetsPropertiesOptions.TANGLED].y}`
                        : undefined
                    }
                  ></IconAndHits>
                </IonRow>
                <IonRow className="hitsAnalyzeIconRow">
                  <IconAndHits
                    iconSrc={ESPIKEIcons.INNER_OPEN}
                    hitsRate={
                      el.ariaCell[ITargetsPropertiesOptions.OPEN]?.y
                        ? `${el.ariaCell[ITargetsPropertiesOptions.OPEN].x}/${
                            el.ariaCell[ITargetsPropertiesOptions.OPEN].y
                          }`
                        : undefined
                    }
                  ></IconAndHits>
                </IonRow>
              </IonRow>
            </div>
          ))}
        </IonCol>
        <IonCol className="targetType">
          {/**target range*/}
          <IonRow className="hitsAnalyzeTableRow targetTypeTitle">
            <PMLabel fontColor="light" fontFamily="Regular" fontSize="medium">
              {t("shootToTarget")}{" "}
            </PMLabel>
          </IonRow>
          <IonRow className="hitsAnalyzeTableRow targetTypeTitle">
            <IonRow className="hitsAnalyzeTableRow titleRowHA">
              <PMLabel fontColor="light" fontFamily="Regular" fontSize="medium">
                {t(ITargetsPropertiesOptions.SHORT)}
              </PMLabel>
            </IonRow>
            <IonRow className="hitsAnalyzeTableRow titleRowHA">
              <PMLabel fontColor="light" fontFamily="Regular" fontSize="medium">
                {t(ITargetsPropertiesOptions.MEDIUM)}
              </PMLabel>
            </IonRow>
            <IonRow className="hitsAnalyzeTableRow titleRowHA">
              <PMLabel fontColor="light" fontFamily="Regular" fontSize="medium">
                {t(ITargetsPropertiesOptions.LONG)}
              </PMLabel>
            </IonRow>
          </IonRow>
          {Object.values(getResultsToDisplay()).map((el, index) => (
            <div key={index}>
              <IonRow
                key={index}
                className={`hitsAnalyzeTableRow ${
                  (index + 1) % 2 === 0 ? "marginBottom" : ""
                }`}
              >
                <IonRow className="hitsAnalyzeIconRow">
                  <IconAndHits
                    iconSrc={ESPIKEIcons.INNER_SHORT}
                    hitsRate={
                      el.shootToTarget[ITargetsPropertiesOptions.SHORT]?.y
                        ? `${
                            el.shootToTarget[ITargetsPropertiesOptions.SHORT].x
                          }/${
                            el.shootToTarget[ITargetsPropertiesOptions.SHORT].y
                          }`
                        : undefined
                    }
                  ></IconAndHits>
                </IonRow>
                <IonRow className="hitsAnalyzeIconRow">
                  <IconAndHits
                    iconSrc={ESPIKEIcons.INNER_MEDIUM}
                    hitsRate={
                      el.shootToTarget[ITargetsPropertiesOptions.MEDIUM]?.y
                        ? `${
                            el.shootToTarget[ITargetsPropertiesOptions.MEDIUM].x
                          }/${
                            el.shootToTarget[ITargetsPropertiesOptions.MEDIUM].y
                          }`
                        : undefined
                    }
                  ></IconAndHits>
                </IonRow>
                <IonRow className="hitsAnalyzeIconRow">
                  <IconAndHits
                    iconSrc={ESPIKEIcons.INNER_LONG}
                    hitsRate={
                      el.shootToTarget[ITargetsPropertiesOptions.LONG]?.y
                        ? `${
                            el.shootToTarget[ITargetsPropertiesOptions.LONG].x
                          }/${
                            el.shootToTarget[ITargetsPropertiesOptions.LONG].y
                          }`
                        : undefined
                    }
                  ></IconAndHits>
                </IonRow>
              </IonRow>
            </div>
          ))}
        </IonCol>
      </IonRow>
    </IonCol>
  );
};
export default HitsAnalyze;
