import { FC, useContext, useEffect, useRef, useState } from "react";
import "./Hashtags.css";
import IPlan from "../../../../../Interfaces/IPlan";
import IForceTreeNode from "../../../../../Interfaces/IForceTreeNode";
import { useTranslation } from "react-i18next";
import ILabel from "../../../../../Interfaces/ILabel";
import IThreshold from "../../../../../Interfaces/IThreshold";
import PerformanceTemplate from "../../performanceTemplate";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import PMLabel from "../../../../../components/themeComponents/PMLabel";
import classNames from "classnames";
import PMIcon from "../../../../../components/themeComponents/PMIcon";
import EIconsSrc from "../../../../../Interfaces/EIconsSrc";
import Legend from "../../../../../components/Desktop/VerticalProgressBar/Legend";
import useGetHashtagsResults from "../../../../../components/CustomHooks/useGetHashtagsResults";
import { hashtagsColors } from "../../../../../Interfaces/Colors";
import HashtagsBarChart from "../../../../../components/Shared/Charts/Bar/HashtagsBarChart";
import { ChartDataset } from "chart.js";
import { TrainingTypesContext } from "../../../../../context/TrainingTypesContext/TrainingTypesContext";
import Divider from "../../../../../components/Shared/SPIKEGorilla/Divider";
import PMToggle from "../../../../../components/Shared/Buttons/PMToggle";
import useGetSubForces from "../../../../../components/CustomHooks/useGetSubForces";
import CheckboxesList from "../../../../../components/Shared/CheckboxesList/CheckboxesList";
import { MAX_SELECTED } from "../../../../../Configurations/consts";
import PMIconRoundedButton from "../../../../../components/themeComponents/PMIconRoundedButton";
import Spinner from "../../../../../components/Shared/Spinner/Spinner";
import { IForceHashtagsResults } from "../../../../../Interfaces/IHashtag";
import { getForceFullName } from "../../../../../services/helpers";
interface IProps {
  downloadLoading: boolean;
  selectedPlan: IPlan;
  allPlansOptions: IPlan[];
  onPlanSelectedHandler: (option: IPlan) => void;
}
enum EDisplayState {
  grades = "justGrade",
  rounds = "rounds",
}
const Hashtags: FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    downloadLoading,
    selectedPlan,
    allPlansOptions,
    onPlanSelectedHandler,
  } = props;
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(true);

  const [displayState, setDisplayState] = useState<EDisplayState | undefined>(
    EDisplayState.grades
  );

  const [isHashtagsModalOpen, setIsHashtagsModalOpen] =
    useState<boolean>(false);
  const [orbatForces, setOrbatForces] = useState<{
    forces: IForceTreeNode[];
    isSubordinates?: boolean;
  }>({ forces: [], isSubordinates: false });

  const { trainingTypes } = useContext(TrainingTypesContext);
  const { subForces } = useGetSubForces(
    orbatForces.forces,
    orbatForces.isSubordinates
  );

  const {
    hashtagsData: hashtagsResults,
    isLoading,
    refresh,
  } = useGetHashtagsResults(
    subForces.map((force) => force.id),
    selectedPlan?.id
  );
  const [barChartData, setBarChartData] = useState<ChartDataset<"bar">[]>([]);

  const [labels, setLabels] = useState<ILabel[]>([]);
  const [hashtags, setHashtags] = useState<string[]>([]);
  const [selectedHashtags, setSelectedHashtags] = useState<string[]>([]);
  const [hashtagsThresholds, setHashtagsThresholds] = useState<IThreshold[]>(
    []
  );
  const [hashtagsData, setHashtagsData] = useState<
    IForceHashtagsResults[] | undefined
  >();
  //set hashtags data results for graph state
  const setHashtagsDataForCheckedForces = () => {
    const labels: ILabel[] = subForces.map((force) => ({
      label: getForceFullName(force.name, force.force_type, t),
      id: force.id,
    }));

    setLabels(labels);
    if (hashtagsData && subForces.length) {
      const dataSets: ChartDataset<"bar">[] = [];

      selectedHashtags.forEach((hashtag, index) => {
        //add force results for each selected hashtags
        const forceData = subForces.map((force) => {
          const forceResult = hashtagsData.find(
            (res) => +res.forceId === +force.id
          );
          if (!forceResult) return 0;

          let totalCount = 0;
          let length = 0;

          forceResult.trainingTypeHashtagsResults.forEach((trainingResult) => {
            trainingResult.hashtagsResults.forEach((hashtagResult) => {
              if (hashtagResult.name === hashtag) {
                totalCount +=
                  displayState === EDisplayState.grades
                    ? hashtagResult.grade
                    : hashtagResult.rounds;
                length++;
              }
            });
          });

          return Math.round(
            length
              ? displayState === EDisplayState.grades
                ? totalCount / length
                : totalCount
              : 0
          );
        });

        dataSets.push({
          label: hashtag,
          data: forceData,
          backgroundColor: hashtagsColors[index],
          borderWidth: 1,
        });
      });

      setBarChartData(dataSets);

      let hashtagsThresholdsForForces: IThreshold[] = [];

      subForces.forEach((force) => {
        const avgHashtags: IThreshold[] = selectedHashtags.map((selHash) => {
          const hashtags = hashtagsData
            .flatMap((forceData) =>
              forceData.trainingTypeHashtagsResults.flatMap(
                (tt) => tt.hashtagsResults
              )
            )
            .filter((hashtag) => hashtag.name === selHash);

          const averageGrade = Math.round(
            hashtags.reduce(
              (acc, ht) =>
                acc +
                (displayState === EDisplayState.grades ? ht.grade : ht.rounds),
              0
            ) / (hashtags.length || 1)
          );

          return { id: selHash, threshold: averageGrade };
        });

        hashtagsThresholdsForForces.push({
          id: force.id,
          thresholds: avgHashtags,
        });
      });

      setHashtagsThresholds(hashtagsThresholdsForForces);
    } else {
      if (
        !hashtagsData?.find(
          (data) => selectedForceBar?.forceId === data.forceId
        )
      )
        setSelectedForceBar(undefined); //close side bar if the selected force is unchecked
      if (!subForces.length) {
        setLabels([]);
        setBarChartData([]);
      }
    }
  };
  useEffect(() => {
    if (!subForces.length) setHashtagsData(undefined);
    else if (hashtagsResults) setHashtagsData(hashtagsResults);
  }, [hashtagsResults, subForces]);

  useEffect(() => {
    setHashtagsDataForCheckedForces();
  }, [hashtagsData, subForces, displayState, selectedHashtags]);

  useEffect(() => {
    if (hashtagsData) {
      const hashtagSets = new Set<string>();

      hashtagsData.forEach((hashtagData) => {
        hashtagData.trainingTypeHashtagsResults.forEach((trainingResult) => {
          trainingResult.hashtagsResults.forEach((hashtagResult) => {
            hashtagSets.add(hashtagResult.name);
          });
        });
      });
      setHashtags(Array.from(hashtagSets));

      const topHashtags = Array.from(hashtagSets).slice(0, MAX_SELECTED);
      setSelectedHashtags(topHashtags);
    }
  }, [hashtagsData]);

  const [isRemove, setIsRemove] = useState<boolean>(false);
  const [selectedForceBar, setSelectedForceBar] = useState<{
    hashtag: string;
    forceId: number;
    forceName: string;
  }>();

  const onBarClick = (elements: any) => {
    if (elements.length > 0) {
      const { datasetIndex, index } = elements[0];
      const forceId = labels[index].id;
      const hashtagName = barChartData[datasetIndex].label;
      setSelectedForceBar({
        hashtag: hashtagName || "",
        forceId: Number(forceId),
        forceName:
          subForces.find((force) => +force.id === +forceId)?.name || "",
      });
    }
  };

  useEffect(() => {
    setIsRemove(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRemove]);
  const selectedRef = useRef<any>(null);

  useEffect(() => {
    if (selectedRef.current) {
      // Use requestAnimationFrame to ensure the DOM is ready
      requestAnimationFrame(() => {
        selectedRef.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      });
    }
  }, [selectedForceBar]);

  const onCheckForce = async (
    forces: IForceTreeNode[],
    isSubordinates?: boolean
  ) => {
    setOrbatForces({ forces, isSubordinates });
  };

  const StationsTooltips = //component to display the tooltips details in the left panel
    (
      <IonGrid className="stationsTooltipsColumn">
        <IonRow className="stationDetailsForceRow">
          <PMIcon
            iconSrc={EIconsSrc.CLOSE}
            onClick={() => setSelectedForceBar(undefined)}
            size="large"
          ></PMIcon>
        </IonRow>
        <IonRow className="stationDetailsForceRow">
          <PMLabel
            fontFamily="SemiBold"
            fontSize="xxLarge"
            fontColor="light"
            cssClass="stationDetailsTitle"
          >
            {selectedForceBar?.forceName}
          </PMLabel>
        </IonRow>

        <IonCol className="forceDetailsRow scrollM">
          {selectedHashtags.map((hashtag, index) => {
            const isSelected = selectedForceBar?.hashtag === hashtag;
            let forceResults = hashtagsData?.find(
              (res) => res.forceId === selectedForceBar?.forceId
            );
            let trainingTypesGrades = forceResults?.trainingTypeHashtagsResults
              .map((trainingTypeResult) => {
                const hashtagResult = trainingTypeResult.hashtagsResults.find(
                  (result) => result.name === hashtag
                );

                // Return null if hashtagResult is undefined
                if (!hashtagResult) return null;

                return {
                  trainingTypeId: trainingTypeResult.trainingTypeId,
                  grade: hashtagResult.grade,
                  rounds: hashtagResult.rounds,
                };
              })
              .filter((result) => result !== null); // Remove null elements

            return trainingTypesGrades?.length ? (
              <div key={index} className="HashtagDetailsCol">
                {index !== 0 ? (
                  <IonRow className="dividerRow">
                    <Divider isFull />
                  </IonRow>
                ) : null}
                <IonCol
                  onClick={() => {
                    setSelectedForceBar((prev) =>
                      prev
                        ? {
                            ...prev,
                            hashtag: String(hashtag),
                          }
                        : undefined
                    );
                  }}
                  key={index}
                  className={classNames("tooltipsSection", {
                    selected: isSelected,
                  })}
                  ref={isSelected ? selectedRef : null} // Attach ref if selected in order to scroll to its place
                >
                  <IonRow className="stationDetailsForceRow">
                    <Legend
                      color={hashtagsColors[index] || ""}
                      label={hashtag}
                      id={index}
                      fontsize={"xLarge"}
                    />
                  </IonRow>
                  <IonRow className="stationDetailsForceRow"></IonRow>
                  <IonRow key={index} className="hashtagsDetailsHeaderRow">
                    <IonCol size="5" className="hashtagDetailCol">
                      <PMLabel fontColor="light">{t("station")}</PMLabel>
                    </IonCol>
                    <IonCol className="hashtagDetailCol">
                      <PMLabel fontColor="light">{t("justGrade")}</PMLabel>
                    </IonCol>
                    <IonCol size="2" className="hashtagDetailCol">
                      <PMLabel fontColor="light">{t("rounds")}</PMLabel>
                    </IonCol>
                  </IonRow>
                  <IonRow className="stationDetailsForceRow"></IonRow>
                  {trainingTypesGrades?.map((trainingType: any, index) => {
                    {
                      return (
                        <IonRow key={index} className={`hashtagsDetailsRow `}>
                          <IonCol size="5" className="hashtagDetailCol">
                            <PMLabel fontColor="light">
                              {`${t(
                                trainingTypes.find(
                                  (tt) =>
                                    Number(tt.id) ===
                                    Number(trainingType.trainingTypeId)
                                )?.name
                              )}`}
                            </PMLabel>
                          </IonCol>
                          <IonCol className="hashtagDetailCol">
                            <PMLabel fontColor="light">
                              {`${Math.round(Number(trainingType.grade))}`}
                            </PMLabel>
                          </IonCol>
                          <IonCol size="2" className="hashtagDetailCol">
                            <PMLabel fontColor="light">
                              {`${Math.round(trainingType.rounds)}`}
                            </PMLabel>
                          </IonCol>
                        </IonRow>
                      );
                    }
                  })}
                </IonCol>
              </div>
            ) : null;
          })}
        </IonCol>
      </IonGrid>
    );

  return (
    <PerformanceTemplate
      headerComponent={
        <PMToggle
          firstOptionText={EDisplayState.grades}
          secondOptionText={EDisplayState.rounds}
          firstOptionValue={EDisplayState.grades}
          secondOptionValue={EDisplayState.rounds}
          state={displayState}
          setState={setDisplayState}
        />
      }
      loadingText={
        isLoading ? t("loadingData") : downloadLoading ? t("downloadFile") : ""
      }
      chart={
        <div className="barD">
          {isLoading ? <Spinner className="stackSpinner" /> : null}
          <CheckboxesList
            limit={MAX_SELECTED}
            list={hashtags.map((hashtag, index) => ({
              isChecked: selectedHashtags.includes(hashtag),
              name: hashtag,
              isExecuted: false,
              id: index,
            }))}
            isOpen={isHashtagsModalOpen}
            onCancel={() => {
              setIsHashtagsModalOpen(false);
            }}
            onSave={(hashtagsTemp) => {
              setSelectedHashtags(Array.from(hashtagsTemp));
              setIsHashtagsModalOpen(false);
            }}
            title={t("selectHashtags")}
          ></CheckboxesList>
          <div className="grapsWrap">
            <div className="selectHashtagButton">
              <PMIconRoundedButton
                iconSrc={EIconsSrc.HASHTAG}
                disabled={!(hashtags.length && subForces.length)}
                onClick={() => {
                  setIsHashtagsModalOpen(true);
                }}
              ></PMIconRoundedButton>
              <div className="hashtagsBarWrap">
                <HashtagsBarChart
                  barChartData={barChartData}
                  hashtagsThresholds={hashtagsThresholds}
                  labels={labels}
                  plan={selectedPlan}
                  isDesktop
                  onBarClick={onBarClick}
                  hashtagsForcesResults={hashtagsData}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      }
      detailsComponent={selectedForceBar ? StationsTooltips : undefined}
      iButtonsDisabled={!subForces.length}
      refreshButtonText={t("refresh")}
      onClickRefreshButton={() => refresh()}
      isDrawerOpen={isDrawerOpen}
      loading={isLoading}
      orbatForces={subForces}
      selectedPlan={selectedPlan}
      plansOptions={allPlansOptions}
      onPlanSelectedHandler={onPlanSelectedHandler}
      setChecked={onCheckForce}
      setDrawerOpen={setDrawerOpen}
      title={t("hashtags")}
      canSelectAllChildren
      disableForcesAboveLevel={1}
    ></PerformanceTemplate>
  );
};

export default Hashtags;
