import React from "react";
import ReactDropdown, { Option } from "react-dropdown";
import "./Dropdown.css";

interface IDropdownProps {
  options: (string | Option)[];
  onChange: (arg: Option) => void;
  value: string;
  placeholder: string;
  disabled?: boolean;
  controlClassName: string | "Dropdown";
  arrowClassName: string | "DropdownArrow";
  placeholderClassName: string | "DropdownPlaceholder";
  menuClassName: string | "DropdownMenu";
  isLoading?: boolean;
}
const Dropdown = (props: IDropdownProps) => {
  return (
    <ReactDropdown
      options={props.options}
      onChange={(arg: Option) => props.onChange(arg)}
      value={"" || props.value}
      placeholder={props.placeholder}
      controlClassName={`${props.controlClassName} ${
        props.isLoading ? "loadingDropdown" : ""
      }  ${props.disabled ? "disabledDropdown" : ""}`}
      arrowClassName={`${props.arrowClassName}  ${
        props.disabled ? "disabledArrow" : ""
      }`}
      placeholderClassName={`${props.placeholderClassName} DropdownPlaceholder`}
      menuClassName={`${props.menuClassName} scrollM`}
      disabled={props.disabled}
    ></ReactDropdown>
  );
};

export default Dropdown;
