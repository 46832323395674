// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentContainerM {
  --ion-background-color: var(--ion-color-primary);
  flex-wrap: nowrap;
  --overflow: hidden !important;
}

.itemsContainerTM {
  width: 100%;
  height: 90%;
  padding: 0;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
}
.mobileweb .itemsContainerTM {
  height: calc(90% - 70px);
}

.stationNameItemM {
  --border-color: var(--ion-color-dividerAndroidTitle);
  --background: var(--ion-color-primary);
  font-size: large;
  font-family: "Bold";
  padding: 0;
  --min-height: 0px;
}

.itemM {
  width: 100%;
  --background: var(--ion-color-primary);
  --padding-start: 0px;
  --padding-end: 0px;
  --inner-padding-end: 0px;
}

.traineeNameLabelM {
  width: 100%;
  --color: var(--ion-color-fontXLight);
  font-size: 16pt;
  font-family: "Regular";
}

.titleM {
  margin-top: 0;
  --color: var(--ion-color-fontXLight);
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/MenualStation/CombatFitnessStation/CombatFitnessStation.css"],"names":[],"mappings":"AAAA;EACE,gDAAgD;EAChD,iBAAiB;EACjB,6BAA6B;AAC/B;;AAEA;EACE,WAAW;EACX,WAAW;EACX,UAAU;EACV,cAAc;EACd,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,aAAa;AACf;AACA;EACE,wBAAwB;AAC1B;;AAEA;EACE,oDAAoD;EACpD,sCAAsC;EACtC,gBAAgB;EAChB,mBAAmB;EACnB,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,sCAAsC;EACtC,oBAAoB;EACpB,kBAAkB;EAClB,wBAAwB;AAC1B;;AAEA;EACE,WAAW;EACX,oCAAoC;EACpC,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,oCAAoC;AACtC","sourcesContent":[".contentContainerM {\n  --ion-background-color: var(--ion-color-primary);\n  flex-wrap: nowrap;\n  --overflow: hidden !important;\n}\n\n.itemsContainerTM {\n  width: 100%;\n  height: 90%;\n  padding: 0;\n  padding-top: 0;\n  padding-bottom: 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  padding: 24px;\n}\n.mobileweb .itemsContainerTM {\n  height: calc(90% - 70px);\n}\n\n.stationNameItemM {\n  --border-color: var(--ion-color-dividerAndroidTitle);\n  --background: var(--ion-color-primary);\n  font-size: large;\n  font-family: \"Bold\";\n  padding: 0;\n  --min-height: 0px;\n}\n\n.itemM {\n  width: 100%;\n  --background: var(--ion-color-primary);\n  --padding-start: 0px;\n  --padding-end: 0px;\n  --inner-padding-end: 0px;\n}\n\n.traineeNameLabelM {\n  width: 100%;\n  --color: var(--ion-color-fontXLight);\n  font-size: 16pt;\n  font-family: \"Regular\";\n}\n\n.titleM {\n  margin-top: 0;\n  --color: var(--ion-color-fontXLight);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
