import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ElementsContext } from "../../context/ElementsContext/ElementsContext";
import { TrainingTypesContext } from "../../context/TrainingTypesContext/TrainingTypesContext";
import { StationsContext } from "../../context/StationsContext/StationsContext";
import { EAppMode } from "../../Enums/EAppMode";
type IGradesOptionForElement = {
  [key: number]: number[];
};

export interface IFireSupportTrainingTypeData {
  id: number;
  name: string;
  elements: IFireSupportElementData[];
  assessment: string;
}
export interface IFireSupportElementData {
  id: number;
  name: string;
  gradeOptions: number[];
  dataTypeName: string;
  selectedGrade: string | number | undefined;
}
const UseGetFireSupportDataPosting = (appMode: EAppMode) => {
  const { t } = useTranslation();
  const [dataPostingTrainingTypes, setDataPostingTrainingTypes] = useState<
    IFireSupportTrainingTypeData[]
  >([]);
  const { stations } = useContext(StationsContext);
  const { elements } = useContext(ElementsContext);
  const { trainingTypes } = useContext(TrainingTypesContext);

  //map with elements grades options by element id
  const elementsGradesOptions: IGradesOptionForElement = {
    221: [1, 2, 3, 4, 5],
    222: [1, 2, 3, 4, 5],
    223: [2, 4, 6, 8, 10],
    224: [1, 2, 3, 4, 5],
    225: [1, 2, 3, 4, 5],
    226: [1, 2, 3, 4, 5],
    227: [2, 4, 6, 8, 10],
    228: [1, 2, 3, 4, 5],
    229: [1, 2, 3, 4, 5],
    230: [1, 2, 3, 4, 5],
    231: [1, 2, 3, 4, 5],
    232: [1, 2, 3, 4, 5],
    233: [1, 2, 3, 4, 5],
    234: [1, 2, 3, 4, 5],
    235: [3, 6, 9, 12, 15],
    236: [1, 2, 3, 4, 5],
  };
  useEffect(() => {
    //for each training type add the the elements with the grades option and an assessment
    setDataPostingTrainingTypes(
      trainingTypes
        .filter(
          (
            tt //filter only training types of stations with fire support app mode
          ) =>
            EAppMode.FIRE_SUPPORT ===
            stations.find((s) => +s.id === +tt.stationId)?.appMode
        )
        .map((trainingType) => {
          let trainingTypeElements = elements.filter(
            (element) =>
              element.trainingTypeId === trainingType.id && !element.isArchived
          );
          return {
            id: trainingType.id,
            name: t(trainingType.name),
            elements: trainingTypeElements.map((element) => ({
              id: element.id,
              name: t(element.name),
              gradeOptions: elementsGradesOptions[element.id],
              dataTypeName: element.name,
              selectedGrade: undefined,
            })),
            assessment: "",
          };
        })
    );
  }, [elements, trainingTypes]);
  return { dataPostingTrainingTypes, setDataPostingTrainingTypes };
};
export default UseGetFireSupportDataPosting;
