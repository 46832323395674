// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-tooltip {
  z-index: 1000;
  background-color: var(--ion-color-orange) !important;
  text-align: center;
  font-family: SemiBold !important;
  /* opacity: 1 !important; */
  box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.15) !important;
  margin: 2px;
  font-size: medium !important;
  color: var(--ion-color-fontDark) !important;
}
/* .custom-tooltip .tooltip-custom-arrow {
  width: 15px;
  height: 15px;
  bottom: -8px !important;
  margin: 2px;
} */
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/PMTooltip/PMTooltip.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,oDAAoD;EACpD,kBAAkB;EAClB,gCAAgC;EAChC,2BAA2B;EAC3B,0DAA0D;EAC1D,WAAW;EACX,4BAA4B;EAC5B,2CAA2C;AAC7C;AACA;;;;;GAKG","sourcesContent":[".custom-tooltip {\n  z-index: 1000;\n  background-color: var(--ion-color-orange) !important;\n  text-align: center;\n  font-family: SemiBold !important;\n  /* opacity: 1 !important; */\n  box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.15) !important;\n  margin: 2px;\n  font-size: medium !important;\n  color: var(--ion-color-fontDark) !important;\n}\n/* .custom-tooltip .tooltip-custom-arrow {\n  width: 15px;\n  height: 15px;\n  bottom: -8px !important;\n  margin: 2px;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
