// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .tabs ion-label {
  font-family: "Bold";
  font-size: medium;
} */

.tabs ion-icon {
  font-size: 100%;
}
.mobileTabs {
  min-height: 60px;
}
.mobileTabs ion-tab-button {
  font-size: x-large;
  min-width: fit-content;
  max-width: 25%;
  --padding-start: 2px;
  --padding-end: 2px;
}

.mobileTabs {
  --background: var(--ion-color-primary);
  box-shadow: var(--box-shadow);
}

.keyboard-is-open .tabbar {
  display: none;
}

.keyboard-is-open scroll-content {
  margin-bottom: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Mobile/Tabs/Tabs.css"],"names":[],"mappings":"AAAA;;;GAGG;;AAEH;EACE,eAAe;AACjB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,cAAc;EACd,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,sCAAsC;EACtC,6BAA6B;AAC/B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":["/* .tabs ion-label {\n  font-family: \"Bold\";\n  font-size: medium;\n} */\n\n.tabs ion-icon {\n  font-size: 100%;\n}\n.mobileTabs {\n  min-height: 60px;\n}\n.mobileTabs ion-tab-button {\n  font-size: x-large;\n  min-width: fit-content;\n  max-width: 25%;\n  --padding-start: 2px;\n  --padding-end: 2px;\n}\n\n.mobileTabs {\n  --background: var(--ion-color-primary);\n  box-shadow: var(--box-shadow);\n}\n\n.keyboard-is-open .tabbar {\n  display: none;\n}\n\n.keyboard-is-open scroll-content {\n  margin-bottom: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
