import { useState } from "react";
import "./MobileFIreSupport.css";
import { useHistory, useParams } from "react-router-dom";
import FireSupportComponent from "../../../Desktop/DataPosting/FIreSupportComponent";
import DataPostingMobileTemplate from "../../../Mobile/DataPosting/DataPostingMobileTemplate";

const MobileFIreSupport = (): JSX.Element => {
  const params = useParams<{
    stationName: string;
    trainingTypeId: string;
    stationId: string;
    laneId: string;
    traineeName: string;
    traineeId: string;
    planId: string;
  }>();
  const traineeId = parseInt(params.traineeId);
  const traineeName = params.traineeName;
  const stationName = params.stationName;
  const history = useHistory();

  const [isComplete, setIsComplete] = useState<boolean>(false);

  const backHandler = () => {
    history.goBack();
  };

  return (
    <DataPostingMobileTemplate
      stationName={stationName}
      traineeName={traineeName}
      isSendDisabled={!isComplete}
    >
      <FireSupportComponent
        traineeId={traineeId}
        afterPostHandler={backHandler}
        isComplete={isComplete}
        setIsComplete={setIsComplete}
      ></FireSupportComponent>
    </DataPostingMobileTemplate>
  );
};

export default MobileFIreSupport;
