// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-heats,
.no-selected-forces,
.no-relevant {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.no-heats {
  height: 80%;
}

.not-selected {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-block-end: 4vh;
}

.no-selected-forces-in-date {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 30px;
  margin-left: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/NotExistLabel/NotExistLabel.css"],"names":[],"mappings":"AAAA;;;EAGE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":[".no-heats,\n.no-selected-forces,\n.no-relevant {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 60vh;\n}\n\n.no-heats {\n  height: 80%;\n}\n\n.not-selected {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin-block-end: 4vh;\n}\n\n.no-selected-forces-in-date {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  margin-right: 30px;\n  margin-left: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
