import React, { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ForcesContext from "../../../../../context/ForcesContext/forcesContext";
import EIconsSrc from "../../../../../Interfaces/EIconsSrc";
import { URLs } from "../../../../../Configurations/urls";
import useNavigation from "../../../../CustomHooks/useNavigation";
import NotExistLabel from "../../../../Shared/NotExistLabel/NotExistLabel";
import Spinner from "../../../../Shared/Spinner/Spinner";
import List from "../../../List/List";
import MobileHeader from "../../../MobileHeader/MobileHeader";
import MobileTitleRow from "../../../MobileHeader/MobileTitleRow";
import OrbatTreeModal from "../../../OrbatTreeModal/OrbatTreeModal";
import IPlan from "../../../../../Interfaces/IPlan";
import usePlans from "../../../../CustomHooks/usePlans";
import "./PlanSelectionScreen.css";
import { IonPage } from "@ionic/react";
import ELanguage from "../../../../../Enums/ELanguage";
import useMobileDataPostingParams from "../../../../CustomHooks/useMobileDataPostingParams";

type IProps = {};

const PlanSelectionScreen: FC<IProps> = (props: IProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const { navigate, returnToPreviousPage } = useNavigation();
  const { trainingTypeId } = useMobileDataPostingParams();
  const [loading, setLoading] = useState<boolean>(true);
  const { forces, isForcesTreeOpen, setIsForcesTreeOpen } =
    useContext(ForcesContext);
  const { plans } = usePlans();

  useEffect(() => {
    if (plans && plans.length !== 0) {
      setLoading(false);
    }
  }, [plans]);
  const comparePlans = (a: IPlan, b: IPlan) => {
    // Sort by isGlobal first (true before false)
    if (a.isGlobal && !b.isGlobal) {
      return -1;
    }
    if (!a.isGlobal && b.isGlobal) {
      return 1;
    }

    // Sort by startDate next (earlier dates before later dates)
    if (a.startDate && b.startDate) {
      if (a.startDate < b.startDate) {
        return -1;
      }
      if (a.startDate > b.startDate) {
        return 1;
      }
    }

    // If isGlobal and startDate are equal or undefined, maintain original order
    return 0;
  };

  // Sort the plans array using the custom comparison function
  const plansToDisplay = (plans: IPlan[]): IPlan[] => {
    const sortedPlans = plans.sort(comparePlans);

    return moveActivePlanToFirst(sortedPlans);
  };

  const moveActivePlanToFirst = (plans: IPlan[]): IPlan[] => {
    let tempArray = [...plans];
    const activePlanIndex = plans.findIndex((plan: IPlan) => plan.isActive);
    const removed = tempArray.splice(activePlanIndex, 1);
    tempArray.unshift({
      ...removed[0],
      name: `${removed[0].name} (${t("active")})`,
    });
    return tempArray;
  };

  const navigateToShootingDays = (item: IPlan) => {
    const currentPlan: IPlan | undefined = plans?.find(
      (currentPlan) => currentPlan.id === item.id
    );
    navigate(
      `${URLs.stations.shootingRange.plans.shootingDays.getURL(
        undefined,
        currentPlan?.id,
        trainingTypeId
      )}`,
      {
        planId: currentPlan?.id,
      }
    );
  };

  return (
    <IonPage className="plans-selection">
      <MobileHeader
        isUserDisplay={false}
        title={t("Shooting_Range")}
        iconStartSrc={
          i18n.language === ELanguage.he
            ? EIconsSrc.BACK_ICON_HE
            : EIconsSrc.BACK_ICON_EN
        }
        iconStartHandler={returnToPreviousPage}
        isShowForcesTree
      />
      <div className="plans-list">
        {forces.length > 0 ? (
          <>
            <MobileTitleRow title={t("chooseTrainingPlan")} />
            {loading ? (
              <Spinner />
            ) : (
              <List
                items={plansToDisplay(plans || [])}
                handleClick={(listItem: IPlan | undefined) =>
                  navigateToShootingDays(listItem!)
                }
              />
            )}
          </>
        ) : (
          <NotExistLabel
            text="noSelectedForces"
            className="no-selected-forces"
          />
        )}
      </div>
      <OrbatTreeModal
        limit={1}
        isOpen={isForcesTreeOpen}
        close={() => setIsForcesTreeOpen(false)}
        mode="secondary"
      />
    </IonPage>
  );
};

export default PlanSelectionScreen;
