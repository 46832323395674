import { IonItem } from "@ionic/react";
import React, { Dispatch, SetStateAction, useState } from "react";
import "./Plan.css";
import IPlan from "../../../Interfaces/IPlan";
import { trash, copy } from "ionicons/icons";
import { useDispatch } from "react-redux";
import { SELECTED_PLAN } from "../../../redux/actions/plansActions";
import PopoverMenu from "../../Shared/Popover/PopoverMenu";
import PMLabel from "../../themeComponents/PMLabel";
import PopoverItem from "../../themeComponents/PopoverItem";
import PMIcon from "../../themeComponents/PMIcon";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import { useTranslation } from "react-i18next";
import { useTrainingPlanValues } from "../../CustomHooks/HooksWithRQ/useTrainingPlanValues";
import { exportTrainingPlan } from "../../../services/plansSharedFunctions";

interface IPlanProps {
  plan: IPlan;
  handlePlanEdit: (id: number) => void;
  handlePlanImport: (id: number) => void;
  handlePlanDuplicate: (id: number) => void;
  handlePlanDelete: (id: number) => void;
  handlePlanActive: (id: number) => void;
  isAdmin: boolean;
  isGlobal: boolean;
  isEditable: boolean;
  isUnsaved: () => boolean;
  setIsShowUnsaveModal: Dispatch<SetStateAction<boolean>>;
  setPlanToChange: Dispatch<SetStateAction<IPlan | undefined>>;
  isSelected: boolean;
}
const Plan: React.FC<IPlanProps> = (props: IPlanProps): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { trainingPlanIndicators } = useTrainingPlanValues(
    props.plan.id,
    props.plan.allPlans
  );
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  const onPlanClick = () => {
    if (props.isUnsaved()) {
      props.setIsShowUnsaveModal(true);
      props.setPlanToChange(props.plan);
    } else {
      dispatch({
        type: SELECTED_PLAN,
        selectedPlan: props.plan,
        initialPlan: props.plan,
        editMode: false,
      });
    }
  };
  return (
    <>
      <IonItem
        lines="none"
        color="sidebar"
        className={`planItem ${props.plan.isActive ? "activePlan" : ""}`}
      >
        <div className={`planDetailsDiv`} onClick={onPlanClick}>
          <PMLabel
            fontFamily="Regular"
            fontSize={props.plan.isActive ? "large" : "medium"}
            fontColor={props.plan.isActive ? "orange" : "xLight"}
          >
            {props.plan.name
              ? `${props.plan.name}  ${
                  props.plan.isActive ? `(${t("active")})` : ""
                }`
              : ""}
          </PMLabel>
        </div>
        <div className="menuParentDiv">
          <div className="activeIcon">
            {props.isSelected ? (
              <PMIcon
                size="small"
                color="light"
                iconSrc={EIconsSrc.PENCIL}
              ></PMIcon>
            ) : null}
          </div>
          {props.isAdmin ? (
            <PMIcon
              color={props.plan.isActive ? "light" : "light"}
              size="medium"
              iconSrc={EIconsSrc.MENU_ICON}
              onClick={(e: any) => {
                e.persist();
                setShowPopover({ showPopover: true, event: e });
              }}
            ></PMIcon>
          ) : null}

          <PopoverMenu
            popoverState={popoverState}
            onDismiss={() =>
              setShowPopover({ showPopover: false, event: undefined })
            }
          >
            <PopoverItem
              hidden={!props.isEditable}
              fontColor="light"
              onClickHandler={() => {
                setShowPopover({ showPopover: false, event: undefined });
                props.handlePlanEdit(props.plan.id);
              }}
              background="Blight"
            >
              <PMLabel fontFamily="Light" fontSize="medium">
                {t("edit")}
              </PMLabel>
              <PMIcon
                size="small"
                color="light"
                iconSrc={EIconsSrc.PENCIL}
              ></PMIcon>
            </PopoverItem>
            <PopoverItem
              fontColor="light"
              onClickHandler={() => {
                setShowPopover({ showPopover: false, event: undefined });
                props.handlePlanActive(props.plan.id);
              }}
              background="Blight"
            >
              <PMLabel fontFamily="Light" fontSize="medium">
                {t("setActive")}
              </PMLabel>
              <PMIcon
                size="medium"
                color="light"
                iconSrc={EIconsSrc.STAR}
              ></PMIcon>
            </PopoverItem>
            <PopoverItem
              hidden={props.isGlobal}
              fontColor="light"
              onClickHandler={() => {
                setShowPopover({ showPopover: false, event: undefined });
                props.handlePlanDelete(props.plan.id);
              }}
              background="Blight"
            >
              <PMLabel fontFamily="Light" fontSize="medium">
                {t("delete")}
              </PMLabel>
              <PMIcon size="small" color="light" iconSrc={trash}></PMIcon>
            </PopoverItem>
            <PopoverItem
              hidden={props.isGlobal}
              fontColor="light"
              onClickHandler={() => {
                setShowPopover({ showPopover: false, event: undefined });
                props.handlePlanDuplicate(props.plan.id);
              }}
              background="Blight"
            >
              <PMLabel fontFamily="Light" fontSize="medium">
                {t("duplicatePlan")}
              </PMLabel>
              <PMIcon size="small" color="light" iconSrc={copy}></PMIcon>
            </PopoverItem>
            <PopoverItem
              fontColor="light"
              onClickHandler={() => {
                setShowPopover({ showPopover: false, event: undefined });
                exportTrainingPlan(props.plan, trainingPlanIndicators, t);
              }}
              background="Blight"
            >
              <PMLabel fontFamily="Light" fontSize="medium">
                {t("export")}
              </PMLabel>
              <PMIcon
                size="medium"
                color="light"
                iconSrc={EIconsSrc.XLS_FILE_EXPORT}
              ></PMIcon>
            </PopoverItem>

            <PopoverItem
              hidden={!props.isEditable}
              fontColor="light"
              background="Blight"
              onClickHandler={() => {
                props.handlePlanImport(props.plan.id);
                setShowPopover({
                  showPopover: false,
                  event: undefined,
                });
              }}
            >
              <PMLabel fontFamily="Light" fontSize="medium">
                {t("import")}
              </PMLabel>
              <PMIcon iconSrc={EIconsSrc.XLS_FILE_IMPORT} size="medium" />
            </PopoverItem>
          </PopoverMenu>
        </div>
      </IonItem>
    </>
  );
};

export default Plan;
