// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shooting-day-selection {
  height: 100%;
  display: "flex";
  flex-direction: "column";
}

.shooting-days-list {
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Mobile/ManualStation/ShootingRange/ShootingDaySelectionScreen/ShootingDaySelectionScreen.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,wBAAwB;AAC1B;;AAEA;EACE,YAAY;AACd","sourcesContent":[".shooting-day-selection {\n  height: 100%;\n  display: \"flex\";\n  flex-direction: \"column\";\n}\n\n.shooting-days-list {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
