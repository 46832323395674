// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switchButton {
  direction: rtl;
  display: flex;
  flex-direction: row;
  height: 80%;
  width: 40%;
}
.switchSpan {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: var(--ion-color-light);
  border-color: transparent;
  border-style: solid;
  border-radius: 7px;
  color: var(--ion-color-fontLight) !important;
}
.true {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.false {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.selected.true {
  background-color: var(--ion-color-success) !important;
}
.selected.false {
  background-color: var(--ion-color-danger) !important;
}
.iconSB {
  height: 24px;
  width: 24px;
  color: var(--ion-color-fontWhite);
}
`, "",{"version":3,"sources":["webpack://./src/components/Mobile/SiteManagement/ManualStations/SwitchButton.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,UAAU;AACZ;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,wCAAwC;EACxC,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;EAClB,4CAA4C;AAC9C;AACA;EACE,yBAAyB;EACzB,4BAA4B;AAC9B;AACA;EACE,0BAA0B;EAC1B,6BAA6B;AAC/B;AACA;EACE,qDAAqD;AACvD;AACA;EACE,oDAAoD;AACtD;AACA;EACE,YAAY;EACZ,WAAW;EACX,iCAAiC;AACnC","sourcesContent":[".switchButton {\n  direction: rtl;\n  display: flex;\n  flex-direction: row;\n  height: 80%;\n  width: 40%;\n}\n.switchSpan {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n  background-color: var(--ion-color-light);\n  border-color: transparent;\n  border-style: solid;\n  border-radius: 7px;\n  color: var(--ion-color-fontLight) !important;\n}\n.true {\n  border-top-left-radius: 0;\n  border-bottom-left-radius: 0;\n}\n.false {\n  border-top-right-radius: 0;\n  border-bottom-right-radius: 0;\n}\n.selected.true {\n  background-color: var(--ion-color-success) !important;\n}\n.selected.false {\n  background-color: var(--ion-color-danger) !important;\n}\n.iconSB {\n  height: 24px;\n  width: 24px;\n  color: var(--ion-color-fontWhite);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
