import React from "react";
import "./LeadersList.css";
import { ILeaders } from "../../../Interfaces/ILeaders";
import { IonItemGroup, IonItem } from "@ionic/react";
import PMLabel from "../../themeComponents/PMLabel";

const LeadersList: React.FC<ILeaders> = (props: ILeaders): JSX.Element => {
  return (
    <IonItemGroup className={`leadersItemGroup ${props.platform}`}>
      {props.leaders?.map((leader, index) => (
        <IonItem
          key={index}
          lines="none"
          mode="md"
          className={"leadersItem " + (index > 2 ? "" : "top")}
        >
          <PMLabel fontColor="xLight" fontFamily="ExtraBold" fontSize="large">
            {index + 1}
          </PMLabel>
          <div className={"containerLeft " + props.platform + "Details"}>
            <div className="leadersDetails">
              <PMLabel
                fontColor="light"
                fontFamily="ExtraBold"
                fontSize="large"
              >
                {leader?.name}
              </PMLabel>
              <PMLabel fontFamily="Regular" fontColor="light">
                {leader?.affiliation.join(" / ")}
              </PMLabel>
            </div>
          </div>
          <PMLabel fontColor="xLight" fontFamily="ExtraBold" fontSize="large">
            {Math.round(leader?.grade)}
          </PMLabel>
        </IonItem>
      ))}
    </IonItemGroup>
  );
};

export default LeadersList;
