const MAX_TRAINEE_DISPLAYED = 7;
const TYPES = {
  GROUPING: "grouping",
  SCORE: "score",
  NUMBER_OF_HITS: "numberOfHits",
};
const MIN_RESULT = 0;
const MAX_RESULT = 100;
const SHOOTING_RANGE_STATION_NAME = "Shooting_Range";
const Magnet_FOF = "Magnet_FOF";
export {
  MAX_TRAINEE_DISPLAYED,
  TYPES,
  MAX_RESULT,
  MIN_RESULT,
  SHOOTING_RANGE_STATION_NAME,
  Magnet_FOF,
};
