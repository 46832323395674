import {
  MAX_RESULT,
  MIN_RESULT,
  TYPES,
} from "../Configurations/shootingRangeConsts";
import IShootingHeat from "../Interfaces/IShootingHeat";
import IShootingHeatType from "../Interfaces/IShootingHeatType";

const { GROUPING, NUMBER_OF_HITS, SCORE } = TYPES;

export const getFinalResultsByTypes = (
  result: number | string | undefined,
  type: "grouping" | "numberOfHits" | "score"
): IShootingHeatType => {
  let types: IShootingHeatType = {
    grouping: undefined,
    numberOfHits: undefined,
    score: undefined,
  };
  types[type] = Number(result);
  return types;
};

export const checkValidation = (
  heat: IShootingHeat | undefined,
  value: number
): boolean => {
  let isValid: boolean = true;
  switch (heat?.type) {
    case GROUPING:
      if (value < MIN_RESULT) {
        isValid = false;
      }
      break;
    case SCORE:
      if (value < MIN_RESULT || value > MAX_RESULT) {
        isValid = false;
      }
      break;
    case NUMBER_OF_HITS:
      if (value < MIN_RESULT || value > +heat.bullets) {
        isValid = false;
      }
      break;
    default:
      break;
  }
  return isValid;
};
