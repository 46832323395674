// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orbat-modal {
  direction: ltr;
  --background: var(--ion-color-enable);
}
.orbat-modal :lang(he) {
  direction: rtl;
  --background: var(--ion-color-enable);
}

ion-modal.orbat-modal.secondary {
  height: 100%;
}

.orbat-modal ion-row.modal-header-row {
  position: static;
}

.orbat-modal ion-icon {
  font-size: medium;
}

.orbat-modal ion-grid.modal-grid {
  margin: 0 !important;
  padding: 0 !important;
}

.orbat-modal ion-row.sub-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 3%;
  padding-left: 20px;
  padding-right: 20px;
}

.orbat-modal ion-row {
  width: 100%;
}

.orbat-modal ion-row.modal-tree-row {
  height: 90%;
  padding-left: 1%;
  padding-right: 1%;
}

.orbat-modal ion-row.modal-tree-row ion-checkbox {
  width: 6%;
  height: 6%;
  --border-radius: 2px !important;
  --background: transparent !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Mobile/OrbatTreeModal/OrbatTreeModal.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,qCAAqC;AACvC;AACA;EACE,cAAc;EACd,qCAAqC;AACvC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;EACpB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,eAAe;EACf,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,+BAA+B;EAC/B,oCAAoC;AACtC","sourcesContent":[".orbat-modal {\n  direction: ltr;\n  --background: var(--ion-color-enable);\n}\n.orbat-modal :lang(he) {\n  direction: rtl;\n  --background: var(--ion-color-enable);\n}\n\nion-modal.orbat-modal.secondary {\n  height: 100%;\n}\n\n.orbat-modal ion-row.modal-header-row {\n  position: static;\n}\n\n.orbat-modal ion-icon {\n  font-size: medium;\n}\n\n.orbat-modal ion-grid.modal-grid {\n  margin: 0 !important;\n  padding: 0 !important;\n}\n\n.orbat-modal ion-row.sub-header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding-top: 3%;\n  padding-left: 20px;\n  padding-right: 20px;\n}\n\n.orbat-modal ion-row {\n  width: 100%;\n}\n\n.orbat-modal ion-row.modal-tree-row {\n  height: 90%;\n  padding-left: 1%;\n  padding-right: 1%;\n}\n\n.orbat-modal ion-row.modal-tree-row ion-checkbox {\n  width: 6%;\n  height: 6%;\n  --border-radius: 2px !important;\n  --background: transparent !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
