// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttonContainerMT {
  display: flex;
  justify-content: flex-end;
}

.rowTextMT {
  font-family: "Regular";
  font-size: large;
  color: var(--ion-color-fontXLight);
  width: 100%;
}
.menualInputContainerM {
  width: 100%;
  --color: var(--ion-color-fontXLight);
  font-size: 16pt;
  font-family: "Regular";
  --background: var(--ion-color-dark) !important;
  --inner-padding-start: 10px;
  --border-radius: 2px;
}
.inputRowTM {
  margin-right: 7vw;
  margin-left: 7vw;
}
.combatFitnessWrap {
  height: 90%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/MenualStation/CombatFitnessStation/CombatFitnessComponent.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,kCAAkC;EAClC,WAAW;AACb;AACA;EACE,WAAW;EACX,oCAAoC;EACpC,eAAe;EACf,sBAAsB;EACtB,8CAA8C;EAC9C,2BAA2B;EAC3B,oBAAoB;AACtB;AACA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,WAAW;AACb","sourcesContent":[".buttonContainerMT {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.rowTextMT {\n  font-family: \"Regular\";\n  font-size: large;\n  color: var(--ion-color-fontXLight);\n  width: 100%;\n}\n.menualInputContainerM {\n  width: 100%;\n  --color: var(--ion-color-fontXLight);\n  font-size: 16pt;\n  font-family: \"Regular\";\n  --background: var(--ion-color-dark) !important;\n  --inner-padding-start: 10px;\n  --border-radius: 2px;\n}\n.inputRowTM {\n  margin-right: 7vw;\n  margin-left: 7vw;\n}\n.combatFitnessWrap {\n  height: 90%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
