export let mrkLanes = [
  { iestId: 1, siteManagerId: 1102 },
  { iestId: 2, siteManagerId: 1103 },
  { iestId: 3, siteManagerId: 1104 },
  { iestId: 4, siteManagerId: 1105 },
  { iestId: 6, siteManagerId: 1107 },
  { iestId: 7, siteManagerId: 1108 },
  { iestId: 8, siteManagerId: 1109 },
  { iestId: 9, siteManagerId: 1110 },
  { iestId: 11, siteManagerId: 1112 },
  { iestId: 12, siteManagerId: 1113 },
  { iestId: 13, siteManagerId: 1114 },
  { iestId: 14, siteManagerId: 1115 },
];
