import {
  IonAccordion,
  IonAccordionGroup,
  IonCol,
  IonContent,
  IonItem,
  IonMenu,
  IonMenuButton,
  IonRow,
} from "@ionic/react";
import "./MobileSideMenu.css";
import { FC, ReactNode, useContext } from "react";
import UserIcon from "../../Shared/UserIcon/UserIcon";
import { UserContext } from "../../../context/UserContext/userContext";
import PMLabel from "../../themeComponents/PMLabel";
import { useTranslation } from "react-i18next";
import PMIcon from "../../themeComponents/PMIcon";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import {
  mobileRoutes,
  routeRoles,
  userRoles,
} from "../../../services/routeRoles";
import useNavigation from "../../CustomHooks/useNavigation";
import { AboutContent } from "../../Shared/About/About";
import { AppModeGroup } from "../../Shared/AppModeChange/AppModeChange";
import { caretDown } from "ionicons/icons";
import EEnvironment from "../../../Enums/EEnvironment";
import { environment } from "../../../Configurations/consts";
import MicrosoftLogout from "../../CustomHooks/MicrosoftLogout";
import { AppModeContext } from "../../../context/AppModeContext/AppModeContext";
import { EAppMode } from "../../../Enums/EAppMode";
import { menuController } from "@ionic/core/components";
import ELanguage from "../../../Enums/ELanguage";
import { LanguageChangePopover } from "../../Shared/LanguageChangePopover/LanguageChangePopover";
import PlansMobile from "../../Shared/PlansMobile/PlansMobile";

interface IOption {
  value: string;
  onClick: () => void;
}
interface IMenuItemProps {
  icon: EIconsSrc;
  url?: string;
  onClick?: () => void;
  options?: IOption[];
  title: string;
  component?: () => ReactNode;
  key: number;
}
const MobileSideMenu: FC = (): JSX.Element => {
  const { user, logout } = useContext(UserContext);
  const { t, i18n } = useTranslation();
  const { replace } = useNavigation();
  const { logoutUser } = MicrosoftLogout();
  const { appMode } = useContext(AppModeContext);
  const closeMenu = () => {
    menuController.close("mobile-menu");
  };

  const getMenuItems = () => {
    let menuItems: IMenuItemProps[] = [
      {
        icon: EIconsSrc.LANGUAGE,
        component: () => (
          <LanguageChangePopover isMobile></LanguageChangePopover>
        ),
        title: "Language",
        key: 4,
      },

      {
        icon: EIconsSrc.STAR,
        component: () => <PlansMobile></PlansMobile>,
        title: "selectActiveTrainingPlan",
        key: 5,
      },
    ];
    if (appMode === EAppMode.WARTAC) {
      menuItems = [
        {
          icon: EIconsSrc.SITE_STATUS,
          url: mobileRoutes.sitStatus,
          title: "siteManagement",
          key: 2,
        },
        ...menuItems,
      ];
    }
    if (
      (user.role === routeRoles.Admins[0] ||
        user.role === routeRoles.Instructors[0]) &&
      appMode !== EAppMode.SPIKE
    )
      menuItems.push({
        icon: EIconsSrc.DATA_POSTING,
        url: mobileRoutes.stations,
        title: "dataPosting",
        key: 1,
      });

    if (environment !== EEnvironment.staging) {
      menuItems.push({
        icon: EIconsSrc.INFO,
        component: () => <AboutContent />,
        title: "about",
        key: 5,
      });
    }
    if (
      environment === EEnvironment.staging &&
      (user.role === userRoles.Admin || user.isBagiraUser)
    ) {
      menuItems.push({
        icon: EIconsSrc.MODE_SELECTION,
        component: () => <AppModeGroup onSelectHandler={closeMenu} />,
        title: "modeSelection",
        key: 3,
      });
    }

    return menuItems.sort((a, b) => a.key - b.key);
  };

  return (
    <IonMenu
      side={i18n.language === ELanguage.he ? "start" : "end"}
      contentId="mobile-main-content"
      className="mobileSideMenu"
      menuId="mobile-menu"
      id="mobile-menu"
    >
      <IonContent className="mobileSideMenuContent" scrollY={false}>
        <IonRow className="ion-justify-content-end ">
          <IonMenuButton>
            <PMIcon iconSrc={EIconsSrc.CLOSE}></PMIcon>
          </IonMenuButton>
        </IonRow>
        <IonRow className="menuHeaderMobile">
          <IonCol size="2.3" className="userIconWrap">
            <UserIcon userName={user.displayName} />
          </IonCol>
          <IonCol className="userWrap">
            <IonRow className="userNameRow">
              <PMLabel fontSize="xxLarge">{user.displayName}</PMLabel>
            </IonRow>
            <IonRow>
              <PMLabel fontSize="small">{t(user.role)}</PMLabel>
            </IonRow>
          </IonCol>
        </IonRow>
        <IonCol className="itemsWrapMobile">
          <IonAccordionGroup className="menuItemsMobile" multiple>
            {getMenuItems().map((item, index) =>
              item.url ? (
                <IonRow key={index}>
                  <IonMenuButton className="menuButtonSM">
                    <IonItem
                      lines="none"
                      className="sideMenuItem"
                      onClick={() => {
                        replace(item.url!);
                      }}
                    >
                      <IonCol className="iconColMenuMobile">
                        <PMIcon
                          iconSrc={item.icon}
                          color="xLight"
                          cssClass="menuMobileIcon"
                        ></PMIcon>
                      </IonCol>
                      <IonCol>
                        <PMLabel
                          fontColor="light"
                          fontSize="large"
                          fontFamily="Regular"
                        >
                          {t(item.title)}
                        </PMLabel>
                      </IonCol>
                    </IonItem>
                  </IonMenuButton>
                </IonRow>
              ) : (
                <IonAccordion key={index} toggleIcon={caretDown}>
                  <IonItem lines="none" className="sideMenuItem" slot="header">
                    <IonCol className="iconColMenuMobile">
                      <PMIcon
                        iconSrc={item.icon}
                        color="xLight"
                        cssClass="menuMobileIcon"
                      ></PMIcon>
                    </IonCol>
                    <IonCol>
                      <PMLabel
                        fontColor="light"
                        fontSize="large"
                        fontFamily="Regular"
                      >
                        {t(item.title)}
                      </PMLabel>
                    </IonCol>
                  </IonItem>
                  <div slot="content" className="mobileSideMenuContent">
                    {item.component
                      ? item.component()
                      : item.options?.map((option, index) => (
                          <IonItem
                            lines="none"
                            className="sideMenuItem"
                            onClick={option.onClick}
                            key={index}
                          >
                            <IonCol>
                              <PMLabel
                                fontColor="light"
                                fontSize="medium"
                                fontFamily="Regular"
                              >
                                {t(option.value)}
                              </PMLabel>
                            </IonCol>
                          </IonItem>
                        ))}
                  </div>
                </IonAccordion>
              )
            )}
          </IonAccordionGroup>
          <IonRow className="logoutRowMenuMobile">
            <IonItem
              lines="none"
              className="sideMenuItem"
              onClick={() => {
                if (environment !== EEnvironment.localSite) logoutUser();
                logout();
              }}
            >
              <IonCol className="iconColMenuMobile">
                <PMIcon
                  iconSrc={EIconsSrc.LOGOUT}
                  color="xLight"
                  cssClass="menuMobileIcon"
                ></PMIcon>
              </IonCol>
              <IonCol>
                <PMLabel
                  fontColor="light"
                  fontSize="large"
                  fontFamily="Regular"
                >
                  {t("logout")}
                </PMLabel>
              </IonCol>
            </IonItem>
          </IonRow>
        </IonCol>
      </IonContent>
    </IonMenu>
  );
};

export default MobileSideMenu;
