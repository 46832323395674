import React from "react";
import { Route } from "react-router-dom";
import { useLocation } from "react-router-dom";

// css
import "./TrainingPlanTabs.css";

// ionic imports
import {
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";

// components
import TrainingPlanPage from "./TrainingPlan";

// interfaces
import TabItem from "./../../../Interfaces/ITabItem";
import { useTranslation } from "react-i18next";
import PMTitle from "../../../components/themeComponents/PMTitle";
import { routes } from "../../../services/routeRoles";

const TrainingPlanTabs: React.FC = (): JSX.Element => {
  const location = useLocation();
  const { t } = useTranslation();

  // Tabs buttons options
  const TabsOptions: TabItem[] = [
    {
      url: routes.trainingPlan,
      title: t("PlansPage"),
    },
  ];

  return (
    <IonTabs className="pm-tabs">
      <IonRouterOutlet id="main">
        <Route path={routes.trainingPlan} component={TrainingPlanPage} exact />
      </IonRouterOutlet>
      <IonTabBar
        slot="top"
        className="ion-justify-content-start trainingPlansTabs"
      >
        {TabsOptions.map((item: TabItem, index: number) => {
          return (
            <IonTabButton
              className={location.pathname === item.url ? "tab-selected" : ""}
              tab={item.title}
              href={item.url}
              key={item.url}
            >
              <PMTitle fontColor="light" fontFamily="Light" fontSize="medium">
                {item.title}
              </PMTitle>
            </IonTabButton>
          );
        })}
      </IonTabBar>
    </IonTabs>
  );
};
export default TrainingPlanTabs;
