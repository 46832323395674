// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentContainerMS {
  flex-wrap: nowrap;
  --overflow: hidden !important;
  height: 100%;
}

.backButtonIconMS {
  height: 24px;
  width: 24px;
}
.gridMS {
  height: 100%;
}
.backButtonMS {
  --color: var(--ion-color-fontXLight);
  --border-radius: 30px !important;
  width: 44px;
  height: 44px;
  --icon-font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.itemsContainerMS {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 60px);
  padding: 0;
}
.pageMS {
  --ion-background-color: var(--ion-color-primary);
}

.medicalSearchWrap {
  width: 100%;
  padding-top: 20px;
  height: 100%;
}
.searchSoldierWrapRow {
  width: 100%;
  display: flex;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.searchSoldierWrapCol {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
}
.nextItem {
  width: 100%;
  height: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/MenualStation/SearchSoldierComp/SearchSoldier.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;AACA;EACE,YAAY;AACd;AACA;EACE,oCAAoC;EACpC,gCAAgC;EAChC,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,yBAAyB;EACzB,UAAU;AACZ;AACA;EACE,gDAAgD;AAClD;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,YAAY;AACd;AACA;EACE,WAAW;EACX,aAAa;EACb,SAAO;EACP,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,UAAU;AACZ;AACA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".contentContainerMS {\n  flex-wrap: nowrap;\n  --overflow: hidden !important;\n  height: 100%;\n}\n\n.backButtonIconMS {\n  height: 24px;\n  width: 24px;\n}\n.gridMS {\n  height: 100%;\n}\n.backButtonMS {\n  --color: var(--ion-color-fontXLight);\n  --border-radius: 30px !important;\n  width: 44px;\n  height: 44px;\n  --icon-font-size: 24px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.itemsContainerMS {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  height: calc(100% - 60px);\n  padding: 0;\n}\n.pageMS {\n  --ion-background-color: var(--ion-color-primary);\n}\n\n.medicalSearchWrap {\n  width: 100%;\n  padding-top: 20px;\n  height: 100%;\n}\n.searchSoldierWrapRow {\n  width: 100%;\n  display: flex;\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  overflow-y: auto;\n}\n.searchSoldierWrapCol {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  padding: 0;\n}\n.nextItem {\n  width: 100%;\n  height: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
