import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import Axios from "../../../Axios";
import { baseUrlPMBackend } from "../../../Configurations/consts";
import { ElementsContext } from "../../../context/ElementsContext/ElementsContext";
import customToast from "../../Shared/Toast/CustomToast";
import IElementType from "../../../Interfaces/IElementType";
import { AppModeContext } from "../../../context/AppModeContext/AppModeContext";
/**
 *
 * @returns array with of elements
 */
const asyncFetchElements = async () => {
  const res = await Axios.get(`${baseUrlPMBackend}elements/getElements`);
  return res.data;
};

export const useElements = () => {
  const { appMode } = useContext(AppModeContext);
  const { t } = useTranslation();
  const { setElements } = useContext(ElementsContext);

  let query = useQuery<IElementType[]>({
    queryKey: ["elements", appMode],
    queryFn: asyncFetchElements,
    enabled: appMode !== undefined,
    staleTime: Infinity,
  });
  const { data, isLoading, isError, refetch } = query;

  useEffect(() => {
    //when the app mode change fetch the app elements
    refetch();
  }, [appMode]);

  useEffect(() => {
    if (!isLoading && !isError && data && data?.length) {
      //det the context with the elements
      setElements(
        data.map((element) => ({
          ...element,
          name: element.name,
          id: Number(element.id),
          trainingTypeId: Number(element.trainingTypeId),
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (isError) {
      customToast.error(t("errorWhileLoadingElements"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);
};
