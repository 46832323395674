import React, { useEffect, useState } from "react";
import "./UserIcon.css";
import PMLabel from "../../themeComponents/PMLabel";
interface IProps {
  userName: string;
}
const UserIcon: React.FC<IProps> = (props: IProps) => {
  const [initials, setInitials] = useState<string>("");
  useEffect(() => {
    let words = props.userName.trim().split(" ");

    // Extract the first character of each word
    let initials = words.map((word) => word.charAt(0).toUpperCase());

    // Join the initials into a single string
    setInitials(initials.join(""));
  }, [props.userName]);
  return (
    <div className="userIcon">
      <PMLabel fontSize="large">{initials}</PMLabel>
    </div>
  );
};

export default UserIcon;
