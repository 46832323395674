import React, { ReactNode, useState } from "react";
import { IGorillaTrainingsForces } from "../../Interfaces/Gorilla/GorillaTrainings";
import { ELuncherTypes } from "../../Enums/EHasifaTkifa";
import IPlan from "../../Interfaces/IPlan";
interface IGorillaTrainingContext {
  gorillaTrainingsForces: IGorillaTrainingsForces;
  setGorillaTrainingsForces: React.Dispatch<
    React.SetStateAction<IGorillaTrainingsForces>
  >;
  plan: IPlan | undefined;
  setPlan: React.Dispatch<React.SetStateAction<IPlan | undefined>>;
  initHeatsAmount: number;
  setHeatsAmount: React.Dispatch<React.SetStateAction<number>>;
  minimumHeatsAmount: number;
  resetForcesObject: () => void;
  selectedLuncherType: ELuncherTypes | undefined;
  setSelectedLuncherType: React.Dispatch<
    React.SetStateAction<ELuncherTypes | undefined>
  >;
}
const minimumHeatsAmount = 1;
const defaultHeatAmount = 6;
export const GorillaTrainingContext =
  React.createContext<IGorillaTrainingContext>({
    gorillaTrainingsForces: {
      Single_Gunner: undefined,
      Observer: undefined,
      NCO: undefined,
    },
    plan: undefined,
    setGorillaTrainingsForces: () => {},
    setHeatsAmount: () => {},
    setPlan: () => {},
    initHeatsAmount: minimumHeatsAmount,
    minimumHeatsAmount: minimumHeatsAmount,
    resetForcesObject: () => {},
    selectedLuncherType: undefined,
    setSelectedLuncherType: () => {},
  });

const GorillaTrainingProvider: React.FC<{ children: ReactNode }> = ({
  children,
}: {
  children: ReactNode;
}) => {
  const initGorillaTrainingsForces = {
    Single_Gunner: undefined,
    Observer: undefined,
    NCO: undefined,
  };
  const [gorillaTrainingsForces, setGorillaTrainingsForces] =
    useState<IGorillaTrainingsForces>(initGorillaTrainingsForces);
  const [plan, setPlan] = useState<IPlan>();
  const [initHeatsAmount, setHeatsAmount] = useState<number>(defaultHeatAmount);
  const [selectedLuncherType, setSelectedLuncherType] =
    useState<ELuncherTypes>();
  const resetForcesObject = () => {
    setGorillaTrainingsForces(initGorillaTrainingsForces);
  };
  return (
    <GorillaTrainingContext.Provider
      value={{
        gorillaTrainingsForces,
        setGorillaTrainingsForces,
        plan,
        setPlan,
        initHeatsAmount,
        setHeatsAmount,
        minimumHeatsAmount,
        resetForcesObject,
        selectedLuncherType,
        setSelectedLuncherType,
      }}
    >
      {children}
    </GorillaTrainingContext.Provider>
  );
};

export default GorillaTrainingProvider;
