import { IonCol } from "@ionic/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PMIcon from "../../../components/themeComponents/PMIcon";
import PMLabel from "../../../components/themeComponents/PMLabel";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import { ITabOptions } from "../../../Interfaces/IDataHistory";

type Props = {
  selectedSegment: ITabOptions | undefined;
};

const SegmentTitle = (props: Props) => {
  const { t } = useTranslation();

  const [iconColor, setIconColor] = useState<"xLight" | "orange">("xLight");

  return (
    <>
      <IonCol size="10.4">
        <PMLabel fontColor="xLight" fontSize="large" fontFamily="Regular">
          {props.selectedSegment && t(props.selectedSegment.title)}
        </PMLabel>
      </IonCol>
      <IonCol size="1.5" id="data-history-drawer">
        {props.selectedSegment && (
          <PMIcon
            isButton={true}
            iconSrc={EIconsSrc.DATE_RANGE}
            size="xLarge"
            color={iconColor}
            onMouseEnter={() => setIconColor("orange")}
            onMouseLeave={() => setIconColor("xLight")}
          />
        )}
      </IonCol>
    </>
  );
};

export default SegmentTitle;
