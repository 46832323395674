import { FC } from "react";
import { IonCol, IonRow } from "@ionic/react";
import "./TroubleshootingElement.css";
import PMLabel from "../../themeComponents/PMLabel";
import Checkbox from "../Checkbox/Checkbox";
import { useTranslation } from "react-i18next";

interface IProps {
  fault: { name: string; isSelected: boolean; isExecuted: boolean };
  onCheckedChanged: (isChecked: boolean, name: string) => void;
}

const TroubleshootingElement: FC<IProps> = (props: IProps) => {
  const { fault, onCheckedChanged } = props;
  const { t } = useTranslation();
  return (
    <IonRow
      className="faultRow"
      onClick={() => {
        if (!props.fault.isExecuted)
          onCheckedChanged(!fault.isSelected, fault.name);
      }}
    >
      <IonCol size="1.4" className="faultCheckboxCol">
        <Checkbox
          isLocked={fault.isExecuted}
          isChecked={fault.isSelected}
          onChange={(event) => {
            onCheckedChanged(event.detail.checked, fault.name);
          }}
          color="orange"
        ></Checkbox>
      </IonCol>
      <IonCol>
        <PMLabel fontFamily="Light" fontSize="medium" fontColor="light">
          {t(fault.name)}
        </PMLabel>
      </IonCol>
    </IonRow>
  );
};

export default TroubleshootingElement;
