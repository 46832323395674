import {
  IonRow,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  IonCol,
} from "@ionic/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./SearchComponents.css";
import ISimpleTrainee from "../../../../Interfaces/ISimpleTrainee";
import Spinner from "../../Spinner/Spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import EIconsSrc from "../../../../Interfaces/EIconsSrc";
import PMIcon from "../../../themeComponents/PMIcon";
import useGetFilterForce from "./useGetFilterForces";
import PMInput from "../../../Desktop/TrainingPlan/PMInput";

interface IProps {
  setSoldierDetails: React.Dispatch<
    React.SetStateAction<ISimpleTrainee | undefined>
  >;
  soldierDetails: ISimpleTrainee | undefined;
  okHandler?: (soldier: ISimpleTrainee | undefined) => void;
  isDesktop?: boolean;
}

const SearchComponents: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { setSoldierDetails, isDesktop, soldierDetails, okHandler } = props;

  const { t } = useTranslation();
  const onClickHandler = (soldier: ISimpleTrainee) => {
    setSoldierDetails(
      props.soldierDetails?.uniqId === soldier.uniqId ? undefined : soldier
    );

    if (okHandler)
      okHandler(
        props.soldierDetails?.uniqId === soldier.uniqId ? undefined : soldier
      );
  };
  const {
    loading,
    setSearchText,
    soldiersNamesAndIds,
    searchText,
    displayedArray,
    hasMoreTrainees,
    loadMoreData,
  } = useGetFilterForce();
  useEffect(() => {
    setSoldierDetails(undefined);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soldiersNamesAndIds]);
  return (
    <div className="searchCompo">
      {loading ? (
        <Spinner className="spinner-trainees-list" />
      ) : (
        <>
          <div
            className={
              isDesktop ? "searchCompWrapDesktop" : "searchCompWrapMobile"
            }
          >
            <IonRow className="searchCompRow">
              <PMInput
                inputName="searchForce"
                inputType="text"
                placeholder={t("searchForce")}
                inputValue={searchText}
                onChangeHandler={(_name, value) => {
                  setSearchText(value && value !== null ? value : "");
                }}
              ></PMInput>
            </IonRow>

            <IonRow className="soldiersListRow">
              {displayedArray.length ? (
                <InfiniteScroll
                  dataLength={displayedArray.length}
                  next={loadMoreData}
                  hasMore={hasMoreTrainees}
                  loader={<Spinner className="spinner-trainees-list" />}
                  className={isDesktop ? "soldiersList" : "mobileSoldiersList"}
                >
                  {displayedArray.map((trainee: any, index: number) => (
                    <IonItem
                      key={trainee.uniqId}
                      mode="md"
                      button
                      className={
                        "soldierFound itemMS " +
                        (isDesktop
                          ? "backgroundSearch "
                          : "soldierFoundMobile ") +
                        (Number(soldierDetails?.uniqId) ===
                        Number(trainee.uniqId)
                          ? " selectedDesktopSearch"
                          : "")
                      }
                      lines={"full"}
                      onClick={() => {
                        onClickHandler(trainee);
                      }}
                    >
                      <IonText className="traineeNameSC">{`${trainee.name}  `}</IonText>
                    </IonItem>
                  ))}
                </InfiniteScroll>
              ) : searchText.length ? (
                <IonItem
                  mode="md"
                  className={
                    "notFoundSoldier itemMS" +
                    (isDesktop ? "" : "soldierFoundMobile")
                  }
                  lines={"none"}
                >
                  <IonText className={"notFoundSoldier"}>
                    {t("soldierNotFound")}
                  </IonText>
                </IonItem>
              ) : null}
            </IonRow>
          </div>
        </>
      )}
    </div>
  );
};

export default SearchComponents;
