const { create } = require("xmlbuilder2");
const createXml = (object: Object) => {
  const doc = create(object);
  const xml = doc.end({ prettyPrint: true });
  return xml;
};
const getCurrentDate = () => {
  const currentDate = new Date();

  // Get day, month, and year components
  const day = String(currentDate.getDate()).padStart(2, "0");
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = currentDate.getFullYear();

  // Create the date string in "dd.mm.yyyy" format
  const dateString = `${day}.${month}.${year}`;
  return dateString;
};
export { createXml, getCurrentDate };
