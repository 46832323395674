import { IonPage } from "@ionic/react";
import ModalExcel from "../../../components/Desktop/ToExcel/exportModal";

const ReportGenerator: React.FC = (): JSX.Element => {
  return (
    <IonPage>
      <ModalExcel />
    </IonPage>
  );
};
export default ReportGenerator;
