import { useEffect, useState } from "react";
import IColor from "../../Interfaces/IColor";
import IForceFromOrbat from "../../Interfaces/IForceFromOrbat";
import IForceTreeNode from "../../Interfaces/IForceTreeNode";
import { useGetRelevantPlans } from "./HooksWithRQ/useGetRelevantPlans";

const useForcesChecked = (
  orbatForces: IForceTreeNode[],
  isMobile?: boolean
) => {
  const [newForces, setNewForces] = useState<IForceFromOrbat[]>(
    [] as IForceFromOrbat[]
  );
  const [forcesToRemove, setForcesToRemove] = useState<IForceFromOrbat[]>(
    [] as IForceFromOrbat[]
  );
  const [checkedForces, setCheckedForces] = useState<IForceFromOrbat[]>([]);
  const [forceTaggingRemoved, setForceTaggingRemoved] = useState<
    number | undefined
  >();

  const [colors, setColors] = useState<IColor[]>([]);
  const { relevantPlansIds } = useGetRelevantPlans(
    orbatForces.map((force) => force.id)
  );
  useEffect(() => {
    setColors(initializeColors());
  }, []);

  const initializeColors = (): IColor[] => {
    return [
      { color: "#2334B5", isChecked: false, id: undefined },
      { color: "#36D3D2", isChecked: false, id: undefined },
      { color: "#5F7E0C", isChecked: false, id: undefined },
      { color: "#BC5151", isChecked: false, id: undefined },
      { color: "#C18035", isChecked: false, id: undefined },
    ];
  };

  useEffect(() => {
    if (!forcesToRemove.length) return;
    setColors((prev) => {
      return prev.map((color) => {
        if (
          forcesToRemove.find((force) => Number(force.id) === Number(color.id))
        )
          return { ...color, id: undefined, isChecked: false };
        return color;
      });
    });
    setCheckedForces((prev) => {
      return prev.filter(
        (force) =>
          !forcesToRemove.find(
            (fForce) => Number(fForce.id) === Number(force.id)
          )
      );
    });
  }, [forcesToRemove]);

  //set checked forces
  useEffect(() => {
    if (!newForces?.length) return;
    setCheckedForces((prev) => prev.concat(newForces));
  }, [newForces]);

  useEffect(() => {
    const getColors = (forceIds: number[]): IColor[] => {
      let colorsArray = colors.length ? colors : initializeColors();
      if (
        isMobile &&
        orbatForces.filter(
          (force) => colorsArray.map((color) => color.id).indexOf(force.id) < 0
        ).length >
          colorsArray.filter((color) => color.isChecked === false).length
      ) {
        return initializeColors();
      } else {
        let colorToUse = colorsArray.filter((color) => !color.isChecked);
        if (!colorToUse.length) {
          let colorRemoved = colorsArray.find(
            (color) => Number(color.id) === Number(forceTaggingRemoved)
          );
          if (colorRemoved) colorToUse = [colorRemoved];
        }
        setColors((prev) => {
          return prev.map((color) => {
            if (colorToUse.find((colorT) => colorT.color === color.color)) {
              let forceId = forceIds.shift();
              if (forceId) return { ...color, isChecked: true, id: forceId };
              return color;
            }
            return color;
          });
        });
        return [...colorToUse];
      }
    };

    //set new forces
    setNewForces(() => {
      let newForces: IForceFromOrbat[] = [];
      let forceToColor = orbatForces.filter(
        (force) =>
          force.id !== undefined &&
          !checkedForces.find(
            (cForce) => Number(cForce.id) === Number(force.id)
          )
      );
      let colorsToUse: IColor[] = getColors(
        forceToColor.map((force) => force.id)
      );
      forceToColor.forEach((force) => {
        if (
          !checkedForces.find(
            (cForce) => Number(cForce.id) === Number(force.id)
          )
        ) {
          let color = colorsToUse.shift();
          newForces.push({
            id: force.id,
            isSoldier: force.nodes === null,
            name: force.name,
            color: color?.color,
            forceType: force.force_type,
          });
        }
      });
      return newForces;
    });
    //set forces to remove
    setForcesToRemove(() => {
      let newForces: IForceFromOrbat[] = [];
      checkedForces.forEach((force) => {
        if (
          !orbatForces.find((cForce) => Number(cForce.id) === Number(force.id))
        )
          newForces.push({
            ...force,
          });
      });
      return newForces;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orbatForces]);

  return {
    newForces,
    forcesToRemove,
    checkedForces,
    setForceTaggingRemoved,
    setCheckedForces,
    setNewForces,
    relevantPlansIds,
  };
};
export default useForcesChecked;
