export default interface ISimpleTrainee {
  name: string;
  id?: number;
  uniqId: number;
  team?: ETeam;
}

export enum ETeam {
  teamGreen = "green",
  teamBlue = "blue",
}
