// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.siteStatusTrainees {
  min-height: 50px;
}
.stationAccordionItem {
  margin-top: 8px;
  border-radius: 4px;
  --background: var(--ion-color-dark) !important;
}
.stationAccordion {
  --background-activated: red;
  padding-inline-end: 16px;
}
.stationAccordion .ion-accordion-toggle-icon {
  font-size: 15px !important;
  color: var(--ion-color-text-secondary) !important ;
}
.stationAccordion .searchbar-input.sc-ion-searchbar-md {
  --background: var(--ion-color-enable);
  height: 48px;
}
.stationAccordion .searchbar-search-icon {
  margin-top: 3px;
}
.stationAccordion .pmSearchBar {
  padding: 0;
}
.siteStatusSearchRow {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--ion-color-dark) !important;
  padding: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Mobile/SiteStatus/StationAccordion/StationAccordion.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,8CAA8C;AAChD;AACA;EACE,2BAA2B;EAC3B,wBAAwB;AAC1B;AACA;EACE,0BAA0B;EAC1B,kDAAkD;AACpD;AACA;EACE,qCAAqC;EACrC,YAAY;AACd;AACA;EACE,eAAe;AACjB;AACA;EACE,UAAU;AACZ;AACA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kDAAkD;EAClD,aAAa;AACf","sourcesContent":[".siteStatusTrainees {\n  min-height: 50px;\n}\n.stationAccordionItem {\n  margin-top: 8px;\n  border-radius: 4px;\n  --background: var(--ion-color-dark) !important;\n}\n.stationAccordion {\n  --background-activated: red;\n  padding-inline-end: 16px;\n}\n.stationAccordion .ion-accordion-toggle-icon {\n  font-size: 15px !important;\n  color: var(--ion-color-text-secondary) !important ;\n}\n.stationAccordion .searchbar-input.sc-ion-searchbar-md {\n  --background: var(--ion-color-enable);\n  height: 48px;\n}\n.stationAccordion .searchbar-search-icon {\n  margin-top: 3px;\n}\n.stationAccordion .pmSearchBar {\n  padding: 0;\n}\n.siteStatusSearchRow {\n  width: 100%;\n  height: 60px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: var(--ion-color-dark) !important;\n  padding: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
