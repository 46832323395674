import { TFunction } from "i18next";
import { useState, useEffect } from "react";
import customToast from "../Shared/Toast/CustomToast";
import {
  InstructorFeedbackGrades,
  InstructorFeedbackElementsData,
} from "../../Interfaces/IInstructorFeedback";
import EEventType from "../../Interfaces/EEventType";
import { sendDrillToBBackend } from "../Desktop/DataPosting/dataPostingHelpers";
const InstructorFeedbackComponentHook = (
  t: TFunction,
  traineeId: number | undefined,
  elementsData: InstructorFeedbackElementsData[] | undefined,
  trainingTypeId: number,
  selectedType: string | undefined,
  planId?: number | string | undefined,
  isDesktop?: boolean,
  afterPostHandler?: () => void
) => {
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [isTraineeChanged, setIsTraineeChanged] = useState<boolean>(false);
  const [IFGrades, setIFGrades] = useState<InstructorFeedbackGrades[]>(
    [] as InstructorFeedbackGrades[]
  );

  const initUFIGrades = () => {
    let newArray: InstructorFeedbackGrades[] = [];
    elementsData?.forEach((dataTypeData) => {
      newArray.push({
        dataType: dataTypeData.dataType,
        isComplete: false,
        value: undefined,
        isMust: dataTypeData.isMust,
      });
    });
    setIFGrades(newArray);
  };
  /**
   * check if the user insert the all required input
   */
  const isCompleteInput = () => {
    let isComplete = true;
    IFGrades.forEach((grade) => {
      if (!grade.isComplete && grade.isMust) isComplete = false;
    });
    setIsComplete(isComplete);
  };

  useEffect(() => {
    if (IFGrades.length) {
      isCompleteInput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IFGrades]);

  useEffect(() => {
    elementsData?.length && initUFIGrades();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementsData]);
  /**
   * post the data to b-backend
   */
  const postData = async () => {
    if (
      (traineeId !== undefined && isNaN(traineeId)) ||
      traineeId === undefined
    ) {
      if (isDesktop) customToast.error(t("noSelectedSoldier"));
      return;
    }
    const timeStamp: Date = new Date();

    //create object with the data types results
    const dataTypesObject = IFGrades.reduce((acc: any, item) => {
      const { dataType, isComplete, value, isMust } = item;
      if (
        isComplete ||
        isMust ||
        (selectedType && //send all the data in the selected type
          dataType.toLowerCase().includes(selectedType?.toLowerCase()))
      )
        acc[dataType] = value;
      return acc;
    }, {});
    // add the trainee and time , plan id details
    const UFIDrill = {
      ...dataTypesObject,
      MessageType: EEventType.URBAN_INSTRUCTOR_FEEDBACK,
      TimeStamp: timeStamp,
      TrainingTypeID: trainingTypeId,
      DrillStartTime: timeStamp,
      SoldierID: traineeId,
      PlanId: planId,
    };
    const displayToast = true;
    sendDrillToBBackend(UFIDrill, afterPostHandler, undefined, t, displayToast);
  };

  useEffect(() => {
    initUFIGrades();
    setIsTraineeChanged(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [traineeId]);

  return {
    isComplete,
    isTraineeChanged,
    setUIFGrades: setIFGrades,
    postData,
    setIsTraineeChanged,
  };
};
export default InstructorFeedbackComponentHook;
