import { Dispatch, FC, SetStateAction } from "react";
import "./NotesAndEndTraining.css";
import PMLabel from "../../themeComponents/PMLabel";
import { useTranslation } from "react-i18next";
import AddButton from "./AddButton";
import NotesModal from "./NotesModal";
import Divider from "./Divider";
import EndTraining from "./EndTraining";
import { INote } from "../../../Interfaces/Gorilla/GorillaTrainings";
interface IProps {
  mainNotes: INote[];
  addNote: (note: string) => void;
  deleteNote: (index: number) => void;
  editNote: (index: number, note: string) => void;
  endTrainingHandler: () => void;
  isNotesModalOpen: boolean;
  setIsNotesModalOpen: Dispatch<SetStateAction<boolean>>;
  isEndTrainingDisable?: boolean;
  trainingName: string;
  isTrainingEnd: boolean;
}
const NotesAndEndTraining: FC<IProps> = (props: IProps) => {
  const {
    mainNotes,
    addNote,
    editNote,
    deleteNote,
    endTrainingHandler,
    isNotesModalOpen,
    setIsNotesModalOpen,
    isEndTrainingDisable,
    isTrainingEnd,
  } = props;

  const { t } = useTranslation();

  return (
    <div className="commentsAndEndTraining">
      <NotesModal
        isOpen={isNotesModalOpen}
        deleteNote={deleteNote}
        editNote={editNote}
        addNote={addNote}
        notes={mainNotes}
        closeModal={() => {
          setIsNotesModalOpen(false);
        }}
      ></NotesModal>
      <div className="mainNotes ">
        <PMLabel fontFamily="Regular" fontSize="medium" fontColor="light">
          {t("mainNotes")}
        </PMLabel>

        <AddButton
          buttonText={t("addEditNotes")}
          onClickHandler={() => {
            setIsNotesModalOpen(true);
          }}
          isDisabled={isTrainingEnd}
        ></AddButton>
      </div>
      <Divider></Divider>

      <EndTraining
        endTrainingHandler={endTrainingHandler}
        isEndTrainingDisable={isEndTrainingDisable || isTrainingEnd}
      ></EndTraining>
    </div>
  );
};

export default NotesAndEndTraining;
