import IForceType from "../Interfaces/IForceType";
import Axios from "../Axios";
import { baseUrlPMBackend } from "../Configurations/consts";
import { TFunction } from "i18next";
import IForceTreeNode from "../Interfaces/IForceTreeNode";
import ILabel from "../Interfaces/ILabel";
import IShootingRangeForce from "../Interfaces/IShootingRangeForce";
import IShootingDay from "../Interfaces/IShootingDay";
import { IMRKRow } from "../components/Desktop/DataPosting/Mrk";
import { Dispatch, SetStateAction } from "react";
import IForcePointer from "../Interfaces/IForcePointer";

export const convertDateToString = (date: Date) => {
  if (!date) return "";
  let newDate: Date = new Date(date);

  return (
    newDate.getDate() +
    "/" +
    (newDate.getMonth() + 1) +
    "/" +
    newDate.getFullYear()
  );
};

export const getForceFullName: (
  name: string,
  type: string,
  t: TFunction
) => string = (name: string, type: string, t: TFunction): string => {
  let typeToConcat =
    !/[0-9]/.test(name) && t(type) === "חטיבה" ? "חטיבת" : t(type);
  return ![t("מתאמן"), t("קבוצה"), "מתאמן", "קבוצה"].includes(type)
    ? t(typeToConcat) + " " + name?.trim()
    : name;
};

export function instanceOfIForceType(object: any): object is IForceType {
  return "is_soldier" in object;
}
export const loadAllSoldiersDataFromDB = async (
  forceId: number | undefined
) => {
  return await Axios.get(`${baseUrlPMBackend}forces/getSoldiersIdsAndNames`, {
    params: { forceId: forceId },
  });
};
export const sortByLabelId = (array: any, labels: ILabel[] | undefined) => {
  return labels
    ? array?.sort(
        (firstItem: any, secondItem: any) =>
          labels?.findIndex(
            (label) => Number(label.id) === Number(firstItem.id)
          ) -
          labels?.findIndex(
            (label) => Number(label.id) === Number(secondItem.id)
          )
      )
    : [];
};

export const getForceById = (
  tree: IForceTreeNode,
  id: number
): IForceTreeNode | undefined => {
  if (tree === undefined) return tree;
  if (+tree.id === +id) {
    let typeToRep = tree.name.includes("חטיבת") ? "חטיבת" : tree.force_type;
    return { ...tree, name: tree.name.replace(typeToRep, "") };
  } else {
    let result;
    if (tree.nodes !== null) {
      for (
        let index = 0;
        result === undefined && index < tree.nodes?.length;
        index++
      ) {
        const element: IForceTreeNode = tree.nodes[index];
        result = getForceById(element, id);
      }
      return result;
    } else return result;
  }
};

export const compareNumbers = (
  currentItem: IShootingDay,
  nextItem: IShootingDay
) => +currentItem.name.split("-")[0] - +nextItem.name.split("-")[0];

export const flattenObject = <T>(object: T): boolean[] => {
  let result: boolean[] = [];
  for (const item in object) {
    if (typeof object[item] === "object") {
      const temp = flattenObject(object[item]);
      for (const notObjectitem in temp) {
        result.push(temp[notObjectitem]);
      }
    } else {
      result.push(!!object[item]);
    }
  }
  return result;
};

export const compareToInitialArrayOfObjects = <T>(
  initial: T[],
  current: T[]
): boolean => {
  if (initial.length === current.length) {
    return compareToInitialObject(initial, current);
  } else {
    return true;
  }
};

export const compareToInitialObject = <T>(initial: T, current: T): boolean => {
  return JSON.stringify(initial) !== JSON.stringify(current);
};

export const getChildrenSoldiers = (
  checkedForce: IForceTreeNode,
  soldiers: IShootingRangeForce[]
): IShootingRangeForce[] => {
  if (checkedForce.is_soldier) {
    soldiers.push({
      id: checkedForce.soldier_id!,
      name: checkedForce.name,
      forceType: checkedForce.force_type,
      result: "",
      weaponType: checkedForce.weapon_type,
      isValidResult: true,
    });
  }
  checkedForce.nodes?.forEach((child: IForceTreeNode) => {
    if (child.is_soldier) {
      soldiers.push({
        id: child.soldier_id!,
        name: child.name,
        forceType: child.force_type,
        result: "",
        weaponType: child.weapon_type,
        isValidResult: true,
      });
    } else {
      soldiers = getChildrenSoldiers(child, soldiers);
    }
  });
  return soldiers;
};

export const findChangedElement = <T>(arr1: T[], arr2: T[]) => {
  for (let i = 0; i < arr1.length; i++) {
    let differences = [];
    for (let key in arr1[i]) {
      if (arr1[i][key] !== arr2[i][key]) {
        differences.push({ key: key, value: arr2[i][key], action: "update" });
      }
    }
    for (let key in arr2[i]) {
      if (arr1[i][key] === undefined) {
        differences.push({ key: key, value: arr2[i][key], action: "add" });
      }
    }
    if (differences.length > 0) {
      return { element: arr2[i], index: i, differences: differences };
    }
  }
  return null;
};

export const sortByEmptyValues = (array: IMRKRow[]): IMRKRow[] => {
  return array.sort((currentItem: IMRKRow, nextItem: IMRKRow) => {
    if (currentItem.soldierId === "") {
      return 1;
    } else if (nextItem.soldierId === "") {
      return -1;
    } else {
      return currentItem.soldierId - nextItem.soldierId;
    }
  });
};

// Converts the hierarchical tree to a flat array
export const treeFlatting = (
  tree: IForceTreeNode,
  setFlatTree: Dispatch<SetStateAction<IForcePointer[]>>
): void => {
  setFlatTree((prev: IForcePointer[]) => [
    ...prev,
    {
      id: tree.id,
      name: tree.name,
      parent: tree.parent_id,
      soldier_id: tree.soldier_id,
      tag_id: tree.tag_id,
      weapon_id: tree.weapon_id,
      weapon_type: tree.weapon_type,
      weapon_sight: tree.weapon_sight,
      weapon_sight_id: tree.weapon_sight_id,
      force_type: tree.force_type,
      magazine_id: tree.magazine_id,
      laser_id: tree.laser_id,
      head_sensor_id: tree.head_sensor_id,
      personal_id: tree.personal_id,
    },
  ]);

  tree.nodes &&
    tree.nodes.forEach((node: IForceTreeNode) => {
      treeFlatting(node, setFlatTree);
    });
};
export const translateString = (string: string, t: TFunction) =>
  string
    .split(" ")
    .map((word) => t(word))
    .join(" ");
