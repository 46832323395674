import { useContext, useState, useEffect } from "react";
import {
  IOpenAndCover,
  ISettingStation,
  IConductingQuality,
  ITargetAcquisition,
  IWorkRoutine,
  EObserverSteps,
  IObserverStep,
} from "../../Interfaces/Gorilla/IObserver";
import { mobileRoutes } from "../../services/routeRoles";
import useNavigation from "./useNavigation";
import { GorillaTrainingContext } from "../../context/GorillaTrainingContext/GorillaTrainingContext";
import EEventType from "../../Interfaces/EEventType";
import { sendDrillToBBackend } from "../Desktop/DataPosting/dataPostingHelpers";
import {
  EGorillaForces,
  EGorillaTrainings,
  INote,
} from "../../Interfaces/Gorilla/GorillaTrainings";
import GorillaConsts from "./GorillaConsts";
import { dataPostingRoutes } from "./useDataPostingTabs";
import customToast from "../Shared/Toast/CustomToast";
import { useTranslation } from "react-i18next";

const ObserverHook = (isDesktop?: boolean) => {
  const { t } = useTranslation();
  const { replace } = useNavigation();

  const [observerSteps, setObserverSteps] = useState<
    Record<EObserverSteps, IObserverStep>
  >({
    [EObserverSteps.SettingStation]: {
      id: EObserverSteps.SettingStation,
      title: "SettingStation",
      isPartlyCompleted: false,
      isCompleted: false,
    },
    [EObserverSteps.TargetAcquisition]: {
      id: EObserverSteps.TargetAcquisition,
      title: "targetAcquisition",
      isPartlyCompleted: false,
      isCompleted: false,
    },
    [EObserverSteps.ConductingQuality]: {
      id: EObserverSteps.ConductingQuality,
      title: "ConductingQuality",
      isPartlyCompleted: false,
      isCompleted: false,
    },
    [EObserverSteps.WorkRoutine]: {
      id: EObserverSteps.WorkRoutine,
      title: "workRoutine",
      isPartlyCompleted: false,
      isCompleted: false,
    },
    [EObserverSteps.OpenAndCover]: {
      id: EObserverSteps.OpenAndCover,
      title: "openAndCover",
      isPartlyCompleted: false,
      isCompleted: false,
    },
    [EObserverSteps.MainNotes]: {
      id: EObserverSteps.MainNotes,
      title: "mainNotes",
      isPartlyCompleted: false,
      isCompleted: false,
    },
  });

  const [observerCurrentStepType, setObserverCurrentStepType] =
    useState<EObserverSteps>(EObserverSteps.SettingStation);
  const { gorillaTrainingsForces, plan } = useContext(GorillaTrainingContext);
  const [isTrainingEnd, setIsTrainingEnd] = useState<boolean>(false);
  const { initSettingStation } = GorillaConsts(plan?.id, plan?.allPlans);
  const [settingStation, setSettingStation] =
    useState<ISettingStation>(initSettingStation);

  const [targetAcquisition, setTargetAcquisition] =
    useState<ITargetAcquisition>({
      LasingAccuracy: undefined,
      AccurateIdentification: undefined,
      LasingOnSuspectedObject: undefined,
      LoadingSuspectIdentificationC2: undefined,
      MultiRangesFilterRanges: undefined,
      TargetAcquisitionFastness: undefined,
    } as ITargetAcquisition);
  const [conductingQuality, setConductingQuality] =
    useState<IConductingQuality>({
      ConductingQuality: undefined,
    } as IConductingQuality);
  const [workRoutine, setWorkRoutine] = useState<IWorkRoutine>({
    MeasureRangeForCoordinates: undefined,
    SearchingMethod: undefined,
    SettledGoalsReturns: undefined,
    RulingForcesLocations: undefined,
  } as IWorkRoutine);
  const [openAndCover, setOpenAndCover] = useState<IOpenAndCover>({
    EscortingMovingForce: undefined,
    AdjustingForceToFight: undefined,
  } as IOpenAndCover);
  const [mainNotes, setMainNotes] = useState<INote[]>([]);
  const [isEndTrainingModalOpen, setIsEndTrainingModalOpen] =
    useState<boolean>(false);
  const endTrainingHandlerMO = () => {
    setIsEndTrainingModalOpen(true);
  };
  const closeConfirmationModal = () => {
    setIsEndTrainingModalOpen(false);
  };
  const endTrainingHandlerMOAfterConfirmation = () => {
    try {
      closeConfirmationModal();
      let isError = false;
      const observerForceId = gorillaTrainingsForces.Observer?.id;
      const settingStationTrainingTypeId = 24;
      const conductingQualityTrainingTypeId = 25;
      const openAndCoverTrainingTypeId = 26;
      const targetAcquisitionTrainingTypeId = 27;
      const workRoutineTrainingTypeId = 28;
      if (
        observerSteps[EObserverSteps.SettingStation].isPartlyCompleted ||
        mainNotes.length
      ) {
        let SettingStationDrill = {
          SoldierID: observerForceId,
          MessageType: EEventType.SPIKE_MESSAGE_TYPE,
          TimeStamp: new Date(),
          TrainingTypeID: settingStationTrainingTypeId,
          HIZDATRUTatFieldTime: settingStation?.HIZDATRUTatField.time,
          HIZDATRUTatFieldAccuracy: settingStation?.HIZDATRUTatField.accuracy,
          SettingStationTime: settingStation?.SettingStation.time,
          SettingStationAccuracy: settingStation?.SettingStation.accuracy,
          StationSelection: settingStation?.StationSelection,
          Notes: JSON.stringify(mainNotes.map((note) => note.note)),
          DrillStartTime: new Date(),
          PlanId: plan?.id,
        };
        sendDrillToBBackend(
          SettingStationDrill,
          () => {},
          () => {
            isError = true;
          }
        );
      }
      if (observerSteps[EObserverSteps.ConductingQuality].isPartlyCompleted) {
        let ConductingQualityDrill = {
          SoldierID: observerForceId,
          MessageType: EEventType.SPIKE_MESSAGE_TYPE,
          TimeStamp: new Date(),
          TrainingTypeID: conductingQualityTrainingTypeId,
          ConductingQuality: conductingQuality?.ConductingQuality,
          DrillStartTime: new Date(),
          PlanId: plan?.id,
        };
        sendDrillToBBackend(
          ConductingQualityDrill,
          () => {},
          () => {
            isError = true;
          }
        );
      }
      if (observerSteps[EObserverSteps.OpenAndCover].isPartlyCompleted) {
        let openAndCoverDrill = {
          SoldierID: observerForceId,
          MessageType: EEventType.SPIKE_MESSAGE_TYPE,
          TimeStamp: new Date(),
          TrainingTypeID: openAndCoverTrainingTypeId,
          AdjustingForceToFight: openAndCover?.AdjustingForceToFight,
          EscortingMovingForce: openAndCover?.EscortingMovingForce,
          DrillStartTime: new Date(),
          PlanId: plan?.id,
        };
        sendDrillToBBackend(
          openAndCoverDrill,
          () => {},
          () => {
            isError = true;
          }
        );
      }
      if (observerSteps[EObserverSteps.TargetAcquisition].isPartlyCompleted) {
        let targetAcquisitionDrill = {
          SoldierID: observerForceId,
          MessageType: EEventType.SPIKE_MESSAGE_TYPE,
          TimeStamp: new Date(),
          TrainingTypeID: targetAcquisitionTrainingTypeId,
          AdjustingForceToFight: targetAcquisition?.AccurateIdentification,
          LasingAccuracy: targetAcquisition?.LasingAccuracy,
          LasingOnSuspectedObject: targetAcquisition?.LasingOnSuspectedObject,
          LoadingSuspectIdentificationC2:
            targetAcquisition?.LoadingSuspectIdentificationC2,
          MultiRangesFilterRanges: targetAcquisition?.MultiRangesFilterRanges,
          TargetAcquisitionFastness:
            targetAcquisition?.TargetAcquisitionFastness,
          DrillStartTime: new Date(),
          PlanId: plan?.id,
        };
        sendDrillToBBackend(
          targetAcquisitionDrill,
          () => {},
          () => {
            isError = true;
          }
        );
      }
      if (observerSteps[EObserverSteps.WorkRoutine].isPartlyCompleted) {
        let workRoutineDrill = {
          SoldierID: observerForceId,
          MessageType: EEventType.SPIKE_MESSAGE_TYPE,
          TimeStamp: new Date(),
          TrainingTypeID: workRoutineTrainingTypeId,
          MeasureRangeForCoordinates: workRoutine?.MeasureRangeForCoordinates,
          RulingForcesLocations: workRoutine?.RulingForcesLocations,
          SearchingMethod: workRoutine?.SearchingMethod,
          SettledGoalsReturns: workRoutine?.SettledGoalsReturns,
          DrillStartTime: new Date(),
          PlanId: plan?.id,
        };
        sendDrillToBBackend(
          workRoutineDrill,
          () => {},
          () => {
            isError = true;
          }
        );
      }

      if (!isError) {
        setIsTrainingEnd(true);
      } else {
        throw "error";
      }
    } catch {
      customToast.error(
        `${t("dataSendError")} ${t("for")} ${EGorillaForces.Observer}`
      );
    }
  };
  useEffect(() => {
    if (
      isTrainingEnd &&
      !(
        gorillaTrainingsForces[EGorillaForces.SingleGunner] ||
        gorillaTrainingsForces[EGorillaForces.NCO]
      )
    )
      replace(
        isDesktop
          ? `${dataPostingRoutes.hasifaTkifa}`
          : `${mobileRoutes.stations}`
      );
  }, [isTrainingEnd]);
  const addNote = (note: string) => {
    setMainNotes((prev) => [
      ...prev,
      {
        note: note,
        isEditable: true,
        noteId: Math.round(Math.random() * 100 + 100),
      },
    ]);
  };
  const editNote = (noteId: number, noteText: string) => {
    setMainNotes((prev) =>
      prev.map((note) =>
        note.noteId === noteId ? { ...note, note: noteText } : note
      )
    );
  };
  const deleteNote = (noteId: number) => {
    setMainNotes((prev) => prev.filter((note) => note.noteId !== noteId));
  };
  useEffect(() => {
    setObserverSteps((prevState) => {
      return {
        ...prevState,
        [EObserverSteps.ConductingQuality]: {
          ...prevState[EObserverSteps.ConductingQuality],
          isCompleted: !Object.values(conductingQuality).includes(undefined),
          isPartlyCompleted: Object.values(conductingQuality).some(
            (value) => value !== undefined
          ),
        },
        [EObserverSteps.MainNotes]: {
          ...prevState[EObserverSteps.MainNotes],
          isCompleted: mainNotes.length > 0,
        },

        [EObserverSteps.OpenAndCover]: {
          ...prevState[EObserverSteps.OpenAndCover],
          isCompleted: !Object.values(openAndCover).includes(undefined),
          isPartlyCompleted: Object.values(openAndCover).some(
            (value) => value !== undefined
          ),
        },
        [EObserverSteps.SettingStation]: {
          ...prevState[EObserverSteps.SettingStation],
          isCompleted:
            settingStation.StationSelection !== undefined &&
            !Object.values(settingStation.HIZDATRUTatField).includes(
              undefined
            ) &&
            !Object.values(settingStation.SettingStation).includes(undefined),
          isPartlyCompleted:
            settingStation.StationSelection !== undefined ||
            settingStation.HIZDATRUTatField.accuracy !== undefined ||
            settingStation.HIZDATRUTatField.time !== undefined ||
            settingStation.SettingStation.accuracy !== undefined ||
            settingStation.SettingStation.time !== undefined,
        },
        [EObserverSteps.TargetAcquisition]: {
          ...prevState[EObserverSteps.TargetAcquisition],
          isCompleted: !Object.values(targetAcquisition).includes(undefined),
          isPartlyCompleted: Object.values(targetAcquisition).some(
            (value) => value !== undefined
          ),
        },
        [EObserverSteps.WorkRoutine]: {
          ...prevState[EObserverSteps.WorkRoutine],
          isCompleted: !Object.values(workRoutine).includes(undefined),
          isPartlyCompleted: Object.values(workRoutine).some(
            (value) => value !== undefined
          ),
        },
      };
    });
    // eslint-disable-next-line
  }, [
    settingStation,
    targetAcquisition,
    conductingQuality,
    workRoutine,
    openAndCover,
    mainNotes,
  ]);

  useEffect(() => {
    setSettingStation(initSettingStation);
  }, [initSettingStation]);
  return {
    settingStation,
    endTrainingHandlerMO,
    setSettingStation,
    targetAcquisition,
    setTargetAcquisition,
    conductingQuality,
    setConductingQuality,
    workRoutine,
    setWorkRoutine,
    openAndCover,
    setOpenAndCover,
    deleteNote,
    editNote,
    addNote,
    mainNotes,
    traineeName: gorillaTrainingsForces.Observer?.name,
    observerSteps,
    isTrainingEnd,
    observerCurrentStepType,
    setObserverCurrentStepType,
    endTrainingHandlerMOAfterConfirmation,
    closeConfirmationModal,
    isEndTrainingModalOpen,
  };
};
export default ObserverHook;
