import { IonCard, IonCol, IonItem, IonRow, IonText } from "@ionic/react";
import React from "react";
import "./PMToggle.css";
import { useTranslation } from "react-i18next";

interface ILeadersToggle<T> {
  firstOptionText: string;
  firstOptionValue: T;
  secondOptionText: string;
  secondOptionValue: T;
  state: T | undefined;
  setState: (state: T | undefined) => void;
}

const PMToggle = <T extends boolean | string | number>(
  props: ILeadersToggle<T>
) => {
  const { t } = useTranslation();
  return (
    <div className="toggleRow">
      <IonCol className="toggleCol">
        <IonItem
          lines="none"
          onClick={() => props.setState(props.firstOptionValue)}
          className={
            props.state === props.firstOptionValue
              ? "toggleItem selectedToggle"
              : "toggleItem"
          }
        >
          <IonText className="toggleText">{t(props.firstOptionText)}</IonText>
        </IonItem>
      </IonCol>
      <IonCol className="toggleCol">
        <IonItem
          lines="none"
          onClick={() => props.setState(props.secondOptionValue)}
          className={
            props.state === props.secondOptionValue
              ? "toggleItem selectedToggle"
              : "toggleItem"
          }
        >
          <IonText className="toggleText">{t(props.secondOptionText)}</IonText>
        </IonItem>
      </IonCol>
    </div>
  );
};

export default PMToggle;
