// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selected {
  --background: var(--ion-color-dark);
  --width: 100%;
}
.traineeItemLI {
  width: 100%;
  --background: var(--ion-color-dark);
}
.selectedDesktop {
  --background: var(--ion-color-success);
}
.traineeItemLI ion-checkbox {
  --background-checked: var(--ion-color-orange);
  --checkmark-color: var(--ion-color-dark);
  --border-color-checked: var(--ion-color-orange);
  --border-radius: 3px;
}

.traineeNameTL {
  width: 100%;
  font-size: large;
  font-family: "Light" !important;
  --color: ;
  --padding-start: 0;
  --padding-end: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/Mobile/SiteManagement/ManualStations/MobileListItem.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,aAAa;AACf;AACA;EACE,WAAW;EACX,mCAAmC;AACrC;AACA;EACE,sCAAsC;AACxC;AACA;EACE,6CAA6C;EAC7C,wCAAwC;EACxC,+CAA+C;EAC/C,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,+BAA+B;EAC/B,SAAS;EACT,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".selected {\n  --background: var(--ion-color-dark);\n  --width: 100%;\n}\n.traineeItemLI {\n  width: 100%;\n  --background: var(--ion-color-dark);\n}\n.selectedDesktop {\n  --background: var(--ion-color-success);\n}\n.traineeItemLI ion-checkbox {\n  --background-checked: var(--ion-color-orange);\n  --checkmark-color: var(--ion-color-dark);\n  --border-color-checked: var(--ion-color-orange);\n  --border-radius: 3px;\n}\n\n.traineeNameTL {\n  width: 100%;\n  font-size: large;\n  font-family: \"Light\" !important;\n  --color: ;\n  --padding-start: 0;\n  --padding-end: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
