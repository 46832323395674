// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-topics {
  display: flex;
  justify-content: space-between;
  margin-inline: 10vw;
}

.list-topic {
  color: var(--ion-color-text-quaternary);
  font-family: "Regular";
  font-size: 14px;
  margin-bottom: 1vh;
  margin-inline-end: -5vw;
}
`, "",{"version":3,"sources":["webpack://./src/components/Mobile/ManualStation/ShootingRange/ResultsScreen/ShootingRangeTraineesList/ListTopics.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,uCAAuC;EACvC,sBAAsB;EACtB,eAAe;EACf,kBAAkB;EAClB,uBAAuB;AACzB","sourcesContent":[".list-topics {\n  display: flex;\n  justify-content: space-between;\n  margin-inline: 10vw;\n}\n\n.list-topic {\n  color: var(--ion-color-text-quaternary);\n  font-family: \"Regular\";\n  font-size: 14px;\n  margin-bottom: 1vh;\n  margin-inline-end: -5vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
