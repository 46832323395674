// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerMO {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--ion-color-dark-shade);
}

.header {
  height: max-content;
}

.footer {
  height: max-content;
}

.contentObserver {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.segmentMenuRow {
  width: 100%;
  min-height: 62px;
  display: flex;
  justify-content: space-between;
  background-color: var(--ion-color-dark-shade);
}
.segmentMenuRow .all-segments {
  width: 100%;
}
.observerComponentWrap {
  /* display: flex;
  flex-direction: column;
  flex: 1; */
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/SPIKEGorilla/Observer/ObserverComponent.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,6CAA6C;AAC/C;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,SAAO;EACP,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,8BAA8B;EAC9B,6CAA6C;AAC/C;AACA;EACE,WAAW;AACb;AACA;EACE;;YAEU;AACZ","sourcesContent":[".containerMO {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  background-color: var(--ion-color-dark-shade);\n}\n\n.header {\n  height: max-content;\n}\n\n.footer {\n  height: max-content;\n}\n\n.contentObserver {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  overflow-y: auto;\n}\n\n.segmentMenuRow {\n  width: 100%;\n  min-height: 62px;\n  display: flex;\n  justify-content: space-between;\n  background-color: var(--ion-color-dark-shade);\n}\n.segmentMenuRow .all-segments {\n  width: 100%;\n}\n.observerComponentWrap {\n  /* display: flex;\n  flex-direction: column;\n  flex: 1; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
