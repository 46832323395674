// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.numberSelector {
  width: 124px;
  height: 48px;
  border-radius: 60px;
  background-color: var(--ion-color-labelInput);

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: var(--box-shadow);
}
.numberSelectorRow {
  height: 70px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.numberSelectorCol {
  min-width: max-content;
  max-width: max-content;
  margin-inline-end: 10px;
}
.desktop .numberSelector {
  width: 154px;
  height: 50px;
}
.desktop .numberSelectorRow {
  flex-direction: column;
  align-items: flex-start;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/SPIKEGorilla/NumberSelector.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,6CAA6C;;EAE7C,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,6BAA6B;AAC/B;AACA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,sBAAsB;EACtB,sBAAsB;EACtB,uBAAuB;AACzB;AACA;EACE,YAAY;EACZ,YAAY;AACd;AACA;EACE,sBAAsB;EACtB,uBAAuB;AACzB","sourcesContent":[".numberSelector {\n  width: 124px;\n  height: 48px;\n  border-radius: 60px;\n  background-color: var(--ion-color-labelInput);\n\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  box-shadow: var(--box-shadow);\n}\n.numberSelectorRow {\n  height: 70px;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.numberSelectorCol {\n  min-width: max-content;\n  max-width: max-content;\n  margin-inline-end: 10px;\n}\n.desktop .numberSelector {\n  width: 154px;\n  height: 50px;\n}\n.desktop .numberSelectorRow {\n  flex-direction: column;\n  align-items: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
