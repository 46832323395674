import { useEffect, useState } from "react";
import Axios from "../../Axios";
import axios, { CancelTokenSource } from "axios";

import {
  baseUrlPMBackend,
  filterLeadersByAttendance,
} from "../../Configurations/consts";
import IForceFromOrbat from "../../Interfaces/IForceFromOrbat";
import IPlan from "../../Interfaces/IPlan";
import { useTranslation } from "react-i18next";
import customToast from "../Shared/Toast/CustomToast";
import { ILeadersDetails, ILeadersResponse } from "../../Interfaces/ILeaders";
import IForceTreeNode from "../../Interfaces/IForceTreeNode";
import { flattenTreeNodes } from "../../services/orbatExportsFunction";
import { getForceFullName } from "../../services/helpers";

const useGetLeaders = (
  selectedPlan: IPlan | undefined | null,
  checkedForces: IForceFromOrbat[],
  newForces: IForceFromOrbat[],
  forcesToRemove: IForceFromOrbat[],
  isLeadersForTrainee: boolean,
  orbatForces: IForceTreeNode[]
) => {
  const [individuals, setIndividuals] = useState<ILeadersDetails[]>([]);
  const [units, setUnits] = useState<ILeadersDetails[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const [cancelTokenSource, setCancelTokenSource] = useState<
    CancelTokenSource | undefined
  >(undefined);
  let maxUnitsToDisplay = 20;
  let maxIndividualsToDisplay = 100;

  //insert the results to the units and individual arrays in recursive way
  const getResults = (
    responseLeaders: ILeadersResponse,
    individualsTemp: ILeadersDetails[],
    unitsTemp: ILeadersDetails[]
  ) => {
    const UNIT_PARTICIPANTS_PERCENTS = 70;
    if (!responseLeaders.children.length) {
      //stop condition!!

      if (
        responseLeaders.results?.participantsAtLeastOnes &&
        (responseLeaders.results?.isAttendAllTrainingPlan || //if the trainee participants at tall the plans training types
          !filterLeadersByAttendance)
        //or if the display with filer is off -> display all
      ) {
        individualsTemp.push({
          grade: responseLeaders.results?.grade,
          id: responseLeaders.id,
          name: getForceFullName(
            responseLeaders.name,
            responseLeaders.forceType,
            t
          ),
          affiliation: responseLeaders.hierarchicalName.map((affiliation) => {
            return getForceFullName(affiliation.name, affiliation.forceType, t);
          }),
        });
      }
    } else {
      //search for training type with less than 70% participants
      const isOneStationHasLessThan70PercentsParticipants =
        responseLeaders.results?.indicatorResults.find((indicatorResult) =>
          indicatorResult.trainingTypeResults.find(
            (trainingTypeResult) =>
              trainingTypeResult.weight && //make sure the training plan includes the training type
              (trainingTypeResult.participants /
                (trainingTypeResult.soldierMissed +
                  trainingTypeResult.participants)) *
                100 <
                UNIT_PARTICIPANTS_PERCENTS
          )
        );
      if (
        responseLeaders.results?.participantsAtLeastOnes &&
        (!isOneStationHasLessThan70PercentsParticipants || // if one of the stations has less then 70 % participants
          !filterLeadersByAttendance) //or if the display with filer is off -> display all
      ) {
        unitsTemp.push({
          grade: responseLeaders.results?.grade,
          id: responseLeaders.id,
          name: getForceFullName(
            responseLeaders.name,
            responseLeaders.forceType,
            t
          ),
          affiliation: responseLeaders.hierarchicalName.map((affiliation) => {
            return getForceFullName(affiliation.name, affiliation.forceType, t);
          }),
        });
      }
      responseLeaders.children.forEach((child) => {
        getResults(child, individualsTemp, unitsTemp);
      });
    }
  };
  const setLeadersResults = (leadersResults: ILeadersResponse) => {
    let individualsTemp: ILeadersDetails[] = [];
    let unitsTemp: ILeadersDetails[] = [];
    getResults(leadersResults, individualsTemp, unitsTemp);
    setIndividuals((prev: ILeadersDetails[]) => {
      prev = prev.concat(individualsTemp);
      prev = prev.reduce(
        (accumalator: ILeadersDetails[], current: ILeadersDetails) => {
          if (!accumalator.some((item) => item.id === current.id)) {
            accumalator.push(current);
          }
          return accumalator;
        },
        []
      );
      return prev.sort((x, y) => {
        return y.grade - x.grade;
      });
    });
    setUnits((prev: ILeadersDetails[]) => {
      prev = prev.concat(unitsTemp);
      prev = prev.reduce(
        (accumalator: ILeadersDetails[], current: ILeadersDetails) => {
          if (!accumalator.some((item) => item.id === current.id)) {
            accumalator.push(current);
          }
          return accumalator;
        },
        []
      );
      return prev.sort((x, y) => {
        return y.grade - x.grade;
      });
    });
    setLoading(false);
  };

  useEffect(() => {
    if (!newForces?.length || !selectedPlan) return;

    setLoading(true);

    getLeadersGradesForForces(
      selectedPlan,
      newForces?.map((force) => force.id)
    )
      .then((res: any) => {
        setLoading(false);
        res.data.forEach((resp: ILeadersResponse) => {
          setLeadersResults(resp);
        });
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          setLoading(false);
          customToast.error(t("errorWhileLoadingData"));
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newForces]);

  //when selectedPlan changed both competency and station details need to be refresh
  useEffect(() => {
    setUnits([]);
    setIndividuals([]);
    let mounted: boolean = true;
    if (selectedPlan?.id === undefined || !checkedForces.length) return;
    setLoading(true);

    getLeadersGradesForForces(
      selectedPlan,
      checkedForces.map((force) => force.id)
    )
      .then((res: any) => {
        mounted && setLoading(false);
        mounted &&
          res.data.forEach((resp: ILeadersResponse) => {
            setLeadersResults(resp);
          });
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          setLoading(false);
          customToast.error(t("errorWhileLoadingData"));
        }
      });
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlan]);

  const getLeadersGradesForForces = async (
    plan: IPlan,
    forcesIds: number[]
  ) => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Request canceled due to new request");
    }
    if (!plan.id || !forcesIds.length) return;
    const source = axios.CancelToken.source();
    setCancelTokenSource(source);
    return await Axios.get(
      `${baseUrlPMBackend}performanceGrades/getLeadersResults`,
      {
        params: {
          forcesIds: forcesIds,
          isLeadersForTrainee: isLeadersForTrainee,
          planId: plan.id,
        },
        cancelToken: source.token,
      }
    );
  };
  useEffect(() => {
    if (forcesToRemove.length) {
      let flatTree = orbatForces
        .map((force) => flattenTreeNodes(force))
        .flat(1)
        .map((force) => force.id);

      if (forcesToRemove.length) {
        setUnits((prev: ILeadersDetails[]) => {
          return prev.filter((unit) => flatTree.includes(unit.id));
        });
        setIndividuals((prev: ILeadersDetails[]) => {
          return prev.filter((individual) => flatTree.includes(individual.id));
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forcesToRemove]);

  return {
    individuals: individuals.slice(0, maxIndividualsToDisplay),
    units: units.slice(0, maxUnitsToDisplay),
    setIndividuals,
    setUnits,
    loading,
  };
};

export default useGetLeaders;
