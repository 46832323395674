import Axios from "../Axios";
import { baseUrlPMBackend } from "../Configurations/consts";

const checkSuperUserPassword = async (
  password: string,
  setInvalid: (state: boolean) => void
) => {
  try {
    setInvalid(false);

    const isPasswordValid = await Axios.post(
      `${baseUrlPMBackend}users/authSuperUserPassword`,
      {
        password: password,
      }
    );
    if (!isPasswordValid.data) setInvalid(true);
    else setInvalid(false);

    return isPasswordValid.data;
  } catch (error) {
    setInvalid(true);
    return null;
  }
};

const checkSuperSoferPassword = async (
  password: string,
  setInvalid: (state: boolean) => void
) => {
  try {
    setInvalid(false);

    const isPasswordValid = await Axios.post(
      `${baseUrlPMBackend}users/authSuperSoferPassword`,
      {
        params: { password: password },
      }
    );
    if (!isPasswordValid.data) setInvalid(true);
    else setInvalid(false);

    return isPasswordValid.data;
  } catch (error) {
    setInvalid(true);
    return null;
  }
};
const checkPassword = async (
  password: string,
  setInvalid: (state: boolean) => void
) => {
  try {
    setInvalid(false);

    const isPasswordValid = await Axios.post(
      `${baseUrlPMBackend}users/authAdminPassword`,
      {
        password: password,
      }
    );
    if (!isPasswordValid.data) setInvalid(true);
    else setInvalid(false);

    return isPasswordValid.data;
  } catch (error) {
    setInvalid(true);
    return null;
  }
};

export { checkSuperUserPassword, checkPassword, checkSuperSoferPassword };
