import { FC, useEffect, useState } from "react";
import { IonCol, IonRow } from "@ionic/react";
import "./AccuracyElement.css";
import PMLabel from "../../themeComponents/PMLabel";
import { useTranslation } from "react-i18next";
import SwitchButton from "../../Mobile/SiteManagement/ManualStations/SwitchButton";
interface IProps {
  elementName: string;
  onChange: (isChecked: boolean | undefined) => void;
  accuracy: boolean | undefined;
}
const AccuracyElement: FC<IProps> = (props: IProps) => {
  const { elementName, onChange } = props;

  const { t } = useTranslation();
  const [accuracy, setAccuracy] = useState<boolean | undefined>(props.accuracy);
  useEffect(() => {
    onChange(accuracy);
    // eslint-disable-next-line
  }, [accuracy]);

  useEffect(() => {
    setAccuracy(props.accuracy);
    // eslint-disable-next-line
  }, [props.accuracy]);
  return (
    <IonRow className="accuracyRow">
      <IonCol size="4" className=" accuracyElementCol elementNameColA">
        <PMLabel
          fontFamily="Regular"
          fontSize="medium"
          fontColor="light"
          textAlign="start"
        >
          {t(elementName)}
        </PMLabel>
      </IonCol>
      <IonCol className=" AccuracyElementCol">
        <IonRow className="switchButtonRowA">
          <SwitchButton
            handleToggle={setAccuracy}
            isOn={accuracy}
          ></SwitchButton>
        </IonRow>
      </IonCol>
    </IonRow>
  );
};

export default AccuracyElement;
