// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-popover h3,
.contact-popover h5 {
  font-family: "Light" !important;
  color: #ffffff6b;
  text-align: center;
}

.popover-content {
  width: min-content !important;
}

.popover-content.sc-ion-popover-md {
  background-color: #36383d;
  padding: 0.5%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/SecurityContact/SecurityContact.css"],"names":[],"mappings":"AAAA;;EAEE,+BAA+B;EAC/B,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;EACzB,aAAa;AACf","sourcesContent":[".contact-popover h3,\n.contact-popover h5 {\n  font-family: \"Light\" !important;\n  color: #ffffff6b;\n  text-align: center;\n}\n\n.popover-content {\n  width: min-content !important;\n}\n\n.popover-content.sc-ion-popover-md {\n  background-color: #36383d;\n  padding: 0.5%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
