import { IonItem, IonList } from "@ionic/react";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import ISelectionOption from "../../../Interfaces/ISelectionOption";
import Checkbox from "../../Shared/Checkbox/Checkbox";
import "./MultiSelectDropdown.css";
import PMLabel from "../../themeComponents/PMLabel";
import PMButton from "../../themeComponents/PMButton";
import PMAccordion from "../../Shared/PMAccordion/PMAccordion";

interface IProps {
  setChecked?: Dispatch<SetStateAction<ISelectionOption[]>>;
  setCheckedHandler?: (
    checkedData?: { id: string; labelName: string },
    checked?: boolean,
    isSelect?: boolean
  ) => void;
  selectionPotions: ISelectionOption[];
  title: string;
  placeHolder?: string;
  isForModal?: boolean;
  isInnerItem?: boolean;
}
const MultiSelectDropdown: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { setChecked, setCheckedHandler, selectionPotions, title, isForModal } =
    props;
  const { t } = useTranslation();

  /**
   * checkbox changes
   * @param event
   * @returns
   */
  const handleChange = (event: any, option: ISelectionOption) => {
    if (setChecked)
      return setChecked((prev: ISelectionOption[]) => {
        let newArray: ISelectionOption[] = [];
        prev.forEach((option) => {
          if (Number(option.option.id) === Number(event.detail.value))
            newArray.push({
              option: option.option,
              isSelected: event.detail.checked,
            });
          else newArray.push(option);
        });
        return newArray;
      });
    else if (setCheckedHandler) {
      setCheckedHandler(
        {
          id: event.detail.value,
          labelName: option.option.label,
        },
        event.detail.checked
      );
    }
  };

  return (
    <PMAccordion
      isInnerItem={props.isInnerItem}
      label={
        isForModal
          ? `${title} (${
              selectionPotions.filter((option) => option.isSelected).length
            })`
          : selectionPotions.filter((option) => option.isSelected).length
          ? `${
              selectionPotions.filter((option) => option.isSelected)[0]?.option
                .label
            } + ${
              selectionPotions.filter((option) => option.isSelected).length
            }`
          : undefined
      }
      placeholder={isForModal ? "" : title}
    >
      <div className="selections scrollS">
        {isForModal ? (
          <IonItem className="selectionRow" lines="none">
            <PMButton
              label={{
                fontColor: "light",
                fontSize: "medium",
                fontFamily: "Light",
              }}
              color="orange"
              fill="solid"
              size="fit"
              cssClass="scaBtn"
              onClickHandler={() => {
                if (setCheckedHandler)
                  setCheckedHandler(undefined, undefined, true);
              }}
            >
              {t("selectAll")}
            </PMButton>

            <PMButton
              label={{
                fontColor: "light",
                fontSize: "medium",
                fontFamily: "Light",
              }}
              color="orange"
              fill="solid"
              size="fit"
              cssClass="scaBtn"
              onClickHandler={() => {
                if (setCheckedHandler)
                  setCheckedHandler(undefined, undefined, false);
              }}
            >
              {t("clearAll")}
            </PMButton>
          </IonItem>
        ) : null}
        <IonList className="multiSelectionList">
          {selectionPotions.map((option) => (
            <IonItem
              className="selectionRow"
              key={option.option.id}
              lines="none"
            >
              <Checkbox
                color="orange"
                name={`${option.option.label}`}
                value={String(option.option.id)}
                isChecked={!!option.isSelected}
                onChange={(event) => {
                  handleChange(event, option);
                }}
              />
              <div className="searchLabels">
                <PMLabel fontColor="light" fontFamily="Light">{`${t(
                  option.option.label
                )}`}</PMLabel>
                {option.option.label2 ? (
                  <PMLabel fontColor="light" fontFamily="Light">{`${t(
                    option.option.label2
                  )}`}</PMLabel>
                ) : null}
              </div>
            </IonItem>
          ))}
        </IonList>
      </div>
    </PMAccordion>
  );
};

export default MultiSelectDropdown;
