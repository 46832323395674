// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heatMenuRow {
  --width: 100%;
  --height: 48px;
  --background: var(--ion-color-dark);
  --min-height: 48px;
}
.heatMenuCol {
  display: flex;
  justify-content: center;
  align-items: center;
}
.heatMenuCol.start {
  justify-content: flex-start;
}

.heatMenuCol.end {
  justify-content: flex-end;
  padding-inline-end: 15px;
}
.activeHeat {
  border-radius: 2px;
  --background: #aeaeae !important ;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/SPIKEGorilla/HeatMenuRow.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,mCAAmC;EACnC,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;EACzB,wBAAwB;AAC1B;AACA;EACE,kBAAkB;EAClB,iCAAiC;EACjC,+CAA+C;AACjD","sourcesContent":[".heatMenuRow {\n  --width: 100%;\n  --height: 48px;\n  --background: var(--ion-color-dark);\n  --min-height: 48px;\n}\n.heatMenuCol {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.heatMenuCol.start {\n  justify-content: flex-start;\n}\n\n.heatMenuCol.end {\n  justify-content: flex-end;\n  padding-inline-end: 15px;\n}\n.activeHeat {\n  border-radius: 2px;\n  --background: #aeaeae !important ;\n  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
