import "./AdminPasswordModal.css";
import React from "react";
import AdminPassword from "./AdminPassword";
import DragModal from "../DragModal/DragModal";

interface passModalProps {
  onSendPasswordHandler: (password: string) => void;
  invalid: boolean;
  text: string;
  onCancelHandler?: () => void;
  isOpen: boolean;
}
const AdminPasswordModal = (props: passModalProps) => {
  return (
    <DragModal
      cssClass="passwordModal"
      isOpen={props.isOpen}
      onDismiss={props.onCancelHandler!}
    >
      <div className="passModalWrap">
        <AdminPassword
          onSendPasswordHandler={(password: string) =>
            props.onSendPasswordHandler(password)
          }
          onCancelHandler={props.onCancelHandler}
          invalid={props.invalid}
          text={props.text}
        ></AdminPassword>
      </div>
    </DragModal>
  );
};

export default AdminPasswordModal;
