import { Dispatch, SetStateAction, useEffect, useState } from "react";
import IForceFromOrbat from "../../Interfaces/IForceFromOrbat";
import IPlan from "../../Interfaces/IPlan";
import { IIndicatorResult } from "../../Interfaces/dataCalculator";
import useGetGorillaGrades from "./useGetGorillaGrades";
import { EGorillaIndicators } from "../../Interfaces/Gorilla/GorillaTrainings";

const useGetSingleGunnerGrades = (
  selectedPlan: IPlan | undefined,
  force: IForceFromOrbat[],
  refresh: boolean | undefined,
  setRefresh: Dispatch<SetStateAction<boolean | undefined>>
) => {
  const [singleGunnerForceResults, setSingleGunnerForceResults] =
    useState<IIndicatorResult>();
  const [notes, setNotes] = useState<string[] | undefined>();

  const { forceData, hitAnalyze, loading } = useGetGorillaGrades(
    selectedPlan,
    force,
    refresh,
    setRefresh
  );
  useEffect(() => {
    if (forceData) {
      setNotes(forceData?.notes?.Single_Gunner || undefined);

      setSingleGunnerForceResults(
        forceData.indicatorResults?.find(
          (indicator) => indicator.id === EGorillaIndicators.SingleGunner
        )
      );
    } else setSingleGunnerForceResults(undefined);
  }, [forceData]);
  return { singleGunnerForceResults, hitAnalyze, loading, notes };
};
export default useGetSingleGunnerGrades;
