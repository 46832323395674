import React, { useContext, useEffect } from "react";
import { Route } from "react-router-dom";
import { Redirect, useLocation } from "react-router-dom";

// css
import "./DataPosting.css";

// ionic imports
import {
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";

// interfaces
import TabItem from "../../../Interfaces/ITabItem";
import Mrk from "../../../components/Desktop/DataPosting/Mrk";
import LiveShooting from "../../../components/Desktop/DataPosting/ShootingRange/LiveShooting";

import { useTranslation } from "react-i18next";
// Tabs buttons options
import MedicalDesktop from "../../../components/Desktop/DataPosting/MedicalDesktop";
import CombatFitnessDesktop from "../../../components/Desktop/DataPosting/CombatFitnessDesktop";
import InstructorFeedback from "../../../components/Desktop/DataPosting/InstructorFeedback";
import Magnet from "../../../components/Desktop/DataPosting/Magnet";
import BISTUrban from "../../../components/Desktop/DataPosting/BISTUrban";
import ResponsiveShooting from "../../../components/Desktop/DataPosting/ResponsiveShooting";
import usePlans from "../../../components/CustomHooks/usePlans";
import SPIKE from "../../../components/Desktop/DataPosting/SPIKE";
import PMLabel from "../../../components/themeComponents/PMLabel";
import { ForcesContextDesktop } from "../../../context/ForcesContext/forcesContextProviderDesktop";
import UseDataPostingTabs, {
  dataPostingRoutes,
} from "../../../components/CustomHooks/useDataPostingTabs";
import { routes } from "../../../services/routeRoles";
import FireSupport from "../../../components/Desktop/DataPosting/FIreSupport";
import HasifaTKifaDesktop from "../../../components/Desktop/DataPosting/HASIFA_TKIFA/HasifaTKifaDesktop";
import { EGorillaTrainings } from "../../../Interfaces/Gorilla/GorillaTrainings";
import FrameTrainingDesktop from "../../../components/Desktop/DataPosting/HASIFA_TKIFA/FrameTrainingDesktop";
import SingleGunnerDesktop from "../../../components/Desktop/DataPosting/HASIFA_TKIFA/SingleGunnerDesktop";
import ObserverDesktop from "../../../components/Desktop/DataPosting/HASIFA_TKIFA/ObserverDesktop";
import { EAppMode } from "../../../Enums/EAppMode";
import { AppModeContext } from "../../../context/AppModeContext/AppModeContext";
import BilliardDesktop from "../../../components/Desktop/DataPosting/BilliardDesktop";
import { isValidAppMode } from "../../../services/helpers";
import useNavigation from "../../../components/CustomHooks/useNavigation";

const DataPosting: React.FC = (): JSX.Element => {
  const location = useLocation();
  const { t } = useTranslation();
  const { resetCompetencyForces } = useContext(ForcesContextDesktop);
  const { tabOptions } = UseDataPostingTabs();
  const { appMode } = useContext(AppModeContext);
  const { replace } = useNavigation();
  const {
    onPlanSelectedHandler,
    plansOptions,
    selectedPlan,
    plansValuesForSelectedPlan,
  } = usePlans();
  const redirectToValidTab = (
    pathname: string,
    tabOptions: TabItem[],
    defaultRoute: string,
    replace: (path: string) => void
  ) => {
    if (tabOptions.length && pathname !== defaultRoute) {
      const isValidPath = tabOptions.some((tab) => pathname.includes(tab.url));
      if (!isValidPath) {
        replace(defaultRoute);
      }
    }
  };
  useEffect(() => {
    redirectToValidTab(
      location.pathname,
      tabOptions,
      routes.dataPosting,
      replace
    );
  }, [location.pathname, tabOptions, replace]);
  const Routes = () => (
    <IonRouterOutlet id="main" className="dataPostingRouter">
      <Route
        path={routes.dataPosting}
        render={() => (
          <Redirect
            to={`${tabOptions.length ? tabOptions[0].url : routes.dataPosting}`}
          />
        )}
        exact={true}
      />

      <Route
        path={dataPostingRoutes.simulativeShootingIEST}
        render={() => (
          <Mrk
            onPlanSelectedHandler={onPlanSelectedHandler}
            plansOptions={plansOptions}
            selectedPlan={selectedPlan}
            resetCompetencyForces={resetCompetencyForces}
          ></Mrk>
        )}
        exact
      />
      <Route
        path={dataPostingRoutes.simulativeShootingBIST}
        render={() => (
          <Mrk
            onPlanSelectedHandler={onPlanSelectedHandler}
            plansOptions={plansOptions}
            selectedPlan={selectedPlan}
            resetCompetencyForces={resetCompetencyForces}
            isBist
          ></Mrk>
        )}
        exact
      />
      <Route
        path={dataPostingRoutes.BISTUrban}
        render={() => (
          <BISTUrban
            onPlanSelectedHandler={onPlanSelectedHandler}
            plansOptions={plansOptions}
            resetCompetencyForces={resetCompetencyForces}
            selectedPlan={selectedPlan}
          ></BISTUrban>
        )}
        exact
      />
      <Route
        path={dataPostingRoutes.shootingRange}
        render={() => (
          <LiveShooting
            onPlanSelectedHandler={onPlanSelectedHandler}
            plansOptions={plansOptions}
            resetCompetencyForces={resetCompetencyForces}
            selectedPlan={selectedPlan}
          />
        )}
        exact
      />
      <Route
        path={dataPostingRoutes.responsiveShootingIEST}
        render={() => (
          <ResponsiveShooting
            onPlanSelectedHandler={onPlanSelectedHandler}
            plansOptions={plansOptions}
            resetCompetencyForces={resetCompetencyForces}
            selectedPlan={selectedPlan}
          ></ResponsiveShooting>
        )}
        exact
      />
      <Route
        path={dataPostingRoutes.responsiveShootingBIST}
        render={() => (
          <ResponsiveShooting
            onPlanSelectedHandler={onPlanSelectedHandler}
            plansOptions={plansOptions}
            resetCompetencyForces={resetCompetencyForces}
            selectedPlan={selectedPlan}
            isBist
          ></ResponsiveShooting>
        )}
        exact
      />
      <Route
        path={dataPostingRoutes.medical}
        render={() => (
          <MedicalDesktop
            onPlanSelectedHandler={onPlanSelectedHandler}
            plansOptions={plansOptions}
            resetCompetencyForces={resetCompetencyForces}
            selectedPlan={selectedPlan}
          ></MedicalDesktop>
        )}
        exact
      />
      <Route
        path={dataPostingRoutes.combatFitness}
        render={() => (
          <CombatFitnessDesktop
            onPlanSelectedHandler={onPlanSelectedHandler}
            plansOptions={plansOptions}
            resetCompetencyForces={resetCompetencyForces}
            selectedPlan={selectedPlan}
          ></CombatFitnessDesktop>
        )}
        exact
      />
      <Route
        path={dataPostingRoutes.urbanInstructorFeedback}
        render={() => (
          <InstructorFeedback
            onPlanSelectedHandler={onPlanSelectedHandler}
            plansOptions={plansOptions}
            selectedPlan={selectedPlan}
            plansValuesForSelectedPlan={plansValuesForSelectedPlan}
            resetCompetencyForces={resetCompetencyForces}
            trainingTypeId={16}
          ></InstructorFeedback>
        )}
        exact
      />
      <Route
        path={dataPostingRoutes.magnetFOF}
        render={() => (
          <Magnet
            onPlanSelectedHandler={onPlanSelectedHandler}
            plansOptions={plansOptions}
            resetCompetencyForces={resetCompetencyForces}
            selectedPlan={selectedPlan}
          ></Magnet>
        )}
        exact
      />
      <Route
        path={dataPostingRoutes.magnetInstructorFeedback}
        render={() => (
          <InstructorFeedback
            onPlanSelectedHandler={onPlanSelectedHandler}
            plansOptions={plansOptions}
            selectedPlan={selectedPlan}
            plansValuesForSelectedPlan={plansValuesForSelectedPlan}
            resetCompetencyForces={resetCompetencyForces}
            trainingTypeId={20}
          ></InstructorFeedback>
        )}
        exact
      />
      <Route
        path={dataPostingRoutes.spike}
        render={() => (
          <SPIKE
            onPlanSelectedHandler={onPlanSelectedHandler}
            plansOptions={plansOptions}
            resetCompetencyForces={resetCompetencyForces}
            selectedPlan={selectedPlan}
          ></SPIKE>
        )}
        exact
      />
      <Route
        path={dataPostingRoutes.fireSupport}
        render={() => (
          <FireSupport
            onPlanSelectedHandler={onPlanSelectedHandler}
            plansOptions={plansOptions}
            resetCompetencyForces={resetCompetencyForces}
            selectedPlan={selectedPlan}
          ></FireSupport>
        )}
        exact
      />
      <Route
        path={dataPostingRoutes.hasifaTkifa}
        render={() => <HasifaTKifaDesktop></HasifaTKifaDesktop>}
        exact
      />
      <Route
        path={`${dataPostingRoutes.hasifaTkifa}/${EGorillaTrainings.FrameTraining}`}
        render={() => <FrameTrainingDesktop></FrameTrainingDesktop>}
        exact
      />
      <Route
        path={`${dataPostingRoutes.hasifaTkifa}/${EGorillaTrainings.SingleGunner}`}
        render={() => <SingleGunnerDesktop></SingleGunnerDesktop>}
        exact
      />
      <Route
        path={`${dataPostingRoutes.hasifaTkifa}/${EGorillaTrainings.Observer}`}
        render={() => <ObserverDesktop></ObserverDesktop>}
        exact
      />
      <Route
        path={`${dataPostingRoutes.billiard}`}
        render={() => (
          <BilliardDesktop
            onPlanSelectedHandler={onPlanSelectedHandler}
            plansOptions={plansOptions}
            resetCompetencyForces={resetCompetencyForces}
            selectedPlan={selectedPlan}
          ></BilliardDesktop>
        )}
        exact
      />
    </IonRouterOutlet>
  );

  return tabOptions.length > 1 || isValidAppMode(appMode, EAppMode.WARTAC) ? (
    <IonTabs className="pm-tabs">
      {Routes()}
      <IonTabBar
        slot="top"
        className={"ion-justify-content-start dataPostingTabs"}
      >
        {tabOptions.map((item: TabItem, index: number) => {
          return (
            <IonTabButton
              className={
                location.pathname.includes(item.url) ? "tab-selected" : ""
              }
              tab={item.title}
              href={item.url}
              key={index}
            >
              <PMLabel
                fontColor="light"
                fontFamily="Light"
                fontSize="medium"
                textAlign="center"
              >
                {t(item.title.trim())}
              </PMLabel>
            </IonTabButton>
          );
        })}
      </IonTabBar>
    </IonTabs>
  ) : (
    Routes()
  );
};
export default DataPosting;
