import { useState, useEffect, useContext } from "react";
import { InstructorFeedbackElementsData } from "../../Interfaces/IInstructorFeedback";
import { IElementsPlanValue } from "../../Interfaces/IPlanValues";
import { useTranslation } from "react-i18next";
import { instructorFeedbackElementsData } from "../../Configurations/InstructorFeedbackElementsData";
import ITrainingType from "../../Interfaces/ITrainingType";
import { ITabOptions } from "../../Interfaces/IDataHistory";
import { TrainingTypesContext } from "../../context/TrainingTypesContext/TrainingTypesContext";
import { ElementsContext } from "../../context/ElementsContext/ElementsContext";

interface IInstructorFeedbackSegment extends ITabOptions {
  elements?: InstructorFeedbackElementsData[];
}
const InstructorFeedbackParamsHook = (
  elementsPlanValues: IElementsPlanValue[] | undefined,
  trainingTypeId: number
) => {
  const { trainingTypes } = useContext(TrainingTypesContext);
  const { elements } = useContext(ElementsContext);
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [selectedSegment, setSelectedSegment] = useState<
    IInstructorFeedbackSegment | undefined
  >();

  const [segmentsOptions, setSegmentsOptions] = useState<
    IInstructorFeedbackSegment[]
  >([] as IInstructorFeedbackSegment[]);
  const [isSendDataClicked, setSendDataClicked] = useState<boolean>(false);
  const [currentTrainingType, setCurrentTrainingTyped] =
    useState<ITrainingType>();
  const [isSendEnabled, setIsComplete] = useState<boolean>(false);

  useEffect(() => {
    setSendDataClicked(false);
  }, [isSendDataClicked]);

  useEffect(() => {
    setCurrentTrainingTyped(
      trainingTypes.find((trainingType) => trainingType.id === trainingTypeId)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainingTypes]);

  useEffect(() => {
    const options = [
      {
        tabId: 0,
        title: "single",
        url: "instructorFeedback/single",
      },
      {
        tabId: 1,
        title: "couple",
        url: "instructorFeedback/couple",
      },
      {
        tabId: 2,
        title: "class",
        url: "instructorFeedback/class",
      },
    ];
    if (
      elementsPlanValues?.length &&
      elements.length &&
      currentTrainingType?.id
    ) {
      let segmentsOptions = options
        .map((segment) => {
          let currentElements = elements
            .filter(
              (element) =>
                element.trainingTypeId === currentTrainingType?.id &&
                segment?.title &&
                element.name
                  .toLowerCase()
                  .includes(segment?.title.toLowerCase())
            )
            .sort((a, b) => a.id - b.id);
          let isExist = !!currentElements.find(
            (element) =>
              !!elementsPlanValues.find(
                (planValue) => planValue.elementId === element.id
              )?.weight
          );
          let currentElements2: InstructorFeedbackElementsData[] = [];
          if (isExist)
            currentElements2 = currentElements.map((element) => {
              let isMust = !!elementsPlanValues.find(
                (planValue) => planValue.elementId === element.id
              )?.weight;

              return {
                title: t(element.name),
                dataType: element.name,
                params: Array(instructorFeedbackElementsData[element.name])
                  .fill("")
                  .map((_value, index: number) =>
                    t(`${element.name}${index + 1}`)
                  ),
                isMust: isMust,
              };
            });
          return { ...segment, elements: currentElements2 };
        })
        .filter((segmentsOption) => segmentsOption.elements?.length);

      setSelectedSegment(segmentsOptions[0]);
      setIsLoading(false);
      setSegmentsOptions(segmentsOptions);
    }
  }, [elementsPlanValues, elements, currentTrainingType, t]);

  return {
    currentTrainingType,
    setSendDataClicked,
    isSendEnabled,
    segmentsOptions,
    isSendDataClicked,
    setIsComplete,
    selectedSegment,
    setSelectedSegment,
    isLoading,
  };
};
export default InstructorFeedbackParamsHook;
