// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plansRow {
  margin-top: 10%;
  height: 100%;
  width: 100%;
}

.plansDiv {
  overflow-x: hidden !important;
  overflow-y: auto !important;
  width: 100%;
  max-height: 85%;
}

.plansGrid {
  height: 100%;
  width: 100%;
  overflow-x: visible;
}

.planDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: visible;
  position: relative;
}

.addPlanDiv {
  padding-inline-start: 5%;
  padding-top: 10px;
  font-size: 15px !important;
}

.plansDiv::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.loadingPlans {
  color: #878a91;
}

.__react_component_tooltip {
  top: 10px !important;
}

.tpListDivider {
  height: 3px;
  border-bottom: 1px solid grey;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/TrainingPlan/TrainingPlansSideBar.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,6BAA6B;EAC7B,2BAA2B;EAC3B,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,wBAAwB;EACxB,iBAAiB;EACjB,0BAA0B;AAC5B;;AAEA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,6BAA6B;AAC/B","sourcesContent":[".plansRow {\n  margin-top: 10%;\n  height: 100%;\n  width: 100%;\n}\n\n.plansDiv {\n  overflow-x: hidden !important;\n  overflow-y: auto !important;\n  width: 100%;\n  max-height: 85%;\n}\n\n.plansGrid {\n  height: 100%;\n  width: 100%;\n  overflow-x: visible;\n}\n\n.planDiv {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  overflow: visible;\n  position: relative;\n}\n\n.addPlanDiv {\n  padding-inline-start: 5%;\n  padding-top: 10px;\n  font-size: 15px !important;\n}\n\n.plansDiv::-webkit-scrollbar {\n  width: 5px;\n  height: 5px;\n}\n\n.loadingPlans {\n  color: #878a91;\n}\n\n.__react_component_tooltip {\n  top: 10px !important;\n}\n\n.tpListDivider {\n  height: 3px;\n  border-bottom: 1px solid grey;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
