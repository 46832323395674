// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.results-header {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 3%;
  height: 10%;
}

.selected-heat {
  width: 60%;
  margin-inline-start: 3vw;
}

.archievement-required-details {
  width: 40%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Mobile/ManualStation/ShootingRange/ResultsScreen/ResultsScreenHeader/ResultsScreenHeader.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,mBAAmB;EACnB,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,UAAU;EACV,wBAAwB;AAC1B;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".results-header {\n  display: flex;\n  width: 100%;\n  flex-direction: row;\n  margin-bottom: 3%;\n  height: 10%;\n}\n\n.selected-heat {\n  width: 60%;\n  margin-inline-start: 3vw;\n}\n\n.archievement-required-details {\n  width: 40%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
