import React from "react";
import { IonItem, IonLabel, IonItemGroup } from "@ionic/react";
import "./NumbersList.css";

interface IMaxColumnLength {
  length: number;
}

const NumbersList: React.FC<IMaxColumnLength> = (props: IMaxColumnLength) => {
  const initNumbersList: Function = (maxListLength: number) => {
    let list = [];
    for (let i = 1; i <= maxListLength; i++) {
      list.push(i);
    }
    return list;
  };
  const numbers: number[] = initNumbersList(props.length);
  return (
    <IonItemGroup>
      {numbers.map((number) => (
        <IonItem key={number} lines="full" className="numbersList" mode="md">
          <IonLabel className="label" class="ion-text-center">
            {number}
          </IonLabel>
        </IonItem>
      ))}
    </IonItemGroup>
  );
};
export default NumbersList;
