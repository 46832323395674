import { IonCol, IonRow } from "@ionic/react";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import Axios from "../../../../Axios";
import {
  baseUrlPMBackend,
  environment,
} from "../../../../Configurations/consts";
import customToast from "../../../Shared/Toast/CustomToast";
import IUser from "../../../../Interfaces/IUser";
import IForceTreeNode from "../../../../Interfaces/IForceTreeNode";
import { useSelector } from "react-redux";
import { IOrbatTreeReducer } from "../../../../redux/reducers/orbatTree";
import { orbatTreeState } from "../../../../redux/store/OrbatTreeStore";
import { getForceById } from "../../../../services/helpers";
import SaveCancelButtons from "../../../Shared/Buttons/SaveCancelButtons";
import { userRoles } from "../../../../services/routeRoles";
import EEnvironment from "../../../../Enums/EEnvironment";

import "./AddUser.css";
import OrbatInput from "../../../Shared/Orbat/Input/OrbatInput";
import PMLabel from "../../../themeComponents/PMLabel";
import { UserContext } from "../../../../context/UserContext/userContext";
import { MarketingContext } from "../../../../context/MarketingContext/MarketingContext";
import PMIcon from "../../../themeComponents/PMIcon";
import EIconsSrc from "../../../../Interfaces/EIconsSrc";
import PMDropdown from "../../../Shared/PMDropdown/PMDropdown";
import IOption from "../../../../Interfaces/IOption";
import PMInput from "../../TrainingPlan/PMInput";
import { AppModeContext } from "../../../../context/AppModeContext/AppModeContext";
import PMTooltip from "../../PMTooltip/PMTooltip";

interface IEditUserProps {
  mode: "edit" | "add";
  user?: IUser | undefined;
  editUserHandler?: (user: IUser) => void;
  addUserToList?: (user: IUser) => void;
  cancelEditMode?: () => void;
  setUpdateUsersTable: Dispatch<SetStateAction<boolean>>;
}

export const validateRelatedForce = (
  role: string,
  relatedForce: IForceTreeNode | undefined
) => {
  if (
    !relatedForce?.id &&
    (role === userRoles.Instructor || role === userRoles.Viewer)
  ) {
    return false;
  }
  return true;
};
const AddUser = (props: IEditUserProps) => {
  const { appMode } = useContext(AppModeContext);
  const { t } = useTranslation();
  const { isMarketing } = useContext(MarketingContext);
  const [roles, setRoles] = useState<IOption[]>([]);
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [role, setRole] = useState<IOption>({} as IOption);
  const [displayName, setDisplayName] = useState<string>("");
  const [isLocalUser, setIsLocalUser] = useState(false);
  const [password, setPassword] = useState<string>("");
  const [selectedSoldier, setSelectedSoldier] = useState<IForceTreeNode[]>(
    [] as IForceTreeNode[]
  );

  const { user } = useContext(UserContext);
  const orbatTree: IForceTreeNode = useSelector<
    orbatTreeState,
    IOrbatTreeReducer
  >((state) => state.orbatTree).orbatTree;

  // Gets roles to add user
  useEffect(() => {
    let mounted: boolean = true;
    const getAllRoles = async () => {
      let res = await Axios.get(`${baseUrlPMBackend}users/getRoles`);
      if (res.status === 200) {
        mounted &&
          setRoles(
            res.data.map((role: { name: string }) => {
              return { label: t(role.name), value: role.name };
            })
          );
      } else {
        customToast.error(t("getRolesError"));
      }
    };
    getAllRoles();

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLocalUser(environment === EEnvironment.localSite || isMarketing);
  }, []);

  useEffect(() => {
    if (props.user !== undefined) {
      // Reset selected soldier
      setSelectedSoldier([]);

      // Apply user current details
      setDisplayName(props.user.displayName);
      setEmailAddress(props.user.emailAddress);
      setRole({ key: props.user.role, value: t(props.user.role) });

      // Checks if has attached force
      const force = getForceById(
        orbatTree,
        Number(props.user.relatedForce?.id)
      );
      if (force) setSelectedSoldier([force]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user]);

  useEffect(() => {
    if (
      props.mode === "add" &&
      (emailAddress !== "" ||
        displayName !== "" ||
        password !== "" ||
        selectedSoldier.length !== 0) //array !== [] always be true by reference
    ) {
      resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.mode]);

  const validateEmail = (text?: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(text ? text : emailAddress).toLowerCase());
  };

  const validationRegex = [
    { condition: /.{8,}/, description: "minimum8characters" },
    { condition: /[0-9]/, description: "atLeastOneNumber" },
    { condition: /[a-z]/, description: "atLeastOneLowercaseLetter" },
    { condition: /[A-Z]/, description: "atLeastOneUppercaseLetter" },
    { condition: /[^A-Za-z0-9]/, description: "atLeastOneSpecialCharacter" },
  ];
  const isStrongPassword = (password: string) => {
    return validationRegex.every((regex) => regex.condition.test(password));
  };
  // Resets fields after a user has been added
  const resetFields = () => {
    setEmailAddress("");
    setRole({} as IOption);
    setDisplayName("");
    setPassword("");
    setSelectedSoldier([]);

    // If in edit mode, cancels it
    if (props.mode === "edit" && props.cancelEditMode) props.cancelEditMode();
  };

  const addUser = async () => {
    if (
      emailAddress &&
      role.value &&
      displayName &&
      (!isLocalUser || (isLocalUser && password))
    ) {
      if (
        ((isLocalUser && validateEmail(emailAddress)) || validateEmail()) &&
        validateRelatedForce(role.value, selectedSoldier[0]) &&
        (!isMarketing || isStrongPassword(password))
      ) {
        try {
          let res = await Axios.post(`${baseUrlPMBackend}users/addUser`, {
            password: password,
            displayName: displayName,
            emailAddress: emailAddress,
            role: role.value,
            forceId: selectedSoldier[0] ? selectedSoldier[0].id : null,
          });
          if (res.status === 200) {
            let newUserId = res.data[0].id;
            customToast.success(t("userAddSuccess"));
            if (props.mode === "add" && props.addUserToList)
              await props.addUserToList({
                id: newUserId,
                emailAddress: emailAddress,
                displayName: displayName,
                role: role.value,
                relatedForce: selectedSoldier[0]
                  ? selectedSoldier[0]
                  : undefined,
                forceToDisplayInOrbat: { id: -1, forceType: "" },
                appMode: appMode,
              });
            props.setUpdateUsersTable((prev: boolean) => !prev);
            resetFields();
          } else {
            customToast.error(t("userAddFail"));
          }
        } catch (error: any) {
          if (error.response.data === "user already exist")
            customToast.error(t("userAlreadyExist"));
          else {
            customToast.error(t("userAddFail"));
          }
        }
      } else {
        !validateEmail() && !isLocalUser
          ? customToast.error(t("invalidEmailAddress"))
          : isLocalUser && !validateEmail(emailAddress) //only email character for user name
          ? customToast.error(t("userNameMustBeEnglish"))
          : isMarketing && !isStrongPassword(password)
          ? customToast.error(t("strongPasswordRequired"))
          : customToast.error(t("instructorOrViewerMustHaveRelatedForce"));
      }
    } else {
      customToast.error(t("missingInformation"));
    }
  };

  const editUser = () => {
    if (props.mode === "edit" && props.user && props.editUserHandler) {
      const user = {
        ...props.user,
        displayName: displayName,
        emailAddress: emailAddress,
        password: password,
        relatedForce: selectedSoldier[0] ? selectedSoldier[0] : undefined,
        role: role.key,
      };
      props.editUserHandler(user);
    }
  };

  // return the options for the user according to the role he have. if the user is viewer or instructor he wouldn't be able to see an "Admin" option
  const optionsToShow = (roles: IOption[], role: IOption) => {
    if (role.key === "Admin") {
      return roles.map((role) => {
        return { key: role.key, value: role.value };
      });
    } else {
      return roles
        .filter((role) => role.key !== "Admin")
        .map((role) => {
          return { key: role.key, value: role.value };
        });
    }
  };

  return (
    <IonRow className="addUserRow">
      {roles ? (
        <IonRow className="inputFields">
          <div className="checkboxDiv">
            <PMLabel fontFamily="Regular" fontSize="large" cssClass="userTitle">
              {props.mode === "add" ? t("addUserHeader") : t("editUser")}
            </PMLabel>
          </div>
          <IonCol>
            <IonRow>
              <IonCol>
                <PMInput
                  inputName="displayName"
                  onChangeHandler={(_field: string, value: string) =>
                    setDisplayName(value)
                  }
                  inputType="text"
                  placeholder={t("displayName")}
                  width="long"
                  inputValue={displayName}
                ></PMInput>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                {isLocalUser ? (
                  <PMInput
                    inputName="emailAddress"
                    inputValue={emailAddress}
                    onChangeHandler={(_field: string, value: string) =>
                      setEmailAddress(value)
                    }
                    inputType="text"
                    placeholder={t("username")}
                    width="long"
                  ></PMInput>
                ) : (
                  <PMInput
                    inputName="email"
                    placeholder={t("email")}
                    inputType="text"
                    inputValue={emailAddress}
                    onChangeHandler={(_field: string, value: string) =>
                      setEmailAddress(value)
                    }
                    width="long"
                  />
                )}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <PMDropdown
                  placeholder={t("role")}
                  options={optionsToShow(roles, role)}
                  selectedOption={role}
                  onOptionChanges={(option) => {
                    setRole({
                      key: option?.key ? option.key : "",
                      value: option?.value ? option.value.toString() : "",
                    });
                  }}
                ></PMDropdown>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                {isLocalUser ? (
                  <PMInput
                    inputName="password"
                    placeholder={t("password")}
                    inputType="password"
                    inputValue={password}
                    onChangeHandler={(field: string, value: string) =>
                      setPassword(value)
                    }
                    width="long"
                  />
                ) : null}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <OrbatInput
                  wrapperCss="forcesPickerAddUser"
                  inputValue={`${t("selectForces")}`}
                  placeholder={`${t("selectForces")}`}
                  inputWidth="long"
                  isForDropDown
                  collapseCssClass="treeCollapse treeAddUser scrollM"
                  selectedForces={selectedSoldier}
                  setSelectedForces={setSelectedSoldier}
                  isRootDisable={
                    !(
                      user.role === userRoles.Admin &&
                      role.value === userRoles.Instructor &&
                      environment === EEnvironment.localSite
                    )
                  }
                  limit={1}
                />
              </IonCol>
            </IonRow>

            {isMarketing ? (
              <>
                <PMTooltip id="passwordInformation">
                  <PMLabel
                    fontFamily="Regular"
                    fontSize="medium"
                    fontColor="dark"
                    cssClass="tooltipTitlePassword"
                  >
                    {t("strongPassword")}
                  </PMLabel>
                  <ul className="tooltipColumn">
                    {validationRegex.map((cond, index) => (
                      <li key={index} className="tooltipRow">
                        <PMLabel
                          fontFamily="Regular"
                          fontSize="medium"
                          cssClass="userTitle"
                        >
                          {t(cond.description)}
                        </PMLabel>
                      </li>
                    ))}
                  </ul>
                </PMTooltip>
                <div
                  data-tooltip-id="passwordInformation"
                  className="passwordInformation"
                >
                  <PMIcon
                    iconSrc={EIconsSrc.QUESTION}
                    color={"light"}
                    size="large"
                  />
                </div>
              </>
            ) : null}
          </IonCol>
        </IonRow>
      ) : (
        <div></div>
      )}
      <IonRow className="addButtonRow">
        <div className="editButtonsDiv">
          <SaveCancelButtons
            onCancelClickHandler={resetFields}
            onSaveClickHandler={props.mode === "add" ? addUser : editUser}
          ></SaveCancelButtons>
        </div>
      </IonRow>
    </IonRow>
  );
};

export default AddUser;
