import React, { FC, useEffect, useState } from "react";
import "./PerformanceTrends.css";
import { IonRow } from "@ionic/react";
import IndicatorBarChart from "../../../../../components/Desktop/IndicatorBarChart/IndicatorBarChart";
import ILabel from "../../../../../Interfaces/ILabel";
import IForceTreeNode from "../../../../../Interfaces/IForceTreeNode";
import { useTranslation } from "react-i18next";
import IForceFromOrbat from "../../../../../Interfaces/IForceFromOrbat";
import IFinalGradeDisplay from "../../../../../Interfaces/IFinalGradeDisplay";
import IPlan from "../../../../../Interfaces/IPlan";
import IThreshold from "../../../../../Interfaces/IThreshold";
import ITrendIndicator from "../../../../../Interfaces/ITrendIndicator";

import MultiSelectDropdown from "../../../../../components/Desktop/MultySelectDropdown/MultiSelectDropdown";
import ISelectionOption from "../../../../../Interfaces/ISelectionOption";
import useGetPerformanceTrends from "../../../../../components/CustomHooks/UseGetPerformaneTrends";
import EIconsSrc from "../../../../../Interfaces/EIconsSrc";
import PMIcon from "../../../../../components/themeComponents/PMIcon";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles

import "swiper/css";
import "swiper/css/pagination";

import PerformanceTemplate from "../../performanceTemplate";
import usePlans from "../../../../../components/CustomHooks/usePlans";
import exportToCsv from "../../../../../components/Desktop/ToExcel/export-to-csv";
import generatePDF from "../../../../../components/Desktop/ToExcel/generatePDFHelper";
import EExportMethod from "../../../../../Interfaces/EExportMethod";
import { toPng } from "html-to-image";
import ELanguage from "../../../../../Enums/ELanguage";
import { EGraphType } from "../../../../../Enums/EGraphType";
import PMIconRoundedButton from "../../../../../components/themeComponents/PMIconRoundedButton";
interface IProps {
  orbatForces: IForceTreeNode[];
  checkedForces: IForceFromOrbat[];
  setChecked: (forces: IForceTreeNode[]) => void;
  isAdmin?: boolean | true;
  plan: IPlan;
  thresholds: IThreshold[];
  labels: ILabel[];
  newForces: IForceFromOrbat[];
  forcesToRemove: IForceFromOrbat[];
  finalGrades: IFinalGradeDisplay[];
  indicators: ITrendIndicator[];
  isDrawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PerformanceTrends: FC<IProps> = (props: IProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const {
    orbatForces,
    setChecked,
    plan,
    thresholds,
    labels,
    forcesToRemove,
    checkedForces,
    newForces,
    finalGrades,
    indicators,
    isDrawerOpen,
    setDrawerOpen,
  } = props;
  const [swiperRef, setSwiperRef] = useState<any>(null);
  const { plans, globalPlans } = usePlans();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedLabels, setSelectedLabels] = useState<ILabel[]>(
    [] as ILabel[]
  );
  const [myNewForces, setMyNewForces] = useState<IForceFromOrbat[]>([]);
  const [currentSwiperPage, setCurrentSwiperPage] = useState<number>(0);

  useEffect(() => {
    setMyNewForces((prev) => {
      if (!prev.length) return checkedForces;
      return newForces;
    });
  }, [newForces, checkedForces]);

  const getSelectionPlans = () => {
    let tempArray: ISelectionOption[] = [];
    labels?.forEach((plan: ILabel) => {
      tempArray.push({ option: plan, isSelected: plan.isSelected });
    });
    return tempArray;
  };
  const [selectedPlans, setSelectedPlans] = useState<ISelectionOption[]>(
    getSelectionPlans()
  );
  const [lineOrBarTypeToggle, setLineOrBarTypeToggle] = useState<EGraphType>(
    EGraphType.bar
  );
  const { trendsBarChartsData, setIsToRefresh } = useGetPerformanceTrends(
    plans,
    myNewForces,
    forcesToRemove,
    checkedForces,
    indicators,
    setLoading
  );

  useEffect(() => {
    setSelectedPlans(getSelectionPlans());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labels]);

  useEffect(() => {
    let newLabels: ILabel[] = [];
    selectedPlans.forEach((plan) => {
      if (plan.isSelected) newLabels.push(plan.option);
    });
    let isChanged = false;
    selectedLabels?.forEach((label) => {
      let newLabel = newLabels.find(
        (newLabel) => Number(newLabel.id) === Number(label.id)
      );
      if (!newLabel) isChanged = true;
    });
    newLabels?.forEach((label) => {
      let newLabel = selectedLabels?.find(
        (newLabel) => newLabel.id === label.id
      );
      if (!newLabel) isChanged = true;
    });

    if (isChanged || !selectedLabels?.length) {
      setSelectedLabels(newLabels);
    }
  }, [selectedPlans, selectedLabels]);
  /**
   * organize trends data and export to csv
   * @param image
   * @returns
   */
  const exportToCsvs = async (
    images: {
      name: string;
      image: string;
    }[],
    exportMethod: EExportMethod
  ) => {
    let currentDate: Date = new Date();

    let fileName = `PerformanceTrendsReport_${String(
      currentDate.getDate()
    ).padStart(2, "0")}${String(currentDate.getMonth() + 1).padStart(2, "0")}${
      currentDate.getFullYear() % 100
    }${currentDate.getHours()}${currentDate.getMinutes()}${currentDate.getSeconds()}`;

    let indicatorsNames = [
      "",
      ...indicators.map((indicator) => t(indicator.name)),
    ];
    let rowIndex = 6;
    let headersRows: number[] = [];
    let rows: string[][] = [[], [], [], []];
    let rowsIndexToBorder: number[][] = [];
    let headersRowsIndexToBold: number[][] = [];
    if (checkedForces.length === 0) return;

    let forcesData = orbatForces.map((force) => {
      return {
        forceName: force.name,
        forceGrades: trendsBarChartsData.map((indicator) => {
          return {
            indicatorId: indicator.indicatorId,
            plansGrade: indicator.data.find(
              (forceData) => +forceData.id === +force.id
            )?.gradesArray,
          };
        }),
      };
    });
    rows = [...rows, [t("trendsReport")], [], []];
    headersRows.push(rowIndex);
    rowIndex += 3;
    let columnToBold: number[] = [];
    let dataForPDF: any[] = [];
    dataForPDF.push({ type: "title", data: t("trendsReport") });
    forcesData.forEach((force) => {
      headersRows.push(rowIndex);
      rows.push([force.forceName]);
      rows.push([""]);

      rows.push(indicatorsNames);
      rowIndex += 2;
      headersRowsIndexToBold.push([rowIndex, indicators.length + 1]);
      let globalPlansForPDF = globalPlans
        .sort((p1, p2) => p1.name.localeCompare(p2.name))
        .map((plan, index) => {
          let row: string[] = [t(plan.name)];
          indicators
            .sort((i1, i2) => i1.id - i2.id)
            .forEach((indicator) => {
              let forcePlansData = force.forceGrades
                .find(
                  (forceIndicatorData) =>
                    +forceIndicatorData.indicatorId === +indicator.id
                )
                ?.plansGrade?.find((planData) => +planData.id === +plan.id);
              row.push(
                forcePlansData?.isAttended
                  ? String(Math.round(forcePlansData.grade))
                  : "-"
              );
            });
          rows.push(row);
          columnToBold.push(rowIndex + 1 + index);
          return row;
        });
      dataForPDF.push({
        type: "trends",
        data: [indicatorsNames, ...globalPlansForPDF],
        title: force.forceName,
        images: images,
      });

      new Array(globalPlans.length + 1).fill(0).forEach((_val, index) => {
        rowsIndexToBorder.push([rowIndex + index, indicators.length]);
      });
      rowIndex += globalPlans.length;
      rows.push([]);
      rows.push([]);
      rowIndex += 3;
    });

    headersRowsIndexToBold.push([rowIndex]);
    rowIndex += 1;
    if (
      exportMethod === EExportMethod.pdfDarkMode ||
      exportMethod === EExportMethod.pdfLightMode
    )
      generatePDF(
        dataForPDF,
        `${fileName}.pdf`,
        exportMethod === EExportMethod.pdfLightMode
      );
    if (exportMethod === EExportMethod.excel)
      await exportToCsv(
        fileName,
        headersRows,
        rows,
        indicators.length,
        [],
        headersRowsIndexToBold,
        rowsIndexToBorder,
        [],
        [],
        [rowIndex],
        [],
        [],
        t,
        false,
        undefined,
        undefined,
        700,
        1500,
        columnToBold,
        undefined,
        undefined,
        images
      );
  };

  const getImageAndSendToCsv = async (exportMethod: EExportMethod) => {
    let images: { name: string; image: string }[] = [];
    await Promise.all(
      indicators.map(async (indicator) => {
        let indicatorData = trendsBarChartsData.find(
          (d) => +d.indicatorId === +indicator.id
        );
        if (
          indicatorData?.data.find(
            (forceData) =>
              forceData.gradesArray.find(
                (planIndicatorForForce) => planIndicatorForForce.isAttended
              ) !== undefined
          )
        ) {
          const image = await toPng(
            document.getElementById(
              `csvTrendsImage${indicatorData.indicatorId}`
            )!
          );
          images.push({
            name: t(indicator.name),
            image: image || "",
          });
        }
      })
    );

    exportToCsvs(images, exportMethod);
  };
  const chart = (
    <div className="slidesWrap">
      <div className="trendsIconContainerRow">
        <PMIconRoundedButton
          iconSrc={
            lineOrBarTypeToggle === EGraphType.bar
              ? EIconsSrc.LINE_CHART_ICON
              : EIconsSrc.BAR_ICON
          }
          onClick={() => {
            setLineOrBarTypeToggle((prev) =>
              prev === EGraphType.bar ? EGraphType.line : EGraphType.bar
            );
          }}
        ></PMIconRoundedButton>
      </div>
      {indicators.length > 0 && (
        <div className="graph-colT ">
          <div className="barT">
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              allowTouchMove
              loop
              onSlideChange={(event) => {
                setCurrentSwiperPage(event.realIndex);
              }}
              onSwiper={setSwiperRef}
            >
              {indicators.map((indicator: ITrendIndicator) => (
                <SwiperSlide key={indicator.id}>
                  <div
                    className="trendSlide"
                    id={`csvTrendsImage${indicator.id}`}
                    key={indicator.id}
                  >
                    <IndicatorBarChart
                      key={indicator.id}
                      indicatorId={indicator.id}
                      thresholds={thresholds}
                      labels={selectedLabels}
                      selectedForces={checkedForces}
                      newForces={myNewForces}
                      forcesToRemove={forcesToRemove}
                      barChartsData={trendsBarChartsData}
                      isDesktop={true}
                      plan={plan}
                      trends
                      lineOrBarTypeToggle={lineOrBarTypeToggle}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="arrows">
              <IonRow class="ion-justify-content-center">
                <PMIcon
                  isButton
                  iconSrc={
                    i18n.language === ELanguage.he
                      ? EIconsSrc.CHEVRON_RIGHT
                      : EIconsSrc.CHEVRON_LEFT
                  }
                  onClick={() => swiperRef.slidePrev()}
                  size="xLarge"
                  color="light"
                />
                <PMIcon
                  isButton
                  iconSrc={
                    i18n.language === ELanguage.he
                      ? EIconsSrc.CHEVRON_LEFT
                      : EIconsSrc.CHEVRON_RIGHT
                  }
                  onClick={() => swiperRef.slideNext()}
                  size="xLarge"
                  color="light"
                />
              </IonRow>
            </div>
          </div>
        </div>
      )}{" "}
    </div>
  );
  return (
    <PerformanceTemplate
      key={"trends"}
      loadingText={t("loadingData")}
      title={t(indicators[currentSwiperPage]?.name)}
      chart={chart}
      finalGrades={finalGrades}
      isDrawerOpen={isDrawerOpen}
      loading={loading}
      onClickExportButton={(exportMethod) => {
        getImageAndSendToCsv(exportMethod);
      }}
      exportButtonText={t("export")}
      iButtonsDisabled={!checkedForces.length}
      onClickRefreshButton={() => setIsToRefresh(true)}
      refreshButtonText={t("refresh")}
      trendsDropdown={
        <div className="trendsDropdownWrap">
          <MultiSelectDropdown
            selectionPotions={selectedPlans}
            setChecked={setSelectedPlans}
            title={t("chooseTrainingPlans")}
          />
        </div>
      }
      selectedPlan={plan}
      setChecked={setChecked}
      setDrawerOpen={setDrawerOpen}
      orbatForces={orbatForces}
      isNotExport
    ></PerformanceTemplate>
  );
};

export default PerformanceTrends;
