import { IonPage } from "@ionic/react";
import React from "react";
import logo from "../../../Assets/notFound.png";

const NotFound = () => {
  return (
    <IonPage>
      <img alt="notfoundPic" src={logo} className="img"></img>
    </IonPage>
  );
};

export default NotFound;
