import { FC, useContext } from "react";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonRefresher,
  IonRefresherContent,
  IonText,
  IonToolbar,
  RefresherEventDetail,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../context/UserContext/userContext";
import PMIcon from "../../themeComponents/PMIcon";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import ForcesContext from "../../../context/ForcesContext/forcesContext";
import "./MobileHeader.css";
import { useAppMode } from "../../CustomHooks/useAppMode";

interface IProps {
  iconEndSrc?: string;
  iconStartSrc?: string;
  iconEndHandler?: () => void;
  iconStartHandler?: () => void;
  title?: string;
  isUserDisplay?: boolean;
  isShowForcesTree?: boolean;
  isToHideModalIcon?: boolean;
}

const MobileHeader: FC<IProps> = (props: IProps) => {
  const {
    iconStartSrc,
    iconStartHandler,
    title,
    isUserDisplay,
    isShowForcesTree,
  } = props;
  const { t } = useTranslation();
  useAppMode();

  const { isForcesTreeOpen, setIsForcesTreeOpen, forces } =
    useContext(ForcesContext);

  const { user } = useContext(UserContext);

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    window.location.reload();
  }
  return (
    <IonContent scrollY={false} className="contentHeder">
      <IonRefresher
        slot="fixed"
        onIonRefresh={handleRefresh}
        className="ionRefresher"
      >
        <IonRefresherContent> </IonRefresherContent>
      </IonRefresher>
      <IonHeader>
        <IonToolbar>
          <div className="row-header">
            <div className="page-header">
              <div className="headerWrap">
                {!props.isToHideModalIcon ? (
                  <IonMenuButton>
                    <PMIcon iconSrc={EIconsSrc.DAHAZE}></PMIcon>
                  </IonMenuButton>
                ) : null}
                <IonButton
                  hidden={iconStartSrc === undefined}
                  fill="default"
                  className="header-button"
                  onClick={iconStartHandler}
                >
                  {iconStartSrc ? (
                    <PMIcon size="large" iconSrc={iconStartSrc} color="light" />
                  ) : null}
                </IonButton>

                {isUserDisplay === false ? null : (
                  <IonText className="mobile-user-text">
                    {t("hello") + ", " + user.displayName}
                  </IonText>
                )}
              </div>

              <IonText
                className={iconStartSrc === undefined ? "header-title" : ""}
              >
                {title}
              </IonText>
            </div>
            <div className="more-details">
              <div className="orbat-tree-icon">
                <PMIcon
                  hidden={isForcesTreeOpen === undefined || !isShowForcesTree}
                  iconSrc={EIconsSrc.TREE_ICON_EN}
                  size="large"
                  color="light"
                  cssClass={!forces.length && !isForcesTreeOpen ? "flash" : ""}
                  onClick={() => setIsForcesTreeOpen((prev) => !prev)}
                />
              </div>
            </div>
          </div>
        </IonToolbar>
      </IonHeader>
    </IonContent>
  );
};

export default MobileHeader;
