import { FC } from "react";
import { IonRow } from "@ionic/react";
import "./Steps.css";
import PMLabel from "../../themeComponents/PMLabel";
import PMIcon from "../../themeComponents/PMIcon";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import { useTranslation } from "react-i18next";
import { ISingleGunnerStep } from "../../../Interfaces/Gorilla/IHeatTemplate";

interface IProps {
  steps: ISingleGunnerStep[];
  activeStep: number;
}
const Steps: FC<IProps> = (props: IProps) => {
  const { activeStep, steps } = props;
  const { t } = useTranslation();
  return (
    <IonRow className="stepsRow">
      {steps.map((step, index) => (
        <div className="stepDiv" key={index}>
          {
            <span
              key={index}
              className={`stepItem ${
                activeStep === step.id
                  ? "activeStep"
                  : activeStep > step.id
                  ? "complectedStep"
                  : ""
              }`}
            >
              {activeStep > step.id ? (
                <PMIcon size="xSmall" iconSrc={EIconsSrc.OK}></PMIcon>
              ) : (
                step.id
              )}
            </span>
          }
          <PMLabel
            fontColor={activeStep === step.id ? "orange" : "light"}
            fontFamily="Light"
            fontSize="medium"
            cssClass={`stepTitle ${
              activeStep === step.id ? "selectedStepTitle" : ""
            }`}
          >
            {t(step.title)}
          </PMLabel>
        </div>
      ))}
    </IonRow>
  );
};

export default Steps;
