import { Dispatch, FC, SetStateAction } from "react";
import "./Observer.css";
import AccuracyElement from "../AccuracyElement";
import GeneralGrade from "../GeneralGrade";
import { ITargetAcquisition } from "../../../../Interfaces/Gorilla/IObserver";
import { IonGrid } from "@ionic/react";

type IProps = {
  targetAcquisition: ITargetAcquisition;
  setTargetAcquisition: Dispatch<SetStateAction<ITargetAcquisition>>;
  isDesktop?: boolean;
};

const TargetAcquisition: FC<IProps> = (props: IProps): JSX.Element => {
  const { setTargetAcquisition, targetAcquisition } = props;
  return (
    <IonGrid className="observerContentWrap scrollS">
      <div className="TACol">
        <GeneralGrade
          elementName={"TargetAcquisitionFastness"}
          grade={targetAcquisition?.TargetAcquisitionFastness}
          setGrade={(grade) => {
            setTargetAcquisition((prev) => ({
              ...prev,
              TargetAcquisitionFastness: grade,
            }));
          }}
          isDesktop={props.isDesktop}
        ></GeneralGrade>
        <div className="dividerLine"> </div>
        <div>
          <AccuracyElement
            onChange={(isChecked) => {
              setTargetAcquisition((prev) => ({
                ...prev,
                LasingOnSuspectedObject: isChecked,
              }));
            }}
            elementName={`LasingOnSuspectedObject`}
            accuracy={targetAcquisition.LasingOnSuspectedObject}
          ></AccuracyElement>
          <AccuracyElement
            onChange={(isChecked) => {
              setTargetAcquisition((prev) => ({
                ...prev,
                LasingAccuracy: isChecked,
              }));
            }}
            elementName={`LasingAccuracy`}
            accuracy={targetAcquisition.LasingAccuracy}
          ></AccuracyElement>
          <AccuracyElement
            onChange={(isChecked) => {
              setTargetAcquisition((prev) => ({
                ...prev,
                MultiRangesFilterRanges: isChecked,
              }));
            }}
            elementName={`MultiRangesFilterRanges`}
            accuracy={targetAcquisition.MultiRangesFilterRanges}
          ></AccuracyElement>
          <AccuracyElement
            onChange={(isChecked) => {
              setTargetAcquisition((prev) => ({
                ...prev,
                LoadingSuspectIdentificationC2: isChecked,
              }));
            }}
            elementName={`LoadingSuspectIdentificationC2`}
            accuracy={targetAcquisition.LoadingSuspectIdentificationC2}
          ></AccuracyElement>
          <AccuracyElement
            onChange={(isChecked) => {
              setTargetAcquisition((prev) => ({
                ...prev,
                AccurateIdentification: isChecked,
              }));
            }}
            elementName={`AccurateIdentification`}
            accuracy={targetAcquisition.AccurateIdentification}
          ></AccuracyElement>
        </div>
      </div>
    </IonGrid>
  );
};

export default TargetAcquisition;
